import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import {Link, useNavigate, useParams} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Accordion} from "react-bootstrap";
import ImageUploader from "./../component/ImageUploader";
import {customerBookingDetailsApi, customerPaymentHistoryApi, getCustomerDetailsApi, getTaxDetailsApi, upcomingBookingApi} from "../services/ApiService";
import {Helmet} from "react-helmet";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const crossIcon2 = require("./../assets/img/cross.svg").default;
const filterBtn = require("./../assets/img/filter-icon.svg").default;

const PaymentHistory = () => {
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState();
  const [paymentHistoryList, setpaymentHistoryList] = useState([]);
  const [filterParameter, setFilterParameter] = useState({
    filterType: 1,
    startDate: null,
    endDate: null,
    isCancelledBooking: false,
    bookingStatus: null,
    phoneNumber: null,
    customerName: null,
    destination: null,
    originDestination: null,
    bookingId: null,
  });

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustomerData(response.data);
        }
      }
    } catch (error) {}
  };

  const getPaymentList = async (startDate, endDate) => {
    try {
      const fd = new FormData();
      fd.append("start_date", startDate ? startDate : "");
      fd.append("end_date", endDate ? endDate : "");
      let response = await customerPaymentHistoryApi(fd);
      if (response) {
        if (response.data.res === true) {
          setpaymentHistoryList(response.data.data);
        }
      }
    } catch (error) {}
  };

  const formatDateToYMD = date => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const formatDate = dateString => {
    const date = new Date(dateString);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  };

  const handleViewButtonClick = () => {
    const formattedStartDate = formatDateToYMD(startDate);
    const formattedEndDate = formatDateToYMD(endDate);
    getPaymentList(formattedStartDate, formattedEndDate);
  };

  useEffect(() => {
    getCustomerDetails();
    getPaymentList();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Booked Tour Details`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Booked Tour Details page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv paymentHistory">
            <div className="accountName">
              <h2>
                {customerData?.data?.first_name} {customerData?.data?.last_name}
              </h2>
              <h3>{customerData?.User?.mobile}</h3>
            </div>
            <div className="pointDiv">
              <h2>
                Wishmytour Points <span>0</span>
              </h2>
            </div>

            <div className="breadCmb">
              <p>
                <Link to="/profile">My Account</Link>Payment History
              </p>
            </div>

            <div className="upcomingInner">
              <div className="uptabContent tourDtl">
                <div className="pymntHist">
                  <div className="statementPeriod">Statement Period</div>
                  <div className="topHist">
                    <ul className="stateDate">
                      <li>
                        <div className="datePckr2">
                          <DatePicker selected={startDate} onChange={date => setStartDate(date)} dateFormat="MMMM d, yyyy" placeholderText="Start Date" />
                        </div>
                      </li>
                      <li>
                        <div className="datePckr2">
                          <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            // dateFormat="yyyy-MM-dd"
                            dateFormat="MMMM d, yyyy"
                            placeholderText="End Date"
                            minDate={startDate}
                          />
                        </div>
                      </li>
                      <li>
                        <button className="vwBtn" onClick={handleViewButtonClick}>
                          View
                        </button>
                      </li>
                    </ul>
                    {/* <button className="downloadHistPDF">Download PDF</button> */}
                    <button
                      className="downloadHistPDF"
                      onClick={() => {
                        getPaymentList("", "");
                        setStartDate(null);
                        setEndDate(null);
                      }}>
                      Reset
                    </button>
                  </div>
                </div>

                <div className="storybrainResets">
                  <div className="frame thFrame">
                    <div className="date">Date</div>
                    <div className="date">Booking ID</div>
                    <div className="type">Type</div>
                    <div className="amount">Amount</div>
                    <div className="transactionID">Transaction ID</div>
                    <div className="downloadInvoice"></div>
                  </div>
                  {paymentHistoryList?.map((item, index) => (
                    <>
                      <div className="frame" key={index}>
                        <div className="date">{formatDate(item?.payment_date)}</div>
                        <div className="date">#{item?.booking_id}</div>
                        <div className="type">{parseInt(item?.refund_amount) > 0 ? "Refund" : item?.payment_type}</div>
                        <div className={parseInt(item?.refund_amount) > 0 ? "amount redColorSts" : "amount"}>
                          ₹{parseInt(item?.refund_amount) > 0 ? item?.refund_amount : item?.paid_amount}
                        </div>
                        <div className="transactionID">{item?.transaction_number ? item?.transaction_number : "-"}</div>
                        <div className="downloadInvoice">
                          {parseInt(item?.refund_amount) > 0 ? null : (
                            <a href={item?.pdfFilePath} target="_blank" download>
                              Download Invoice
                            </a>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentHistory;
