// Live Url

export const API_BASE_URL = "https://wishmytour.in/backend_live/api/";
export const IMAGE_URL = "https://wishmytour.in/backend_live/storage/app/public/";

// Local Url

// export const API_BASE_URL = "https://wishmytour.in/backend/api/";
// export const IMAGE_URL = "https://wishmytour.in/backend/storage/app/public/";


export const CAPTCHA_SITE_KEY = "6Ldiu54pAAAAACQc8XKNIUpr6XH4XxqvpY6RvvU-";

// export const IS_LIVE = false;
export const IS_LIVE = true;
