import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import {submitContactFormApi} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {Helmet} from "react-helmet";

const ContactUs = () => {
  const [btnDis, setBtnDis] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    const newErrors = {};
    let isvalid = true;
    if (!formData.firstName) {
      newErrors.firstName = "First Name is required";
      isvalid = false;
    }
    if (!formData.lastName) {
      newErrors.lastName = "Last Name is required";
      isvalid = false;
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
      isvalid = false;
    }
    if (formData.email && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.email)) {
      newErrors.email = "Email is invalid";
      isvalid = false;
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
      isvalid = false;
    }
    if (formData.phoneNumber && !/^\d{10}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone Number is invalid";
      isvalid = false;
    }
    if (!formData.message) {
      newErrors.message = "Message is required";
      isvalid = false;
    }
    setErrors(newErrors);
    return isvalid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setBtnDis(true);
      try {
        const fd = new FormData();
        fd.append("first_name", formData?.firstName);
        fd.append("last_name", formData?.lastName);
        fd.append("email", formData?.email);
        fd.append("phone_number", formData?.phoneNumber);
        fd.append("message", formData?.message);
        let response = await submitContactFormApi(fd);
        if (response) {
          if (response.data.res === true) {
            NotificationManager.success(response.data.msg);
            setFormData({
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              message: "",
            });
            setErrors({});
          } else {
            NotificationManager.error(response.data.msg);
          }
          setBtnDis(false);
        }
      } catch (error) {
        setBtnDis(false);
      }
    }
  };

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Contact Us`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Contact Us page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="contactBanner">
          <div className="mainDiv">
            <h2>Contact Us</h2>
          </div>
        </div>
        <div className="mainDiv">
          <div className="leftContact">
            <h3>
              <a href="tel:+913323581262">+91 33 2358 1262</a>
            </h3>
            <h4>
              <a href="mailto:wishmytour@gmail.com">wishmytour@gmail.com</a>
            </h4>
          </div>
          <div className="rightContact">
            <ul>
              <li>
                <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="First Name" />
                {errors.firstName && <span className="validationErrorMsg">{errors.firstName}</span>}
              </li>
              <li>
                <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Last Name" />
                {errors.lastName && <span className="validationErrorMsg">{errors.lastName}</span>}
              </li>
              <li>
                <input type="text" name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
                {errors.email && <span className="validationErrorMsg">{errors.email}</span>}
              </li>
              <li>
                <input type="number" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Phone Number" />
                {errors.phoneNumber && <span className="validationErrorMsg">{errors.phoneNumber}</span>}
              </li>
              <li>
                <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Message"></textarea>
                {errors.message && <span className="validationErrorMsg">{errors.message}</span>}
              </li>
              <li>
                {btnDis ? (
                  <button type="button" disabled>
                    Please wait...
                  </button>
                ) : (
                  <button type="button" onClick={() => handleSubmit()}>
                    Submit
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
