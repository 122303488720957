import React, {Component, useState, useRef, useEffect} from "react";
import {useNavigate} from "react-router";
// import {
//     addPackageApi,
//     changePackageStatusApi,
//     getCityListApi,
//     getStatesListApi,
//     getThemesListApi,
//     getTripListApi,
//     getTypeOfTourPackagesListApi,
//     vendorPackageListApi,
//   } from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import { Form, Button, Dropdown, Tabs, Tab, Modal } from 'react-bootstrap';

import dotsImg from "../assets/img/3dots.svg";


const columns = [
    {
      name: "Pack ID",
      // selector: row => {
      //   const maxLength = 35; // Maximum number of characters before adding ellipsis
      //   const packageName = row.package_name;
      //   return packageName.length > maxLength ? packageName.slice(0, maxLength) + "..." : packageName;
      // },
      selector: (row) => row.pack_id,
      sortable: true,
      minWidth: "80px",
      maxWidth: "14%",
    },
    {
      name: "Package Title",
      selector: row => row.package_title,
      sortable: true,
      minWidth: "140px",
      maxWidth: "13%",
    },
    {
      name: "Seat Adjusted",
      selector: row => row.seat_adjusted,
      sortable: true,
      minWidth: "140px",
      maxWidth: "22%",
    },
    {
      name: "Price Adjusted",
      selector: row => row.price_adjusted,
      sortable: true,
      minWidth: "80px",
      maxWidth: "22%",
    },
    {
      name: "Date of Modification",
      selector: row => row.date_of_modification,
      sortable: true,
      minWidth: "130px",
      maxWidth: "17%",
    },
    {
      name: "Changed for the Date",
      selector: row => row.changed_for_the_date,
      sortable: true,
      minWidth: "194px",
      maxWidth: "10%",
    },
  ];

  const data = [
    {
      pack_id: '#157896',
      package_title: "Sun Kissed Goa Escape",
      seat_adjusted: "60",
      price_adjusted: "₹19,000 INR",
      date_of_modification: "02 Jan, 2024",
      changed_for_the_date: "02 Jan, 2024",
    },
    {
      pack_id: '#157896',
      package_title: "Sun Kissed Goa Escape",
      seat_adjusted: "60",
      price_adjusted: "₹19,000 INR",
      date_of_modification: "02 Jan, 2024",
      changed_for_the_date: "02 Jan, 2024",
    },
    {
      pack_id: '#157896',
      package_title: "Sun Kissed Goa Escape",
      seat_adjusted: "60",
      price_adjusted: "₹19,000 INR",
      date_of_modification: "02 Jan, 2024",
      changed_for_the_date: "02 Jan, 2024",
    },
    {
      pack_id: '#157896',
      package_title: "Sun Kissed Goa Escape",
      seat_adjusted: "60",
      price_adjusted: "₹19,000 INR",
      date_of_modification: "02 Jan, 2024",
      changed_for_the_date: "02 Jan, 2024",
    },
    {
      pack_id: '#157896',
      package_title: "Sun Kissed Goa Escape",
      seat_adjusted: "60",
      price_adjusted: "₹19,000 INR",
      date_of_modification: "02 Jan, 2024",
      changed_for_the_date: "02 Jan, 2024",
    },
    {
      pack_id: '#157896',
      package_title: "Sun Kissed Goa Escape",
      seat_adjusted: "60",
      price_adjusted: "₹19,000 INR",
      date_of_modification: "02 Jan, 2024",
      changed_for_the_date: "02 Jan, 2024",
    },
    {
      pack_id: '#157896',
      package_title: "Sun Kissed Goa Escape",
      seat_adjusted: "60",
      price_adjusted: "₹19,000 INR",
      date_of_modification: "02 Jan, 2024",
      changed_for_the_date: "02 Jan, 2024",
    },
    {
      pack_id: '#157896',
      package_title: "Sun Kissed Goa Escape",
      seat_adjusted: "60",
      price_adjusted: "₹19,000 INR",
      date_of_modification: "02 Jan, 2024",
      changed_for_the_date: "02 Jan, 2024",
    },
  ];


const VendorManualAdjustmentsTable = () => {
    const navigate = useNavigate();
    const [key, setKey] = useState('tab1'); 
    // const [packageList, setPackageList] = useState([]);
    // const [search, setSearch] = useState("");
    // const [filter, setFilter] = useState([]);
    // const [loading, setLoading] = useState(true);
    // const [activeTab, setActiveTab] = useState("all");
    // const [dynamicTableKey, setDynamicTableKey] = useState(1);
  
    //const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  

  
    // const getPackageList = async status => {
    //   if (status === "initial" && setLoading(true));
  
    //   try {
    //     const formData = new FormData();
    //     {
    //       status && status !== "initial" && formData.append("status", status ? status : "");
    //     }
  
    //     let response = await vendorPackageListApi(formData);
    //     if (response) {
    //       if (response.data.res === true) {
    //         setPackageList(response.data.data);
    //       } else {
    //       }
    //       setLoading(false);
    //     }
    //   } catch (error) {
    //     setLoading(false);
    //   }
    // };
  
    // Filter packageList based on the search input
    // const filteredPackageList = packageList.filter(item => item?.package_name?.toLowerCase().includes(search?.toLowerCase()));
  
    // const handleCheckBoxChange = state => {
    //   let tempArray = [];
    //   if (state?.selectedRows?.length > 0) {
    //     state?.selectedRows?.map(item => {
    //       tempArray.push(item?.package_id);
    //     });
    //   }
    //   setSelectedCheckboxIds(tempArray);
    // };
  
    // const handleActionChange = async (actionType, status) => {
    //   setDynamicTableKey(prev => prev + 1);
    //   try {
    //     const formData = new FormData();
    //     if (selectedCheckboxIds?.length > 0) {
    //       selectedCheckboxIds?.map(item => {
    //         formData.append("package_ids[]", item);
    //       });
    //     }
    //     formData.append("status", status ? status : "");
  
    //     let response = await changePackageStatusApi(formData);
    //     if (response) {
    //       if (response.data.res === true) {
    //         NotificationManager.success(response.data.msg);
    //         if (activeTab === "active" || activeTab === "inactive") {
    //           const updatedPackageList = packageList.filter(item => !selectedCheckboxIds.includes(parseInt(item.package_id)));
    //           setPackageList(updatedPackageList);
    //         } else {
    //           if (status === "3") {
    //             const updatedPackageList = packageList.filter(item => !selectedCheckboxIds.includes(parseInt(item.package_id)));
    //             setPackageList(updatedPackageList);
    //           } else if (status === "0") {
    //             const updatedPackageList = packageList.map(item => {
    //               if (selectedCheckboxIds.includes(parseInt(item.package_id))) {
    //                 return {...item, status: "Inactive"}; // Assuming 'status' is the property to update
    //               }
    //               return item;
    //             });
    //             setPackageList(updatedPackageList);
    //           } else if (status === "1") {
    //             const updatedPackageList = packageList.map(item => {
    //               if (selectedCheckboxIds.includes(parseInt(item.package_id))) {
    //                 return {...item, status: "Active"}; // Assuming 'status' is the property to update
    //               }
    //               return item;
    //             });
    //             setPackageList(updatedPackageList);
    //           } else {
    //           }
    //         }
    //         setSelectedCheckboxIds([]);
    //       } else {
    //         NotificationManager.error(response.data.msg);
    //       }
    //     }
    //   } catch (error) {}
    // };
  
    // const handleDuplicate = async () => {
    //   if (selectedCheckboxIds.length > 1) {
    //     NotificationManager.warning("You have to select only one package to duplicate.");
    //   } else {
    //     navigate("/business/package/duplicate/" + selectedCheckboxIds[0]);
    //   }
    // };
  
    // useEffect(() => {
    //   // Clear selected checkboxes when packageList is updated
    //   setSelectedCheckboxIds([]);
    // }, [packageList]);w
  
    // useEffect(() => {
    //   const result = data.filter(item => {
    //     //return item.title.toLowerCase().match(search.toLowerCase());
    //   });
    //   //setFilter(result);
    // }, [search]);
  
    // useEffect(() => {
    //   //getPackageList("initial");
    // }, []);

  return (
    <div>
         <div className="data-table-section">
            <DataTable
                // key={activeTab + dynamicTableKey}
                className="Tbl packages-listing"
                columns={columns}
                // data={filteredPackageList}
                data={data}
                fixedHeader
                //fixedHeaderScrollHeight="300px"
                highlightOnHover
                pagination
                //paginationComponentOptions={paginationComponentOptions}
                responsive
                // selectableRows
                selectableRowsHighlight
                subHeader
                subHeaderAlign="left"
                // onSelectedRowsChange={handleCheckBoxChange}
                subHeaderComponent={
                <>
                    {/* <div className="search-box">
                        <Form.Control type="text" className="SrchInpt" placeholder="Search here" value={search} onChange={e => setSearch(e.target.value)} />
                    </div> */}
                    {/* <div className="ActionRow">
                        <span className="LftField">
                            <span>Action:</span>{" "}
                            <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 || activeTab === "inactive" ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 || activeTab === "inactive" ? true : false}
                            onClick={() => handleActionChange("inactive", "0")}>
                            Mark Inactive
                            </button>
                            <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 || activeTab === "active" ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 || activeTab === "active" ? true : false}
                            onClick={() => handleActionChange("active", "1")}>
                            Mark Active
                            </button>
                            <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 || selectedCheckboxIds?.length > 1 ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 || selectedCheckboxIds?.length > 1 ? true : false}
                            onClick={() => handleDuplicate()}>
                            Duplicate
                            </button>
                            <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 ? true : false}
                            style={{border: "none"}}
                            onClick={() => handleActionChange("archive", "3")}>
                            Archive
                            </button>
                        </span>
                    </div> */}
                    {/* <p className='SelectedRow'>08 Selected</p> */}
                    <div className="BookingsMiddleSec">
                        <h3>Manual Adjustments</h3>
                        <ul className="bookingFilterList">
                            <li>
                                <a className="filterLink">Filter</a>
                            </li>
                            <li>
                                <div className="input-group">
                                    <input type="text" className="form-control caln" placeholder="Date From" />
                                    <input type="text" className="form-control caln" placeholder="Date To" />
                                </div>
                            </li>
                            <li><input type="button" name="" value="Download CSV" className="CmnBtn" /></li>
                        </ul>
                    </div>
                </>
                }
            />
        </div>
    </div>
  )
}

export default VendorManualAdjustmentsTable;