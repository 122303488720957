import React, {useState, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import {Link, useNavigate} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {getCustomerDetailsApi, getWishlistApi, removeWishlistApi} from "../services/ApiService";
import {Helmet} from "react-helmet";
import {IMAGE_URL} from "../utils/commonUrls";
import { useUserContext } from "../context/UserContext";
import { NotificationManager } from "react-notifications";

const transferIcon2 = require("./../assets/img/car-icon2.svg").default;
const mealIcon2 = require("./../assets/img/restaurant-icon2.svg").default;
const hotelIcon2 = require("./../assets/img/bed-icon2.svg").default;
const optionalIcon2 = require("./../assets/img/optional-icon2.svg").default;
const trainIcon = require("./../assets/img/train.svg").default;
const binocularIcon = require("./../assets/img/binoculars.svg").default;

const MyWishList = () => {
  const navigate = useNavigate();
  const {setWishListCount} = useUserContext();
  const [customerData, setCustomerData] = useState();
  const [wishItemList, setWishItemList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [removeBtnDis, setRemoveBtnDis] = useState(false);

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustomerData(response.data);
        }
      }
    } catch (error) {}
  };

  const getWishlist = async () => {
    setLoading(true);
    try {
      let response = await getWishlistApi();
      if (response) {
        if (response.data.res === true) {
          setWishItemList(response.data.data);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemoveWishlist = async (index, package_id) => {
    // setRemoveBtnDis(true);
    try {
      const formData = new FormData();
      formData.append("package_id", package_id);
      let response = await removeWishlistApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          let tempArray = wishItemList.filter((item, i) => parseInt(i) !== parseInt(index));
          setWishItemList(tempArray);
          setWishListCount(prev => prev - 1);
        }
        setRemoveBtnDis(false);
      }
    } catch (error) {
      setRemoveBtnDis(false);
    }
  };

  const formarDateFunc = date => {
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
    });
    return formattedDate;
  };

  useEffect(() => {
    getCustomerDetails();
    getWishlist();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Wishlist`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Wishlist page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="accountName">
              <h2>
                {customerData?.data?.first_name} {customerData?.data?.last_name}
              </h2>
              <h3>{customerData?.User?.mobile}</h3>
            </div>
            <div className="pointDiv">
              <h2>
                Wishmytour Points <span>0</span>
              </h2>
            </div>

            <div className="breadCmb">
              <p>
                <Link to="/profile">My Account</Link>My Wishlist
              </p>
            </div>

            <div className="upcomingInner">
              <div className="tourListing">
                {wishItemList?.length > 0
                  ? wishItemList?.map((item, index) => (
                      <div className="specialBox" key={index}>
                        <div className="tourBoxImg">
                          <Link to={`/booking/detail/${item?.bookings_id}`}>
                            <img src={IMAGE_URL + item?.first_gallery_image} alt="Image 1" className="specialSliderImg" />
                          </Link>
                        </div>
                        <div className="bannerTxt">
                          {/* <div className="bookingStatus">Booking Pending</div> */}
                          <h2>
                            <Link to={`/booking/detail/${item?.bookings_id}`}>{item?.package_name}</Link>
                          </h2>
                          <h4>{/* By: <span>{item?.vendor_fullname}</span>*/}</h4>
                          <h3>{item?.total_days}</h3>
                          {/* <h3>3 Nights & 4 Days</h3> */}
                          <ul>
                            {item?.is_flight ? (
                              <li>
                                <img src={optionalIcon2} alt="Image 1" />
                                Flight
                              </li>
                            ) : null}
                            {item?.is_train === 1 ? (
                              <li>
                                <img src={trainIcon} alt="Image 1" />
                                Train
                              </li>
                            ) : null}
                            {item?.is_hotel ? (
                              <li>
                                <img src={hotelIcon2} alt="Image 1" />
                                Hotel
                              </li>
                            ) : null}
                            {item?.is_meal ? (
                              <li>
                                <img src={mealIcon2} alt="Image 1" />
                                Meals
                              </li>
                            ) : null}
                            {item?.is_transport ? (
                              <li>
                                <img src={transferIcon2} alt="Image 1" />
                                Transfer
                              </li>
                            ) : null}
                            {item?.is_sightseeing === 1 ? (
                              <li>
                                <img src={binocularIcon} alt="Image 1" />
                                Sightseeing
                              </li>
                            ) : null}
                          </ul>
                          <h5>{/* {formarDateFunc(item?.first_booking_date)} - {formarDateFunc(item?.last_booking_date)} */}</h5>
                          <div class="wishBtns">
                            <button onClick={() => navigate(`/package/${item?.package_id}`)}>View Details</button>
                            {removeBtnDis ? (
                              <button className="rmvBtns" disabled>
                                Remove
                              </button>
                            ) : (
                              <button className="rmvBtns" onClick={() => handleRemoveWishlist(index, item?.package_id)}>
                                Remove
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  : !loading && <p className="noPackageFound">No tours found.</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyWishList;
