import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import {getCmsPageDetailsApi} from "../services/ApiService";
import {Helmet} from "react-helmet";

const CancellationPolicy = () => {
  const [allDetails, setAllDetails] = useState();
  const getCmsPageDetails = async () => {
    try {
      const fd = new FormData();
      fd.append("slug", "cancellation-policy");
      let response = await getCmsPageDetailsApi(fd);
      if (response) {
        setAllDetails(response.data.page);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCmsPageDetails();
  }, []);
  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Cancellation Policy`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Cancellation Policy page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="cmsDiv" dangerouslySetInnerHTML={{__html: allDetails?.content}}></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CancellationPolicy;
