import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  fetchPlacesApi,
  fetchLocationsApi,
  getBudgetRangeApi,
  getCityListApi,
  getComparePackageListApi,
  getDurationListApi,
  getPackageListApi,
  getReligionListApi,
  getStatesListApi,
  getThemesListApi,
  getTotaldaysRangeApi,
  getTransportationListApi,
  getTripListApi,
  getTypeOfTourPackagesListApi,
  onFilterPackagesApi,
  fetchSearchDestinationsApi,
  fetchOriginsApi,
} from "../services/ApiService";
import {IMAGE_URL} from "../utils/commonUrls";
import "react-notifications/lib/notifications.css";
import {NotificationManager} from "react-notifications";
import RangeSliderPriceComponent from "../component/RangeSliderPriceComponent";
import StarRatings from "react-star-ratings";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Typeahead} from "react-bootstrap-typeahead";
import {Helmet} from "react-helmet";
import Skeleton from "@mui/material/Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import {LazyLoadImage} from "react-lazy-load-image-component";

const transferIcon2 = require("./../assets/img/car-icon2.svg").default;
const mealIcon2 = require("./../assets/img/restaurant-icon2.svg").default;
const hotelIcon2 = require("./../assets/img/bed-icon2.svg").default;
const optionalIcon2 = require("./../assets/img/optional-icon2.svg").default;
const special1 = require("./../assets/img/special1.png");
const cross = require("./../assets/img/cross.svg").default;
const trainIcon = require("./../assets/img/train-icon2.svg").default;
const trainIcon2 = require("./../assets/img/train.svg").default;
const binocularIcon = require("./../assets/img/binoculars.svg").default;

const CompareTourListing = () => {
  const location = useLocation();
  const footerRef = useRef(null); // Reference to the footer

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const [tourPackageList, setTourPackageList] = useState([]);
  const [similarTourPackageList, setSimilarTourPackageList] = useState([]);
  const [originList, setOriginList] = useState([]);
  const [checkCompareArray, setCheckCompareArray] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [cityListForOne, setCityListForOne] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [transportationList, setTransportationList] = useState([]);
  const [tripList, setTripList] = useState([
    {
      id: 1,
      name: "Domestic",
    },
    // {
    //   id: 2,
    //   name: "International",
    // },
  ]);
  const [typeOfTourPackagesList, setTypeOfTourPackagesList] = useState([
    {
      id: 1,
      name: "Standard",
    },
    // {
    //   id: 2,
    //   name: "Weekend",
    // },
  ]);
  const [religionList, setReligionList] = useState([]);
  const [themesList, setThemesList] = useState([]);
  const [totalDaysRange, setTotalDaysRange] = useState();
  const [budgetRange, setBudgetRange] = useState();
  const [showCompareButton, setShowCompareButton] = useState(false);
  const [loadingDaysRange, setLoadingDaysRange] = useState(false);
  const [loadingPriceRange, setLoadingPriceRange] = useState(false);
  const [showCompareSection, setShowCompareSection] = useState(false);
  const [visibleStates, setVisibleStates] = useState(5);
  const [visibleCities, setVisibleCities] = useState(5);
  const [visibleBroadLoction, setVisibleBroadLoction] = useState(10);
  const [comparePackageListResp, setComparePackageListResp] = useState([]);
  const [searchValues, setSearchValues] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [fromValue, setFromValue] = useState([]);
  const [destinationValue, setDestinationValue] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [keywordsList, setKeywordsList] = useState([]);
  const [destinationToSearch, setDestinationToSearch] = useState("");
  const [durationToSearch, setDurationToSearch] = useState("");
  const [hotelStarArray, setHotelStarArray] = useState([
    {value: 5, display: "5 Star"},
    {value: 4, display: "4 Star"},
    {value: 3, display: "Up To 3 Star"},
  ]);
  const [filterPackageParameter, setFilterPackageParameter] = useState({
    transportation_id: [],
    filter_origins: [],
    transportation_flight_id: "",
    transportation_train_id: "",
    destination_state_id: [],
    destination_city_id: [],
    hotel_star_id: [],
    religion_id: [],
    trip_id: [],
    type_of_tour_packages_id: [],
    themes_id: [],
    total_days: "",
    starting_price: "",
    slot_price: [],
    location: "",
  });
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [matchNotFound, setMatchNotFound] = useState(false);
  const [trainAvail, setTrainAvail] = useState([
    {name: "With Train", value: 1},
    {name: "Without Train", value: 0},
  ]);
  const [flightAvail, setFlightAvail] = useState([
    {name: "With Flight", value: 1},
    {name: "Without Flight", value: 0},
  ]);

  const getPackageList = async () => {
    setLoading(true);
    try {
      let response = await getPackageListApi();
      if (response) {
        if (response.data.res === true) {
          setTourPackageList(response.data.data);
        } else {
        }
        // setLoading(false);
      }
    } catch {
      // setLoading(false);
    }
    setLoading(false);
  };

  const fetchBroadLocations = async (tempObject, stateIdFromSearch) => {
    try {
      let response = await fetchLocationsApi();
      if (response) {
        if (response.data.res === true) {
          setLocationList(response.data.data);
          let tempSearchValues = sessionStorage.getItem("wishmytourSearchValues");
          const defaultSelectedDestinationObject = response.data.data.find(item => parseInt(item.id) === parseInt(JSON.parse(tempSearchValues)?.destination));
          setDestinationValue([defaultSelectedDestinationObject]);
        } else {
        }
      }
    } catch (error) {}
  };

  const getPlaces = async (fromId, locationId, locationType) => {
    try {
      const formData = new FormData();
      // let tempSearchValues = JSON.parse(sessionStorage.getItem("wishmytourSearchValues"));
      formData.append("from_city_id", fromId);
      formData.append("destination_id", locationId ? locationId : "");
      formData.append("destination_type", locationType ? locationType : "");

      let response = await fetchPlacesApi("", formData);
      if (response) {
        if (response.data.res === true) {
          setKeywordsList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getOrigins = async (fromId, locationId, locationType) => {
    try {
      const formData = new FormData();
      formData.append("from_city_id", fromId);
      formData.append("destination_id", locationId ? locationId : "");
      formData.append("destination_type", locationType ? locationType : "");

      let response = await fetchOriginsApi(formData);
      if (response) {
        if (response.data.res === true) {
          setOriginList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };
  // {item?.total_train_nights_count + (item?.total_train_nights_count > 1 ? " Nights" : " Night")} )
  const formattedStayPlan = (stayPlan, trainNightCount) => (
    <p>
      {trainNightCount ? <span>Train (1N) → </span> : null}
      {stayPlan.map((stayItem, stayIndex) => (
        <span key={stayIndex}>
          {stayItem.city_name} ({stayItem.total_nights}N){stayIndex < stayPlan.length - 1 && " → "}
        </span>
      ))}
    </p>
  );

  const onCompareCheckboxChange = event => {
    const {checked, value} = event.target;
    let tempArray = [...checkCompareArray];
    if (checked) {
      tempArray.push(parseInt(value));
    } else {
      tempArray = tempArray.filter(item => parseInt(item) !== parseInt(value));
    }
    setCheckCompareArray(tempArray);
  };

  const handleOnCompareClick = async () => {
    if (checkCompareArray?.length < 2) {
      NotificationManager.warning("Please select atleast 2 packages for compare.");
    } else if (checkCompareArray?.length > 4) {
      NotificationManager.warning("You can select maximum 4 packages for compare.");
    } else {
      setShowCompareSection(true);
      window.scrollTo({top: 0, behavior: "smooth"});
      try {
        const formData = new FormData();
        checkCompareArray?.map((item, index) => {
          formData.append("package_ids[]", item);
        });
        let response = await getComparePackageListApi(formData);
        if (response) {
          if (response.data.res === true) {
            setComparePackageListResp(response.data.data);
          } else {
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCloseCompare = () => {
    setCheckCompareArray([]);
    setShowCompareSection(false);
    window.scrollTo({top: 0, behavior: "smooth"});
  };

  const handleRemovePackageCompare = index => {
    let tempArray = [...comparePackageListResp];
    tempArray.splice(index, 1);
    if (tempArray.length === 0) {
      setShowCompareSection(false);
    }
    setComparePackageListResp(tempArray);
  };

  const getStateList = async destinationStateId => {
    try {
      let response = await getStatesListApi();
      if (response) {
        if (response.data.res === true) {
          if (destinationStateId) {
            let filteredState = response.data.data.filter(item => parseInt(item?.id) === parseInt(destinationStateId));
            setStateList(filteredState);
          } else {
            setStateList(response.data.data);
          }
        } else {
        }
      }
    } catch (error) {}
  };

  const getCityList = async (tempObject, stateIdFromSearch) => {
    try {
      const formData = new FormData();
      // tempObject.destination_state_id.map((item, index) => {
      //   formData.append("state[]", item);
      // });
      let response = await getCityListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setCityList(response.data.data);
          // if (stateIdFromSearch) {
          //   let tempArray = [];
          //   response.data.data.map(item => {
          //     if (parseInt(item?.state_id) === parseInt(stateIdFromSearch)) {
          //       tempArray.push(item);
          //     }
          //   });
          //   setCityListForOne(tempArray);
          // } else {
          //   setCityListForOne(response.data.data);
          // }
          let tempSearchValues = sessionStorage.getItem("wishmytourSearchValues");
          const defaultSelectedFromObject = response.data.data.find(item => parseInt(item.id) === parseInt(JSON.parse(tempSearchValues)?.from));
          if (defaultSelectedFromObject) {
            setFromValue([defaultSelectedFromObject]);
          }
          // const defaultSelectedDestinationObject = response.data.data.find(item => parseInt(item.id) === parseInt(JSON.parse(tempSearchValues)?.destination));
          // setDestinationValue([defaultSelectedDestinationObject]);
        } else {
        }
      }
    } catch (error) {}
  };

  const getDurationList = async () => {
    try {
      let response = await getDurationListApi();
      if (response) {
        if (response.data.res === true) {
          setDurationList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getTransportationList = async () => {
    try {
      let response = await getTransportationListApi();
      if (response) {
        if (response.data.res === true) {
          setTransportationList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getReligionList = async () => {
    try {
      let response = await getReligionListApi();
      if (response) {
        if (response.data.res === true) {
          setReligionList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getTripList = async () => {
    try {
      let response = await getTripListApi();
      if (response) {
        if (response.data.res === true) {
          // setTripList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getTypeOfTourPackagesList = async () => {
    try {
      let response = await getTypeOfTourPackagesListApi();
      if (response) {
        if (response.data.res === true) {
          // setTypeOfTourPackagesList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getThemesList = async () => {
    try {
      let response = await getThemesListApi();
      if (response) {
        if (response.data.res === true) {
          setThemesList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getTotaldaysRange = async () => {
    setLoadingDaysRange(true);
    try {
      let response = await getTotaldaysRangeApi();
      if (response) {
        if (response.data.res === true) {
          setTotalDaysRange(response.data.data);
        } else {
        }
        setLoadingDaysRange(false);
      }
    } catch (error) {
      setLoadingDaysRange(false);
    }
  };

  const getBudgetRange = async () => {
    setLoadingPriceRange(true);
    try {
      let response = await getBudgetRangeApi();
      if (response) {
        if (response.data.res === true) {
          setBudgetRange(response.data.data);
        } else {
        }
        setLoadingPriceRange(false);
      }
    } catch (error) {
      setLoadingPriceRange(false);
    }
  };

  const handleShowMoreBroadLocation = () => {
    setVisibleBroadLoction(keywordsList.length);
  };

  const handleShowMoreStates = () => {
    setVisibleStates(stateList.length);
  };

  const handleShowLessBroadLocation = () => {
    setVisibleBroadLoction(5);
  };

  const handleShowLessStates = () => {
    setVisibleStates(5);
  };

  const handleShowMoreCities = () => {
    setVisibleCities(cityListForOne.length);
  };

  const handleShowLessCities = () => {
    setVisibleCities(5);
  };

  // const handleStateChange = event => {
  //   const {value, checked} = event.target;
  //   let tempObject = {...filterPackageParameter};
  //   if (checked) {
  //     tempObject.destination_state_id.push(value);
  //   } else {
  //     let tempIndex = tempObject.destination_state_id.findIndex(item => item === value);
  //     tempObject.destination_state_id.splice(tempIndex, 1);
  //   }
  //   handleFilteringPackages(tempObject);
  //   setFilterPackageParameter(tempObject);
  //   getCityList();
  // };

  const handleStateChange = event => {
    const {value, checked} = event.target;
    let tempObject = {...filterPackageParameter};

    if (checked) {
      tempObject = {
        ...tempObject,
        destination_state_id: [...tempObject.destination_state_id, parseInt(value)],
      };
    } else {
      tempObject = {
        ...tempObject,
        destination_state_id: tempObject.destination_state_id.filter(id => parseInt(id) !== parseInt(value)),
      };
    }
    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
    // getCityList(tempObject);
  };

  const handleOriginChange = event => {
    const {value, checked} = event.target;
    let tempObject = {...filterPackageParameter};

    if (checked) {
      tempObject = {
        ...tempObject,
        filter_origins: [...tempObject.filter_origins, parseInt(value)],
      };
    } else {
      tempObject = {
        ...tempObject,
        filter_origins: tempObject.filter_origins.filter(id => parseInt(id) !== parseInt(value)),
      };
    }
    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
    // getCityList(tempObject);
  };

  // const handleCityChange = event => {
  //   const {value, checked} = event.target;
  //   let tempObject = {...filterPackageParameter};
  //   if (checked) {
  //     tempObject.destination_city_id.push(value);
  //   } else {
  //     let tempIndex = tempObject.destination_city_id.findIndex(item => item === value);
  //     tempObject.destination_city_id.splice(tempIndex, 1);
  //   }
  //   handleFilteringPackages(tempObject);
  //   setFilterPackageParameter(tempObject);
  // };

  const handleCityChange = event => {
    const {value, checked} = event.target;
    let tempObject = {...filterPackageParameter};

    if (checked) {
      tempObject = {
        ...tempObject,
        destination_city_id: [...tempObject.destination_city_id, parseInt(value)],
      };
    } else {
      tempObject = {
        ...tempObject,
        destination_city_id: tempObject.destination_city_id.filter(id => parseInt(id) !== parseInt(value)),
      };
    }

    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
  };

  const handleTransportationChange = event => {
    const {value, checked} = event.target;
    let tempObject = {...filterPackageParameter};
    if (checked) {
      tempObject.transportation_id.push(value);
    } else {
      let tempIndex = tempObject.transportation_id.findIndex(item => item === value);
      tempObject.transportation_id.splice(tempIndex, 1);
    }
    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
  };

  const handleTransportationTrainChange = event => {
    // const {value, checked} = event.target;
    let tempObject = {...filterPackageParameter};
    if (parseInt(filterPackageParameter.transportation_train_id) === parseInt(event)) {
      tempObject.transportation_train_id = "";
    } else {
      tempObject.transportation_train_id = event;
    }
    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
  };

  const handleTransportationFlightChange = event => {
    // const {value, checked} = event.target;
    let tempObject = {...filterPackageParameter};
    if (parseInt(filterPackageParameter.transportation_flight_id) === parseInt(event)) {
      tempObject.transportation_flight_id = "";
    } else {
      tempObject.transportation_flight_id = event;
    }
    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
  };

  const handleHotelStarChange = event => {
    const {value, checked} = event.target;
    let tempObject = {...filterPackageParameter};
    if (checked) {
      tempObject.hotel_star_id.push(value);
    } else {
      let tempIndex = tempObject.hotel_star_id.findIndex(item => item === value);
      tempObject.hotel_star_id.splice(tempIndex, 1);
    }
    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
  };

  const handleReligionChange = event => {
    const {value, checked} = event.target;
    let tempObject = {...filterPackageParameter};
    if (checked) {
      tempObject.religion_id.push(value);
    } else {
      let tempIndex = tempObject.religion_id.findIndex(item => item === value);
      tempObject.religion_id.splice(tempIndex, 1);
    }
    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
  };

  const handleTripChange = event => {
    const {value, checked} = event.target;
    let tempObject = {...filterPackageParameter};
    if (checked) {
      tempObject.trip_id.push(value);
    } else {
      let tempIndex = tempObject.trip_id.findIndex(item => item === value);
      tempObject.trip_id.splice(tempIndex, 1);
    }
    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
  };

  const handleTypePackageChange = event => {
    const {value, checked} = event.target;
    let tempObject = {...filterPackageParameter};
    if (checked) {
      tempObject.type_of_tour_packages_id.push(value);
    } else {
      let tempIndex = tempObject.type_of_tour_packages_id.findIndex(item => item === value);
      tempObject.type_of_tour_packages_id.splice(tempIndex, 1);
    }
    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
  };

  // const handleThemesChange = event => {
  //   const {value, checked} = event.target;
  //   let tempObject = {...filterPackageParameter};
  //   if (checked) {
  //     tempObject.themes_id.push(parseInt(value));
  //   } else {
  //     let tempIndex = tempObject.themes_id.findIndex(item => parseInt(item) === parseInt(value));
  //     tempObject.themes_id.splice(tempIndex, 1);
  //   }
  //   handleFilteringPackages(tempObject);
  //   setFilterPackageParameter(tempObject);
  // };

  const handleThemesChange = event => {
    const {value, checked} = event.target;
    let tempObject = {...filterPackageParameter};

    if (checked) {
      tempObject = {
        ...tempObject,
        themes_id: [...tempObject.themes_id, parseInt(value)],
      };
    } else {
      tempObject = {
        ...tempObject,
        themes_id: tempObject.themes_id.filter(id => parseInt(id) !== parseInt(value)),
      };
    }

    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
  };

  const handleSlideChange = (identifier, minValue, maxValue) => {
    let tempObject = {...filterPackageParameter};
    if (identifier === "day") {
      let newValue = minValue + "-" + maxValue;
      tempObject.total_days = newValue;
      handleFilteringPackages(tempObject);
      setFilterPackageParameter(tempObject);
    }
    // else {
    //   let newValue = minValue + "-" + maxValue;
    //   tempObject.starting_price = newValue;
    //   handleFilteringPackages(tempObject);
    //   setFilterPackageParameter(tempObject);
    // }
  };

  const handleSlidePriceChange = (identifier, minValue, maxValue) => {
    let tempObject = {...filterPackageParameter};
    let newValue = minValue + "-" + maxValue;
    tempObject.starting_price = newValue;
    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
  };

  const handleBudgetChange = event => {
    const {value, checked} = event.target;
    let tempObject = {...filterPackageParameter};
    if (checked) {
      tempObject.slot_price.push(value);
    } else {
      let tempIndex = tempObject.slot_price.findIndex(item => item === value);
      tempObject.slot_price.splice(tempIndex, 1);
    }
    handleFilteringPackages(tempObject);
    setFilterPackageParameter(tempObject);
  };

  const handleDestinationInputChange = (text, e) => {
    setDestinationToSearch(text);
  };

  const fetchSearchKeywordsList = async searchTerm => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("search_keyword", searchTerm);
      let response = await fetchSearchDestinationsApi(formData);
      if (response) {
        if (response.data.res === true) {
          setLocationList(response.data.data);
        } else {
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleFilteringPackages = async (filterPackageParameter, retryCount = 0) => {
    try {
      const formData = new FormData();
      filterPackageParameter.themes_id.map((item, index) => {
        formData.append("themes_id[]", item);
      });
      // console.log(filterPackageParameter.destination_city_id)
      // if (filterPackageParameter.destination_city_id?.length > 0) {
      filterPackageParameter.destination_city_id.map((item, index) => {
        formData.append("filter_location", item);
        // formData.append("destination_city_id[]", item);
      });

      filterPackageParameter.filter_origins.map((item, index) => {
        formData.append("filter_origins", item);
        // formData.append("destination_city_id[]", item);
      });
      // }
      filterPackageParameter.destination_state_id.map((item, index) => {
        formData.append("filter_places[]", item);
        // formData.append("filter_keywords[]", item);
        // formData.append("destination_state_id[]", item);
      });
      filterPackageParameter.transportation_id.map((item, index) => {
        formData.append("transportations[]", item);
      });
      formData.append("is_train", filterPackageParameter.transportation_train_id);
      formData.append("is_flight", filterPackageParameter.transportation_flight_id);
      filterPackageParameter.hotel_star_id.map((item, index) => {
        formData.append("hotel_stars[]", item);
      });
      filterPackageParameter.religion_id.map((item, index) => {
        formData.append("religion_id[]", item);
      });
      filterPackageParameter.trip_id.map((item, index) => {
        formData.append("trip_id[]", item);
      });
      filterPackageParameter.type_of_tour_packages_id.map((item, index) => {
        formData.append("type_of_tour_packages_id[]", item);
      });
      formData.append("total_days", filterPackageParameter.total_days);
      if (filterPackageParameter.slot_price?.length === 0) {
        formData.append("starting_price", filterPackageParameter.starting_price);
      }
      filterPackageParameter.slot_price.map((item, index) => {
        formData.append("slot_price[]", item);
      });
      if (filterPackageParameter.location) {
        formData.append("location", filterPackageParameter.location);
      }

      // From search

      let tempSearchValues = JSON.parse(sessionStorage.getItem("wishmytourSearchValues"));
      if (tempSearchValues?.duration) {
        formData.append("duration", tempSearchValues?.duration);
      }
      if (tempSearchValues?.destination) {
        formData.append("destination_id", tempSearchValues?.destination ? tempSearchValues?.destination : "");
        // formData.append("destination_city_id[]", tempSearchValues?.destination);
      }
      if (tempSearchValues?.destinationName) {
        fetchSearchKeywordsList(tempSearchValues?.destinationName);
        let tempObj = {id: tempSearchValues?.destination, name: tempSearchValues?.destinationName, type: tempSearchValues?.destinationType};
        setDestinationValue([tempObj]);
      }
      if (tempSearchValues?.destinationType) {
        formData.append("destination_type", tempSearchValues?.destinationType ? tempSearchValues?.destinationType : "");
        // formData.append("destination_city_id[]", tempSearchValues?.destination);
      }
      if (tempSearchValues?.from) {
        formData.append("from_city_id", tempSearchValues?.from);
      }
      setLoading(true);
      let response = await onFilterPackagesApi(formData);
      if (response) {
        if (response.data.res === true) {
          // if (response.data.data.length > 0) {
          if (response.data.data.has_package_from_city === 0) {
            setMatchNotFound(true);
          } else {
            setMatchNotFound(false);
          }
          setTourPackageList(response.data.data.packages);
          setSimilarTourPackageList(response.data.data.similar_packages);
          // setOriginList(response.data.data.origins);
          // } else {
          //   setMatchNotFound(true);
          // let tempSearchValues = JSON.parse(sessionStorage.getItem("wishmytourSearchValues"));
          // tempSearchValues.from = "";
          // sessionStorage.setItem("wishmytourSearchValues", JSON.stringify(tempSearchValues));
          // if (retryCount < 1) {
          //   await handleFilteringPackages(filterPackageParameter, retryCount + 1);
          // }
          // }
        } else {
        }
      }
    } catch (error) {}
    setLoading(false);
  };

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleModifySearch = () => {
    // if (fromValue?.length === 0 || !destinationToSearch || !durationToSearch) {
    //   NotificationManager.warning("Please select required fields to search.");
    // } else {
    let formatedDate;
    if (selectedDate) {
      formatedDate = new Date(selectedDate).toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      });
    }
    let values = {
      from: fromValue[0]?.id,
      destination: destinationValue[0]?.id,
      destinationStateId: destinationValue[0]?.state_id,
      // destination: destinationToSearch,
      destinationType: destinationValue[0]?.type,
      destinationName: destinationValue[0]?.name,
      month: formatedDate,
      fullDate: selectedDate,
      duration: durationToSearch === "Select Duration(Optional)" ? "" : durationToSearch,
    };
    // getStateList(destinationValue[0]?.state_id);
    let tempObject = {...filterPackageParameter};
    // tempObject.destination_city_id = [parseInt(destinationValue[0]?.id)];
    if (destinationValue[0]?.id) {
      getCityList(tempObject, destinationValue[0]?.id);
    }
    getPlaces(fromValue[0]?.id, destinationValue[0]?.id, destinationValue[0]?.type);
    getOrigins(fromValue[0]?.id, destinationValue[0]?.id, destinationValue[0]?.type);
    sessionStorage.setItem("wishmytourSearchValues", JSON.stringify(values));
    handleFilteringPackages(tempObject);
    // }
  };

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     // console.log(filterPackageParameter.religion_id);
  //     handleFilteringPackages();
  //   }, 100);
  //   return () => clearTimeout(timeoutId);
  // }, [filterPackageParameter]);

  useEffect(() => {
    // getTransportationList();
    // getTripList();
    getReligionList();
    // getTypeOfTourPackagesList();
    getThemesList();
    getTotaldaysRange();
    getBudgetRange();
    getDurationList();
    // fetchBroadLocations();
    let tempSearchValues = JSON.parse(sessionStorage.getItem("wishmytourSearchValues"));
    setSearchValues(tempSearchValues);
    // getStateList(tempSearchValues?.destinationStateId);
    let tempObject = {...filterPackageParameter};
    if (tempSearchValues?.destination) {
      getCityList(tempObject, tempSearchValues?.destination);
    }
    // fetchBroadLocations(tempObject, tempSearchValues?.destinationStateId);
    getPlaces(tempSearchValues?.from, tempSearchValues?.destination, tempSearchValues?.destinationType);
    getOrigins(tempSearchValues?.from, tempSearchValues?.destination, tempSearchValues?.destinationType);
    // if (tempSearchValues?.destinationStateId) {
    //   tempObject.destination_state_id = [tempSearchValues?.destinationStateId];
    //   getCityList(tempObject);
    // } else {
    //   getCityList(tempObject);
    // }

    const queryParams = new URLSearchParams(location.search);
    const locationParam = queryParams.get("location");
    if (locationParam) {
      let tempObject = {...filterPackageParameter};
      tempObject.location = locationParam;
      handleFilteringPackages(tempObject);
      setFilterPackageParameter(tempObject);
    } else {
      if (tempSearchValues?.destination) {
        // tempObject.destination_city_id = [parseInt(tempSearchValues?.destination)];
        handleFilteringPackages(tempObject);
        setFilterPackageParameter(tempObject);
        // } else if (location?.state?.destination_state_id && location?.state?.total_days) {
      } else if (location?.state?.location_id && location?.state?.total_days) {
        let tempObject = {...filterPackageParameter};
        tempObject.destination_city_id.push(parseInt(location?.state?.location_id));
        // tempObject.destination_state_id.push(parseInt(location?.state?.destination_state_id));
        tempObject.total_days = location?.state?.total_days;
        handleFilteringPackages(tempObject);
        setFilterPackageParameter(tempObject);
      } else if (location?.state?.destination_state_id) {
        let tempObject = {...filterPackageParameter};
        tempObject.destination_state_id.push(parseInt(location?.state?.destination_state_id));
        handleFilteringPackages(tempObject);
        setFilterPackageParameter(tempObject);
      } else if (location?.state?.theme_id) {
        let tempObject = {...filterPackageParameter};
        tempObject.themes_id.push(parseInt(location?.state?.theme_id));
        handleFilteringPackages(tempObject);
        setFilterPackageParameter(tempObject);
      } else {
        getPackageList();
      }
    }
    setDestinationToSearch(tempSearchValues?.destination);
    setDurationToSearch(tempSearchValues?.duration);
    if (tempSearchValues?.fullDate) {
      const originalDate = tempSearchValues?.fullDate;
      const dateObject = new Date(originalDate);
      setSelectedDate(dateObject);
    }
  }, []);

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const handleScroll = debounce(() => {
    const threshold = 3000; // Number of pixels above the bottom
    if (document.documentElement.scrollHeight - window.innerHeight - document.documentElement.scrollTop > threshold) return;
    // console.log("first");
  }, 800); // Adjust the delay as needed

  // Set up and tear down the scroll listener
  useEffect(() => {
    const handleScroll = () => {
      if (loading) return;

      const footerHeight = footerRef.current ? footerRef.current.getBoundingClientRect().height : 0;
      const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
      const bottomPosition = document.documentElement.offsetHeight - footerHeight;

      if (scrollPosition >= bottomPosition - 500) {
        console.log("Loading more data...");
      }
    };

    const debounceScroll = () => {
      clearTimeout(window.scrollTimeout);
      window.scrollTimeout = setTimeout(handleScroll, 200);
    };

    window.addEventListener("scroll", debounceScroll);
    return () => window.removeEventListener("scroll", debounceScroll);
  }, [loading]);

  const handleScrollUsingDiv = e => {
    const bottom = Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) - e.target.clientHeight < 50;
    let page = this.state.page;
    if (bottom) {
      // write fetching logic here...
      console.log(1111);
    }
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (destinationToSearch && destinationToSearch.length >= 2) {
        fetchSearchKeywordsList(destinationToSearch);
      } else {
        setLocationList([]);
      }
    }, 300); // 300 ms debounce to minimize API calls

    return () => {
      clearTimeout(delayDebounce);
    };
  }, [destinationToSearch]);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Packages`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Package list page" />
      </Helmet>
      <Header />
      {!showCompareSection ? (
        <div className="innerDiv">
          {searchValues ? (
            <div className="filterDiv">
              <div className="mainDiv">
                <ul>
                  <li>
                    {/* <input type="text" placeholder="From*" value={searchValues?.from} /> */}
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="city"
                      onChange={selected => {
                        setFromValue(selected);
                      }}
                      options={cityList}
                      placeholder="From"
                      selected={fromValue}
                    />
                  </li>
                  <li>
                    {/* <input type="text" placeholder="Destination*" value={destinationToSearch} onChange={e => setDestinationToSearch(e.target.value)} /> */}
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      onChange={selected => {
                        setDestinationValue(selected);
                      }}
                      onInputChange={handleDestinationInputChange}
                      options={locationList}
                      placeholder="Destination"
                      selected={destinationValue}
                    />
                  </li>
                  <li>
                    <select placeholder="Select Duration(Optional)" value={durationToSearch} onChange={e => setDurationToSearch(e.target.value)}>
                      <option value={null}>Select Duration(Optional)</option>
                      {durationList?.map((item, index) => (
                        <option key={index} value={item?.total_days}>
                          {item?.total_days} days
                        </option>
                      ))}
                    </select>
                  </li>
                  <li>
                    {/* <select placeholder="Select Month (Optional)" value={searchValues?.month}>
                    <option>December 2023</option>
                  </select> */}
                    <div className="datePickerSection">
                      <DatePicker
                        placeholderText="Select Month (Optional)"
                        selected={selectedDate}
                        onChange={handleDateChange}
                        showMonthYearPicker
                        dateFormat="MMMM yyyy"
                      />
                    </div>
                  </li>
                  <li>
                    <button onClick={() => handleModifySearch()}>Search</button>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}

          <div className="compareDiv">
            {/* <InfiniteScroll
              dataLength={5}
              next={() => (searchValues ? handleFilteringPackages(filterPackageParameter) : getPackageList())}
              hasMore={true} 
            > */}
            <div className="mainDiv" onScroll={handleScrollUsingDiv}>
              <div className="leftCompare">
                <h2>FILTERS</h2>
                <div className="compareBtn">
                  <h3>Compare Package</h3>

                  {/* {filterPackageParameter?.destination_city_id?.length === 0 && filterPackageParameter?.destination_state_id?.length === 0 && !searchValues ? ( */}
                  {tourPackageList?.length === 0 ? (
                    <div className="checkboxes-and-radios">
                      <input
                        type="checkbox"
                        name="checkbox-cats2"
                        disabled={
                          filterPackageParameter?.destination_city_id?.length === 0 &&
                          filterPackageParameter?.destination_state_id?.length === 0 &&
                          !searchValues
                            ? true
                            : false
                        }
                        id="checkbox-2"
                        value="2"
                        checked={false}
                      />
                      <label for="checkbox-2"></label>
                      {/* <input type="checkbox" name="checkbox-cats3" id="checkbox-3" value="3" checked /> */}
                    </div>
                  ) : (
                    <div className="checkboxes-and-radios">
                      <input
                        type="checkbox"
                        name="checkbox-cats2"
                        // disabled={
                        //   filterPackageParameter?.destination_city_id?.length === 0 &&
                        //   filterPackageParameter?.destination_state_id?.length === 0 &&
                        //   !searchValues
                        //     ? true
                        //     : false
                        // }
                        id="checkbox-2"
                        value="2"
                        checked={showCompareButton ? true : false}
                        onChange={e => setShowCompareButton(e.target.checked)}
                      />
                      <label for="checkbox-2"></label>
                      {/* <input type="checkbox" name="checkbox-cats3" id="checkbox-3" value="3" checked /> */}
                    </div>
                  )}
                </div>

                {originList?.length > 1 ? (
                  <div className="sideFilter">
                    <h3>Origins</h3>
                    <ul>
                      {originList.map((item, index) => (
                        <li key={index}>
                          <input
                            type="checkbox"
                            checked={filterPackageParameter?.filter_origins?.includes(parseInt(item?.id))}
                            value={item?.id}
                            onChange={e => handleOriginChange(e)}
                          />
                          {item?.city}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                <div className="sideFilter">
                  <h3>Places</h3>
                  <ul className={visibleBroadLoction === keywordsList?.length ? "showScroll" : ""}>
                    {keywordsList?.slice(0, visibleBroadLoction)?.map((item, index) => (
                      <li key={index}>
                        <input
                          type="checkbox"
                          checked={filterPackageParameter?.destination_state_id?.includes(parseInt(item?.place_id))}
                          value={item?.place_id}
                          onChange={e => handleStateChange(e)}
                        />
                        {item?.place_name} {item?.package_count ? `(${item?.package_count})` : ""}
                      </li>
                    ))}
                  </ul>

                  {visibleBroadLoction < keywordsList.length && (
                    <button className="showBtn" onClick={handleShowMoreBroadLocation}>
                      Show More
                    </button>
                  )}
                  {visibleBroadLoction === keywordsList.length && (
                    <button className="showBtn" onClick={handleShowLessBroadLocation}>
                      Show Less
                    </button>
                  )}
                </div>

                {/* <div className="sideFilter">
                  <h3>Destination State</h3>
                  {searchValues?.destinationStateId ? (
                    <ul className={visibleStates === stateList.length ? "showScroll" : ""}>
                      {stateList?.slice(0, visibleStates)?.map((item, index) => (
                        <li key={index}>
                          <input type="checkbox" checked disabled value={item?.id} />
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <ul className={visibleStates === stateList.length ? "showScroll" : ""}>
                      {stateList?.slice(0, visibleStates)?.map((item, index) => (
                        <li key={index}>
                          <input
                            type="checkbox"
                            checked={filterPackageParameter?.destination_state_id?.includes(item?.id)}
                            value={item?.id}
                            onChange={e => handleStateChange(e)}
                          />
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  )}

                  {visibleStates < stateList.length && (
                    <button className="showBtn" onClick={handleShowMoreStates}>
                      Show More
                    </button>
                  )}
                  {visibleStates === stateList.length && (
                    <button className="showBtn" onClick={handleShowLessStates}>
                      Show Less
                    </button>
                  )}
                </div> */}

                {/* <div className="sideFilter">
                  <h3>Destination City</h3>
                  <ul className={visibleCities === cityListForOne.length ? "showScroll" : ""}>
                    {cityListForOne?.slice(0, visibleCities)?.map((item, index) => (
                      <li key={index}>
                        <input
                          type="checkbox"
                          checked={filterPackageParameter?.destination_city_id?.includes(parseInt(item?.id))}
                          value={item?.id}
                          onChange={e => handleCityChange(e)}
                        />
                        {item?.city}
                      </li>
                    ))}
                  </ul>
                  {visibleCities < cityListForOne.length && (
                    <button className="showBtn" onClick={handleShowMoreCities}>
                      Show More
                    </button>
                  )}
                  {visibleCities === cityListForOne.length && (
                    <button className="showBtn" onClick={handleShowLessCities}>
                      Show Less
                    </button>
                  )}
                </div> */}

                {/* <div className="sideFilter">
                  <h3>Transportation</h3>
                  <ul>
                    {transportationList?.map((item, index) => (
                      <li key={index}>
                        <input type="checkbox" value={item?.name} onChange={e => handleTransportationChange(e)} />
                        {item?.name}
                      </li>
                    ))}
                  </ul>
                </div> */}

                <div className="sideFilter">
                  <h3>Trains</h3>
                  <ul>
                    {trainAvail?.map((item, index) => (
                      <li key={index} onClick={() => handleTransportationTrainChange(item?.value)}>
                        <input
                          type="radio"
                          name={`trainRadio`}
                          value={item?.value}
                          checked={filterPackageParameter.transportation_train_id === item?.value}
                          // onChange={e => handleTransportationTrainChange(e)}
                        />
                        {item?.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="sideFilter">
                  <h3>Flights</h3>
                  <ul>
                    {flightAvail?.map((item, index) => (
                      <li key={index} onClick={() => handleTransportationFlightChange(item?.value)}>
                        <input
                          type="radio"
                          name={`flightRadio`}
                          value={item?.value}
                          checked={filterPackageParameter.transportation_flight_id === item?.value}
                          // onChange={e => handleTransportationFlightChange(e)}
                        />
                        {item?.name}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="sideFilter">
                  <h3>Hotel Star</h3>
                  <ul>
                    {hotelStarArray?.map((item, index) => (
                      <li key={index}>
                        <input type="checkbox" value={item?.value} onChange={e => handleHotelStarChange(e)} /> {item?.display}
                      </li>
                    ))}
                  </ul>
                </div>

                {/* <div className="sideFilter">
                  <h3>Trip</h3>
                  <ul>
                    {tripList?.map((item, index) => (
                      <li key={index}>
                        <input type="checkbox" value={item?.id} onChange={e => handleTripChange(e)} />
                        {item?.name}
                      </li>
                    ))}
                  </ul>
                </div> */}

                <div className="sideFilter">
                  <h3>Type of Tour Packages</h3>
                  <ul>
                    {typeOfTourPackagesList?.map((item, index) => (
                      <li key={index}>
                        <input type="checkbox" value={item?.id} onChange={e => handleTypePackageChange(e)} />
                        {item?.name}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="sideFilter">
                  <h3>Themes</h3>
                  <ul>
                    {themesList?.map((item, index) => (
                      <li key={index}>
                        <input
                          type="checkbox"
                          value={item?.id}
                          checked={filterPackageParameter?.themes_id?.includes(parseInt(item?.id))}
                          onChange={e => handleThemesChange(e)}
                        />
                        {item?.name}
                      </li>
                    ))}
                  </ul>
                </div>

                {filterPackageParameter.themes_id.includes(5) ? (
                  <div className="sideFilter">
                    <h3>Religion</h3>
                    <ul>
                      {religionList?.map((item, index) => (
                        <li key={index}>
                          <input type="checkbox" value={item?.id} onChange={e => handleReligionChange(e)} />
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                    {/* <Link to="/">Show More</Link> */}
                  </div>
                ) : null}

                <div className="sideFilter">
                  <h3>Days</h3>
                  <div className="daysRange">
                    {!loadingDaysRange ? (
                      <RangeSliderComponent
                        minLimit={parseInt(totalDaysRange?.min_day)}
                        maxLimit={parseInt(totalDaysRange?.max_day)}
                        stepIncrement={1}
                        label="days"
                        identifier="day"
                        handleSlideChange={handleSlideChange}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="sideFilter">
                  <h3>Budget</h3>
                  <div className="budgetRange">
                    {!loadingPriceRange ? (
                      <RangeSliderPriceComponent
                        minLimit={budgetRange?.min_price}
                        maxLimit={budgetRange?.max_price}
                        stepIncrement={1000}
                        label="rupees"
                        identifier="price"
                        handleSlideChange={handleSlidePriceChange}
                      />
                    ) : null}
                  </div>
                  <ul>
                    {budgetRange?.slot_counts?.map((item, index) => (
                      <li key={index}>
                        <input type="checkbox" value={`${item?.start}-${item?.end}`} onChange={e => handleBudgetChange(e)} />₹{item?.start} - ₹{item?.end}{" "}
                        <span>({item?.count})</span>
                      </li>
                    ))}
                    {/* <li>
                        <input type="checkbox" />
                        ₹25,000 <span>(46)</span>
                      </li>
                      <li>
                        <input type="checkbox" />
                        ₹25,000 - ₹35,000 <span>(37)</span>
                      </li>
                      <li>
                        <input type="checkbox" />
                        ₹35,000 - ₹45,000 <span>(34)</span>
                      </li>
                      <li>
                        <input type="checkbox" />
                        ₹45,000 <span>(34)</span>
                      </li> */}
                  </ul>
                  <button className="showBtn">Show More</button>
                </div>
              </div>

              <div className="rightCompare row">
                {loading ? (
                  // <p className="noPackageFound">Loading...</p>

                  Array.from(new Array(6)).map((_, index) => (
                    <div className="compareLoop compareLoopInner col-md-6" key={index}>
                      <div className="specialDiv">
                        <div className="specialBox">
                          <Skeleton key={index} animation="wave" variant="rectangular" width={"100%"} height={271} />
                          <div className="bannerTxt">
                            <h2>
                              <Skeleton key={index} animation="wave" width={200} height={18} />
                            </h2>
                            <h4>
                              <Skeleton key={index} animation="wave" width={140} height={18} />
                            </h4>
                            <h3>
                              <Skeleton key={index} animation="wave" width={100} height={15} />
                            </h3>
                            <h5>
                              <Skeleton key={index} animation="wave" width={100} height={15} />
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : tourPackageList.length > 0 || similarTourPackageList.length > 0 ? (
                  <>
                    {searchValues && matchNotFound && location?.state?.fromSection !== "popularSection" ? (
                      <p className="validationErrorMsg">No matches found for you. You may select from the packages below.</p>
                    ) : null}

                    {tourPackageList?.map((item, index) => (
                      <div className="compareLoop compareLoopInner col-md-6" key={index}>
                        <div className="specialDiv">
                          <div className="specialBox">
                            <Link to={`/package/${item?.package_id}`}>
                              <LazyLoadImage key={index} src={IMAGE_URL + item?.featured_image_path} alt="Image 1" className="specialSliderImg" />
                            </Link>
                            <div className="bannerTxt listingPage">
                              {showCompareButton ? (
                                <div className="compareLabel">
                                  <input type="checkbox" onChange={onCompareCheckboxChange} value={item?.package_id} /> Compare
                                </div>
                              ) : null}
                              <Link to={`/package/${item?.package_id}`} className="productLink">
                                <h2>{item?.package_name}</h2>
                                {/* <h4>{item?.vendor_name ? <>By: <span>{item?.vendor_name}</span></> : null}</h4> */}
                                <h4>
                                  {item?.origin_city_name ? (
                                    <>
                                      {" "}
                                      Starting from: <span>{item?.origin_city_name}</span>{" "}
                                    </>
                                  ) : null}
                                </h4>
                                <h3>
                                  {item?.total_days}{" "}
                                  {/* {item?.total_train_nights_count > 0 ? (
                                    <span>
                                      ( <img src={trainIcon} alt="Image 1" />{" "}
                                      {item?.total_train_nights_count + (item?.total_train_nights_count > 1 ? " Nights" : " Night")} )
                                    </span>
                                  ) : (
                                    ""
                                  )} */}
                                </h3>
                                {formattedStayPlan(item?.stay_plan, item?.total_train_nights_count)}
                                <ul>
                                  {/* {item?.inclusions_in_package?.map((incluItem, incluIndex) => (
                                <li key={incluIndex}>
                                  {incluItem.slug === "transfer" && <img src={transferIcon2} alt="Transfer Icon" />}
                                  {incluItem.slug === "meals" && <img src={mealIcon2} alt="Meals Icon" />}
                                  {incluItem.slug === "flight" && <img src={optionalIcon2} alt="Flight Icon" />}
                                  {incluItem.slug === "hotel" && <img src={hotelIcon2} alt="Flight Icon" />}
                                  {incluItem.name}
                                </li>
                              ))} */}
                                  {item?.is_flight === 1 ? (
                                    <li>
                                      <img src={optionalIcon2} alt="Image 1" />
                                      Flight
                                    </li>
                                  ) : null}
                                  {item?.is_train === 1 ? (
                                    <li>
                                      <img src={trainIcon2} alt="Image 1" />
                                      Train
                                    </li>
                                  ) : null}
                                  {item?.is_hotel === 1 ? (
                                    <li>
                                      <img src={hotelIcon2} alt="Image 1" />
                                      Hotel
                                    </li>
                                  ) : null}
                                  {item?.is_meal === 1 ? (
                                    <li>
                                      <img src={mealIcon2} alt="Image 1" />
                                      Meals
                                    </li>
                                  ) : null}
                                  {item?.is_transport === 1 ? (
                                    <li>
                                      <img src={transferIcon2} alt="Image 1" />
                                      Transfer
                                    </li>
                                  ) : null}
                                  {item?.is_sightseeing === 1 ? (
                                    <li>
                                      <img src={binocularIcon} alt="Image 1" />
                                      Sightseeing
                                    </li>
                                  ) : null}
                                </ul>
                                {/* {formattedStayPlan(item?.stay_plan)} */}
                                <h5>
                                  ₹{item?.starting_price.toLocaleString()} <span>(Starting From)</span>
                                </h5>
                              </Link>
                              <Link to={`/package/${item?.package_id}`}>View Details</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {similarTourPackageList?.length > 0 ? (
                      <>
                        <h3>Similar Packages</h3>
                        {similarTourPackageList?.map((item, index) => (
                          <div className="compareLoop compareLoopInner col-md-6" key={index}>
                            <div className="specialDiv">
                              <div className="specialBox">
                                <Link to={`/package/${item?.package_id}`}>
                                  <LazyLoadImage src={IMAGE_URL + item?.featured_image_path} alt="Image 1" className="specialSliderImg" />
                                </Link>
                                <div className="bannerTxt">
                                  {showCompareButton ? (
                                    <div className="compareLabel">
                                      <input type="checkbox" onChange={onCompareCheckboxChange} value={item?.package_id} /> Compare
                                    </div>
                                  ) : null}
                                  <Link to={`/package/${item?.package_id}`}>
                                    <h2>{item?.package_name}</h2>
                                    <h4>
                                      {item?.origin_city_name ? (
                                        <>
                                          {" "}
                                          Starting from: <span>{item?.origin_city_name}</span>{" "}
                                        </>
                                      ) : null}
                                    </h4>
                                    <h3>
                                      {item?.total_days}{" "}
                                      {/* {item?.total_train_nights_count > 0 ? (
                                        <span>
                                          ( <img src={trainIcon} alt="Image 1" />{" "}
                                          {item?.total_train_nights_count + (item?.total_train_nights_count > 1 ? " Nights" : " Night")} )
                                        </span>
                                      ) : (
                                        ""
                                      )} */}
                                    </h3>
                                    <ul>
                                      {item?.is_flight === 1 ? (
                                        <li>
                                          <img src={optionalIcon2} alt="Image 1" />
                                          Flight
                                        </li>
                                      ) : null}
                                      {item?.is_train === 1 ? (
                                        <li>
                                          <img src={trainIcon2} alt="Image 1" />
                                          Train
                                        </li>
                                      ) : null}
                                      {item?.is_hotel === 1 ? (
                                        <li>
                                          <img src={hotelIcon2} alt="Image 1" />
                                          Hotel
                                        </li>
                                      ) : null}
                                      {item?.is_meal === 1 ? (
                                        <li>
                                          <img src={mealIcon2} alt="Image 1" />
                                          Meals
                                        </li>
                                      ) : null}
                                      {item?.is_transport === 1 ? (
                                        <li>
                                          <img src={transferIcon2} alt="Image 1" />
                                          Transfer
                                        </li>
                                      ) : null}
                                      {item?.is_sightseeing === 1 ? (
                                        <li>
                                          <img src={binocularIcon} alt="Image 1" />
                                          Sightseeing
                                        </li>
                                      ) : null}
                                    </ul>
                                    {formattedStayPlan(item?.stay_plan)}
                                    <h5>
                                      ₹{item?.starting_price.toLocaleString()} <span>(Starting From)</span>
                                    </h5>
                                  </Link>
                                  <Link to={`/package/${item?.package_id}`}>View Details</Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : null}
                  </>
                ) : (
                  <p className="noPackageFound">No Packages Found.</p>
                )}

                {/* <div className="compareLoop">
                  <div className="specialDiv">
                    <div className="specialBox">
                      <img src={special1} alt="Image 1" className="specialSliderImg" />
                      <div className="bannerTxt">
                        <div className="compareLabel">
                          <input type="checkbox" /> Compare
                        </div>
                        <h2>Chadar And Lingshed Village Trek</h2>
                        <h4>
                          By: <span>Ayan Dutta Tour & Traveller</span>
                        </h4>
                        <h3>3 Nights & 4 Days</h3>
                        <ul>
                          <li>
                            <img src={transferIcon2} alt="Image 1" />
                            Transfer
                          </li>
                          <li>
                            <img src={mealIcon2} alt="Image 1" />
                            Meals
                          </li>
                          <li>
                            <img src={hotelIcon2} alt="Image 1" />
                            Hotel
                          </li>
                          <li>
                            <img src={optionalIcon2} alt="Image 1" />
                            Flight
                          </li>
                        </ul>
                        <p>Leh (2N) → Nubra (1N) → Pangong (1N)</p>
                        <h5>
                          ₹15,899 <span>(Starting From)</span>
                        </h5>
                        <Link to="/">View Details</Link>
                      </div>
                    </div>
                  </div>
                </div> */}
                {showCompareButton ? (
                  <div className="comparePackage">
                    <p>{checkCompareArray?.length}/4 Package Selected</p>
                    <button onClick={() => handleOnCompareClick()}>Compare</button>
                  </div>
                ) : null}
              </div>
            </div>
            {/* </InfiniteScroll> */}
          </div>
        </div>
      ) : (
        <div className="innerDiv">
          <div className="mainDiv">
            <div className="compareCross">
              <h2>
                <Link to="javascript:void(0);" onClick={() => handleCloseCompare()}>
                  <img src={cross} alt="" /> Compare
                </Link>
              </h2>
            </div>

            <div className="compareMain">
              {comparePackageListResp?.map((item, index) => (
                <div className="compareList" key={index}>
                  <div className="compareSlider">
                    <Slider {...settings}>
                      {item?.galleryImages?.map((galleryItem, galleryIndex) => (
                        <div key={galleryIndex}>
                          <LazyLoadImage src={IMAGE_URL + galleryItem.path} alt="" />
                        </div>
                      ))}

                      {/* <div>
                        <img src={special1} alt="" />
                      </div> */}
                    </Slider>
                  </div>

                  <div className="compareCaption">
                    <h2>{item?.package_name}</h2>
                    <h3>{/* By: <span>{item?.vendor_name}</span> */}</h3>
                    <div className="ratingCompare">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="112" height="14" viewBox="0 0 112 14" fill="none">
                        <path
                          d="M7.956 0L4.96 3.916L0 5.332L3.108 9.172L3.036 13.968L7.956 12.424L12.876 13.968L12.804 9.168L15.92 5.328L10.956 3.912L7.956 0ZM31.984 0.0319977L29 3.936L24.052 5.348L27.152 9.172L27.08 13.952L31.984 12.412L36.888 13.952L36.816 9.172L39.916 5.348L34.968 3.936L31.984 0.0319977ZM56.028 0L53.032 3.916L48.072 5.332L51.18 9.172L51.108 13.968L56.028 12.424L60.948 13.968L60.876 9.172L63.992 5.332L59.028 3.916L56.028 0ZM80.06 0.0319977L77.076 3.936L72.128 5.348L75.228 9.172L75.156 13.952L80.06 12.412L84.964 13.952L84.892 9.172L87.992 5.348L83.044 3.936L80.06 0.0319977ZM104 0.0319977L101.016 3.936L96.068 5.348L99.168 9.172L99.096 13.952L104 12.412L108.904 13.952L108.832 9.172L111.932 5.348L106.984 3.936L104 0.0319977Z"
                          fill="#F8B84E"
                        />
                      </svg> */}
                      <StarRatings
                        rating={item?.hotel_star ? item?.hotel_star : 0} // Initial rating value
                        starRatedColor="#F8B84E" // Color of the filled stars
                        numberOfStars={5} // Total number of stars
                        name="rating" // Unique name for the component
                        starDimension="18px"
                        starSpacing="2px"
                      />
                    </div>
                    <h4>{item?.total_days}</h4>

                    <h5>
                      Starting From
                      <strong>
                        ₹{parseInt(item?.starting_price).toLocaleString()}
                        <span>(Starting From)</span>
                      </strong>
                    </h5>
                    {item?.stay_plan?.length > 0
                      ? item?.stay_plan?.map((stayItem, stayIndex) => (
                          <p key={stayIndex}>
                            {stayItem?.city_name} ({stayItem?.total_nights}N)
                          </p>
                        ))
                      : null}
                    {/* <p>Leh (2N)</p>
                    <p>Nubra (1N)</p>
                    <p>Pangong (1N)</p> */}
                    <ul>
                      {/* {item?.inclusions_in_package?.map((incluItem, incluIndex) => (
                        <li key={incluIndex}>
                          {incluItem.slug === "transfer" && incluItem.name}
                          {incluItem.slug === "meals" && incluItem.name}
                          {incluItem.slug === "flight" && incluItem.name}
                          {incluItem.slug === "hotel" && incluItem.name}
                        </li>
                      ))} */}

                      {item?.is_flight === 1 && <li>Flight</li>}
                      {item?.is_train === 1 && <li>Train</li>}
                      {item?.is_hotel === 1 && <li>Hotel</li>}
                      {item?.is_meal === 1 && <li>Meals</li>}
                      {item?.is_transport === 1 && <li>Transfer</li>}
                      {item?.is_sightseeing === 1 && <li>Sightseeing</li>}
                    </ul>
                    <Link to={`/package/${item?.package_id}`}>View Details</Link>
                    <button onClick={() => handleRemovePackageCompare(index)}>Remove</button>
                  </div>
                </div>
              ))}
              {/* <div className="compareList">
                <div className="compareSlider">
                  <Slider {...settings}>
                    <div>
                      <img src={special1} alt="" />
                    </div>
                    <div>
                      <img src={special1} alt="" />
                    </div>
                  </Slider>
                </div>

                <div className="compareCaption">
                  <h2>Best Of Ladakh - Standard</h2>
                  <h3>
                    By: <span>Subrata Dutta Tour & Traveller</span>
                  </h3>
                  <div className="ratingCompare">
                    <svg xmlns="http://www.w3.org/2000/svg" width="112" height="14" viewBox="0 0 112 14" fill="none">
                      <path
                        d="M7.956 0L4.96 3.916L0 5.332L3.108 9.172L3.036 13.968L7.956 12.424L12.876 13.968L12.804 9.168L15.92 5.328L10.956 3.912L7.956 0ZM31.984 0.0319977L29 3.936L24.052 5.348L27.152 9.172L27.08 13.952L31.984 12.412L36.888 13.952L36.816 9.172L39.916 5.348L34.968 3.936L31.984 0.0319977ZM56.028 0L53.032 3.916L48.072 5.332L51.18 9.172L51.108 13.968L56.028 12.424L60.948 13.968L60.876 9.172L63.992 5.332L59.028 3.916L56.028 0ZM80.06 0.0319977L77.076 3.936L72.128 5.348L75.228 9.172L75.156 13.952L80.06 12.412L84.964 13.952L84.892 9.172L87.992 5.348L83.044 3.936L80.06 0.0319977ZM104 0.0319977L101.016 3.936L96.068 5.348L99.168 9.172L99.096 13.952L104 12.412L108.904 13.952L108.832 9.172L111.932 5.348L106.984 3.936L104 0.0319977Z"
                        fill="#F8B84E"
                      />
                    </svg>
                  </div>
                  <h4>5 Nights & 6 Days</h4>

                  <h5>
                    Starting From
                    <strong>
                      ₹30,000<span>(Starting From)</span>
                    </strong>
                  </h5>
                  <p>Leh (2N)</p>
                  <p>Nubra (1N)</p>
                  <p>Pangong (1N)</p>
                  <ul>
                    <li>Transfer</li>
                    <li>Meals</li>
                    <li>Hotel</li>
                    <li>Flight</li>
                  </ul>
                  <Link to="/">View Details</Link>
                  <button>Remove</button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}
      {/* <div ref={footerRef}> */}
      <Footer footerRef={footerRef} />
      {/* </div> */}
    </div>
  );
};

export default CompareTourListing;
