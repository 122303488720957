import {createContext, useContext, useState} from "react";

const UserContext = createContext();

export const UserProvider = ({children}) => {
  const [userData, setUserData] = useState(JSON.parse(localStorage?.getItem("WMTUserData")));
  const [wishListCount, setWishListCount] = useState(0);

  return <UserContext.Provider value={{userData, setUserData, wishListCount, setWishListCount}}>{children}</UserContext.Provider>;
};

export function useUserContext() {
  return useContext(UserContext);
}
