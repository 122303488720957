import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useUserContext} from "../context/UserContext";
import {fetchVendorNotificationsListApi, getCustomerDetailsApi, getWishlistCountApi, logoutApi} from "../services/ApiService";
import Dropdown from "react-bootstrap/Dropdown";
import {NotificationManager} from "react-notifications";

const logo = require("./../assets/img/logo.svg").default;
const menuBtn = require("./../assets/img/menu-btn.svg").default;

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {userData, setUserData, wishListCount, setWishListCount} = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [notiResp, setNotiResp] = useState();

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          let tempUserData = JSON.parse(localStorage.getItem("WMTUserData"));
          if (response.data.data.first_name) {
            tempUserData.data.fullname = response.data.data.first_name;
            localStorage.setItem("WMTUserData", JSON.stringify(tempUserData));
            setUserData(tempUserData);
          }
        }
      }
    } catch (error) {}
  };

  const fetchVendorNotificationsList = async () => {
    try {
      let response = await fetchVendorNotificationsListApi();
      if (response) {
        setNotiResp(response.data);
      }
    } catch (error) {}
  };

  const getWishlistCount = async () => {
    try {
      let response = await getWishlistCountApi();
      if (response) {
        if (response.data.res === true) {
          setWishListCount(response.data.count);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1030); // Adjust the breakpoint (768) as needed
    };

    handleResize(); // Set initial width on component mount

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    setUserData(null);
    navigate("/");
    localStorage.removeItem("WMTUserData");
    localStorage.removeItem("WMTLastActivityTimestamp");
    NotificationManager.success("You have been successfully logged out.");
    try {
      let response = await logoutApi();
      if (response) {
        if (response.data.res === true) {
        } else {
        }
      }
    } catch (error) {}
  };

  const checkAndRemoveUserData = () => {
    const lastActivityTimestamp = localStorage.getItem("WMTLastActivityTimestamp");
    if (lastActivityTimestamp && Date.now() - parseInt(lastActivityTimestamp) > 24 * 60 * 60 * 1000) {
      localStorage.removeItem("WMTUserData");
      localStorage.removeItem("WMTLastActivityTimestamp");
      setUserData(null);
      navigate("/");
    }
  };

  useEffect(() => {
    getCustomerDetails();
    getWishlistCount();
    fetchVendorNotificationsList();
    checkAndRemoveUserData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(fetchVendorNotificationsList, 8000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="header">
      <div className="topHeader">
        <div className="mainDiv">
          <div className="innerTop">
            <div className="topLft">
              <p>
                <Link to="/support">Support</Link>
                <Link to="/offers">Offers</Link>
                <Link to="/popular-destination">Plan My Holiday</Link>
                <Link to="/news">News</Link>
              </p>
            </div>
            <div className="topRgt">
              <p>
                Tour Operator? <Link to="/business/login/email">Join Us</Link>
              </p>
              <Link to="/" className="topPhone">
                +91 33 2358 1262
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="mainDiv">
        <div className="bottomHeader">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>

          <div className="nav">
            <button className="menuBtn" onClick={toggleMenu}>
              <img src={menuBtn} alt="" />
            </button>
            <ul style={{display: isOpen || !isMobile ? "block" : "none"}}>
              <li>
                <Link to="/">Family Packages</Link>
              </li>
              <li>
                <Link to="/">Holiday Packages</Link>
              </li>
              <li>
                <Link to="/">Holiday Deals</Link>
              </li>
            </ul>
            <button className="moreBtn">+ More</button>
          </div>

          <div className="headerBtns">
            {userData ? (
              <Link to="/tour/custom" className="customBtn">
                Create Custom Tour
              </Link>
            ) : (
              <a href="javascript:void(0);" className="customBtn" onClick={() => NotificationManager.warning("Please login to create custom tour")}>
                Create Custom Tour
              </a>
            )}

            {!userData ? (
              <Link to="/" state={{from: location.pathname}} className="loginBtn">
                Login/Sign up
              </Link>
            ) : // <Link to="/login" state={{from: location.pathname}} className="loginBtn">
            //   Login/Sign up
            // </Link>
            null}

            {userData ? (
              <Link to="/wishlist" className="wishBtn">
                {wishListCount ? <span>{wishListCount}</span> : null}
              </Link>
            ) : null}

            {userData ? (
              <>
                <div className="customerNoti">
                  <Dropdown className="HeaderDropdown arrowHdr newNotifiaction">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <div className="notifBtn">{notiResp?.unread_count ? <span>{notiResp?.unread_count ? notiResp?.unread_count : 0}</span> : null}</div>
                    </Dropdown.Toggle>
                    {notiResp?.notifications?.length > 0 ? (
                      <Dropdown.Menu>
                        <div className="dropdown-menu-section">
                          {notiResp?.notifications?.map((item, index) => (
                            <p key={index} className="dropdown-item">
                              {item?.message}
                            </p>
                          ))}
                        </div>
                        {userData?.data?.user_type === 4 ? (
                          <Link to={"/partners/notifications"} className="dropdown-item seeAllBtn">
                            See All
                          </Link>
                        ) : (
                          <Link to={"/notifications"} className="dropdown-item seeAllBtn">
                            See All
                          </Link>
                        )}
                      </Dropdown.Menu>
                    ) : null}
                  </Dropdown>
                </div>

                <Dropdown className="HeaderDropdown">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {userData?.data?.user_type === 4 ? (
                      <span className="UserBrand">{userData?.data?.agentFirstName ? userData?.data?.agentFirstName?.charAt(0) : "A"}</span>
                    ) : (
                      <span className="UserBrand">{userData?.data?.fullname ? userData?.data?.fullname?.charAt(0) : "U"}</span>
                    )}

                    {/* <span className="userNeme">{userData?.data?.fullname ? userData?.data?.fullname : "Vendor"}</span> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="topSectionContainer">
                      <div className="topSection">
                        <div>{userData?.data?.fullname?.split(" ")[0]}</div>
                        <div>{userData?.data?.mobile}</div>
                      </div>
                      <span className="points">0</span>
                    </div>

                    {userData?.data?.user_type !== 4 ? (
                      <>
                        <Link to={"/wishlist"} className="my-wishlist-item dropdown-item">
                          My Wishlist
                        </Link>
                        <Link to={"/tour/upcoming"} className="account-settings-item dropdown-item">
                          My Tour
                        </Link>
                        <Link to={"/quotations"} className="quotation-item dropdown-item">
                          Quotation
                        </Link>
                        <Link to={"/profile"} className="account-settings-item dropdown-item">
                          Account Settings
                        </Link>
                        <Dropdown.Item className="my-rewards-item">My Rewards</Dropdown.Item>
                        <Link to={"/notifications"} className="notifications-item dropdown-item">
                          Notifications
                        </Link>
                        {/* <Link to={"/profile/membership"} className="membership-item dropdown-item">Membership</Link> */}
                        <Dropdown.Item className="membership-item">Membership</Dropdown.Item>
                        <Link to={"/payment-history"} className="payment-history-item dropdown-item">
                          Payment History
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to={"/partners/profile"} className="account-settings-item dropdown-item">
                          My Profile
                        </Link>
                      </>
                    )}

                    <button onClick={() => handleLogout()} className="logout-item dropdown-item">
                      Logout
                    </button>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
