import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {changePhoneNumberApi, getCustomerDetailsApi, verifyPhoneNumberApi} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {Helmet} from "react-helmet";

const crossIcon2 = require("./../assets/img/cross.svg").default;

const LoginDetails = () => {
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [customerData, setCustomerData] = useState();
  const [showChangeNumberPopup, setShowChangeNumberPopup] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [existingNumber, setExistingNumber] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [confirmNewNumber, setConfirmNewNumber] = useState("");
  const [existingNumberError, setExistingNumberError] = useState("");
  const [newNumberError, setNewNumberError] = useState("");
  const [confirmNewNumberError, setConfirmNewNumberError] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [timer, setTimer] = useState(60);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [successMsg, setSuccessMsg] = useState("");
  const [recountTimer, setRecountTimer] = useState(false);
  const [changeNumberResponse, setChangeNumberResponse] = useState();

  const closePhonePopup = () => {
    // Reset form and close popup
    setExistingNumber("");
    setNewNumber("");
    setConfirmNewNumber("");
    setExistingNumberError("");
    setNewNumberError("");
    setConfirmNewNumberError("");
    setShowChangeNumberPopup(false);
    setShowOtpPopup(false);
  };

  const handleInputChange = (index, e) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    // Check if all OTP digits are entered
    if (index === inputRefs.length - 1 && newOtp.every(digit => digit !== "")) {
      setValidationError("");
      handleSubmit(newOtp);
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);

      // Set focus on the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4);

    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      for (let i = 0; i < 4; i++) {
        newOtp[i] = pastedData[i] || "";
      }
      return newOtp;
    });

    if (pastedData?.length < 4) {
      inputRefs[pastedData?.length].current.focus();
    } else {
      inputRefs[3].current.focus();
    }

    // Check if all four digits are present in the pasted data
    if (pastedData.length === 4 && pastedData.match(/^\d{4}$/)) {
      let newValue = pastedData.split("");
      handleSubmit(newValue);
    }
  };

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustomerData(response.data);
        }
      }
    } catch (error) {}
  };

  const startTimer = () => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    // Clear the interval after the countdown is complete
    setTimeout(() => {
      clearInterval(countdown);
    }, timer * 1000);
  };

  const handleChangeNumber = async () => {
    // Reset previous errors
    setValidationError("");
    setExistingNumberError("");
    setNewNumberError("");
    setConfirmNewNumberError("");

    // Perform validation
    if (!existingNumber || !/^\d{10}$/.test(existingNumber)) {
      setExistingNumberError("Please enter a valid 10-digit Mobile Number.");
    }
    if (!newNumber || !/^\d{10}$/.test(newNumber)) {
      setNewNumberError("Please enter a valid 10-digit Mobile Number.");
    }
    if (!confirmNewNumber || !/^\d{10}$/.test(confirmNewNumber)) {
      setConfirmNewNumberError("Please enter a valid 10-digit Mobile Number.");
    }

    if (newNumber !== confirmNewNumber) {
      setConfirmNewNumberError("New Number and Confirm Number must match.");
    }

    // If no errors, proceed with OTP generation logic
    if (!existingNumberError && !newNumberError && !confirmNewNumberError) {
      // Your logic to generate OTP goes here
      try {
        setLoading(true);
        let apiFormData = new FormData();
        apiFormData.append("current_phone_number", existingNumber);
        apiFormData.append("new_phone_number", newNumber);
        apiFormData.append("confirm_new_phone_number", confirmNewNumber);
        // Api call
        let response = await changePhoneNumberApi(apiFormData);
        if (response) {
          if (response.data.res === true) {
            // NotificationManager.success(response.data.msg);
            setChangeNumberResponse(response.data.data);
            setShowChangeNumberPopup(false);
            setShowOtpPopup(true);
            setValidationError("");
            startTimer();
          } else {
            setValidationError(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleKeyPress = event => {
    if (event.key === "Enter" && !loading) {
      handleChangeNumber();
    }
  };

  const handleSubmit = async otp => {
    if (otp.every(digit => digit === "")) {
      setValidationError("Please enter Your Otp.");
    } else {
      let newVal = otp.join("");
      setValidationError("");
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("temp_phone_number", changeNumberResponse?.temp_phone_number);
        formData.append("otp", newVal);

        let response = await verifyPhoneNumberApi(formData);
        if (response) {
          if (response.data.res === true) {
            setValidationError("");
            NotificationManager.success(response.data.msg);
            getCustomerDetails();
            closePhonePopup();
          } else {
            setValidationError(response.data.msg);
            setSuccessMsg("");
          }
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (showOtpPopup) {
      inputRefs[0].current.focus();
    }
  }, [showOtpPopup]);

  useEffect(() => {
    getCustomerDetails();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`My Account`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is my account login details page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="accountName">
              <h2>
                {customerData?.data?.first_name} {customerData?.data?.last_name}
              </h2>
              <h3>{customerData?.User?.mobile}</h3>
            </div>
            <div className="pointDiv">
              <h2>
                Wishmytour Points <span>0</span>
              </h2>
            </div>

            <div className="breadCmb">
              <p>
                <Link to="/profile">My Account</Link>Account Settings
              </p>
            </div>

            <div className="upcomingInner">
              <div className="uptab">
                <Link to="/profile">Personal Information</Link>
                <Link to="/profile/login-details" className="activeTab">
                  Login Details
                </Link>
              </div>

              <div className="uptabContent">
                <div className="personalInfo">
                  <h3>
                    Login Details{" "}
                    <span style={{cursor: "pointer"}} onClick={() => setShowChangeNumberPopup(true)}>
                      Change Phone Number?
                    </span>
                  </h3>

                  <ul className="infoForm">
                    <li>
                      <input type="text" readOnly={customerData?.User?.mobile ? true : false} placeholder="Mobile Number" value={customerData?.User?.mobile} />
                    </li>
                    <li>
                      <h5>Verified</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hotelSliderPopup" style={{display: showChangeNumberPopup ? "block" : "none"}}>
        <div className="sliderflex">
          <div className="popInner loginDetails">
            <h2>Change Phone Number</h2>

            <ul>
              <li>
                <input
                  type="text"
                  value={existingNumber}
                  onChange={e => {
                    setExistingNumber(e.target.value);
                    setExistingNumberError("");
                  }}
                  placeholder="Existing Phone Number"
                />
                {existingNumberError && <span className="validationErrorMsg">{existingNumberError}</span>}
              </li>
              <li>
                <input
                  type="text"
                  value={newNumber}
                  onChange={e => {
                    setNewNumber(e.target.value);
                    setNewNumberError("");
                  }}
                  placeholder="Enter New Number"
                />
                {newNumberError && <span className="validationErrorMsg">{newNumberError}</span>}
              </li>
              <li>
                <input
                  type="text"
                  value={confirmNewNumber}
                  onKeyDown={handleKeyPress}
                  onChange={e => {
                    setConfirmNewNumber(e.target.value);
                    setConfirmNewNumberError("");
                  }}
                  placeholder="Confirm New Number"
                />
                {confirmNewNumberError && <span className="validationErrorMsg">{confirmNewNumberError}</span>}
              </li>
              <li className="submitLink">{validationError && <span className="validationErrorMsg">{validationError}</span>}</li>
              <li className="submitLink">
                {loading ? (
                  <button className="submitDropBtn" disabled>
                    Please wait...
                  </button>
                ) : (
                  <button className="submitDropBtn" onClick={handleChangeNumber}>
                    Generate OTP
                  </button>
                )}
              </li>
              
            </ul>

            <button className="closePopupBtn" onClick={closePhonePopup}>
              <img src={crossIcon2} alt="" />
            </button>
          </div>
        </div>
      </div>

      <div className="hotelSliderPopup" style={{display: showOtpPopup ? "block" : "none"}}>
        <div className="sliderflex">
          <div className="popInner loginDetailsOtp loginDiv">
            <h2>OTP Verification</h2>
            <h3>Check text messages for your OTP</h3>
            {/* <h5>Your Otp is - {changeNumberResponse?.otp}</h5> */}
            <ul className="otpList">
              <li>
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={e => handleInputChange(index, e)}
                    onKeyUp={e => handleBackspace(index, e)}
                    onPaste={handlePaste}
                    ref={inputRefs[index]}
                  />
                ))}
              </li>
              <li>00:{parseInt(timer) < 10 ? "0" + timer : timer}</li>
              <li>
                <h4>
                  Not Received OTP?{" "}
                  {parseInt(timer) === 0 ? (
                    <Link to="javascript:void(0);" onClick={() => handleChangeNumber()}>
                      Resend Now
                    </Link>
                  ) : (
                    "Resend Now"
                  )}
                </h4>
                {validationError && <p className="validationErrorMsg">{validationError}</p>}
                {successMsg && <p className="successMsg">{successMsg}</p>}
              </li>
              <li>
                <button onClick={() => handleSubmit(otp)}>Submit</button>
              </li>
            </ul>
            <button className="closePopupBtn" onClick={closePhonePopup}>
              <img src={crossIcon2} alt="" />
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LoginDetails;
