import React, {Component, useState, useRef, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";
import {
  addPackageApi,
  changePackageStatusApi,
  getCityListApi,
  getStatesListApi,
  getThemesListApi,
  getTripListApi,
  getTypeOfTourPackagesListApi,
  vendorPackageListApi,
} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import {Form, Button, Dropdown, Tooltip, OverlayTrigger} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import LoadingPage from "../component/loadingPage";

import dotsImg from "../assets/img/3dots.svg";
const messageIcon = require("./../assets/img/message-icon.svg").default;

const VendorPackageList = () => {
  const navigate = useNavigate();
  const [packageList, setPackageList] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("all");
  const [dynamicTableKey, setDynamicTableKey] = useState(1);
  const [screenLoading, setScreenLoading] = useState(false);

  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);

  const columns = [
    {
      name: "Package Title",
      selector: row => {
        const maxLength = 35; // Maximum number of characters before adding ellipsis
        const packageName = row.package_name;
        const truncatedName = packageName.length > maxLength ? packageName.slice(0, maxLength) + "..." : packageName;

        return (
          <div>
            {row.has_unread_messages === 1 ? (
              // <Link to={`/business/message/${row?.package_id}`}>
              <OverlayTrigger
                placement="top"
                delay={{show: 250, hide: 400}}
                overlay={props => (
                  <Tooltip id="button-tooltip" {...props}>
                    You have a new message.
                  </Tooltip>
                )}>
                <img src={messageIcon} className="msgIcon" alt="" />
                {/* <span className="red-dot-forMsgIndiacte"></span> */}
              </OverlayTrigger>
            ) : // </Link>
            null}
            <span className="tblPackageName">{truncatedName}</span>
          </div>
        );
      },
      sortable: true,
      minWidth: "270px",
      maxWidth: "14%",
    },
    {
      name: "Duration",
      selector: row => row.total_days,
      sortable: true,
      minWidth: "100px",
      maxWidth: "13%",
    },
    {
      name: "Starting Amt.",
      selector: row => row.starting_price,
      sortable: true,
      minWidth: "135px",
      maxWidth: "22%",
    },
    {
      name: "Website Amt.",
      selector: row => row.website_price,
      sortable: true,
      minWidth: "135px",
      maxWidth: "22%",
    },
    {
      name: "Created on",
      selector: row => row.created_at,
      sortable: true,
      minWidth: "130px",
      maxWidth: "17%",
    },
    {
      name: "Origin",
      selector: row => row.origin,
      sortable: true,
      minWidth: "110px",
      maxWidth: "10%",
    },
    {
      name: "Status",
      selector: row => {
        return (
          <div>
            {/* {row.status}{" "} */}
            <span class="checkboxes-and-radios activePackage onList">
              <input
                type="checkbox"
                name="checkbox-cats2"
                id="checkbox-2"
                value="2"
                checked={row?.status === "Active" ? true : false}
                onChange={e =>
                  e.target.checked
                    ? handleActionChangeFromCheckBox("active", "1", row?.package_id)
                    : handleActionChangeFromCheckBox("inactive", "0", row?.package_id)
                }
                // onChange={e => setActivePackage(e.target.checked)}
              />
              <label for="checkbox-2">{row?.status === "Active" ? "Active" : "Inactive"}</label>
              <input type="checkbox" name="checkbox-cats3" id="checkbox-3" value="3" checked />
            </span>
          </div>
        );
      },
      sortable: true,
      minWidth: "75px",
      maxWidth: "12%",
    },
    {
      name: "Action",
      selector: row => row.quoteItems,
      sortable: true,
      minWidth: "50px",
      maxWidth: "12%",
      cell: row =>
        activeTab !== "pending" && !(row?.status === 2 && row?.admin_verified_status === 0 && row?.vendor_verified_status === 0) ? (
          <Dropdown className="AddDropdownBtn">
            <Dropdown.Toggle id="dropdown-basic1">
              <img src={dotsImg} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {activeTab === "accept" ? (
                <>
                  <Link className="dropdown-item" to={`/business/package/edit/${row?.package_id}`}>
                    Accept
                  </Link>
                </>
              ) : row?.status === 2 && row?.admin_verified_status === 0 && row?.vendor_verified_status === 0 ? (
                <></>
              ) : row?.status === 2 && row?.admin_verified_status === 1 && row?.vendor_verified_status === 0 ? (
                <>
                  <Link className="dropdown-item" to={`/business/package/edit/${row?.package_id}`}>
                    Accept
                  </Link>
                </>
              ) : (
                <>
                  <Link className="dropdown-item" to={`/business/package/edit/${row?.package_id}`}>
                    Edit
                  </Link>
                  <Link className="dropdown-item" to={`/vendor-package-preview/${row?.package_id}`}>
                    View
                  </Link>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        ) : null,
    },
  ];

  const data = [
    {
      quoteID: 1157820,
      reatilerName: "Attilio Gatti",
      businessLegalName: "Maroob Perfume Store",
      RetailerInternalID: "Perfume & Cosmetic",
      quoteDate: "Amman",
      totalQuote: "150 orders",
      quoteItems: "1532.00 JOD",
    },
    {
      quoteID: 1157821,
      reatilerName: "M. Hossain",
      businessLegalName: "Qalb Grocery Store",
      RetailerInternalID: "Grocery",
      quoteDate: "Zarqa",
      totalQuote: "298 orders",
      quoteItems: "2117.00 JOD",
    },
    {
      quoteID: 1157822,
      reatilerName: "Firoja Begum",
      businessLegalName: "New Habibi Amman",
      RetailerInternalID: "Perfume & Cosmetic",
      quoteDate: "Na’ur",
      totalQuote: "432 orders",
      quoteItems: "32115.00 JOD",
    },
    {
      quoteID: 1157823,
      reatilerName: "Jafar Javeed",
      businessLegalName: "Jafar & Sons",
      RetailerInternalID: "Grocery",
      quoteDate: "Beit Raus",
      totalQuote: "298 orders",
      quoteItems: "2117.00 JOD",
    },
    {
      quoteID: 1157824,
      reatilerName: "Sk. Sahanawaz",
      businessLegalName: "Big Grocery",
      RetailerInternalID: "Grocery",
      quoteDate: "Amman",
      totalQuote: "732 orders",
      quoteItems: "4117.00 JOD",
    },
    {
      quoteID: 1157825,
      reatilerName: "Makbul Hossain",
      businessLegalName: "Mood Fragrances",
      RetailerInternalID: "Perfume & Cosmetic",
      quoteDate: "Fuheis",
      totalQuote: "385 orders",
      quoteItems: "2536.00 JOD",
    },
    {
      quoteID: 1157826,
      reatilerName: "Irfaan Khan",
      businessLegalName: "Grocery Market Store",
      RetailerInternalID: "Grocery",
      orderDate: "Fuheis",
      totalQuote: "98 orders",
      quoteItems: "186.00 JOD",
    },
    {
      quoteID: 1157827,
      reatilerName: "Rana Al Abiad",
      businessLegalName: "Food&Food Hub",
      RetailerInternalID: "Grocery",
      orderDate: "Beit Raus",
      totalQuote: "315 orders",
      quoteItems: "5123.00 JOD",
    },
  ];

  const getPackageList = async status => {
    if (status === "initial" && setLoading(true));
    setScreenLoading(true);
    try {
      const formData = new FormData();
      {
        status && status !== "initial" && formData.append("status", status ? status : "");
      }

      let response = await vendorPackageListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setPackageList(response.data.data);
        } else {
        }
        setLoading(false);
        setScreenLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setScreenLoading(false);
    }
  };

  // Filter packageList based on the search input
  const filteredPackageList = packageList.filter(item => item?.package_name?.toLowerCase().includes(search?.toLowerCase()));

  const handleCheckBoxChange = state => {
    let tempArray = [];
    if (state?.selectedRows?.length > 0) {
      state?.selectedRows?.map(item => {
        tempArray.push(item?.package_id);
      });
    }
    setSelectedCheckboxIds(tempArray);
  };

  const handleActionChange = async (actionType, status) => {
    setDynamicTableKey(prev => prev + 1);
    try {
      const formData = new FormData();
      if (selectedCheckboxIds?.length > 0) {
        selectedCheckboxIds?.map(item => {
          formData.append("package_ids[]", item);
        });
      }
      formData.append("status", status ? status : "");

      let response = await changePackageStatusApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          if (activeTab === "active" || activeTab === "inactive") {
            const updatedPackageList = packageList.filter(item => !selectedCheckboxIds.includes(parseInt(item.package_id)));
            setPackageList(updatedPackageList);
          } else {
            if (status === "3") {
              const updatedPackageList = packageList.filter(item => !selectedCheckboxIds.includes(parseInt(item.package_id)));
              setPackageList(updatedPackageList);
            } else if (status === "0") {
              const updatedPackageList = packageList.map(item => {
                if (selectedCheckboxIds.includes(parseInt(item.package_id))) {
                  return {...item, status: "Inactive"}; // Assuming 'status' is the property to update
                }
                return item;
              });
              setPackageList(updatedPackageList);
            } else if (status === "1") {
              const updatedPackageList = packageList.map(item => {
                if (selectedCheckboxIds.includes(parseInt(item.package_id))) {
                  return {...item, status: "Active"}; // Assuming 'status' is the property to update
                }
                return item;
              });
              setPackageList(updatedPackageList);
            } else {
            }
          }
          setSelectedCheckboxIds([]);
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {}
  };

  const handleActionChangeFromCheckBox = async (actionType, status, packageId) => {
    console.log(actionType, status, packageId);

    setDynamicTableKey(prev => prev + 1);
    try {
      const formData = new FormData();
      formData.append("package_ids[]", packageId);
      formData.append("status", status ? status : "");

      let response = await changePackageStatusApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);

          if (activeTab === "active" || activeTab === "inactive") {
            const updatedPackageList = packageList.filter(item => parseInt(item.package_id) !== parseInt(packageId));
            setPackageList(updatedPackageList);
          } else {
            const updatedPackageList = packageList.map(item => {
              if (item.package_id === packageId) {
                return {...item, status: status === "1" ? "Active" : "Inactive"}; // Update status directly
              }
              return item;
            });

            setPackageList(updatedPackageList);
          }
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {
      // Handle error if needed
      console.error(error);
    }
  };

  const handleDuplicate = async () => {
    if (selectedCheckboxIds.length > 1) {
      NotificationManager.warning("You have to select only one package to duplicate.");
    } else {
      navigate("/business/package/duplicate/" + selectedCheckboxIds[0]);
    }
  };

  useEffect(() => {
    // Clear selected checkboxes when packageList is updated
    setSelectedCheckboxIds([]);
  }, [packageList]);

  useEffect(() => {
    const result = data.filter(item => {
      //return item.title.toLowerCase().match(search.toLowerCase());
    });
    setFilter(result);
  }, [search]);

  useEffect(() => {
    getPackageList("initial");
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Package List`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Package list page" />
      </Helmet>
      <VenderHeader />
      <div className="venderInnerDiv">
        <VenderSidebar />

        <div className="venderRight">
          {screenLoading && <LoadingPage />}
          <div className="hdrSec">
            <h2>Tour Packages</h2>
            <div className="btnsHdn">
              <button className="saveBtn saveBtn2" onClick={() => navigate("/business/package/add")}>
                Add Tour Packages
              </button>
            </div>
          </div>
          <div className="venderMain venderMain2">
            <ul className="navbar packages-list">
              <li className={activeTab === "all" ? "active" : ""}>
                <a
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("all");
                    getPackageList();
                  }}>
                  All
                </a>
              </li>
              <li className={activeTab === "active" ? "active" : ""}>
                <a
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("active");
                    getPackageList("1");
                  }}>
                  Active
                </a>
              </li>
              <li className={activeTab === "inactive" ? "active" : ""}>
                <a
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("inactive");
                    getPackageList("0");
                  }}>
                  Inactive
                </a>
              </li>
              <li className={activeTab === "pending" ? "active" : ""}>
                <a
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("pending");
                    getPackageList("2");
                  }}>
                  Approval Pending
                </a>
              </li>
              <li className={activeTab === "accept" ? "active" : ""}>
                <a
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("accept");
                    getPackageList("3");
                  }}>
                  Acceptance Pending
                </a>
              </li>
            </ul>
            <div className="data-table-section">
              {!loading ? (
                <DataTable
                  key={activeTab + dynamicTableKey}
                  className="Tbl packages-listing"
                  columns={columns}
                  data={filteredPackageList}
                  fixedHeader
                  //fixedHeaderScrollHeight="300px"
                  highlightOnHover
                  pagination
                  //paginationComponentOptions={paginationComponentOptions}
                  responsive
                  selectableRows
                  selectableRowsHighlight
                  subHeader
                  subHeaderAlign="left"
                  onSelectedRowsChange={handleCheckBoxChange}
                  subHeaderComponent={
                    <>
                      <div className="search-box">
                        <Form.Control type="text" className="SrchInpt" placeholder="Search here" value={search} onChange={e => setSearch(e.target.value)} />
                      </div>
                      <div className="ActionRow">
                        <span className="LftField">
                          <span>Action:</span>{" "}
                          <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 || activeTab === "inactive" ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 || activeTab === "inactive" ? true : false}
                            onClick={() => handleActionChange("inactive", "0")}>
                            Mark Inactive
                          </button>
                          <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 || activeTab === "active" ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 || activeTab === "active" ? true : false}
                            onClick={() => handleActionChange("active", "1")}>
                            Mark Active
                          </button>
                          <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 || selectedCheckboxIds?.length > 1 ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 || selectedCheckboxIds?.length > 1 ? true : false}
                            onClick={() => handleDuplicate()}>
                            Duplicate
                          </button>
                          <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 ? true : false}
                            style={{border: "none"}}
                            onClick={() => handleActionChange("archive", "3")}>
                            Archive
                          </button>
                        </span>
                      </div>
                      {/* <p className='SelectedRow'>08 Selected</p> */}
                    </>
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorPackageList;
