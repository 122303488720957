import axios from "axios";
import {API_BASE_URL} from "../utils/commonUrls";

const getHeader = () => {
  let value = JSON.parse(localStorage.getItem("WMTUserData"));
  let header = {
    headers: {
      Authorization: "Bearer " + value.token,
    },
  };
  return header;
};

export const loginApi = values => {
  return axios.post(API_BASE_URL + `login`, values);
};

export const verifyOtpApi = values => {
  return axios.post(API_BASE_URL + `verify-otp`, values);
};

export const getPackageListApi = values => {
  return axios.post(API_BASE_URL + `get-package-list`, values);
};

export const getBestSellingListApi = values => {
  return axios.post(API_BASE_URL + `bestselling-list`, values);
};

export const getPopularDestinationListApi = values => {
  return axios.post(API_BASE_URL + `popular-destination-list`, values);
};

export const getDestinationsByThemeListApi = values => {
  return axios.post(API_BASE_URL + `destinations-by-theme`, values);
};

export const getBannerPackListApi = values => {
  return axios.post(API_BASE_URL + `get-front-package-list`, values);
};

export const getStatesListApi = values => {
  return axios.post(API_BASE_URL + `state-list`, values);
};

export const getCityListApi = values => {
  return axios.post(API_BASE_URL + `city-list`, values);
};

export const getTransportationListApi = values => {
  return axios.post(API_BASE_URL + `transportation-list`, values);
};

export const getTripListApi = values => {
  return axios.post(API_BASE_URL + `trip-list`, values);
};

export const getReligionListApi = values => {
  return axios.post(API_BASE_URL + `religion-list`, values);
};

export const getTypeOfTourPackagesListApi = values => {
  return axios.post(API_BASE_URL + `type-of-tour-packages-list`, values);
};

export const getThemesListApi = values => {
  return axios.post(API_BASE_URL + `themes-list`, values);
};

export const getTotaldaysRangeApi = values => {
  return axios.post(API_BASE_URL + `totaldays-range`, values);
};

export const getBudgetRangeApi = values => {
  return axios.post(API_BASE_URL + `startingprice-range`, values);
};

export const onFilterPackagesApi = values => {
  return axios.post(API_BASE_URL + `filter-packages`, values);
};

export const getComparePackageListApi = values => {
  return axios.post(API_BASE_URL + `compare-packagelist`, values);
};

export const getDurationListApi = values => {
  return axios.post(API_BASE_URL + `duration-list`, values);
};

export const getPackageDetailsApi = id => {
  return axios.post(API_BASE_URL + `package-details/${id}`);
};

export const getvendorPackageDetailsApi = id => {
  return axios.post(API_BASE_URL + `vendor-package-details/${id}`, {}, getHeader());
};

export const vendorLoginApi = values => {
  return axios.post(API_BASE_URL + `vendor/login`, values);
};

export const vendorVerifyOtpApi = values => {
  return axios.post(API_BASE_URL + `vendor/verify-otp`, values);
};

export const customerRegisterApi = values => {
  return axios.post(API_BASE_URL + `add-customer`, values);
};

export const getCustomerDetailsApi = values => {
  return axios.post(API_BASE_URL + `customer-details-view`, values, getHeader());
};

export const editCustomerDetailsApi = values => {
  return axios.post(API_BASE_URL + `edit-customer`, values, getHeader());
};

export const addPhotoIdApi = values => {
  return axios.post(API_BASE_URL + `add_photo_id`, values, getHeader());
};

export const changePhoneNumberApi = values => {
  return axios.post(API_BASE_URL + `change-phone-number`, values, getHeader());
};

export const verifyPhoneNumberApi = values => {
  return axios.post(API_BASE_URL + `verify-phone-number`, values, getHeader());
};

export const addPackageApi = values => {
  return axios.post(API_BASE_URL + `add-package`, values, getHeader());
};

export const deleteGalleryImageApi = id => {
  return axios.delete(API_BASE_URL + `packages/${id}/gallery-images`, getHeader());
};

export const deleteMediaLinkApi = id => {
  return axios.delete(API_BASE_URL + `packages/${id}/media-link`, getHeader());
};

export const deleteFlightApi = id => {
  return axios.delete(API_BASE_URL + `packages/${id}/flight`, getHeader());
};

export const deleteSightseeingApi = id => {
  return axios.delete(API_BASE_URL + `packages/${id}/sightseeing`, getHeader());
};

export const deleteMealApi = id => {
  return axios.post(API_BASE_URL + `packages/${id}/meal`, {}, getHeader());
};

export const deleteHotelApi = id => {
  return axios.delete(API_BASE_URL + `packages/${id}/hotel`, getHeader());
};

export const deleteHotelGalleryApi = id => {
  return axios.delete(API_BASE_URL + `packages/${id}/hotel-gallery`, getHeader());
};

export const deleteTrainApi = id => {
  return axios.delete(API_BASE_URL + `packages/${id}/train`, getHeader());
};

export const deleteLocalTransportApi = id => {
  return axios.delete(API_BASE_URL + `packages/${id}/local-transport`, getHeader());
};

export const deleteAddonsApi = id => {
  return axios.delete(API_BASE_URL + `packages/${id}/addons`, getHeader());
};

export const deleteInclusionListApi = id => {
  return axios.delete(API_BASE_URL + `packages/${id}/inclusion-list`, getHeader());
};

export const deleteExclusionListApi = id => {
  return axios.delete(API_BASE_URL + `packages/${id}/exclusion-list`, getHeader());
};

export const deleteItineraryApi = id => {
  return axios.delete(API_BASE_URL + `packages/${id}/itinerary`, getHeader());
};

export const editPackageApi = values => {
  return axios.post(API_BASE_URL + `edit-package`, values, getHeader());
};

export const vendorPackageListApi = values => {
  return axios.post(API_BASE_URL + `vendor-package-list`, values, getHeader());
};

export const changePackageStatusApi = values => {
  return axios.post(API_BASE_URL + `mark-status`, values, getHeader());
};

export const vendorDetailsApi = values => {
  return axios.post(API_BASE_URL + `vendor/view-profile`, values, getHeader());
};

export const editVendorDetailsApi = values => {
  return axios.post(API_BASE_URL + `vendor/update-profile`, values, getHeader());
};

export const addVendorBankDetailsApi = values => {
  return axios.post(API_BASE_URL + `vendor/add-bank-details`, values, getHeader());
};

export const exploreDestinationCountsApi = values => {
  return axios.post(API_BASE_URL + `explore-destination-count`, values);
};

export const logoutApi = values => {
  return axios.post(API_BASE_URL + `logout`, values, getHeader());
};

export const addVendorInformationtApi = (values, token) => {
  return axios.post(API_BASE_URL + `vendor/add-details`, values, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const addonListByPackageApi = values => {
  return axios.post(API_BASE_URL + `addon-list-by-package`, values);
};

export const editVendorInformationApi = values => {
  return axios.post(API_BASE_URL + `vendor/update-details`, values, getHeader());
};

export const addBookingApi = values => {
  return axios.post(API_BASE_URL + `add-booking`, values, getHeader());
};

export const upcomingBookingApi = values => {
  return axios.post(API_BASE_URL + `upcoming-booking`, values, getHeader());
};

export const completedBookingApi = values => {
  return axios.post(API_BASE_URL + `completed-booking`, values, getHeader());
};

export const typeOfVendorListApi = values => {
  return axios.post(API_BASE_URL + `type-of-vendor-list`, values);
};

export const tourismCircuitsApi = values => {
  return axios.post(API_BASE_URL + `fetch-tourism-circuits`, values);
};

export const fetchLocationsApi = values => {
  return axios.post(API_BASE_URL + `fetch-regions`, values);
};

export const fetchPlacesApi = (locationId, values) => {
  return axios.post(API_BASE_URL + `${locationId ? `fetch-places/${locationId}` : `fetch-places`}`, values);
};

export const fetchInclusionsApi = () => {
  return axios.get(API_BASE_URL + `fetch-inclusions`);
};

export const fetchExclusionsApi = () => {
  return axios.get(API_BASE_URL + `fetch-exclusions`);
};

export const fetchSearchKeywordsApi = (values, cancelToken) => {
  return axios.post(API_BASE_URL + `search-keywords`, values);
};

export const fetchVendorNotificationsListApi = () => {
  return axios.get(API_BASE_URL + `notifications`, getHeader());
};

export const notificationMarkAsReadsApi = () => {
  return axios.put(API_BASE_URL + `notifications/mark-as-read`, {}, getHeader());
};

export const notificationRemoveAllApi = () => {
  return axios.post(API_BASE_URL + `notifications/remove-all`, {}, getHeader());
};

export const getRelatedPackagesApi = id => {
  return axios.get(API_BASE_URL + `related-packages/${id}`);
};

export const getTaxDetailsApi = values => {
  return axios.post(API_BASE_URL + `taxes`, values);
};

export const getBookingListApi = values => {
  return axios.post(API_BASE_URL + `vendor/booking-list`, values, getHeader());
};

export const getBookingDetailsApi = id => {
  return axios.get(API_BASE_URL + `vendor/booking/${id}/view`, getHeader());
};

export const fetchSearchDestinationsApi = values => {
  return axios.post(API_BASE_URL + `search-destinations`, values);
};

export const agentLoginApi = values => {
  return axios.post(API_BASE_URL + `agent-login`, values);
};

export const agentVerifyOtpApi = values => {
  return axios.post(API_BASE_URL + `agent-verify-otp`, values);
};

export const addAgentApi = values => {
  return axios.post(API_BASE_URL + `add-agent`, values);
};

export const agentDetailsViewApi = values => {
  return axios.post(API_BASE_URL + `agent-details-view`, values, getHeader());
};

export const editAgentDetailsApi = values => {
  return axios.post(API_BASE_URL + `edit-agent`, values, getHeader());
};

export const addBankingAccountAgentApi = values => {
  return axios.post(API_BASE_URL + `add-banking-account-agent`, values, getHeader());
};

export const agentBookingListApi = values => {
  return axios.post(API_BASE_URL + `agent/agent-booking-list`, values, getHeader());
};

export const customerBookingDetailsApi = id => {
  return axios.post(API_BASE_URL + `bookingdetails/${id}/view`, {}, getHeader());
};

export const agentBookingDetailsApi = id => {
  return axios.post(API_BASE_URL + `agent/commissiondetails/${id}/view`, {}, getHeader());
};

export const allCouponsApi = () => {
  return axios.post(API_BASE_URL + `coupons`, {});
};

export const couponsCheckApi = values => {
  return axios.post(API_BASE_URL + `coupons/check`, values);
};

export const vendorCitiesApi = () => {
  return axios.get(API_BASE_URL + `vendor/cities`, getHeader());
};

export const fetchOriginsApi = values => {
  return axios.post(API_BASE_URL + `fetch-origins`, values);
};

export const addWishlistApi = values => {
  return axios.post(API_BASE_URL + `add-wishlist`, values, getHeader());
};

export const getWishlistApi = values => {
  return axios.post(API_BASE_URL + `fetch-wishlist`, values, getHeader());
};

export const removeWishlistApi = values => {
  return axios.post(API_BASE_URL + `remove-wishlist`, values, getHeader());
};

export const getWishlistCountApi = () => {
  return axios.get(API_BASE_URL + `wishlist-count`, getHeader());
};

export const vendorApprovalApi = values => {
  return axios.post(API_BASE_URL + `vendor-approval`, values, getHeader());
};

export const getDetailsFromPincodeApi = code => {
  return axios.get(`https://api.postalpincode.in/pincode/${code}`);
};

export const getFileManagerApi = values => {
  return axios.post(API_BASE_URL + `vendor/file-manager`, values, getHeader());
};

export const cancelBookingApi = values => {
  return axios.post(API_BASE_URL + `cancel-booking`, values, getHeader());
};

export const confirmBookingApi = values => {
  return axios.post(API_BASE_URL + `confirm-booking`, values, getHeader());
};

export const vendorCancelApproveApi = values => {
  return axios.post(API_BASE_URL + `vendor/cancel-approve`, values, getHeader());
};

export const cancelBookingListApi = values => {
  return axios.post(API_BASE_URL + `cancel-booking-list`, values, getHeader());
};

export const addFeedbackApi = values => {
  return axios.post(API_BASE_URL + `add-feedback`, values, getHeader());
};

export const addReportApi = values => {
  return axios.post(API_BASE_URL + `add-report`, values, getHeader());
};

export const addMessageApi = values => {
  return axios.post(API_BASE_URL + `add-message`, values, getHeader());
};

export const getMessageListApi = values => {
  return axios.post(API_BASE_URL + `message-list`, values, getHeader());
};

export const messageViewApi = values => {
  return axios.post(API_BASE_URL + `message-view`, values, getHeader());
};

export const bookingOnRequestApi = values => {
  return axios.post(API_BASE_URL + `send-booking-onrequest`, values, getHeader());
};

export const bookingOnrequestListApi = values => {
  return axios.post(API_BASE_URL + `booking-onrequest-list`, values, getHeader());
};

export const vendorBookingOnrequestListApi = values => {
  return axios.post(API_BASE_URL + `vendor/booking-onrequest-list`, values, getHeader());
};

export const updateOtpApi = values => {
  return axios.post(API_BASE_URL + `vendor/update-otp`, values);
};

export const verifyEmailOrMobileApi = values => {
  return axios.post(API_BASE_URL + `vendor/verify-email-or-mobile`, values);
};

export const getCmsPageDetailsApi = values => {
  return axios.post(API_BASE_URL + `cms-page`, values);
};

export const getCompanyDetailsApi = () => {
  return axios.get(API_BASE_URL + `company-details`);
};

export const submitContactFormApi = values => {
  return axios.post(API_BASE_URL + `submit-contact-form`, values);
};

export const getVendoronRequestDetailsApi = id => {
  return axios.get(API_BASE_URL + `vendor/booking-onrequest-details/${id}`, getHeader());
};

export const getOnRequestDetailsApi = id => {
  return axios.get(API_BASE_URL + `booking-onreq-details/${id}`, getHeader());
};

export const vendorOnRequestApproveApi = values => {
  return axios.post(API_BASE_URL + `vendor/booking-onrequest-approve`, values, getHeader());
};

export const agentCommissionListApi = values => {
  return axios.post(API_BASE_URL + `agent/agent-commission-list`, values, getHeader());
};

export const agentPaymentHistoryDetailsApi = values => {
  return axios.post(API_BASE_URL + `agent/agent-payment-history-by-invoice`, values, getHeader());
};

export const addBookingPaymentApi = values => {
  return axios.post(API_BASE_URL + `add-booking-payment`, values, getHeader());
};

export const agentBookingCommissionClaimApi = id => {
  return axios.post(API_BASE_URL + `agent/agent-booking/${id}/claim`, {}, getHeader());
};

export const agentPaymentHistoryApi = id => {
  return axios.post(API_BASE_URL + `agent/agent-payment-history`, {}, getHeader());
};

export const customerPaymentHistoryApi = values => {
  return axios.post(API_BASE_URL + `payment-history`, values, getHeader());
};

export const gstDetailsApi = (gstNumber, token) => {
  return axios.get(
    API_BASE_URL + `vendor/gst-details/${gstNumber}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};
