import React, {Component, useState, useRef, useEffect} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {NotificationManager} from "react-notifications";
import {useLocation, useNavigate} from "react-router";
import {Helmet} from "react-helmet";

import GreenCircleTickIcon from "../assets/img/green-circle-tick-icon.svg";

const PackageBooked = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const downloadFile = url => {
    // const url = "https://abwebx.com/updates/wishmytour/backend/storage/app/public/bookingPdf/provisional_confirmation_1714476910.pdf";
    fetch(url)
      .then(response => {
        // Convert the response to a blob
        return response.blob();
      })
      .then(blob => {
        // Create a URL for the blob
        const blobUrl = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = "provisional_confirmation.pdf"; // Set the filename for the downloaded file

        // Append the anchor to the body
        document.body.appendChild(a);

        // Trigger the click event
        a.click();

        // Remove the anchor from the body
        document.body.removeChild(a);
      })
      .catch(error => {
        console.error("Error downloading PDF:", error);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      // navigate('/tour/upcoming')
    }, 3000);
  }, []);

  // useEffect(() => {
  //   let WMTBookingObj = JSON.parse(sessionStorage.getItem("WMTBookingObj"));
  //   if (!WMTBookingObj?.selectedTourStartDate) {
  //     navigate("/");
  //   } else {
  //   }
  // }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Package Booked`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Package Booked page" />
      </Helmet>
      <Header />
      <div className="innerDiv">
        <div className="UserAfterLogin">
          <div className="container">
            <div className="CongratulationsBox">
              <img src={GreenCircleTickIcon} alt="" />
              <h3>Congratulations!</h3>
              <p>Your Payment has been recieved you will be notified upon confirmation</p>
              <p>
                Please{" "}
                {/* <a href={'javascript:void(0);'} onClick={() => downloadFile(location?.state?.pdfUrl?.pdfFilePath)}>
                  Click Here!
                </a>{" "} */}
                <a href={location?.state?.pdfUrl?.pdfFilePath} download="provisional_confirmation.pdf" target="_blank">
                  Click Here!
                </a>{" "}
                to get your provisional booking confirmation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PackageBooked;
