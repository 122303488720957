import React, {Component, useState, useRef, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";

import {NotificationManager} from "react-notifications";
import {useNavigate, useParams} from "react-router";
import {Helmet} from "react-helmet";
import {Form, Button, Dropdown} from "react-bootstrap";
import DataTable from "react-data-table-component";
import dotsImg from "../assets/img/3dots.svg";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {getBookingDetailsApi, getTaxDetailsApi} from "../services/ApiService";
import {useStateManager} from "react-select";
import AgentHeader from "../component/Agent-Header";
import AgentSideBar from "../component/Agent-Sidebar";
import {useUserContext} from "../context/UserContext";

const arrow = require("./../assets/img/left-arrow.svg").default;
const reviewImg = require("./../assets/img/review-img.svg").default;

const AgentBookingDetails = () => {
  const navigate = useNavigate();
  const {bookingId} = useParams();
  const {userData} = useUserContext();

  const [allDetails, setAllDetails] = useState();
  const [taxDetails, setTaxDetails] = useState();
  const [priceDetails, setPriceDetails] = useState({
    basePrice: 0,
    addOnPrice: 0,
    taxPrice: 0,
    gstTax: 0,
    tcsTax: 0,
    gstTaxIncludePrice: 0,
    tcsTaxIncludePrice: 0,
    bulkDiscountPrice: 0,
    subTotalPrice: 0,
    platformCharges: 0,
    platformChargesIncludePrice: 0,
    netPayAmt: 0,
  });
  const [passengersArray, setPassengersArray] = useState([]);

  const formatDateFormat = date => {
    const originalDateString = date;

    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);

    // Months array for mapping month indexes to month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get the month, day, and year from the parsed date
    const monthIndex = originalDate.getMonth();
    const day = originalDate.getDate();
    const year = originalDate.getFullYear();

    // Format the date string
    const formattedDateString = `${months[monthIndex]} ${day}, ${year}`;
    return formattedDateString;
  };

  const formatDateFormat1 = date1 => {
    const dateString = date1;

    const date = new Date(dateString);

    // Format the date as "January 26, 2024"
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

    // Format the time as "05:00 AM"
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;
    return formattedDate;
  };

  const formatTimeFormat1 = date1 => {
    const dateString = date1;

    const date = new Date(dateString);

    // Format the date as "January 26, 2024"
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

    // Format the time as "05:00 AM"
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;

    return formattedTime;
  };

  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - dob.getFullYear();

    // Check if the birthday hasn't occurred yet in the current year
    if (currentDate.getMonth() < dob.getMonth() || (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  }

  const getBookingDetails = async () => {
    try {
      let response = await getBookingDetailsApi(bookingId);
      if (response) {
        if (response.data.res === true) {
          setAllDetails(response.data.data);
          setPriceDetails(prev => ({
            ...prev,
            basePrice: response.data.data.booking?.base_amount,
          }));

          let tempArray = [];
          response.data.data.booking_rooms.map((roomItem, roomIndex) => {
            const roomData = {room: "Room " + (roomIndex + 1), adults: [], children: []};
            response.data.data.booking_passengers.map((passItem, passIndex) => {
              if (roomItem.id === passItem.booking_room_id) {
                const passenger = {
                  title: passItem.title,
                  first_name: passItem.first_name,
                  last_name: passItem.last_name,
                  dob: passItem.dob,
                  gender: passItem.gender,
                };

                if (passItem.is_adult === 1) {
                  roomData.adults.push(passenger);
                } else {
                  roomData.children.push(passenger);
                }
              }
            });
            tempArray.push(roomData);
          });

          setPassengersArray(tempArray);

          let basePrice = response.data.data.booking?.base_amount;
          let addOnPrice = 0;
          let taxes = 0;
          let gstTax = 0;
          let tcsTax = 0;
          let taxIncludePrice = 0;
          let gstTaxIncludePrice = 0;
          let tcsTaxIncludePrice = 0;
          let bulkDiscountPrice = 0;
          let subTotal = 0;
          let platformCharges = 0;
          let platformChargesIncludePrice = 0;
          let netPayAmt = 0;

          try {
            const formData = new FormData();
            formData.append("package_id", response.data.data.booking.package_id);
            let response1 = await getTaxDetailsApi(formData);
            if (response1 && response1.data.res === true) {
              setTaxDetails(response1.data.data);
              gstTax = response1.data.data.gst.value ? parseInt(response1.data.data.gst.value) : 0;
              tcsTax = response1.data.data.tcs.value ? parseInt(response1.data.data.tcs.value) : 0;
              platformCharges = response1.data.data.platform_charges.value ? parseInt(response1.data.data.platform_charges.value) : 0;
            }
          } catch (error) {
            console.error("Error fetching package details:", error);
          }

          taxes = gstTax + tcsTax;
          if (taxes > 0) {
            taxIncludePrice = parseFloat(basePrice) + (parseFloat(basePrice) * taxes) / 100;
          }
          if (gstTax > 0) {
            gstTaxIncludePrice = (parseFloat(basePrice) * gstTax) / 100;
          }
          if (tcsTax > 0) {
            tcsTaxIncludePrice = (parseFloat(basePrice) * tcsTax) / 100;
          }

          subTotal = parseFloat(taxes ? taxIncludePrice : basePrice);

          console.log('subTotal', subTotal);

          platformChargesIncludePrice = (parseFloat(subTotal) * platformCharges) / 100;

          netPayAmt = parseFloat(subTotal) - parseFloat(platformChargesIncludePrice);

          setPriceDetails(prev => ({
            ...prev,
            basePrice: parseFloat(basePrice).toFixed(2),
            taxPrice: taxes,
            gstTax: gstTax,
            tcsTax: tcsTax,
            gstTaxIncludePrice: parseFloat(gstTaxIncludePrice).toFixed(2),
            tcsTaxIncludePrice: parseFloat(tcsTaxIncludePrice).toFixed(2),
            subTotalPrice: parseFloat(subTotal).toFixed(2),
            platformCharges: platformCharges,
            platformChargesIncludePrice: parseFloat(platformChargesIncludePrice).toFixed(2),
            netPayAmt: parseFloat(netPayAmt).toFixed(2),
          }));
        } else {
        }
      }
    } catch (error) {}
  };

  const generateInvoice = pdfUrl => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "Booking_Invoice.pdf";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("PDF URL is not available");
    }
  };

  useEffect(() => {
    getBookingDetails();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Booking Details`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Booking Details page" />
      </Helmet>
      <AgentHeader />
      <div className="venderInnerDiv">
        <AgentSideBar />

        <div className="venderRight">
          <div className="hdrSec">
            <h2>
              <Button variant="link" onClick={() => navigate("/partners/bookings")}>
                <img src={arrow} alt="" />
              </Button>
              Booking Details
            </h2>
          </div>
          <div className="bookMainDiv">
            <div className="bookLeft">
              <div className="bookInner">
                <h2>Basic Details</h2>
                <ul className="bookLst">
                  <li>
                    <label>Package ID:</label>
                    <span className="rdTagClr">#{allDetails?.booking?.package_id}</span>
                  </li>
                  <li>
                    <label>Tour Package:</label>
                    <span>
                      <Link to="">{allDetails?.booking?.vendor_fullname}</Link>
                    </span>
                  </li>
                  <li>
                    <label>Customer Name:</label>
                    <span>{allDetails?.booking?.booking_customer_name}</span>
                  </li>
                  <li>
                    <label>Pan Number:</label>
                    <span>{allDetails?.booking?.booking_customer_pan_number}</span>
                  </li>
                  <li>
                    <label>Email:</label>
                    <span>
                      <Link to={`mailto:${allDetails?.booking?.booking_customer_email}`}>{allDetails?.booking?.booking_customer_email}</Link>
                    </span>
                  </li>
                  <li>
                    <label>Phone:</label>
                    <span>{allDetails?.booking?.booking_customer_phone_number}</span>
                  </li>
                  <li>
                    <label>Address:</label>
                    <span>{allDetails?.booking?.booking_customer_address}</span>
                  </li>
                  <li>
                    <label>State:</label>
                    <span>{allDetails?.booking?.booking_customer_state}</span>
                  </li>
                  {/* <li>
                    <label>City:</label>
                    <span>Kolkata</span>
                  </li>
                  <li>
                    <label>Zip code:</label>
                    <span>711110</span>
                  </li> */}
                  <li>
                    <label>Tour Start Date:</label>
                    <span>{formatDateFormat(allDetails?.booking?.first_booking_date)}</span>
                  </li>
                  <li>
                    <label>Tour End Date:</label>
                    <span>{formatDateFormat(allDetails?.booking?.last_booking_date)}</span>
                    {/* <span>February 03, 2024</span> */}
                  </li>
                </ul>
              </div>

              <div className="bookInner">
                <h2>Passenger Details</h2>
                {passengersArray?.map((item, index) => (
                  <div key={index}>
                    <h3>{item?.room}</h3>
                    {item?.adults?.map((adultItem, adultIndex) => (
                      <>
                        <h3>Adult {adultIndex + 1}</h3>
                        <ul className="bookLst">
                          <li>
                            <label>Name:</label>
                            <span>
                              {adultItem?.title} {adultItem?.first_name} {adultItem?.last_name}
                              {/* <span className="canBtn">CANCELLED</span> */}
                            </span>
                          </li>
                          <li>
                            <label>Age:</label>
                            <span>{calculateAge(adultItem?.dob)} years old</span>
                          </li>
                          {/* <li>
                            <label>Age:</label>
                            <span>42 years old</span>
                          </li> */}
                          <li>
                            <label>Gender:</label>
                            <span style={{textTransform: "capitalize"}}>{adultItem?.gender}</span>
                          </li>
                        </ul>
                      </>
                    ))}
                    {item?.children?.map((childItem, childIndex) => (
                      <>
                        <h3>Children {childIndex + 1}</h3>
                        <ul className="bookLst">
                          <li>
                            <label>Name:</label>
                            <span>
                              {childItem?.title} {childItem?.first_name} {childItem?.last_name}
                              {/* <span className="canBtn">CANCELLED</span> */}
                            </span>
                          </li>
                          <li>
                            <label>Age:</label>
                            <span>{calculateAge(childItem?.dob)} years old</span>
                          </li>
                          {/* <li>
                            <label>Age:</label>
                            <span>42 years old</span>
                          </li> */}
                          <li>
                            <label>Gender:</label>
                            <span style={{textTransform: "capitalize"}}>{childItem?.gender}</span>
                          </li>
                        </ul>
                      </>
                    ))}
                  </div>
                ))}
                {/* <h3>Adult 1</h3>
                <ul className="bookLst">
                  <li>
                    <label>Name:</label>
                    <span>Mr. Arnab Ganguly</span>
                  </li>
                  <li>
                    <label>Age:</label>
                    <span>42 years old</span>
                  </li>
                  <li>
                    <label>Gender:</label>
                    <span>Male</span>
                  </li>
                </ul>

                <h3>Adult 2</h3>
                <ul className="bookLst">
                  <li>
                    <label>Name:</label>
                    <span>
                      Mr. Arnab Ganguly
                    </span>
                  </li>
                  <li>
                    <label>Age:</label>
                    <span>42 years old</span>
                  </li>
                  <li>
                    <label>Gender:</label>
                    <span>Male</span>
                  </li>
                </ul>

                <h3>Children</h3>
                <ul className="bookLst">
                  <li>
                    <label>Name:</label>
                    <span>Mr. Arnab Ganguly</span>
                  </li>
                  <li>
                    <label>Age:</label>
                    <span>5 years old</span>
                  </li>
                  <li>
                    <label>Gender:</label>
                    <span>Male</span>
                  </li>
                </ul> */}
              </div>

              {allDetails?.booking_flight?.length > 0 || allDetails?.booking_train?.length > 0 || allDetails?.booking_LocalTransport?.length > 0 ? (
                <div className="bookInner">
                  <h2>Transportation Details</h2>
                  {allDetails?.booking_flight?.length > 0 ? (
                    <>
                      <h3>FLIGHT</h3>
                      <ul className="bookLst">
                        {/* <li>
                        <label>Airline:</label>
                        <span>Air India</span>
                      </li>
                      <li>
                        <label>Flight Number:</label>
                        <span>852456753159</span>
                      </li>
                      <li>
                        <label>Class:</label>
                        <span>Economy</span>
                      </li> */}
                        <li>
                          <label>Departure:</label>
                          <span>{allDetails?.booking_flight[0]?.depart_destination}</span>
                        </li>
                        <li>
                          <label>Arrival:</label>
                          <span>{allDetails?.booking_flight[0]?.arrive_destination}</span>
                        </li>
                        <li>
                          <label>Departure Date:</label>
                          <span>{formatDateFormat1(allDetails?.booking_flight[0]?.depart_datetime)}</span>
                        </li>
                        <li>
                          <label>Departure Time:</label>
                          <span>{formatTimeFormat1(allDetails?.booking_flight[0]?.depart_datetime)}</span>
                        </li>
                        <li>
                          <label>Arrival Date:</label>
                          <span>{formatDateFormat1(allDetails?.booking_flight[0]?.arrive_datetime)}</span>
                        </li>
                        <li>
                          <label>Arrival Time:</label>
                          <span>{formatTimeFormat1(allDetails?.booking_flight[0]?.arrive_datetime)}</span>
                        </li>
                        {/* <li>
                        <label>Stoppage:</label>
                        <span>Non Stop</span>
                      </li> */}
                      </ul>
                    </>
                  ) : null}

                  {allDetails?.booking_train?.length > 0 ? (
                    <>
                      <h3>TRAIN</h3>
                      <ul className="bookLst">
                        <li>
                          <label>Train Name:</label>
                          <span>{allDetails?.booking_train[0]?.train_name}</span>
                        </li>
                        <li>
                          <label>Train Number:</label>
                          <span>{allDetails?.booking_train[0]?.train_number}</span>
                        </li>
                        <li>
                          <label>Class:</label>
                          <span>{allDetails?.booking_train[0]?.class}</span>
                        </li>
                        <li>
                          <label>Departure:</label>
                          <span>{allDetails?.booking_train[0]?.from_station}</span>
                        </li>
                        <li>
                          <label>Arrival:</label>
                          <span>{allDetails?.booking_train[0]?.to_station}</span>
                        </li>
                        <li>
                          <label>Departure Date:</label>
                          <span>{formatDateFormat1(allDetails?.booking_train[0]?.depart_datetime)}</span>
                        </li>
                        <li>
                          <label>Departure Time:</label>
                          <span>{formatTimeFormat1(allDetails?.booking_train[0]?.depart_datetime)}</span>
                        </li>
                        <li>
                          <label>Arrival Date:</label>
                          <span>{formatDateFormat1(allDetails?.booking_train[0]?.arrive_datetime)}</span>
                        </li>
                        <li>
                          <label>Arrival Time:</label>
                          <span>{formatTimeFormat1(allDetails?.booking_train[0]?.arrive_datetime)}</span>
                        </li>
                      </ul>
                    </>
                  ) : null}

                  {allDetails?.booking_LocalTransport?.length > 0 ? (
                    <>
                      <h3>LOCAL TRANSPORT</h3>
                      <ul className="bookLst">
                        <li>
                          <label>Type:</label>
                          <span>{allDetails?.booking_LocalTransport[0]?.car}</span>
                        </li>
                        <li>
                          <label>Model:</label>
                          <span>{allDetails?.booking_LocalTransport[0]?.model}</span>
                        </li>
                        <li>
                          <label>Capacity:</label>
                          <span>{allDetails?.booking_LocalTransport[0]?.capacity}</span>
                        </li>
                        <li>
                          <label>Air Condition:</label>
                          <span>{parseInt(allDetails?.booking_LocalTransport[0]?.AC) === 1 ? "Yes" : "No"}</span>
                        </li>
                      </ul>
                    </>
                  ) : null}
                </div>
              ) : null}

              {allDetails?.packageAddon?.length > 0 ? (
                <>
                  <div className="bookInner">
                    <h2>Add Ons</h2>

                    {allDetails?.packageAddon?.map((item, index) => (
                      <>
                        <h3>{item?.title}</h3>
                        <ul className="bookLst">
                          <li>
                            <label>Price:</label>
                            <span>₹{item?.price} INR</span>
                          </li>
                          <li>
                            <label>Description:</label>
                            <span>{item?.description}</span>
                          </li>
                        </ul>
                      </>
                    ))}

                    {/* <h3>ADD ON TITLE GOES HERE</h3>
                <ul className="bookLst">
                  <li>
                    <label>Price:</label>
                    <span>₹500 INR</span>
                  </li>
                  <li>
                    <label>Description:</label>
                    <span>Lorem Ipsum is a simple dummy text for typing</span>
                  </li>
                </ul> */}
                  </div>
                </>
              ) : null}
            </div>

            <div className="bookRight">
              <div className="bookInner">
                <h2>Booking Information</h2>

                <ul className="bookLst">
                  <li>
                    <label>Booking ID:</label>
                    <span className="rdTagClr">#{allDetails?.booking?.id}</span>
                  </li>
                  <li>
                    <label>Booking Date:</label>
                    <span>{formatDateFormat(allDetails?.booking?.created_at)}</span>
                    {/* <span>Jan 05, 2024</span> */}
                  </li>
                  <li>
                    <label>Status:</label>
                    <span className="rdTagClr" style={{textTransform: "capitalize"}}>
                      {allDetails?.booking?.booking_status}
                    </span>
                  </li>
                  <li>
                    <button onClick={() => generateInvoice(allDetails?.pdfFilePath)}>Generate Invoice</button>
                  </li>
                </ul>
              </div>

              <div className="bookInner">
                <h2>Payment Information </h2>

                <ul className="bookLst">
                  <li>
                    <label>Tour Package Cost</label>
                    <span>₹{priceDetails.basePrice}</span>
                  </li>
                  {/* <li>
                    <label>Platform Charges (2%)</label>
                    <span>₹00,000</span>
                  </li> */}
                  {priceDetails?.gstTax > 0 ? (
                    <li>
                      <label>GST Addition ({priceDetails?.gstTax}%)</label>
                      <span>₹{priceDetails?.gstTaxIncludePrice}</span>
                    </li>
                  ) : null}

                  {priceDetails?.tcsTax > 0 ? (
                    <li>
                      <label>TCS ({priceDetails?.tcsTax}%)</label>
                      <span>₹{priceDetails?.tcsTaxIncludePrice}</span>
                    </li>
                  ) : null}
                  <li className="grssLine">
                    <label>
                      <strong>Gross Due</strong>
                    </label>
                    <span>₹{priceDetails?.subTotalPrice}</span>
                  </li>
                  <li>
                    <strong>Redemption to Vendor</strong>
                  </li>
                  <li>
                    <label>Platform Charges({priceDetails?.platformCharges}%)</label>
                    <span>(-) ₹{priceDetails?.platformChargesIncludePrice}</span>
                  </li>
                  <li>
                    <label>GST TDS</label>
                    <span>(-) ₹00,000</span>
                  </li>
                  <li>
                    <label>TCS</label>
                    <span>(-) ₹00,000</span>
                  </li>
                  <li>
                    <label>Vendor TDS (10%)</label>
                    <span>(-) ₹00,000</span>
                  </li>
                  <li className="grssLine">
                    <label>
                      <strong>Net Payment Due</strong>
                    </label>
                    <span>₹{priceDetails?.netPayAmt}</span>
                  </li>
                </ul>
              </div>

              <div className="bookInner">
                <h2>Client Review</h2>

                <div className="rvwImg">
                  <img src={reviewImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentBookingDetails;
