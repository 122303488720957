import React from "react";
import loadingGif from "../assets/img/rolling-loader.gif";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

const LoadingPage = () => {
  return (
    <div className="loader-container">
      {/* <div className="loader">
        <img src={loadingGif}></img>
      </div> */}
      <Backdrop sx={{color: "#B50102"}} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default LoadingPage;
