import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {bookingOnrequestListApi, getCustomerDetailsApi, upcomingBookingApi} from "../services/ApiService";
import {Helmet} from "react-helmet";
import {IMAGE_URL} from "../utils/commonUrls";
import {Form, Button, Dropdown, Tabs, Tab, Modal} from "react-bootstrap";

const transferIcon2 = require("./../assets/img/car-icon2.svg").default;
const mealIcon2 = require("./../assets/img/restaurant-icon2.svg").default;
const hotelIcon2 = require("./../assets/img/bed-icon2.svg").default;
const optionalIcon2 = require("./../assets/img/optional-icon2.svg").default;
const special1 = require("./../assets/img/special1.png");
const trainIcon = require("./../assets/img/train.svg").default;
const binocularIcon = require("./../assets/img/binoculars.svg").default;

const QuotationList = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("tab2");
  const {from} = useLocation().state ?? {};

  const [customerData, setCustomerData] = useState();
  const [packageList, setPackageList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [searchPressed, setSearchPressed] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustomerData(response.data);
        }
      }
    } catch (error) {}
  };

  const handleSearchInputChange = event => {
    setSearchQuery(event.target.value);
  };

  const performSearch = () => {
    setSearchPressed(true);
    const filteredPackageList = packageList.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
    setSearchResult(filteredPackageList);
  };

  const getOnrequestBooking = async () => {
    setLoading(true);
    try {
      let response = await bookingOnrequestListApi();
      if (response) {
        if (response.data.res === true) {
          setPackageList(response.data.data);
          setSearchResult(response.data.data);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const formarDateFunc = date => {
    const originalDateString = date;

    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);

    // Months array for mapping month indexes to month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get the month, day, and year from the parsed date
    const monthIndex = originalDate.getMonth();
    const day = originalDate.getDate();
    const year = originalDate.getFullYear();

    // Format the date string
    const formattedDateString = `${day < 10 ? "0" + day : day} ${months[monthIndex]},  ${year}`;
    return formattedDateString;
  };

  useEffect(() => {
    getCustomerDetails();
    getOnrequestBooking();
    // if (from === "onRequest") {
    //   setKey("tab2");
    // } else {
    //   setKey("tab1");
    // }
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Quotations`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Quotation List Tour page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="accountName">
              <h2>
                {customerData?.data?.first_name} {customerData?.data?.last_name}
              </h2>
              <h3>{customerData?.User?.mobile}</h3>
            </div>
            <div className="pointDiv">
              <h2>
                Wishmytour Points <span>0</span>
              </h2>
            </div>

            <div className="breadCmb">
              <p>
                <Link to="/profile">My Account</Link>Quotation
              </p>
            </div>

            <div className="upcomingInner">
              <Tabs id="controlled-tabs" activeKey={key} onSelect={k => setKey(k)}>
                <Tab eventKey="tab1" title="Custom Package">
                  <div className="QuotationListTableSec">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Quote ID</th>
                          <th>Destination</th>
                          <th>Tour Start Date</th>
                          <th>Tour End Date</th>
                          <th>Pax</th>
                          <th>Budget</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>#852654</td>
                          <td>Shimla Manali</td>
                          <td>March 06, 2024</td>
                          <td>March 12, 2024</td>
                          <td>12</td>
                          <td>86,000</td>
                          <td>
                            <span className="SendSpan">Send</span>
                          </td>
                        </tr>
                        <tr>
                          <td>#9632581</td>
                          <td>Goa</td>
                          <td>February 19, 2024</td>
                          <td>February 24, 2024</td>
                          <td>03</td>
                          <td>27,000</td>
                          <td>
                            <span className="ReceivedSpan">Received</span>
                          </td>
                        </tr>
                        <tr>
                          <td>#9641375</td>
                          <td>Vizag</td>
                          <td>March 06, 2024</td>
                          <td>March 12, 2024</td>
                          <td>12</td>
                          <td>86,000</td>
                          <td>
                            <span className="SendSpan">Send</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab>
                <Tab eventKey="tab2" title="On Request Date">
                  <div className="QuotationListTableSec">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Quote ID</th>
                          <th>Package Name</th>
                          {/* <th>Destination</th> */}
                          <th>Tour Start Date</th>
                          <th>Tour End Date</th>
                          <th>Pax</th>
                          <th>Budget</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {packageList?.length > 0 ? (
                          packageList?.map((item, index) => (
                            <tr key={index} style={{cursor: "pointer"}} onClick={() => navigate(`/quotation/${item?.id}`)}>
                              <td>#{item?.id}</td>
                              <td>{item?.package_name}</td>
                              <td>{formarDateFunc(item?.start_date)}</td>
                              <td>{formarDateFunc(item?.end_date)}</td>
                              <td>{item?.pax < 10 ? "0" + item.pax : item.pax}</td>
                              <td>{item?.final_price ? item?.final_price : '-'}</td>
                              <td>{item?.status === 0 ? <span className="SendSpan">Send</span> : ""}</td>
                            </tr>
                          ))
                        ) : !loading ? (
                          <tr>
                            <td colSpan={7} style={{textAlign: "center"}}>
                              No packages found.
                            </td>
                          </tr>
                        ) : null}
                        {/* <tr>
                          <td>#852654</td>
                          <td>Shimla Manali</td>
                          <td>March 06, 2024</td>
                          <td>March 12, 2024</td>
                          <td>12</td>
                          <td>86,000</td>
                          <td>
                            <span className="SendSpan">Send</span>
                          </td>
                        </tr>
                        <tr>
                          <td>#9632581</td>
                          <td>Goa</td>
                          <td>February 19, 2024</td>
                          <td>February 24, 2024</td>
                          <td>03</td>
                          <td>27,000</td>
                          <td>
                            <span className="ReceivedSpan">Received</span>
                          </td>
                        </tr>
                        <tr>
                          <td>#9641375</td>
                          <td>Vizag</td>
                          <td>March 06, 2024</td>
                          <td>March 12, 2024</td>
                          <td>12</td>
                          <td>86,000</td>
                          <td>
                            <span className="SendSpan">Send</span>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default QuotationList;
