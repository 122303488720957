import React, {Component, useState, useRef, useEffect} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {Link, useNavigate} from "react-router-dom";

const destination1 = require("./../assets/img/destination1.png");

const PopularDestination = () => {
  return ( 
    <div className="main">
      <Header />

      <div className="innerDiv">
        <div className="destinationDiv pplrDstn">
          <div className="mainDiv">
            <h2>
              Popular <span>Destination</span>
            </h2>
            <p>We have selected some best locations around the world for you.</p>
            <ul>
              <li>
                <Link to="">
                  <img src={destination1} alt="" />
                  <h3>London</h3>
                </Link>
              </li>
              <li>
                <Link to="">
                  <img src={destination1} alt="" />
                  <h3>London</h3>
                </Link>
              </li>
              <li>
                <Link to="">
                  <img src={destination1} alt="" />
                  <h3>London</h3>
                </Link>
              </li>
              <li>
                <Link to="">
                  <img src={destination1} alt="" />
                  <h3>London</h3>
                </Link>
              </li>
              <li>
                <Link to="">
                  <img src={destination1} alt="" />
                  <h3>London</h3>
                </Link>
              </li>
              <li>
                <Link to="">
                  <img src={destination1} alt="" />
                  <h3>London</h3>
                </Link>
              </li>
            </ul>
            <Link to="/packages" className="viewBtn">
              Load More
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PopularDestination;
