import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";

const Header = () => {
  const location = useLocation();
  return (
    <div className="venderSidebar">
      <ul>
        <li className={location.pathname === "/business/profile" || location.pathname.includes("/business/profile/edit") ? "active" : ""}>
          <Link to="/business/profile">Settings</Link>
        </li>
        <li
          className={
            location.pathname === "/business/packages" ||
            location.pathname === "/business/package/add" ||
            location.pathname.includes("/edit-package") ||
            location.pathname.includes("/vendor-message") ||
            location.pathname.includes("/vendor-package-preview") ||
            location.pathname.includes("/business/package/edit/preview") 
              ? "active"
              : ""
          }>
          <Link to="/business/packages">Tour Packages</Link>
        </li>
        <li className={location.pathname === "/business/bookings" || location.pathname.includes("/business/booking/") ? "active" : ""}>
          <Link to="/business/bookings">Your Bookings</Link>
        </li>
        <li className={location.pathname === "/business/custom-tours" ? "active" : ""}>
          <Link to="/business/custom-tours">Custom Tour Quotation</Link>
        </li>
        <li>
          <Link to="">Reports</Link>
        </li>
        <li>
          <Link to="">Promotion</Link>
        </li>
      </ul>
    </div>
  );
};

export default Header;
