import React, { Component, useState, useRef, useEffect } from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const checkIcon = require("./../assets/img/check-icon.svg").default;
const crossIcon = require("./../assets/img/cross-icon.svg").default;

const Quotation = () => {
  return (
    <div className="main">
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="accountName">
              <h2>Subhendu Bhattacharjee</h2>
              <h3>7980267218</h3>
            </div>
            <div className="pointDiv">
              <h2>
                Wishmytour Points <span>100</span>
              </h2>
            </div>

            <div className="breadCmb">
              <p>
                <Link to="/">My Account</Link>Membership
              </p>
            </div>

            <div className="upcomingInner">
              <div className="memberSec">
                <h2>
                  Bronze Membership <Link to="">Upgrade</Link>
                </h2>
                <h3>Free</h3>

                <ul>
                  <li>
                    <img src={checkIcon} alt="" /> Lorem Ipsum is simply
                  </li>
                  <li>
                    <img src={crossIcon} alt="" /> Contrary to popular{" "}
                  </li>
                  <li>
                    <img src={checkIcon} alt="" /> There are many
                  </li>
                  <li>
                    <img src={checkIcon} alt="" /> It is a long established
                  </li>
                  <li>
                    <img src={checkIcon} alt="" /> The standard chunk
                  </li>
                  <li>
                    <img src={crossIcon} alt="" /> The generated Lorem{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Quotation;
