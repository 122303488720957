import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useUserContext} from "../context/UserContext";
import {fetchVendorNotificationsListApi, logoutApi, vendorDetailsApi} from "../services/ApiService";
import Dropdown from "react-bootstrap/Dropdown";

import logo from "./../assets/img/logo.svg";
import {NotificationManager} from "react-notifications";

const Header = props => {
  const {userData, setUserData} = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [notiResp, setNotiResp] = useState();

  const getVendorDetails = async () => {
    try {
      let response = await vendorDetailsApi();
      if (response) {
        if (response.data.res === true) {
          if (location.pathname !== "/business/profile/edit" && location.pathname !== "/business/notifications") {
            if (
              !response.data.data.bank_account_number ||
              !response.data.user.email ||
              !response.data.user.mobile ||
              (response.data.data.have_gst === 1 && !response.data.data.gst_rate)
            ) {
              navigate("/business/profile");
              if (
                location.pathname !== "/business/profile" &&
                location.pathname !== "/business/profile/edit" &&
                location.pathname !== "/business/notifications"
              ) {
                NotificationManager.warning("Please complete your profile first.");
              }
            } else {
              if (response.data?.data.bank_account_number && parseInt(response.data.data.bank_verified) !== 1) {
                navigate("/business/profile");
                if (
                  location.pathname !== "/business/profile" &&
                  location.pathname !== "/business/profile/edit" &&
                  location.pathname !== "/business/notifications"
                ) {
                  NotificationManager.warning("Your bank details have not been verified yet.");
                }
              } else if (response.data?.data.is_verified !== 1) {
                navigate("/business/profile");
                if (
                  location.pathname !== "/business/profile" &&
                  location.pathname !== "/business/profile/edit" &&
                  location.pathname !== "/business/notifications"
                ) {
                  NotificationManager.warning("Your account have not been verified yet.");
                }
              } else {
              }
            }
          }
          let tempUserData = JSON.parse(localStorage.getItem("WMTUserData"));
          if (response.data.data.name) {
            tempUserData.data.name = response.data.data.name;

            localStorage.setItem("WMTUserData", JSON.stringify(tempUserData));
            setUserData(tempUserData);
          }
        } else {
        }
      }
    } catch (error) {}
  };

  const fetchVendorNotificationsList = async () => {
    try {
      let response = await fetchVendorNotificationsListApi();
      if (response) {
        setNotiResp(response.data);
        props?.setCheckIsVerified(response.data.is_verified);
      }
    } catch (error) {}
  };

  const handleLogout = async () => {
    setUserData(null);
    navigate("/");
    localStorage.removeItem("WMTUserData");
    localStorage.removeItem("WMTLastActivityTimestamp");
    NotificationManager.success("You have been successfully logged out.");
    try {
      let response = await logoutApi();
      if (response) {
        if (response.data.res === true) {
        } else {
        }
      }
    } catch (error) {}
  };

  const checkAndRemoveUserData = () => {
    const lastActivityTimestamp = localStorage.getItem("WMTLastActivityTimestamp");
    if (lastActivityTimestamp && Date.now() - parseInt(lastActivityTimestamp) > 24 * 60 * 60 * 1000) {
      localStorage.removeItem("WMTUserData");
      localStorage.removeItem("WMTLastActivityTimestamp");
      setUserData(null);
      navigate("/");
    }
  };

  useEffect(() => {
    getVendorDetails();
    fetchVendorNotificationsList();
    checkAndRemoveUserData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(fetchVendorNotificationsList, 8000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="venderHeader">
      <div className="logoLft">
        <Link to={"/business/packages"}>
          <img src={logo} alt="" />
        </Link>
      </div>

      <div className="hdrRgt notificationHdr">
        <Dropdown className="HeaderDropdown arrowHdr newNotifiaction">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div className="notifBtn">{notiResp?.unread_count ? <span>{notiResp?.unread_count ? notiResp?.unread_count : 0}</span> : null}</div>
          </Dropdown.Toggle>
          {notiResp?.notifications?.length > 0 ? (
            <Dropdown.Menu>
              <div className="dropdown-menu-section">
                {notiResp?.notifications?.map((item, index) => (
                  <p key={index} className="dropdown-item">
                    {item?.message}
                  </p>
                ))}
              </div>
              <Link to={"/business/notifications"} className="dropdown-item">
                See All
              </Link>
            </Dropdown.Menu>
          ) : null}
        </Dropdown>

        <div className="topAcct">
          {/* <img src={accountImg} alt="" />
          Alexander */}
          <Dropdown className="HeaderDropdown">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <span className="UserBrand">{userData?.data?.name ? userData?.data?.name?.charAt(0) : "V"}</span>{" "}
              <span className="userNeme">{userData?.data?.name ? userData?.data?.name : "Vendor"}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item className="my-wishlist-item">My Wishlist</Dropdown.Item>
              <Dropdown.Item className="my-tour-item">My Tour</Dropdown.Item>
              <Dropdown.Item className="quotation-item">Quotation</Dropdown.Item> */}
              <Link to={"/business/profile"} className="account-settings-item dropdown-item">
                Account Settings
              </Link>
              {/* <Dropdown.Item className="my-rewards-item">My Rewards</Dropdown.Item>
              <Dropdown.Item className="notifications-item">Notifications</Dropdown.Item>
              <Dropdown.Item className="membership-item">Membership</Dropdown.Item>
              <Dropdown.Item className="payment-history-item">Payment History</Dropdown.Item> */}
              <button onClick={() => handleLogout()} className="logout-item dropdown-item">
                Logout
              </button>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
