import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Helmet} from "react-helmet";

const special1 = require("./../assets/img/special1.png");
const cross = require("./../assets/img/cross.svg").default;

const Compare = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Compare`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is compare page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="compareCross">
            <h2>
              <Link to="/">
                <img src={cross} alt="" /> Compare
              </Link>
            </h2>
          </div>

          <div className="compareMain">
            <div className="compareList">
              <div className="compareSlider">
                <Slider {...settings}>
                  <div>
                    <img src={special1} alt="" />
                  </div>
                  <div>
                    <img src={special1} alt="" />
                  </div>
                </Slider>
              </div>

              <div className="compareCaption">
                <h2>Best Of Ladakh - Standard</h2>
                <h3>
                  {/* By: <span>Subrata Dutta Tour & Traveller</span> */}
                </h3>
                <div className="ratingCompare">
                  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="14" viewBox="0 0 112 14" fill="none">
                    <path
                      d="M7.956 0L4.96 3.916L0 5.332L3.108 9.172L3.036 13.968L7.956 12.424L12.876 13.968L12.804 9.168L15.92 5.328L10.956 3.912L7.956 0ZM31.984 0.0319977L29 3.936L24.052 5.348L27.152 9.172L27.08 13.952L31.984 12.412L36.888 13.952L36.816 9.172L39.916 5.348L34.968 3.936L31.984 0.0319977ZM56.028 0L53.032 3.916L48.072 5.332L51.18 9.172L51.108 13.968L56.028 12.424L60.948 13.968L60.876 9.172L63.992 5.332L59.028 3.916L56.028 0ZM80.06 0.0319977L77.076 3.936L72.128 5.348L75.228 9.172L75.156 13.952L80.06 12.412L84.964 13.952L84.892 9.172L87.992 5.348L83.044 3.936L80.06 0.0319977ZM104 0.0319977L101.016 3.936L96.068 5.348L99.168 9.172L99.096 13.952L104 12.412L108.904 13.952L108.832 9.172L111.932 5.348L106.984 3.936L104 0.0319977Z"
                      fill="#F8B84E"
                    />
                  </svg>
                </div>
                <h4>5 Nights & 6 Days</h4>

                <h5>
                  Starting From
                  <strong>
                    ₹30,000<span>(Starting From)</span>
                  </strong>
                </h5>
                <p>Leh (2N)</p>
                <p>Nubra (1N)</p>
                <p>Pangong (1N)</p>
                <ul>
                  <li>Transfer</li>
                  <li>Meals</li>
                  <li>Hotel</li>
                  <li>Flight</li>
                </ul>
                <Link to="/">View Details</Link>
                <button>Remove</button>
              </div>
            </div>

            <div className="compareList">
              <div className="compareSlider">
                <Slider {...settings}>
                  <div>
                    <img src={special1} alt="" />
                  </div>
                  <div>
                    <img src={special1} alt="" />
                  </div>
                </Slider>
              </div>

              <div className="compareCaption">
                <h2>Best Of Ladakh - Standard</h2>
                <h3>
                  {/* By: <span>Subrata Dutta Tour & Traveller</span> */}
                </h3>
                <div className="ratingCompare">
                  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="14" viewBox="0 0 112 14" fill="none">
                    <path
                      d="M7.956 0L4.96 3.916L0 5.332L3.108 9.172L3.036 13.968L7.956 12.424L12.876 13.968L12.804 9.168L15.92 5.328L10.956 3.912L7.956 0ZM31.984 0.0319977L29 3.936L24.052 5.348L27.152 9.172L27.08 13.952L31.984 12.412L36.888 13.952L36.816 9.172L39.916 5.348L34.968 3.936L31.984 0.0319977ZM56.028 0L53.032 3.916L48.072 5.332L51.18 9.172L51.108 13.968L56.028 12.424L60.948 13.968L60.876 9.172L63.992 5.332L59.028 3.916L56.028 0ZM80.06 0.0319977L77.076 3.936L72.128 5.348L75.228 9.172L75.156 13.952L80.06 12.412L84.964 13.952L84.892 9.172L87.992 5.348L83.044 3.936L80.06 0.0319977ZM104 0.0319977L101.016 3.936L96.068 5.348L99.168 9.172L99.096 13.952L104 12.412L108.904 13.952L108.832 9.172L111.932 5.348L106.984 3.936L104 0.0319977Z"
                      fill="#F8B84E"
                    />
                  </svg>
                </div>
                <h4>5 Nights & 6 Days</h4>

                <h5>
                  Starting From
                  <strong>
                    ₹30,000<span>(Starting From)</span>
                  </strong>
                </h5>
                <p>Leh (2N)</p>
                <p>Nubra (1N)</p>
                <p>Pangong (1N)</p>
                <ul>
                  <li>Transfer</li>
                  <li>Meals</li>
                  <li>Hotel</li>
                  <li>Flight</li>
                </ul>
                <Link to="/">View Details</Link>
                <button>Remove</button>
              </div>
            </div>

            <div className="compareList">
              <div className="compareSlider">
                <Slider {...settings}>
                  <div>
                    <img src={special1} alt="" />
                  </div>
                  <div>
                    <img src={special1} alt="" />
                  </div>
                </Slider>
              </div>

              <div className="compareCaption">
                <h2>Best Of Ladakh - Standard</h2>
                <h3>
                  {/* By: <span>Subrata Dutta Tour & Traveller</span> */}
                </h3>
                <div className="ratingCompare">
                  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="14" viewBox="0 0 112 14" fill="none">
                    <path
                      d="M7.956 0L4.96 3.916L0 5.332L3.108 9.172L3.036 13.968L7.956 12.424L12.876 13.968L12.804 9.168L15.92 5.328L10.956 3.912L7.956 0ZM31.984 0.0319977L29 3.936L24.052 5.348L27.152 9.172L27.08 13.952L31.984 12.412L36.888 13.952L36.816 9.172L39.916 5.348L34.968 3.936L31.984 0.0319977ZM56.028 0L53.032 3.916L48.072 5.332L51.18 9.172L51.108 13.968L56.028 12.424L60.948 13.968L60.876 9.172L63.992 5.332L59.028 3.916L56.028 0ZM80.06 0.0319977L77.076 3.936L72.128 5.348L75.228 9.172L75.156 13.952L80.06 12.412L84.964 13.952L84.892 9.172L87.992 5.348L83.044 3.936L80.06 0.0319977ZM104 0.0319977L101.016 3.936L96.068 5.348L99.168 9.172L99.096 13.952L104 12.412L108.904 13.952L108.832 9.172L111.932 5.348L106.984 3.936L104 0.0319977Z"
                      fill="#F8B84E"
                    />
                  </svg>
                </div>
                <h4>5 Nights & 6 Days</h4>

                <h5>
                  Starting From
                  <strong>
                    ₹30,000<span>(Starting From)</span>
                  </strong>
                </h5>
                <p>Leh (2N)</p>
                <p>Nubra (1N)</p>
                <p>Pangong (1N)</p>
                <ul>
                  <li>Transfer</li>
                  <li>Meals</li>
                  <li>Hotel</li>
                  <li>Flight</li>
                </ul>
                <Link to="/">View Details</Link>
                <button>Remove</button>
              </div>
            </div>

            <div className="compareList">
              <div className="compareSlider">
                <Slider {...settings}>
                  <div>
                    <img src={special1} alt="" />
                  </div>
                  <div>
                    <img src={special1} alt="" />
                  </div>
                </Slider>
              </div>

              <div className="compareCaption">
                <h2>Best Of Ladakh - Standard</h2>
                <h3>
                  {/* By: <span>Subrata Dutta Tour & Traveller</span> */}
                </h3>
                <div className="ratingCompare">
                  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="14" viewBox="0 0 112 14" fill="none">
                    <path
                      d="M7.956 0L4.96 3.916L0 5.332L3.108 9.172L3.036 13.968L7.956 12.424L12.876 13.968L12.804 9.168L15.92 5.328L10.956 3.912L7.956 0ZM31.984 0.0319977L29 3.936L24.052 5.348L27.152 9.172L27.08 13.952L31.984 12.412L36.888 13.952L36.816 9.172L39.916 5.348L34.968 3.936L31.984 0.0319977ZM56.028 0L53.032 3.916L48.072 5.332L51.18 9.172L51.108 13.968L56.028 12.424L60.948 13.968L60.876 9.172L63.992 5.332L59.028 3.916L56.028 0ZM80.06 0.0319977L77.076 3.936L72.128 5.348L75.228 9.172L75.156 13.952L80.06 12.412L84.964 13.952L84.892 9.172L87.992 5.348L83.044 3.936L80.06 0.0319977ZM104 0.0319977L101.016 3.936L96.068 5.348L99.168 9.172L99.096 13.952L104 12.412L108.904 13.952L108.832 9.172L111.932 5.348L106.984 3.936L104 0.0319977Z"
                      fill="#F8B84E"
                    />
                  </svg>
                </div>
                <h4>5 Nights & 6 Days</h4>

                <h5>
                  Starting From
                  <strong>
                    ₹30,000<span>(Starting From)</span>
                  </strong>
                </h5>
                <p>Leh (2N)</p>
                <p>Nubra (1N)</p>
                <p>Pangong (1N)</p>
                <ul>
                  <li>Transfer</li>
                  <li>Meals</li>
                  <li>Hotel</li>
                  <li>Flight</li>
                </ul>
                <Link to="/">View Details</Link>
                <button>Remove</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Compare;
