import React, {Component, useState, useRef, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";
import {
  addPackageApi,
  addVendorBankDetailsApi,
  editVendorDetailsApi,
  getCityListApi,
  getDetailsFromPincodeApi,
  getFileManagerApi,
  getStatesListApi,
  getThemesListApi,
  getTripListApi,
  getTypeOfTourPackagesListApi,
  vendorDetailsApi,
} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import {Form, Button, Dropdown, Tabs, Tab, Modal} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import panImg from "../assets/img/pan-image.png";
import EmptyImg from "../assets/img/empty-img.svg";
import PdfImg from "../assets/img/pdf-1.png";
import ProfileLogo from "../assets/img/dropdown-icon4.svg";
import CancelChequeImg from "../assets/img/cancel-cheque-img.png";
import AuthoriedLetterImg from "../assets/img/Authoried-letter-img.png";
import {CAPTCHA_SITE_KEY, IMAGE_URL} from "../utils/commonUrls";
import {useUserContext} from "../context/UserContext";
import {Link} from "react-router-dom";
import LoadingPage from "../component/loadingPage";

const arrow = require("./../assets/img/left-arrow.svg").default;

const VendorAccountSetting = () => {
  const navigate = useNavigate();
  const {userData, setUserData} = useUserContext();
  const [key, setKey] = useState("tab1");
  const [show, setShow] = useState(false);
  const [showSection, setShowSection] = useState("main");
  const [vendorDetail, setVendorDetail] = useState();
  const [fileManagerFiles, setFileManagerFiles] = useState();
  const [vendorFormData, setVendorFormData] = useState({
    fullName: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
    pincode: "",
    country: "",
    state: "",
    city: "",
    panPhoto: "",
    panNumber: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    fullName: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
    pincode: "",
    panPhoto: "",
    panNumber: "",
  });
  const [changePassFormData, setChangePassFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [changePassValidationErrors, setChangePassValidationErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [bankFormData, setBankFormData] = useState({
    fullName: "",
    accNumber: "",
    ifscCode: "",
    bankName: "",
    branchName: "",
    cancelCheque: "",
    authorizationLetter: "",
  });
  const [bankValidationErrors, setBankValidationErrors] = useState({
    fullName: "",
    accNumber: "",
    ifscCode: "",
    bankName: "",
    branchName: "",
    cancelCheque: "",
    authorizationLetter: "",
    captchaError: "",
  });
  const [loading, setLoading] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  const [bankButtonDis, setBankButtonDis] = useState(false);
  const [profileCompletion, setProfileCompletion] = useState(0);
  const [captchaChecked, setCaptchaChecked] = useState(false);
  const [checkIsVerified, setCheckIsVerified] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setCaptchaChecked(false);
  };

  const getVendorDetails = async () => {
    setScreenLoading(true);
    try {
      let response = await vendorDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setVendorDetail(response.data);

          let tempCompleteValue = 0;
          if (response.data.data) {
            let tempUserData = JSON.parse(localStorage.getItem("WMTUserData"));
            if (response.data.data.fullname) {
              tempUserData.data.fullname = response.data.data.fullname;
              localStorage.setItem("WMTUserData", JSON.stringify(tempUserData));
              setUserData(tempUserData);
            }

            // if (!response.data.user.email && !response.data.user.mobile) {
            //   tempCompleteValue = 60;
            // } else if (response.data.user.email && !response.data.user.mobile) {
            //   tempCompleteValue = 80;
            // } else if (!response.data.user.email && response.data.user.mobile) {
            //   tempCompleteValue = 80;
            // } else if (response.data.user.email && response.data.user.mobile) {
            //   tempCompleteValue = 90;
            // } else if (response.data.data.bank_account_number) {
            //   tempCompleteValue = 100;
            // }

            // setProfileCompletion(tempCompleteValue);

            const countFilledFields = () => {
              let filledFields = 0;
              if (response.data?.data?.name) filledFields++;
              if (response.data?.user?.email) filledFields++;
              if (response.data?.user?.mobile) filledFields++;
              // if (response.data?.user?.email || userData?.data?.email) filledFields++;
              // if (response.data?.user?.mobile || userData?.data?.mobile) filledFields++;
              if (response.data?.data?.address) filledFields++;
              if (response.data?.data?.zip_code) filledFields++;
              if (response.data?.data?.pan_number) filledFields++;
              if (response.data?.data?.pan_card_file) filledFields++;
              if (response.data.data.contact_person_name) filledFields++;
              if (response.data.data.bank_account_number) filledFields++;
              if (response.data.data.ifsc_code) filledFields++;
              if (response.data.data.bank_name) filledFields++;
              if (response.data.data.branch_name) filledFields++;
              if (response.data.data.cancelled_cheque) filledFields++;
              // if (response.data.data.authorization_letter) filledFields++;
              if (response.data.data.have_gst === 1) {
                if (response.data.data.gst_rate) {
                  filledFields++;
                }
              }
              return filledFields;
            };

            const calculateCompletionPercentage = () => {
              const totalFields = response.data.data.have_gst === 1 ? 14 : 13; // Total number of fields
              // const totalFields = response.data.data.have_gst === 1 ? 15 : 14; // Total number of fields
              const filledFields = countFilledFields();
              return (filledFields / totalFields) * 100;
            };

            setProfileCompletion(Math.floor(calculateCompletionPercentage()));

            setVendorFormData({
              fullName: response.data.data.name,
              mobileNumber: response.data.user.mobile,
              emailAddress: response.data.user.email,
              address: response.data.data.address,
              pincode: response.data.data.zip_code,
              country: response.data.data.country,
              state: response.data.data.state,
              city: response.data.data.city,
              panPhoto: response.data.data.pancard,
              panNumber: response.data.data.pan_number,
            });

            setBankFormData({
              fullName: response.data.data.contact_person_name,
              accNumber: response.data.data.bank_account_number,
              ifscCode: response.data.data.ifsc_code,
              bankName: response.data.data.bank_name,
              branchName: response.data.data.branch_name,
              cancelCheque: response.data.data.cancelled_cheque,
              // authorizationLetter: response.data.data.authorization_letter,
            });

            const fetchAndAppendImage = async (imageUrl, imageId) => {
              try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const file = new File([blob], imageId, {type: "image/*"});
                return file;
              } catch (error) {
                console.error("Error fetching image:", error);
                // Handle the error if needed
                return null;
              }
            };

            // const promises = () => {
            //   const imageUrl = IMAGE_URL + response.data.data.cancelled_cheque;
            //   if (imageUrl) {
            //     return fetchAndAppendImage(imageUrl, response.data.data.cancelled_cheque);
            //   }
            // };

            const handleImageFetching = async (imageKey, imageId) => {
              const imageUrl = IMAGE_URL + response.data.data[imageKey];
              if (imageUrl) {
                try {
                  const file = await fetchAndAppendImage(imageUrl, imageId);
                  return file;
                } catch (error) {
                  console.error("Error fetching image:", error);
                  // Handle the error if needed
                  return null;
                }
              }
              return null;
            };
            Promise.all([
              response.data.data.cancelled_cheque &&
                handleImageFetching("cancelled_cheque", response.data.data.cancelled_cheque?.split(userData.data.customer_id + "_")[1]),
              // response.data.data.authorization_letter &&
              //   handleImageFetching("authorization_letter", response.data.data.authorization_letter?.split(userData.data.customer_id + "_")[1]),
            ])
              .then(files => {
                const [
                  cancelChequeFile,
                  // authorizationLetterFile
                ] = files;
                // Update state with fetched files
                setBankFormData(prevFormData => ({
                  ...prevFormData,
                  cancelCheque: cancelChequeFile,
                  // authorizationLetter: authorizationLetterFile,
                }));
              })
              .catch(error => {
                console.error("Error fetching images:", error);
              });

            // Promise.all([promises()])
            //   .then(files => {
            //     const file = files[0];
            //     setBankFormData({
            //       ...bankFormData, cancelCheque: file
            //     })
            //     console.log(file);
            //   })
            //   .catch(error => {
            //     console.error("Error:", error);
            //   });
          } else {
            setVendorFormData(prev => ({...prev, mobileNumber: userData?.data.mobile, emailAddress: userData?.data.email}));
          }
        } else {
        }
        setScreenLoading(false);
      }
    } catch (error) {
      setScreenLoading(false);
    }
  };

  const handleCompleteNow = async () => {
    if (!vendorDetail?.data?.bank_account_number) {
      setKey("tab3");
    }
  };

  const getFileManagerFiles = async () => {
    try {
      let response = await getFileManagerApi();
      if (response) {
        if (response.data.res === true) {
          setFileManagerFiles(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const handleDownload = async (imageUrl, name) => {
    if (imageUrl) {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        NotificationManager.success("Downloaded successfully.");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onCaptchaChange = value => {
    // console.log("Captcha value:", value);
    setCaptchaChecked(true);
    setBankValidationErrors(prevErrors => ({...prevErrors, captchaError: ""}));
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      fullName: "",
      mobileNumber: "",
      emailAddress: "",
      address: "",
      pincode: "",
      panPhoto: "",
      panNumber: "",
    };

    if (vendorFormData.fullName === "") {
      errors.fullName = "Please enter your Name.";
      isValid = false;
    }

    if (vendorFormData.mobileNumber === "" || !/^\d{10}$/.test(vendorFormData.mobileNumber)) {
      errors.mobileNumber = "Please enter a valid 10-digit Mobile Number.";
      isValid = false;
    }

    if (vendorFormData.emailAddress === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(vendorFormData.emailAddress)) {
      errors.emailAddress = "Please enter a valid Email Address.";
      isValid = false;
    }

    if (vendorFormData.address === "" || vendorFormData.address === null) {
      errors.address = "Please enter your address.";
      isValid = false;
    }

    if (vendorFormData.pincode === "" || vendorFormData.pincode === null) {
      errors.pincode = "Please enter your pincode.";
      isValid = false;
    }

    if (vendorFormData.pincode && (!vendorFormData.country || !vendorFormData.state || !vendorFormData.city)) {
      errors.pincode = "Please enter valid pincode.";
      isValid = false;
    }

    if (vendorFormData.panNumber === "") {
      errors.panNumber = "Please enter your Zip Code.";
      isValid = false;
    }
    setValidationErrors(errors);
    return isValid;
  };

  const changePassValidateForm = () => {
    let isValid = true;
    const errors = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };

    if (changePassFormData.oldPassword === "") {
      errors.oldPassword = "Please enter old password.";
      isValid = false;
    }

    if (changePassFormData.newPassword === "") {
      errors.newPassword = "Please enter new password.";
      isValid = false;
    }

    if (changePassFormData.confirmPassword === "") {
      errors.confirmPassword = "Please enter confirm password.";
      isValid = false;
    }

    setChangePassValidationErrors(errors);
    return isValid;
  };

  const bankValidateForm = () => {
    let isValid = true;
    const errors = {
      fullName: "",
      accNumber: "",
      ifscCode: "",
      bankName: "",
      branchName: "",
      cancelCheque: "",
      authorizationLetter: "",
      captchaError: "",
    };

    if (!bankFormData.fullName) {
      errors.fullName = "Please enter full name.";
      isValid = false;
    }

    if (!bankFormData.accNumber) {
      errors.accNumber = "Please enter account number.";
      isValid = false;
    }

    if (!bankFormData.ifscCode) {
      errors.ifscCode = "Please enter IFSC code.";
      isValid = false;
    }

    if (!bankFormData.bankName) {
      errors.bankName = "Please enter bank name.";
      isValid = false;
    }

    if (!bankFormData.branchName) {
      errors.branchName = "Please enter branch name.";
      isValid = false;
    }

    if (!bankFormData.cancelCheque) {
      errors.cancelCheque = "Please select cancel cheque.";
      isValid = false;
    }

    // if (!bankFormData.authorizationLetter) {
    //   errors.authorizationLetter = "Please select authorization letter.";
    //   isValid = false;
    // }

    if (!captchaChecked) {
      errors.captchaError = "Please check the 'I am not a robot' checkbox";
      isValid = false;
    }

    setBankValidationErrors(errors);
    return isValid;
  };

  const handleInputChange = (field, value) => {
    setVendorFormData(prevData => ({...prevData, [field]: value}));
    setValidationErrors(prevErrors => ({...prevErrors, [field]: ""}));
  };

  const handlePincodeChange = async value => {
    try {
      let response = await getDetailsFromPincodeApi(value);
      if (response && response.data[0].Status === "Success") {
        setVendorFormData(prevData => ({
          ...prevData,
          country: response.data[0].PostOffice[0].Country,
          state: response.data[0].PostOffice[0].State,
          city: response.data[0].PostOffice[0].District,
        }));
      } else {
        setVendorFormData(prevData => ({...prevData, country: "", state: "", city: ""}));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBankDetailsInputChange = (field, value) => {
    if (field === "cancelCheque" || field === "authorizationLetter") {
      const validFileTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (value && !validFileTypes.includes(value.type)) {
        NotificationManager.warning("Please upload a valid file (JPEG, PNG, or PDF).");
        return;
      }
    }
    setBankFormData(prevData => ({...prevData, [field]: value}));
    setBankValidationErrors(prevErrors => ({...prevErrors, [field]: ""}));
  };

  const handleEditVendorDetails = async () => {
    if (validateForm()) {
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("name", vendorFormData.fullName);
        // formData.append("address", vendorFormData.address);
        // formData.append("pincode", vendorFormData.pincode);
        // formData.append("country", vendorFormData.country);
        // formData.append("state", vendorFormData.state);
        // formData.append("city", vendorFormData.city);
        formData.append("pan_number", vendorFormData.panNumber);
        formData.append("email", vendorFormData.emailAddress);
        formData.append("mobile", vendorFormData.mobileNumber);
        // Api call
        let response = await editVendorDetailsApi(formData);
        if (response) {
          if (response.data.res === true) {
            NotificationManager.success(response.data.msg);
            getVendorDetails();
            getFileManagerFiles();
            setShowSection("main");
          } else {
            // setValidationError(response.data.msg);
            NotificationManager.warning(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        NotificationManager.error("Something went wrong, please try again.");
      }
    }
  };

  const handleSaveBankDetails = async () => {
    if (bankValidateForm()) {
      setBankButtonDis(true);
      try {
        let formData = new FormData();
        formData.append("contact_person_name", bankFormData.fullName);
        formData.append("bank_account_number", bankFormData.accNumber);
        formData.append("ifsc_code", bankFormData.ifscCode);
        formData.append("bank_name", bankFormData.bankName);
        formData.append("branch_name", bankFormData.branchName);
        formData.append("cancelled_cheque", bankFormData.cancelCheque);
        // formData.append("authorization_letter", bankFormData.authorizationLetter);
        formData.append("bank_verified", "0");
        // Api call
        let response = await addVendorBankDetailsApi(formData);
        if (response) {
          if (response.data.res === true) {
            NotificationManager.success(response.data.msg);
            getVendorDetails();
            handleClose();
          } else {
            // setValidationError(response.data.msg);
            NotificationManager.warning(response.data.msg);
          }
          setBankButtonDis(false);
        }
      } catch (error) {
        setBankButtonDis(false);
        NotificationManager.error("Something went wrong, please try again.");
      }
    }
  };

  useEffect(() => {
    getVendorDetails();
    getFileManagerFiles();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Account settings`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Account settings page" />
      </Helmet>
      <VenderHeader setCheckIsVerified={setCheckIsVerified} />
      <div className="venderInnerDiv">
        <VenderSidebar />

        <div className="venderRight">
          {screenLoading && <LoadingPage />}
          <div className="hdrSec">
            <h2>
              {showSection === "edit" || showSection === "changePassword" ? (
                <Button className="btnBack" variant="link" onClick={() => setShowSection("main")}>
                  <img src={arrow} alt="" />
                </Button>
              ) : null}
              Account Settings
            </h2>
            <div className="top-progress-sec">
              <h4>
                Profile Status:
                <span>
                  {profileCompletion}%{" "}
                  {profileCompletion < 100 && checkIsVerified === 1 ? (
                    // {profileCompletion < 100 && (vendorDetail?.data?.is_verified === 1 && checkIsVerified !== 1) ? (
                    <button className="completeNowBtn" onClick={() => handleCompleteNow()}>
                      Complete Now
                    </button>
                  ) : null}{" "}
                </span>
              </h4>
              <div className="progressbar">
                <div className="progressbar-inner" style={{width: `${profileCompletion}%`}}></div>
              </div>
              {/* <h4>
                <span>{profileCompletion < 100 ? "Please complete your profile." : null}</span>
              </h4> */}
            </div>
          </div>
          <div className="venderMain venderMain2">
            <div className="OverlaySec">
              <div className="seller-accounts">
                {showSection === "main" ? (
                  <div className="hide-tabs-sec">
                    <Tabs id="controlled-tabs" activeKey={key} onSelect={k => setKey(k)}>
                      <Tab eventKey="tab1" title="User Profile">
                        <ul className="row profile-list">
                          <li className="col-md-4">
                            <label>Name</label>
                            <span className="InputValue">{vendorDetail?.data?.name}</span>
                          </li>
                          <li className="col-md-4">
                            <label>Email</label>
                            <span className="InputValue">{vendorDetail?.user?.email ? vendorDetail?.user?.email : "--"}</span>
                          </li>
                          <li className="col-md-4">
                            <label>Phone Number</label>
                            <span className="InputValue">{vendorDetail?.user?.mobile ? vendorDetail?.user?.mobile : "--"}</span>
                          </li>

                          {/* <li className="col-md-4">
                          <label>Pincode</label>
                          <span className="InputValue">{vendorDetail?.data?.zip_code}</span>
                        </li>
                        <li className="col-md-4">
                          <label>Country</label>
                          <span className="InputValue">{vendorDetail?.data?.country}</span>
                        </li>
                        <li className="col-md-4">
                          <label>State</label>
                          <span className="InputValue">{vendorDetail?.data?.state}</span>
                        </li>
                        <li className="col-md-4">
                          <label>City</label>
                          <span className="InputValue">{vendorDetail?.data?.city}</span>
                        </li> */}
                          <li className="col-md-4">
                            {/* <label>Address</label>
                          <span className="InputValue">{vendorDetail?.data?.address}</span> */}
                            <div className="hideMobile">
                              <br />
                              <label>PAN Number</label>
                              <span className="InputValue">{vendorDetail?.data?.pan_number}</span>
                            </div>
                          </li>
                          {vendorDetail?.data?.pan_number ? (
                            <li className="col-md-4">
                              <label>PAN Photo</label>
                              {vendorDetail?.data?.pan_card_file?.endsWith(".pdf") ? (
                                <div className="download-box">
                                  <img src={PdfImg} alt="" />
                                  <button
                                    className="btn download-btn"
                                    type="button"
                                    onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.pan_card_file, "pancard.pdf")}>
                                    Download
                                  </button>
                                </div>
                              ) : (
                                <div className="download-box">
                                  <img src={IMAGE_URL + vendorDetail?.data?.pan_card_file} alt="" />
                                  <button
                                    className="btn download-btn"
                                    type="button"
                                    onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.pan_card_file, "pancard.jpg")}>
                                    Download
                                  </button>
                                </div>
                              )}
                              {/* <div className="download-box">
                              <img src={IMAGE_URL + vendorDetail?.data?.pan_card_file} alt="" />
                              <button
                                className="btn download-btn"
                                type="button"
                                onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.pan_card_file, "pancard.jpg")}>
                                Download
                              </button>
                            </div> */}
                            </li>
                          ) : null}

                          <li className="col-md-4 hideWeb">
                            <label>PAN Number</label>
                            <span className="InputValue">{vendorDetail?.data?.pan_number}</span>
                          </li>
                          <li className="col-md-12">
                            <a className="btn btn-primary edit-btn CmnBtn" onClick={() => setShowSection("edit")}>
                              Edit
                            </a>
                            {/* <a className="btn change-password-btn OutLineBtn" onClick={() => setShowSection("changePassword")}>
                            Change Password
                          </a> */}
                          </li>
                        </ul>
                      </Tab>
                      <Tab eventKey="tab2" title="Tour Operator Profile">
                        <ul className="row profile-list">
                          {/* <li className="col-md-4">
                          <label>Type of Vendor</label>
                          <span className="InputValue">{vendorDetail?.data?.type_of_vendor ? vendorDetail?.data?.type_of_vendor : "--"}</span>
                        </li> */}
                          {vendorDetail?.data.have_gst ? (
                            <li className="col-md-4">
                              <label>GST Number</label>
                              <span className="InputValue">
                                {vendorDetail?.data?.gst_number ? vendorDetail?.data?.gst_number : "--"}{" "}
                                {vendorDetail?.data?.gst_verified === 1 ? (
                                  <span className="isVerified">Verified</span>
                                ) : (
                                  <span className="notVerified">Not verified</span>
                                )}
                              </span>
                            </li>
                          ) : null}
                          {vendorDetail?.data.have_gst ? (
                            <li className="col-md-4">
                              <label>GST Certificate</label>
                              {vendorDetail?.data?.gst_certificate_file?.endsWith(".pdf") ? (
                                <div className="download-box">
                                  <img src={PdfImg} alt="" />
                                  <button
                                    className="btn download-btn"
                                    type="button"
                                    onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.gst_certificate_file, "gst_certificate.pdf")}>
                                    Download
                                  </button>
                                </div>
                              ) : (
                                <div className="download-box">
                                  <img src={IMAGE_URL + vendorDetail?.data?.gst_certificate_file} alt="" />
                                  <button
                                    className="btn download-btn"
                                    type="button"
                                    onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.gst_certificate_file, "gst_certificate.jpg")}>
                                    Download
                                  </button>
                                </div>
                              )}
                              {/* <div className="download-box">
                              <img src={IMAGE_URL + vendorDetail?.data?.gst_certificate_file} alt="" />
                              <button
                                className="btn download-btn"
                                type="button"
                                onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.gst_certificate_file, "gst_certificate.jpg")}>
                                Download
                              </button>
                            </div> */}
                            </li>
                          ) : null}
                          {vendorDetail?.data.have_gst ? (
                            <li className="col-md-4">
                              <label>GST Rate</label>
                              <span className="InputValue">{vendorDetail?.data?.gst_rate ? vendorDetail?.data?.gst_rate : "--"}</span>
                            </li>
                          ) : null}
                          {/* {!vendorDetail?.data.have_gst ? (
                          <> */}
                          <li className="col-md-4">
                            <label>Organization Name</label>
                            <span className="InputValue">{vendorDetail?.data?.organization_name ? vendorDetail?.data?.organization_name : "--"}</span>
                          </li>
                          <li className="col-md-4">
                            <label>Organization Type</label>
                            <span className="InputValue">{vendorDetail?.organization_type ? vendorDetail?.organization_type : "--"}</span>
                          </li>
                          <li className="col-md-4">
                            <label>Pincode</label>
                            <span className="InputValue">{vendorDetail?.data?.zip_code}</span>
                          </li>
                          <li className="col-md-4">
                            <label>Country</label>
                            <span className="InputValue">{vendorDetail?.data?.country}</span>
                          </li>
                          <li className="col-md-4">
                            <label>State</label>
                            <span className="InputValue">{vendorDetail?.data?.state}</span>
                          </li>
                          <li className="col-md-4">
                            <label>City</label>
                            <span className="InputValue">{vendorDetail?.data?.city}</span>
                          </li>
                          <li className="col-md-4">
                            <label>Address</label>
                            <span className="InputValue">{vendorDetail?.data?.address}</span>
                          </li>
                          <li className="col-md-4">
                            {parseInt(vendorDetail?.data?.organization_type) === 2 ? (
                              <>
                                <label>Number of Partnership</label>
                                <span className="InputValue">{vendorDetail?.data?.details?.length}</span>
                              </>
                            ) : parseInt(vendorDetail?.data?.organization_type) === 3 ? (
                              <>
                                <label>Number of Directors</label>
                                <span className="InputValue">{vendorDetail?.data?.details?.length}</span>
                                {/* <span className="InputValue">{vendorDetail?.data?.no_of_directors}</span> */}
                              </>
                            ) : null}
                          </li>
                          <li className="col-md-4"></li>
                          <li className="col-md-4">
                            {/* <label>Address</label>
                          <span className="InputValue">{vendorDetail?.data?.address}</span> */}
                            <div className="hideMobile">
                              <br />
                              <label>PAN Number</label>
                              <span className="InputValue">{vendorDetail?.data?.organization_pan_number}</span>
                            </div>
                          </li>
                          {vendorDetail?.data?.organization_pan_number ? (
                            <li className="col-md-4">
                              <label>PAN Photo</label>
                              {vendorDetail?.data?.organization_pan_file?.endsWith(".pdf") ? (
                                <div className="download-box">
                                  <img src={PdfImg} alt="" />
                                  <button
                                    className="btn download-btn"
                                    type="button"
                                    onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.organization_pan_file, "pancard.pdf")}>
                                    Download
                                  </button>
                                </div>
                              ) : (
                                <div className="download-box">
                                  <img src={IMAGE_URL + vendorDetail?.data?.organization_pan_file} alt="" />
                                  <button
                                    className="btn download-btn"
                                    type="button"
                                    onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.organization_pan_file, "pancard.jpg")}>
                                    Download
                                  </button>
                                </div>
                              )}
                            </li>
                          ) : null}

                          {/* </>
                        ) : null} */}
                        </ul>

                        {parseInt(vendorDetail?.data?.organization_type) === 1 ? (
                          <>
                            <h4>Proprietor Detail</h4>
                            <div className="table-sec">
                              <table className="table-stripe">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Proprietor Name</th>
                                    <th>Phone Number</th>
                                    <th>PAN Number</th>
                                    <th>Address</th>
                                    <th>Photo</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {vendorDetail?.data?.details?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1 < 10 ? "0" + (index + 1) : index + 1}</td>
                                      <td>{item?.name}</td>
                                      <td>{item?.phone_number}</td>
                                      <td>{item?.pan_number}</td>
                                      <td>{item?.address}</td>
                                      <td>
                                        <a target="_blank" href={IMAGE_URL + item?.photo}>
                                          {/* <img src={ProfileLogo} /> */}
                                          <img src={IMAGE_URL + item?.photo} />
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : parseInt(vendorDetail?.data?.organization_type) === 2 ? (
                          <>
                            <h4>Partner Details</h4>
                            <div className="table-sec">
                              <table className="table-stripe">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Proprietor Name</th>
                                    <th>Phone Number</th>
                                    <th>PAN Number</th>
                                    <th>Address</th>
                                    <th>Photo</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {vendorDetail?.data?.details?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1 < 10 ? "0" + (index + 1) : index + 1}</td>
                                      <td>{item?.name}</td>
                                      <td>{item?.phone_number}</td>
                                      <td>{item?.pan_number}</td>
                                      <td>{item?.address}</td>
                                      <td>
                                        <a target="_blank" href={IMAGE_URL + item?.photo}>
                                          {/* <img src={ProfileLogo} /> */}
                                          <img src={IMAGE_URL + item?.photo} />
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : parseInt(vendorDetail?.data?.organization_type) === 3 ? (
                          <>
                            <h4>Director Details</h4>
                            <div className="table-sec">
                              <table className="table-stripe">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Director Name</th>
                                    <th>Phone Number</th>
                                    <th>PAN Number</th>
                                    <th>Address</th>
                                    <th>Photo</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {vendorDetail?.data?.details?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1 < 10 ? "0" + (index + 1) : index + 1}</td>
                                      <td>{item?.name}</td>
                                      <td>{item?.phone_number}</td>
                                      <td>{item?.pan_number}</td>
                                      <td>{item?.address}</td>
                                      <td>
                                        <a target="_blank" href={IMAGE_URL + item?.photo}>
                                          {/* <img src={ProfileLogo} /> */}
                                          <img src={IMAGE_URL + item?.photo} />
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : null}

                        {/* <h4>Broad Locations</h4>
                      <ul className="row broad-locations-list">
                        {vendorDetail?.vendorBroadLocations?.length > 0 ? (
                          vendorDetail?.vendorBroadLocations?.map((item, index) => (
                            <li key={index}>
                              <div className="border-box">
                                <h5>{item?.state_name}</h5>
                                <p>{item?.city_name}</p>
                              </div>
                            </li>
                          ))
                        ) : (
                          <li>
                            <p>No broad location added.</p>
                          </li>
                        )}
                      </ul> */}
                        {/* {vendorDetail?.data?.is_verified !== 1 && parseInt(checkIsVerified) !== 1 ? ( */}
                        {parseInt(checkIsVerified) !== 1 ? (
                          <ul className="row profile-list">
                            <li className="col-md-12">
                              <Link to={"/business/profile/edit"} className="btn btn-primary edit-btn CmnBtn">
                                Edit
                              </Link>
                            </li>
                          </ul>
                        ) : null}
                      </Tab>
                      <Tab eventKey="tab3" title="Financial Details">
                        <div className="OverlaySec">
                          <div className="body-header-box bankAddHeader">
                            {/* <h3>Financial Details</h3> */}
                            {vendorDetail?.data?.bank_verified === 0 ? (
                              !vendorDetail?.data?.bank_account_number ? (
                                <button className="btn OutLineBtn" onClick={handleShow}>
                                  Add Bank Details
                                </button>
                              ) : (
                                <button className="btn OutLineBtn" onClick={handleShow}>
                                  Update Bank Details
                                </button>
                              )
                            ) : null}
                          </div>
                          {!vendorDetail?.data?.bank_account_number ? (
                            <div className="empty-financial-details">
                              <img src={EmptyImg} alt="" />
                            </div>
                          ) : (
                            <ul className="row profile-list">
                              <li className="col-md-4">
                                <label>Full Name</label>
                                <span className="InputValue">{vendorDetail?.data?.contact_person_name}</span>
                              </li>
                              <li className="col-md-4">
                                <label>Bank Account No.</label>
                                <span className="InputValue">{vendorDetail?.data?.bank_account_number}</span>
                              </li>
                              <li className="col-md-4">
                                <label>IFSC Code</label>
                                <span className="InputValue">{vendorDetail?.data?.ifsc_code}</span>
                              </li>
                              <li className="col-md-4">
                                <label>Bank Name</label>
                                <span className="InputValue">{vendorDetail?.data?.bank_name}</span>
                              </li>
                              <li className="col-md-4">
                                <label>Branch Name</label>
                                <span className="InputValue">{vendorDetail?.data?.branch_name}</span>
                              </li>
                              <li className="col-md-4">&nbsp;</li>
                              <li className="col-md-4">
                                <label>Cancelled Check</label>
                                {vendorDetail?.data?.cancelled_cheque?.endsWith(".pdf") ? (
                                  <div className="download-box">
                                    <img src={PdfImg} alt="" />
                                    <button
                                      className="btn download-btn"
                                      type="button"
                                      onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.cancelled_cheque, "cancelled_cheque.pdf")}>
                                      Download
                                    </button>
                                  </div>
                                ) : (
                                  <div className="download-box">
                                    <img src={IMAGE_URL + vendorDetail?.data?.cancelled_cheque} alt="" />
                                    <button
                                      className="btn download-btn"
                                      type="button"
                                      onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.cancelled_cheque, "cancelled_cheque.jpg")}>
                                      Download
                                    </button>
                                  </div>
                                )}
                              </li>
                              {/* <li className="col-md-4">
                                <label>Authorization Letter</label>
                                {vendorDetail?.data?.authorization_letter?.endsWith(".pdf") ? (
                                  <div className="download-box">
                                    <img src={PdfImg} alt="" />
                                    <button
                                      className="btn download-btn"
                                      type="button"
                                      onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.authorization_letter, "authorization_letter.pdf")}>
                                      Download
                                    </button>
                                  </div>
                                ) : (
                                  <div className="download-box">
                                    <img src={IMAGE_URL + vendorDetail?.data?.authorization_letter} alt="" />
                                    <button
                                      className="btn download-btn"
                                      type="button"
                                      onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.authorization_letter, "authorization_letter.jpg")}>
                                      Download
                                    </button>
                                  </div>
                                )}
                                <div className="download-box">
                                <img src={IMAGE_URL + vendorDetail?.data?.authorization_letter} alt="" />
                                <button
                                  className="btn download-btn"
                                  type="button"
                                  onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.authorization_letter, "authorization_letter.jpg")}>
                                  Download
                                </button>
                              </div>
                              </li> */}
                            </ul>
                          )}
                          {vendorDetail?.data.bank_account_number && vendorDetail?.data.bank_verified !== 1 ? (
                            <div className="OverlayTextSec">
                              <span>Bank Details Not Verified Yet.</span>
                            </div>
                          ) : null}
                        </div>
                      </Tab>
                      <Tab eventKey="tab4" title="File Manager">
                        <ul className="row profile-list">
                          {fileManagerFiles?.length > 0
                            ? fileManagerFiles?.map((item, index) =>
                                item?.file ? (
                                  <li className="col-md-4" key={index}>
                                    <label>{item?.name}</label>
                                    {item?.file?.endsWith(".pdf") ? (
                                      <div className="download-box">
                                        <img src={PdfImg} alt="" />
                                        <button
                                          className="btn download-btn"
                                          type="button"
                                          onClick={() => handleDownload(IMAGE_URL + item?.file, item?.name?.replace(" ", "_") + ".pdf")}>
                                          {/* onClick={() => handleDownload(IMAGE_URL + item?.file, item?.file.split("/").pop())}> */}
                                          Download
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="download-box">
                                        <img src={IMAGE_URL + item?.file} alt="" />
                                        <button
                                          className="btn download-btn"
                                          type="button"
                                          onClick={() => handleDownload(IMAGE_URL + item?.file, item?.name?.replace(" ", "_") + ".jpg")}>
                                          {/* onClick={() => handleDownload(IMAGE_URL + item?.file, item?.file.split("/").pop())}> */}
                                          Download
                                        </button>
                                      </div>
                                    )}
                                  </li>
                                ) : null
                              )
                            : null}
                        </ul>
                      </Tab>
                    </Tabs>
                  </div>
                ) : null}

                {showSection === "edit" ? (
                  <div className="normal-accrounts-sec">
                    <h3>Edit Profile</h3>
                    <ul className="row edit-profile-form-list">
                      <li className="col-md-4">
                        <label>Name</label>
                        <input
                          type="text"
                          name=""
                          value={vendorFormData.fullName}
                          className="form-control"
                          onChange={e => handleInputChange("fullName", e.target.value)}
                        />
                        {validationErrors.fullName && <span className="validationErrorMsg">{validationErrors.fullName}</span>}
                      </li>
                      <li className="col-md-4">
                        <label>Email</label>
                        <input
                          type="email"
                          name=""
                          readOnly={vendorDetail?.user?.email ? true : false}
                          value={vendorFormData.emailAddress}
                          className="form-control"
                          onChange={e => handleInputChange("emailAddress", e.target.value)}
                        />
                        {validationErrors.emailAddress && <span className="validationErrorMsg">{validationErrors.emailAddress}</span>}
                      </li>
                      <li className="col-md-4">
                        <label>Phone Number</label>
                        <input
                          type="tel"
                          name=""
                          readOnly={vendorDetail?.user?.mobile ? true : false}
                          value={vendorFormData.mobileNumber}
                          className="form-control"
                          onChange={e => handleInputChange("mobileNumber", e.target.value)}
                        />
                        {validationErrors.mobileNumber && <span className="validationErrorMsg">{validationErrors.mobileNumber}</span>}
                      </li>
                      {/* <li className="col-md-6">
                      <label>Address</label>
                      <input
                        type="text"
                        name=""
                        value={vendorFormData.address}
                        className="form-control"
                        onChange={e => handleInputChange("address", e.target.value)}
                      />
                      {validationErrors.address && <span className="validationErrorMsg">{validationErrors.address}</span>}
                    </li>
                    <li className="col-md-6">
                      <label>Pincode</label>
                      <input
                        type="text"
                        name=""
                        value={vendorFormData.pincode}
                        className="form-control"
                        onChange={e => {
                          handleInputChange("pincode", e.target.value);
                          handlePincodeChange(e.target.value);
                        }}
                      />
                      {validationErrors.pincode && <span className="validationErrorMsg">{validationErrors.pincode}</span>}
                    </li>
                    <li className="col-md-6">
                      <label>Country</label>
                      <input type="text" name="" value={vendorFormData.country} className="form-control" readOnly />
                    </li>
                    <li className="col-md-6">
                      <label>State</label>
                      <input type="text" name="" value={vendorFormData.state} className="form-control" readOnly />
                    </li>
                    <li className="col-md-6">
                      <label>City</label>
                      <input type="text" name="" value={vendorFormData.city} className="form-control" readOnly />
                    </li> */}
                      <li className="col-md-4">
                        <label>PAN Number</label>
                        <input
                          type="text"
                          name=""
                          readOnly={vendorDetail?.data?.pan_number ? true : false}
                          value={vendorFormData.panNumber}
                          className={vendorFormData.panNumber ? `form-control green-form-control` : `form-control`}
                          onChange={e => handleInputChange("panNumber", e.target.value)}
                        />
                        {validationErrors.panNumber && <span className="validationErrorMsg">{validationErrors.panNumber}</span>}
                      </li>
                      <li className="col-md-12">
                        {loading ? (
                          <input type="submit" name="" value="Please wait.." className="btn btn-primary CmnBtn" />
                        ) : (
                          <input type="submit" name="" value="Save" className="btn btn-primary CmnBtn" onClick={() => handleEditVendorDetails()} />
                        )}
                      </li>
                    </ul>
                  </div>
                ) : null}

                {showSection === "changePassword" ? (
                  <div className="normal-accrounts-sec">
                    <h3>Change Password</h3>
                    <ul className="row edit-profile-form-list">
                      <li className="col-md-4">
                        <label>Existing Password</label>
                        <input type="password" name="" value="" placeholder="Existing Password" className="form-control" />
                      </li>
                      <li className="col-md-4">
                        <label>New Password</label>
                        <input type="password" name="" value="" placeholder="New Password" className="form-control" />
                      </li>
                      <li className="col-md-4">
                        <label>Confirm New Password</label>
                        <input type="password" name="" value="" placeholder="Confirm New Password" className="form-control" />
                      </li>
                      <li className="col-md-12">
                        <input type="submit" name="" value="Save" className="btn btn-primary CmnBtn" />
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              {/* {vendorDetail?.data.is_verified !== 1 && checkIsVerified !== 1 ? ( */}
              {checkIsVerified !== 1 ? (
                <div className="OverlayTextSec">
                  <span>Your Account is Not Verified Yet.</span>
                </div>
              ) : null}
            </div>

            {/* Add Bank Modal Start */}
            <Modal show={show} onHide={handleClose} className="CmnModal AddBankdModal">
              <Modal.Header closeButton>
                <Modal.Title>{!vendorDetail?.data?.bank_account_number ? "Add Bank Details" : "Update Bank Details"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul className="row company-form-list">
                  <li className="col-md-12">
                    <label>Full Name*</label>
                    <input
                      type="text"
                      name=""
                      value={bankFormData?.fullName}
                      placeholder="Full Name*"
                      className="form-control"
                      onChange={e => handleBankDetailsInputChange("fullName", e.target.value)}
                    />
                    {bankValidationErrors.fullName && <span className="validationErrorMsg">{bankValidationErrors.fullName}</span>}
                  </li>
                  <li className="col-md-6">
                    <label>Bank Account No.*</label>
                    <input
                      type="text"
                      name=""
                      value={bankFormData?.accNumber}
                      placeholder="Bank Account No.*"
                      className="form-control"
                      onChange={e => handleBankDetailsInputChange("accNumber", e.target.value)}
                    />
                    {bankValidationErrors.accNumber && <span className="validationErrorMsg">{bankValidationErrors.accNumber}</span>}
                  </li>
                  <li className="col-md-6">
                    <label>IFSC Code*</label>
                    <input
                      type="text"
                      name=""
                      value={bankFormData?.ifscCode}
                      placeholder="IFSC Code*"
                      className="form-control"
                      onChange={e => handleBankDetailsInputChange("ifscCode", e.target.value)}
                    />
                    {bankValidationErrors.ifscCode && <span className="validationErrorMsg">{bankValidationErrors.ifscCode}</span>}
                  </li>
                  <li className="col-md-6">
                    <label>Bank Name*</label>
                    <input
                      type="text"
                      name=""
                      value={bankFormData?.bankName}
                      placeholder="Bank Name*"
                      className="form-control"
                      onChange={e => handleBankDetailsInputChange("bankName", e.target.value)}
                    />
                    {bankValidationErrors.bankName && <span className="validationErrorMsg">{bankValidationErrors.bankName}</span>}
                  </li>
                  <li className="col-md-6">
                    <label>Branch Name*</label>
                    <input
                      type="text"
                      name=""
                      value={bankFormData?.branchName}
                      placeholder="Branch Name*"
                      className="form-control"
                      onChange={e => handleBankDetailsInputChange("branchName", e.target.value)}
                    />
                    {bankValidationErrors.branchName && <span className="validationErrorMsg">{bankValidationErrors.branchName}</span>}
                  </li>
                  <li className="col-md-6">
                    <label>Upload Cancelled Check*</label>
                    <div className="upload-file-box">
                      <input
                        type="file"
                        className="input-file"
                        accept="image/*,application/pdf"
                        onChange={e => handleBankDetailsInputChange("cancelCheque", e.target.files[0])}
                      />
                      <input
                        type="text"
                        name=""
                        readOnly
                        value={bankFormData?.cancelCheque?.name && bankFormData?.cancelCheque?.name !== "undefined" ? bankFormData?.cancelCheque?.name : ""}
                        placeholder="Upload Cancelled Check*"
                        className="form-control"
                      />
                      <button variant="primary" className="upload-btn">
                        Upload
                      </button>
                    </div>
                    {bankValidationErrors.cancelCheque && <span className="validationErrorMsg">{bankValidationErrors.cancelCheque}</span>}
                  </li>
                  {/* <li className="col-md-6">
                    <label>Upload Authorization Letter</label>
                    <div className="upload-file-box">
                      <input
                        type="file"
                        className="input-file"
                        aaccept="image/*,application/pdf"
                        onChange={e => handleBankDetailsInputChange("authorizationLetter", e.target.files[0])}
                      />
                      <input
                        type="text"
                        name=""
                        readOnly
                        value={
                          bankFormData?.authorizationLetter?.name && bankFormData?.authorizationLetter?.name !== "undefined"
                            ? bankFormData?.authorizationLetter?.name
                            : ""
                        }
                        placeholder="Authorization Letter"
                        className="form-control"
                      />
                      <button variant="primary" className="upload-btn">
                        Upload
                      </button>
                    </div>
                    {bankValidationErrors.authorizationLetter && <span className="validationErrorMsg">{bankValidationErrors.authorizationLetter}</span>}
                  </li> */}
                  <li className="col-md-12">
                    <label>Captcha</label>
                    <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
                    {bankValidationErrors.captchaError && <span className="validationErrorMsg">{bankValidationErrors.captchaError}</span>}
                  </li>
                </ul>
              </Modal.Body>
              <Modal.Footer>
                {bankButtonDis ? (
                  <button variant="primary" disabled className="btn btn-primary CmnBtn">
                    Please wait ...
                  </button>
                ) : (
                  <button variant="primary" onClick={() => handleSaveBankDetails()} className="btn btn-primary CmnBtn">
                    {!vendorDetail?.data?.bank_account_number ? "Add" : "Update"}
                  </button>
                )}
              </Modal.Footer>
            </Modal>
            {/* Add Bank Modal End */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorAccountSetting;
