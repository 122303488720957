import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {addAgentApi, customerRegisterApi, getCityListApi, getStatesListApi, updateOtpApi, verifyEmailOrMobileApi} from "../services/ApiService";
import {Typeahead} from "react-bootstrap-typeahead";
import {NotificationManager} from "react-notifications";
import {useUserContext} from "../context/UserContext";
import {Modal} from "react-bootstrap";
import VendorRegisteredSuccessIcon from "../assets/img/Vendor-registered-success-icon.svg";
import {CAPTCHA_SITE_KEY, IMAGE_URL} from "../utils/commonUrls";
import ReCAPTCHA from "react-google-recaptcha";

const google = require("./../assets/img/google-icon.svg").default;
const facebook = require("./../assets/img/facebook-icon2.svg").default;
const crossIcon2 = require("./../assets/img/cross.svg").default;

const AgentRegistration = () => {
  const navigate = useNavigate();
  const {from, responseDataForLocal} = useLocation().state ?? {};
  const {setUserData} = useUserContext();
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [cityVal, setCityVal] = useState();
  const [stateVal, setStateVal] = useState();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
    profileImage: "",
    panNumber: "",
    panImage: "",
    authorizationLetter: "",
    bankFullName: "",
    bankAccountNo: "",
    bankAccountNoConfirm: "",
    bankIfscCode: "",
    bankName: "",
    bankBranchName: "",
    bankCancelledCheck: "",
    bankAuthorizationLetter: "",
    checkBoxOneValue: false,
    checkBoxTwoValue: false,
  });
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
    profileImage: "",
    panNumber: "",
    panImage: "",
    authorizationLetter: "",
    bankFullName: "",
    bankAccountNo: "",
    bankAccountNoConfirm: "",
    bankIfscCode: "",
    bankName: "",
    bankBranchName: "",
    bankCancelledCheck: "",
    bankAuthorizationLetter: "",
    checkBoxOneValue: false,
    checkBoxTwoValue: false,
  });
  const [responseStateValue, setResponseStateValue] = useState(useLocation().state?.responseValue ?? null);
  const [verifyOtpResponse, setVerifyOtpResponse] = useState(useLocation().state?.verifyOtpResponse ?? null);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [timer, setTimer] = useState(60);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [changeNumberResponse, setChangeNumberResponse] = useState();
  const [successMsg, setSuccessMsg] = useState("");
  const [verifyBtnDis, setVerifyBtnDis] = useState(false);
  const [secondVerified, setSecondVerified] = useState(false);
  const [onSendRequestSuccessPopup, setOnSendRequestSuccessPopup] = useState(false);
  const [recountTimer, setRecountTimer] = useState(false);
  const [clickedType, setClickedType] = useState();
  const [captchaChecked, setCaptchaChecked] = useState(false);

  const handleKeyPress = event => {
    if (event.key === "Enter" && !loading) {
      handleRegister();
    }
  };

  const getStateList = async () => {
    try {
      let response = await getStatesListApi();
      if (response) {
        if (response.data.res === true) {
          setStateList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getCityList = async stateId => {
    try {
      const fd = new FormData();
      if (stateId) {
        fd.append("state[]", stateId);
      }
      let response = await getCityListApi(fd);
      if (response) {
        if (response.data.res === true) {
          setCityList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const handleStateChange = value => {
    setStateVal(value);
    getCityList(value[0]?.id);
  };

  const validateField = (field, value) => {
    // Perform validation based on your requirements
    switch (field) {
      case "firstName":
        return value.trim() === "" ? "Please enter your First Name." : "";
      case "lastName":
        return value.trim() === "" ? "Please enter your Last Name." : "";
      case "mobileNumber":
        return !/^\d{10}$/.test(value) ? "Please enter a valid 10-digit Mobile Number." : "";
      case "emailAddress":
        return !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(value) ? "Please enter a valid Email Address." : "";
      case "zipCode":
        return value.trim() === "" ? "Please enter your Zip Code." : "";
      case "gender":
        return value.trim() === "" ? "Please enter your Gender." : "";
      case "state":
        return !value || value.length === 0 ? "Please select a State." : "";
      case "city":
        return !value || value.length === 0 ? "Please select a City." : "";
      default:
        return "";
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);

      // Set focus on the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4);

    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      for (let i = 0; i < 4; i++) {
        newOtp[i] = pastedData[i] || "";
      }
      return newOtp;
    });

    if (pastedData?.length < 4) {
      inputRefs[pastedData?.length].current.focus();
    } else {
      inputRefs[3].current.focus();
    }

    // Check if all four digits are present in the pasted data
    if (pastedData.length === 4 && pastedData.match(/^\d{4}$/)) {
      let newValue = pastedData.split("");
      handleOtpSubmit(newValue);
    }
  };

  const handleOtpInputChange = (index, e) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    // Check if all OTP digits are entered
    if (index === inputRefs.length - 1 && newOtp.every(digit => digit !== "")) {
      setValidationError("");
      handleOtpSubmit(newOtp);
    }
  };

  const handleSendOtp = async type => {
    setClickedType(type);
    setRecountTimer(!recountTimer);
    if (type === "mobile") {
      if (formData.mobileNumber === "" || !/^\d{10}$/.test(formData.mobileNumber)) {
        NotificationManager.warning("Please enter a valid phone number.");
        return false;
      } else {
        const isValid = /^\d{10}$/.test(formData.mobileNumber);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid phone number.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let fd = new FormData();
            fd.append("user_id", responseStateValue.agent);
            fd.append("mobile", formData.mobileNumber);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                setTimer(60);
                // setSecondVerified(true);
                inputRefs[0].current.focus();
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else if (type === "email") {
      if (formData.emailAddress === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress)) {
        NotificationManager.warning("Please enter a valid Email Address.");
        return false;
      } else {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid email.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let fd = new FormData();
            fd.append("user_id", responseStateValue.agent);
            fd.append("email", formData.emailAddress);
            setTimer(60);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                // setSecondVerified(true);
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else {
    }
  };

  const handleResendOTP = async () => {
    setRecountTimer(!recountTimer);
    let type = clickedType;
    if (type === "mobile") {
      if (formData.mobileNumber === "" || !/^\d{10}$/.test(formData.mobileNumber)) {
        NotificationManager.warning("Please enter a valid phone number.");
        return false;
      } else {
        const isValid = /^\d{10}$/.test(formData.mobileNumber);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid phone number.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let fd = new FormData();
            fd.append("user_id", responseStateValue.agent);
            fd.append("mobile", formData.mobileNumber);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                setTimer(60);
                // setSecondVerified(true);
                inputRefs[0].current.focus();
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else if (type === "email") {
      if (formData.emailAddress === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress)) {
        NotificationManager.warning("Please enter a valid Email Address.");
        return false;
      } else {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid email.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let fd = new FormData();
            fd.append("user_id", responseStateValue.agent);
            fd.append("email", formData.emailAddress);
            setTimer(60);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                // setSecondVerified(true);
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else {
    }
  };

  const handleOtpSubmit = async otp => {
    if (otp.every(digit => digit === "")) {
      setValidationError("Please enter Your Otp.");
    } else {
      let newVal = otp.join("");
      setValidationError("");
      // setLoading(true);
      try {
        let fd = new FormData();
        fd.append("user_id", responseStateValue.agent);
        if (responseDataForLocal?.data?.email) {
          fd.append("email", responseDataForLocal?.data?.email);
          fd.append("mobile", formData?.mobileNumber);
        }
        if (responseDataForLocal?.data?.mobile) {
          fd.append("mobile", responseDataForLocal?.data?.mobile);
          fd.append("email", formData?.emailAddress);
        }
        fd.append("otp", newVal);

        let response = await verifyEmailOrMobileApi(fd);
        if (response) {
          if (response.data.res === true) {
            setValidationError("");
            // setSuccessMsg(response.data.msg);
            NotificationManager.success(response.data.msg);
            setShowOtpPopup(false);
            setSecondVerified(true);
          } else {
            setValidationError(response.data.msg);
            setSuccessMsg("");
          }
        }
      } catch (error) {}
    }
  };

  const onCaptchaChange = value => {
    setCaptchaChecked(true);
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      emailAddress: "",
      address: "",
      profileImage: "",
      panNumber: "",
      panImage: "",
      authorizationLetter: "",
      bankFullName: "",
      bankAccountNo: "",
      bankAccountNoConfirm: "",
      bankIfscCode: "",
      bankName: "",
      bankBranchName: "",
      bankCancelledCheck: "",
      bankAuthorizationLetter: "",
      checkBoxOneValue: false,
      checkBoxTwoValue: false,
    };

    if (formData.firstName === "") {
      // errors.firstName = "Please enter your First Name.";
      NotificationManager.warning("Please enter your First Name.");
      isValid = false;
    } else if (formData.lastName === "") {
      // errors.lastName = "Please enter your Last Name.";
      NotificationManager.warning("Please enter your Last Name.");
      isValid = false;
    } else if (formData.mobileNumber === "" || !/^\d{10}$/.test(formData.mobileNumber)) {
      // errors.mobileNumber = "Please enter a valid 10-digit Mobile Number.";
      NotificationManager.warning("Please enter a valid 10-digit Mobile Number.");
      isValid = false;
    } else if (formData.emailAddress === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress)) {
      // errors.emailAddress = "Please enter a valid Email Address.";
      NotificationManager.warning("Please enter a valid Email Address.");
      isValid = false;
    } else if (formData.address === "") {
      // errors.address = "Please enter your Last Name.";
      NotificationManager.warning("Please enter your address.");
      isValid = false;
    } else if (formData.profileImage === "") {
      // errors.profileImage = "Please choose profile photo.";
      NotificationManager.warning("Please choose profile photo.");
      isValid = false;
    } else if (formData.panNumber === "") {
      // errors.panNumber = "Please enter pan number.";
      NotificationManager.warning("Please enter pan number.");
      isValid = false;
    } else if (formData.panImage === "") {
      // errors.panImage = "Please choose pan photo.";
      NotificationManager.warning("Please choose pan photo.");
      isValid = false;
      // } else if (formData.authorizationLetter === "") {
      //   // errors.authorizationLetter = "Please choose pan photo.";
      //   NotificationManager.warning("Please choose authorization letter.");
      isValid = false;
    } else if (formData.bankFullName === "") {
      // errors.bankFullName = "Please enter full name.";
      NotificationManager.warning("Please enter bank details full name.");
      isValid = false;
    } else if (formData.bankAccountNo === "") {
      // errors.bankAccountNo = "Please enter account no.";
      NotificationManager.warning("Please enter bank account no.");
      isValid = false;
    } else if (formData.bankAccountNoConfirm === "") {
      // errors.bankAccountNo = "Please enter account no.";
      NotificationManager.warning("Please enter confirm bank account no.");
      isValid = false;
    } else if (formData.bankAccountNo !== formData.bankAccountNoConfirm) {
      // errors.bankAccountNo = "Please enter account no.";
      NotificationManager.warning("The bank account number and confirm account number do not match.");
      isValid = false;
    } else if (formData.bankIfscCode === "") {
      // errors.bankIfscCode = "Please enter IFSC code.";
      NotificationManager.warning("Please enter bank IFSC code.");
      isValid = false;
    } else if (formData.bankName === "") {
      // errors.bankName = "Please enter bank name.";
      NotificationManager.warning("Please enter bank name.");
      isValid = false;
    } else if (formData.bankBranchName === "") {
      // errors.bankBranchName = "Please enter branch name.";
      NotificationManager.warning("Please enter bank branch name.");
      isValid = false;
    } else if (formData.bankCancelledCheck === "") {
      // errors.bankCancelledCheck = "Please choose cancelled check.";
      NotificationManager.warning("Please choose bank cancelled check.");
      isValid = false;
      // } else if (formData.bankAuthorizationLetter === "") {
      //   // errors.bankAuthorizationLetter = "Please choose authorization letter.";
      //   NotificationManager.warning("Please choose bank authorization letter.");
      //   isValid = false;
    } else if (!secondVerified) {
      NotificationManager.warning(`Please verify your ${!responseDataForLocal?.data?.mobile ? "phone number" : "email address"}.`);
      isValid = false;
    } else if (!formData.checkBoxOneValue) {
      NotificationManager.warning(`Please verify all your details and agree to the Terms and Conditions and TDS deduction before proceeding.`);
      isValid = false;
    } else if (!formData.checkBoxTwoValue) {
      NotificationManager.warning(`Please verify all your details and agree to the Terms and Conditions and TDS deduction before proceeding.`);
      isValid = false;
    } else if (!captchaChecked) {
      NotificationManager.warning(`Please check the 'I am not a robot' checkbox`);
      isValid = false;
    }

    // setValidationErrors(errors);
    return isValid;
  };

  const handleInputChange = (field, value) => {
    if (field === "profileImage") {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (value && !validImageTypes.includes(value.type)) {
        // setValidationErrors(prev => ({...prev, profileImage: "Please upload a valid image file (JPEG or PNG)."}));
        NotificationManager.warning("Please upload a valid image file (JPEG or PNG).");
        return;
      }
    }
    if (field === "panImage") {
      const validFileTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (value && !validFileTypes.includes(value.type)) {
        // setValidationErrors(prev => ({...prev, panImage: "Please upload a valid image file (JPEG or PNG)."}));
        NotificationManager.warning("Please upload a valid file (JPEG, PNG, or PDF).");
        return;
      }
    }
    if (field === "bankCancelledCheck") {
      const validFileTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (value && !validFileTypes.includes(value.type)) {
        // setValidationErrors(prev => ({...prev, bankCancelledCheck: "Please upload a valid image file (JPEG or PNG)."}));
        NotificationManager.warning("Please upload a valid file (JPEG, PNG, or PDF).");
        return;
      }
    }
    // if (field === "bankAuthorizationLetter") {
    //   const validFileTypes = ["image/jpeg", "image/png", "application/pdf"];
    //   if (value && !validFileTypes.includes(value.type)) {
    //     // setValidationErrors(prev => ({...prev, bankAuthorizationLetter: "Please upload a valid image file (JPEG or PNG)."}));
    //     NotificationManager.warning("Please upload a valid file (JPEG, PNG, or PDF).");
    //     return;
    //   }
    // }
    // if (field === "authorizationLetter") {
    //   const validFileTypes = ["image/jpeg", "image/png", "application/pdf"];
    //   if (value && !validFileTypes.includes(value.type)) {
    //     // setValidationErrors(prev => ({...prev, bankAuthorizationLetter: "Please upload a valid image file (JPEG or PNG)."}));
    //     NotificationManager.warning("Please upload a valid file (JPEG, PNG, or PDF).");
    //     return;
    //   }
    // }

    // Update form data
    setFormData(prevData => ({...prevData, [field]: value}));

    // Validate the field
    setValidationErrors(prevErrors => ({...prevErrors, [field]: ""}));
    // setValidationErrors(prevErrors => ({...prevErrors, [field]: validateField(field, value)}));
  };

  const handleRegister = async () => {
    // Validate all fields before proceeding
    // const errors = {};
    // Object.keys(formData).forEach(field => {
    //   errors[field] = validateField(field, formData[field]);
    // });

    // // Update validation errors state
    // setValidationErrors(errors);

    // Check if any validation errors exist
    // if (Object.values(errors).every(error => error === "")) {
    if (validateForm()) {
      setLoading(true);
      try {
        let apiFormData = new FormData();
        apiFormData.append("user_id", responseStateValue.agent);
        apiFormData.append("first_name", formData.firstName);
        apiFormData.append("last_name", formData.lastName);
        apiFormData.append("email", formData.emailAddress);
        apiFormData.append("mobile", formData.mobileNumber);
        apiFormData.append("address", formData.address);
        apiFormData.append("pan_number", formData.panNumber);
        apiFormData.append("pan_card_file", formData.panImage);
        // apiFormData.append("authorization_file", formData.authorizationLetter);
        apiFormData.append("profile_img", formData.profileImage);
        apiFormData.append("bank_person_name", formData.bankFullName);
        apiFormData.append("bank_acc_no", formData.bankAccountNo);
        apiFormData.append("ifsc_code", formData.bankIfscCode);
        apiFormData.append("bank_name", formData.bankName);
        apiFormData.append("branch_name", formData.bankBranchName);
        apiFormData.append("cancelled_cheque", formData.bankCancelledCheck);
        // apiFormData.append("authorization_letter", formData.bankAuthorizationLetter);
        // Api call
        let response = await addAgentApi(apiFormData);
        if (response) {
          if (response.data.res === true) {
            responseDataForLocal.data.email = formData.emailAddress;
            responseDataForLocal.data.mobile = formData.mobileNumber;
            localStorage.setItem("WMTUserData", JSON.stringify(responseDataForLocal));
            localStorage.setItem("WMTLastActivityTimestamp", Date.now());
            setUserData(response.data);
            setOnSendRequestSuccessPopup(true);
            NotificationManager.success(response.data.msg);
          } else {
            setValidationError(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        NotificationManager.error("Something went wrong. Please try again later.");
      }
    }
  };

  const handleContinueClickFromModal = () => {
    if (from) {
      navigate(from);
    } else {
      navigate("/partners/profile");
    }
    setOnSendRequestSuccessPopup(false);
  };

  // Resend Counter useEffect
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [recountTimer]);

  useEffect(() => {
    if (showOtpPopup) {
      inputRefs[0].current.focus();
    }
  }, [showOtpPopup]);

  useEffect(() => {
    if (verifyOtpResponse) {
      let tempFormData = {...formData};
      if (verifyOtpResponse?.email !== null) {
        tempFormData.emailAddress = verifyOtpResponse?.email;
      } else if (verifyOtpResponse?.mobile !== null) {
        tempFormData.mobileNumber = verifyOtpResponse?.mobile;
      } else {
      }
      setFormData(tempFormData);
    }
  }, []);

  return (
    <div className="main">
      <div className="innerDiv RegistrationInnerDiv">
        <div className="mainDiv">
          <div className="VendorRegistrationLogoSec VendorRegistrationLogoSec2">
            <div className="loginDiv agentDiv">
              <h2>Agent Registration</h2>

              <ul className="row VendorRegistrationList">
                <li className="col-md-12">
                  <h4>Personal Information</h4>
                </li>
                {/* <ul className="loginList"> */}
                <li className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    onKeyDown={handleKeyPress}
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={e => handleInputChange("firstName", e.target.value)}
                  />
                  {validationErrors.firstName && <span className="validationErrorMsg">{validationErrors.firstName}</span>}
                </li>
                <li className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    onKeyDown={handleKeyPress}
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={e => handleInputChange("lastName", e.target.value)}
                  />
                  {validationErrors.lastName && <span className="validationErrorMsg">{validationErrors.lastName}</span>}
                </li>
                <li className="col-md-6">
                  <input
                    type="text"
                    className="form-control paddingRight"
                    onKeyDown={handleKeyPress}
                    placeholder="Mobile Number"
                    value={formData.mobileNumber}
                    disabled={responseDataForLocal?.data?.mobile !== null || secondVerified}
                    onChange={e => handleInputChange("mobileNumber", e.target.value)}
                  />
                  {responseDataForLocal?.data?.mobile === null && formData?.mobileNumber?.length === 10 && !secondVerified ? (
                    <>
                      <button className="verifyLink" onClick={() => handleSendOtp("mobile")} disabled={verifyBtnDis}>
                        Verify
                      </button>
                    </>
                  ) : null}
                  {responseDataForLocal?.data?.mobile === null && secondVerified ? <span className="otpVerified">Verified</span> : null}
                  {validationErrors.mobileNumber && <span className="validationErrorMsg">{validationErrors.mobileNumber}</span>}
                </li>
                <li className="col-md-6">
                  <input
                    type="text"
                    className="form-control paddingRight"
                    onKeyDown={handleKeyPress}
                    placeholder="Email Address"
                    value={formData.emailAddress}
                    disabled={responseDataForLocal?.data?.email !== null || secondVerified}
                    onChange={e => handleInputChange("emailAddress", e.target.value)}
                  />
                  {responseDataForLocal?.data?.email === null && !secondVerified && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress) ? (
                    <>
                      <button className="verifyLink" onClick={() => handleSendOtp("email")} disabled={verifyBtnDis}>
                        Verify
                      </button>
                    </>
                  ) : null}
                  {responseDataForLocal?.data?.email === null && secondVerified ? <span className="otpVerified">Verified</span> : null}
                  {validationErrors.emailAddress && <span className="validationErrorMsg">{validationErrors.emailAddress}</span>}
                </li>
                <li className="col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    onKeyDown={handleKeyPress}
                    placeholder="Address"
                    value={formData.address}
                    onChange={e => handleInputChange("address", e.target.value)}
                  />
                  {validationErrors.address && <span className="validationErrorMsg">{validationErrors.address}</span>}
                </li>
                <li className="col-md-6">
                  <div className="upload-file-box">
                    <input type="file" className="input-file" accept="image/*" onChange={e => handleInputChange("profileImage", e.target.files[0])} />
                    <input
                      type="text"
                      name=""
                      value={formData?.profileImage?.name?.length > 23 ? formData?.profileImage?.name?.slice(0, 23) + "..." : formData?.profileImage?.name}
                      // value={vendorFormData?.vendorImage?.name}
                      placeholder="Upload Profile Image"
                      class="form-control disabled-form-control"
                      disabled
                    />
                    <button variant="primary" className="upload-btn">
                      Upload
                    </button>
                  </div>
                  {validationErrors.profileImage && <span className="validationErrorMsg">{validationErrors.profileImage}</span>}
                </li>
                <li className="col-md-6">
                  <input type="text" placeholder="PAN Number" className="form-control" onChange={e => handleInputChange("panNumber", e.target.value)} />
                  {validationErrors.panNumber && <span className="validationErrorMsg">{validationErrors.panNumber}</span>}
                </li>
                <li className="col-md-6">
                  <div className="upload-file-box">
                    <input
                      type="file"
                      className="input-file"
                      accept="image/*, application/pdf"
                      onChange={e => handleInputChange("panImage", e.target.files[0])}
                    />
                    <input
                      type="text"
                      name=""
                      value={formData?.panImage?.name?.length > 23 ? formData?.panImage?.name?.slice(0, 23) + "..." : formData?.panImage?.name}
                      placeholder="Upload PAN"
                      class="form-control disabled-form-control"
                      disabled
                    />
                    <button variant="primary" className="upload-btn">
                      Upload
                    </button>
                  </div>
                  {validationErrors.panImage && <span className="validationErrorMsg">{validationErrors.panImage}</span>}
                </li>
                {/* <li className="col-md-6">
                  <div className="upload-file-box">
                    <input type="file" className="input-file" accept="image/*, application/pdf" onChange={e => handleInputChange("authorizationLetter", e.target.files[0])} />
                    <input
                      type="text"
                      name=""
                      value={
                        formData?.authorizationLetter?.name?.length > 23
                          ? formData?.authorizationLetter?.name?.slice(0, 23) + "..."
                          : formData?.authorizationLetter?.name
                      }
                      placeholder="Authorization Document"
                      class="form-control disabled-form-control"
                      disabled
                    />
                    <button variant="primary" className="upload-btn">
                      Upload
                    </button>
                    <span className="infoText">
                      Please download the{" "}
                      <a href={`${IMAGE_URL + "authorization_letter.pdf"}`} target="_blank" download="Authorization_letter.pdf">
                        authorization letter
                      </a>
                      , sign it, and then upload your scanned document.
                    </span>
                  </div>
                  {validationErrors.panImage && <span className="validationErrorMsg">{validationErrors.panImage}</span>}
                </li> */}
                <li className="col-md-12">
                  <h4>Bank Details</h4>
                </li>
                <li className="col-md-12">
                  <input type="text" placeholder="Full Name" className="form-control" onChange={e => handleInputChange("bankFullName", e.target.value)} />
                  {validationErrors.bankFullName && <span className="validationErrorMsg">{validationErrors.bankFullName}</span>}
                </li>
                <li className="col-md-6">
                  <input type="text" placeholder="Account Number" className="form-control" onChange={e => handleInputChange("bankAccountNo", e.target.value)} />
                  {validationErrors.bankAccountNo && <span className="validationErrorMsg">{validationErrors.bankAccountNo}</span>}
                </li>
                <li className="col-md-6">
                  <input
                    type="text"
                    placeholder="Confrim Account Number"
                    className="form-control"
                    onChange={e => handleInputChange("bankAccountNoConfirm", e.target.value)}
                  />
                  {validationErrors.bankAccountNoConfirm && <span className="validationErrorMsg">{validationErrors.bankAccountNoConfirm}</span>}
                </li>
                <li className="col-md-6">
                  <input type="text" placeholder="IFSC Code" className="form-control" onChange={e => handleInputChange("bankIfscCode", e.target.value)} />
                  {validationErrors.bankIfscCode && <span className="validationErrorMsg">{validationErrors.bankIfscCode}</span>}
                </li>
                <li className="col-md-6">
                  <input type="text" placeholder="Bank Name" className="form-control" onChange={e => handleInputChange("bankName", e.target.value)} />
                  {validationErrors.bankName && <span className="validationErrorMsg">{validationErrors.bankName}</span>}
                </li>
                <li className="col-md-6">
                  <input type="text" placeholder="Branch Name" className="form-control" onChange={e => handleInputChange("bankBranchName", e.target.value)} />
                  {validationErrors.bankBranchName && <span className="validationErrorMsg">{validationErrors.bankBranchName}</span>}
                </li>
                <li className="col-md-6">
                  <div className="upload-file-box">
                    <input
                      type="file"
                      className="input-file"
                      accept="image/*, application/pdf"
                      onChange={e => handleInputChange("bankCancelledCheck", e.target.files[0])}
                    />
                    <input
                      type="text"
                      name=""
                      value={
                        formData?.bankCancelledCheck?.name?.length > 23
                          ? formData?.bankCancelledCheck?.name?.slice(0, 23) + "..."
                          : formData?.bankCancelledCheck?.name
                      }
                      placeholder="Cancelled Check"
                      class="form-control disabled-form-control"
                      disabled
                    />
                    <button variant="primary" className="upload-btn">
                      Upload
                    </button>
                  </div>
                  {validationErrors.bankCancelledCheck && <span className="validationErrorMsg">{validationErrors.bankCancelledCheck}</span>}
                </li>
                {/* <li className="col-md-6">
                  <div className="upload-file-box">
                    <input
                      type="file"
                      className="input-file"
                      accept="image/*, application/pdf"
                      onChange={e => handleInputChange("bankAuthorizationLetter", e.target.files[0])}
                    />
                    <input
                      type="text"
                      name=""
                      value={
                        formData?.bankAuthorizationLetter?.name?.length > 23
                          ? formData?.bankAuthorizationLetter?.name?.slice(0, 23) + "..."
                          : formData?.bankAuthorizationLetter?.name
                      }
                      placeholder="Authorization Letter"
                      class="form-control disabled-form-control"
                      disabled
                    />
                    <button variant="primary" className="upload-btn">
                      Upload
                    </button>
                  </div>
                  {validationErrors.bankAuthorizationLetter && <span className="validationErrorMsg">{validationErrors.bankAuthorizationLetter}</span>}
                </li> */}
                <li>
                  <label class="checkCustom">
                    I hereby confirm that I have thoroughly reviewed and verified all the information provided above.
                    <input
                      type="checkbox"
                      value={formData.checkBoxOneValue}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          checkBoxOneValue: e.target.checked,
                        })
                      }
                    />
                    <span class="checkmark agentReg"></span>
                  </label>
                </li>
                <li>
                  <label class="checkCustom">
                    I agree to the{" "}
                    <Link to={"/terms-of-use"} target="_blank">
                      Terms and Condition{" "}
                    </Link>
                    and acknowledge the applicable TDS deductions.
                    <input
                      type="checkbox"
                      value={formData.checkBoxTwoValue}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          checkBoxTwoValue: e.target.checked,
                        })
                      }
                    />
                    <span class="checkmark agentReg"></span>
                  </label>
                </li>
                <li>
                  <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
                </li>
                <li>
                  {loading ? (
                    <button disabled className="btn btn-primary CmnBtn">
                      Please wait...
                    </button>
                  ) : (
                    <button className="btn btn-primary CmnBtn" onClick={() => handleRegister()}>
                      Register Now
                    </button>
                  )}
                  {validationError && <p className="validationErrorMsg">{validationError}</p>}
                </li>
              </ul>

              <div className="orDiv">
                <span>Or Login/Signup With</span>
              </div>
              <p>
                <Link>
                  <img src={google} alt="" />
                </Link>
                <Link>
                  <img src={facebook} alt="" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal show={onSendRequestSuccessPopup} className="CmnModal SendRequestModal">
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="congratsClass">
            <img src={VendorRegisteredSuccessIcon} alt="" className="SuccessIconImg" />
            <h2>Registration Successful</h2>
            <p>Congratulations! Your registration has been successfully processed. You are now officially registered as an agent. Welcome to our team!</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="modalFooterButton" onClick={() => handleContinueClickFromModal()}>
            Continue to your dashboard
          </button>
        </Modal.Footer>
      </Modal>

      <div className="hotelSliderPopup" style={{display: showOtpPopup ? "block" : "none"}}>
        <div className="sliderflex">
          <div className="popInner loginDetailsOtp loginDiv">
            <h2>OTP Verification</h2>
            {clickedType === "mobile" ? <h3>Check text messages for your OTP</h3> : <h3>Check email inbox for your OTP</h3>}

            {/* <h5>Your Otp is - {changeNumberResponse}</h5> */}
            <ul className="otpList">
              <li>
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={e => handleOtpInputChange(index, e)}
                    onKeyUp={e => handleBackspace(index, e)}
                    onPaste={handlePaste}
                    ref={inputRefs[index]}
                  />
                ))}
              </li>
              <li>00:{parseInt(timer) < 10 ? "0" + timer : timer}</li>
              <li>
                <h4>
                  Not Received OTP?{" "}
                  {parseInt(timer) === 0 ? (
                    <Link to="javascript:void(0);" onClick={() => handleResendOTP()}>
                      Resend Now
                    </Link>
                  ) : (
                    "Resend Now"
                  )}
                </h4>
                {validationError && <p className="validationErrorMsg">{validationError}</p>}
                {successMsg && <p className="successMsg">{successMsg}</p>}
              </li>
              <li>
                <button onClick={() => handleOtpSubmit(otp)}>Submit</button>
              </li>
            </ul>
            <button className="closePopupBtn" onClick={() => setShowOtpPopup(false)}>
              <img src={crossIcon2} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentRegistration;
