import React from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

const Error = () => {
  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Wish My Tour`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Home page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <section class="page_404">
          <div class="col-sm-12 text-center">
            <div class="four_zero_four_bg">
              <h1 class="text-center ">404</h1>
            </div>

            <div class="contant_box_404">
              <h3 class="h2">Look like you're lost</h3>

              <p>the page you are looking for not avaible!</p>

              <Link to={"/"} class="link_404">
                Go to Home
              </Link>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Error;
