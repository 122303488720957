import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {loginApi} from "../services/ApiService";
import {Helmet} from "react-helmet";
import {CAPTCHA_SITE_KEY} from "../utils/commonUrls";
import ReCAPTCHA from "react-google-recaptcha";

const google = require("./../assets/img/google-icon.svg").default;
const facebook = require("./../assets/img/facebook-icon2.svg").default;

const Login = () => {
  const navigate = useNavigate();
  const {from} = useLocation().state ?? {};
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaChecked, setCaptchaChecked] = useState(false);

  const handleInputChange = e => {
    setEmailOrMobile(e.target.value);
  };

  const handleKeyPress = event => {
    if (event.key === "Enter" && !loading) {
      handleContinueWithOTP();
    }
  };

  const onCaptchaChange = value => {
    setCaptchaChecked(true);
    setValidationError("");
  };

  const handleContinueWithOTP = async () => {
    const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(emailOrMobile) || /^\d{10}$/.test(emailOrMobile);
    if (!emailOrMobile) {
      setValidationError(`Please enter email or phone number.`);
    } else if (!isValid) {
      setValidationError("Please enter a valid email or mobile number.");
    } else if (!captchaChecked) {
      setValidationError(`Please check the 'I am not a robot' checkbox.`);
    } else {
      setValidationError("");
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("login", emailOrMobile);
        // Api call
        let response = await loginApi(formData);
        if (response) {
          if (response.data.res === true) {
            navigate("/otp-verification", {state: {loginValue: emailOrMobile, responseValue: response.data.data, from: from}});
          } else {
            setValidationError(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Wish My Tour`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Home page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="loginDiv">
            <h2>Login/Signup</h2>

            <ul className="loginList">
              <li>
                <input type="text" onKeyDown={handleKeyPress} placeholder="Email or Mobile Number" value={emailOrMobile} onChange={handleInputChange} />
              </li>
              <li>
                <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
              </li>
              <li>
                {loading ? <button disabled>Please wait...</button> : <button onClick={() => handleContinueWithOTP()}>Continue with OTP</button>}

                {validationError && <p className="validationErrorMsg">{validationError}</p>}
              </li>
            </ul>

            <div className="orDiv">
              <span>Or Login/Signup With</span>
            </div>
            <p>
              <Link to="/">
                <img src={google} alt="" />
              </Link>
              <Link to="/">
                <img src={facebook} alt="" />
              </Link>
            </p>
            <p className="privacyText">
              By proceeding, you agree to WishMyTour's{" "}
              <Link to="/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
              ,{" "}
              <Link to={"/user-agreement"} target="_blank">
                User Agreement
              </Link>{" "}
              and{" "}
              <Link to="/terms-of-use" target="_blank">
                T&Cs
              </Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
