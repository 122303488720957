import React, {Component, useState, useRef, useEffect} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {Link, useNavigate} from "react-router-dom";

const transferIcon2 = require("./../assets/img/car-icon2.svg").default;
const mealIcon2 = require("./../assets/img/restaurant-icon2.svg").default;
const hotelIcon2 = require("./../assets/img/bed-icon2.svg").default;
const optionalIcon2 = require("./../assets/img/optional-icon2.svg").default;
const special1 = require("./../assets/img/special1.png");

const Offers = () => {
  return (
    <div className="main">
      <Header />

      <div className="innerDiv">
        <div className="pplrDstn offersDiv">
          <div className="mainDiv">
            <h2 className="offerHdn">Offers</h2>
            <div className="offerList">
              <div className="compareLoop">
                <div className="specialDiv">
                  <div className="specialBox">
                    <img src={special1} alt="Image 1" className="specialSliderImg" />
                    <div className="offerPerntge">20% Off</div>
                    <div className="bannerTxt">
                      <h2>Chadar And Lingshed Village Trek</h2>
                      <h4>
                        By: <span>Ayan Dutta Tour & Traveller</span>
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>
                        <strong>₹15,899</strong> ₹15,899 <span>(Per person)</span>
                      </h5>
                      <Link to="/">View Details</Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="compareLoop">
                <div className="specialDiv">
                  <div className="specialBox">
                    <img src={special1} alt="Image 1" className="specialSliderImg" />
                    <div className="offerPerntge">20% Off</div>
                    <div className="bannerTxt">
                      <h2>Chadar And Lingshed Village Trek</h2>
                      <h4>
                        By: <span>Ayan Dutta Tour & Traveller</span>
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>
                        <strong>₹15,899</strong> ₹15,899 <span>(Per person)</span>
                      </h5>
                      <Link to="/">View Details</Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="compareLoop">
                <div className="specialDiv">
                  <div className="specialBox">
                    <img src={special1} alt="Image 1" className="specialSliderImg" />
                    <div className="offerPerntge">20% Off</div>
                    <div className="bannerTxt">
                      <h2>Chadar And Lingshed Village Trek</h2>
                      <h4>
                        By: <span>Ayan Dutta Tour & Traveller</span>
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>
                        <strong>₹15,899</strong> ₹15,899 <span>(Per person)</span>
                      </h5>
                      <Link to="/">View Details</Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="compareLoop">
                <div className="specialDiv">
                  <div className="specialBox">
                    <img src={special1} alt="Image 1" className="specialSliderImg" />
                    <div className="offerPerntge">20% Off</div>
                    <div className="bannerTxt">
                      <h2>Chadar And Lingshed Village Trek</h2>
                      <h4>
                        By: <span>Ayan Dutta Tour & Traveller</span>
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>
                        <strong>₹15,899</strong> ₹15,899 <span>(Per person)</span>
                      </h5>
                      <Link to="/">View Details</Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="compareLoop">
                <div className="specialDiv">
                  <div className="specialBox">
                    <img src={special1} alt="Image 1" className="specialSliderImg" />
                    <div className="offerPerntge">20% Off</div>
                    <div className="bannerTxt">
                      <h2>Chadar And Lingshed Village Trek</h2>
                      <h4>
                        By: <span>Ayan Dutta Tour & Traveller</span>
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>
                        <strong>₹15,899</strong> ₹15,899 <span>(Per person)</span>
                      </h5>
                      <Link to="/">View Details</Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="compareLoop">
                <div className="specialDiv">
                  <div className="specialBox">
                    <img src={special1} alt="Image 1" className="specialSliderImg" />
                    <div className="offerPerntge">20% Off</div>
                    <div className="bannerTxt">
                      <h2>Chadar And Lingshed Village Trek</h2>
                      <h4>
                        By: <span>Ayan Dutta Tour & Traveller</span>
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>
                        <strong>₹15,899</strong> ₹15,899 <span>(Per person)</span>
                      </h5>
                      <Link to="/">View Details</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Link to="/packages" className="offersBtn">
              Load More
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
 
export default Offers;
