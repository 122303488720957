import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import {Link, useNavigate} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {NotificationManager} from "react-notifications";
import {
  getCustomerDetailsApi,
  getCityListApi,
  getStatesListApi,
  editCustomerDetailsApi,
  addPhotoIdApi,
  verifyEmailOrMobileApi,
  updateOtpApi,
} from "../services/ApiService";
import {Typeahead} from "react-bootstrap-typeahead";
import {Helmet} from "react-helmet";
import {useUserContext} from "../context/UserContext";

const crossIcon2 = require("./../assets/img/cross.svg").default;

const PersonalInformation = () => {
  const navigate = useNavigate();
  const {userData, setUserData} = useUserContext();
  const [customerData, setCustomerData] = useState();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
    zipCode: "",
    gender: "",
    state: null,
    city: null,
  });
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
    zipCode: "",
    gender: "",
    state: null,
    city: null,
  });
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [cityVal, setCityVal] = useState();
  const [stateVal, setStateVal] = useState();
  const [cardIdentityNumber, setCardIdentityNumber] = useState();
  const [cardIdentityType, setCardIdentityType] = useState();
  const [cardIdentityTypeError, setCardIdentityTypeError] = useState();
  const [cardIdentityNumberError, setCardIdentityNumberError] = useState();
  const [cardIdentityVerified, setCardIdentityVerified] = useState();
  const [cardDetailsFromServer, setCardDetailsFromServer] = useState(false);
  const [secondVerified, setSecondVerified] = useState(false);
  const [verifyBtnDis, setVerifyBtnDis] = useState(false);
  const [clickedType, setClickedType] = useState();
  const [recountTimer, setRecountTimer] = useState(false);
  const [onSendRequestSuccessPopup, setOnSendRequestSuccessPopup] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [timer, setTimer] = useState(60);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [changeNumberResponse, setChangeNumberResponse] = useState();
  const [successMsg, setSuccessMsg] = useState("");

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustomerData(response.data);
          let tempUserData = JSON.parse(localStorage.getItem("WMTUserData"));
          if (response.data.data.first_name) {
            tempUserData.data.fullname = response.data.data.first_name;
            localStorage.setItem("WMTUserData", JSON.stringify(tempUserData));
            setUserData(tempUserData);
          }
          setFormData({
            firstName: response.data.data.first_name,
            lastName: response.data.data.last_name,
            emailAddress: response.data.User.email,
            mobileNumber: response.data.User.mobile,
            address: response.data.data.address,
            gender: response.data.data.gender,
            zipCode: response.data.data.zipcode,
            state: response.data.data.state,
            city: response.data.data.city,
          });
          setCardIdentityType(response.data.data.id_type);
          // setCardIdentityVerified(1);
          setCardIdentityVerified(response.data.data.id_verified);
          setCardIdentityNumber(response.data.data.id_number);
          if (response.data.data.id_number) {
            setCardDetailsFromServer(true);
          }
          setCardIdentityNumber(response.data.data.id_number);
          getStateList(response.data.data.state);
          getCityList(response.data.data.state, response.data.data.city);
        }
      }
    } catch (error) {}
  };

  const getStateList = async id => {
    try {
      let response = await getStatesListApi();
      if (response) {
        if (response.data.res === true) {
          setStateList(response.data.data);
          let stateObj = response.data.data.filter(item => parseInt(item.id) === parseInt(id));
          setStateVal(stateObj);
        } else {
        }
      }
    } catch (error) {}
  };

  const getCityList = async (stateId, cityId) => {
    try {
      const formData = new FormData();
      if (stateId) {
        formData.append("state[]", stateId);
      }
      let response = await getCityListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setCityList(response.data.data);
          let cityObj = response.data.data.filter(item => parseInt(item.id) === parseInt(cityId));
          setCityVal(cityObj);
        } else {
        }
      }
    } catch (error) {}
  };

  const handleStateChange = value => {
    setStateVal(value);
    getCityList(value[0]?.id);
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);

      // Set focus on the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4);

    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      for (let i = 0; i < 4; i++) {
        newOtp[i] = pastedData[i] || "";
      }
      return newOtp;
    });

    if (pastedData?.length < 4) {
      inputRefs[pastedData?.length].current.focus();
    } else {
      inputRefs[3].current.focus();
    }

    // Check if all four digits are present in the pasted data
    if (pastedData.length === 4 && pastedData.match(/^\d{4}$/)) {
      let newValue = pastedData.split("");
      handleOtpSubmit(newValue);
    }
  };

  const handleOtpInputChange = (index, e) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    // Check if all OTP digits are entered
    if (index === inputRefs.length - 1 && newOtp.every(digit => digit !== "")) {
      setValidationError("");
      handleOtpSubmit(newOtp);
    }
  };

  const handleSendOtp = async type => {
    setClickedType(type);
    setRecountTimer(!recountTimer);
    if (type === "mobile") {
      if (formData.mobileNumber === "" || !/^\d{10}$/.test(formData.mobileNumber)) {
        NotificationManager.warning("Please enter a valid phone number.");
        return false;
      } else {
        const isValid = /^\d{10}$/.test(formData.mobileNumber);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid phone number.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let fd = new FormData();
            fd.append("user_id", customerData?.User?.id);
            fd.append("mobile", formData.mobileNumber);
            setTimer(60);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                // setSecondVerified(true);
                inputRefs[0].current.focus();
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else if (type === "email") {
      if (formData.emailAddress === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress)) {
        NotificationManager.warning("Please enter a valid Email Address.");
        return false;
      } else {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid email.");
        } else {
          setValidationError("");
          try {
            let fd = new FormData();
            fd.append("user_id", customerData?.User?.id);
            fd.append("email", formData.emailAddress);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                setTimer(60);
                // setSecondVerified(true);
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else {
    }
  };

  const handleResendOTP = async () => {
    setRecountTimer(!recountTimer);
    let type = clickedType;
    if (type === "mobile") {
      if (formData.mobileNumber === "" || !/^\d{10}$/.test(formData.mobileNumber)) {
        NotificationManager.warning("Please enter a valid phone number.");
        return false;
      } else {
        const isValid = /^\d{10}$/.test(formData.mobileNumber);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid phone number.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let fd = new FormData();
            fd.append("user_id", customerData?.User?.id);
            fd.append("mobile", formData.mobileNumber);
            setTimer(60);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                // setSecondVerified(true);
                inputRefs[0].current.focus();
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else if (type === "email") {
      if (formData.emailAddress === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress)) {
        NotificationManager.warning("Please enter a valid Email Address.");
        return false;
      } else {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid email.");
        } else {
          setValidationError("");
          try {
            let fd = new FormData();
            fd.append("user_id", customerData?.User?.id);
            fd.append("email", formData.emailAddress);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                setTimer(60);
                // setSecondVerified(true);
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else {
    }
  };

  const handleOtpSubmit = async otp => {
    if (otp.every(digit => digit === "")) {
      setValidationError("Please enter Your Otp.");
    } else {
      let newVal = otp.join("");
      setValidationError("");
      // setLoading(true);
      try {
        let fd = new FormData();
        fd.append("user_id", customerData?.User?.id);
        if (customerData?.User?.email) {
          fd.append("email", customerData?.User?.email);
          fd.append("mobile", formData?.mobileNumber);
        }
        if (customerData?.User?.mobile) {
          fd.append("mobile", customerData?.User?.mobile);
          fd.append("email", formData?.emailAddress);
        }
        fd.append("otp", newVal);

        let response = await verifyEmailOrMobileApi(fd);
        if (response) {
          if (response.data.res === true) {
            setValidationError("");
            // setSuccessMsg(response.data.msg);
            NotificationManager.success(response.data.msg);
            setShowOtpPopup(false);
            setSecondVerified(true);
          } else {
            setValidationError(response.data.msg);
            setSuccessMsg("");
          }
        }
      } catch (error) {}
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      emailAddress: "",
      address: "",
      zipCode: "",
      gender: "",
      state: null,
      city: null,
    };

    if (formData.firstName === "") {
      errors.firstName = "Please enter your First Name.";
      isValid = false;
    }

    if (formData.lastName === "") {
      errors.lastName = "Please enter your Last Name.";
      isValid = false;
    }

    // if (formData.mobileNumber === "" || !/^\d{10}$/.test(formData.mobileNumber)) {
    //   errors.mobileNumber = "Please enter a valid 10-digit Mobile Number.";
    //   isValid = false;
    // }

    // if (formData.emailAddress === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress)) {
    //   errors.emailAddress = "Please enter a valid Email Address.";
    //   isValid = false;
    // }

    // if (formData.address === "" || formData.address === null) {
    //   errors.address = "Please enter your address.";
    //   isValid = false;
    // }

    // if (formData.zipCode === "") {
    //   errors.zipCode = "Please enter your Zip Code.";
    //   isValid = false;
    // }

    // if (formData.gender === "") {
    //   errors.gender = "Please enter your Gender.";
    //   isValid = false;
    // }

    // if (!formData.state || formData.state.length === 0) {
    //   errors.state = "Please select a State.";
    //   isValid = false;
    // }

    // if (!formData.city || formData.city.length === 0) {
    //   errors.city = "Please select a City.";
    //   isValid = false;
    // }

    if (formData.mobileNumber && !/^\d{10}$/.test(formData.mobileNumber)) {
      errors.mobileNumber = "Please enter a valid 10-digit Mobile Number.";
      isValid = false;
    }

    if (formData.emailAddress && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress)) {
      errors.emailAddress = "Please enter a valid Email Address.";
      isValid = false;
    }

    if (customerData?.User?.mobile && formData?.emailAddress && !secondVerified) {
      errors.emailAddress = `Please verify your email address.`;
      isValid = false;
    }

    if (customerData?.User?.email && formData?.mobileNumber && !secondVerified) {
      errors.mobileNumber = `Please verify your phone number.`;
      isValid = false;
    }

    // if ((formData?.emailAddress || formData.mobileNumber) && !secondVerified) {
    //   NotificationManager.warning(`Please verify your ${!customerData?.User?.mobile ? "phone number" : "email address"}.`);
    //   isValid = false;
    // }

    setValidationErrors(errors);
    return isValid;
  };

  const handleInputChange = (field, value) => {
    // Update form data
    setFormData(prevData => ({...prevData, [field]: value}));

    // Validate the field
    setValidationErrors(prevErrors => ({...prevErrors, [field]: ""}));
  };

  const handleRegister = async () => {
    if (validateForm()) {
      setLoading(true);
      try {
        let apiFormData = new FormData();
        apiFormData.append("first_name", formData.firstName ? formData.firstName : "");
        apiFormData.append("last_name", formData.lastName ? formData.lastName : "");
        apiFormData.append("state", formData.state ? formData.state : "");
        apiFormData.append("city", formData.city ? formData.city : "");
        apiFormData.append("zipcode", formData.zipCode ? formData.zipCode : "");
        apiFormData.append("gender", formData.gender ? formData.gender : "");
        apiFormData.append("email", formData.emailAddress ? formData.emailAddress : "");
        apiFormData.append("mobile", formData.mobileNumber ? formData.mobileNumber : "");
        apiFormData.append("address", formData.address ? formData.address : "");
        // Api call
        let response = await editCustomerDetailsApi(apiFormData);
        if (response) {
          if (response.data.res === true) {
            NotificationManager.success(response.data.msg);
            getCustomerDetails();
          } else {
            // setValidationError(response.data.msg);
            NotificationManager.warning(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handlePhotoIdSubmit = async () => {
    let cardIdentityTypeError = "";
    let cardIdentityNumberError = "";

    if (!cardIdentityType) {
      cardIdentityTypeError = "Please enter card type.";
    }

    if (!cardIdentityNumber) {
      cardIdentityNumberError = "Please enter card identity number.";
    }

    if (cardIdentityType === "pancard" && cardIdentityNumber && !/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(cardIdentityNumber)) {
      cardIdentityNumberError = "Please enter valid pancard number.";
    }

    if (cardIdentityType === "adhaarcard" && cardIdentityNumber && !/^\d{12}$/.test(cardIdentityNumber)) {
      cardIdentityNumberError = "Please enter valid adhaar card number.";
    }

    // Set the errors in state
    setCardIdentityTypeError(cardIdentityTypeError);
    setCardIdentityNumberError(cardIdentityNumberError);

    if (!cardIdentityTypeError && !cardIdentityNumberError) {
      setLoadingId(true);
      try {
        let apiFormData = new FormData();
        apiFormData.append("id_type", cardIdentityType);
        apiFormData.append("id_number", cardIdentityNumber);
        // Api call
        let response = await addPhotoIdApi(apiFormData);
        if (response) {
          if (response.data.res === true) {
            setCardDetailsFromServer(true);
            NotificationManager.success(response.data.msg);
          } else {
            // setValidationError(response.data.msg);
            NotificationManager.warning(response.data.msg);
          }
          setLoadingId(false);
        }
      } catch (error) {
        setLoadingId(false);
      }
    }
  };

  // Resend Counter useEffect
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [recountTimer]);

  useEffect(() => {
    if (showOtpPopup) {
      inputRefs[0].current.focus();
    }
  }, [showOtpPopup]);

  useEffect(() => {
    getCustomerDetails();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`My Account`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is my account personal information page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="accountName">
              <h2>
                {customerData?.data?.first_name} {customerData?.data?.last_name}
              </h2>
              <h3>{customerData?.User?.mobile}</h3>
            </div>
            <div className="pointDiv">
              <h2>
                Wishmytour Points <span>0</span>
              </h2>
            </div>

            <div className="breadCmb">
              <p>
                <Link to="/profile">My Account</Link>Account Settings
              </p>
            </div>

            <div className="upcomingInner">
              <div className="uptab">
                <Link to="/profile" className="activeTab">
                  Personal Information
                </Link>
                <Link to="/profile/login-details">Login Details</Link>
              </div>

              <div className="uptabContent">
                <div className="personalInfo myAccount">
                  <h3>Personal Information</h3>

                  <ul className="infoForm">
                    <li>
                      <input type="text" placeholder="First Name" value={formData.firstName} onChange={e => handleInputChange("firstName", e.target.value)} />
                      {validationErrors.firstName && <span className="validationErrorMsg">{validationErrors.firstName}</span>}
                    </li>
                    <li>
                      <input type="text" placeholder="Last Name" value={formData.lastName} onChange={e => handleInputChange("lastName", e.target.value)} />
                      {validationErrors.lastName && <span className="validationErrorMsg">{validationErrors.lastName}</span>}
                    </li>
                    <li>
                      <input
                        type="email"
                        readOnly={customerData?.User?.email || secondVerified ? true : false}
                        placeholder="Email Address"
                        value={formData.emailAddress}
                        onChange={e => handleInputChange("emailAddress", e.target.value)}
                      />
                      {customerData?.User?.email === null && !secondVerified && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress) ? (
                        <>
                          <button className="verifyLink" onClick={() => handleSendOtp("email")} disabled={verifyBtnDis}>
                            Verify
                          </button>
                        </>
                      ) : null}
                      {customerData?.User?.email === null && secondVerified ? <span className="otpVerified">Verified</span> : null}
                      {customerData?.User?.email ? <span className="otpVerified">Verified</span> : null}
                      {validationErrors.emailAddress && <span className="validationErrorMsg">{validationErrors.emailAddress}</span>}
                    </li>
                    <li>
                      <input
                        readOnly={customerData?.User?.mobile || secondVerified ? true : false}
                        type="text"
                        placeholder="Phone Number"
                        value={formData.mobileNumber}
                        onChange={e => handleInputChange("mobileNumber", e.target.value)}
                      />
                      {customerData?.User?.mobile === null && formData?.mobileNumber?.length === 10 && !secondVerified ? (
                        <>
                          <button className="verifyLink" onClick={() => handleSendOtp("mobile")} disabled={verifyBtnDis}>
                            Verify
                          </button>
                        </>
                      ) : null}
                      {customerData?.User?.mobile === null && secondVerified ? <span className="otpVerified">Verified</span> : null}
                      {customerData?.User?.mobile ? <span className="otpVerified">Verified</span> : null}
                      {validationErrors.mobileNumber && <span className="validationErrorMsg">{validationErrors.mobileNumber}</span>}
                    </li>
                    <li className="fullWid">
                      <input type="text" placeholder="Address" value={formData.address} onChange={e => handleInputChange("address", e.target.value)} />
                      {validationErrors.address && <span className="validationErrorMsg">{validationErrors.address}</span>}
                    </li>
                    <li>
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="city"
                        onChange={selected => {
                          handleInputChange("city", selected?.[0]?.id);
                          setCityVal(selected);
                        }}
                        onInputChange={text => {
                          if (!text) {
                            handleInputChange("city", null);
                          } else {
                            const matchingCity = cityList.find(city => city.city.toLowerCase() === text.toLowerCase());
                            if (matchingCity) {
                              handleInputChange("city", matchingCity.id);
                            } else {
                              handleInputChange("city", null);
                            }
                          }
                        }}
                        options={cityList}
                        placeholder="City"
                        selected={cityVal}
                      />
                      {validationErrors.city && <span className="validationErrorMsg">{validationErrors.city}</span>}
                    </li>
                    <li>
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        onChange={selected => {
                          handleInputChange("state", selected?.[0]?.id);
                          handleStateChange(selected);
                          setFormData(prev => ({...prev, city: null}));
                          setCityVal([]);
                        }}
                        onInputChange={text => {
                          if (!text) {
                            handleInputChange("state", null);
                          } else {
                            const matchingState = stateList.find(state => state.name.toLowerCase() === text.toLowerCase());
                            if (matchingState) {
                              handleInputChange("state", matchingState.id);
                              handleStateChange([matchingState]);
                              setFormData(prev => ({...prev, city: null}));
                              setCityVal([]);
                            } else {
                              handleInputChange("state", null);
                            }
                          }
                        }}
                        options={stateList}
                        placeholder="State"
                        selected={stateVal}
                      />
                      {validationErrors.state && <span className="validationErrorMsg">{validationErrors.state}</span>}
                    </li>
                    <li>
                      {/* <input type="text" placeholder="Gender" value={formData.gender} onChange={e => handleInputChange("gender", e.target.value)} /> */}
                      <select value={formData.gender} onChange={e => handleInputChange("gender", e.target.value)}>
                        <option hidden selected>
                          Gender
                        </option>
                        <option value={"male"}>Male</option>
                        <option value={"female"}>Female</option>
                        <option value={"other"}>Other</option>
                      </select>
                      {validationErrors.gender && <span className="validationErrorMsg">{validationErrors.gender}</span>}
                    </li>
                    <li>
                      <input type="text" value={formData.zipCode} placeholder="Pincode" onChange={e => handleInputChange("zipCode", e.target.value)} />
                      {validationErrors.zipCode && <span className="validationErrorMsg">{validationErrors.zipCode}</span>}
                    </li>
                    <li className="fullWid">{loading ? <button disabled>Please wait...</button> : <button onClick={() => handleRegister()}>Edit</button>}</li>

                    <li className="fullWid">
                      <h4>Photo ID Details</h4>
                    </li>
                    {!cardDetailsFromServer ? (
                      <>
                        <li>
                          <select
                            placeholder="Photo I Card"
                            value={cardIdentityType}
                            onChange={e => {
                              setCardIdentityType(e.target.value);
                              setCardIdentityTypeError(null);
                            }}>
                            <option hidden value={null}>
                              Photo I Card
                            </option>
                            <option value={"pancard"}>Pancard</option>
                            <option value={"adhaarcard"}>Adhaar Card</option>
                          </select>
                          {cardIdentityTypeError ? <span className="validationErrorMsg">{cardIdentityTypeError}</span> : ""}
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Card Identity Number"
                            value={cardIdentityNumber}
                            onChange={e => {
                              setCardIdentityNumber(e.target.value);
                              setCardIdentityNumberError(null);
                            }}
                          />
                          {cardIdentityNumberError ? <span className="validationErrorMsg">{cardIdentityNumberError}</span> : ""}
                        </li>
                        <li className="fullWid">
                          {loadingId ? <button disabled>Please wait...</button> : <button onClick={() => handlePhotoIdSubmit()}>Save</button>}
                        </li>
                      </>
                    ) : parseInt(cardIdentityVerified) === 0 ? (
                      <li className="fullWid IdVerified">
                        <h3 style={{textTransform: "capitalize"}}>
                          {cardIdentityType} No. - {cardIdentityNumber} Not Verified Yet.
                        </h3>
                      </li>
                    ) : (
                      <li className="IdVerified">
                        <h3 style={{textTransform: "capitalize"}}>
                          {cardIdentityType} No. - {cardIdentityNumber} Verified.
                        </h3>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <div className="hotelSliderPopup" style={{display: showOtpPopup ? "block" : "none"}}>
        <div className="sliderflex">
          <div className="popInner loginDetailsOtp loginDiv">
            <h2>OTP Verification</h2>
            <h3>Check text messages for your OTP</h3>
            {/* <h5>Your Otp is - {changeNumberResponse}</h5> */}
            <ul className="otpList">
              <li>
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={e => handleOtpInputChange(index, e)}
                    onKeyUp={e => handleBackspace(index, e)}
                    onPaste={handlePaste}
                    ref={inputRefs[index]}
                  />
                ))}
              </li>
              <li>00:{parseInt(timer) < 10 ? "0" + timer : timer}</li>
              <li>
                <h4>
                  Not Received OTP?{" "}
                  {parseInt(timer) === 0 ? (
                    <Link to="javascript:void(0);" onClick={() => handleResendOTP()}>
                      Resend Now
                    </Link>
                  ) : (
                    "Resend Now"
                  )}
                </h4>
                {validationError && <p className="validationErrorMsg">{validationError}</p>}
                {successMsg && <p className="successMsg">{successMsg}</p>}
              </li>
              <li>
                <button onClick={() => handleOtpSubmit(otp)}>Submit</button>
              </li>
            </ul>
            <button className="closePopupBtn" onClick={() => setShowOtpPopup(false)}>
              <img src={crossIcon2} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
