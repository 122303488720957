import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import Select from "react-select";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-datepicker";

import deleteImg from "../assets/img/delete-img.svg";
import MinusButton from "../assets/img/minus-button.svg";
import PlusButton from "../assets/img/plus-button.svg";
import crossIcon2 from "../assets/img/cross.svg";
import {Helmet} from "react-helmet";
import {Autocomplete, Avatar, Chip, TextField} from "@mui/material";
import {getThemesListApi} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
const google = require("./../assets/img/google-icon.svg").default;
const cross = require("./../assets/img/cross.svg").default;

const CustomTourPackage = () => {
  const childrenAgeArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [rooms, setRooms] = useState([{adults: 0, children: 0, childrenArray: []}]);
  const [roomPersonError, setRoomPersonError] = useState();
  const [themesList, setThemesList] = useState([]);
  const [inclusionListValues, setInclusionListValues] = useState({
    tourStartDate: null,
    tourEndDate: null,
    message: null,
    theme: null,
    budget: null,
    transportation: 0,
    transportationType: null,
    transportationFrom: null,
    transportationTo: null,
    localTransport: 0,
    localTransportType: 0,
    localTransportCapacity: 0,
    meal: 0,
    mealOptions: [],
    sightseeing: 0,
  });

  const options = [
    {value: "shimla", label: "Shimla"},
    {value: "manali", label: "Manali"},
    {value: "punjab", label: "Punjab"},
    {value: "dalhousie", label: "Dalhousie"},
    // add more options as needed
  ];

  const handleChange = selected => {
    setSelectedOptions(selected || []);
  };

  const getThemesList = async () => {
    try {
      let response = await getThemesListApi();
      if (response) {
        if (response.data.res === true) {
          setThemesList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const handleLocationChange = e => {
    const location = e.target.value;
    if (location && !selectedLocations.includes(location)) {
      setSelectedLocations([...selectedLocations, location]);
      setCurrentLocation("");
    }
  };

  const handleRemoveLocation = index => {
    const updatedLocations = [...selectedLocations];
    updatedLocations.splice(index, 1);
    setSelectedLocations(updatedLocations);
  };

  const handleOptionChange = (event, newValue) => {
    // Filter out any duplicates
    const uniqueOptions = newValue.filter((option, index, self) => index === self.findIndex(t => t.label === option.label));
    setSelectedOptions(uniqueOptions);
  };

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
  ];

  const addRoom = () => {
    setRooms([...rooms, {adults: 0, children: 0, childrenArray: []}]);
  };

  const handleAdultsChange = (index, value) => {
    const updatedRooms = [...rooms];
    const newAdultsCount = updatedRooms[index].adults + value;
    if (newAdultsCount <= 3) {
      updatedRooms[index].adults = Math.max(0, newAdultsCount);
      setRooms(updatedRooms);
    }
  };

  const handleChildrenChange = (index, value) => {
    const updatedRooms = [...rooms];
    const newChildrenCount = updatedRooms[index].children + value;
    if (newChildrenCount <= 3) {
      if (value === 1) {
        updatedRooms[index].children = Math.max(0, newChildrenCount);
        updatedRooms[index].childrenArray.push({age: 0}); // Push to childrenArray property
      } else {
        if (value === -1) {
          updatedRooms[index].children = Math.max(0, newChildrenCount);
          updatedRooms[index].childrenArray.pop();
        }
      }
      setRooms(updatedRooms);
    }
  };

  const handleAgeChange = (roomIndex, childIndex, event) => {
    const {value} = event.target;
    const updatedRooms = [...rooms];
    updatedRooms[roomIndex].childrenArray[childIndex].age = parseInt(value);
    setRooms(updatedRooms);
  };

  const deleteRoom = index => {
    const updatedRooms = rooms.filter((room, i) => i !== index);
    setRooms(updatedRooms);
  };

  const handleRemoveDestination = index => {
    const tempSelectedOptions = selectedOptions.filter((room, i) => i !== index);
    setSelectedOptions(tempSelectedOptions);
  };

  const handleMealCheckboxChange = event => {
    const {value, checked} = event.target;
    setInclusionListValues(prevState => {
      const mealOptions = checked ? [...prevState.mealOptions, value] : prevState.mealOptions.filter(item => item !== value);

      return {...prevState, mealOptions};
    });
  };

  const isAtLeastOnePersonAdded = () => {
    for (const room of rooms) {
      if (room.adults > 0 || room.children > 0) {
        return true;
      }
    }
    return false;
  };

  const validateForm = () => {
    let isValid = true;
    let roomIndexWithInsufficientCapacity = -1;
    let childrenUnder10 = 0;
    let childrenAbove10 = 0;
    let ageError = false;

    rooms.forEach((room, index) => {
      // Reset child count for each room
      let roomChildrenUnder10 = 0;
      let roomChildrenAbove10 = 0;

      room.childrenArray.forEach(child => {
        if (child.age <= 0) {
          ageError = true;
        }
        if (child.age < 10) {
          roomChildrenUnder10++;
        } else {
          roomChildrenAbove10++;
        }
      });

      childrenUnder10 += roomChildrenUnder10;
      childrenAbove10 += roomChildrenAbove10;

      if (room.adults === 3 && room.children > 0) {
        roomIndexWithInsufficientCapacity = index;
        setRoomPersonError(null);
      } else if (room.adults === 2 && room.children > 2) {
        roomIndexWithInsufficientCapacity = index;
        setRoomPersonError(null);
      } else if (room.adults === 2 && roomChildrenAbove10 > 1) {
        roomIndexWithInsufficientCapacity = index;
        setRoomPersonError("Room capacity exceeded: Maximum of 1 child over 10 years allowed with 2 adults.");
      } else if (room.adults === 2 && roomChildrenUnder10 > 2) {
        roomIndexWithInsufficientCapacity = index;
        setRoomPersonError("Room capacity exceeded: Maximum of 2 children under 10 years allowed with 2 adults.");
      }
    });

    if (selectedOptions?.length === 0) {
      NotificationManager.warning("Please select destination.");
      isValid = false;
    } else if (!inclusionListValues?.tourStartDate) {
      NotificationManager.warning("Please select tour start date.");
      isValid = false;
    } else if (!inclusionListValues?.tourEndDate) {
      NotificationManager.warning("Please select tour end date.");
      isValid = false;
    } else if (new Date(inclusionListValues?.tourStartDate).getTime() >= new Date(inclusionListValues?.tourEndDate).getTime()) {
      NotificationManager.warning("Tour start date should not be same or later than the tour end date.");
      isValid = false;
    } else if (!inclusionListValues?.message) {
      NotificationManager.warning("Please enter a message.");
      isValid = false;
    } else if (!inclusionListValues?.budget) {
      NotificationManager.warning("Please enter your budget.");
      isValid = false;
    } else if (!inclusionListValues?.budget) {
      NotificationManager.warning("Please enter your budget.");
      isValid = false;
    } else if (rooms.length === 0) {
      NotificationManager.warning("Please add atleast one room.");
      isValid = false;
    } else if (!isAtLeastOnePersonAdded()) {
      NotificationManager.warning("Please add atleast one person.");
      isValid = false;
    } else if (ageError) {
      NotificationManager.warning("Please select ages for all children.");
      isValid = false;
    } else if (roomIndexWithInsufficientCapacity !== -1) {
      NotificationManager.warning("Please add another room.");
      isValid = false;
    } else if (
      inclusionListValues?.transportation === 1 &&
      (!inclusionListValues?.transportationType || !inclusionListValues?.transportationFrom || !inclusionListValues?.transportationTo)
    ) {
      NotificationManager.warning("Please add all transportation details.");
      isValid = false;
    } else if (inclusionListValues?.localTransport === 1 && (!inclusionListValues?.localTransportType || !inclusionListValues?.localTransportCapacity)) {
      NotificationManager.warning("Please add all local transport details.");
      isValid = false;
    } else if (inclusionListValues?.meal === 1 && inclusionListValues?.mealOptions?.length === 0) {
      NotificationManager.warning("Please add atleast one meal.");
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      console.log(inclusionListValues);
      console.log(selectedOptions);
      console.log(rooms);
    }
  };

  useEffect(() => {
    getThemesList();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Custom Tour Package`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Custom Tour Package page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="customDiv">
            <h2>Custom Tour</h2>
            {/* <div style={{marginBottom: "10px"}}>
              <Select
                isMulti
                value={selectedOptions}
                onChange={handleChange}
                options={options}
                placeholder="Choose a state..."
                className="react-select-destination-container"
                classNamePrefix="react-select-destination"
              />
            </div>
            <div>
              {selectedOptions.map(option => (
                <span key={option.value} style={{display: "inline-block", margin: "5px", padding: "5px", background: "#e0f7fa", borderRadius: "5px"}}>
                  {option.label}
                </span>
              ))}
            </div> */}
            {/* <ul>
              <li>
                <select value={currentLocation} onChange={handleLocationChange}>
                  <option value="">Destination</option>
                  {indianStates.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                <div className="dropdownDiv">
                  {selectedLocations.map((location, index) => (
                    <div className="stateLoop" key={index}>
                      {location}
                      <button onClick={() => handleRemoveLocation(index)}>
                        <img src={cross} alt="" />
                      </button>
                    </div>
                  ))}
                </div>
              </li>
              <li>
                <div className="selectSec">
                  <select>
                    <option>Duration</option>
                  </select>
                  <select>
                    <option>Budget</option>
                  </select>
                  <select>
                    <option>Number Of Person</option>
                  </select>
                </div>
              </li>
              <li>
                <textarea placeholder="Message"></textarea>
              </li>
              <li>
                <button className="submitBtn">Submit</button>
              </li>
            </ul> */}
            <ul class="row CustomTourList">
              <li class="col-md-12">
                {/* <Autocomplete
                  multiple
                  id="tags-standard"
                  options={options}
                  getOptionLabel={option => option.label}
                  // value={}
                  renderInput={params => <TextField {...params} placeholder="Type Here" />}
                  renderTags={() => null}
                  onChange={(event, newValue) => {
                    setSelectedOptions(newValue);
                  }}
                />
                {selectedOptions.map((option, index) => (
                  <Chip
                    key={index}
                    label={option.label}
                    onDelete={() => {
                      setSelectedOptions(selectedOptions.filter(o => o.label !== option.label));
                    }}
                  />
                ))} */}
                {/* <Select isMulti name="destinations" options={options} className="basic-multi-select" classNamePrefix="select" placeholder="Type Here" /> */}
                {/* <input type="text" name="" value="" class="form-control" placeholder="Destinations (You can also write multiple destination)" /> */}
                <Select
                  isMulti
                  value={selectedOptions}
                  onChange={handleChange}
                  options={options}
                  placeholder="Destinations (You can also write multiple destination)"
                  className="form-control react-select-destination-container"
                  classNamePrefix="react-select-destination"
                />
                <div>
                  {selectedOptions.map((option, index) => (
                    <span key={option.value} className="destination-selected-items">
                      {option.label}
                      <img className="cross-icon" src={crossIcon2} onClick={() => handleRemoveDestination(index)} />
                    </span>
                  ))}
                </div>
              </li>
              <li class="col-md-6">
                {/* <input type="text" name="" value="" class="form-control" placeholder="Tour Start Date" /> */}
                <div className="custom-tour-datepicker">
                  <DatePicker
                    className="form-control"
                    selected={inclusionListValues?.tourStartDate}
                    onChange={date => setInclusionListValues(prev => ({...prev, tourStartDate: date}))}
                    dateFormat="MMMM d, yyyy"
                    placeholderText="Tour Start Date"
                    minDate={new Date()}
                  />
                </div>
              </li>
              <li class="col-md-6">
                {/* <input type="text" name="" value="" class="form-control" placeholder="Tour End Date" /> */}
                <div className="custom-tour-datepicker">
                  <DatePicker
                    className="form-control"
                    selected={inclusionListValues?.tourEndDate}
                    onChange={date => setInclusionListValues(prev => ({...prev, tourEndDate: date}))}
                    dateFormat="MMMM d, yyyy"
                    placeholderText="Tour End Date"
                    minDate={new Date()}
                  />
                </div>
              </li>
              <li class="col-md-12">
                <textarea
                  class="textarea-form"
                  cols=""
                  rows=""
                  placeholder="Message"
                  onChange={e => setInclusionListValues(prev => ({...prev, message: e.target.value}))}></textarea>
              </li>
              <li class="col-md-6">
                <select className="form-select" onChange={e => setInclusionListValues(prev => ({...prev, theme: e.target.value}))}>
                  <option selected hidden>
                    Theme (Optional)
                  </option>
                  {themesList?.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </li>
              <li class="col-md-6">
                <input
                  type="number"
                  name=""
                  value={inclusionListValues?.budget}
                  class="form-control"
                  placeholder="Your Budget"
                  onChange={e => setInclusionListValues(prev => ({...prev, budget: e.target.value}))}
                />
              </li>
            </ul>
            <h4>Rooms & Travellers</h4>
            <ul className="RoomsList">
              {rooms.map((room, index) => (
                <li key={index}>
                  <ul className="SubRoomsList">
                    <li>
                      <h4>Room {index + 1}</h4>
                      <a onClick={() => deleteRoom(index)}>
                        <img src={deleteImg} alt="" />
                      </a>
                    </li>
                    <li>
                      <label>Adults</label>
                      <div className="NumBox">
                        <button className="MinusButton" onClick={() => handleAdultsChange(index, -1)}>
                          <img src={MinusButton} alt="" />
                        </button>
                        <span className="NumSpan">{room.adults}</span>
                        <button className="PlusButton" onClick={() => handleAdultsChange(index, 1)}>
                          <img src={PlusButton} alt="" />
                        </button>
                      </div>
                      <span className="noteSpan">(12+ Years)</span>
                    </li>
                    <li>
                      <label>Children</label>
                      <div className="NumBox">
                        <button className="MinusButton" onClick={() => handleChildrenChange(index, -1)}>
                          <img src={MinusButton} alt="" />
                        </button>
                        <span className="NumSpan">{room.children}</span>
                        <button className="PlusButton" onClick={() => handleChildrenChange(index, 1)}>
                          <img src={PlusButton} alt="" />
                        </button>
                      </div>
                      <span className="noteSpan">(Below 12 Years)</span>
                    </li>
                  </ul>
                  {Array.from({length: room.children}, (_, childIndex) => (
                    <ul className="SubRoomsList AgeSelection" key={childIndex}>
                      <li>
                        <h4>{`Age of children ${childIndex + 1}`}</h4>
                      </li>
                      <li>
                        <span className="NumSpan">
                          <div className="SelectWrapper">
                            <select className="SelectBox" onChange={event => handleAgeChange(index, childIndex, event)}>
                              <option selected hidden>
                                Select Age
                              </option>
                              {childrenAgeArray?.map(item => (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </span>
                      </li>
                    </ul>
                  ))}
                  {roomPersonError ? (
                    <ul className="SubRoomsList AgeSelection">
                      <li className="validationErrorMsg">{roomPersonError} </li>
                    </ul>
                  ) : null}
                </li>
              ))}
              {/* <li>
                <ul className="SubRoomsList">
                  <li>
                    <h4>Room 1</h4>
                    <a className="DeleteBtn">
                      <img src={deleteImg} alt="" />
                    </a>
                  </li>
                  <li>
                    <label>Adults</label>
                    <div className="NumBox">
                      <button className="PlusButton">
                        <img src={MinusButton} alt="" />
                      </button>
                      <span className="NumSpan">0</span>
                      <button className="MinusButton">
                        <img src={PlusButton} alt="" />
                      </button>
                    </div>
                    <span className="noteSpan">(12+ Years)</span>
                  </li>
                  <li>
                    <label>Children</label>
                    <div className="NumBox">
                      <button className="PlusButton">
                        <img src={MinusButton} alt="" />
                      </button>
                      <span className="NumSpan">0</span>
                      <button className="MinusButton">
                        <img src={PlusButton} alt="" />
                      </button>
                    </div>
                    <span className="noteSpan">(Below 12 Years)</span>
                  </li>
                </ul>
              </li>
              <li>
                <ul className="SubRoomsList">
                  <li>
                    <h4>Room 1</h4>
                    <a className="DeleteBtn">
                      <img src={deleteImg} alt="" />
                    </a>
                  </li>
                  <li>
                    <label>Adults</label>
                    <div className="NumBox">
                      <button className="PlusButton">
                        <img src={MinusButton} alt="" />
                      </button>
                      <span className="NumSpan">0</span>
                      <button className="MinusButton">
                        <img src={PlusButton} alt="" />
                      </button>
                    </div>
                    <span className="noteSpan">(12+ Years)</span>
                  </li>
                  <li>
                    <label>Children</label>
                    <div className="NumBox">
                      <button className="PlusButton">
                        <img src={MinusButton} alt="" />
                      </button>
                      <span className="NumSpan">0</span>
                      <button className="MinusButton">
                        <img src={PlusButton} alt="" />
                      </button>
                    </div>
                    <span className="noteSpan">(Below 12 Years)</span>
                  </li>
                </ul>
              </li> */}
              <li className="FullWidth">
                <a href="javascript:void(0);" className="AddAnotherRoom" onClick={addRoom}>
                  Add another room
                </a>
              </li>
            </ul>
            <br />
            <h4>Inclusions</h4>
            <ul className="InclusionsList">
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input form-input-check"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked={inclusionListValues?.transportation === 1 ? true : false}
                    onChange={e => setInclusionListValues(prev => ({...prev, transportation: e.target.checked ? 1 : 0}))}
                  />
                  <label class="form-check-label LabelSpan" for="flexCheckChecked">
                    Transportation
                  </label>
                </div>
                <ul className="row RadioList">
                  <li>
                    <div class="form-check" onClick={() => setInclusionListValues(prev => ({...prev, transportationType: "flight"}))}>
                      <input
                        class="form-check-input form-input-radio"
                        type="radio"
                        name="flexRadioDefault1"
                        disabled={inclusionListValues?.transportation === 0}
                        id="flexRadioDefault1"
                        checked={inclusionListValues?.transportationType === "flight" ? true : false}
                      />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Flight
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check" onClick={() => setInclusionListValues(prev => ({...prev, transportationType: "train"}))}>
                      <input
                        class="form-check-input form-input-radio"
                        type="radio"
                        name="flexRadioDefault1"
                        disabled={inclusionListValues?.transportation === 0}
                        id="flexRadioDefault2"
                        checked={inclusionListValues?.transportationType === "train" ? true : false}
                      />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Train
                      </label>
                    </div>
                  </li>
                </ul>
                <ul className="row InputList">
                  <li className="col-md-6">
                    <input
                      type="text"
                      name=""
                      value={inclusionListValues?.transportationFrom}
                      class="form-control"
                      placeholder="Depart From"
                      disabled={inclusionListValues?.transportation === 0}
                      onChange={e => setInclusionListValues(prev => ({...prev, transportationFrom: e.target.value}))}
                    />
                  </li>
                  <li className="col-md-6">
                    <input
                      type="text"
                      name=""
                      value={inclusionListValues?.transportationTo}
                      class="form-control"
                      placeholder="Arrive To"
                      disabled={inclusionListValues?.transportation === 0}
                      onChange={e => setInclusionListValues(prev => ({...prev, transportationTo: e.target.value}))}
                    />
                  </li>
                </ul>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input form-input-check"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked={inclusionListValues?.localTransport === 1 ? true : false}
                    onChange={e => setInclusionListValues(prev => ({...prev, localTransport: e.target.checked ? 1 : 0}))}
                  />
                  <label class="form-check-label LabelSpan" for="flexCheckChecked">
                    Local Transportation
                  </label>
                </div>
                <ul className="row InputList">
                  <li className="col-md-6">
                    <select
                      className="form-select"
                      disabled={inclusionListValues?.localTransport === 0}
                      onChange={e => setInclusionListValues(prev => ({...prev, localTransportType: e.target.value}))}>
                      <option selected hidden>
                        Transport
                      </option>
                      <option>Car</option>
                      <option>Jeep</option>
                      <option>Traveller</option>
                      <option>Bus</option>
                    </select>
                  </li>
                  <li className="col-md-6">
                    <select
                      className="form-select"
                      placeholder="Capacity"
                      disabled={inclusionListValues?.localTransport === 0}
                      onChange={e => setInclusionListValues(prev => ({...prev, localTransportCapacity: e.target.value}))}>
                      <option selected hidden>
                        Capacity
                      </option>
                      <option>4 Seater</option>
                      <option>7 Seater</option>
                      <option>14 Seater</option>
                    </select>
                  </li>
                </ul>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input form-input-check"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked={inclusionListValues?.meal === 1 ? true : false}
                    onChange={e => setInclusionListValues(prev => ({...prev, meal: e.target.checked ? 1 : 0}))}
                  />
                  <label class="form-check-label LabelSpan" for="flexCheckChecked">
                    Meal
                  </label>
                </div>
                <ul className="row CheckboxList">
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input form-input-check"
                        type="checkbox"
                        value="Breakfast"
                        id="Breakfast"
                        disabled={inclusionListValues?.meal === 0}
                        onChange={handleMealCheckboxChange}
                      />
                      <label class="form-check-label LabelSpan" for="Breakfast">
                        Breakfast
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input form-input-check"
                        type="checkbox"
                        value="Lunch"
                        id="Lunch"
                        disabled={inclusionListValues?.meal === 0}
                        onChange={handleMealCheckboxChange}
                      />
                      <label class="form-check-label LabelSpan" for="Lunch">
                        Lunch
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="form-check">
                      <input
                        class="form-check-input form-input-check"
                        type="checkbox"
                        value="Dinner"
                        id="Dinner"
                        disabled={inclusionListValues?.meal === 0}
                        onChange={handleMealCheckboxChange}
                      />
                      <label class="form-check-label LabelSpan" for="Dinner">
                        Dinner
                      </label>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input form-input-check"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked={inclusionListValues?.sightseeing === 1 ? true : false}
                    onChange={e => setInclusionListValues(prev => ({...prev, sightseeing: e.target.checked ? 1 : 0}))}
                  />
                  <label class="form-check-label LabelSpan" for="flexCheckChecked">
                    Sightseeing
                  </label>
                </div>
                <ul className="row InputList">
                  <li className="col-md-12">
                    <Select
                      isMulti
                      isDisabled={inclusionListValues?.sightseeing === 0}
                      value={selectedOptions}
                      onChange={handleChange}
                      options={options}
                      placeholder="Type here"
                      className="form-control react-select-destination-container"
                      classNamePrefix="react-select-destination"
                    />
                    <div>
                      {selectedOptions.map((option, index) => (
                        <span key={option.value} className="destination-selected-items">
                          {option.label}
                          <img className="cross-icon" src={crossIcon2} onClick={() => handleRemoveDestination(index)} />
                        </span>
                      ))}
                    </div>
                    {/* <input type="text" name="" value="" class="form-control" placeholder="Type here" /> */}
                  </li>
                </ul>
              </li>
              <li>
                <input type="button" name="" value="Submit" className="btn btn-primary CmnBtn" onClick={() => handleSubmit()} />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CustomTourPackage;
