import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import {Link, useNavigate, useParams} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Accordion, Modal} from "react-bootstrap";
import ImageUploader from "./../component/ImageUploader";
import {
  addFeedbackApi,
  addReportApi,
  customerBookingDetailsApi,
  getCustomerDetailsApi,
  getOnRequestDetailsApi,
  getTaxDetailsApi,
  upcomingBookingApi,
} from "../services/ApiService";

import {Helmet} from "react-helmet";
import StarRatings from "react-star-ratings";
import {IMAGE_URL} from "../utils/commonUrls";
import {useUserContext} from "../context/UserContext";
import {NotificationManager} from "react-notifications";
import {TimeConverter} from "../utils/commonFunctions";

const transferIcon2 = require("./../assets/img/car-icon2.svg").default;
const mealIcon2 = require("./../assets/img/restaurant-icon2.svg").default;
const hotelIcon2 = require("./../assets/img/bed-icon2.svg").default;
const optionalIcon2 = require("./../assets/img/optional-icon2.svg").default;
const special1 = require("./../assets/img/special1.png");
const trainIcon2 = require("./../assets/img/train-icon2.svg").default;
const trainIcon = require("./../assets/img/train.svg").default;
const binocularIcon = require("./../assets/img/binoculars.svg").default;
const FlightFromImg = require("./../assets/img/flightfromimg.svg").default;
const HotelImg = require("./../assets/img/hotel-img.png");
const crossIcon2 = require("./../assets/img/cross.svg").default;
const dragIcon = require("./../assets/img/drag-icon.svg").default;
const noteIcon = require("./../assets/img/note-icon.svg").default;

const OnRequestQuoteDetails = () => {
  const {bookingId} = useParams();
  const navigate = useNavigate();
  const {userData} = useUserContext();
  const [customerData, setCustomerData] = useState();
  const [bookingData, setBookingData] = useState();
  const [totalAdult, setTotalAdult] = useState();
  const [passengersArray, setPassengersArray] = useState([]);
  const [priceDetails, setPriceDetails] = useState({
    basePrice: 0,
    addOnPrice: 0,
    taxPrice: 0,
    gstTax: 0,
    tcsTax: 0,
    gstTaxIncludePrice: 0,
    tcsTaxIncludePrice: 0,
    bulkDiscountPrice: 0,
    bulkDiscountPercent: 0,
    subTotalPrice: 0,
    totalPartialCalAmt: 0,
    latestPartialCancelDate: null,
  });
  const [taxDetails, setTaxDetails] = useState();
  const [startDateFromToday, setStartDateFromToday] = useState();
  const [rating, setRating] = useState(1);
  const [feedback, setFeedback] = useState();
  const [showRatePopup, setShowRatePopup] = useState(false);
  const [reportBtnDis, setReportBtnDis] = useState(false);
  const [showReportButton, setShowReportButton] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [reportIssueText, setReportIssueText] = useState();
  const [reportIssueImages, setReportIssueImages] = useState([]);
  const [roomDetails, setRoomDetails] = useState({roomCount: 0, adultCount: 0, childCount: 0});

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustomerData(response.data);
        }
      }
    } catch (error) {}
  };

  const getBookingDetails = async () => {
    try {
      let response = await getOnRequestDetailsApi(bookingId);
      if (response) {
        if (response.data.res === true) {
          // let parsedRoomDetails = JSON.parse(response.data.data.booking.room_details);
          // response.data.data.booking.room_details = parsedRoomDetails;
          setBookingData(response.data.data);

          // console.log(JSON.parse(response.data.data.booking.room_details));
          // setRating(response.data.data[0].rating ? parseInt(response.data.data[0].rating) : 0);
          // setFeedback(response.data.data[0].feedback);
          // setPriceDetails(prev => ({
          //   ...prev,
          //   basePrice: response.data.data[0].final_price,
          // }));
          // if (response.data.booking_rooms.length > 0) {
          //   let adults = 0;
          //   response.data.booking_rooms.map(item => {
          //     adults += item?.adults;
          //   });
          //   setTotalAdult(adults);
          // }

          const roomCount = response.data.data.booking?.room_details.length;
          const adultCount = response.data.data.booking?.room_details.map(room => parseInt(room.adults, 10)).reduce((acc, val) => acc + val, 0);
          const childCount = response.data.data.booking?.room_details.map(room => parseInt(room.children, 10)).reduce((acc, val) => acc + val, 0);

          setRoomDetails({roomCount, adultCount, childCount});

          let tempArray = [];

          // response.data.booking_rooms.map((roomItem, roomIndex) => {
          //   const roomData = {room: "Room " + (roomIndex + 1), adults: [], children: []};
          //   response.data.booking_passengers.map((passItem, passIndex) => {
          //     if (roomItem.id === passItem.booking_room_id) {
          //       const passenger = {
          //         title: passItem.title,
          //         first_name: passItem.first_name,
          //         last_name: passItem.last_name,
          //         dob: passItem.dob,
          //         gender: passItem.gender,
          //         status: passItem.status,
          //       };

          //       if (passItem.is_adult === 1) {
          //         roomData.adults.push(passenger);
          //       } else {
          //         roomData.children.push(passenger);
          //       }
          //     }
          //   });
          //   tempArray.push(roomData);
          // });

          // setPassengersArray(tempArray);

          let basePrice = response.data.data.booking.final_price;
          let addOnPrice = response.data.data.booking.addon_total_price;
          let taxes = 0;
          let gstTax = response.data.data.booking.gst_percent;
          let tcsTax = 0;
          let taxIncludePrice = 0;
          let gstTaxIncludePrice = response.data.data.booking.gst_price;
          let tcsTaxIncludePrice = 0;
          let bulkDiscountPrice = 0;
          let bulkDiscountPercent = 0;
          let subTotal = 0;
          let totalPartialCalAmt = 0;
          let latestPartialCancelDate = null;

          // if (response.data?.bookingCancellation?.length > 0) {
          //   response.data?.bookingCancellation?.map(item => {
          //     totalPartialCalAmt += parseFloat(item?.cancellation_charge);
          //   });
          //   let lastItemDate = response.data?.bookingCancellation?.slice(-1)[0]?.created_at;
          //   latestPartialCancelDate = lastItemDate;
          // }

          // if (response.data?.packageAddon?.length > 0) {
          //   response.data?.packageAddon?.map(item => {
          //     addOnPrice += parseFloat(item?.price);
          //   });
          // }

          // try {
          //   const formData = new FormData();
          //   formData.append("package_id", response.data.data[0].package_id);
          //   let response1 = await getTaxDetailsApi(formData);
          //   if (response1 && response1.data.res === true) {
          //     setTaxDetails(response1.data.data);
          //     gstTax = response1.data.data.gst.value ? parseInt(response1.data.data.gst.value) : 0;
          //     tcsTax = response1.data.data.tcs.value ? parseInt(response1.data.data.tcs.value) : 0;
          //   }
          // } catch (error) {
          //   console.error("Error fetching package details:", error);
          // }

          // GST and tcs calculation
          taxes = gstTax + tcsTax;

          let basepriceWithAddon = parseFloat(basePrice) + parseFloat(addOnPrice);

          if (taxes > 0) {
            taxIncludePrice = parseFloat(basepriceWithAddon) + (parseFloat(basepriceWithAddon) * taxes) / 100;
          }
          if (gstTax > 0) {
            gstTaxIncludePrice = (basepriceWithAddon * gstTax) / 100;
          }
          if (tcsTax > 0) {
            tcsTaxIncludePrice = (basepriceWithAddon * tcsTax) / 100;
          }

          subTotal = parseFloat(taxes ? taxIncludePrice : basepriceWithAddon);

          setPriceDetails(prev => ({
            ...prev,
            basePrice: basePrice,
            addOnPrice: addOnPrice,
            taxPrice: taxes,
            gstTax: gstTax,
            tcsTax: tcsTax,
            gstTaxIncludePrice: parseFloat(gstTaxIncludePrice).toFixed(2),
            tcsTaxIncludePrice: parseFloat(tcsTaxIncludePrice).toFixed(2),
            bulkDiscountPrice: parseFloat(bulkDiscountPrice).toFixed(2),
            bulkDiscountPercent: bulkDiscountPercent,
            subTotalPrice: subTotal.toFixed(2),
            totalPartialCalAmt: parseFloat(totalPartialCalAmt).toFixed(2),
            latestPartialCancelDate: latestPartialCancelDate,
          }));

          // // Calculate the difference in time (in milliseconds)
          // const diffTime = Math.abs(new Date() - new Date(response?.data?.last_booking_date));

          // // Convert milliseconds to days
          // const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

          // if (diffDays === 1) {
          //   setShowReportButton(true);
          // } else {
          //   setShowReportButton(false);
          // }
        }
      }
    } catch (error) {}
  };

  const formatDateFormat = date => {
    const originalDateString = date;

    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);

    // Months array for mapping month indexes to month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get the month, day, and year from the parsed date
    const monthIndex = originalDate.getMonth();
    const day = originalDate.getDate();
    const year = originalDate.getFullYear();

    // Format the date string
    const formattedDateString = `${day} ${months[monthIndex]}, ${year}`;
    return formattedDateString;
  };

  const formattedStayPlan = stayPlan => (
    <p className="packDay">
      {stayPlan?.map((stayItem, stayIndex) => (
        <span key={stayIndex}>
          {stayItem.city_name} ({stayItem.total_nights}N){stayIndex < stayPlan.length - 1 && " → "}
        </span>
      ))}
    </p>
  );

  const formatDate = inputDate => {
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-GB"); // 'en-GB' uses day/month/year format
  };

  const handleDragEnter = e => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = e => {
    e.preventDefault();
    setDragging(false);

    const droppedFiles = e.dataTransfer.files;
    const newImages = Array.from(droppedFiles).map(file => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    const allowedExtensions = ["png", "jpeg", "jpg"];
    const allowedDimension = 800;
    const maxSizeInBytes = 1024 * 1024; // 1mb

    const validateImage = image => {
      return new Promise((resolve, reject) => {
        const extension = image.file.name.split(".").pop().toLowerCase();

        // Check file extension
        if (!allowedExtensions.includes(extension)) {
          NotificationManager.warning(`File '${image.file.name}' has an invalid extension. Allowed extensions are png, jpeg, and jpg.`);
          resolve(false);
        }

        // Check image dimensions
        const img = new Image();
        img.onload = () => {
          // if (allowedExtensions.includes(extension) && (img.width < allowedDimension || img.height < allowedDimension)) {
          //   NotificationManager.warning(`Image '${image.file.name}' dimensions should be ${allowedDimension}x${allowedDimension} pixels.`);
          //   resolve(false);
          // } else {
          // Check file size
          if (image.file.size > maxSizeInBytes) {
            NotificationManager.warning(`File '${image.file.name}' size exceeds the maximum allowed size of 1mb.`);
            resolve(false);
          } else {
            resolve(true);
          }
          // }
        };
        img.onerror = () => {
          // NotificationManager.warning(`Failed to load image '${image.file.name}'.`);
          resolve(false);
        };
        img.src = image.preview;
      });
    };

    const validatedImagesPromises = newImages.map(image => validateImage(image));

    Promise.all(validatedImagesPromises)
      .then(validatedResults => {
        const validatedImages = newImages.filter((_, index) => validatedResults[index]);
        setReportIssueImages(prevImages => [...prevImages, ...validatedImages]);
      })
      .catch(error => {
        console.error("Error validating images:", error);
      });
  };

  const handleRemoveReportImage = index => {
    setReportIssueImages(prevImages => {
      const updatedImages = [...reportIssueImages];
      updatedImages.splice(index, 1);
      return updatedImages; // Return the updated array to setImages
    });
    // NotificationManager.success("Image deleted successfully");
  };

  const handleSubmitFeedback = async () => {
    if (!rating) {
      NotificationManager.warning("Please provide rating.");
    } else {
      setReportBtnDis(true);
      try {
        const formData = new FormData();
        formData.append("booking_id", bookingId);
        formData.append("feedback", feedback);
        formData.append("rating", rating);
        let response = await addFeedbackApi(formData);
        if (response) {
          if (response.data.res === true) {
            setShowRatePopup(false);
            NotificationManager.success(response.data.msg);
            getBookingDetails();
          } else {
            NotificationManager.error(response.data.msg);
          }
          setReportBtnDis(false);
        }
      } catch (error) {
        NotificationManager.error("Something went wrong, please try again.");
        setReportBtnDis(false);
      }
    }
  };

  const handleSubmitReport = async () => {
    if (!reportIssueText) {
      NotificationManager.warning("Please enter your complain.");
    } else if (reportIssueImages?.length === 0) {
      NotificationManager.warning("Please upload atleast one image.");
    } else {
      setReportBtnDis(true);
      try {
        const formData = new FormData();
        formData.append("booking_id", bookingId);
        formData.append("report", reportIssueText);
        reportIssueImages?.map(item => {
          formData.append("gallery_images[]", item?.file);
        });
        let response = await addReportApi(formData);
        if (response) {
          if (response.data.res === true) {
            setShowPopup(false);
            NotificationManager.success(response.data.msg);
            // getBookingDetails();
          } else {
            NotificationManager.error(response.data.msg);
          }
          setReportBtnDis(false);
        }
      } catch (error) {
        NotificationManager.error("Something went wrong, please try again.");
        setReportBtnDis(false);
      }
    }
  };

  useEffect(() => {
    getCustomerDetails();
    getBookingDetails();
  }, []);

  const hotelSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
  };

  const [showPopup, setShowPopup] = useState(false);
  const [section1Images, setSection1Images] = useState([]);
  const [section2Images, setSection2Images] = useState([]);
  const [editingValues, setEditingValues] = useState({});

  const handleAddInclusion = () => {
    setShowPopup(true); // Fixing the syntax
  };

  const handleImagesChange = (newImages, sectionTitle) => {
    if (sectionTitle === "Section 1") {
      setSection1Images(newImages);
    } else if (sectionTitle === "Section 2") {
      setSection2Images(newImages);
      setEditingValues({
        ...editingValues,
        hotelImages: newImages,
      });
      setSection2Images([]);
    }
    // You can perform any other necessary actions here if needed
  };

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Quote Details`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Quote Details page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="accountName">
              <h2>
                {customerData?.data?.first_name} {customerData?.data?.last_name}
              </h2>
              <h3>{customerData?.User?.mobile}</h3>
            </div>
            <div className="pointDiv">
              <h2>
                Wishmytour Points <span>0</span>
              </h2>
            </div>

            <div className="breadCmb">
              <p>
                <Link to="/profile">My Account</Link>Quote ID #{bookingData?.booking?.id}
              </p>
            </div>

            <div className="upcomingInner">
              <div className="uptabContent tourDtl">
                <div className="tourListing">
                  <h3 className="dtsBkId">Package ID # {bookingData?.package?.package_id}</h3>
                  <div className="specialBox">
                    <div className="tourBoxImg">
                      {/* <Link to="/"> */}
                      <img src={IMAGE_URL + bookingData?.gallery_images[0]?.path} alt="Image 1" className="specialSliderImg" />
                      {/* </Link> */}
                    </div>
                    <div className="bannerTxt">
                      {/* <div className="bookingStatus">Booking Pending</div> */}
                      <h2>
                        <Link>{bookingData?.package_name}</Link>
                      </h2>
                      {/* <h4>
                        By: <span>Ayan Dutta Tour & Traveller</span>
                      </h4> */}
                      <h3>{bookingData?.total_days}</h3>

                      {formattedStayPlan(bookingData?.stay_plan)}

                      <ul>
                        {bookingData?.is_flight ? (
                          <li>
                            <img src={optionalIcon2} alt="Image 1" />
                            Flight
                          </li>
                        ) : null}

                        {bookingData?.is_train ? (
                          <li>
                            <img src={trainIcon} alt="Image 1" />
                            Train
                          </li>
                        ) : null}

                        {bookingData?.is_hotel ? (
                          <li>
                            <img src={hotelIcon2} alt="Image 1" />
                            Hotel
                          </li>
                        ) : null}

                        {bookingData?.is_meal ? (
                          <li>
                            <img src={mealIcon2} alt="Image 1" />
                            Meals
                          </li>
                        ) : null}

                        {bookingData?.is_transport ? (
                          <li>
                            <img src={transferIcon2} alt="Image 1" />
                            Transfer
                          </li>
                        ) : null}

                        {bookingData?.is_sightseeing ? (
                          <li>
                            <img src={binocularIcon} alt="Image 1" />
                            Sightseeing
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* {bookingData?.bookings_status_id === 3 ? (
                <>
                  <div className="bookingDetails">
                    <h3>
                      How was your experience?{" "}
                      {!bookingData?.rating ? (
                        <span className="rateLink" onClick={() => setShowRatePopup(true)}>
                          Rate us!
                        </span>
                      ) : null}
                    </h3>
                    <div className="bookDtlsInner">
                      <StarRatings
                        rating={bookingData?.rating ? parseInt(bookingData?.rating) : 0} // Initial rating value
                        starRatedColor="#F8B84E" // Color of the filled stars
                        numberOfStars={5} // Total number of stars
                        name="rating" // Unique name for the component
                        starDimension="30px"
                        starSpacing="2px"
                      />
                      {bookingData?.feedback ? (
                        <ul className="bookContainer cancelIssue feedbackText">
                          <li>{bookingData?.feedback}</li>
                        </ul>
                      ) : null}
                    </div>
                  </div>

                  {bookingData?.report ? (
                    <>
                      <div className="bookingDetails">
                        <h3>Report Details</h3>
                        <div className="bookDtlsInner">
                          {bookingData?.report ? (
                            <ul className="bookContainer cancelIssue feedbackText">
                              <li>{bookingData?.report}</li>
                            </ul>
                          ) : null}

                          {bookingData?.reportGalleryImages?.length > 0 ? (
                            <div className={`dragUpload withoutBorder`}>
                              <div className="uploadedImagesContainer">
                                {bookingData?.reportGalleryImages?.map((image, index) => (
                                  <div key={index} className="uploadedImage">
                                    <img src={IMAGE_URL + image.path} alt={`Uploaded ${index + 1}`} />
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}

              {bookingData?.bookings_status_id === 4 ? (
                <div className="bookingDetails">
                  <h3>Booking Cancellation Issue</h3>
                  <div className="bookDtlsInner">
                    <ul className="bookContainer cancelIssue">
                      <li>{bookingData?.bookingCancellation.find(item => item?.cancellation_type === "full")?.cancellation_reason}</li>
                    </ul>
                  </div>
                </div>
              ) : null} */}

              <div className="bookingDetails">
                <h3>Quote Details</h3>
                <div className="bookDtlsInner">
                  <ul className="bookContainer">
                    <li>
                      <label>Quote ID:</label>
                      {bookingData?.booking?.id}
                    </li>
                    <li>
                      <label>Quote Created:</label>
                      {formatDateFormat(bookingData?.booking?.created_at)}
                    </li>
                    <li>
                      <label>Status:</label>
                      {bookingData?.booking?.status === 0 ? <span className="greenColorSts">Send</span> : ""}
                    </li>
                  </ul>
                </div>
                <div className="bookDtlsInner">
                  <ul className="bookContainer">
                    <li>
                      <label>Tour Date:</label>
                      {formatDateFormat(bookingData?.booking?.start_date)} - {formatDateFormat(bookingData?.booking?.end_date)}
                    </li>
                    <li>
                      <label>Room:</label>
                      {roomDetails?.roomCount}
                    </li>
                    <li>
                      <label>Adult(s):</label>
                      {roomDetails?.adultCount ? roomDetails?.adultCount : 0}
                    </li>
                    {roomDetails?.childCount > 0 ? (
                      <li>
                        <label>Children(s):</label>
                        {roomDetails?.childCount ? roomDetails?.childCount : 0}
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>

              <div className="bookingDetails">
                <h3>Customer Details</h3>
                <div className="bookDtlsInner">
                  <ul className="bookContainer">
                    <li>
                      <label>Name:</label>
                      {bookingData?.customer?.first_name} {bookingData?.customer?.last_name}
                    </li>
                    <li>
                      <label>Address:</label>
                      {bookingData?.customer?.address ? bookingData?.customer?.address : "-"}
                    </li>
                    <li>
                      <label>Email:</label>
                      {bookingData?.customer?.email}
                    </li>
                    <li>
                      <label>Phone Number:</label>
                      {bookingData?.customer?.mobile}
                    </li>
                    <li>
                      <label>State:</label>
                      {bookingData?.customer?.state_name}
                    </li>
                    {bookingData?.customer?.id_type === "pancard" ? (
                      <li>
                        <label>PAN Number:</label>
                        {bookingData?.customer?.id_number ? bookingData?.customer?.id_number : "-"}
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>

              {bookingData?.booking_flight?.length > 0 || bookingData?.booking_train?.length > 0 ? (
                <div className="bookingDetails">
                  <h3>Transport Details</h3>
                  {/* <div className="bookDtlsInner bookFlgt">
                    <div className="DetailsWhiteBox">
                      <div className="FlightListSec">
                        <ul className="row FlightList">
                          <li>
                            <h4>Air India</h4>
                            <h5>AI 445</h5>
                          </li>
                          <li>
                            <h4>new Delhi</h4>
                          </li>
                          <li className="text-center">
                            <img src={FlightFromImg} alt="" className="FlightFromImg" />
                            <span className="FlightClass">hgg</span>
                          </li>
                          <li>gjgjg</li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  {bookingData?.booking_flight?.map((item, index) => (
                    <div className="bookDtlsInner bookFlgt" key={index}>
                      <div className="DetailsWhiteBox">
                        <div className="FlightListSec">
                          <ul className="row FlightList">
                            {/* <li>
                              <h4>Air India</h4>
                              <h5>AI 445</h5>
                            </li> */}
                            <li>
                              <h4>{item?.flights[0]?.depart_destination.id}</h4>
                              <h4>{TimeConverter(item?.flights[0]?.depart_datetime)}</h4>
                            </li>
                            <li className="text-center">
                              <img src={FlightFromImg} alt="" className="FlightFromImg" />
                              <span className="FlightClass">{item?.flights[0]?.class}</span>
                            </li>
                            <li>
                              <h4>{item?.flights[0]?.arrive_destination.id}</h4>
                              <h4>{TimeConverter(item?.flights[0]?.arrive_datetime)}</h4>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                  {bookingData?.booking_train?.map((item, index) => (
                    <div className="bookDtlsInner bookFlgt" key={index}>
                      <div className="DetailsWhiteBox">
                        <div className="FlightListSec">
                          <ul className="row FlightList">
                            {/* <li>
                              <h4>Air India</h4>
                              <h5>AI 445</h5>
                            </li>
                            <li>
                              <h4>{item?.from_station}</h4>
                            </li>
                            <li className="text-center">
                              <img src={trainIcon} alt="" className="FlightFromImg" />
                              <span className="FlightClass">df</span>
                            </li>
                            <li>{item?.to_station}</li> */}

                            <li class="Train trnDtls">
                              <div class="accoInner">
                                <div class="transportName">
                                  <h4>{item?.train_name}</h4>
                                  <p>{item?.train_number}</p>
                                </div>
                                <div class="stationName">
                                  <h4>{item?.from_station}</h4>
                                </div>
                                <div class="classCategory">
                                  <img src={trainIcon2} />
                                  <h4>{item?.class}</h4>
                                </div>
                                <div class="destinationName">
                                  <h4>{item?.to_station}</h4>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}

              {/* {passengersArray.length > 0 ? (
                <div className="bookingDetails">
                  <h3>Travellers</h3>
                  <div className="bookDtlsInner travlerkContainer">
                    {passengersArray?.map((item, index) => (
                      <div className="loopBook" key={index}>
                        <h2>{item?.room}</h2>
                        <ul>
                          {item?.adults?.length > 0
                            ? item?.adults?.map((chItem, chIndex) => (
                                <li>
                                  <div className="trvlrDiv">
                                    <h4>
                                      {chItem?.first_name} {chItem?.last_name} <span>Adult</span>
                                    </h4>
                                    <h5 style={{textTransform: "capitalize"}}>
                                      {chItem?.gender} | {formatDate(chItem?.dob)}
                                    </h5>
                                  </div>
                                  {chItem?.status === 0 ? (
                                    <div className="bookAction">
                                      <button>Cancelled</button>
                                    </div>
                                  ) : null}
                                </li>
                              ))
                            : null}
                          {item?.children?.length > 0
                            ? item?.children?.map((chItem, chIndex) => (
                                <li>
                                  <div className="trvlrDiv">
                                    <h4>
                                      {chItem?.first_name} {chItem?.last_name} <span>Children</span>
                                    </h4>
                                    <h5>
                                      {chItem?.gender} | {formatDate(chItem?.dob)}
                                    </h5>
                                  </div>
                                  {chItem?.status === 0 ? (
                                    <div className="bookAction">
                                      <button>Cancelled</button>
                                    </div>
                                  ) : null}
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null} */}

              {bookingData?.packageAddon?.length > 0 ? (
                <div className="bookingDetails">
                  <h3>Add On</h3>

                  <div className="bookDtlsInner addTrvl">
                    <ul>
                      {bookingData?.packageAddon?.map((item, index) => (
                        <>
                          <li>
                            <h3>{item?.title}</h3>
                            <p>{item?.description}</p>
                            <h4>₹{item?.price}</h4>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}

              <div className="itternaryBooking">
                <h3>Itinerary</h3>

                <div className="ConditionAccordion">
                  <Accordion defaultActiveKey={bookingData?.itinerary?.map((_, index) => index.toString())}>
                    {/* <Accordion defaultActiveKey={["0", "1", "2", "3", "4"]}> */}
                    {bookingData?.itinerary?.map((item, index) => (
                      <Accordion eventKey={index.toString()} key={index}>
                        <div className="accordion-item">
                          <Accordion.Header>Day {index + 1}</Accordion.Header>
                          <Accordion.Body>
                            <ul className="ItineraryList">
                              <li>
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="BorderBox">
                                      <h3>Day</h3>
                                      <h4>{index + 1}</h4>
                                    </div>
                                  </div>
                                  <div className="col-md-10 ItineraryRightContent">
                                    <h3>{item?.itinerary_title}</h3>
                                    {item?.meal ? (
                                      <h4>
                                        Meals: <span>{item?.meal}</span>{" "}
                                      </h4>
                                    ) : null}

                                    <p>{item.itinerary_description}</p>

                                    {item?.flights?.length > 0 ? (
                                      <>
                                        <h5>Flight</h5>
                                        <div className="FlightListSec">
                                          <ul className="row FlightList">
                                            {/* <li>
                                              <h4>Air India</h4>
                                              <h5>AI 445</h5>
                                            </li> */}
                                            <li>
                                              <h4>{item?.flights[0]?.depart_destination.id}</h4>
                                              <h4>{TimeConverter(item?.flights[0]?.depart_datetime)}</h4>
                                            </li>
                                            <li className="text-center">
                                              <img src={FlightFromImg} alt="" className="FlightFromImg" />
                                              <span className="FlightClass">{item?.flights[0]?.class}</span>
                                            </li>
                                            <li className="flightWidth">
                                              <h4>{item?.flights[0]?.arrive_destination.id}</h4>
                                              <h4>{TimeConverter(item?.flights[0]?.arrive_datetime)}</h4>
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {item?.sightseeing?.length > 0 ? (
                                      <>
                                        <h5>Sightseeing</h5>
                                        <ul className="row GreenArrowList">
                                          <li>Visit Shanti Stupa & Leh Palace</li>
                                        </ul>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {item?.sightseeing?.length > 0 ? (
                                      <>
                                        <h5>Sightseeing</h5>
                                        <ul className="row GreenArrowList">
                                          {item?.sightseeing?.map((sight, index) => (
                                            <React.Fragment key={index}>
                                              {sight.morning && <li>{sight?.morning}</li>}
                                              {sight.afternoon && <li>{sight?.afternoon}</li>}
                                              {sight.evening && <li>{sight?.evening}</li>}
                                              {sight.night && <li>{sight?.night}</li>}
                                            </React.Fragment>
                                          ))}
                                        </ul>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {item?.hotels?.length > 0 ? (
                                      <div className="HotelDesSec">
                                        <h3>Hotel</h3>
                                        {/* <p>Check In For 1 Nights</p> */}
                                        <ul className="row HotelDesSecList">
                                          <li className="col-md-4">
                                            <Slider {...hotelSliderSettings}>
                                              {item?.hotels[0]?.hotel_gallery?.map((hotelItem, hotelIndex) => (
                                                <div key={hotelIndex}>
                                                  <img
                                                    src={IMAGE_URL + hotelItem?.path}
                                                    alt=""
                                                    // onClick={() => openHotelPopup(item?.hotels[0]?.hotel_gallery, hotelIndex)}
                                                  />
                                                </div>
                                              ))}
                                            </Slider>
                                          </li>
                                          <li className="col-md-8 TextRightSec">
                                            <StarRatings
                                              rating={item?.hotels[0]?.star} // Initial rating value
                                              starRatedColor="#F8B84E" // Color of the filled stars
                                              numberOfStars={5} // Total number of stars
                                              name="rating" // Unique name for the component
                                              starDimension="18px"
                                              starSpacing="2px"
                                            />
                                            <h3>{item?.hotels[0]?.hotel_name} or Similar</h3>
                                            {item?.hotels[0]?.is_other_place === 1 ? (
                                              <div className="nameDesc">
                                                <p>
                                                  {item?.hotels[0]?.place_name} | {item?.hotels[0]?.distance_from_main_town}
                                                </p>
                                              </div>
                                            ) : null}
                                          </li>
                                        </ul>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </Accordion.Body>
                        </div>
                      </Accordion>
                    ))}
                    {/* <Accordion eventKey="0">
                      <div className="accordion-item">
                        <Accordion.Header>Day 1</Accordion.Header>
                        <Accordion.Body>
                          <ul className="ItineraryList">
                            <li>
                              <div className="row">
                                <div className="col-md-2">
                                  <div className="BorderBox">
                                    <h3>Day</h3>
                                    <h4>1</h4>
                                  </div>
                                </div>
                                <div className="col-md-10 ItineraryRightContent">
                                  <h3>Arrive Leh : 3505 meters / 11567 feet</h3>
                                  <h4>
                                    Meals: <span>Dinner Only</span>{" "}
                                  </h4>
                                  <p>
                                    Arrive Kushok Bakula airport Leh - 3500m above sea level. Transfer to hotel. Breakfast at the hotel before 09.30 am. Half
                                    Day at rest for acclimatization. Lunch at the hotel. After Lunch drive to Visit Shanti Stupa & Leh Palace, Later in the
                                    evening walk around local market. Overnight stay at the Hotel.
                                  </p>
                                  <h5>Flight</h5>
                                  <div className="FlightListSec">
                                    <ul className="row FlightList">
                                      <li>
                                        <h4>Air India</h4>
                                        <h5>AI 445</h5>
                                      </li>
                                      <li>
                                        <h4>New Delhi</h4>
                                      </li>
                                      <li className="text-center">
                                        <img src={FlightFromImg} alt="" className="FlightFromImg" />
                                        <span className="FlightClass">Economy</span>
                                      </li>
                                      <li className="flightWidth">
                                        <h4>Leh</h4>
                                      </li>
                                    </ul>
                                  </div>

                                  <h5>Sightseeing</h5>
                                  <ul className="row GreenArrowList">
                                    <li>Visit Shanti Stupa & Leh Palace</li>
                                  </ul>

                                  <h5>Meal</h5>
                                  <ul className="row GreenArrowList">
                                    <li style={{textTransform: "capitalize"}}>Dinner Only</li>
                                  </ul>

                                  <div className="HotelDesSec">
                                    <h3>Hotel</h3>
                                    <p>Check In For 1 Nights</p>
                                    <ul className="row HotelDesSecList">
                                      <li className="col-md-4">
                                        <Slider {...hotelSliderSettings}>
                                          <div>
                                            <img src={HotelImg} alt="" />
                                          </div>
                                          <div>
                                            <img src={HotelImg} alt="" />
                                          </div>
                                        </Slider>
                                      </li>
                                      <li className="col-md-8 TextRightSec">
                                        <StarRatings
                                          rating={4} // Initial rating value
                                          starRatedColor="#F8B84E" // Color of the filled stars
                                          numberOfStars={5} // Total number of stars
                                          name="rating" // Unique name for the component
                                          starDimension="18px"
                                          starSpacing="2px"
                                        />
                                        <h3>Tiaraa Hotels and Resorts Manali</h3>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </div>
                    </Accordion> */}

                    {/* <Accordion eventKey="1">
                      <div className="accordion-item">
                        <Accordion.Header>Day 2</Accordion.Header>
                        <Accordion.Body>
                          <ul className="ItineraryList">
                            <li>
                              <div className="row">
                                <div className="col-md-2">
                                  <div className="BorderBox">
                                    <h3>Day</h3>
                                    <h4>2</h4>
                                  </div>
                                </div>
                                <div className="col-md-10 ItineraryRightContent">
                                  <h3>Arrive Leh : 3505 meters / 11567 feet</h3>
                                  <h4>
                                    Meals: <span>Dinner Only</span>{" "}
                                  </h4>
                                  <p>
                                    Arrive Kushok Bakula airport Leh - 3500m above sea level. Transfer to hotel. Breakfast at the hotel before 09.30 am. Half
                                    Day at rest for acclimatization. Lunch at the hotel. After Lunch drive to Visit Shanti Stupa & Leh Palace, Later in the
                                    evening walk around local market. Overnight stay at the Hotel.
                                  </p>
                                  <h5>Flight</h5>
                                  <div className="FlightListSec">
                                    <ul className="row FlightList">
                                      <li>
                                        <h4>Air India</h4>
                                        <h5>AI 445</h5>
                                      </li>
                                      <li>
                                        <h4>New Delhi</h4>
                                      </li>
                                      <li className="text-center">
                                        <img src={FlightFromImg} alt="" className="FlightFromImg" />
                                        <span className="FlightClass">Economy</span>
                                      </li>
                                      <li className="flightWidth">
                                        <h4>Leh</h4>
                                      </li>
                                    </ul>
                                  </div>

                                  <h5>Sightseeing</h5>
                                  <ul className="row GreenArrowList">
                                    <li>Visit Shanti Stupa & Leh Palace</li>
                                  </ul>

                                  <h5>Meal</h5>
                                  <ul className="row GreenArrowList">
                                    <li style={{textTransform: "capitalize"}}>Dinner Only</li>
                                  </ul>

                                  <div className="HotelDesSec">
                                    <h3>Hotel</h3>
                                    <p>Check In For 1 Nights</p>
                                    <ul className="row HotelDesSecList">
                                      <li className="col-md-4">
                                        <Slider {...hotelSliderSettings}>
                                          <div>
                                            <img src={HotelImg} alt="" />
                                          </div>
                                          <div>
                                            <img src={HotelImg} alt="" />
                                          </div>
                                        </Slider>
                                      </li>
                                      <li className="col-md-8 TextRightSec">
                                        <StarRatings
                                          rating={4} // Initial rating value
                                          starRatedColor="#F8B84E" // Color of the filled stars
                                          numberOfStars={5} // Total number of stars
                                          name="rating" // Unique name for the component
                                          starDimension="18px"
                                          starSpacing="2px"
                                        />
                                        <h3>Tiaraa Hotels and Resorts Manali</h3>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </div>
                    </Accordion> */}
                  </Accordion>
                </div>
              </div>

              <div className="itternaryBooking">
                <div className="ConditionAccordion">
                  <ul className="bookPricing">
                    <li>
                      <div className="thHdn">Base Price</div>
                      <div className="tdHdn">
                        ₹
                        {(parseFloat(priceDetails?.basePrice) + parseFloat(priceDetails?.bulkDiscountPrice)).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </li>
                    <li>
                      <div className="thHdn">Add On</div>
                      <div className="tdHdn">
                        ₹{parseFloat(priceDetails?.addOnPrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                      </div>
                    </li>
                    {priceDetails.gstTax > 0 ? (
                      <li>
                        <div className="thHdn">GST({priceDetails?.gstTax}%)</div>
                        <div className="tdHdn">
                          ₹{Number(priceDetails?.gstTaxIncludePrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </li>
                    ) : null}
                    {priceDetails.tcsTax > 0 ? (
                      <li>
                        <div className="thHdn">GST({priceDetails?.tcsTax}%)</div>
                        <div className="tdHdn">
                          ₹{Number(priceDetails?.tcsTaxIncludePrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </li>
                    ) : null}
                    <li>
                      <div className="thHdn">
                        <h3>Total Amount</h3>
                      </div>
                      <div className="tdHdn">
                        <h4>
                          ₹
                          {priceDetails?.subTotalPrice
                            ? Number(priceDetails.subTotalPrice).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "0.00"}
                        </h4>
                      </div>
                    </li>
                    {bookingData?.refund_amount_sum ? (
                      <>
                        <li>
                          <div className="thHdn">Refunded Amount</div>
                          <div className="tdHdn">
                            -₹{Number(bookingData?.refund_amount_sum).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                          </div>
                        </li>
                        <li>
                          <div className="thHdn">
                            <h3>Balance Amount</h3>
                          </div>
                          <div className="tdHdn">
                            <h4>
                              ₹
                              {Number(parseFloat(priceDetails?.subTotalPrice) - parseFloat(bookingData?.refund_amount_sum)).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </h4>
                          </div>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              </div>

              <div className="itternaryBooking">
                <div className="supportNumber">
                  <h5>support Number</h5>
                  <a href="javascirpt:void(0);">
                    {/* <Link to="tel:02268727374"> */}
                    <h4>+91 33 2358 1262</h4>
                  </a>
                </div>
              </div>

              <div className="overviewDiv itternaryBooking">
                <h3>Cancellation Policy</h3>
                <div className="inclusDiv">
                  <ul>
                    {bookingData?.cancellation_policy.map((policy, index) => {
                      let [from, to, percentage] = policy.split("-");
                      return (
                        <li key={index}>
                          {index === 0
                            ? `${from} or more days before departure: ${percentage}% of total package cost`
                            : `Between ${from} to ${to} days before departure: ${percentage}% of total package cost`}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="overviewDiv itternaryBooking">
                <h3>Quote Replied</h3>
                <div className="inclusDiv quoteReply">
                  <img src={noteIcon} />
                  <span className="heading">Not Replied Yet</span>
                  <span className="text">Currently, there is no reply from Vendor</span>
                </div>
              </div>

              {/* <div className="itternaryBooking">
                <h3>Cancellation Policy</h3>
                <div className="ConditionAccordion">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                    centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release
                    of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
                    versions of Lorem Ipsum.
                  </p>
                  <p>
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of
                    using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it
                    look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a
                    search for 'lorem ipsum' will uncover many web sites still in their infancy.
                  </p>
                </div>
              </div> */}

              {bookingData?.bookings_status_id === 2 || bookingData?.bookings_status_id === 5 ? (
                <>
                  <div className="bookingBtns">
                    <button onClick={() => navigate(`/booking/${bookingId}/partial-cancellation`)}>Partial Cancel</button>
                    <button className="cancelBook" onClick={() => navigate(`/booking/${bookingId}/cancellation`)}>
                      Cancel
                    </button>
                    {/* <button className="reportBook" onClick={handleAddInclusion}>
                  Report
                </button> */}
                  </div>
                </>
              ) : null}

              {bookingData?.bookings_status_id === 3 && showReportButton && !bookingData?.report ? (
                <>
                  <div className="bookingBtns">
                    <button className="reportBook" onClick={() => setShowPopup(true)}>
                      Report
                    </button>
                  </div>
                </>
              ) : null}

              {showPopup && (
                <div className="popup addPopup">
                  <div className="sliderflex">
                    <div className="popInner2 reportFrm">
                      <h2>Report</h2>

                      <ul>
                        <li className="fullLIst">
                          <textarea
                            placeholder="Enter your complain here"
                            maxLength={500}
                            value={reportIssueText}
                            onChange={e => setReportIssueText(e.target.value)}></textarea>
                          <span className="wordCount">{reportIssueText ? reportIssueText?.length : 0}/500</span>
                        </li>

                        <li className="fullLIst">
                          <h4>Images</h4>

                          <div
                            className={`dragUpload ${dragging ? "dragging" : ""}`}
                            onDragOver={e => handleDragEnter(e)}
                            onDragLeave={handleDragLeave}
                            onDrop={e => handleDrop(e)}>
                            {reportIssueImages.length > 0 && (
                              <div className="uploadedImagesContainer">
                                {reportIssueImages.map((image, index) => (
                                  <div key={index} className="uploadedImage">
                                    <img src={image.preview} alt={`Uploaded ${index + 1}`} />
                                    <button onClick={() => handleRemoveReportImage(index)}></button>
                                  </div>
                                ))}
                              </div>
                            )}

                            {reportIssueImages.length === 0 && (
                              <div className="dragImg">
                                <img src={dragIcon} alt="" />
                                Drag and drop here
                              </div>
                            )}
                          </div>

                          <p className="dragtxt2">
                            Images size should be below 1mb for each. Allowed file extensions are (png, jpeg, and jpg). Maximum 5 images are allowed.
                          </p>
                        </li>

                        <li className="fullLIst sbmt">
                          {reportBtnDis ? (
                            <button disabled className="addBtn">
                              Please wait...
                            </button>
                          ) : (
                            <button onClick={() => handleSubmitReport()} className="addBtn">
                              Submit
                            </button>
                          )}
                        </li>
                      </ul>

                      <button className="closePopupBtn2" onClick={() => setShowPopup(false)}>
                        <img src={crossIcon2} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {showRatePopup && (
                <div className="popup addPopup">
                  <div className="sliderflex">
                    <div className="popInner2 reportFrm">
                      <h2>Rate Us!</h2>

                      <ul>
                        <li className="fullLIst">
                          <StarRatings
                            rating={rating} // Initial rating value
                            starRatedColor="#F8B84E" // Color of the filled stars
                            numberOfStars={5} // Total number of stars
                            name="rating" // Unique name for the component
                            starDimension="30px"
                            starSpacing="2px"
                            changeRating={rating => setRating(rating)}
                          />
                        </li>
                        <li className="fullLIst">
                          <textarea
                            placeholder="Enter your feedback here"
                            maxLength={500}
                            value={feedback}
                            onChange={e => setFeedback(e.target.value)}></textarea>
                          <span className="wordCount">{feedback ? feedback?.length : 0}/500</span>
                        </li>
                        <li className="fullLIst sbmt">
                          {reportBtnDis ? (
                            <button disabled className="addBtn">
                              Please wait...
                            </button>
                          ) : (
                            <button onClick={() => handleSubmitFeedback()} className="addBtn">
                              Submit
                            </button>
                          )}
                        </li>
                      </ul>

                      <button className="closePopupBtn2" onClick={() => setShowRatePopup(false)}>
                        <img src={crossIcon2} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OnRequestQuoteDetails;
