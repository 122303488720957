import React, {Component, useState, useRef, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";
import {
  addPackageApi,
  changePackageStatusApi,
  getCityListApi,
  getStatesListApi,
  getThemesListApi,
  getTripListApi,
  getTypeOfTourPackagesListApi,
  vendorPackageListApi,
} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import { Form, Button, Dropdown, Tabs, Tab, Modal } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import VendorBookingHistoryTable from "../component/vendor-booking-history-table";
import VendorTransactionHistoryTable from "../component/vendor-transaction-history-table";
import VendorManualAdjustmentsTable from "../component/vendor-manual-adjustments-table";
import VendorInventoryTable2 from "../component/vendor-inventory-table2.js";
import VendorInventoryTable3 from "../component/vendor-inventory-table3.js";


import dotsImg from "../assets/img/3dots.svg";
import tickcalenderImg from "../assets/img/white-tick-calender.svg";
import crosscalenderImg from "../assets/img/white-cross-calender.svg";
import editcalenderImg from "../assets/img/white-edit-calender.svg";
import disputcalenderImg from "../assets/img/white-disput-calender.svg";
import totalPaymentInwardImg from "../assets/img/total-payment-inward-icon.svg";
import totalPaymentOutwardImg from "../assets/img/total-payment-outward-icon.svg";
import totalBalanceTransactionImg from "../assets/img/total-balance-transaction-icon.svg";
import totalCashMonthTransactionImg from "../assets/img/total-cash-of-month-transaction-icon.svg";

const VendorReportsInventory2 = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState('tab5'); 
  const [innerKey, setInnerKey] = useState('InnerTab1'); 
  const [packageList, setPackageList] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("all");
  const [dynamicTableKey, setDynamicTableKey] = useState(1);

  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);

  const columns = [
    {
      name: "Package Title",
      selector: row => {
        const maxLength = 35; // Maximum number of characters before adding ellipsis
        const packageName = row.package_name;
        return packageName.length > maxLength ? packageName.slice(0, maxLength) + "..." : packageName;
      },
      sortable: true,
      minWidth: "270px",
      maxWidth: "14%",
    },
    {
      name: "Duration",
      selector: row => row.total_days,
      sortable: true,
      minWidth: "170px",
      maxWidth: "13%",
    },
    {
      name: "Starting Amt.",
      selector: row => row.starting_price,
      sortable: true,
      minWidth: "140px",
      maxWidth: "22%",
    },
    {
      name: "Website Amt.",
      selector: row => row.website_price,
      sortable: true,
      minWidth: "140px",
      maxWidth: "22%",
    },
    {
      name: "Created on",
      selector: row => row.created_at,
      sortable: true,
      minWidth: "130px",
      maxWidth: "17%",
    },
    {
      name: "Origin",
      selector: row => row.origin,
      sortable: true,
      minWidth: "110px",
      maxWidth: "10%",
    },
    {
      name: "Status",
      selector: row => row.status,
      sortable: true,
      minWidth: "75px",
      maxWidth: "12%",
    },
    {
      name: "Action",
      selector: row => row.quoteItems,
      sortable: true,
      minWidth: "90px",
      maxWidth: "12%",
      cell: row => (
        <Dropdown className="AddDropdownBtn">
          <Dropdown.Toggle id="dropdown-basic1">
            <img src={dotsImg} alt="" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Link className="dropdown-item" to={`/business/package/edit/${row?.package_id}`}>
              Edit Package
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const data = [
    {
      quoteID: 1157820,
      reatilerName: "Attilio Gatti",
      businessLegalName: "Maroob Perfume Store",
      RetailerInternalID: "Perfume & Cosmetic",
      quoteDate: "Amman",
      totalQuote: "150 orders",
      quoteItems: "1532.00 JOD",
    },
    {
      quoteID: 1157821,
      reatilerName: "M. Hossain",
      businessLegalName: "Qalb Grocery Store",
      RetailerInternalID: "Grocery",
      quoteDate: "Zarqa",
      totalQuote: "298 orders",
      quoteItems: "2117.00 JOD",
    },
    {
      quoteID: 1157822,
      reatilerName: "Firoja Begum",
      businessLegalName: "New Habibi Amman",
      RetailerInternalID: "Perfume & Cosmetic",
      quoteDate: "Na’ur",
      totalQuote: "432 orders",
      quoteItems: "32115.00 JOD",
    },
    {
      quoteID: 1157823,
      reatilerName: "Jafar Javeed",
      businessLegalName: "Jafar & Sons",
      RetailerInternalID: "Grocery",
      quoteDate: "Beit Raus",
      totalQuote: "298 orders",
      quoteItems: "2117.00 JOD",
    },
    {
      quoteID: 1157824,
      reatilerName: "Sk. Sahanawaz",
      businessLegalName: "Big Grocery",
      RetailerInternalID: "Grocery",
      quoteDate: "Amman",
      totalQuote: "732 orders",
      quoteItems: "4117.00 JOD",
    },
    {
      quoteID: 1157825,
      reatilerName: "Makbul Hossain",
      businessLegalName: "Mood Fragrances",
      RetailerInternalID: "Perfume & Cosmetic",
      quoteDate: "Fuheis",
      totalQuote: "385 orders",
      quoteItems: "2536.00 JOD",
    },
    {
      quoteID: 1157826,
      reatilerName: "Irfaan Khan",
      businessLegalName: "Grocery Market Store",
      RetailerInternalID: "Grocery",
      orderDate: "Fuheis",
      totalQuote: "98 orders",
      quoteItems: "186.00 JOD",
    },
    {
      quoteID: 1157827,
      reatilerName: "Rana Al Abiad",
      businessLegalName: "Food&Food Hub",
      RetailerInternalID: "Grocery",
      orderDate: "Beit Raus",
      totalQuote: "315 orders",
      quoteItems: "5123.00 JOD",
    },
  ];

  const getPackageList = async status => {
    if (status === "initial" && setLoading(true));

    try {
      const formData = new FormData();
      {
        status && status !== "initial" && formData.append("status", status ? status : "");
      }

      let response = await vendorPackageListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setPackageList(response.data.data);
        } else {
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Filter packageList based on the search input
  const filteredPackageList = packageList.filter(item => item?.package_name?.toLowerCase().includes(search?.toLowerCase()));

  const handleCheckBoxChange = state => {
    let tempArray = [];
    if (state?.selectedRows?.length > 0) {
      state?.selectedRows?.map(item => {
        tempArray.push(item?.package_id);
      });
    }
    setSelectedCheckboxIds(tempArray);
  };

  const handleActionChange = async (actionType, status) => {
    setDynamicTableKey(prev => prev + 1);
    try {
      const formData = new FormData();
      if (selectedCheckboxIds?.length > 0) {
        selectedCheckboxIds?.map(item => {
          formData.append("package_ids[]", item);
        });
      }
      formData.append("status", status ? status : "");

      let response = await changePackageStatusApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          if (activeTab === "active" || activeTab === "inactive") {
            const updatedPackageList = packageList.filter(item => !selectedCheckboxIds.includes(parseInt(item.package_id)));
            setPackageList(updatedPackageList);
          } else {
            if (status === "3") {
              const updatedPackageList = packageList.filter(item => !selectedCheckboxIds.includes(parseInt(item.package_id)));
              setPackageList(updatedPackageList);
            } else if (status === "0") {
              const updatedPackageList = packageList.map(item => {
                if (selectedCheckboxIds.includes(parseInt(item.package_id))) {
                  return {...item, status: "Inactive"}; // Assuming 'status' is the property to update
                }
                return item;
              });
              setPackageList(updatedPackageList);
            } else if (status === "1") {
              const updatedPackageList = packageList.map(item => {
                if (selectedCheckboxIds.includes(parseInt(item.package_id))) {
                  return {...item, status: "Active"}; // Assuming 'status' is the property to update
                }
                return item;
              });
              setPackageList(updatedPackageList);
            } else {
            }
          }
          setSelectedCheckboxIds([]);
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {}
  };

  const handleDuplicate = async () => {
    if (selectedCheckboxIds.length > 1) {
      NotificationManager.warning("You have to select only one package to duplicate.");
    } else {
      navigate("/business/package/duplicate/" + selectedCheckboxIds[0]);
    }
  };

  useEffect(() => {
    // Clear selected checkboxes when packageList is updated
    setSelectedCheckboxIds([]);
  }, [packageList]);

  useEffect(() => {
    const result = data.filter(item => {
      //return item.title.toLowerCase().match(search.toLowerCase());
    });
    setFilter(result);
  }, [search]);

  useEffect(() => {
    getPackageList("initial");
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Package List`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Package list page" />
      </Helmet>
      <VenderHeader />
      <div className="venderInnerDiv">
        <VenderSidebar />

        <div className="venderRight">
          <div className="hdrSec">
            <h2>Reports</h2>
            {/* <div className="btnsHdn">
              <button className="saveBtn saveBtn2" onClick={() => navigate('/business/package/add')}>Add Tour Packages</button>
            </div> */}
          </div>
          <div className="venderMain venderMain2">
            <Tabs
              id="controlled-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="tab1" title="Booking History">
                <ul className="row VendorBookingList">
                  <li>
                    <div className="box YellowBpox">
                      <div className="imgSec"><img src={tickcalenderImg} alt="" /></div>
                      <h3>Total Bookings</h3>
                      <h4>385</h4>
                    </div>
                  </li>
                  <li>
                    <div className="box OrangeBox">
                      <div className="imgSec"><img src={crosscalenderImg} alt="" /></div>
                      <h3>Booking Cancelled</h3>
                      <h4>18</h4>
                    </div>
                  </li>
                  <li>
                    <div className="box PurpleBox">
                      <div className="imgSec"><img src={editcalenderImg} alt="" /></div>
                      <h3>Booking Modified</h3>
                      <h4>124</h4>
                    </div>
                  </li>
                  <li>
                    <div className="box GreenBox">
                      <div className="imgSec"><img src={tickcalenderImg} alt="" /></div>
                      <h3>Booking Completed</h3>
                      <h4>199</h4>
                    </div>
                  </li>
                  <li>
                    <div className="box GrayBox">
                      <div className="imgSec"><img src={disputcalenderImg} alt="" /></div>
                      <h3>Booking Disputed</h3>
                      <h4>44</h4>
                    </div>
                  </li>
                </ul>
                <VendorBookingHistoryTable />
                <div className="VendorBookingBottom">
                  <h4>Summary:</h4>
                  <table className="table">
                    <tr>
                      <th>Total Bookings:</th>
                      <td>385</td>
                    </tr>
                    <tr>
                      <th>Booking Cancelled:</th>
                      <td>18</td>
                    </tr>
                    <tr>
                      <th>Booking Modified:</th>
                      <td>124</td>
                    </tr>
                    <tr>
                      <th>Booking Completed:</th>
                      <td>199</td>
                    </tr>
                    <tr>
                      <th>Booking Disputed:</th>
                      <td>44</td>
                    </tr>
                  </table>
                </div>
              </Tab>
              <Tab eventKey="tab2" title="Transaction History">
                <ul className="row VendorBookingList VendorTransactionList">
                  <li>
                    <div className="box YellowBpox">
                      <div className="imgSec"><img src={totalPaymentInwardImg} alt="" /></div>
                      <h3>Total Payment Inward</h3>
                      <h4>16,24,000</h4>
                    </div>
                  </li>
                  <li>
                    <div className="box OrangeBox">
                      <div className="imgSec"><img src={totalPaymentOutwardImg} alt="" /></div>
                      <h3>Total Payment Outward</h3>
                      <h4>1,30,280</h4>
                    </div>
                  </li>
                  <li>
                    <div className="box PurpleBox">
                      <div className="imgSec"><img src={totalBalanceTransactionImg} alt="" /></div>
                      <h3>Total Balance</h3>
                      <h4>8,45,189</h4>
                    </div>
                  </li>
                  <li>
                    <div className="box GreenBox">
                      <div className="imgSec"><img src={totalCashMonthTransactionImg} alt="" /></div>
                      <h3>Total Cash of Month</h3>
                      <h4>12,58,153</h4>
                    </div>
                  </li>
                </ul>
                <VendorTransactionHistoryTable />
                <div className="VendorBookingBottom">
                  <h4>Summary:</h4>
                  <table className="table">
                    <tr>
                      <th>Total Payment Inward:</th>
                      <td>16,24,000</td>
                    </tr>
                    <tr>
                      <th>Total Payment Outward:</th>
                      <td>1,30,280</td>
                    </tr>
                    <tr>
                      <th>Total Balance:</th>
                      <td>8,45,189</td>
                    </tr>
                    <tr>
                      <th>Booking Completed:</th>
                      <td>199</td>
                    </tr>
                    <tr>
                      <th>Total Cash of Month:</th>
                      <td>12,58,153</td>
                    </tr>
                  </table>
                </div>
              </Tab>
              <Tab eventKey="tab3" title="Account Reports">tab 3</Tab>
              <Tab eventKey="tab4" title="Manual Adjustments">
                <VendorManualAdjustmentsTable />
              </Tab>
              <Tab eventKey="tab5" title="Inventory">
                <div className="InnerInventoryTabSec">
                  <div className="InnerInventoryTabRightSec">
                    <div className="BookingsMiddleSec">
                        <ul className="LeftFormList">
                          <li>
                            <label>Select Date</label>
                            <input type="text" name="" value="" placeholder="" className="form-control" />
                          </li>
                          <li><input type="button" name="" value="Download CSV" className="CmnBtn" /></li>
                        </ul>
                    </div>
                  </div>
                  <Tabs
                  id="controlled-tabs"
                  activeKey={innerKey}
                  onSelect={(k) => setInnerKey(k)}
                  >
                    <Tab eventKey="InnerTab1" title="All">
                      <VendorInventoryTable2 />
                    </Tab>
                    <Tab eventKey="innerTab2" title="Cancellation Approved">
                      <VendorInventoryTable3 /> 
                    </Tab>
                  </Tabs>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorReportsInventory2;
