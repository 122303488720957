import React, { Component, useState, useRef, useEffect } from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MyRewards = () => {
  return (
    <div className="main">
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="accountName">
              <h2>Subhendu Bhattacharjee</h2>
              <h3>7980267218</h3>
            </div>
            <div className="pointDiv">
              <h2>
                Wishmytour Points <span>100</span>
              </h2>
            </div>

            <div className="breadCmb">
              <p>
                <Link to="/">My Account</Link>My Rewards
              </p>
            </div>

            <div className="upcomingInner">
              <div className="uptabContent">
                <div className="rewardDiv">
                  <ul>
                    <li>
                      <div className="rewardLft">
                        <h3>ICICIFESTAMZ</h3>
                        <p>
                          Flat 10% OFF on using Amazon ICICI bank credit card.
                        </p>
                      </div>
                      <div className="rewardRgt">
                        <h4>
                          <span>Expiry:</span> Dec 31 2023
                        </h4>
                      </div>
                    </li>
                    <li>
                      <div className="rewardLft">
                        <h3>ICICIFESTAMZ</h3>
                        <p>
                          Flat 10% OFF on using Amazon ICICI bank credit card.
                        </p>
                      </div>
                      <div className="rewardRgt">
                        <h4>
                          <span>Expiry:</span> Dec 31 2023
                        </h4>
                      </div>
                    </li>
                    <li>
                      <div className="rewardLft">
                        <h3>ICICIFESTAMZ</h3>
                        <p>
                          Flat 10% OFF on using Amazon ICICI bank credit card.
                        </p>
                      </div>
                      <div className="rewardRgt">
                        <h4>
                          <span>Expiry:</span> Dec 31 2023
                        </h4>
                      </div>
                    </li>
                    <li>
                      <div className="rewardLft">
                        <h3>ICICIFESTAMZ</h3>
                        <p>
                          Flat 10% OFF on using Amazon ICICI bank credit card.
                        </p>
                      </div>
                      <div className="rewardRgt">
                        <h4>
                          <span>Expiry:</span> Dec 31 2023
                        </h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyRewards;
