import React, {useState, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";
import {fetchVendorNotificationsListApi, notificationMarkAsReadsApi, notificationRemoveAllApi} from "../services/ApiService";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import LoadingPage from "../component/loadingPage";

const notificationsIcon = require("./../assets/img/notifications-icon.svg").default;

const VendorNotificationList = () => {
  const navigate = useNavigate();
  const [notiResp, setNotiResp] = useState();
  const [loading, setLoading] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);

  const fetchVendorNotificationsList = async () => {
    setScreenLoading(true);
    try {
      let response = await fetchVendorNotificationsListApi();
      if (response) {
        setNotiResp(response.data);
      }
      setScreenLoading(false);
    } catch (error) {
      setScreenLoading(false);
    }
  };

  const notificationMarkAsReads = async () => {
    try {
      let response = await notificationMarkAsReadsApi();
      if (response) {
      }
    } catch (error) {}
  };

  const handleNotificationRemoveAll = async () => {
    try {
      let response = await notificationRemoveAllApi();
      if (response) {
        setNotiResp(null);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchVendorNotificationsList();
    notificationMarkAsReads();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Notifications`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Notifications page" />
      </Helmet>
      <VenderHeader />
      <div className="venderInnerDiv">
        <VenderSidebar />

        <div className="venderRight">
          {screenLoading && <LoadingPage />}
          <div className="hdrSec">
            <h2>Notifications</h2>
            {notiResp?.notifications?.length > 0 ? (
              <div className="btnsHdn">
                <button className="saveBtn saveBtn2" onClick={() => handleNotificationRemoveAll()}>
                  Remove All
                </button>
              </div>
            ) : null}
          </div>
          <div className="venderMain venderMain2">
            <div class="notificationSnippet">
              {notiResp?.notifications?.length > 0 ? (
                notiResp?.notifications?.map((item, index) => (
                  <div key={index} class="notificationDescription">
                    <img src={notificationsIcon} alt="" />
                    <div class="contnt">
                      <p>{item?.message}</p>
                      <span>
                        {new Date(item?.created_at).toLocaleString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                    </div>
                  </div>
                ))
              ) : !loading ? (
                <p>No notification found.</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorNotificationList;
