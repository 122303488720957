export const TimeConverter = (time) => {
  // Splitting the time string into hours, minutes, and seconds -> "14:35:00";
  const [hours, minutes, seconds] = time.split(":");

  // Creating a Date object to format the time
  const formattedTime = new Date(0, 0, 0, hours, minutes, seconds);

  // Formatting the time into AM/PM format
  const formattedTimeString = formattedTime.toLocaleString("en-US", {hour: "numeric", minute: "numeric", hour12: true});

  return formattedTimeString;
};
