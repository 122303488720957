import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import {Link, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  exploreDestinationCountsApi,
  fetchLocationsApi,
  fetchSearchDestinationsApi,
  fetchSearchKeywordsApi,
  getBannerPackListApi,
  getBestSellingListApi,
  getCityListApi,
  getDestinationsByThemeListApi,
  getDurationListApi,
  getPackageListApi,
  getPopularDestinationListApi,
} from "../services/ApiService";
import {IMAGE_URL} from "../utils/commonUrls";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Typeahead} from "react-bootstrap-typeahead";
import {Helmet} from "react-helmet";
import {useUserContext} from "../context/UserContext";
import Skeleton from "@mui/material/Skeleton";
import {LazyLoadImage} from "react-lazy-load-image-component";

const slider1 = require("./../assets/img/slider-package1.png");
const slider2 = require("./../assets/img/slider-package2.png");
const tourLogo = require("./../assets/img/tour-logo.png");
const transferIcon = require("./../assets/img/car-icon.svg").default;
const mealIcon = require("./../assets/img/restaurant-icon.svg").default;
const hotelIcon = require("./../assets/img/bed-icon.svg").default;
const optionalIcon = require("./../assets/img/optional-icon.svg").default;
const transferIcon2 = require("./../assets/img/car-icon2.svg").default;
const mealIcon2 = require("./../assets/img/restaurant-icon2.svg").default;
const hotelIcon2 = require("./../assets/img/bed-icon2.svg").default;
const trainIcon = require("./../assets/img/train.svg").default;
const binocularIcon = require("./../assets/img/binoculars.svg").default;
const optionalIcon2 = require("./../assets/img/optional-icon2.svg").default;
const special1 = require("./../assets/img/special1.png");
const destination1 = require("./../assets/img/destination1.png");
const theme1 = require("./../assets/img/theme1.png");
const best1 = require("./../assets/img/best1.png");

const Home = () => {
  const navigate = useNavigate();
  const {userData} = useUserContext();
  const [activeTab, setActiveTab] = useState(3);
  const [specialTourPackageList, setSpecialTourPackageList] = useState();
  const [bestSellingList, setBestSellingList] = useState();
  const [popularDestList, setPopularDestList] = useState();
  const [byThemeList, setByThemeList] = useState();
  const [bannerPackList, setBannerPackList] = useState();
  const [fromValue, setFromValue] = useState([]);
  const [destinationValue, setDestinationValue] = useState([]);
  const [fromValueToSearch, setFromValueToSearch] = useState("");
  const [destinationToSearch, setDestinationToSearch] = useState("");
  const [durationToSearch, setDurationToSearch] = useState("");
  const [cityList, setCityList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [destinationCount, setDestinationCount] = useState();
  const [locationList, setLocationList] = useState([]);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [slider3Settings, setSlider3Settings] = useState({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const slider1Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slider2Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const slider3Settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   responsive: [
  //     {
  //       breakpoint: 1030,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  const handleTabClick = tabNumber => {
    setActiveTab(tabNumber);
    getBestSellingList(tabNumber);
  };

  const getPackageList = async () => {
    try {
      const formData = new FormData();
      formData.append("featured", 1);
      let response = await getPackageListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setSpecialTourPackageList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const fetchBroadLocations = async () => {
    try {
      let response = await fetchLocationsApi();
      if (response) {
        if (response.data.res === true) {
          // setLocationList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const formattedStayPlan = stayPlan => (
    <p>
      {stayPlan.map((stayItem, stayIndex) => (
        <span key={stayIndex}>
          {stayItem.city_name} ({stayItem.total_nights}N){stayIndex < stayPlan.length - 1 && " → "}
        </span>
      ))}
    </p>
  );

  const getBestSellingList = async totalDays => {
    try {
      const formData = new FormData();
      formData.append("total_days", totalDays);
      let response = await getBestSellingListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setBestSellingList(response.data.data);
          setSlider3Settings({
            ...slider3Settings,
            slidesToShow: response.data.data.length > 3 ? 4 : response.data.data.length,
          });
        } else {
        }
      }
    } catch (error) {}
  };

  const getPopularDestinationList = async () => {
    try {
      let response = await getPopularDestinationListApi();
      if (response) {
        if (response.data.res === true) {
          setPopularDestList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getDestinationsByThemeList = async () => {
    try {
      let response = await getDestinationsByThemeListApi();
      if (response) {
        if (response.data.res === true) {
          setByThemeList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getBannerPackList = async () => {
    setLoading(true);
    try {
      let response = await getBannerPackListApi();
      if (response) {
        if (response.data.res === true) {
          setBannerPackList(response.data.data);
        } else {
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getCityList = async () => {
    try {
      const formData = new FormData();
      let response = await getCityListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setCityList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getDurationList = async () => {
    try {
      let response = await getDurationListApi();
      if (response) {
        if (response.data.res === true) {
          setDurationList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const fetchSearchKeywordsList = async () => {
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append("search_keyword", destinationToSearch);
      let response = await fetchSearchDestinationsApi(formData);
      if (response) {
        if (response.data.res === true) {
          setLocationList(response.data.data);
        } else {
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const destinationCountsList = async () => {
    try {
      let response = await exploreDestinationCountsApi();
      if (response) {
        if (response.data.res === true) {
          setDestinationCount(response.data.data.total_locations);
        } else {
        }
      }
    } catch (error) {}
  };

  const handleDestinationInputChange = (text, e) => {
    setDestinationToSearch(text);
  };

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const onResetClick = () => {
    setFromValue("");
    setFromValueToSearch("");
    setDestinationToSearch("");
    setFromValue([]);
    setDestinationValue([]);
  };

  const handleExplorePackage = () => {
    if (destinationValue?.length === 0) {
      // if (fromValue?.length === 0 || destinationValue?.length === 0 || !durationToSearch) {
      NotificationManager.warning("Please select required fields to search.");
    } else if (fromValue[0]?.id === destinationValue[0]?.id) {
      NotificationManager.warning("Origin and destination place should not be same.");
    } else {
      let formatedDate;
      if (selectedDate) {
        formatedDate = new Date(selectedDate).toLocaleDateString("en-US", {
          month: "long",
          year: "numeric",
        });
      }
      let values = {
        from: fromValue[0]?.id,
        destination: destinationValue[0]?.id,
        destinationType: destinationValue[0]?.type,
        destinationName: destinationValue[0]?.name,
        destinationStateId: destinationValue[0]?.state_id,
        // destination: destinationToSearch,
        month: formatedDate,
        fullDate: selectedDate,
        duration: durationToSearch,
      };
      sessionStorage.setItem("wishmytourSearchValues", JSON.stringify(values));
      navigate("/packages");
    }
  };

  const handleClickPoularDestination = (id, name, type) => {
    let values = {
      from: "",
      destination: id,
      destinationType: type,
      destinationName: name,
      destinationStateId: "",
      // destination: destinationToSearch,
      month: "",
      fullDate: "",
      duration: "",
    };
    sessionStorage.setItem("wishmytourSearchValues", JSON.stringify(values));
    navigate("/packages", {state: {fromSection: "popularSection"}});
  };

  const LazyImage = ({src, alt, className}) => {
    const imgRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Corrected the usage of unobserve
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        }
      );

      if (imgRef.current) {
        observer.observe(imgRef.current);
      }

      return () => {
        if (imgRef.current) {
          observer.unobserve(imgRef.current); // Unobserve the imgRef.current
        }
      };
    }, []);

    return <img ref={imgRef} src={isVisible ? src : ""} alt={alt} className={className} style={{opacity: isVisible ? 1 : 0, transition: "opacity 0.5s"}} />;
  };

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (destinationToSearch.length >= 2) {
        fetchSearchKeywordsList(destinationToSearch);
      } else {
        setLocationList([]);
      }
    }, 300); // 300 ms debounce to minimize API calls

    return () => {
      clearTimeout(delayDebounce);
    };
  }, [destinationToSearch]);

  useEffect(() => {
    getPackageList();
    // fetchBroadLocations();
    getBestSellingList(activeTab);
    getPopularDestinationList();
    getDestinationsByThemeList();
    getBannerPackList();
    getCityList();
    getDurationList();
    destinationCountsList();
    sessionStorage.removeItem("wishmytourSearchValues");
    if (userData?.data?.user_type === 3) {
      navigate("/business/packages");
    }
    sessionStorage.removeItem("WMTBookingObj");
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Wish My Tour`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Home page" />
      </Helmet>
      <Header />

      <div className="homeDiv">
        <div className="banner">
          <div className="mainDiv">
            <div className="bannerLft">
              <h2>
                Book your <span>Holiday Packages</span>
              </h2>
              <h3>650+ Travel Agents serving 65+ Destinations worldwide</h3>
              <ul>
                <li>
                  {/* <select>
                      <option>From*</option>
                    </select> */}
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="city"
                    onChange={selected => {
                      setFromValue(selected);
                    }}
                    options={cityList}
                    placeholder="From"
                    selected={fromValue}
                  />
                </li>
                <li>
                  {/* <input type="text" placeholder="Destination*" value={destinationToSearch} onChange={e => setDestinationToSearch(e.target.value)} /> */}
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={selected => {
                      setDestinationValue(selected);
                    }}
                    onInputChange={handleDestinationInputChange}
                    options={locationList}
                    // options={cityList}
                    placeholder="Destination*"
                    selected={destinationValue}
                    // minLength={3}
                    // isLoading={isLoading}
                  />
                </li>
                <li>
                  <select placeholder="Select Duration*" onChange={e => setDurationToSearch(e.target.value)}>
                    <option>Select Duration (Optional)</option>
                    {durationList?.map((item, index) => (
                      <option key={index} value={item?.total_days}>
                        {item?.total_days} days
                      </option>
                    ))}
                  </select>
                </li>
                <li>
                  <DatePicker
                    placeholderText="Select Month (Optional)"
                    selected={selectedDate}
                    onChange={handleDateChange}
                    showMonthYearPicker
                    dateFormat="MMMM yyyy"
                  />
                  {/* <select>
                    <option>Select Month (Optional)</option>
                  </select> */}
                </li>
                <li>
                  <button onClick={() => handleExplorePackage()} className="btn">
                    Explore Packages
                  </button>
                </li>
                <li className="resetBtn" style={{cursor: "pointer"}} onClick={() => onResetClick()}>
                  Reset
                </li>
              </ul>
              <p>
                Destination not sure? <Link to="/packages">Click here!</Link>
              </p>
              {/* <DatePicker selected={startDate} onChange={date => setStartDate(date)} showMonthYearPicker dateFormat="MMMM yyyy" /> */}
            </div>

            <div className="bannerRgt">
              <Slider {...settings}>
                {loading
                  ? Array.from(new Array(4)).map((_, index) => (
                      <>
                        <div key={index}>
                          <div className="boxDiv">
                            <Skeleton animation="wave" variant="rectangular" width={"100%"}>
                              <img src={""} alt="Image 1" className="bannerSliderImg" />{" "}
                            </Skeleton>
                            <div className="bannerTxt">
                              <Skeleton animation="wave" width={150}>
                                <h3></h3>
                              </Skeleton>
                              <ul>
                                <li>
                                  <Skeleton animation="wave" width={150}></Skeleton>
                                </li>
                              </ul>
                              <Skeleton animation="wave" width={150}>
                                <h2></h2>
                              </Skeleton>
                            </div>
                          </div>
                        </div>
                        <div key={index}>
                          <div className="boxDiv">
                            <Skeleton animation="wave" variant="rectangular" width={"100%"}>
                              <img src={""} alt="Image 1" className="bannerSliderImg" />{" "}
                            </Skeleton>
                            <div className="bannerTxt">
                              <Skeleton animation="wave" width={150}>
                                <h3></h3>
                              </Skeleton>
                              <ul>
                                <li>
                                  <Skeleton animation="wave" width={150}></Skeleton>
                                </li>
                              </ul>
                              <Skeleton animation="wave" width={150}>
                                <h2></h2>
                              </Skeleton>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  : bannerPackList?.map((item, index) => (
                      <div key={index}>
                        {item?.map((chItem, chIndex) => (
                          <Link to={`/package/${chItem?.package_id}`}>
                            <div className="boxDiv" key={chIndex}>
                              {/* <LazyImage src={IMAGE_URL + chItem?.featured_image_path} alt="Image 1" className="bannerSliderImg" /> */}
                              {/* <LazyLoadImage src={IMAGE_URL + chItem?.featured_image_path} alt="Image 1" className="bannerSliderImg" /> */}
                              <img src={IMAGE_URL + chItem?.featured_image_path} alt="Image 1" className="bannerSliderImg" />
                              <div className="bannerTxt">
                                <h3>{chItem?.total_days}</h3>
                                <ul>
                                  {chItem?.inclusionIds?.map((incluItem, incluIndex) => (
                                    <li key={incluIndex}>
                                      {incluItem.slug === "flight" && <img src={optionalIcon} alt="Flight Icon" />}
                                      {incluItem.slug === "hotel" && <img src={hotelIcon} alt="Flight Icon" />}
                                      {incluItem.slug === "meals" && <img src={mealIcon} alt="Meals Icon" />}
                                      {incluItem.slug === "transfer" && <img src={transferIcon} alt="Transfer Icon" />}
                                      {incluItem.name}
                                    </li>
                                  ))}
                                  {/* <li>
                              <img src={transferIcon} alt="Transfer Icon" />
                              Transfer
                            </li>
                            <li>
                              <img src={mealIcon} alt="Image 1" />
                              Meals
                            </li>
                            <li>
                              <img src={hotelIcon} alt="Image 1" />
                              Hotel
                            </li>
                            <li>
                              <img src={optionalIcon} alt="Image 1" />
                              Optional
                            </li> */}
                                </ul>
                                <h2>{truncateString(chItem?.package_name, 22)}</h2>
                              </div>
                            </div>
                          </Link>
                        ))}
                        {/* <div className="boxDiv">
                      <img src={slider1} alt="Image 1" className="bannerSliderImg" />
                      <div className="bannerTxt">
                        <h3>3 Nights & 4 Days</h3>
                        <ul>
                          <li>
                            <img src={transferIcon} alt="Image 1" />
                            Transfer
                          </li>
                          <li>
                            <img src={mealIcon} alt="Image 1" />
                            Meals
                          </li>
                          <li>
                            <img src={hotelIcon} alt="Image 1" />
                            Hotel
                          </li>
                          <li>
                            <img src={optionalIcon} alt="Image 1" />
                            Optional
                          </li>
                        </ul>
                        <h2>Himachal Temple Package</h2>
                      </div>
                    </div>
                    <div className="boxDiv">
                      <img src={slider2} alt="Image 2" className="bannerSliderImg" />
                      <div className="bannerTxt">
                        <h3>3 Nights & 4 Days</h3>
                        <ul>
                          <li>
                            <img src={transferIcon} alt="Image 1" />
                            Transfer
                          </li>
                          <li>
                            <img src={mealIcon} alt="Image 1" />
                            Meals
                          </li>
                          <li>
                            <img src={hotelIcon} alt="Image 1" />
                            Hotel
                          </li>
                          <li>
                            <img src={optionalIcon} alt="Image 1" />
                            Optional
                          </li>
                        </ul>
                        <h2>North Sikkim</h2>
                      </div>
                    </div> */}

                        {/* <div className="bannerBtm">
                      <div className="tourLogo">
                        <img src={tourLogo} alt="Image 1" className="bannerSliderImg" />
                      </div>
                      <div className="tourOffer">Flight Deal: Buy 1 Get 1 Ticket to Hong Kong with Cathay Pacific</div>
                    </div> */}
                      </div>
                    ))}
                {/* Add more slides with images */}
              </Slider>
            </div>
          </div>
        </div>

        <div className="specialDiv">
          <div className="mainDiv">
            <h2>
              Wishmytour <span>Special</span>
            </h2>

            <div className="specialSlider">
              {loading ? (
                Array.from(new Array(6)).map((_, index) => (
                  <div key={index}>
                    <div className="specialBox">
                      <Skeleton animation="wave" variant="rectangular" width={"100%"}>
                        <img src={""} alt="Image 1" className="specialSliderImg" />
                      </Skeleton>
                      <div className="bannerTxt">
                        <Skeleton animation="wave" width={180} height={30}>
                          <h2></h2>
                        </Skeleton>
                        <Skeleton animation="wave" width={150}>
                          <h3></h3>
                        </Skeleton>
                        <Skeleton animation="wave" width={150}>
                          <span></span>
                        </Skeleton>
                        <h5>
                          <Skeleton animation="wave" width={150}>
                            <span>(Starting From)</span>
                          </Skeleton>
                        </h5>
                        <Skeleton animation="wave" width={80}></Skeleton>
                      </div>
                    </div>
                  </div>
                ))
              ) : specialTourPackageList && specialTourPackageList?.length < 4 ? (
                <div className="notSlider">
                  {specialTourPackageList?.map((item, index) => (
                    <Link to={`/package/${item?.package_id}`} key={index}>
                      <div key={index}>
                        <div className="specialBox">
                          <img src={IMAGE_URL + item?.featured_image_path} alt="Image 1" className="specialSliderImg" />
                          {/* <LazyLoadImage src={IMAGE_URL + item?.featured_image_path} alt="Image 1" className="specialSliderImg" /> */}
                          {/* <LazyImage src={IMAGE_URL + item?.featured_image_path} alt="Image 1" className="specialSliderImg" /> */}
                          <div className="bannerTxt">
                            <h2>
                              <h2>{truncateString(item?.package_name, 30)}</h2>
                            </h2>
                            <h4>{item?.vendor_name ? <>{/* By: <span>{item?.vendor_name}</span> */}</> : null}</h4>

                            <h3>{item?.total_days}</h3>
                            <ul>
                              {/* {item?.inclusions_in_package?.map((incluItem, incluIndex) => (
                                <li key={incluIndex}>
                                  {incluItem.slug === "transfer" && <img src={transferIcon2} alt="Transfer Icon" />}
                                  {incluItem.slug === "meals" && <img src={mealIcon2} alt="Meals Icon" />}
                                  {incluItem.slug === "flight" && <img src={optionalIcon2} alt="Flight Icon" />}
                                  {incluItem.slug === "hotel" && <img src={hotelIcon2} alt="Flight Icon" />}
                                  {incluItem.name}
                                </li>
                              ))} */}
                              {item?.is_flight === 1 ? (
                                <li>
                                  <img src={optionalIcon2} alt="Image 1" />
                                  Flight
                                </li>
                              ) : null}
                              {item?.is_train === 1 ? (
                                <li>
                                  <img src={trainIcon} alt="Image 1" />
                                  Train
                                </li>
                              ) : null}
                              {item?.is_hotel === 1 ? (
                                <li>
                                  <img src={hotelIcon2} alt="Image 1" />
                                  Hotel
                                </li>
                              ) : null}
                              {item?.is_meal === 1 ? (
                                <li>
                                  <img src={mealIcon2} alt="Image 1" />
                                  Meals
                                </li>
                              ) : null}
                              {item?.is_transport === 1 ? (
                                <li>
                                  <img src={transferIcon2} alt="Image 1" />
                                  Transfer
                                </li>
                              ) : null}
                              {item?.is_sightseeing === 1 ? (
                                <li>
                                  <img src={binocularIcon} alt="Image 1" />
                                  Sightseeing
                                </li>
                              ) : null}
                            </ul>
                            {formattedStayPlan(item?.stay_plan)}
                            {/* {item?.stay_plan?.map((stayItem, stayIndex) => (
                                <p key={stayIndex}>
                                  
                                  {stayItem?.city_name} ({stayItem?.total_nights}N) → Nubra (1N) → Pangong (1N)
                                </p>
                              ))} */}
                            <h5>
                              ₹{item?.starting_price.toLocaleString()} <span>(Starting From)</span>
                            </h5>
                            <Link to={`/package/${item?.package_id}`}>View Details</Link>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                <Slider {...slider1Settings}>
                  {specialTourPackageList?.map((item, index) => (
                    <Link to={`/package/${item?.package_id}`} key={index}>
                      <div key={index}>
                        <div className="specialBox">
                          <img src={IMAGE_URL + item?.featured_image_path} alt="Image 1" className="specialSliderImg" />
                          {/* <LazyLoadImage src={IMAGE_URL + item?.featured_image_path} alt="Image 1" className="specialSliderImg" /> */}
                          {/* <LazyImage src={IMAGE_URL + item?.featured_image_path} alt="Image 1" className="specialSliderImg" /> */}
                          <div className="bannerTxt">
                            <h2>
                              <h2>{truncateString(item?.package_name, 30)}</h2>
                            </h2>
                            <h4>{item?.vendor_name ? <>{/* By: <span>{item?.vendor_name}</span> */}</> : null}</h4>

                            <h3>{item?.total_days}</h3>
                            <ul>
                              {/* {item?.inclusions_in_package?.map((incluItem, incluIndex) => (
                                <li key={incluIndex}>
                                  {incluItem.slug === "transfer" && <img src={transferIcon2} alt="Transfer Icon" />}
                                  {incluItem.slug === "meals" && <img src={mealIcon2} alt="Meals Icon" />}
                                  {incluItem.slug === "flight" && <img src={optionalIcon2} alt="Flight Icon" />}
                                  {incluItem.slug === "hotel" && <img src={hotelIcon2} alt="Flight Icon" />}
                                  {incluItem.name}
                                </li>
                              ))} */}
                              {item?.is_flight === 1 ? (
                                <li>
                                  <img src={optionalIcon2} alt="Image 1" />
                                  Flight
                                </li>
                              ) : null}
                              {item?.is_train === 1 ? (
                                <li>
                                  <img src={trainIcon} alt="Image 1" />
                                  Train
                                </li>
                              ) : null}
                              {item?.is_hotel === 1 ? (
                                <li>
                                  <img src={hotelIcon2} alt="Image 1" />
                                  Hotel
                                </li>
                              ) : null}
                              {item?.is_meal === 1 ? (
                                <li>
                                  <img src={mealIcon2} alt="Image 1" />
                                  Meals
                                </li>
                              ) : null}
                              {item?.is_transport === 1 ? (
                                <li>
                                  <img src={transferIcon2} alt="Image 1" />
                                  Transfer
                                </li>
                              ) : null}
                              {item?.is_sightseeing === 1 ? (
                                <li>
                                  <img src={binocularIcon} alt="Image 1" />
                                  Sightseeing
                                </li>
                              ) : null}
                            </ul>
                            {formattedStayPlan(item?.stay_plan)}
                            {/* {item?.stay_plan?.map((stayItem, stayIndex) => (
                                <p key={stayIndex}>
                                  
                                  {stayItem?.city_name} ({stayItem?.total_nights}N) → Nubra (1N) → Pangong (1N)
                                </p>
                              ))} */}
                            <h5>
                              ₹{item?.starting_price.toLocaleString()} <span>(Starting From)</span>
                            </h5>
                            <Link to={`/package/${item?.package_id}`}>View Details</Link>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </Slider>
              )}
            </div>

            <Link to="/packages" className="viewBtn">
              View All
            </Link>
          </div>
        </div>

        <div className="bestSellingDiv">
          <div className="mainDiv">
            <div className="bestTop">
              <h2>
                Explore <span>Best Selling</span> Packages
              </h2>

              {/* <div className="bestPackage">
                <div className="checkBx">
                  <input type="checkbox" checked /> Domestic Packages
                </div>
                <div className="checkBx">
                  <input type="checkbox" /> International Packages
                </div>
              </div> */}
            </div>

            <div className="tabDiv">
              <div className="tabComponent">
                <div className="tabHeaders">
                  <button className={activeTab === 3 ? "activeTab" : ""} onClick={() => handleTabClick(3)}>
                    <div className="roundDiv"></div>1 to 3 Days
                  </button>
                  <button className={activeTab === 6 ? "activeTab" : ""} onClick={() => handleTabClick(6)}>
                    <div className="roundDiv"></div>4 to 6 Days
                  </button>
                  <button className={activeTab === 9 ? "activeTab" : ""} onClick={() => handleTabClick(9)}>
                    <div className="roundDiv"></div>7 to 9 Days
                  </button>

                  <button className={activeTab === 12 ? "activeTab" : ""} onClick={() => handleTabClick(12)}>
                    <div className="roundDiv"></div>10 to 12 Days
                  </button>
                  <button className={activeTab === 13 ? "activeTab" : ""} onClick={() => handleTabClick(13)}>
                    <div className="roundDiv"></div>13 Days or More
                  </button>
                  <div className="dividerLine"></div>
                </div>
                <div className="tabContent">
                  {/* {activeTab === 1 && ( */}
                  <div className="tabDiv">
                    {loading ? (
                      <Slider {...slider3Settings}>
                        {Array.from(new Array(2))?.map((_, index) => (
                          <div key={index}>
                            <div className="bestBoxDiv">
                              <div className="bestImg">
                                <Skeleton key={index} animation="wave" variant="rectangular" width={"100%"}>
                                  <img src={""} alt="" />
                                </Skeleton>
                                <div className="imgCaption">
                                  <Skeleton key={index} animation="wave" width={170} height={18}>
                                    <p></p>
                                  </Skeleton>
                                  <Skeleton key={index} animation="wave" width={170} height={18}>
                                    <h3></h3>
                                  </Skeleton>
                                </div>
                              </div>
                              <div className="bestCaption">
                                <Skeleton key={index} animation="wave" width={170} height={18}>
                                  <h3></h3>
                                </Skeleton>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    ) : bestSellingList?.length > 0 ? (
                      <Slider {...slider3Settings}>
                        {bestSellingList?.map((item, index) => (
                          <Link
                          key={index}
                            to={`/package/${item?.id}`}
                            state={{
                              location_id: item?.location_id,
                              // destination_state_id: item?.destination_state_id,
                              total_days:
                                activeTab === 3
                                  ? "1-3"
                                  : activeTab === 6
                                  ? "4-6"
                                  : activeTab === 9
                                  ? "7-9"
                                  : activeTab === 12
                                  ? "10-12"
                                  : activeTab === 13
                                  ? "13-100"
                                  : "",
                            }}>
                            <div key={index}>
                              <div className="bestBoxDiv">
                                <div className="bestImg">
                                  <img src={IMAGE_URL + item?.last_featured_image_path} alt="" />
                                  {/* <LazyLoadImage src={IMAGE_URL + item?.last_featured_image_path} alt="" /> */}
                                  {/* <LazyImage src={IMAGE_URL + item?.last_featured_image_path} alt="Image 1" /> */}
                                  <div className="imgCaption">
                                    <p>Starting From:</p>
                                    <h3>₹{item?.min_starting_price.toLocaleString()}</h3>
                                  </div>
                                </div>
                                <div className="bestCaption">
                                  <h3>{item?.location_name}</h3>
                                  {/* <p>{item?.package_count} + Packages</p> */}
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </Slider>
                    ) : (
                      <p className="noPackageFound">No Packages Found.</p>
                    )}
                  </div>
                  {/* // )} */}
                </div>
              </div>
            </div>
            <Link to="/packages" state={{id: 1}} className="viewBtn">
              View All
            </Link>
          </div>
        </div>

        <div className="destinationDiv">
          <div className="mainDiv">
            <h2>
              Popular <span>Destination</span>
            </h2>
            <p>We have selected some best locations around the world for you.</p>
            <ul>
              {loading
                ? Array.from(new Array(6))?.map((_, index) => (
                    <li key={index}>
                      <Skeleton key={index} animation="wave" variant="rectangular" width={"100%"} height={271}>
                        <img src={""} alt="" />
                      </Skeleton>
                      <Skeleton key={index} animation="wave" width={170} height={30}>
                        <h3></h3>
                      </Skeleton>
                    </li>
                  ))
                : popularDestList?.map((item, index) => (
                    <li key={index}>
                      <Link
                        to={`javascript:void(0);`}
                        onClick={() => handleClickPoularDestination(item?.destination_id, item?.destination_name, item?.destination_type)}>
                        <img src={IMAGE_URL + item?.last_package_featured_image_path} alt="" />
                        {/* <LazyLoadImage src={IMAGE_URL + item?.last_package_featured_image_path} alt="" /> */}
                        {/* <LazyImage src={IMAGE_URL + item?.last_package_featured_image_path} alt="" /> */}
                        <h3>{item?.destination_name}</h3>
                      </Link>
                    </li>
                  ))}

              {/* <li>
                <img src={destination1} alt="" />
                <h3>London</h3>
              </li> */}
              {/* <li>
                <img src={destination1} alt="" />
                <h3>Krabi</h3>
              </li>
              <li>
                <img src={destination1} alt="" />
                <h3>Maldives</h3>
              </li>
              <li>
                <img src={destination1} alt="" />
                <h3>Dubai</h3>
              </li>
              <li>
                <img src={destination1} alt="" />
                <h3>Goa</h3>
              </li>
              <li>
                <img src={destination1} alt="" />
                <h3>Australia</h3>
              </li> */}
            </ul>
            <Link to="/packages" className="viewBtn">
              View All
            </Link>
          </div>
        </div>

        <div className="themeDiv">
          <div className="mainDiv">
            <h2>
              Explore <span>Destinations</span> By Theme
            </h2>

            <div className="themeSlider">
              <Slider {...slider2Settings}>
                {loading
                  ? Array.from(new Array(4))?.map((_, index) => (
                      <div key={index}>
                        <div className="themeBox">
                          <Skeleton key={index} animation="wave" variant="circular" width={"100%"} height={271}>
                            <img src={""} alt="" />
                          </Skeleton>
                        </div>
                      </div>
                    ))
                  : byThemeList?.map((item, index) => (
                      <Link to={`/packages`} state={{theme_id: item?.theme_id}} key={index}>
                        <div key={index}>
                          <div className="themeBox">
                            <img src={IMAGE_URL + item?.image} alt="" />
                            {/* <LazyLoadImage src={IMAGE_URL + item?.image} alt="" /> */}
                            {/* <LazyImage src={IMAGE_URL + item?.image} alt="" /> */}
                            <h3>{item?.name}</h3>
                            <p>
                              {parseInt(item?.destination_state_count) > 0
                                ? item?.destination_state_count + " + Destinations"
                                : item?.destination_state_count + " Destination"}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}

                {/* <div>
                  <div className="themeBox">
                    <img src={theme1} alt="" />
                    <h3>Adventure</h3>
                    <p>30 + Destinations</p>
                  </div>
                </div>
                <div>
                  <div className="themeBox">
                    <img src={theme1} alt="" />
                    <h3>Nature</h3>
                    <p>30 + Destinations</p>
                  </div>
                </div>
                <div>
                  <div className="themeBox">
                    <img src={theme1} alt="" />
                    <h3>Religious</h3>
                    <p>30 + Destinations</p>
                  </div>
                </div>
                <div>
                  <div className="themeBox">
                    <img src={theme1} alt="" />
                    <h3>Wildlife</h3>
                    <p>30 + Destinations</p>
                  </div>
                </div>
                <div>
                  <div className="themeBox">
                    <img src={theme1} alt="" />
                    <h3>Nature</h3>
                    <p>30 + Destinations</p>
                  </div>
                </div> */}
              </Slider>
            </div>

            <div className="themeBtns">
              <h3>For Best Packages, </h3>
              <Link to="/" className="callBtn">
                Call Us At +91 33 2358 1262
              </Link>
              <Link to="/packages" className="exploreBtn">
                Explore {destinationCount} + Destinations
              </Link>
            </div>
          </div>
        </div>

        <div className="packageDiv">
          <div className="mainDiv">
            <div className="formDiv">
              <h3>Our Experts would love to</h3>
              <h2>
                Create a <span>package</span> just for you!
              </h2>
              <h3>How do we connect you?</h3>
              <ul>
                <li>
                  <input type="email" placeholder="Email ID" />
                </li>
                <li>
                  <input type="text" placeholder="Phone Number" />
                </li>
                <li>
                  <input type="checkbox" /> Send trip update on Whatsapp and Newsletter
                </li>
                <li>
                  <button>Next</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
