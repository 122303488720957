import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Helmet} from "react-helmet";
import {completedBookingApi, getCustomerDetailsApi} from "../services/ApiService";
import {IMAGE_URL} from "../utils/commonUrls";

const transferIcon2 = require("./../assets/img/car-icon2.svg").default;
const mealIcon2 = require("./../assets/img/restaurant-icon2.svg").default;
const hotelIcon2 = require("./../assets/img/bed-icon2.svg").default;
const optionalIcon2 = require("./../assets/img/optional-icon2.svg").default;
const special1 = require("./../assets/img/special1.png");
const trainIcon = require("./../assets/img/train.svg").default;
const binocularIcon = require("./../assets/img/binoculars.svg").default;

const CompletedTour = () => {
  const [customerData, setCustomerData] = useState();
  const [packageList, setPackageList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [searchPressed, setSearchPressed] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustomerData(response.data);
        }
      }
    } catch (error) {}
  };

  const handleSearchInputChange = event => {
    setSearchQuery(event.target.value);
  };

  const performSearch = () => {
    setSearchPressed(true);
    const filteredPackageList = packageList.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
    setSearchResult(filteredPackageList);
  };

  const getUpcomingBooking = async () => {
    setLoading(true);
    try {
      let response = await completedBookingApi();
      if (response) {
        if (response.data.res === true) {
          setPackageList(response.data.data);
          setSearchResult(response.data.data);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const formarDateFunc = date => {
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
    });
    return formattedDate;
  };

  useEffect(() => {
    getCustomerDetails();
    getUpcomingBooking();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Completed Tour`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Completed Tour page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="accountName">
              <h2>
                {customerData?.data?.first_name} {customerData?.data?.last_name}
              </h2>
              <h3>{customerData?.User?.mobile}</h3>
            </div>
            <div className="pointDiv">
              <h2>
                Wishmytour Points <span>0</span>
              </h2>
            </div>

            <div className="breadCmb">
              <p>
                <Link to="/profile">My Account</Link>My Tour
              </p>
            </div>

            <div className="upcomingInner">
              <div className="upcomingSearch">
                <input type="text" placeholder="Search for a Booking" value={searchQuery} onChange={handleSearchInputChange} />
                <button onClick={performSearch}>Search</button>
              </div>

              <div className="uptab">
                <Link to="/tour/upcoming">Upcoming Tour</Link>
                <Link to="/tour/completed" className="activeTab">
                  Completed Tour
                </Link>
                <Link to="/tour/cancelled">Cancelled Tour</Link>
              </div>

              <div className="uptabContent">
                <div className="tourListing">
                  {/* <div className="specialBox">
                    <div className="tourBoxImg">
                      <Link to="/">
                        <img
                          src={special1}
                          alt="Image 1"
                          className="specialSliderImg"
                        />
                      </Link>
                    </div>
                    <div className="bannerTxt">
                      <div className="bookingStatus bookCompld">
                        Tour Completed
                      </div>
                      <h2>
                        <Link to="/">Chadar And Lingshed Village Trek</Link>
                      </h2>
                      <h4>
                        By: <span>Ayan Dutta Tour & Traveller</span>
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>16 Jan - 19 Jan</h5>
                    </div>
                  </div>

                  <div className="specialBox">
                    <div className="tourBoxImg">
                      <Link to="/">
                        <img
                          src={special1}
                          alt="Image 1"
                          className="specialSliderImg"
                        />
                      </Link>
                    </div>
                    <div className="bannerTxt">
                      <div className="bookingStatus bookCompld">
                        Tour Completed
                      </div>
                      <h2>
                        <Link to="/">Chadar And Lingshed Village Trek</Link>
                      </h2>
                      <h4>
                        By: <span>Ayan Dutta Tour & Traveller</span>
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>16 Jan - 19 Jan</h5>
                    </div>
                  </div>

                  <div className="specialBox">
                    <div className="tourBoxImg">
                      <Link to="/">
                        <img
                          src={special1}
                          alt="Image 1"
                          className="specialSliderImg"
                        />
                      </Link>
                    </div>
                    <div className="bannerTxt">
                      <div className="bookingStatus bookCompld">
                        Tour Completed
                      </div>
                      <h2>
                        <Link to="/">Chadar And Lingshed Village Trek</Link>
                      </h2>
                      <h4>
                        By: <span>Ayan Dutta Tour & Traveller</span>
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>16 Jan - 19 Jan</h5>
                    </div>
                  </div>

                  <div className="specialBox">
                    <div className="tourBoxImg">
                      <Link to="/">
                        <img
                          src={special1}
                          alt="Image 1"
                          className="specialSliderImg"
                        />
                      </Link>
                    </div>
                    <div className="bannerTxt">
                      <div className="bookingStatus bookCompld">
                        Tour Completed
                      </div>
                      <h2>
                        <Link to="/">Chadar And Lingshed Village Trek</Link>
                      </h2>
                      <h4>
                        By: <span>Ayan Dutta Tour & Traveller</span>
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>16 Jan - 19 Jan</h5>
                    </div>
                  </div> */}

                  {searchResult?.length > 0
                    ? searchResult?.map((item, index) => (
                        <div className="specialBox" key={index}>
                          <div className="tourBoxImg">
                            <Link to={`/booking/detail/${item?.bookings_id}`}>
                              <img src={IMAGE_URL + item?.first_gallery_image} alt="Image 1" className="specialSliderImg" />
                            </Link>
                          </div>
                          <div className="bannerTxt">
                            <div className="bookingStatus bookCompld">Tour Completed</div>
                            <h2>
                              <Link to={`/booking/detail/${item?.bookings_id}`}>{item?.name}</Link>
                            </h2>
                            <h4>{/* By: <span>{item?.vendor_fullname}</span> */}</h4>
                            <h3>{item?.total_days}</h3>
                            {/* <h3>3 Nights & 4 Days</h3> */}
                            <ul>
                              {item?.is_flight ? (
                                <li>
                                  <img src={optionalIcon2} alt="Image 1" />
                                  Flight
                                </li>
                              ) : null}
                              {item?.is_train === 1 ? (
                                <li>
                                  <img src={trainIcon} alt="Image 1" />
                                  Train
                                </li>
                              ) : null}
                              {item?.is_hotel ? (
                                <li>
                                  <img src={hotelIcon2} alt="Image 1" />
                                  Hotel
                                </li>
                              ) : null}
                              {item?.is_meal ? (
                                <li>
                                  <img src={mealIcon2} alt="Image 1" />
                                  Meals
                                </li>
                              ) : null}
                              {item?.is_transport ? (
                                <li>
                                  <img src={transferIcon2} alt="Image 1" />
                                  Transfer
                                </li>
                              ) : null}
                              {item?.is_sightseeing === 1 ? (
                                <li>
                                  <img src={binocularIcon} alt="Image 1" />
                                  Sightseeing
                                </li>
                              ) : null}
                            </ul>
                            <h5>
                              {formarDateFunc(item?.first_booking_date)} - {formarDateFunc(item?.last_booking_date)}
                            </h5>
                          </div>
                        </div>
                      ))
                    : !loading && (
                        <p className="noPackageFound">
                          {searchQuery || searchPressed ? `No tours found.` : "No tours have been completed yet."}
                          {/* {searchQuery || searchPressed ? `No tours found with ${searchQuery} this name.` : "No tours have been booked yet."} */}
                        </p>
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CompletedTour;
