import React, {Component, useState, useRef, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";
import {
  addMessageApi,
  addPackageApi,
  changePackageStatusApi,
  getCityListApi,
  getMessageListApi,
  getStatesListApi,
  getThemesListApi,
  getTripListApi,
  getTypeOfTourPackagesListApi,
  messageViewApi,
  vendorPackageListApi,
} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useNavigate, useParams} from "react-router";
import {Helmet} from "react-helmet";
import {Form, Button, Dropdown} from "react-bootstrap";
import DataTable from "react-data-table-component";
import dotsImg from "../assets/img/3dots.svg";
import {Link} from "react-router-dom";
import LoadingPage from "../component/loadingPage";

const arrow = require("./../assets/img/left-arrow.svg").default;

const VendorPackageMessage = () => {
  const {packageId} = useParams();
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const [packageList, setPackageList] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sendBtnDis, setSendBtnDis] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [dynamicTableKey, setDynamicTableKey] = useState(1);
  const [messageText, setMessageText] = useState();
  const [messageList, setMessageList] = useState([]);
  const [screenLoading, setScreenLoading] = useState(false);

  const getMessageList = async status => {
    setScreenLoading(true);
    try {
      const formData = new FormData();
      formData.append("package_id", packageId);
      let response = await getMessageListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setMessageList(response.data.data);
        } else {
        }
        setScreenLoading(false);
      }
    } catch (error) {
      setScreenLoading(false);
    }
  };

  const messageReadMark = async () => {
    try {
      const formData = new FormData();
      formData.append("package_id", packageId);

      let response = await messageViewApi(formData);
      if (response) {
        if (response.data.res === true) {
        } else {
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSend = async () => {
    if (!messageText) {
      NotificationManager.warning("Please enter message.");
    } else {
      setSendBtnDis(true);
      try {
        const formData = new FormData();
        formData.append("receiver_id", 34);
        formData.append("package_id", packageId);
        formData.append("message", messageText);
        let response = await addMessageApi(formData);
        if (response) {
          if (response.data.res === true) {
            // NotificationManager.success(response.data.msg);
            let tempArray = [...messageList];
            tempArray.push({
              id: response.data.data.id,
              receiver_id: response.data.data.receiver_id,
              sender_id: response.data.data.sender_id,
              message: response.data.data.message,
              created_at: response.data.data.created_at,
            });
            setMessageList(tempArray);
            setMessageText("");
          } else {
            NotificationManager.error(response.data.msg);
          }
          setSendBtnDis(false);
        }
      } catch (error) {
        setSendBtnDis(false);
        NotificationManager.error("Something went wrong, please try again.");
      }
    }
  };

  useEffect(() => {
    // Scroll to the bottom when the component mounts
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messageList]);

  useEffect(() => {
    const handleScroll = () => {
      if (messagesEndRef.current.scrollTop === 0) {
        // Call your API here
        console.log("Reached the top. Calling API...");
        // Example API call (you should replace this with your actual API call)
        // getMessageList();
      }
    };

    const ulElement = messagesEndRef.current;
    ulElement.addEventListener("scroll", handleScroll);

    // Cleanup the event listener
    return () => {
      ulElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    getMessageList("initial");
    messageReadMark();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Package List`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Package list page" />
      </Helmet>
      <VenderHeader />
      <div className="venderInnerDiv">
        <VenderSidebar />

        <div className="venderRight">
          {screenLoading && <LoadingPage />}
          <div className="hdrSec">
            <h2>
              <Button variant="link" onClick={() => navigate(`/business/package/edit/${packageId}`)}>
                <img src={arrow} alt="" />
              </Button>
              Messages
            </h2>
          </div>
          <div className="venderMain venderMain2">
            <div className="messageDiv">
              <ul ref={messagesEndRef}>
                {messageList?.map((item, index) => (
                  <li key={index}>
                    <div className={`messageInner ${parseInt(item?.receiver_id) == 34 ? "sender" : ""}`}>
                      <p className="senderName">{parseInt(item?.receiver_id) == 34 ? "You" : "Admin"}</p>
                      <p>{item?.message}</p>
                      <p>
                        <strong>
                          {new Date(item?.created_at).toLocaleString("en-US", {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          })}
                        </strong>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>

              <div className="mesTextarea">
                <h3>Enter Your Message</h3>
                <textarea placeholder="Enter Your Message Here..." value={messageText} onChange={e => setMessageText(e.target.value)}></textarea>
                {sendBtnDis || !messageText ? (
                  <button className="saveBtn saveBtn2" disabled>
                    Send
                  </button>
                ) : (
                  <button className="saveBtn saveBtn2" onClick={handleSend}>
                    Send
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorPackageMessage;
