import React, {Component, useState, useRef, useEffect} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {getPackageDetailsApi, getTaxDetailsApi, loginApi} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useLocation, useNavigate, useParams} from "react-router";
import {Helmet} from "react-helmet";
import {Accordion} from "react-bootstrap";
import panImg from "../assets/img/pan-image.png";
import EmptyImg from "../assets/img/empty-img.svg";
import CancelChequeImg from "../assets/img/cancel-cheque-img.png";
import AuthoriedLetterImg from "../assets/img/Authoried-letter-img.png";
import deleteImg from "../assets/img/delete-img.svg";
import MinusButton from "../assets/img/minus-button.svg";
import PlusButton from "../assets/img/plus-button.svg";
import FlightFromImg from "../assets/img/flightfromimg.svg";
import FacebookLinkIcon from "../assets/img/facebook-link-icon.svg";
import GoogleLinkIcon from "../assets/img/google-link-icon.svg";
import StarRatings from "react-star-ratings";
import Slider from "react-slick";
import {IMAGE_URL} from "../utils/commonUrls";
import {useUserContext} from "../context/UserContext";
import {Page, Text, View, Document, StyleSheet, Font, PDFDownloadLink} from "@react-pdf/renderer";
import {TimeConverter} from "../utils/commonFunctions";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 24,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginBottom: 20,
  },
  subTitle: {
    fontSize: 18,
    margin: 10,
  },
  content: {
    fontSize: 14,
    margin: 10,
    textAlign: "justify",
  },
});

// Create Document Component
const PDFDocument = ({tour}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>Tour Booking Confirmation</Text>
      <Text style={styles.subTitle}>Booking Details:</Text>
      <View style={styles.section}>
        <Text style={styles.content}>Name: {tour.customerName}</Text>
        <Text style={styles.content}>Tour Name: {tour.tourName}</Text>
        <Text style={styles.content}>Date: {tour.date}</Text>
        <Text style={styles.content}>Number of Guests: {tour.guestCount}</Text>
        <Text style={styles.content}>Total Price: ${tour.totalPrice}</Text>
      </View>
      <Text style={styles.subTitle}>Additional Information:</Text>
      <View style={styles.section}>
        <Text style={styles.content}>
          This is a provisional booking confirmation. Please ensure that payment is completed by the specified deadline to confirm your booking. Further details
          regarding the tour and itinerary will be shared upon booking confirmation.
        </Text>
      </View>
    </Page>
  </Document>
);

const ReviewFlights = () => {
  const navigate = useNavigate();
  const {userData} = useUserContext();
  const {packageId} = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const sampleTour = {
    customerName: "John Doe",
    tourName: "Historic Castles Tour",
    date: "2024-05-20",
    guestCount: 4,
    totalPrice: 400,
  };

  const hotelSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
  };

  const [key, setKey] = useState("tab1");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [bookingObjFromSession, setBookingObjFromSession] = useState();
  const [allDetails, setAllDetails] = useState();
  const [stayPlanWithDates, setStayPlanWithDates] = useState([]);
  const [packageDetails, setPackageDetails] = useState();
  const [priceDetails, setPriceDetails] = useState({
    basePrice: 0,
    addOnPrice: 0,
    taxPrice: 0,
    gstTax: 0,
    tcsTax: 0,
    gstTaxIncludePrice: 0,
    tcsTaxIncludePrice: 0,
    bulkDiscountPrice: 0,
    bulkDiscountPercent: 0,
    subTotalPrice: 0,
  });
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [haveAccount, setHaveAccount] = useState(true);
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(false);
  const [bulkDiscAvail, setBulkDiscAvail] = useState(false);
  const [taxDetails, setTaxDetails] = useState();
  const [onRequestToken, setOnRequestToken] = useState(searchParams.get("token"));

  const passengerCountFunc = () => {
    if (bookingObjFromSession?.rooms?.length > 0) {
      let totalAdults = 0;
      let totalChildren = 0;
      bookingObjFromSession?.rooms?.forEach(item => {
        totalAdults += item.adults;
        totalChildren += item.children;
      });
      let result = `${totalAdults} ${totalAdults > 1 ? "Adults" : "Adult"}`;

      if (totalChildren > 0) {
        result += ` - ${totalChildren} ${totalChildren > 1 ? "Childrens" : "Child"}`;
      }

      return result;
    }
  };

  const getPackageDetails = async () => {
    try {
      let response = await getPackageDetailsApi(packageId);
      if (response && response.data.res === true) {
        setPackageDetails(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching package details:", error);
    }
  };

  const getTaxDetails = async () => {
    let WMTBookingObj = JSON.parse(sessionStorage.getItem("WMTBookingObj"));
    const formatDateFunc = date => {
      const options = {weekday: "short", day: "2-digit", month: "short"};
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
      return formattedDate;
    };

    if (WMTBookingObj?.selectedTourStartDate && WMTBookingObj?.stay_plan?.length > 0) {
      const dates = [];
      let currentDate = new Date(WMTBookingObj?.selectedTourStartDate);
      let totalTourDays = 0; // Initialize total tour days

      const stayPlanFormatDateFunc = dateString => {
        const date = new Date(dateString);

        // Get day of the week
        const optionsDayOfWeek = {weekday: "short"};
        const dayOfWeek = new Intl.DateTimeFormat("en-US", optionsDayOfWeek).format(date).toLowerCase();

        // Get day of the month
        const day = date.getDate();

        // Get month
        const optionsMonth = {month: "short"};
        const month = new Intl.DateTimeFormat("en-US", optionsMonth).format(date).toLowerCase();
        return `${dayOfWeek}-${day}-${month}`;
      };

      WMTBookingObj?.stay_plan.forEach(city => {
        const cityStartDate = new Date(currentDate);
        const cityEndDate = new Date(currentDate);

        // Add the number of days for the city's stay plan
        cityEndDate.setDate(cityEndDate.getDate() + city.total_nights); // Subtract 1 to include start date in the count
        stayPlanFormatDateFunc(cityStartDate.toISOString());
        // Push the start and end dates for the city
        dates.push({
          city_name: city.city_name,
          hotel_name: city.hotel_name,
          total_days: city.total_nights,
          start_date: cityStartDate.toISOString(),
          start_date_formated: stayPlanFormatDateFunc(cityStartDate.toISOString()),
          end_date: cityEndDate.toISOString(),
          end_date_formated: stayPlanFormatDateFunc(cityEndDate.toISOString()),
        });

        // Move to the next city's start date
        currentDate.setDate(cityEndDate.getDate()); // Move to the next day after the end date

        // Update total tour days
        totalTourDays += city.total_nights;
      });

      // Calculate tour end date based on total tour days
      const tourEndDate = new Date(WMTBookingObj?.selectedTourStartDate);
      tourEndDate.setDate(tourEndDate.getDate() + totalTourDays - 1); // Subtract 1 to include start date in the count

      setStayPlanWithDates(dates);

      const tourStartDate = new Date(WMTBookingObj?.selectedTourStartDate);
    }

    const tourEndDateForAllDetails = new Date(WMTBookingObj?.selectedTourStartDate);
    tourEndDateForAllDetails.setDate(tourEndDateForAllDetails.getDate() + WMTBookingObj?.total_days_count - 1);
    setAllDetails(prev => ({
      ...prev,
      tourStartDate: formatDateFunc(new Date(WMTBookingObj?.selectedTourStartDate)),
      tourEndDate: formatDateFunc(tourEndDateForAllDetails),
    }));

    let basePrice = 0;
    // let basePrice = WMTBookingObj?.tourPrice ? WMTBookingObj?.tourPrice : 0;

    let totalAdult = 0;
    let totalChildren = 0;

    if (WMTBookingObj?.rooms?.length > 0) {
      WMTBookingObj?.rooms?.map(roomItem => {
        if (roomItem?.adults) {
          totalAdult += roomItem.adults;
        }
        if (roomItem?.children) {
          totalChildren += roomItem.children;
        }
      });
    }

    // if (totalAdult > 0) {
    //   basePrice = basePrice * parseInt(totalAdult);
    // }

    // if (totalChildren > 0) {
    //   let newChildDisc = parseFloat(WMTBookingObj?.tourPrice) - parseFloat(WMTBookingObj?.child_discount);
    //   basePrice = basePrice + parseInt(totalChildren) * newChildDisc;
    // }

    // WMTBookingObj.rooms.forEach(roomItem => {
    //   if (roomItem.adults === 1) {
    //     basePrice += parseFloat(WMTBookingObj.single_occupancy_cost);
    //   } else if (roomItem.adults === 3) {
    //     if (WMTBookingObj.triple_sharing_discount) {
    //       basePrice -= parseFloat(WMTBookingObj.triple_sharing_discount);
    //     }
    //   }
    // });

    const calculateTotalPrice = WMTBookingObj => {
      let totalPrice = 0;

      const singleOccupancyCost = parseFloat(WMTBookingObj?.single_occupancy_cost);
      const tripleSharingDiscount = parseFloat(WMTBookingObj?.triple_sharing_discount);
      const childDiscount = parseFloat(WMTBookingObj?.child_discount);
      const infantPrice = parseFloat(WMTBookingObj?.infant_price);
      const baseTourPrice = parseFloat(WMTBookingObj?.tourPrice);

      WMTBookingObj?.rooms.forEach(room => {
        if (room.adults === 1) {
          // Single occupancy
          totalPrice += singleOccupancyCost;
        } else if (room.adults === 3) {
          // Triple sharing
          totalPrice += tripleSharingDiscount * room.adults;
        } else if (room.adults === 2) {
          // Standard double occupancy
          totalPrice += baseTourPrice * room.adults;
        } else {
          // Handle other cases, if any
          totalPrice += baseTourPrice * room.adults;
        }

        // Add cost for children
        room.childrenArray.forEach(child => {
          if (child.age < 6) {
            totalPrice += infantPrice;
          } else {
            totalPrice += childDiscount;
          }
        });
      });

      return totalPrice;
    };

    basePrice = calculateTotalPrice(WMTBookingObj);

    let addOnPrice = 0;
    let taxes = 0;
    let gstTax = 0;
    let tcsTax = 0;
    let taxIncludePrice = 0;
    let gstTaxIncludePrice = 0;
    let tcsTaxIncludePrice = 0;
    let bulkDiscountPrice = 0;
    let bulkDiscountPercent = 0;
    let subTotal = 0;

    try {
      const formData = new FormData();
      formData.append("package_id", packageId);
      let response = await getTaxDetailsApi(formData);
      if (response && response.data.res === true) {
        setTaxDetails(response.data.data);
        gstTax = response.data.data.gst.value ? parseInt(response.data.data.gst.value) : 0;
        tcsTax = response.data.data.tcs.value ? parseInt(response.data.data.tcs.value) : 0;
      }
    } catch (error) {
      console.error("Error fetching package details:", error);
    }

    if (WMTBookingObj?.selectedAddons?.length > 0) {
      WMTBookingObj?.selectedAddons?.map(item => {
        addOnPrice += parseFloat(item?.price);
      });
    }

    // Calculate addon for per person
    addOnPrice = addOnPrice * (totalAdult + totalChildren);

    // Sort bulk discounts array in descending order based on min_pax
    WMTBookingObj.bulk_discounts.sort((a, b) => b.min_pax - a.min_pax);

    const matchedBulkDiscount = WMTBookingObj?.bulk_discounts.find(discount => parseInt(totalAdult) + parseInt(totalChildren) >= parseInt(discount.min_pax));

    if (matchedBulkDiscount) {
      setBulkDiscAvail(true);
      bulkDiscountPercent = parseInt(matchedBulkDiscount?.discount);
      bulkDiscountPrice = (basePrice * parseInt(matchedBulkDiscount?.discount)) / 100;
      basePrice = basePrice - bulkDiscountPrice;
    }

    // if (parseInt(totalAdult) + parseInt(totalChildren) >= parseInt(WMTBookingObj?.bulk_no_of_pax)) {
    //   setBulkDiscAvail(true);
    //   bulkDiscountPrice = (basePrice * parseInt(WMTBookingObj?.pax_discount_percent)) / 100;
    //   basePrice = basePrice - bulkDiscountPrice;
    // }

    // GST and tcs calculation
    taxes = gstTax + tcsTax;
    let basepriceWithAddon = basePrice + parseFloat(addOnPrice);

    if (taxes > 0) {
      taxIncludePrice = basepriceWithAddon + (basepriceWithAddon * taxes) / 100;
    }
    if (gstTax > 0) {
      gstTaxIncludePrice = (basepriceWithAddon * gstTax) / 100;
    }
    if (tcsTax > 0) {
      tcsTaxIncludePrice = (basepriceWithAddon * tcsTax) / 100;
    }

    subTotal = parseFloat(taxes ? taxIncludePrice : basepriceWithAddon);
    // subTotal = Math.round(taxIncludePrice) + Math.round(addOnPrice);

    setPriceDetails(prev => ({
      ...prev,
      basePrice: basePrice,
      addOnPrice: addOnPrice,
      taxPrice: taxes,
      gstTax: gstTax,
      tcsTax: tcsTax,
      gstTaxIncludePrice: parseFloat(gstTaxIncludePrice).toFixed(2),
      tcsTaxIncludePrice: parseFloat(tcsTaxIncludePrice).toFixed(2),
      bulkDiscountPrice: parseFloat(bulkDiscountPrice).toFixed(2),
      bulkDiscountPercent: bulkDiscountPercent,
      subTotalPrice: subTotal.toFixed(2),
    }));
  };

  const handleBook = async () => {
    if (!email && !mobile) {
      NotificationManager.warning("Please provide your email or your mobile number.");
    } else if (email && mobile) {
      NotificationManager.warning("Please provide either your email or your mobile number.");
    } else if (email && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(email)) {
      NotificationManager.warning("Please enter a valid email.");
    } else if (mobile && !/^\d{10}$/.test(mobile)) {
      NotificationManager.warning("Please enter a valid mobile number.");
    } else {
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("login", email ? email : mobile);
        // Api call
        let response = await loginApi(formData);
        if (response) {
          if (response.data.res === true) {
            navigate("/otp-verification", {
              state: {loginValue: email ? email : mobile, responseValue: response.data.data, from: `/package/${packageId}/travellers`},
            });
          } else {
            NotificationManager.warning(response.data.msg);
            setValidationError(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (packageId) {
      if (onRequestToken) {
        if (!userData) {
          const {pathname, search} = location;
          navigate("/login", {
            state: {from: `${pathname}${search}`},
          });
        } else {
          // alert();
        }
      }
      getPackageDetails();
      getTaxDetails();
      let WMTBookingObj = JSON.parse(sessionStorage.getItem("WMTBookingObj"));
      setBookingObjFromSession(WMTBookingObj);
      if (!WMTBookingObj?.selectedTourStartDate || parseInt(packageId) !== parseInt(WMTBookingObj.packageId)) {
        navigate("/");
      } else {
      }

      // if (WMTBookingObj?.selectedTourStartDate && WMTBookingObj?.stay_plan?.length > 0) {
      //   const dates = [];
      //   let currentDate = new Date(WMTBookingObj?.selectedTourStartDate);

      //   const stayPlanFormatDateFunc = dateString => {
      //     const date = new Date(dateString);

      //     // Get day of the week
      //     const optionsDayOfWeek = {weekday: "short"};
      //     const dayOfWeek = new Intl.DateTimeFormat("en-US", optionsDayOfWeek).format(date).toLowerCase();

      //     // Get day of the month
      //     const day = date.getDate();

      //     // Get month
      //     const optionsMonth = {month: "short"};
      //     const month = new Intl.DateTimeFormat("en-US", optionsMonth).format(date).toLowerCase();
      //     return `${dayOfWeek}-${day}-${month}`;
      //   };

      //   WMTBookingObj?.stay_plan.forEach(city => {
      //     const cityStartDate = new Date(currentDate);
      //     const cityEndDate = new Date(currentDate);

      //     // Add the number of days for the city's stay plan
      //     cityEndDate.setDate(cityEndDate.getDate() + city.total_days);
      //     stayPlanFormatDateFunc(cityStartDate.toISOString());
      //     // Push the start and end dates for the city
      //     dates.push({
      //       city_name: city.city_name,
      //       hotel_name: city.hotel_name,
      //       total_days: city.total_days,
      //       start_date: cityStartDate.toISOString(),
      //       start_date_formated: stayPlanFormatDateFunc(cityStartDate.toISOString()),
      //       end_date: cityEndDate.toISOString(),
      //       end_date_formated: stayPlanFormatDateFunc(cityEndDate.toISOString()),
      //     });

      //     // Move to the next city's start date
      //     currentDate.setDate(cityEndDate.getDate());
      //   });
      //   let tourEndDate = null;
      //   if (dates.length > 0) {
      //     tourEndDate = dates[dates.length - 1].end_date;
      //   }

      //   setStayPlanWithDates(dates);
      //   const formatDateFunc = date => {
      //     const options = {weekday: "short", day: "2-digit", month: "short"};
      //     const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
      //     return formattedDate;
      //   };
      //   const tourStartDate = new Date(WMTBookingObj?.selectedTourStartDate);
      //   setAllDetails(prev => ({
      //     ...prev,
      //     tourStartDate: formatDateFunc(new Date(WMTBookingObj?.selectedTourStartDate)),
      //     tourEndDate: formatDateFunc(new Date(tourEndDate)),
      //   }));
      // }
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Booking - Review`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Booking Review page" />
      </Helmet>
      <Header />
      <div className="innerDiv">
        <div className="UserAfterLogin">
          <div className="container">
            <h3>{bookingObjFromSession?.package_name}</h3>
            {/* <PDFDownloadLink document={<PDFDocument tour={sampleTour} />} fileName="tour-booking-confirmation.pdf">
              {({blob, url, loading, error}) => (loading ? "Preparing document..." : "Download Booking Confirmation")}
            </PDFDownloadLink> */}
            <ul className="row CornfirmList">
              {/* <li>
                <span>By: <strong>{bookingObjFromSession?.vendor_name}</strong></span>
              </li> */}
              <li>
                {allDetails?.tourStartDate}-{allDetails?.tourEndDate}
              </li>
              <li>{passengerCountFunc()}</li>
            </ul>
            <ul className="row ProgressList">
              <li className="col-md-4 active">
                <div className="CountSec">
                  <span className="NumSpan">01</span>
                  <h4>Review</h4>
                </div>
              </li>
              <li className="col-md-4">
                <div className="CountSec">
                  <span className="NumSpan">02</span>
                  <h4>Travellers</h4>
                </div>
              </li>
              <li className="col-md-4">
                <div className="CountSec">
                  <span className="NumSpan">03</span>
                  <h4>Payment</h4>
                </div>
              </li>
            </ul>
            <div className="row">
              <div className="col-md-8 LeftReviewFlights">
                {bookingObjFromSession?.flightList?.length > 0 ? (
                  <div className="DetailsWhiteBox">
                    <div className="DetailsTopHeader">
                      <h3>Flight Details</h3>
                    </div>
                    {bookingObjFromSession?.flightList?.length > 0
                      ? bookingObjFromSession?.flightList?.map((item, index) => (
                          <>
                            <div className="FlightListSec">
                              <ul className="row FlightList">
                                <li>
                                  <span className="FlightSpan">Flight</span>
                                </li>
                                {/* <li>
                                  <h4>Air India</h4>
                                  <h5>AI 445</h5>
                                </li> */}
                                <li>
                                  <h4>{item?.depart_destination.id}</h4>
                                </li>
                                <li className="text-center">
                                  <img src={FlightFromImg} alt="" className="FlightFromImg" />
                                  {/* <span className="FlightClass">Economy</span> */}
                                </li>
                                <li className="flightWidth">
                                  <h4>{item?.arrive_destination.id}</h4>
                                </li>
                              </ul>
                            </div>
                          </>
                        ))
                      : null}
                  </div>
                ) : null}

                <div className="DetailsWhiteBox">
                  <div className="DetailsTopHeader">
                    <h3>Hotel Details</h3>
                  </div>
                  <div className="FlightListSec">
                    <ul className="row TopDtlsList">
                      <li>
                        <h4>Hotel Details:</h4>
                      </li>
                      <li>{bookingObjFromSession?.rooms?.length} Room(s)</li>
                      <li>{passengerCountFunc()}</li>
                    </ul>
                    <ul className="HotelsList">
                      {stayPlanWithDates?.map((stayItem, stayIndex) => (
                        <li key={stayIndex}>
                          <span className="HotelCount">Hotel {stayIndex + 1}</span>
                          <div className="row HotelListInfo">
                            <div className="col-md-5 HotelListInfoLeft">
                              <ul className="row HotelDateList">
                                <li>
                                  <div className="CalenSec">
                                    <h4>Check-in</h4>
                                    <div className="CalenBox">
                                      <h4 style={{textTransform: "capitalize"}}>{stayItem?.start_date_formated?.split("-")[0]}</h4>
                                      <div className="CalenBody">
                                        <h4>{stayItem?.start_date_formated?.split("-")[1]}</h4>
                                        <p style={{textTransform: "capitalize"}}>{stayItem?.start_date_formated?.split("-")[2]}</p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  {stayItem?.total_days} {stayItem?.total_days > 1 ? "Nights" : "Night"}
                                </li>
                                <li>
                                  <div className="CalenSec">
                                    <h4>Check-out</h4>
                                    <div className="CalenBox">
                                      <h4 style={{textTransform: "capitalize"}}>{stayItem?.end_date_formated?.split("-")[0]}</h4>
                                      <div className="CalenBody">
                                        <h4>{stayItem?.end_date_formated?.split("-")[1]}</h4>
                                        <p style={{textTransform: "capitalize"}}>{stayItem?.end_date_formated?.split("-")[2]}</p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-7 HotelListInfoRight">
                              <h4>{stayItem?.city_name}</h4>
                              <h3>{stayItem?.hotel_name} or Similar</h3>
                              {bookingObjFromSession?.rooms?.map((roomItem, roomIndex) => (
                                <h5>
                                  Room {roomIndex + 1} : {roomItem?.adults} Adult(s) {roomItem?.children > 0 ? "-" + roomItem?.children + " Children(s)" : null}
                                </h5>
                              ))}
                              {/* Room 1: <span>2 Adult(s)</span> */}
                              {/* {roomDetails} */}
                              {/* <h6>
                                Room Type: <span>Twin Sharing with Camp</span>
                              </h6> */}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="ConditionAccordion">
                  <Accordion defaultActiveKey={["0", "1", "2", "3", "4"]}>
                    {/* <Accordion defaultActiveKey={"0"}> */}
                    {bookingObjFromSession?.selectedAddons?.length > 0 ? (
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Add On</Accordion.Header>
                        <Accordion.Body>
                          <ul className="row AddOnList">
                            {bookingObjFromSession?.selectedAddons?.map((item, index) => (
                              <li className="col-md-6" key={index}>
                                <div className="BorderBox">
                                  <h3>{item?.title}</h3>
                                  <p>{item?.description}</p>
                                  <h4>₹{item?.price}</h4>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : null}

                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Itinerary</Accordion.Header>
                      <Accordion.Body>
                        <ul className="ItineraryList">
                          {packageDetails?.itinerary?.map((item, index) => (
                            <li key={index}>
                              <div className="row">
                                <div className="col-md-2">
                                  <div className="BorderBox">
                                    <h3>Day</h3>
                                    <h4>{index + 1}</h4>
                                  </div>
                                </div>
                                <div className="col-md-10 ItineraryRightContent">
                                  <h3>{item?.itinerary_title}</h3>
                                  {item?.meal ? (
                                    <h4>
                                      Meals: <span>{item?.meal}</span>{" "}
                                    </h4>
                                  ) : null}
                                  <p>{item.itinerary_description}</p>

                                  {item?.flights?.length > 0 ? (
                                    <>
                                      <h5>Flight</h5>
                                      <div className="FlightListSec">
                                        <ul className="row FlightList">
                                          {/* <li>
                                            <h4>Air India</h4>
                                            <h5>AI 445</h5>
                                          </li> */}
                                          <li className="flightWidth">
                                            <h4>{item?.flights[0]?.depart_destination.id}</h4>
                                            <h4>{TimeConverter(item?.flights[0]?.depart_datetime)}</h4>
                                          </li>
                                          <li className="text-center">
                                            <img src={FlightFromImg} alt="" className="FlightFromImg" />
                                            <span className="FlightClass">{item?.flights[0]?.class}</span>
                                          </li>
                                          <li className="flightWidth">
                                            <h4>{item?.flights[0]?.arrive_destination.id}</h4>
                                            <h4>{TimeConverter(item?.flights[0]?.arrive_datetime)}</h4>
                                          </li>
                                        </ul>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {item?.sightseeing?.length > 0 ? (
                                    <>
                                      <h5>Sightseeing</h5>
                                      <ul className="row GreenArrowList">
                                        {item?.sightseeing?.map((sight, index) => (
                                          <React.Fragment key={index}>
                                            {sight.morning && <li>{sight?.morning}</li>}
                                            {sight.afternoon && <li>{sight?.afternoon}</li>}
                                            {sight.evening && <li>{sight?.evening}</li>}
                                            {sight.night && <li>{sight?.night}</li>}
                                          </React.Fragment>
                                        ))}
                                      </ul>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {item?.meal !== null ? (
                                    <>
                                      <h5>Meal</h5>
                                      <ul className="row GreenArrowList">
                                        {item?.meal?.split(",")?.map((mealItem, mealIndex) => (
                                          <li key={mealIndex} style={{textTransform: "capitalize"}}>
                                            {mealItem}
                                          </li>
                                        ))}
                                      </ul>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {item?.hotels?.length > 0 ? (
                                    <div className="HotelDesSec">
                                      <h3>Hotel</h3>
                                      {/* <p>Check In For 1 Nights</p> */}
                                      <ul className="row HotelDesSecList">
                                        <li className="col-md-4">
                                          <div className="hotelSlider">
                                            <Slider {...hotelSliderSettings}>
                                              {item?.hotels[0]?.hotel_gallery?.map((hotelItem, hotelIndex) => (
                                                <div key={hotelIndex}>
                                                  <img
                                                    src={IMAGE_URL + hotelItem?.path}
                                                    alt=""
                                                    // onClick={() => openHotelPopup(item?.hotels[0]?.hotel_gallery, hotelIndex)}
                                                  />
                                                </div>
                                              ))}
                                            </Slider>
                                          </div>
                                        </li>
                                        <li className="col-md-8 TextRightSec">
                                          <StarRatings
                                            rating={item?.hotels[0]?.star} // Initial rating value
                                            starRatedColor="#F8B84E" // Color of the filled stars
                                            numberOfStars={5} // Total number of stars
                                            name="rating" // Unique name for the component
                                            starDimension="18px"
                                            starSpacing="2px"
                                          />
                                          <h3>{item?.hotels[0]?.hotel_name} or Similar</h3>
                                          {item?.hotels[0]?.is_other_place === 1 ? (
                                            <div className="nameDesc">
                                              <p>
                                                {item?.hotels[0]?.place_name} | {item?.hotels[0]?.distance_from_main_town}
                                              </p>
                                            </div>
                                          ) : null}
                                        </li>
                                      </ul>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {/* <div className="HotelDesSec">
                                    <h3>Hotel</h3>
                                    <p>Check In For 1 Nights</p>
                                    <ul className="row HotelDesSecList">
                                      <li className="col-md-4"></li>
                                      <li className="col-md-8 TextRightSec">
                                        <h3>Tiaraa Hotels and Resorts Manali</h3>
                                      </li>
                                    </ul>
                                  </div> */}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    {packageDetails?.inclusions_list?.length > 0 ? (
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Inclusions</Accordion.Header>
                        <Accordion.Body>
                          <ul className="GreenArrowList2">
                            {packageDetails?.inclusions_list?.map((incliListItem, incliListIndex) => (
                              <li key={incliListIndex}>{incliListItem?.name}</li>
                            ))}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : null}

                    {packageDetails?.exclusions_list?.length > 0 ? (
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>Exclusions</Accordion.Header>
                        <Accordion.Body>
                          <ul className="GreenArrowList2">
                            {packageDetails?.exclusions_list?.map((excluListItem, excluListIndex) => (
                              <li key={excluListIndex}>{excluListItem?.name}</li>
                            ))}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : null}

                    {!userData ? (
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>Sign In Now to Book Online</Accordion.Header>
                        <Accordion.Body>
                          <ul className="BookingSignIngList">
                            <li>
                              <input type="email" name="" className="form-control" placeholder="Email Address" onChange={e => setEmail(e.target.value)} />
                              <span className="noteSpan">Your booking details will be sent to this email address.</span>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input form-input-check"
                                  type="checkbox"
                                  value={haveAccount}
                                  checked={haveAccount === true ? true : false}
                                  onChange={e => setHaveAccount(e.target.checked)}
                                />
                                <label>I have a Wishmytour Account.</label>
                              </div>
                            </li>
                            <li>
                              <input type="tel" name="" className="form-control" placeholder="Phone Number" onChange={e => setMobile(e.target.value)} />
                              <span className="noteSpan">We'll use this number to send possible update alerts.</span>
                            </li>
                            <li>
                              {loading ? (
                                <input type="button" name="" value="Please wait..." className="btn btn-primary CmnBtn" disabled />
                              ) : (
                                <input
                                  type="button"
                                  name=""
                                  value={haveAccount ? "Continue with OTP" : `Book as Guest`}
                                  className="btn btn-primary CmnBtn"
                                  onClick={() => handleBook()}
                                />
                              )}
                            </li>
                            <li className="OrList">
                              <span className="BorderLeft"></span> Or Login/Signup With <span className="BorderRight"></span>
                            </li>
                            <li className="align-center">
                              <a className="SocialLink">
                                <img src={FacebookLinkIcon} alt="" />
                              </a>
                              <a className="SocialLink">
                                <img src={GoogleLinkIcon} alt="" />
                              </a>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : null}
                  </Accordion>
                </div>
              </div>
              <div className="col-md-4 RightReviewFlights">
                <div className="card WhiteCard">
                  <table className="table">
                    <tr>
                      <th>Base Price</th>
                      <td>
                        ₹
                        {(parseFloat(priceDetails?.basePrice) + parseFloat(priceDetails?.bulkDiscountPrice)).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                    {bulkDiscAvail ? (
                      <tr className="GreenFont">
                        <th>Bulk Discount({priceDetails?.bulkDiscountPercent}%)</th>
                        {/* <th>Bulk Discount({bookingObjFromSession?.pax_discount_percent}%)</th> */}
                        <td className="GreenFont">₹{priceDetails?.bulkDiscountPrice.toLocaleString()}</td>
                      </tr>
                    ) : null}
                    {priceDetails?.addOnPrice ? (
                      <tr>
                        <th>Add On</th>
                        <td>₹{(priceDetails?.addOnPrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                      </tr>
                    ) : null}
                    {priceDetails.gstTax > 0 ? (
                      <tr>
                        <th>GST({priceDetails?.gstTax}%)</th>
                        <td>₹{Number(priceDetails?.gstTaxIncludePrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                      </tr>
                    ) : null}
                    {priceDetails.tcsTax > 0 ? (
                      <tr>
                        <th>TCS({priceDetails?.tcsTax}%)</th>
                        <td>₹{Number(priceDetails?.tcsTaxIncludePrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <th>You Pay</th>
                      <td>
                        <span className="GrandPrice">
                          ₹
                          {priceDetails?.subTotalPrice
                            ? Number(priceDetails.subTotalPrice).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "0.00"}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
                {priceDetails.tcsTax > 0 ? (
                  <>
                    <h4>How to get</h4>
                    <h5>100% Credit of TCS Amount</h5>
                    <div className="OrangeCard">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                    <div className="LightGreenCard">It is a long established fact that a reader will be distracted by the readable</div>
                    <div className="BlueCard">There are many variations of passages of Lorem Ipsum available, but the</div>
                  </>
                ) : null}
              </div>
            </div>
            {userData ? (
              <input
                type="button"
                name=""
                value="Continue"
                className="btn btn-primary CmnBtn CmnBtn2"
                onClick={() => navigate(`/package/${packageId}/travellers`)}
              />
            ) : null}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReviewFlights;
