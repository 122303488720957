import React, {useState, useEffect, useRef} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {
  addBookingApi,
  agentDetailsViewApi,
  allCouponsApi,
  changePhoneNumberApi,
  couponsCheckApi,
  getCustomerDetailsApi,
  getStatesListApi,
  getTaxDetailsApi,
  updateOtpApi,
  verifyEmailOrMobileApi,
  verifyPhoneNumberApi,
} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useNavigate, useParams} from "react-router";
import {Helmet} from "react-helmet";
import {Accordion} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {useUserContext} from "../context/UserContext";
import {Link} from "react-router-dom";
import CountUp from "react-countup";

// Wrap the component where CountUp is used with React.memo
const CountUpComponent = React.memo(({start, end}) => (
  <CountUp
    start={Number(start)}
    end={Number(end)}
    decimals={2}
    duration={1}
    separator=","
    decimal="."
    prefix=""
    suffix=""
    useEasing={true}
    formattingFn={value =>
      value.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    }
  />
));

const crossIcon2 = require("./../assets/img/cross.svg").default;

const Travellers = () => {
  const navigate = useNavigate();
  const {userData, setUserData} = useUserContext();
  const {packageId} = useParams();

  const [bookingObjFromSession, setBookingObjFromSession] = useState();
  const [priceDetails, setPriceDetails] = useState({
    basePrice: 0,
    addOnPrice: 0,
    taxPrice: 0,
    gstTax: 0,
    tcsTax: 0,
    gstTaxIncludePrice: 0,
    tcsTaxIncludePrice: 0,
    bulkDiscountPrice: 0,
    bulkDiscountPercent: 0,
    subTotalPrice: 0,
    payOption: null,
    payAmount: 0,
  });
  const [allDetails, setAllDetails] = useState();
  const [travellersArray, setTravellersArray] = useState([]);
  const [customerType, setCustomerType] = useState(1);
  const [customerDetails, setCustomerDetails] = useState({name: "", address: "", email: "", phoneNumber: "", state: "", panNumber: "", agentCode: ""});
  const [travellersTitleArray] = useState(["Mr", "Mrs", "Miss", "Ms"]);
  const [travellersGenderArray] = useState(["Male", "Female", "Other"]);
  const [stateList, setStateList] = useState([]);
  const [btnDis, setBtnDis] = useState(false);
  const [showChangeNumberPopup, setShowChangeNumberPopup] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [existingNumber, setExistingNumber] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [confirmNewNumber, setConfirmNewNumber] = useState("");
  const [existingNumberError, setExistingNumberError] = useState("");
  const [newNumberError, setNewNumberError] = useState("");
  const [confirmNewNumberError, setConfirmNewNumberError] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [timer, setTimer] = useState(60);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [successMsg, setSuccessMsg] = useState("");
  const [changeNumberResponse, setChangeNumberResponse] = useState();
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [disclaimerCheckbox, setDisclaimerCheckbox] = useState(false);
  const [termAgree, setTermAgree] = useState(false);
  const [leadConsent, setLeadConsent] = useState("agree");
  const [tourDateRange, setTourDateRange] = useState({startDate: "", endDate: ""});
  const [bulkDiscAvail, setBulkDiscAvail] = useState(false);
  const [custDetails, setCustDetails] = useState();
  const [taxDetails, setTaxDetails] = useState();
  const [couponDetails, setCouponDetails] = useState();
  const [predefinedSelctedCoupon, setPredefinedSelctedCoupon] = useState();
  const [otherSelectedCoupon, setOtherSelectedCoupon] = useState();
  const [couponCodeText, setCouponCodeText] = useState();
  const [couponCodeError, setCouponCodeError] = useState();
  const [couponCodeSuccess, setCouponCodeSuccess] = useState();
  const [priceChanged, setPriceChanged] = useState(0);
  const [agentDetails, setAgentDetails] = useState(0);
  const [payAnotherOptionShow, setPayAnotherOptionShow] = useState(false);
  const [paymentDateForShow, setPaymentDateForShow] = useState();
  const [secondVerified, setSecondVerified] = useState(false);
  const [verifyBtnDis, setVerifyBtnDis] = useState(false);
  const [clickedType, setClickedType] = useState();
  const [recountTimer, setRecountTimer] = useState(false);
  const [onSendRequestSuccessPopup, setOnSendRequestSuccessPopup] = useState(false);
  const [showOtpPopup1, setShowOtpPopup1] = useState(false);
  const [timer1, setTimer1] = useState(60);
  const [otp1, setOtp1] = useState(["", "", "", ""]);
  const inputRefs1 = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [changeNumberResponse1, setChangeNumberResponse1] = useState();
  const [successMsg1, setSuccessMsg1] = useState("");

  const getStateList = async () => {
    try {
      let response = await getStatesListApi();
      if (response) {
        if (response.data.res === true) {
          setStateList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const allCouponsList = async () => {
    try {
      let response = await allCouponsApi();
      if (response) {
        if (response.data.res === true) {
          setCouponDetails(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustDetails(response.data);
          if (response.data.data.id_type === "pancard") {
            setCustomerDetails({
              name: response.data.data.first_name || response.data.data.last_name ? response.data.data.first_name + " " + response.data.data.last_name : "",
              address: response.data.data.address ? response.data.data.address : "",
              email: response.data.User.email ? response.data.User.email : "",
              phoneNumber: response.data.User.mobile ? response.data.User.mobile : "",
              state: response.data.data.state ? response.data.data.state : "",
              panNumber: response.data.data.id_number ? response.data.data.id_number : "",
            });
          } else {
            setCustomerDetails({
              name: response.data.data.first_name || response.data.data.last_name ? response.data.data.first_name + " " + response.data.data.last_name : "",
              address: response.data.data.address ? response.data.data.address : "",
              email: response.data.User.email ? response.data.User.email : "",
              phoneNumber: response.data.User.mobile ? response.data.User.mobile : "",
              state: response.data.data.state ? response.data.data.state : "",
              panNumber: "",
            });
          }
        } else {
        }
      }
    } catch (error) {}
  };

  const getAgentDetails = async () => {
    try {
      let response = await agentDetailsViewApi();
      if (response) {
        if (response.data.res === true) {
          setAgentDetails(response.data);
          console.log(userData);
          if (userData?.data?.user_type === 4) {
            setCustomerDetails(prev => ({
              ...prev,
              agentCode: response.data?.data?.agent_code,
            }));
          }
        } else {
        }
      }
    } catch (error) {}
  };

  const getTaxDetails = async () => {
    let WMTBookingObj = JSON.parse(sessionStorage.getItem("WMTBookingObj"));
    let startDate;
    let endDate;

    const formatDateFunc = date => {
      const options = {weekday: "short", day: "2-digit", month: "short"};
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
      return formattedDate;
    };

    if (WMTBookingObj?.selectedTourStartDate && WMTBookingObj?.stay_plan?.length > 0) {
      const dates = [];
      let currentDate = new Date(WMTBookingObj?.selectedTourStartDate);
      let totalTourDays = 0; // Initialize total tour days

      const stayPlanFormatDateFunc = dateString => {
        const date = new Date(dateString);

        // Get day of the week
        const optionsDayOfWeek = {weekday: "short"};
        const dayOfWeek = new Intl.DateTimeFormat("en-US", optionsDayOfWeek).format(date).toLowerCase();

        // Get day of the month
        const day = date.getDate();

        // Get month
        const optionsMonth = {month: "short"};
        const month = new Intl.DateTimeFormat("en-US", optionsMonth).format(date).toLowerCase();
        return `${dayOfWeek}-${day}-${month}`;
      };

      WMTBookingObj?.stay_plan.forEach(city => {
        const cityStartDate = new Date(currentDate);
        const cityEndDate = new Date(currentDate);

        // Add the number of days for the city's stay plan
        cityEndDate.setDate(cityEndDate.getDate() + city.total_nights - 1); // Subtract 1 to include start date in the count
        stayPlanFormatDateFunc(cityStartDate.toISOString());
        // Push the start and end dates for the city
        dates.push({
          city_name: city.city_name,
          hotel_name: city.hotel_name,
          total_days: city.total_nights,
          start_date: cityStartDate.toISOString(),
          start_date_formated: stayPlanFormatDateFunc(cityStartDate.toISOString()),
          end_date: cityEndDate.toISOString(),
          end_date_formated: stayPlanFormatDateFunc(cityEndDate.toISOString()),
        });

        // Move to the next city's start date
        currentDate.setDate(cityEndDate.getDate() + 1); // Move to the next day after the end date

        // Update total tour days
        totalTourDays += city.total_nights;
      });

      // Calculate tour end date based on total tour days
      const tourEndDate = new Date(WMTBookingObj?.selectedTourStartDate);
      tourEndDate.setDate(tourEndDate.getDate() + totalTourDays - 1); // Subtract 1 to include start date in the count

      const tourStartDate = new Date(WMTBookingObj?.selectedTourStartDate);
      // setAllDetails(prev => ({
      //   ...prev,
      //   tourStartDate: formatDateFunc(new Date(WMTBookingObj?.selectedTourStartDate)),
      //   tourEndDate: formatDateFunc(tourEndDate),
      // }));

      startDate = new Date(WMTBookingObj?.selectedTourStartDate);
      endDate = tourEndDate;
      setTourDateRange({startDate: startDate, endDate: endDate});
    }

    const tourEndDateForAllDetails = new Date(WMTBookingObj?.selectedTourStartDate);
    tourEndDateForAllDetails.setDate(tourEndDateForAllDetails.getDate() + WMTBookingObj?.total_days_count - 1);
    setAllDetails(prev => ({
      ...prev,
      tourStartDate: formatDateFunc(new Date(WMTBookingObj?.selectedTourStartDate)),
      tourEndDate: formatDateFunc(tourEndDateForAllDetails),
    }));

    let basePrice = 0;
    // let basePrice = WMTBookingObj?.tourPrice ? WMTBookingObj?.tourPrice : 0;

    let totalAdult = 0;
    let totalChildren = 0;

    if (WMTBookingObj?.rooms?.length > 0) {
      WMTBookingObj?.rooms?.map(roomItem => {
        if (roomItem?.adults) {
          totalAdult += roomItem.adults;
        }
        if (roomItem?.children) {
          totalChildren += roomItem.children;
        }
      });
    }

    // if (totalAdult > 0) {
    //   basePrice = parseFloat(basePrice) * parseInt(totalAdult);
    // }

    // if (totalChildren > 0) {
    //   let newChildDisc = parseFloat(WMTBookingObj?.tourPrice) - parseFloat(WMTBookingObj?.child_discount);
    //   basePrice = parseFloat(basePrice) + parseInt(totalChildren) * newChildDisc;
    // }

    // WMTBookingObj.rooms.forEach(roomItem => {
    //   if (roomItem.adults === 1) {
    //     basePrice += parseFloat(WMTBookingObj.single_occupancy_cost);
    //   } else if (roomItem.adults === 3) {
    //     if (WMTBookingObj.triple_sharing_discount) {
    //       basePrice -= parseFloat(WMTBookingObj.triple_sharing_discount);
    //     }
    //   }
    // });

    const calculateTotalPrice = WMTBookingObj => {
      let totalPrice = 0;

      const singleOccupancyCost = parseFloat(WMTBookingObj?.single_occupancy_cost);
      const tripleSharingDiscount = parseFloat(WMTBookingObj?.triple_sharing_discount);
      const childDiscount = parseFloat(WMTBookingObj?.child_discount);
      const infantPrice = parseFloat(WMTBookingObj?.infant_price);
      const baseTourPrice = parseFloat(WMTBookingObj?.tourPrice);

      WMTBookingObj?.rooms?.forEach(room => {
        if (room.adults === 1) {
          // Single occupancy
          totalPrice += singleOccupancyCost;
        } else if (room.adults === 3) {
          // Triple sharing
          totalPrice += tripleSharingDiscount * room.adults;
        } else if (room.adults === 2) {
          // Standard double occupancy
          totalPrice += baseTourPrice * room.adults;
        } else {
          // Handle other cases, if any
          totalPrice += baseTourPrice * room.adults;
        }

        // Add cost for children
        room.childrenArray.forEach(child => {
          if (child.age < 6) {
            totalPrice += infantPrice;
          } else {
            totalPrice += childDiscount;
          }
        });
      });

      return totalPrice;
    };

    basePrice = calculateTotalPrice(WMTBookingObj);

    let addOnPrice = 0;
    let taxes = 0;
    let gstTax = 0;
    let tcsTax = 0;
    let taxIncludePrice = 0;
    let gstTaxIncludePrice = 0;
    let tcsTaxIncludePrice = 0;
    let bulkDiscountPrice = 0;
    let bulkDiscountPercent = 0;
    let subTotal = 0;

    try {
      const formData = new FormData();
      formData.append("package_id", packageId);
      let response = await getTaxDetailsApi(formData);
      if (response && response.data.res === true) {
        setTaxDetails(response.data.data);
        gstTax = response.data.data.gst.value ? parseInt(response.data.data.gst.value) : 0;
        tcsTax = response.data.data.tcs.value ? parseInt(response.data.data.tcs.value) : 0;
      }
    } catch (error) {
      console.error("Error fetching package details:", error);
    }

    if (WMTBookingObj?.selectedAddons?.length > 0) {
      WMTBookingObj?.selectedAddons?.map(item => {
        addOnPrice += parseFloat(item?.price);
      });
    }

    // Calculate addon for per person
    addOnPrice = addOnPrice * (totalAdult + totalChildren);

    // Sort bulk discounts array in descending order based on min_pax
    WMTBookingObj?.bulk_discounts.sort((a, b) => b.min_pax - a.min_pax);

    const matchedBulkDiscount = WMTBookingObj?.bulk_discounts.find(discount => parseInt(totalAdult) + parseInt(totalChildren) >= parseInt(discount.min_pax));

    if (matchedBulkDiscount) {
      setBulkDiscAvail(true);
      bulkDiscountPercent = parseInt(matchedBulkDiscount?.discount);
      bulkDiscountPrice = (basePrice * parseInt(matchedBulkDiscount?.discount)) / 100;
      basePrice = basePrice - bulkDiscountPrice;
    }

    // if (parseInt(totalAdult) >= parseInt(WMTBookingObj?.bulk_no_of_pax)) {
    //   setBulkDiscAvail(true);
    //   bulkDiscountPrice = (basePrice * parseInt(WMTBookingObj?.pax_discount_percent)) / 100;
    //   basePrice = basePrice - parseFloat(bulkDiscountPrice);
    // }

    // GST and tcs calculation
    taxes = gstTax + tcsTax;
    let basepriceWithAddon = basePrice + parseFloat(addOnPrice);

    if (taxes > 0) {
      taxIncludePrice = basepriceWithAddon + (basepriceWithAddon * taxes) / 100;
    }
    if (gstTax > 0) {
      gstTaxIncludePrice = (basepriceWithAddon * gstTax) / 100;
    }
    if (tcsTax > 0) {
      tcsTaxIncludePrice = (basepriceWithAddon * tcsTax) / 100;
    }

    subTotal = parseFloat(taxes ? taxIncludePrice : basepriceWithAddon);
    // subTotal = Math.round(taxIncludePrice) + Math.round(addOnPrice);

    // Calculate day difference between booking date and today
    const today = new Date();
    const bookingDate = new Date(WMTBookingObj?.selectedTourStartDate);
    const timeDiff = bookingDate.getTime() - today.getTime();
    const dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));

    // Calculate days remaining for payment policy
    // Parse paymeny policy
    const parsedPolicy = WMTBookingObj?.payment_policy?.map(policy => {
      const parts = policy.split("-");
      return {
        startDay: parseInt(parts[0], 10),
        endDay: parseInt(parts[1], 10),
        percentage: parseInt(parts[2], 10),
      };
    });

    // Find applicable paymeny policy
    const applicablePolicy = parsedPolicy?.find(policy => {
      if (policy.startDay <= policy.endDay) {
        return dayDifference <= policy.startDay && dayDifference >= policy.endDay;
      } else {
        if (policy.endDay === 0) {
          return dayDifference >= policy.startDay;
        } else {
          return (dayDifference <= policy.startDay && dayDifference >= policy.endDay) || (dayDifference >= policy.startDay && dayDifference < policy.endDay);
        }
      }
    });

    // Get the last item from payment policy array
    let lastPaymentPolicy = WMTBookingObj?.payment_policy[WMTBookingObj?.payment_policy.length - 1];
    let paymentPolicyLastDate = parseInt(lastPaymentPolicy?.split("-")[1]);
    let maxPaymentDate = bookingDate?.setDate(bookingDate.getDate() - paymentPolicyLastDate);

    // Format the date as "5 Jul 2024"
    if (maxPaymentDate) {
      const formattedPaymentDate = formatPaymentDateFunc(maxPaymentDate);
      // Function to format the date as "5 Jul 2024"
      function formatPaymentDateFunc(date) {
        const options = {day: "numeric", month: "short", year: "numeric"};
        return new Intl.DateTimeFormat("en-US", options).format(date);
      }
      setPaymentDateForShow(formattedPaymentDate);
    }

    let payAmount = 0;

    if (applicablePolicy && applicablePolicy?.percentage !== 100) {
      payAmount = (subTotal * applicablePolicy?.percentage) / 100;
      setPayAnotherOptionShow(true);
    } else {
      payAmount = subTotal;
      setPayAnotherOptionShow(false);
    }

    setPriceDetails(prev => ({
      ...prev,
      basePrice: basePrice,
      addOnPrice: addOnPrice,
      taxPrice: taxes,
      gstTax: gstTax,
      tcsTax: tcsTax,
      gstTaxIncludePrice: parseFloat(gstTaxIncludePrice).toFixed(2),
      tcsTaxIncludePrice: parseFloat(tcsTaxIncludePrice).toFixed(2),
      bulkDiscountPrice: parseFloat(bulkDiscountPrice).toFixed(2),
      bulkDiscountPercent: bulkDiscountPercent,
      subTotalPrice: subTotal.toFixed(2),
      payAmount: payAmount.toFixed(2),
    }));

    let tempTravelArray = [];

    if (WMTBookingObj?.rooms?.length > 0) {
      WMTBookingObj.rooms.forEach((room, index) => {
        let adults = [];
        let children = [];

        // For Adults
        for (let i = 0; i < room.adults; i++) {
          adults.push({
            title: "",
            firstName: "",
            lastName: "",
            dob: "",
            gender: "",
          });
        }

        // For Children
        for (let i = 0; i < room.childrenArray?.length; i++) {
          children.push({
            title: "",
            firstName: "",
            lastName: "",
            dob: "",
            gender: "",
            age: room.childrenArray[i].age,
          });
        }

        tempTravelArray.push({
          roomName: "Room" + (index + 1),
          adults: adults,
          childrens: children,
        });
      });
    }
    setTravellersArray(tempTravelArray);
    // console.log(tempTravelArray);
    // subTotalPrice

    // Set cancellation percent from applicable policy
    // cancellationPercent = applicablePolicy ? applicablePolicy.percentage : 0;
    // if (cancellationPercent) {
    //   cancellationCharges = (parseFloat(perPersonPrice) * partialCancelChecked.length * cancellationPercent) / 100;
    // }
  };

  const passengerCountFunc = () => {
    if (bookingObjFromSession?.rooms?.length > 0) {
      let totalAdults = 0;
      let totalChildren = 0;
      bookingObjFromSession?.rooms?.forEach(item => {
        totalAdults += item.adults;
        totalChildren += item.children;
      });
      let result = `${totalAdults} ${totalAdults > 1 ? "Adults" : "Adult"}`;

      if (totalChildren > 0) {
        result += ` - ${totalChildren} ${totalChildren > 1 ? "Childrens" : "Child"}`;
      }

      return result;
    }
  };

  const handleCustomerDetailChange = event => {
    const {name, value} = event.target;
    setCustomerDetails({...customerDetails, [name]: value});
  };

  const formatDate = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleAdultChange = (roomIndex, adultIndex, field, value) => {
    const updatedTravellersArray = [...travellersArray];

    // if (field === "dob" && value instanceof Date) {
    //   updatedTravellersArray[roomIndex].adults[adultIndex][field] = formatDate(value);
    // } else {
    updatedTravellersArray[roomIndex].adults[adultIndex][field] = value;
    // }
    setTravellersArray(updatedTravellersArray);
  };

  const handleChildChange = (roomIndex, childIndex, field, value) => {
    const updatedTravellersArray = [...travellersArray];
    updatedTravellersArray[roomIndex].childrens[childIndex][field] = value;
    setTravellersArray(updatedTravellersArray);
  };

  const closePhonePopup = () => {
    // Reset form and close popup
    setExistingNumber("");
    setNewNumber("");
    setConfirmNewNumber("");
    setExistingNumberError("");
    setNewNumberError("");
    setConfirmNewNumberError("");
    setShowChangeNumberPopup(false);
    setShowOtpPopup(false);
  };

  const handleInputChange = (index, e) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    // Check if all OTP digits are entered
    if (index === inputRefs.length - 1 && newOtp.every(digit => digit !== "")) {
      setValidationError("");
      handleSubmit(newOtp);
    }
  };

  const handleBackspaceChangeNumber = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);

      // Set focus on the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePasteChangeNumber = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4);

    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      for (let i = 0; i < 4; i++) {
        newOtp[i] = pastedData[i] || "";
      }
      return newOtp;
    });

    if (pastedData?.length < 4) {
      inputRefs[pastedData?.length].current.focus();
    } else {
      inputRefs[3].current.focus();
    }

    // Check if all four digits are present in the pasted data
    if (pastedData.length === 4 && pastedData.match(/^\d{4}$/)) {
      let newValue = pastedData.split("");
      handleSubmit(newValue);
    }
  };

  const startTimer = () => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    // Clear the interval after the countdown is complete
    setTimeout(() => {
      clearInterval(countdown);
    }, timer * 1000);
  };

  const handleChangeNumber = async () => {
    // Reset previous errors
    setValidationError("");
    setExistingNumberError("");
    setNewNumberError("");
    setConfirmNewNumberError("");

    // Perform validation
    if (!existingNumber || !/^\d{10}$/.test(existingNumber)) {
      setExistingNumberError("Please enter a valid 10-digit Mobile Number.");
    }
    if (!newNumber || !/^\d{10}$/.test(newNumber)) {
      setNewNumberError("Please enter a valid 10-digit Mobile Number.");
    }
    if (!confirmNewNumber || !/^\d{10}$/.test(confirmNewNumber)) {
      setConfirmNewNumberError("Please enter a valid 10-digit Mobile Number.");
    }

    if (newNumber !== confirmNewNumber) {
      setConfirmNewNumberError("New Number and Confirm Number must match.");
    }

    // If no errors, proceed with OTP generation logic
    if (!existingNumberError && !newNumberError && !confirmNewNumberError) {
      // Your logic to generate OTP goes here
      try {
        setLoading(true);
        let apiFormData = new FormData();
        apiFormData.append("current_phone_number", existingNumber);
        apiFormData.append("new_phone_number", newNumber);
        apiFormData.append("confirm_new_phone_number", confirmNewNumber);
        // Api call
        let response = await changePhoneNumberApi(apiFormData);
        if (response) {
          if (response.data.res === true) {
            // NotificationManager.success(response.data.msg);
            setChangeNumberResponse(response.data.data);
            setShowChangeNumberPopup(false);
            setShowOtpPopup(true);
            setValidationError("");
            startTimer();
          } else {
            setValidationError(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleKeyPress = event => {
    if (event.key === "Enter" && !loading) {
      handleChangeNumber();
    }
  };

  const handleSubmit = async otp => {
    if (otp.every(digit => digit === "")) {
      setValidationError("Please enter Your Otp.");
    } else {
      let newVal = otp.join("");
      setValidationError("");
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("temp_phone_number", changeNumberResponse?.temp_phone_number);
        formData.append("otp", newVal);

        let response = await verifyPhoneNumberApi(formData);
        if (response) {
          if (response.data.res === true) {
            setValidationError("");
            NotificationManager.success(response.data.msg);
            // getCustomerDetails();
            let tempUserData = JSON.parse(localStorage.getItem("WMTUserData"));
            tempUserData.data.mobile = response.data.data.mobile;
            localStorage.setItem("WMTUserData", JSON.stringify(tempUserData));
            setUserData(tempUserData);
            closePhonePopup();
            getCustomerDetails();
            setOtp(["", "", "", ""]);
          } else {
            setValidationError(response.data.msg);
            setSuccessMsg("");
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleSendOtp1 = async type => {
    setClickedType(type);
    setRecountTimer(!recountTimer);
    if (type === "mobile") {
      if (customerDetails.phoneNumber === "" || !/^\d{10}$/.test(customerDetails.phoneNumber)) {
        NotificationManager.warning("Please enter a valid phone number.");
        return false;
      } else {
        const isValid = /^\d{10}$/.test(customerDetails.phoneNumber);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid phone number.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let fd = new FormData();
            fd.append("user_id", custDetails?.User?.id);
            fd.append("mobile", customerDetails.phoneNumber);
            setTimer(60);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data);
                setShowOtpPopup(true);
                // setSecondVerified(true);
                inputRefs[0].current.focus();
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else if (type === "email") {
      if (customerDetails.email === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(customerDetails.email)) {
        NotificationManager.warning("Please enter a valid Email Address.");
        return false;
      } else {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(customerDetails.email);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid email.");
        } else {
          setValidationError("");
          try {
            let fd = new FormData();
            fd.append("user_id", custDetails?.User?.id);
            fd.append("email", customerDetails.email);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data);
                setShowOtpPopup(true);
                setTimer(60);
                // setSecondVerified(true);
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else {
    }
  };

  const dateFormatFunc = iniDate => {
    // Create a new Date object with the desired date
    const date = new Date(iniDate);

    // Get the year, month, and day from the date object
    const year = date.getFullYear(); // Get last two digits of the year
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month starts from 0
    const day = date.getDate().toString().padStart(2, "0");

    // Concatenate year, month, and day with hyphens
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const calculateAge = dateOfBirth => {
    const dob = new Date(dateOfBirth);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - dob.getFullYear();

    // Check if the birthday hasn't occurred yet in the current year
    if (currentDate.getMonth() < dob.getMonth() || (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  };

  const validateForm = () => {
    let isValid = true;
    // if(priceDetails?.payOption === 1) {
    //   console.log(priceDetails?.payAmount)
    // } else {
    //   console.log(priceDetails?.subTotalPrice)
    // }
    // console.log(priceDetails?.payAmount)
    // return false;

    // travellersArray.forEach((room, roomIndex) => {
    //   // Validate adults
    //   room.adults.forEach((adult, adultIndex) => {
    //     // Validate each field for adult
    //     if (!adult.title || !adult.firstName || !adult.lastName || !adult.dob || !adult.gender) {
    //       NotificationManager.warning(`Please enter all details for Adult ${adultIndex + 1} in Room ${roomIndex + 1}.`);
    //       isValid = false;
    //     }
    //     // Add more specific validations as needed
    //   });

    //   // Validate children
    //   room.childrens.forEach((child, childIndex) => {
    //     // Validate each field for child
    //     if (!child.title || !child.firstName || !child.lastName || !child.dob || !child.gender) {
    //       NotificationManager.warning(`Please enter all details for Child ${childIndex + 1} in Room ${roomIndex + 1}.`);
    //       isValid = false;
    //     }
    //     // Add more specific validations as needed
    //   });
    // });

    for (let roomIndex = 0; roomIndex < travellersArray.length; roomIndex++) {
      const room = travellersArray[roomIndex];

      // Validate adults
      for (let adultIndex = 0; adultIndex < room.adults.length; adultIndex++) {
        const adult = room.adults[adultIndex];

        // Validate each field for adult
        if (!adult.title || !adult.firstName || !adult.lastName || !adult.dob || !adult.gender) {
          NotificationManager.warning(`Please enter all details for Adult ${adultIndex + 1} in Room ${roomIndex + 1}.`);
          isValid = false;
          return isValid; // Stop validation and return immediately
        }
        // Add more specific validations as needed
      }

      // Validate children
      for (let childIndex = 0; childIndex < room.childrens.length; childIndex++) {
        const child = room.childrens[childIndex];

        // Validate each field for child
        if (!child.title || !child.firstName || !child.lastName || !child.dob || !child.gender) {
          NotificationManager.warning(`Please enter all details for Child ${childIndex + 1} in Room ${roomIndex + 1}.`);
          isValid = false;
          return isValid; // Stop validation and return immediately
        }
      }
    }

    // Validation check for fisrt person should be adult (18 years)
    if (calculateAge(new Date(travellersArray[0].adults[0].dob)) < 18) {
      NotificationManager.warning("First person should be adult(18 years and above).");
      isValid = false;
      return isValid;
    }

    if (
      !customerDetails.name ||
      !customerDetails.address ||
      !customerDetails.email ||
      !customerDetails.phoneNumber ||
      !customerDetails.state ||
      (parseFloat(priceDetails?.subTotalPrice) > 200000 && !customerDetails.panNumber)
    ) {
      NotificationManager.warning("Please enter all customer details.");
      isValid = false;
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(customerDetails.email)) {
      NotificationManager.warning("Please enter a valid customer Email Address.");
      isValid = false;
    } else if (!/^\d{10}$/.test(customerDetails.phoneNumber)) {
      NotificationManager.warning("Please enter a valid customer phone number.");
      isValid = false;
    } else if (customerDetails.panNumber && !/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(customerDetails.panNumber)) {
      NotificationManager.warning("Please enter a valid pan number.");
      isValid = false;
    } else if (payAnotherOptionShow && !priceDetails?.payOption) {
      NotificationManager.warning("Please select a payment option.");
      isValid = false;
    }

    return isValid;
  };

  const handleSaveTravellerDetails = async () => {
    if (validateForm()) {
      setBtnDis(true);
      try {
        const formData = new FormData();

        let addOnIds = "";
        if (bookingObjFromSession?.selectedAddons?.length > 0) {
          bookingObjFromSession?.selectedAddons?.map(item => {
            addOnIds = bookingObjFromSession?.selectedAddons?.map(pkg => pkg.id).join(",");
          });
        }
        if (addOnIds) {
          formData.append("add_on_id", addOnIds);
        }
        formData.append("booking_status", "2"); // 1 for in-process status / 2 for confirmed status
        formData.append("package_id", packageId);

        bookingObjFromSession?.rooms?.map((item, index) => {
          formData.append(`rooms[${index}][room_no]`, "Room " + (index + 1));
          formData.append(`rooms[${index}][adults]`, item?.adults);
          formData.append(`rooms[${index}][children]`, item?.children);
        });

        travellersArray?.forEach((roomItem, roomIndex) => {
          roomItem.adults?.forEach((adultItem, adultIndex) => {
            formData.append(`rooms[${roomIndex}][passengers][${adultIndex}][title]`, adultItem.title);
            formData.append(`rooms[${roomIndex}][passengers][${adultIndex}][first_name]`, adultItem.firstName);
            formData.append(`rooms[${roomIndex}][passengers][${adultIndex}][last_name]`, adultItem.lastName);
            formData.append(`rooms[${roomIndex}][passengers][${adultIndex}][dob]`, dateFormatFunc(adultItem.dob));
            formData.append(`rooms[${roomIndex}][passengers][${adultIndex}][gender]`, adultItem.gender.toLowerCase());
            formData.append(`rooms[${roomIndex}][passengers][${adultIndex}][is_adult]`, "1");
            formData.append(
              `rooms[${roomIndex}][passengers][${adultIndex}][price]`,
              roomItem.adults.length === 1
                ? bookingObjFromSession?.single_occupancy_cost
                : roomItem.adults.length === 3
                ? bookingObjFromSession?.triple_sharing_discount
                : bookingObjFromSession?.tourPrice
            );
          });

          roomItem.childrens?.forEach((childItem, childIndex) => {
            const childPassengerIndex = roomItem.adults?.length + childIndex;
            formData.append(`rooms[${roomIndex}][passengers][${childPassengerIndex}][title]`, childItem.title);
            formData.append(`rooms[${roomIndex}][passengers][${childPassengerIndex}][first_name]`, childItem.firstName);
            formData.append(`rooms[${roomIndex}][passengers][${childPassengerIndex}][last_name]`, childItem.lastName);
            formData.append(`rooms[${roomIndex}][passengers][${childPassengerIndex}][dob]`, dateFormatFunc(childItem.dob));
            formData.append(`rooms[${roomIndex}][passengers][${childPassengerIndex}][gender]`, childItem.gender.toLowerCase());
            formData.append(`rooms[${roomIndex}][passengers][${childPassengerIndex}][is_adult]`, "0");
            formData.append(
              `rooms[${roomIndex}][passengers][${childPassengerIndex}][price]`,
              childItem?.age < 6 ? bookingObjFromSession?.infant_price : bookingObjFromSession?.child_discount
            );
          });
        });

        const startDate = new Date(tourDateRange.startDate);
        const endDate = new Date(tourDateRange.endDate);

        // Function to format date into "yy-mm-dd" format
        const formatDate = date => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        };

        endDate.setDate(endDate.getDate() + 1);

        // Array to store formatted dates
        const formattedDates = [];

        // Push each date into the array in "yy-mm-dd" format
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
          formattedDates.push(formatDate(d));
        }

        formattedDates.map((item, index) => {
          formData.append(`seats[${index}][booking_date]`, item);
          formData.append(
            `seats[${index}][cost]`,
            priceDetails?.couponDiscountedSubTotal ? priceDetails?.couponDiscountedSubTotal : priceDetails?.subTotalPrice
          );
        });

        formData.append("customer_name", customerDetails?.name);
        formData.append("customer_address", customerDetails?.address);
        formData.append("customer_email", customerDetails?.email);
        formData.append("customer_phone_number", customerDetails?.phoneNumber);
        formData.append("customer_state_id", customerDetails?.state);
        formData.append("customer_pan_number", customerDetails?.panNumber ? customerDetails?.panNumber : "");
        formData.append("customer_booking_for", userData?.data?.user_type === 4 ? "2" : customerType);
        formData.append("agent_code", customerDetails?.agentCode ? customerDetails?.agentCode : "");
        if (predefinedSelctedCoupon && otherSelectedCoupon?.id) {
          formData.append("coupon_id", predefinedSelctedCoupon + "," + otherSelectedCoupon?.id);
        } else {
          formData.append("coupon_id", predefinedSelctedCoupon ? predefinedSelctedCoupon : "");
        }

        let tempBasePrice;
        // if (priceDetails?.couponDiscountedSubTotal) {
        //   tempBasePrice =
        //     parseFloat(priceDetails?.couponDiscountedSubTotal) - (parseFloat(priceDetails?.gstTaxIncludePrice) + parseFloat(priceDetails?.tcsTaxIncludePrice));
        // } else {
        tempBasePrice =
          parseFloat(priceDetails?.subTotalPrice) -
          (parseFloat(priceDetails?.gstTaxIncludePrice) + parseFloat(priceDetails?.tcsTaxIncludePrice)) -
          parseFloat(priceDetails?.addOnPrice);
        // }

        formData.append("base_amount", tempBasePrice.toFixed(2));

        if (priceDetails?.addOnPrice) {
          formData.append("addon_total_price", parseFloat(priceDetails?.addOnPrice).toFixed(2));
        }

        if (priceDetails?.gstTaxIncludePrice) {
          formData.append("gst_price", parseFloat(priceDetails?.gstTaxIncludePrice).toFixed(2));
        }

        if (taxDetails?.gst?.value) {
          formData.append("gst_percent", taxDetails?.gst?.value);
        }

        if (taxDetails?.platform_charges?.value) {
          formData.append("website_percent", taxDetails?.platform_charges?.value);
        }

        if (bookingObjFromSession?.website_charges) {
          formData.append("website_price", bookingObjFromSession?.website_charges);
        }

        if (priceDetails?.tcsTaxIncludePrice) {
          formData.append("tcs", parseFloat(priceDetails?.tcsTaxIncludePrice).toFixed(2));
        }

        formData.append(
          "coupon_price",
          (priceDetails?.otherCouponPrice ? parseFloat(priceDetails?.otherCouponPrice) : 0) +
            (priceDetails?.predefinedCouponPrice ? parseFloat(priceDetails?.predefinedCouponPrice) : 0)
        );

        formData.append("final_price", priceDetails?.couponDiscountedSubTotal ? priceDetails?.couponDiscountedSubTotal : priceDetails?.subTotalPrice);
        if (priceDetails.payOption === 1) {
          formData.append("paid_amount", priceDetails.payAmount);
        } else {
          formData.append("paid_amount", priceDetails?.couponDiscountedSubTotal ? priceDetails?.couponDiscountedSubTotal : priceDetails?.subTotalPrice);
        }

        // for (let pair of formData.entries()) {
        //   console.log(pair[0] + ": " + pair[1]);
        // }
        // return;
        let response = await addBookingApi(formData);
        if (response) {
          if (response.data.res === true) {
            NotificationManager.success(response.data.msg);
            navigate("/package/booked", {state: {pdfUrl: response.data.data}});
            sessionStorage.removeItem("WMTBookingObj");
          } else {
            NotificationManager.error(response.data.msg);
          }
          setBtnDis(false);
        }
      } catch (error) {
        setBtnDis(false);
      }
    }
  };

  const handleApplyCoupon = async () => {
    if (!couponCodeText) {
      setCouponCodeError("Please enter coupon code.");
    } else {
      try {
        const formData = new FormData();
        formData.append("code", couponCodeText);
        let response = await couponsCheckApi(formData);
        if (response) {
          if (response.data.exists === true) {
            setOtherSelectedCoupon(response.data.coupon);
            setCouponCodeSuccess("Coupon Applied.");
            setCouponCodeError("");
            setPriceChanged(prev => prev + 1);
          } else {
            setCouponCodeError(response.data.msg);
            setCouponCodeSuccess("");
          }
        }
      } catch (error) {}
    }
  };

  const handleBackspace1 = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp1[index] === "") {
      const newOtp = [...otp1];
      newOtp[index - 1] = "";
      setOtp1(newOtp);

      // Set focus on the previous input
      inputRefs1[index - 1].current.focus();
    }
  };

  const handlePaste1 = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4);

    setOtp1(prevOtp => {
      const newOtp = [...prevOtp];
      for (let i = 0; i < 4; i++) {
        newOtp[i] = pastedData[i] || "";
      }
      return newOtp;
    });

    if (pastedData?.length < 4) {
      inputRefs1[pastedData?.length].current.focus();
    } else {
      inputRefs1[3].current.focus();
    }

    // Check if all four digits are present in the pasted data
    if (pastedData.length === 4 && pastedData.match(/^\d{4}$/)) {
      let newValue = pastedData.split("");
      handleOtpSubmit(newValue);
    }
  };

  const handleOtpInputChange1 = (index, e) => {
    const newOtp = [...otp1];
    newOtp[index] = e.target.value;
    setOtp1(newOtp);

    if (e.target.value && index < inputRefs1.length - 1) {
      inputRefs1[index + 1].current.focus();
    }

    // Check if all OTP digits are entered
    if (index === inputRefs1.length - 1 && newOtp.every(digit => digit !== "")) {
      setValidationError("");
      handleOtpSubmit(newOtp);
    }
  };

  const handleSendOtp = async type => {
    setClickedType(type);
    setRecountTimer(!recountTimer);
    if (type === "mobile") {
      if (customerDetails.phoneNumber === "" || !/^\d{10}$/.test(customerDetails.phoneNumber)) {
        NotificationManager.warning("Please enter a valid phone number.");
        return false;
      } else {
        const isValid = /^\d{10}$/.test(customerDetails.phoneNumber);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid phone number.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let fd = new FormData();
            fd.append("user_id", custDetails?.User?.id);
            fd.append("mobile", customerDetails.phoneNumber);
            setTimer1(60);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse1(response.data.otp);
                setShowOtpPopup1(true);
                // setSecondVerified(true);
                inputRefs1[0].current.focus();
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else if (type === "email") {
      if (customerDetails.email === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(customerDetails.email)) {
        NotificationManager.warning("Please enter a valid Email Address.");
        return false;
      } else {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(customerDetails.email);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid email.");
        } else {
          setValidationError("");
          try {
            let fd = new FormData();
            fd.append("user_id", custDetails?.User?.id);
            fd.append("email", customerDetails.email);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse1(response.data.otp);
                setShowOtpPopup1(true);
                setTimer1(60);
                // setSecondVerified(true);
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else {
    }
  };

  const handleResendOTP = async () => {
    setRecountTimer(!recountTimer);
    let type = clickedType;
    if (type === "mobile") {
      if (customerDetails.phoneNumber === "" || !/^\d{10}$/.test(customerDetails.phoneNumber)) {
        NotificationManager.warning("Please enter a valid phone number.");
        return false;
      } else {
        const isValid = /^\d{10}$/.test(customerDetails.phoneNumber);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid phone number.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let fd = new FormData();
            fd.append("user_id", custDetails?.User?.id);
            fd.append("mobile", customerDetails.phoneNumber);
            setTimer1(60);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse1(response.data.otp);
                setShowOtpPopup1(true);
                // setSecondVerified(true);
                inputRefs1[0].current.focus();
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else if (type === "email") {
      if (customerDetails.email === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(customerDetails.email)) {
        NotificationManager.warning("Please enter a valid Email Address.");
        return false;
      } else {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(customerDetails.email);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid email.");
        } else {
          setValidationError("");
          try {
            let fd = new FormData();
            fd.append("user_id", custDetails?.User?.id);
            fd.append("email", customerDetails.email);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse1(response.data.otp);
                setShowOtpPopup1(true);
                setTimer1(60);
                // setSecondVerified(true);
              } else {
                NotificationManager.error(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else {
    }
  };

  const handleOtpSubmit = async otp => {
    if (otp1.every(digit => digit === "")) {
      setValidationError("Please enter Your Otp.");
    } else {
      let newVal = otp1.join("");
      setValidationError("");
      // setLoading(true);
      try {
        let fd = new FormData();
        fd.append("user_id", custDetails?.User?.id);
        if (custDetails?.User?.email) {
          fd.append("email", custDetails?.User?.email);
          fd.append("mobile", customerDetails?.phoneNumber);
        }
        if (custDetails?.User?.mobile) {
          fd.append("mobile", custDetails?.User?.mobile);
          fd.append("email", customerDetails?.email);
        }
        fd.append("otp", newVal);

        let response = await verifyEmailOrMobileApi(fd);
        if (response) {
          if (response.data.res === true) {
            setValidationError("");
            // setSuccessMsg(response.data.msg);
            NotificationManager.success(response.data.msg);
            setShowOtpPopup1(false);
            setSecondVerified(true);
          } else {
            setValidationError(response.data.msg);
            setSuccessMsg("");
          }
        }
      } catch (error) {}
    }
  };

  // Resend Counter useEffect
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer1(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [recountTimer]);

  useEffect(() => {
    if (showOtpPopup) {
      inputRefs[0].current.focus();
    }
  }, [showOtpPopup]);

  useEffect(() => {
    if (showOtpPopup1) {
      inputRefs1[0].current.focus();
    }
  }, [showOtpPopup1]);

  useEffect(() => {
    let tempObj = {...priceDetails};
    let predefinedCouponPrice = 0;
    let otherCouponPrice = 0;
    // Check for predefined selected coupon and calculate discount
    if (predefinedSelctedCoupon) {
      let selectedCoupon = couponDetails.find(item => parseInt(item.id) === parseInt(predefinedSelctedCoupon));
      if (selectedCoupon?.discount_type === "percentage") {
        predefinedCouponPrice = (tempObj.subTotalPrice * parseFloat(selectedCoupon.discount_amount)) / 100;
        tempObj.predefinedCouponPrice = predefinedCouponPrice;
      }
    } else {
      tempObj.predefinedCouponPrice = 0;
    }

    // Check for other selected coupon and calculate discount
    if (otherSelectedCoupon) {
      if (otherSelectedCoupon?.discount_type === "percentage") {
        otherCouponPrice = (tempObj.subTotalPrice * parseFloat(otherSelectedCoupon.discount_amount)) / 100;
        tempObj.otherCouponPrice = otherCouponPrice;
      }
    }

    tempObj.couponDiscountedSubTotal = tempObj.subTotalPrice - predefinedCouponPrice - otherCouponPrice;
    tempObj.couponDiscountPrice = predefinedCouponPrice + otherCouponPrice;

    // Update the state with the new price details

    let WMTBookingObj = JSON.parse(sessionStorage.getItem("WMTBookingObj"));
    // Calculate day difference between booking date and today
    const today = new Date();
    const bookingDate = new Date(WMTBookingObj?.selectedTourStartDate);
    const timeDiff = bookingDate.getTime() - today.getTime();
    const dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));

    // Calculate days remaining for payment policy
    // Parse paymeny policy
    const parsedPolicy = WMTBookingObj?.payment_policy?.map(policy => {
      const parts = policy.split("-");
      return {
        startDay: parseInt(parts[0], 10),
        endDay: parseInt(parts[1], 10),
        percentage: parseInt(parts[2], 10),
      };
    });

    // Find applicable paymeny policy
    const applicablePolicy = parsedPolicy?.find(policy => {
      if (policy.startDay <= policy.endDay) {
        return dayDifference <= policy.startDay && dayDifference >= policy.endDay;
      } else {
        if (policy.endDay === 0) {
          return dayDifference >= policy.startDay;
        } else {
          return (dayDifference <= policy.startDay && dayDifference >= policy.endDay) || (dayDifference >= policy.startDay && dayDifference < policy.endDay);
        }
      }
    });

    let payAmount = 0;
    // console.log(applicablePolicy);
    if (applicablePolicy && applicablePolicy?.percentage !== 100) {
      payAmount = (tempObj.couponDiscountedSubTotal * applicablePolicy?.percentage) / 100;
      setPayAnotherOptionShow(true);
    } else {
      payAmount = tempObj.couponDiscountedSubTotal;
      setPayAnotherOptionShow(false);
    }
    tempObj.payAmount = payAmount;

    setPriceDetails(tempObj);
  }, [predefinedSelctedCoupon, otherSelectedCoupon, couponDetails, priceDetails?.subTotalPrice]);

  useEffect(() => {
    if (packageId) {
      let WMTBookingObj = JSON.parse(sessionStorage.getItem("WMTBookingObj"));
      setBookingObjFromSession(WMTBookingObj);
      if (!WMTBookingObj?.selectedTourStartDate || parseInt(packageId) !== parseInt(WMTBookingObj.packageId)) {
        navigate("/");
      } else {
      }
      if (userData?.data?.user_type === 4) {
        getAgentDetails();
      }
      getStateList();
      allCouponsList();
      getCustomerDetails();
      getTaxDetails();
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Booking - Add Travellers`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Booking Add Travellers page" />
      </Helmet>
      <Header />
      <div className="innerDiv">
        <div className="UserAfterLogin">
          <div className="container">
            <h3>{bookingObjFromSession?.package_name}</h3>
            <ul className="row CornfirmList">
              {/* <li>
                <span>By: <strong>{bookingObjFromSession?.vendor_name}</strong></span>
              </li> */}
              <li>
                {allDetails?.tourStartDate}-{allDetails?.tourEndDate}
              </li>
              <li>{passengerCountFunc()}</li>
            </ul>

            {userData?.data?.user_type !== 4 ? (
              <div className="card WhiteCardTop">
                <ul className="row TopDetailsList">
                  <li>
                    Your booking details will be sent to <strong>{custDetails?.User?.email} </strong> and on your mobile
                  </li>
                  <li>
                    {" "}
                    <input
                      type="text"
                      name=""
                      className="form-control"
                      placeholder="Mobile Number"
                      value={custDetails?.User?.mobile ? custDetails?.User?.mobile : ""}
                      readOnly
                    />
                  </li>
                  <li>
                    <a className="EditLink" style={{cursor: "pointer"}} onClick={() => setShowChangeNumberPopup(true)}>
                      Edit
                    </a>
                  </li>
                </ul>
              </div>
            ) : null}

            <ul className="row ProgressList">
              <li className="col-md-4 complete">
                <div className="CountSec">
                  <span className="NumSpan">01</span>
                  <h4>Review</h4>
                </div>
              </li>
              <li className="col-md-4 active">
                <div className="CountSec">
                  <span className="NumSpan">02</span>
                  <h4>Travellers</h4>
                </div>
              </li>
              <li className="col-md-4">
                <div className="CountSec">
                  <span className="NumSpan">03</span>
                  <h4>Payment</h4>
                </div>
              </li>
            </ul>
            <div className="row">
              <div className="col-md-8 LeftReviewFlights">
                <div className="DetailsWhiteBox">
                  <div className="DetailsTopHeader">
                    <h3>Passenger Details</h3>
                  </div>

                  {travellersArray?.map((item, index) => (
                    <div className="PagengerDtailsBox" key={index}>
                      <h4>{item?.roomName}</h4>
                      <ul className="FormList">
                        {item?.adults?.length > 0
                          ? item?.adults?.map((chItem, chIndex) => (
                              <li key={chIndex}>
                                <label>Adult {chIndex + 1}</label>
                                <ul className="row FormList2">
                                  <li className="col-md-4">
                                    <select className="form-select" onChange={e => handleAdultChange(index, chIndex, "title", e.target.value)}>
                                      <option selected hidden>
                                        Title
                                      </option>
                                      {travellersTitleArray?.map((titleItem, titleIndex) => (
                                        <option key={titleIndex} value={titleItem}>
                                          {titleItem}
                                        </option>
                                      ))}
                                    </select>
                                  </li>
                                  <li className="col-md-4">
                                    <input
                                      type="text"
                                      name=""
                                      className="form-control"
                                      placeholder="First Name"
                                      onChange={e => handleAdultChange(index, chIndex, "firstName", e.target.value)}
                                    />
                                  </li>
                                  <li className="col-md-4">
                                    <input
                                      type="text"
                                      name=""
                                      className="form-control"
                                      placeholder="Last Name"
                                      onChange={e => handleAdultChange(index, chIndex, "lastName", e.target.value)}
                                    />
                                  </li>
                                </ul>
                                <span className="noteSpan">
                                  Please ensure that the names of all passengers match as per Valid Govt Id Proof valid for Travel.
                                </span>
                                <ul className="row FormList2">
                                  <li className="col-md-6">
                                    <label>DOB</label>
                                    <DatePicker
                                      className="form-control"
                                      showYearDropdown
                                      scrollableYearDropdown
                                      yearDropdownItemNumber={100}
                                      selected={chItem?.dob}
                                      onChange={date => handleAdultChange(index, chIndex, "dob", date)}
                                      dateFormat="MMMM d, yyyy"
                                      placeholderText="From"
                                      maxDate={new Date()}
                                    />
                                  </li>
                                  <li className="col-md-6">
                                    <label>Gender</label>
                                    <select className="form-select" onChange={e => handleAdultChange(index, chIndex, "gender", e.target.value)}>
                                      <option selected hidden>
                                        Gender
                                      </option>
                                      {travellersGenderArray?.map((genderItem, genderIndex) => (
                                        <option key={genderIndex} value={genderItem}>
                                          {genderItem}
                                        </option>
                                      ))}
                                    </select>
                                  </li>
                                </ul>
                              </li>
                            ))
                          : null}
                        {item?.childrens?.length > 0
                          ? item?.childrens?.map((chItem, chIndex) => (
                              <li key={chIndex}>
                                <label>Children {chIndex + 1}</label>
                                <ul className="row FormList2">
                                  <li className="col-md-4">
                                    <select className="form-select" onChange={e => handleChildChange(index, chIndex, "title", e.target.value)}>
                                      <option selected hidden>
                                        Title
                                      </option>
                                      {travellersTitleArray?.map((titleItem, titleIndex) => (
                                        <option key={titleIndex} value={titleItem}>
                                          {titleItem}
                                        </option>
                                      ))}
                                    </select>
                                  </li>
                                  <li className="col-md-4">
                                    <input
                                      type="text"
                                      name=""
                                      className="form-control"
                                      placeholder="First Name"
                                      onChange={e => handleChildChange(index, chIndex, "firstName", e.target.value)}
                                    />
                                  </li>
                                  <li className="col-md-4">
                                    <input
                                      type="text"
                                      name=""
                                      className="form-control"
                                      placeholder="Last Name"
                                      onChange={e => handleChildChange(index, chIndex, "lastName", e.target.value)}
                                    />
                                  </li>
                                </ul>
                                <span className="noteSpan">
                                  Please ensure that the names of all passengers match as per Valid Govt Id Proof valid for Travel.
                                </span>
                                <ul className="row FormList2">
                                  <li className="col-md-6">
                                    <label>DOB</label>
                                    <DatePicker
                                      className="form-control"
                                      showYearDropdown
                                      scrollableYearDropdown
                                      yearDropdownItemNumber={100}
                                      selected={chItem?.dob}
                                      onChange={date => handleChildChange(index, chIndex, "dob", date)}
                                      dateFormat="MMMM d, yyyy"
                                      placeholderText="From"
                                      maxDate={new Date()}
                                    />
                                  </li>
                                  <li className="col-md-6">
                                    <label>Gender</label>
                                    <select className="form-select" onChange={e => handleChildChange(index, chIndex, "gender", e.target.value)}>
                                      <option selected hidden>
                                        Gender
                                      </option>
                                      {travellersGenderArray?.map((genderItem, genderIndex) => (
                                        <option key={genderIndex} value={genderItem}>
                                          {genderItem}
                                        </option>
                                      ))}
                                    </select>
                                  </li>
                                </ul>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  ))}

                  <div className="PagengerDtailsBox">
                    <h3>Customer Details</h3>
                    <ul className="FormList FormListNoBorder">
                      <li>
                        {userData?.data?.user_type !== 4 ? (
                          <ul className="row FormList2 FormRadioList">
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input form-input-radio"
                                  type="radio"
                                  name="customertype"
                                  checked={customerType === 1 ? true : false}
                                  onClick={() => {
                                    setCustomerType(1);
                                    getCustomerDetails();
                                  }}
                                />
                                <label className="form-check-label">I am booking for myself</label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input form-input-radio"
                                  type="radio"
                                  name="customertype"
                                  checked={customerType === 2 ? true : false}
                                  onClick={() => {
                                    setCustomerType(2);
                                    setCustomerDetails({name: "", address: "", email: "", phoneNumber: "", state: "", panNumber: ""});
                                  }}
                                />
                                <label className="form-check-label">I am booking for others</label>
                              </div>
                            </li>
                          </ul>
                        ) : null}
                        <ul className="row FormList2 FormListMrginBottom">
                          <li className="col-md-6">
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              value={customerDetails?.name}
                              placeholder="Name"
                              onChange={handleCustomerDetailChange}
                            />
                          </li>
                          <li className="col-md-6">
                            <input
                              type="text"
                              name="address"
                              className="form-control"
                              placeholder="Address"
                              value={customerDetails?.address}
                              onChange={handleCustomerDetailChange}
                            />
                          </li>
                          <li className="col-md-6">
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="Email"
                              // readOnly={(customerType === 1 && custDetails?.User?.email) || secondVerified ? true : false}
                              value={customerDetails?.email}
                              onChange={handleCustomerDetailChange}
                            />
                            {/* {customerType === 1 &&
                            custDetails?.User?.email === null &&
                            !secondVerified &&
                            /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(customerDetails?.email) ? (
                              <>
                                <button className="verifyLink" onClick={() => handleSendOtp("email")} disabled={verifyBtnDis}>
                                  Verify
                                </button>
                              </>
                            ) : null} */}
                            {/* {customerType === 2 && !secondVerified && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(customerDetails?.email) ? (
                              <>
                                <button className="verifyLink" onClick={() => handleSendOtp("email")} disabled={verifyBtnDis}>
                                  Verify
                                </button>
                              </>
                            ) : null} */}
                          </li>

                          <li className="col-md-6">
                            <input
                              type="number"
                              name="phoneNumber"
                              className="form-control"
                              placeholder="Phone Number"
                              // readOnly={(customerType === 1 && custDetails?.User?.mobile) || secondVerified ? true : false}
                              value={customerDetails?.phoneNumber}
                              onChange={handleCustomerDetailChange}
                            />
                          </li>
                          <li className="col-md-6">
                            <select
                              className="form-select form-control"
                              name="state"
                              placeholder="State"
                              value={customerDetails?.state}
                              onChange={handleCustomerDetailChange}>
                              <option selected hidden>
                                State
                              </option>
                              {stateList?.map((stateItem, stateIndex) => (
                                <option key={stateIndex} value={stateItem?.id}>
                                  {stateItem?.name}
                                </option>
                              ))}
                            </select>
                            {/* <input type="text" name="state" className="form-control" placeholder="State" onChange={handleCustomerDetailChange} /> */}
                          </li>
                          <li className="col-md-6">
                            <input
                              type="text"
                              name="panNumber"
                              className="form-control"
                              placeholder="PAN Number (Optional)"
                              value={customerDetails?.panNumber}
                              onChange={handleCustomerDetailChange}
                            />
                          </li>
                          <li className="col-md-6">
                            <input
                              type="text"
                              name="agentCode"
                              className="form-control"
                              placeholder="Agent Code"
                              readOnly={userData?.data?.user_type === 4}
                              value={customerDetails?.agentCode}
                              onChange={handleCustomerDetailChange}
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ConditionAccordion">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Wishmytour T&C</Accordion.Header>
                      <Accordion.Body>
                        <ul className="ContentList">
                          <li>
                            <h4>Applicability of Rates</h4>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
                              ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived
                              not only five centuries.
                            </p>
                          </li>
                          <li>
                            <h4>Deposit Payment</h4>
                            <p>
                              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The
                              point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,
                              content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as
                              their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.
                            </p>
                            <p>
                              Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45
                              BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of
                              the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical
                              literature, discovered the undoubtable source.
                            </p>
                            <p>
                              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by
                              injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum,
                              you need to be sure there isn't anything embarrassing hidden in the middle of text.
                            </p>
                            <p>
                              All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator
                              on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate
                              Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or
                              non-characteristic words etc.
                            </p>
                            <a className="ShowMoreLink">Show More</a>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Disclaimer</Accordion.Header>
                      <Accordion.Body>
                        <ul className="DisclaimerList">
                          <li>
                            <div className="form-check">
                              <input
                                className="form-check-input form-input-check"
                                type="checkbox"
                                checked={disclaimerCheckbox === true ? true : false}
                                onChange={e => setDisclaimerCheckbox(e.target.checked)}
                              />
                              <label className="form-check-label">
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                                quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                                sit aspernatur aut odit aut fugit,{" "}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Lead Consent</Accordion.Header>
                      <Accordion.Body>
                        <ul className="ContentList">
                          <li>
                            <p>
                              But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a
                              complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human
                              happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to
                              pursue pleasure rationally encounter consequences that are extremely painful.
                            </p>
                          </li>
                        </ul>
                        <ul className="row FormRadioList">
                          <li>
                            <div className="form-check">
                              <input className="form-check-input form-input-radio" type="radio" name="leadType" onClick={() => setLeadConsent("agree")} />
                              <label className="form-check-label">Agree</label>
                            </div>
                          </li>
                          <li>
                            <div className="form-check">
                              <input className="form-check-input form-input-radio" type="radio" name="leadType" onClick={() => setLeadConsent("disagree")} />
                              <label className="form-check-label">Disagree</label>
                            </div>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="DetailsWhiteBox">
                  <div className="DetailsTopHeader">
                    <h3>Important Information</h3>
                  </div>
                  <div className="ConditionAccordion">
                    <div className="accordion">
                      <div className="accordion-item">
                        <div className="accordion-body">
                          <ul className="DisclaimerList">
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input form-input-check agreeSection"
                                  type="checkbox"
                                  checked={termAgree === true ? true : false}
                                  onChange={e => setTermAgree(e.target.checked)}
                                />
                                <label className="form-check-label">
                                  By checking this checkbox, I confirm that I have read and I accept{" "}
                                  <Link to={"/cancellation-policy"} target="_blank">
                                    Cancellation policy
                                  </Link>
                                  ,{" "}
                                  <Link to="/user-agreement" target="_blank">
                                    User Agreement
                                  </Link>
                                  ,{" "}
                                  <Link to="/terms-of-use" target="_blank">
                                    Terms of Service
                                  </Link>{" "}
                                  and{" "}
                                  <Link to="/privacy-policy" target="_blank">
                                    Privacy Policy
                                  </Link>{" "}
                                  of Wishmytour.
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 RightReviewFlights">
                <div className="card WhiteCard">
                  <table className="table">
                    <tr>
                      <th>Base Price</th>
                      <td>
                        ₹
                        {(parseFloat(priceDetails?.basePrice) + parseFloat(priceDetails?.bulkDiscountPrice)).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                    {bulkDiscAvail ? (
                      <tr className="GreenFont">
                        <th>Bulk Discount({priceDetails?.bulkDiscountPercent}%)</th>
                        {/* <th>Bulk Discount({bookingObjFromSession?.pax_discount_percent}%)</th> */}
                        <td className="GreenFont">- ₹{priceDetails?.bulkDiscountPrice.toLocaleString()}</td>
                      </tr>
                    ) : null}
                    {priceDetails?.addOnPrice ? (
                      <tr>
                        <th>Add On</th>
                        <td>₹{(priceDetails?.addOnPrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                      </tr>
                    ) : null}
                    {priceDetails.gstTax > 0 ? (
                      <tr>
                        <th>GST({priceDetails?.gstTax}%)</th>
                        <td>₹{Number(priceDetails?.gstTaxIncludePrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                      </tr>
                    ) : null}
                    {priceDetails.tcsTax > 0 ? (
                      <tr>
                        <th>TCS({priceDetails?.tcsTax}%)</th>
                        <td>₹{Number(priceDetails?.tcsTaxIncludePrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                      </tr>
                    ) : null}
                    {priceDetails?.predefinedCouponPrice || priceDetails?.otherCouponPrice ? (
                      <tr className="GreenFont">
                        <th>Coupon Discount</th>
                        <td className="GreenFont">
                          - ₹{Number(priceDetails?.couponDiscountPrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <th>You Pay</th>
                      <td>
                        <span className="GrandPrice">
                          {/* {priceDetails?.predefinedCouponPrice || priceDetails?.otherCouponPrice ? (
                            <>
                              <span style={{textDecoration: "line-through", color: "#000", fontSize: "14px"}}> ₹{priceDetails?.subTotalPrice}</span> <br />
                            </>
                          ) : null} */}
                          ₹
                          {priceDetails?.couponDiscountedSubTotal ? (
                            // priceChanged > 0 && (
                            // <CountUp
                            //   start={Number(priceDetails.subTotalPrice)}
                            //   end={Number(priceDetails.couponDiscountedSubTotal)}
                            //   decimals={2}
                            //   duration={1.5}
                            //   separator=","
                            //   decimal="."
                            //   prefix=""
                            //   suffix=""
                            //   useEasing={true}
                            //   formattingFn={value =>
                            //     value.toLocaleString("en-IN", {
                            //       minimumFractionDigits: 2,
                            //       maximumFractionDigits: 2,
                            //     })
                            //   }
                            // />
                            <>
                              <CountUpComponent start={Number(priceDetails.subTotalPrice)} end={Number(priceDetails.couponDiscountedSubTotal)} />
                            </>
                          ) : // )
                          // Number(priceDetails.couponDiscountedSubTotal).toLocaleString("en-IN", {
                          //     minimumFractionDigits: 2,
                          //     maximumFractionDigits: 2,
                          //   })
                          priceDetails?.subTotalPrice ? (
                            // Number(priceDetails.subTotalPrice).toLocaleString("en-IN", {
                            //   minimumFractionDigits: 2,
                            //   maximumFractionDigits: 2,
                            // })
                            // <CountUp
                            //   start={Number(priceDetails.subTotalPrice)}
                            //   end={Number(priceDetails.subTotalPrice)}
                            //   decimals={2}
                            //   duration={1.5}
                            //   separator=","
                            //   decimal="."
                            //   prefix=""
                            //   suffix=""
                            //   useEasing={true}
                            //   formattingFn={value =>
                            //     value.toLocaleString("en-IN", {
                            //       minimumFractionDigits: 2,
                            //       maximumFractionDigits: 2,
                            //     })
                            //   }
                            // />
                            <CountUpComponent start={Number(priceDetails.subTotalPrice)} end={Number(priceDetails.subTotalPrice)} />
                          ) : (
                            "0.00"
                          )}
                        </span>
                      </td>
                    </tr>
                  </table>
                  {payAnotherOptionShow ? (
                    <ul className="RadioList">
                      <li>
                        <div className="form-check coupons">
                          <div>
                            <input
                              className="form-check-input form-input-radio"
                              type="radio"
                              name="couponCode"
                              // value={item?.id}
                              // checked={predefinedSelctedCoupon === item?.id}
                              onClick={() => {
                                // setPredefinedSelctedCoupon(item?.id);
                                // setPriceChanged(prev => prev + 1);
                                setPriceDetails(prev => ({...prev, payOption: 1}));
                              }}
                            />
                            <label className="form-check-label">
                              <strong>Book Now Pay Later</strong>
                            </label>
                          </div>
                        </div>
                        <ul className="payLaterContainer">
                          <li className="payLaterMainSec">
                            <div className="leftSec">
                              <span>1.</span>
                              <div className="leftSecInner">
                                <p className="mainText">Pay to Book</p>
                                <p className="subText">Amount to pay now to reserve</p>
                              </div>
                            </div>

                            <span>
                              ₹
                              {parseFloat(priceDetails?.payAmount).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                          </li>
                          <li className="payLaterMainSec">
                            <div className="leftSec">
                              <span>2.</span>
                              <div className="leftSecInner">
                                <p className="mainText">Before {paymentDateForShow}</p>
                              </div>
                            </div>

                            <span>
                              ₹
                              {(parseFloat(priceDetails?.subTotalPrice) - parseFloat(priceDetails?.payAmount)).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className="form-check coupons">
                          <div>
                            <input
                              className="form-check-input form-input-radio"
                              type="radio"
                              name="couponCode"
                              // value={item?.id}
                              // checked={predefinedSelctedCoupon === item?.id}
                              onClick={() => {
                                setPriceDetails(prev => ({...prev, payOption: 2}));
                              }}
                            />
                            <label className="form-check-label">
                              <strong>Pay Full Amount Now </strong>
                            </label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  ) : null}
                </div>
                <div className="card WhiteCard">
                  <h4>Coupon</h4>
                  <ul className="CouponList">
                    <li>
                      {otherSelectedCoupon ? (
                        <>
                          <input type="text" name="" className="form-control" placeholder="Coupon code" readOnly value={couponCodeText} />
                          <input
                            type="button"
                            name=""
                            value="Remove"
                            className="btn btn-primary CmnBtn"
                            onClick={() => {
                              setCouponCodeText("");
                              setCouponCodeSuccess("");
                              setOtherSelectedCoupon(null);
                              setPriceChanged(prev => prev + 1);
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            name=""
                            className="form-control"
                            placeholder="Coupon code"
                            value={couponCodeText}
                            onChange={e => {
                              setCouponCodeText(e.target.value);
                              setCouponCodeError("");
                            }}
                          />
                          <input
                            type="button"
                            name=""
                            value="Apply"
                            className="btn btn-primary CmnBtn"
                            disabled={!couponCodeText}
                            onClick={() => handleApplyCoupon()}
                          />
                        </>
                      )}

                      {couponCodeError ? <span className="validationErrorMsg">{couponCodeError}</span> : null}
                      {couponCodeSuccess ? <span className="successMsg">{couponCodeSuccess}</span> : null}
                    </li>
                  </ul>
                  <ul className="RadioList">
                    {couponDetails?.map((item, index) => (
                      <li key={index}>
                        <div className="form-check coupons">
                          <div>
                            <input
                              className="form-check-input form-input-radio"
                              type="radio"
                              name="couponCode"
                              value={item?.id}
                              checked={predefinedSelctedCoupon === item?.id}
                              onClick={() => {
                                setPredefinedSelctedCoupon(item?.id);
                                setPriceChanged(prev => prev + 1);
                              }}
                            />
                            <label className="form-check-label">
                              <strong>{item?.code}</strong>
                              {item?.description}
                            </label>
                          </div>
                          {parseInt(predefinedSelctedCoupon) === parseInt(item?.id) ? (
                            <span
                              className="removeCouponBtn"
                              onClick={() => {
                                setPredefinedSelctedCoupon(null);
                                setPriceChanged(prev => prev + 1);
                              }}>
                              Remove
                            </span>
                          ) : (
                            ""
                          )}{" "}
                        </div>
                      </li>
                    ))}

                    {/* <li>
                      <div className="form-check">
                        <input className="form-check-input form-input-radio" type="radio" name="couponCode" />
                        <label className="form-check-label">
                          <strong>ICICIFESTNB</strong>
                          Flat 10% OFF on using ICICI Bank Internet banking.
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input className="form-check-input form-input-radio" type="radio" name="couponCode" />
                        <label className="form-check-label">
                          <strong>ICICIFESTCC</strong>
                          Flat 10% OFF on using ICICI bank credit cards.
                        </label>
                      </div>
                    </li> */}
                  </ul>
                  <a className="ShowMoreLink">Show More</a>
                </div>
                <div className="card WhiteCard">
                  <h3>
                    Available Points: <span>0</span>
                  </h3>
                  <ul className="CouponList">
                    <li>
                      <input type="text" name="" className="form-control" placeholder="Enter Pointse" />
                      <input type="button" name="" value="Apply" className="btn btn-primary CmnBtn" />
                      <span className="NoteSpan">1 Coin Value ₹10</span>
                    </li>
                  </ul>
                </div>
                {priceDetails.tcsTax > 0 ? (
                  <>
                    <h4>How to get</h4>
                    <h5>100% Credit of TCS Amount</h5>
                    <div className="OrangeCard">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                    <div className="LightGreenCard">It is a long established fact that a reader will be distracted by the readable</div>
                    <div className="BlueCard">There are many variations of passages of Lorem Ipsum available, but the</div>
                  </>
                ) : null}
              </div>
            </div>

            {userData?.data?.user_type === 4 && agentDetails?.data?.is_verified === 0 ? (
              <input
                type="button"
                name=""
                value="Continue"
                disabled={!termAgree}
                className="btn btn-primary CmnBtn CmnBtn2"
                onClick={() => NotificationManager.warning("Your account is not verified yet. You can continue once your account is verified.")}
              />
            ) : btnDis ? (
              <input type="button" name="" value="Please wait..." className="btn btn-primary CmnBtn CmnBtn2" disabled />
            ) : (
              <input
                type="button"
                name=""
                value="Continue"
                disabled={!termAgree}
                className="btn btn-primary CmnBtn CmnBtn2"
                onClick={() => handleSaveTravellerDetails()}
              />
            )}
          </div>
        </div>
      </div>

      <div className="hotelSliderPopup" style={{display: showChangeNumberPopup ? "block" : "none"}}>
        <div className="sliderflex">
          <div className="popInner loginDetails">
            <h2>Change Phone Number</h2>

            <ul>
              <li>
                <input
                  type="text"
                  value={existingNumber}
                  onChange={e => {
                    setExistingNumber(e.target.value);
                    setExistingNumberError("");
                  }}
                  placeholder="Existing Phone Number"
                />
                {existingNumberError && <span className="validationErrorMsg">{existingNumberError}</span>}
              </li>
              <li>
                <input
                  type="text"
                  value={newNumber}
                  onChange={e => {
                    setNewNumber(e.target.value);
                    setNewNumberError("");
                  }}
                  placeholder="Enter New Number"
                />
                {newNumberError && <span className="validationErrorMsg">{newNumberError}</span>}
              </li>
              <li>
                <input
                  type="text"
                  value={confirmNewNumber}
                  onKeyDown={handleKeyPress}
                  onChange={e => {
                    setConfirmNewNumber(e.target.value);
                    setConfirmNewNumberError("");
                  }}
                  placeholder="Confirm New Number"
                />
                {confirmNewNumberError && <span className="validationErrorMsg">{confirmNewNumberError}</span>}
              </li>
              <li className="submitLink">{validationError && <span className="validationErrorMsg">{validationError}</span>}</li>
              <li className="submitLink">
                {loading ? (
                  <button className="submitDropBtn" disabled>
                    Please wait...
                  </button>
                ) : (
                  <button className="submitDropBtn" onClick={handleChangeNumber}>
                    Generate OTP
                  </button>
                )}
              </li>
            </ul>

            <button className="closePopupBtn" onClick={closePhonePopup}>
              <img src={crossIcon2} alt="" />
            </button>
          </div>
        </div>
      </div>

      <div className="hotelSliderPopup" style={{display: showOtpPopup ? "block" : "none"}}>
        <div className="sliderflex">
          <div className="popInner loginDetailsOtp loginDiv">
            <h2>OTP Verification</h2>
            <h3>Check text messages for your OTP</h3>
            {/* <h5>Your Otp is - {changeNumberResponse?.otp}</h5> */}
            <ul className="otpList">
              <li>
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={e => handleInputChange(index, e)}
                    onKeyUp={e => handleBackspaceChangeNumber(index, e)}
                    onPaste={handlePasteChangeNumber}
                    ref={inputRefs[index]}
                  />
                ))}
              </li>
              <li>00:{parseInt(timer) < 10 ? "0" + timer : timer}</li>
              <li>
                <h4>
                  Not Received OTP?{" "}
                  {parseInt(timer) === 0 ? (
                    <Link to="javascript:void(0);" onClick={() => handleChangeNumber()}>
                      Resend Now
                    </Link>
                  ) : (
                    "Resend Now"
                  )}
                </h4>
                {validationError && <p className="validationErrorMsg">{validationError}</p>}
                {successMsg && <p className="successMsg">{successMsg}</p>}
              </li>
              <li>
                <button onClick={() => handleSubmit(otp)}>Submit</button>
              </li>
            </ul>
            <button className="closePopupBtn" onClick={closePhonePopup}>
              <img src={crossIcon2} alt="" />
            </button>
          </div>
        </div>
      </div>

      <Footer />

      {/* Verify Email and Phone Number */}
      <div className="hotelSliderPopup" style={{display: showOtpPopup1 ? "block" : "none"}}>
        <div className="sliderflex">
          <div className="popInner loginDetailsOtp loginDiv">
            <h2>OTP Verification</h2>
            <h3>Check text messages for your OTP</h3>
            {/* <h5>Your Otp is - {changeNumberResponse1}</h5> */}
            <ul className="otpList">
              <li>
                {otp1.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={e => handleOtpInputChange1(index, e)}
                    onKeyUp={e => handleBackspace1(index, e)}
                    onPaste={handlePaste1}
                    ref={inputRefs1[index]}
                  />
                ))}
              </li>
              <li>00:{parseInt(timer1) < 10 ? "0" + timer1 : timer1}</li>
              <li>
                <h4>
                  Not Received OTP?{" "}
                  {parseInt(timer1) === 0 ? (
                    <Link to="javascript:void(0);" onClick={() => handleResendOTP()}>
                      Resend Now
                    </Link>
                  ) : (
                    "Resend Now"
                  )}
                </h4>
                {validationError && <p className="validationErrorMsg">{validationError}</p>}
                {successMsg && <p className="successMsg">{successMsg1}</p>}
              </li>
              <li>
                <button onClick={() => handleOtpSubmit(otp)}>Submit</button>
              </li>
            </ul>
            <button className="closePopupBtn" onClick={() => setShowOtpPopup1(false)}>
              <img src={crossIcon2} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Travellers;
