import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  addVendorInformationtApi,
  getCityListApi,
  getDetailsFromPincodeApi,
  getStatesListApi,
  gstDetailsApi,
  loginApi,
  typeOfVendorListApi,
  updateOtpApi,
  vendorLoginApi,
  vendorVerifyOtpApi,
  verifyEmailOrMobileApi,
  verifyOtpApi,
} from "../services/ApiService";
import {useUserContext} from "../context/UserContext";
import {Modal} from "react-bootstrap";
import {NotificationManager} from "react-notifications";
import ReCAPTCHA from "react-google-recaptcha";
import google from "../assets/img/google-icon.svg";
import facebook from "../assets/img/facebook-icon2.svg";
import VendorRegistrationLogo from "../assets/img/vendor-registration-logo.svg";
import VendorRegisteredSuccessIcon from "../assets/img/Vendor-registered-success-icon.svg";
import MinusIcon from "../assets/img/minus-icon3.svg";
import PlusIcon from "../assets/img/plus-icon3.svg";
import {CAPTCHA_SITE_KEY, IMAGE_URL} from "../utils/commonUrls";
const crossIcon2 = require("./../assets/img/cross.svg").default;

const VendorRegistrationGstProvided = () => {
  const navigate = useNavigate();
  const {setUserData} = useUserContext();
  const {loginValue, verifyOtpResponse, responseDataForLocal} = useLocation().state ?? {};

  const [referralActive, setReferralActive] = useState(true);
  const [gstDetailsAvail, setGstDetailsAvail] = useState("yes");
  const [vendorFormData, setVendorFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    typeOfVendor: "",
    address: "",
    pincode: "",
    referrelCode: "",
    vendorImage: "",
    panImage: "",
    authorizationLetter: "",
    panNumber: "",
    gstNumber: "",
    gstRate: "",
    gstCertificate: "",
    organizationName: "",
    organizationPanImage: "",
    organizationPanNumber: "",
    prosName: "",
    prosPhoneNumber: "",
    prosPanNumber: "",
    prosAddress: "",
    prosPhoto: "",
  });
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [broadLocationArray, setBroadLocationArray] = useState([{country: null, state: null, city: null}]);
  const [organizationType, setOrganizationType] = useState(2);
  const [partnersArray, setPartnersArray] = useState([{name: null, phoneNumber: null, panNumber: null, address: null, photo: null}]);
  const [privateLimitedArray, setPrivateLimitedArray] = useState([{name: null, phoneNumber: null, panNumber: null, address: null, photo: null}]);
  const [loading, setLoading] = useState(false);
  const [verifyBtnDis, setVerifyBtnDis] = useState(false);
  const [typeOfVendorList, setTypeOfVendorList] = useState([]);
  const [pincodeError, setPincodeError] = useState(false);
  const [gstNumberError, setGstNumberError] = useState("");
  const [onSendRequestSuccessPopup, setOnSendRequestSuccessPopup] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [timer, setTimer] = useState(60);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [changeNumberResponse, setChangeNumberResponse] = useState();
  const [successMsg, setSuccessMsg] = useState("");
  const [validationError, setValidationError] = useState("");
  const [secondVerified, setSecondVerified] = useState(false);
  const [recountTimer, setRecountTimer] = useState(false);
  const [clickedType, setClickedType] = useState();
  const [captchaChecked, setCaptchaChecked] = useState(false);
  const [gstLoading, setGstLoading] = useState(false);

  const getStateList = async () => {
    try {
      let response = await getStatesListApi();
      if (response) {
        if (response.data.res === true) {
          setStateList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getTypeOfVendorList = async () => {
    try {
      let response = await typeOfVendorListApi();
      if (response) {
        if (response.data.res === true) {
          setTypeOfVendorList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getCityList = async stateId => {
    try {
      const formData = new FormData();
      if (stateId) {
        formData.append("state[]", stateId);
      }
      let response = await getCityListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setCityList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  // const handleStateChange = (event, index) => {
  //   const { value } = event.target;
  //   let tempArray = [...broadLocationArray];
  //   tempArray[index].state = value;
  //   setBroadLocationArray(tempArray);
  //   getCityList(value, index); // Pass index to getCityList
  // };

  const handleStateChange = async (event, index) => {
    const {value} = event.target;
    let tempArray = [...broadLocationArray];
    tempArray[index].state = value;
    setBroadLocationArray(tempArray);

    try {
      const formData = new FormData();
      if (value) {
        formData.append("state[]", value);
      }
      let response = await getCityListApi(formData);
      if (response && response.data.res === true) {
        tempArray[index].cities = response.data.data; // Store city list for the current broad location
        setBroadLocationArray([...tempArray]); // Update broadLocationArray
      }
    } catch (error) {
      console.error("Error fetching city list:", error);
    }
  };

  const handleCityChange = (event, index) => {
    const {value} = event.target;
    let tempArray = [...broadLocationArray];
    tempArray[index].city = value;
    setBroadLocationArray(tempArray);
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);

      // Set focus on the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4);

    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      for (let i = 0; i < 4; i++) {
        newOtp[i] = pastedData[i] || "";
      }
      return newOtp;
    });

    if (pastedData?.length < 4) {
      inputRefs[pastedData?.length].current.focus();
    } else {
      inputRefs[3].current.focus();
    }

    // Check if all four digits are present in the pasted data
    if (pastedData.length === 4 && pastedData.match(/^\d{4}$/)) {
      let newValue = pastedData.split("");
      handleOtpSubmit(newValue);
    }
  };

  const handleOtpInputChange = (index, e) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    // Check if all OTP digits are entered
    if (index === inputRefs.length - 1 && newOtp.every(digit => digit !== "")) {
      setValidationError("");
      handleOtpSubmit(newOtp);
    }
  };

  const handleInputChange = (field, value) => {
    if (field === "vendorImage" || field === "prosPhoto") {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (value && !validImageTypes.includes(value.type)) {
        NotificationManager.warning("Please upload a valid image file (JPEG or PNG).");
        return;
      }
    }
    if (field === "panImage" || field === "gstCertificate" || field === "organizationPanImage" || field === "authorizationLetter") {
      const validFileTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (value && !validFileTypes.includes(value.type)) {
        NotificationManager.warning("Please upload a valid file (JPEG, PNG, or PDF).");
        return;
      }
    }

    setVendorFormData(prevData => ({...prevData, [field]: value}));
  };

  const handleGstNumberChange = async value => {
    if (value?.length > 5 && !/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(value)) {
      setGstNumberError("Invalid GSTIN.");
    } else {
      try {
        setGstLoading(true);
        let response = await gstDetailsApi(value, responseDataForLocal?.token);
        if (response) {
          if (response.data.error) {
            setGstNumberError(response.data.error_description);
            return;
          }
          setGstNumberError("");
          handlePincodeChange(response?.data?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.pincode);
          let orgType =
            response?.data?.constitutionOfBusiness === "Private Limited Company" ? 3 : response?.data?.constitutionOfBusiness === "Proprietorship" ? 1 : 2;
          setOrganizationType(orgType);
          setVendorFormData(prev => ({
            ...prev,
            organizationName: response?.data?.legalNameOfBusiness,
            address:
              response?.data?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.buildingNumber +
              (response?.data?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.streetName
                ? " " + response?.data?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.streetName
                : ""),
            pincode: response?.data?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.pincode,
          }));
          setGstLoading(false);
        } else {
        }
      } catch (error) {
        console.error(error);
        setGstLoading(false);
      }
    }
  };

  const handlePincodeChange = async value => {
    try {
      let response = await getDetailsFromPincodeApi(value);
      if (response && response.data[0].Status === "Success") {
        setBroadLocationArray([
          {country: response.data[0].PostOffice[0].Country, state: response.data[0].PostOffice[0].State, city: response.data[0].PostOffice[0].District},
        ]);
        setPincodeError(false);
      } else {
        setBroadLocationArray([{country: "", state: "", city: ""}]);
        setPincodeError(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addPartner = () => {
    setPartnersArray([...partnersArray, {name: null, phoneNumber: null, panNumber: null, address: null, photo: null}]);
  };

  const deletePartner = index => {
    const updatedPartners = [...partnersArray];
    updatedPartners.splice(index, 1);
    setPartnersArray(updatedPartners);
  };

  const addPrivateLimited = () => {
    setPrivateLimitedArray([...privateLimitedArray, {name: null, phoneNumber: null, panNumber: null, address: null, photo: null}]);
  };

  const deletePrivateLimited = index => {
    const updatedPartners = [...privateLimitedArray];
    updatedPartners.splice(index, 1);
    setPrivateLimitedArray(updatedPartners);
  };

  const handlePartnerChange = (index, field, value) => {
    if (field === "photo") {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (value && !validImageTypes.includes(value.type)) {
        NotificationManager.warning("Please upload a valid image file (JPEG or PNG).");
        return;
      }
    }
    const updatedPartners = [...partnersArray];
    updatedPartners[index][field] = value;
    setPartnersArray(updatedPartners);
  };

  const handleDirectorChange = (index, field, value) => {
    if (field === "photo") {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (value && !validImageTypes.includes(value.type)) {
        NotificationManager.warning("Please upload a valid image file (JPEG or PNG).");
        return;
      }
    }

    const updatedDirectors = [...privateLimitedArray];
    updatedDirectors[index][field] = value;
    setPrivateLimitedArray(updatedDirectors);
  };

  const handleSendOtp = async type => {
    setClickedType(type);
    setRecountTimer(!recountTimer);
    if (type === "mobile") {
      if (vendorFormData.phoneNumber === "" || !/^\d{10}$/.test(vendorFormData.phoneNumber)) {
        NotificationManager.warning("Please enter a valid phone number.");
        return false;
      } else {
        const isValid = /^\d{10}$/.test(vendorFormData.phoneNumber);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid phone number.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let formData = new FormData();
            formData.append("user_id", responseDataForLocal?.data?.customer_id);
            formData.append("mobile", vendorFormData.phoneNumber);
            setTimer(60);
            // Api call
            let response = await updateOtpApi(formData);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                // setSecondVerified(true);
                inputRefs[0].current.focus();
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else if (type === "email") {
      if (vendorFormData.email === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(vendorFormData.email)) {
        NotificationManager.warning("Please enter a valid Email Address.");
        return false;
      } else {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(vendorFormData.email);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid email.");
        } else {
          setValidationError("");
          try {
            let formData = new FormData();
            formData.append("user_id", responseDataForLocal?.data?.customer_id);
            formData.append("email", vendorFormData.email);
            // Api call
            let response = await updateOtpApi(formData);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                setTimer(60);
                // setSecondVerified(true);
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else {
    }
  };

  const handleResendOTP = async () => {
    setRecountTimer(!recountTimer);
    let type = clickedType;
    if (type === "mobile") {
      if (vendorFormData.phoneNumber === "" || !/^\d{10}$/.test(vendorFormData.phoneNumber)) {
        NotificationManager.warning("Please enter a valid phone number.");
        return false;
      } else {
        const isValid = /^\d{10}$/.test(vendorFormData.phoneNumber);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid phone number.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let formData = new FormData();
            formData.append("user_id", responseDataForLocal?.data?.customer_id);
            formData.append("mobile", vendorFormData.phoneNumber);
            setTimer(60);
            // Api call
            let response = await updateOtpApi(formData);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                // setSecondVerified(true);
                inputRefs[0].current.focus();
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else if (type === "email") {
      if (vendorFormData.email === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(vendorFormData.email)) {
        NotificationManager.warning("Please enter a valid Email Address.");
        return false;
      } else {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(vendorFormData.email);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid email.");
        } else {
          setValidationError("");
          try {
            let formData = new FormData();
            formData.append("user_id", responseDataForLocal?.data?.customer_id);
            formData.append("email", vendorFormData.email);
            // Api call
            let response = await updateOtpApi(formData);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                setTimer(60);
                // setSecondVerified(true);
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else {
    }
  };

  const handleOtpSubmit = async otp => {
    console.log(1);
    if (otp.every(digit => digit === "")) {
      setValidationError("Please enter Your Otp.");
    } else {
      let newVal = otp.join("");
      setValidationError("");
      // setLoading(true);
      console.log(2);
      try {
        let formData = new FormData();
        formData.append("user_id", responseDataForLocal?.data?.customer_id);
        if (responseDataForLocal?.data?.email) {
          formData.append("email", responseDataForLocal?.data?.email);
          formData.append("mobile", vendorFormData?.phoneNumber);
        }
        if (responseDataForLocal?.data?.mobile) {
          formData.append("mobile", responseDataForLocal?.data?.mobile);
          formData.append("email", vendorFormData?.email);
        }
        formData.append("otp", newVal);

        let response = await verifyEmailOrMobileApi(formData);
        if (response) {
          if (response.data.res === true) {
            setValidationError("");
            // setSuccessMsg(response.data.msg);
            NotificationManager.success(response.data.msg);
            setShowOtpPopup(false);
            setSecondVerified(true);
          } else {
            setValidationError(response.data.msg);
            setSuccessMsg("");
          }
        }
      } catch (error) {}
    }
  };

  const onCaptchaChange = value => {
    setCaptchaChecked(true);
  };

  const validationForm = () => {
    let isValid = true;
    if (!vendorFormData?.fullName) {
      NotificationManager.warning("Please enter fullname.");
      isValid = false;
      // } else if (!vendorFormData?.typeOfVendor) {
      //   NotificationManager.warning("Please select vendor type.");
      //   isValid = false;
    } else if (vendorFormData.email === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(vendorFormData.email)) {
      NotificationManager.warning("Please enter a valid Email Address.");
      isValid = false;
    } else if (vendorFormData.phoneNumber === "" || !/^\d{10}$/.test(vendorFormData.phoneNumber)) {
      NotificationManager.warning("Please enter a valid 10-digit Mobile Number.");
      isValid = false;
    } else if (gstDetailsAvail === "yes" && !vendorFormData?.gstNumber) {
      NotificationManager.warning("Please enter GST number.");
      isValid = false;
    } else if (gstDetailsAvail === "yes" && gstNumberError) {
      NotificationManager.warning(gstNumberError);
      isValid = false;
    } else if (gstDetailsAvail === "yes" && !vendorFormData?.gstCertificate) {
      NotificationManager.warning("Please select GST certificate.");
      isValid = false;
    } else if (gstDetailsAvail === "yes" && !vendorFormData?.gstRate) {
      NotificationManager.warning("Please enter GST rate.");
      isValid = false;
    } else if (gstDetailsAvail === "yes" && !/^\d{1,2}(\.\d{1,2})?$/.test(vendorFormData?.gstRate)) {
      NotificationManager.warning("GST rate is not valid.");
      isValid = false;
    } else if (!vendorFormData?.organizationName) {
      NotificationManager.warning("Please enter organization name.");
      isValid = false;
    } else if (!vendorFormData?.organizationPanNumber) {
      NotificationManager.warning("Please enter organization pan number.");
      isValid = false;
    } else if (!vendorFormData?.organizationPanImage) {
      NotificationManager.warning("Please upload organization pan image.");
      isValid = false;
    } else if (!vendorFormData?.authorizationLetter) {
      NotificationManager.warning("Please upload authorization letter.");
      isValid = false;
    } else if (!vendorFormData?.address) {
      NotificationManager.warning("Please enter address.");
      isValid = false;
    } else if (!vendorFormData?.pincode) {
      NotificationManager.warning("Please enter pincode.");
      isValid = false;
    } else if (vendorFormData?.pincode && !broadLocationArray.every(location => location.country && location.state && location.city)) {
      NotificationManager.warning("Please enter valid pincode.");
      isValid = false;
    } else if (!broadLocationArray.every(location => location.country && location.state && location.city)) {
      NotificationManager.warning("Please select broad location details for all locations.");
      isValid = false;
      // } else if (referralActive && !vendorFormData?.referrelCode) {
      //   NotificationManager.warning("Please enter referrel code.");
      //   isValid = false;
    } else if (!vendorFormData?.vendorImage) {
      NotificationManager.warning("Please upload profile image.");
      isValid = false;
    } else if (!vendorFormData?.panImage) {
      NotificationManager.warning("Please upload pancard photo.");
      isValid = false;
    } else if (!vendorFormData?.panNumber) {
      NotificationManager.warning("Please enter pancard number.");
      isValid = false;
    } else if (
      organizationType === 1 &&
      !vendorFormData?.prosName &&
      !vendorFormData?.prosPhoneNumber &&
      !vendorFormData?.prosPanNumber &&
      !vendorFormData?.prosAddress
      // && !vendorFormData?.prosPhoto
    ) {
      NotificationManager.warning("Please enter all proprietor details.");
      isValid = false;
    } else if (organizationType === 1 && !/^\d{10}$/.test(vendorFormData.prosPhoneNumber)) {
      NotificationManager.warning("Please enter a valid 10-digit Mobile Number.");
      isValid = false;
    } else if (
      organizationType === 2 &&
      partnersArray.some(
        partner => !partner.name || !partner.phoneNumber || !partner.panNumber || !partner.address || !/^\d{10}$/.test(partner.phoneNumber)
        // || !partner.photo
      )
    ) {
      NotificationManager.warning("Please enter all partner details and valid phone numbers.");
      isValid = false;
    } else if (
      organizationType === 3 &&
      privateLimitedArray.some(
        partner => !partner.name || !partner.phoneNumber || !partner.panNumber || !partner.address || !/^\d{10}$/.test(partner.phoneNumber)
        // || !partner.photo
      )
    ) {
      NotificationManager.warning("Please enter all directors details and valid phone numbers.");
      isValid = false;
    } else if (!secondVerified) {
      NotificationManager.warning(`Please verify your ${!responseDataForLocal?.data?.mobile ? "phone number" : "email address"}.`);
      isValid = false;
    } else if (!captchaChecked) {
      NotificationManager.warning(`Please check the 'I am not a robot' checkbox`);
      isValid = false;
    }

    return isValid;
  };

  const handleFinalSubmit = async () => {
    if (validationForm()) {
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("name", vendorFormData.fullName);
        formData.append("email", vendorFormData.email);
        formData.append("mobile", vendorFormData.phoneNumber);

        // formData.append("type_of_vendor", vendorFormData.typeOfVendor ? vendorFormData.typeOfVendor : "");

        formData.append("address", vendorFormData.address ? vendorFormData.address : "");
        formData.append("zip_code", vendorFormData.pincode ? vendorFormData.pincode : "");
        formData.append(`country`, broadLocationArray[0].country ? broadLocationArray[0].country : "");
        formData.append(`state`, broadLocationArray[0].state ? broadLocationArray[0].state : "");
        formData.append(`city`, broadLocationArray[0].city ? broadLocationArray[0].city : "");

        // broadLocationArray?.map((item, index) => {
        //   formData.append(`vendor_broad_location[${index}][country]`, item.country ? item.country : '');
        //   formData.append(`vendor_broad_location[${index}][state]`, item.state ? item.state : '');
        //   formData.append(`vendor_broad_location[${index}][city]`, item.city ? item.city : '');
        // });

        // formData.append("referrel_code", vendorFormData.referrelCode);

        formData.append("photo", vendorFormData.vendorImage);
        formData.append("pan_card_file", vendorFormData.panImage);
        formData.append("authorization_file", vendorFormData.authorizationLetter);
        formData.append("pan_number", vendorFormData.panNumber);
        formData.append("have_gst", gstDetailsAvail === "yes" ? 1 : 0);
        if (gstDetailsAvail === "yes") {
          formData.append("gst_number", vendorFormData?.gstNumber);
          formData.append("gst_rate", vendorFormData?.gstRate);
          formData.append("gst_certificate_file", vendorFormData?.gstCertificate);
        }
        formData.append("organization_name", vendorFormData.organizationName);
        formData.append("organization_pan_number", vendorFormData.organizationPanNumber);
        formData.append("organization_pan_file", vendorFormData.organizationPanImage);
        formData.append("organization_type", organizationType.toString());
        if (organizationType === 1) {
          formData.append("proprietor_name", vendorFormData.prosName);
          formData.append("proprietor_phone_number", vendorFormData.prosPhoneNumber);
          formData.append("proprietor_pan_number", vendorFormData.panNumber);
          // formData.append("proprietor_pan", vendorFormData.prosPanNumber);
          formData.append("proprietor_address", vendorFormData.prosAddress);
          formData.append("proprietor_photo", vendorFormData.prosPhoto ? vendorFormData.prosPhoto : "");
        } else if (organizationType === 2) {
          formData.append("no_of_partners", partnersArray?.length);
          partnersArray?.map((item, index) => {
            formData.append(`vendor_partners[${index}][name]`, item.name);
            formData.append(`vendor_partners[${index}][phone_number]`, item.phoneNumber);
            formData.append(`vendor_partners[${index}][pan_number]`, item.panNumber);
            formData.append(`vendor_partners[${index}][address]`, item.address);
            formData.append(`vendor_partners[${index}][photo]`, item.photo ? item.photo : "");
          });
        } else if (organizationType === 3) {
          formData.append("no_of_directors", privateLimitedArray?.length);
          privateLimitedArray?.map((item, index) => {
            formData.append(`vendor_directors[${index}][name]`, item.name);
            formData.append(`vendor_directors[${index}][phone_number]`, item.phoneNumber);
            formData.append(`vendor_directors[${index}][pan_number]`, item.panNumber);
            formData.append(`vendor_directors[${index}][address]`, item.address);
            formData.append(`vendor_directors[${index}][photo]`, item.photo ? item.photo : "");
          });
        } else {
        }

        formData.append("status", 1);

        // Api call
        let response = await addVendorInformationtApi(formData, responseDataForLocal?.token);
        if (response) {
          if (response.data.res === true) {
            localStorage.setItem("WMTUserData", JSON.stringify(responseDataForLocal));
            localStorage.setItem("WMTLastActivityTimestamp", Date.now());
            setUserData(response.data);
            // NotificationManager.success(response.data.msg);
            setOnSendRequestSuccessPopup(true);
          } else {
            NotificationManager.warning(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        NotificationManager.error("Something went wrong, please try again.");
      }
    }
  };

  // Resend Counter useEffect
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [recountTimer]);

  useEffect(() => {
    if (showOtpPopup) {
      inputRefs[0].current.focus();
    }
  }, [showOtpPopup]);

  useEffect(() => {
    getStateList();
    // getTypeOfVendorList();
    if (verifyOtpResponse) {
      let tempFormData = {...vendorFormData};
      if (verifyOtpResponse?.email !== null) {
        tempFormData.email = verifyOtpResponse?.email;
      } else if (verifyOtpResponse?.mobile !== null) {
        tempFormData.phoneNumber = verifyOtpResponse?.mobile;
      } else {
      }
      setVendorFormData(tempFormData);
    }
  }, []);

  return (
    <div className="main">
      <div className="innerDiv RegistrationInnerDiv">
        <div className="mainDiv">
          <div className="VendorRegistrationLogoSec VendorRegistrationLogoSec2">
            <Link to={"/"}>
              <img src={VendorRegistrationLogo} alt="" />
            </Link>
            <div className="loginDiv">
              <h3>Tour Operator Registration</h3>
              <h2>General Information</h2>
              <ul className="row VendorRegistrationList">
                <li className="col-md-12">
                  <h4>Authorize Representative</h4>
                </li>
                <li className="col-md-6">
                  <input type="text" placeholder="Name" className="form-control" onChange={e => handleInputChange("fullName", e.target.value)} />
                </li>
                {/* <li className="col-md-6">
                  <select className="form-select" onChange={e => handleInputChange("typeOfVendor", e.target.value)}>
                    <option selected hidden>
                      Type of Vendor
                    </option>
                    {typeOfVendorList?.map((item, index) => (
                      <option key={index} value={item?.vendor_type}>
                        {item?.vendor_type}
                      </option>
                    ))}
                  </select>
                </li> */}
                <li className="col-md-6">
                  <input
                    type="text"
                    className="form-control paddingRight"
                    placeholder="Email"
                    value={vendorFormData.email}
                    disabled={responseDataForLocal?.data?.email !== null || secondVerified}
                    onChange={e => handleInputChange("email", e.target.value)}
                  />
                  {responseDataForLocal?.data?.email === null && !secondVerified && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(vendorFormData.email) ? (
                    <>
                      <button className="verifyLink" onClick={() => handleSendOtp("email")} disabled={verifyBtnDis}>
                        Verify
                      </button>
                    </>
                  ) : null}
                  {responseDataForLocal?.data?.email === null && secondVerified ? <span className="otpVerified">Verified</span> : null}
                </li>
                <li className="col-md-6">
                  <input
                    type="number"
                    className="form-control paddingRight"
                    placeholder="Phone Number"
                    value={vendorFormData.phoneNumber}
                    disabled={responseDataForLocal?.data?.mobile !== null || secondVerified}
                    onChange={e => handleInputChange("phoneNumber", e.target.value)}
                  />
                  {responseDataForLocal?.data?.mobile === null && vendorFormData?.phoneNumber?.length === 10 && !secondVerified ? (
                    <>
                      <button className="verifyLink" onClick={() => handleSendOtp("mobile")} disabled={verifyBtnDis}>
                        Verify
                      </button>
                    </>
                  ) : null}
                  {responseDataForLocal?.data?.mobile === null && secondVerified ? <span className="otpVerified">Verified</span> : null}
                </li>

                <li className="col-md-6">
                  <div className="upload-file-box">
                    <input type="file" className="input-file" accept="image/*" onChange={e => handleInputChange("vendorImage", e.target.files[0])} />
                    <input
                      type="text"
                      name=""
                      value={
                        vendorFormData?.vendorImage?.name?.length > 23
                          ? vendorFormData?.vendorImage?.name?.slice(0, 23) + "..."
                          : vendorFormData?.vendorImage?.name
                      }
                      // value={vendorFormData?.vendorImage?.name}
                      placeholder="Photo of authorize representative"
                      className="form-control disabled-form-control"
                      disabled
                    />
                    <button variant="primary" className="upload-btn">
                      Upload
                    </button>
                  </div>
                  {/* <span className="infoText">Upload a file in JPEG, PNG, or PDF format.</span> */}
                </li>

                <li className="col-md-6">
                  <input type="text" placeholder="PAN Number" className="form-control" onChange={e => handleInputChange("panNumber", e.target.value)} />
                </li>
                <li className="col-md-6">
                  <div className="upload-file-box">
                    <input
                      type="file"
                      className="input-file"
                      accept="image/*, application/pdf"
                      onChange={e => handleInputChange("panImage", e.target.files[0])}
                    />
                    <input
                      type="text"
                      name=""
                      value={
                        vendorFormData?.panImage?.name?.length > 23 ? vendorFormData?.panImage?.name?.slice(0, 23) + "..." : vendorFormData?.panImage?.name
                      }
                      // value={vendorFormData?.panImage?.name}

                      placeholder="PAN of authorize representative"
                      className="form-control disabled-form-control"
                      disabled
                    />
                    <button variant="primary" className="upload-btn">
                      Upload
                    </button>
                  </div>
                  <span className="infoText">Upload a file in JPEG, PNG, or PDF format.</span>
                </li>

                <li className="col-md-6">
                  <div className="upload-file-box">
                    <input
                      type="file"
                      className="input-file"
                      accept="image/*, application/pdf"
                      onChange={e => handleInputChange("authorizationLetter", e.target.files[0])}
                    />
                    <input
                      type="text"
                      name=""
                      value={
                        vendorFormData?.authorizationLetter?.name?.length > 23
                          ? vendorFormData?.authorizationLetter?.name?.slice(0, 23) + "..."
                          : vendorFormData?.authorizationLetter?.name
                      }
                      // value={vendorFormData?.panImage?.name}
                      placeholder="Authorization Document"
                      className="form-control disabled-form-control"
                      disabled
                    />
                    <button variant="primary" className="upload-btn">
                      Upload
                    </button>
                  </div>
                  <span className="infoText">
                    Please download the{" "}
                    <a href={`${IMAGE_URL + "authorization_letter.pdf"}`} target="_blank" download="Authorization_letter.pdf">
                      authorization letter
                    </a>
                    , sign it, and then upload your scanned document.
                  </span>
                </li>

                {/* {broadLocationArray?.map((item, index) => (
                  <>
                    <li className="col-md-6">
                      <select
                        className="form-select"
                        value={item.state} // Set the selected state value
                        onChange={e => handleStateChange(e, index)}>
                        <option>Select States</option>
                        {stateList?.map((stateItem, stateIndex) => (
                          <option key={stateIndex} value={stateItem.id}>
                            {stateItem.name}
                          </option>
                        ))}
                      </select>
                    </li>
                    <li className="col-md-6">
                      <select
                        className="form-select"
                        value={item.city} // Set the selected city value
                        onChange={e => handleCityChange(e, index)}>
                        <option>Select Cities</option>
                        {cityList?.map((cityItem, cityIndex) => (
                          <option key={cityIndex} value={cityItem.id}>
                            {cityItem.city}
                          </option>
                        ))}
                      </select>
                    </li>
                  </>
                ))} */}

                {/* <li className="col-md-12">
                  <button className="AddAnotherLocationButton" onClick={() => setBroadLocationArray([...broadLocationArray, {state: null, city: null}])}>
                    Add another location
                  </button>
                </li> */}
                {/* <li className="col-md-12">
                  <div className="form-check">
                    <input
                      className="form-check-input form-input-check"
                      type="checkbox"
                      id="flexCheckChecked"
                      checked={referralActive}
                      onChange={e => setReferralActive(e.target.checked)}
                    />

                    <label className="form-check-label LabelSpan" for="flexCheckChecked">
                      I have a referral code
                    </label>
                  </div>
                </li>
                {referralActive ? (
                  <li className="col-md-6">
                    <input
                      type="text"
                      placeholder="Enter Referral Code"
                      className="form-control"
                      onChange={e => handleInputChange("referrelCode", e.target.value)}
                    />
                  </li>
                ) : null} */}
                {/* <li className="col-md-12">
                  <hr />
                </li>
                <li className="col-md-12">
                  <h4>PAN Details</h4>
                </li> */}
                {/* <li className="col-md-6">
                  <div className="upload-file-box">
                    <input type="file" className="input-file" accept="image/*" onChange={e => handleInputChange("panImage", e.target.files[0])} />
                    <input
                      type="text"
                      name=""
                      value={
                        vendorFormData?.panImage?.name?.length > 23 ? vendorFormData?.panImage?.name?.slice(0, 23) + "..." : vendorFormData?.panImage?.name
                      }
                      // value={vendorFormData?.panImage?.name}

                      placeholder="Upload PAN"
                      className="form-control disabled-form-control"
                      disabled
                    />
                    <button variant="primary" className="upload-btn">
                      Upload
                    </button>
                  </div>
                </li>
                <li className="col-md-6">
                  <input type="text" placeholder="PAN Number" className="form-control" onChange={e => handleInputChange("panNumber", e.target.value)} />
                </li> */}
                <li className="col-md-12">
                  <hr />
                </li>
                <li className="col-md-12">
                  <h4>Organization Details</h4>
                  <p>Do you have GST?</p>
                  <ul className="row RadioList">
                    <li>
                      <div className="form-check" onClick={() => setGstDetailsAvail("yes")}>
                        <input
                          className="form-check-input form-input-radio"
                          type="radio"
                          name="flexRadioDefault1"
                          id="flexRadioDefault1"
                          checked={gstDetailsAvail === "yes" ? true : false}
                        />
                        <label className="form-check-label" for="flexRadioDefault1">
                          Yes
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check" onClick={() => setGstDetailsAvail("no")}>
                        <input
                          className="form-check-input form-input-radio"
                          type="radio"
                          name="flexRadioDefault1"
                          id="flexRadioDefault2"
                          checked={gstDetailsAvail === "no" ? true : false}
                        />
                        <label className="form-check-label" for="flexRadioDefault2">
                          No
                        </label>
                      </div>
                    </li>
                  </ul>
                </li>

                {gstDetailsAvail === "yes" ? (
                  <>
                    <li className="col-md-6">
                      <input
                        type="text"
                        placeholder="GST Number"
                        className="form-control"
                        onChange={e => {
                          handleInputChange("gstNumber", e.target.value);
                          handleGstNumberChange(e.target.value);
                        }}
                      />
                      {gstNumberError ? <span className="validationErrorMsg">{gstNumberError}</span> : null}
                    </li>
                    <li className="col-md-6">
                      <div className="upload-file-box">
                        <input
                          type="file"
                          className="input-file"
                          accept="image/*, application/pdf"
                          onChange={e => handleInputChange("gstCertificate", e.target.files[0])}
                        />
                        <input
                          type="text"
                          name=""
                          value={
                            vendorFormData?.gstCertificate?.name?.length > 23
                              ? vendorFormData?.gstCertificate?.name?.slice(0, 23) + "..."
                              : vendorFormData?.gstCertificate?.name
                          }
                          // value={vendorFormData?.gstCertificate?.name}
                          placeholder="GST Certificate"
                          className="form-control disabled-form-control"
                          disabled
                        />
                        <button variant="primary" className="upload-btn">
                          Upload
                        </button>
                      </div>
                      <span className="infoText">Upload a file in JPEG, PNG, or PDF format.</span>
                    </li>
                    <li className="col-md-6">
                      <input
                        type="number"
                        placeholder="GST Rate"
                        className="form-control"
                        onChange={e => {
                          handleInputChange("gstRate", e.target.value);
                        }}
                      />
                    </li>

                    {vendorFormData?.gstNumber?.length > 5 && !gstNumberError && !gstLoading ? (
                      <li className="col-md-12">
                        <div className="organization-sec">
                          {/* <hr />
                          <h4>Organization</h4> */}
                          <input
                            type="text"
                            placeholder="Organization Name"
                            className="form-control orgName"
                            value={vendorFormData?.organizationName}
                            readOnly
                            onChange={e => handleInputChange("organizationName", e.target.value)}
                          />
                          <ul className="row VendorRegistrationList">
                            <li className="col-md-6">
                              <input
                                type="text"
                                placeholder="Address"
                                readOnly
                                className="form-control"
                                value={vendorFormData?.address}
                                onChange={e => handleInputChange("address", e.target.value)}
                              />
                            </li>
                            <li className="col-md-6">
                              <input
                                type="text"
                                placeholder="Pincode"
                                readOnly
                                className="form-control"
                                value={vendorFormData?.pincode}
                                onChange={e => {
                                  handleInputChange("pincode", e.target.value);
                                  handlePincodeChange(e.target.value);
                                }}
                              />
                              {pincodeError ? <span className="validationErrorMsg">Please enter valid pincode.</span> : null}
                            </li>

                            {broadLocationArray?.map((item, index) => (
                              <>
                                <li className="col-md-6">
                                  <input type="text" placeholder="Country" className="form-control" value={item?.country} readOnly />
                                </li>
                                <li className="col-md-6">
                                  <input type="text" placeholder="State" className="form-control" value={item?.state} readOnly />
                                </li>
                                <li className="col-md-6">
                                  <input type="text" placeholder="City" className="form-control" value={item?.city} readOnly />
                                </li>
                              </>
                            ))}
                            <li className="col-md-6">
                              <input
                                type="text"
                                placeholder="PAN Number"
                                className="form-control"
                                onChange={e => handleInputChange("organizationPanNumber", e.target.value)}
                              />
                            </li>
                            <li className="col-md-6">
                              <div className="upload-file-box">
                                <input
                                  type="file"
                                  className="input-file"
                                  accept="image/*, application/pdf"
                                  onChange={e => handleInputChange("organizationPanImage", e.target.files[0])}
                                />
                                <input
                                  type="text"
                                  name=""
                                  value={
                                    vendorFormData?.organizationPanImage?.name?.length > 23
                                      ? vendorFormData?.organizationPanImage?.name?.slice(0, 23) + "..."
                                      : vendorFormData?.organizationPanImage?.name
                                  }
                                  // value={vendorFormData?.panImage?.name}

                                  placeholder="PAN of organization"
                                  className="form-control disabled-form-control"
                                  disabled
                                />
                                <button variant="primary" className="upload-btn">
                                  Upload
                                </button>
                              </div>
                              <span className="infoText">Upload a file in JPEG, PNG, or PDF format.</span>
                            </li>
                          </ul>
                          <ul className="row VendorRegistrationList">
                            <li className="col-md-12">
                              <ul className="row RadioList RadioList2">
                                <li>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input form-input-radio"
                                      type="radio"
                                      name="flexRadioDefault2"
                                      id="flexRadioDefault2"
                                      disabled={organizationType !== 1}
                                      checked={organizationType === 1 ? true : false}
                                    />
                                    <label className="form-check-label" for="flexRadioDefault3">
                                      Proprietorship
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input form-input-radio"
                                      type="radio"
                                      name="flexRadioDefault2"
                                      id="flexRadioDefault2"
                                      disabled={organizationType !== 2}
                                      checked={organizationType === 2 ? true : false}
                                    />
                                    <label className="form-check-label" for="flexRadioDefault4">
                                      Partnership
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input form-input-radio"
                                      type="radio"
                                      name="flexRadioDefault2"
                                      id="flexRadioDefault2"
                                      disabled={organizationType !== 3}
                                      checked={organizationType === 3 ? true : false}
                                    />
                                    <label className="form-check-label" for="flexRadioDefault5">
                                      Private Limited
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </li>
                          </ul>

                          {organizationType === 1 ? (
                            <>
                              <h5>Add Proprietor Details</h5>
                              <div className="gray-box">
                                <ul className="row VendorRegistrationList">
                                  <li className="col-md-12">
                                    <input
                                      type="text"
                                      placeholder="Name"
                                      className="form-control"
                                      onChange={e => handleInputChange("prosName", e.target.value)}
                                    />
                                  </li>
                                  <li className="col-md-6">
                                    <input
                                      type="tel"
                                      placeholder="Phone Number"
                                      className="form-control"
                                      onChange={e => handleInputChange("prosPhoneNumber", e.target.value)}
                                    />
                                  </li>
                                  <li className="col-md-6">
                                    <input
                                      type="text"
                                      placeholder="PAN Number"
                                      className="form-control"
                                      readOnly
                                      value={vendorFormData.panNumber}
                                      onChange={e => handleInputChange("prosPanNumber", e.target.value)}
                                    />
                                  </li>
                                  <li className="col-md-6">
                                    <input
                                      type="text"
                                      placeholder="Address"
                                      className="form-control"
                                      onChange={e => handleInputChange("prosAddress", e.target.value)}
                                    />
                                  </li>
                                  <li className="col-md-6">
                                    <div className="upload-file-box">
                                      <input
                                        type="file"
                                        className="input-file"
                                        accept="image/*"
                                        onChange={e => handleInputChange("prosPhoto", e.target.files[0])}
                                      />
                                      <input
                                        type="text"
                                        name=""
                                        value={
                                          vendorFormData?.prosPhoto?.name?.length > 23
                                            ? vendorFormData?.prosPhoto?.name?.slice(0, 23) + "..."
                                            : vendorFormData?.prosPhoto?.name
                                        }
                                        placeholder="Upload Photo"
                                        className="form-control disabled-form-control"
                                        disabled
                                      />
                                      <button variant="primary" className="upload-btn">
                                        Upload
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </>
                          ) : null}

                          {organizationType === 2 ? (
                            <>
                              <ul className="row VendorRegistrationList">
                                <li className="col-md-12">
                                  <div className="count-sec">
                                    <span className="LabelSpan">Number of Partners</span>
                                    <div className="Count-Box">
                                      <img src={MinusIcon} alt="" onClick={() => deletePartner(partnersArray.length - 1)} />
                                      <img src={PlusIcon} alt="" onClick={() => addPartner()} />
                                      <span className="CountSpan">{partnersArray?.length > 10 ? partnersArray?.length : "0" + partnersArray?.length}</span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <h5>Add Partner Details</h5>
                              {partnersArray?.map((item, index) => (
                                <div className="gray-box" key={index}>
                                  <ul className="row VendorRegistrationList">
                                    <li className="col-md-12">
                                      <h5>Partner {index + 1}</h5>
                                    </li>
                                    <li className="col-md-12">
                                      <input
                                        type="text"
                                        placeholder="Name"
                                        className="form-control"
                                        value={item.name || ""}
                                        onChange={e => handlePartnerChange(index, "name", e.target.value)}
                                      />
                                    </li>
                                    <li className="col-md-6">
                                      <input
                                        type="tel"
                                        placeholder="Phone Number"
                                        className="form-control"
                                        value={item.phoneNumber || ""}
                                        onChange={e => handlePartnerChange(index, "phoneNumber", e.target.value)}
                                      />
                                    </li>
                                    <li className="col-md-6">
                                      <input
                                        type="text"
                                        placeholder="PAN Number"
                                        className="form-control"
                                        value={item.panNumber || ""}
                                        onChange={e => handlePartnerChange(index, "panNumber", e.target.value)}
                                      />
                                    </li>
                                    <li className="col-md-6">
                                      <input
                                        type="text"
                                        placeholder="Address"
                                        className="form-control"
                                        value={item.address || ""}
                                        onChange={e => handlePartnerChange(index, "address", e.target.value)}
                                      />
                                    </li>
                                    <li className="col-md-6">
                                      <div className="upload-file-box">
                                        <input
                                          type="file"
                                          className="input-file"
                                          accept="image/*"
                                          onChange={e => handlePartnerChange(index, "photo", e.target.files[0])}
                                        />
                                        <input
                                          type="text"
                                          name=""
                                          value={item?.photo?.name?.length > 23 ? item?.photo?.name?.slice(0, 23) + "..." : item?.photo?.name}
                                          // value={item?.photo?.name || ""}
                                          placeholder="Upload Photo"
                                          className="form-control disabled-form-control"
                                          disabled
                                        />
                                        <button variant="primary" className="upload-btn">
                                          Upload
                                        </button>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              ))}
                            </>
                          ) : null}

                          {organizationType === 3 ? (
                            <>
                              <ul className="row VendorRegistrationList">
                                <li className="col-md-12">
                                  <div className="count-sec">
                                    <span className="LabelSpan">Number of Directors</span>
                                    <div className="Count-Box">
                                      <img src={MinusIcon} alt="" onClick={() => deletePrivateLimited(privateLimitedArray.length - 1)} />
                                      <img src={PlusIcon} alt="" onClick={() => addPrivateLimited()} />
                                      <span className="CountSpan">
                                        {privateLimitedArray?.length > 10 ? privateLimitedArray?.length : "0" + privateLimitedArray?.length}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <h5>Add Director Details</h5>
                              {privateLimitedArray?.map((item, index) => (
                                <div className="gray-box" key={index}>
                                  <ul className="row VendorRegistrationList">
                                    <li className="col-md-12">
                                      <h5>Director {index + 1}</h5>
                                    </li>
                                    <li className="col-md-12">
                                      <input
                                        type="text"
                                        placeholder="Name"
                                        className="form-control"
                                        value={item.name || ""}
                                        onChange={e => handleDirectorChange(index, "name", e.target.value)}
                                      />
                                    </li>
                                    <li className="col-md-6">
                                      <input
                                        type="tel"
                                        placeholder="Phone Number"
                                        className="form-control"
                                        value={item.phoneNumber || ""}
                                        onChange={e => handleDirectorChange(index, "phoneNumber", e.target.value)}
                                      />
                                    </li>
                                    <li className="col-md-6">
                                      <input
                                        type="text"
                                        placeholder="PAN Number"
                                        className="form-control"
                                        value={item.panNumber || ""}
                                        onChange={e => handleDirectorChange(index, "panNumber", e.target.value)}
                                      />
                                    </li>
                                    <li className="col-md-6">
                                      <input
                                        type="text"
                                        placeholder="Address"
                                        className="form-control"
                                        value={item.address || ""}
                                        onChange={e => handleDirectorChange(index, "address", e.target.value)}
                                      />
                                    </li>
                                    <li className="col-md-6">
                                      <div className="upload-file-box">
                                        <input
                                          type="file"
                                          className="input-file"
                                          accept="image/*"
                                          onChange={e => handleDirectorChange(index, "photo", e.target.files[0])}
                                        />
                                        <input
                                          type="text"
                                          name=""
                                          value={item?.photo?.name?.length > 23 ? item?.photo?.name?.slice(0, 23) + "..." : item?.photo?.name}
                                          placeholder="Upload Photo"
                                          className="form-control disabled-form-control"
                                          disabled
                                        />
                                        <button variant="primary" className="upload-btn">
                                          Upload
                                        </button>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              ))}
                            </>
                          ) : null}
                        </div>
                      </li>
                    ) : null}
                  </>
                ) : null}

                {gstDetailsAvail === "no" ? (
                  <li className="col-md-12">
                    <div className="organization-sec">
                      {/* <hr />
                      <h4>Organization</h4> */}
                      <input
                        type="text"
                        placeholder="Organization Name"
                        className="form-control orgName"
                        onChange={e => handleInputChange("organizationName", e.target.value)}
                      />
                      <ul className="row VendorRegistrationList">
                        <li className="col-md-6">
                          <input type="text" placeholder="Address" className="form-control" onChange={e => handleInputChange("address", e.target.value)} />
                        </li>
                        <li className="col-md-6">
                          <input
                            type="text"
                            placeholder="Pincode"
                            className="form-control"
                            onChange={e => {
                              handleInputChange("pincode", e.target.value);
                              handlePincodeChange(e.target.value);
                            }}
                          />
                          {pincodeError ? <span className="validationErrorMsg">Please enter valid pincode.</span> : null}
                        </li>
                        {/* <li className="col-md-12">
                          <h5>Select Broad Location</h5>
                        </li> */}

                        {broadLocationArray?.map((item, index) => (
                          <>
                            <li className="col-md-6">
                              <input type="text" placeholder="Country" className="form-control" value={item?.country} readOnly />
                              {/* <select className="form-select" value={item.state} onChange={e => handleStateChange(e, index)}>
                                <option>Select States</option>
                                {stateList?.map((stateItem, stateIndex) => (
                                  <option key={stateIndex} value={stateItem.id}>
                                    {stateItem.name}
                                  </option>
                                ))}
                              </select> */}
                            </li>
                            <li className="col-md-6">
                              <input type="text" placeholder="State" className="form-control" value={item?.state} readOnly />
                              {/* <select className="form-select" value={item.state} onChange={e => handleStateChange(e, index)}>
                                <option>Select States</option>
                                {stateList?.map((stateItem, stateIndex) => (
                                  <option key={stateIndex} value={stateItem.id}>
                                    {stateItem.name}
                                  </option>
                                ))}
                              </select> */}
                            </li>
                            <li className="col-md-6">
                              <input type="text" placeholder="City" className="form-control" value={item?.city} readOnly />
                              {/* <select className="form-select" value={item.city} onChange={e => handleCityChange(e, index)}>
                                <option>Select Cities</option>
                                {item.cities?.map((cityItem, cityIndex) => (
                                  <option key={cityIndex} value={cityItem.id}>
                                    {cityItem.city}
                                  </option>
                                ))}
                              </select> */}
                            </li>
                            <li className="col-md-6">
                              <input
                                type="text"
                                placeholder="PAN Number"
                                className="form-control"
                                onChange={e => handleInputChange("organizationPanNumber", e.target.value)}
                              />
                            </li>
                            <li className="col-md-6">
                              <div className="upload-file-box">
                                <input
                                  type="file"
                                  className="input-file"
                                  accept="image/*"
                                  onChange={e => handleInputChange("organizationPanImage", e.target.files[0])}
                                />
                                <input
                                  type="text"
                                  name=""
                                  value={
                                    vendorFormData?.organizationPanImage?.name?.length > 23
                                      ? vendorFormData?.organizationPanImage?.name?.slice(0, 23) + "..."
                                      : vendorFormData?.organizationPanImage?.name
                                  }
                                  // value={vendorFormData?.panImage?.name}

                                  placeholder="PAN of organization"
                                  className="form-control disabled-form-control"
                                  disabled
                                />
                                <button variant="primary" className="upload-btn">
                                  Upload
                                </button>
                              </div>
                              <span className="infoText">Upload a file in JPEG, PNG, or PDF format.</span>
                            </li>
                          </>
                        ))}
                      </ul>
                      <ul className="row VendorRegistrationList">
                        <li className="col-md-12">
                          <ul className="row RadioList RadioList2">
                            <li>
                              <div className="form-check" onClick={() => setOrganizationType(1)}>
                                <input
                                  className="form-check-input form-input-radio"
                                  type="radio"
                                  name="flexRadioDefault2"
                                  id="flexRadioDefault2"
                                  checked={organizationType === 1 ? true : false}
                                />
                                <label className="form-check-label" for="flexRadioDefault3">
                                  Proprietorship
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check" onClick={() => setOrganizationType(2)}>
                                <input
                                  className="form-check-input form-input-radio"
                                  type="radio"
                                  name="flexRadioDefault2"
                                  id="flexRadioDefault2"
                                  checked={organizationType === 2 ? true : false}
                                />
                                <label className="form-check-label" for="flexRadioDefault4">
                                  Partnership
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check" onClick={() => setOrganizationType(3)}>
                                <input
                                  className="form-check-input form-input-radio"
                                  type="radio"
                                  name="flexRadioDefault2"
                                  id="flexRadioDefault2"
                                  checked={organizationType === 3 ? true : false}
                                />
                                <label className="form-check-label" for="flexRadioDefault5">
                                  Private Limited
                                </label>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>

                      {organizationType === 1 ? (
                        <>
                          <h5>Add Proprietor Details</h5>
                          <div className="gray-box">
                            <ul className="row VendorRegistrationList">
                              <li className="col-md-12">
                                <input type="text" placeholder="Name" className="form-control" onChange={e => handleInputChange("prosName", e.target.value)} />
                              </li>
                              <li className="col-md-6">
                                <input
                                  type="tel"
                                  placeholder="Phone Number"
                                  className="form-control"
                                  onChange={e => handleInputChange("prosPhoneNumber", e.target.value)}
                                />
                              </li>
                              <li className="col-md-6">
                                <input
                                  type="text"
                                  placeholder="PAN Number"
                                  className="form-control"
                                  // readOnly
                                  value={vendorFormData.panNumber}
                                  onChange={e => handleInputChange("prosPanNumber", e.target.value)}
                                />
                              </li>
                              <li className="col-md-6">
                                <input
                                  type="text"
                                  placeholder="Address"
                                  className="form-control"
                                  onChange={e => handleInputChange("prosAddress", e.target.value)}
                                />
                              </li>
                              <li className="col-md-6">
                                <div className="upload-file-box">
                                  <input
                                    type="file"
                                    className="input-file"
                                    accept="image/*"
                                    onChange={e => handleInputChange("prosPhoto", e.target.files[0])}
                                  />
                                  <input
                                    type="text"
                                    name=""
                                    value={
                                      vendorFormData?.prosPhoto?.name?.length > 23
                                        ? vendorFormData?.prosPhoto?.name?.slice(0, 23) + "..."
                                        : vendorFormData?.prosPhoto?.name
                                    }
                                    placeholder="Upload Photo"
                                    className="form-control disabled-form-control"
                                    disabled
                                  />
                                  <button variant="primary" className="upload-btn">
                                    Upload
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </>
                      ) : null}

                      {organizationType === 2 ? (
                        <>
                          <ul className="row VendorRegistrationList">
                            <li className="col-md-12">
                              <div className="count-sec">
                                <span className="LabelSpan">Number of Partners</span>
                                <div className="Count-Box">
                                  <img src={MinusIcon} alt="" onClick={() => deletePartner(partnersArray.length - 1)} />
                                  <img src={PlusIcon} alt="" onClick={() => addPartner()} />
                                  <span className="CountSpan">{partnersArray?.length > 10 ? partnersArray?.length : "0" + partnersArray?.length}</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <h5>Add Partner Details</h5>
                          {partnersArray?.map((item, index) => (
                            <div className="gray-box" key={index}>
                              <ul className="row VendorRegistrationList">
                                <li className="col-md-12">
                                  <h5>Partner {index + 1}</h5>
                                </li>
                                <li className="col-md-12">
                                  <input
                                    type="text"
                                    placeholder="Name"
                                    className="form-control"
                                    value={item.name || ""}
                                    onChange={e => handlePartnerChange(index, "name", e.target.value)}
                                  />
                                </li>
                                <li className="col-md-6">
                                  <input
                                    type="tel"
                                    placeholder="Phone Number"
                                    className="form-control"
                                    value={item.phoneNumber || ""}
                                    onChange={e => handlePartnerChange(index, "phoneNumber", e.target.value)}
                                  />
                                </li>
                                <li className="col-md-6">
                                  <input
                                    type="text"
                                    placeholder="PAN Number"
                                    className="form-control"
                                    value={item.panNumber || ""}
                                    onChange={e => handlePartnerChange(index, "panNumber", e.target.value)}
                                  />
                                </li>
                                <li className="col-md-6">
                                  <input
                                    type="text"
                                    placeholder="Address"
                                    className="form-control"
                                    value={item.address || ""}
                                    onChange={e => handlePartnerChange(index, "address", e.target.value)}
                                  />
                                </li>
                                <li className="col-md-6">
                                  <div className="upload-file-box">
                                    <input
                                      type="file"
                                      className="input-file"
                                      accept="image/*"
                                      onChange={e => handlePartnerChange(index, "photo", e.target.files[0])}
                                    />
                                    <input
                                      type="text"
                                      name=""
                                      value={item?.photo?.name?.length > 23 ? item?.photo?.name?.slice(0, 23) + "..." : item?.photo?.name}
                                      // value={item?.photo?.name || ""}
                                      placeholder="Upload Photo"
                                      className="form-control disabled-form-control"
                                      disabled
                                    />
                                    <button variant="primary" className="upload-btn">
                                      Upload
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          ))}
                        </>
                      ) : null}

                      {organizationType === 3 ? (
                        <>
                          <ul className="row VendorRegistrationList">
                            <li className="col-md-12">
                              <div className="count-sec">
                                <span className="LabelSpan">Number of Directors</span>
                                <div className="Count-Box">
                                  <img src={MinusIcon} alt="" onClick={() => deletePrivateLimited(privateLimitedArray.length - 1)} />
                                  <img src={PlusIcon} alt="" onClick={() => addPrivateLimited()} />
                                  <span className="CountSpan">
                                    {privateLimitedArray?.length > 10 ? privateLimitedArray?.length : "0" + privateLimitedArray?.length}
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <h5>Add Director Details</h5>
                          {privateLimitedArray?.map((item, index) => (
                            <div className="gray-box" key={index}>
                              <ul className="row VendorRegistrationList">
                                <li className="col-md-12">
                                  <h5>Director {index + 1}</h5>
                                </li>
                                <li className="col-md-12">
                                  <input
                                    type="text"
                                    placeholder="Name"
                                    className="form-control"
                                    value={item.name || ""}
                                    onChange={e => handleDirectorChange(index, "name", e.target.value)}
                                  />
                                </li>
                                <li className="col-md-6">
                                  <input
                                    type="tel"
                                    placeholder="Phone Number"
                                    className="form-control"
                                    value={item.phoneNumber || ""}
                                    onChange={e => handleDirectorChange(index, "phoneNumber", e.target.value)}
                                  />
                                </li>
                                <li className="col-md-6">
                                  <input
                                    type="text"
                                    placeholder="PAN Number"
                                    className="form-control"
                                    value={item.panNumber || ""}
                                    onChange={e => handleDirectorChange(index, "panNumber", e.target.value)}
                                  />
                                </li>
                                <li className="col-md-6">
                                  <input
                                    type="text"
                                    placeholder="Address"
                                    className="form-control"
                                    value={item.address || ""}
                                    onChange={e => handleDirectorChange(index, "address", e.target.value)}
                                  />
                                </li>
                                <li className="col-md-6">
                                  <div className="upload-file-box">
                                    <input
                                      type="file"
                                      className="input-file"
                                      accept="image/*"
                                      onChange={e => handleDirectorChange(index, "photo", e.target.files[0])}
                                    />
                                    <input
                                      type="text"
                                      name=""
                                      value={item?.photo?.name?.length > 23 ? item?.photo?.name?.slice(0, 23) + "..." : item?.photo?.name}
                                      // value={item?.photo?.name || ""}
                                      placeholder="Upload Photo"
                                      className="form-control disabled-form-control"
                                      disabled
                                    />
                                    <button variant="primary" className="upload-btn">
                                      Upload
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          ))}
                        </>
                      ) : null}
                    </div>
                  </li>
                ) : null}

                <li className="col-md-12">
                  <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
                </li>

                <li className="col-md-12">
                  {loading ? (
                    <button className="btn btn-primary CmnBtn" disabled>
                      Please wait...
                    </button>
                  ) : (
                    <button className="btn btn-primary CmnBtn" onClick={() => handleFinalSubmit()}>
                      Submit
                    </button>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal show={onSendRequestSuccessPopup} className="CmnModal SendRequestModal">
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="congratsClass">
            <img src={VendorRegisteredSuccessIcon} alt="" className="SuccessIconImg" />
            <h2>Registration Received</h2>
            <p>
              Thank you for registering as a tour operator. We have received your application and are currently reviewing it. You will be notified once the
              review is complete. Thank you for your patience.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="modalFooterButton"
            onClick={() => {
              navigate("/business/profile");
              setOnSendRequestSuccessPopup(false);
            }}>
            Continue to your dashboard
          </button>
        </Modal.Footer>
      </Modal>

      <div className="hotelSliderPopup" style={{display: showOtpPopup ? "block" : "none"}}>
        <div className="sliderflex">
          <div className="popInner loginDetailsOtp loginDiv">
            <h2>OTP Verification</h2>
            {clickedType === "mobile" ? <h3>Check text messages for your OTP</h3> : <h3>Check email inbox for your OTP</h3>}
            {/* <h5>Your Otp is - {changeNumberResponse}</h5> */}
            <ul className="otpList">
              <li>
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={e => handleOtpInputChange(index, e)}
                    onKeyUp={e => handleBackspace(index, e)}
                    onPaste={handlePaste}
                    ref={inputRefs[index]}
                  />
                ))}
              </li>
              <li>00:{parseInt(timer) < 10 ? "0" + timer : timer}</li>
              <li>
                <h4>
                  Not Received OTP?{" "}
                  {parseInt(timer) === 0 ? (
                    <Link to="javascript:void(0);" onClick={() => handleResendOTP()}>
                      Resend Now
                    </Link>
                  ) : (
                    "Resend Now"
                  )}
                </h4>
                {validationError && <p className="validationErrorMsg">{validationError}</p>}
                {successMsg && <p className="successMsg">{successMsg}</p>}
              </li>
              <li>
                <button onClick={() => handleOtpSubmit(otp)}>Submit</button>
              </li>
            </ul>
            <button className="closePopupBtn" onClick={() => setShowOtpPopup(false)}>
              <img src={crossIcon2} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorRegistrationGstProvided;
