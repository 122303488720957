import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {getCustomerDetailsApi, upcomingBookingApi} from "../services/ApiService";
import {Helmet} from "react-helmet";
import {IMAGE_URL} from "../utils/commonUrls";
import { Form, Button, Dropdown, Tabs, Tab, Modal } from 'react-bootstrap';

const transferIcon2 = require("./../assets/img/car-icon2.svg").default;
const mealIcon2 = require("./../assets/img/restaurant-icon2.svg").default;
const hotelIcon2 = require("./../assets/img/bed-icon2.svg").default;
const optionalIcon2 = require("./../assets/img/optional-icon2.svg").default;
const special1 = require("./../assets/img/special1.png");
const trainIcon = require("./../assets/img/train.svg").default;
const binocularIcon = require("./../assets/img/binoculars.svg").default;

const QuotationDetailsSend = () => {
  const [key, setKey] = useState('tab1'); 

  const [customerData, setCustomerData] = useState();
  const [packageList, setPackageList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [searchPressed, setSearchPressed] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustomerData(response.data);
        }
      }
    } catch (error) {}
  };

  const handleSearchInputChange = event => {
    setSearchQuery(event.target.value);
  };

  const performSearch = () => {
    setSearchPressed(true);
    const filteredPackageList = packageList.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
    setSearchResult(filteredPackageList);
  };

  const getUpcomingBooking = async () => {
    setLoading(true);
    try {
      let response = await upcomingBookingApi();
      if (response) {
        if (response.data.res === true) {
          setPackageList(response.data.data);
          setSearchResult(response.data.data);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const formarDateFunc = date => {
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
    });
    return formattedDate;
  };

  useEffect(() => {
    getCustomerDetails();
    getUpcomingBooking();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Upcoming Tour`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Upcoming Tour page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="accountName">
              <h2>
                {customerData?.data?.first_name} {customerData?.data?.last_name}
              </h2>
              <h3>{customerData?.User?.mobile}</h3>
            </div>
            <div className="pointDiv">
              <h2>
                Wishmytour Points <span>0</span>
              </h2>
            </div>

            <div className="breadCmb">
              <p>
                <Link to="/profile">My Account</Link>Quotation <span>#951753</span>
              </p>
            </div>

            <div className="row QuotationDetailsBox">
              <div className="col-md-7">
                <div className="QuotationDetailsLeft">
                  <h3>Destinations and Sightseeing</h3>
                  <h4>Destinations</h4>
                  <ul className="TagsList">
                    <li><span>Shimla</span></li>
                    <li><span>Manali</span></li>
                    <li><span>Punjab</span></li>
                    <li><span>Dalhousie</span></li>
                  </ul>
                  <h4>Destinations</h4>
                  <ul className="TagsList">
                    <li><span>Ritz</span></li>
                    <li><span>Shimla Mall</span></li>
                    <li><span>Hirimba Temple</span></li>
                    <li><span>Rotang Pass</span></li>
                    <li><span>Kulu</span></li>
                    <li><span>Manikaran</span></li>
                    <li><span>Golden Temple</span></li>
                    <li><span>Jalianwalabag</span></li>
                    <li><span>Waga Border</span></li>
                  </ul>
                  <div className="MoreContentSec">
                    <h3>Rooms and Travellers</h3>
                    <ul className="row RoomDetailsList">
                      <li className="col-md-4">
                        <h4>No, of Rooms</h4>
                        <h5>02</h5>
                      </li>
                      <li className="col-md-4">
                        <h4>No, of Adults</h4>
                        <h5>04</h5>
                      </li>
                      <li className="col-md-4">
                        <h4>No, of Children</h4>
                        <h5>02</h5>
                      </li>
                    </ul>
                    <h3>Duration</h3>
                    <ul className="row RoomDetailsList">
                      <li className="col-md-4">
                        <h4>Tour Start Date</h4>
                        <h5>Jan 26, 2024</h5>
                      </li>
                      <li className="col-md-4">
                        <h4>Tour End Date</h4>
                        <h5>Feb 03, 2024</h5>
                      </li>
                    </ul>
                    <h3>Meal</h3>
                    <ul className="row RoomDetailsList">
                      <li className="col-md-4">
                        <h5>Breakfast</h5>
                      </li>
                      <li className="col-md-4">
                        <h5>Dinner</h5>
                      </li>
                    </ul>
                    <h3>Budget</h3>
                    <ul className="row RoomDetailsList">
                      <li className="col-md-4">
                        <h5>86,000 INR</h5>
                      </li>
                    </ul>
                  </div>
                  <a className="MoreContentLink">Less</a>
                </div>
              </div>
              <div className="col-md-5">
                <div className="QuotationDetailsRight">
                  <h3>Quote Information </h3>
                  <table className="table">
                    <tr>
                      <td>Quote ID:</td>
                      <td>#951753</td>
                    </tr>
                    <tr>
                      <td>Quote Created:</td>
                      <td>Jan 05, 2024</td>
                    </tr>
                    <tr>
                      <td>Status:</td>
                      <td><span className="ReceivedSpan">Send</span></td>
                    </tr>
                  </table>
                </div>
              </div>
          </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default QuotationDetailsSend;
