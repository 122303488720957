import React, {useState, useRef} from "react";
import {deleteHotelGalleryApi} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
const dragIcon = require("./../assets/img/drag-icon.svg").default;

const ImageUploader = ({sectionTitle, onImagesChange, propsPreviousImage, propsSection2PrevImages}) => {
  const [dragging, setDragging] = useState(false);
  const [images, setImages] = useState(propsPreviousImage ? propsPreviousImage : []);
  const [prevServerImages, setPrevServerImages] = useState(propsSection2PrevImages ? propsSection2PrevImages : []);
  const fileInputRef = useRef(null);

  const handleDragEnter = e => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = e => {
    e.preventDefault();
    setDragging(false);
    handleFiles(e.dataTransfer.files);
  };

  const handleFiles = files => {
    if (files.length + images.length > 5) {
      NotificationManager.warning(`You can upload a maximum of 5 images.`);
      return;
    }

    const newImages = Array.from(files).map(file => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    const allowedExtensions = ["png", "jpeg", "jpg"];
    const allowedDimension = 800;
    const maxSizeInBytes = 1024 * 1024; // 1mb

    const validateImage = image => {
      return new Promise((resolve, reject) => {
        const extension = image.file.name.split(".").pop().toLowerCase();

        // Check file extension
        if (!allowedExtensions.includes(extension)) {
          NotificationManager.warning(`File '${image.file.name}' has an invalid extension. Allowed extensions are png, jpeg, and jpg.`);
          resolve(false);
        }

        // Check image dimensions
        const img = new Image();
        img.onload = () => {
          // if (img.width < allowedDimension || img.height < allowedDimension) {
          //   NotificationManager.warning(`Image '${image.file.name}' dimensions should be ${allowedDimension}x${allowedDimension} pixels.`);
          //   resolve(false);
          // } else {
          // Check file size
          if (image.file.size > maxSizeInBytes) {
            NotificationManager.warning(`File '${image.file.name}' size exceeds the maximum allowed size of 400kb.`);
            resolve(false);
          } else {
            resolve(true);
          }
          // }
        };
        img.onerror = () => {
          resolve(false);
        };
        img.src = image.preview;
      });
    };

    const validatedImagesPromises = newImages.map(image => validateImage(image));

    Promise.all(validatedImagesPromises)
      .then(validatedResults => {
        const validatedImages = newImages.filter((_, index) => validatedResults[index]);
        setImages(prevImages => [...prevImages, ...validatedImages]);
        onImagesChange([...images, ...validatedImages]); // Trigger the callback
      })
      .catch(error => {
        console.error("Error validating images:", error);
      });
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = e => {
    handleFiles(e.target.files);
  };

  const handleRemoveImage = (e, index) => {
    e.stopPropagation(); // Prevent the file upload trigger
    setImages(prevImages => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      onImagesChange(updatedImages); // Trigger the callback with the updated state
      return updatedImages; // Return the updated array to setImages
    });
    NotificationManager.success("Image deleted successfully");
  };

  const handleRemovePrevImage = async (e, index) => {
    e.stopPropagation(); // Prevent the file upload trigger
    try {
      // Api call
      let response = await deleteHotelGalleryApi(images[index].id);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          setImages(prevImages => {
            const updatedImages = [...images];
            updatedImages.splice(index, 1);
            onImagesChange(updatedImages);
            return updatedImages;
          });
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {
      NotificationManager.error("Error deleting image");
    }
  };

  return (
    <div
      className={`dragUpload ${dragging ? "dragging" : ""}`}
      onDragOver={e => handleDragEnter(e)}
      onDragLeave={handleDragLeave}
      onDrop={e => handleDrop(e)}
      onClick={handleUploadClick}>
      <input type="file" ref={fileInputRef} style={{display: "none"}} multiple onChange={handleFileInputChange} />
      {prevServerImages.length > 0 && (
        <div className="uploadedImagesContainer">
          {prevServerImages.map((image, index) => (
            <div key={index} className="uploadedImage">
              <img src={image.path} alt={`Uploaded ${index + 1}`} />
              <button onClick={e => handleRemovePrevImage(e, index)}></button>
            </div>
          ))}
        </div>
      )}
      {images.length > 0 && (
        <div className="uploadedImagesContainer">
          {images.map((image, index) => (
            <div key={index} className="uploadedImage">
              <img src={image.preview} alt={`Uploaded ${index + 1}`} />
              <button onClick={e => handleRemoveImage(e, index)}></button>
            </div>
          ))}
        </div>
      )}
      {images.length === 0 && prevServerImages.length === 0 && (
        <div className="dragImg">
          <img src={dragIcon} alt="" />
          Drag and drop here or click to upload
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
