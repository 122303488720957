import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {customerRegisterApi, getCityListApi, getStatesListApi, updateOtpApi, verifyEmailOrMobileApi} from "../services/ApiService";
import {Typeahead} from "react-bootstrap-typeahead";
import {NotificationManager} from "react-notifications";
import {useUserContext} from "../context/UserContext";
import {Helmet} from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import {CAPTCHA_SITE_KEY, IMAGE_URL} from "../utils/commonUrls";

const google = require("./../assets/img/google-icon.svg").default;
const facebook = require("./../assets/img/facebook-icon2.svg").default;
const crossIcon2 = require("./../assets/img/cross.svg").default;

const Registration = () => {
  const navigate = useNavigate();
  const {from, responseDataForLocal} = useLocation().state ?? {};
  const {setUserData} = useUserContext();
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [cityVal, setCityVal] = useState();
  const [stateVal, setStateVal] = useState();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
    zipCode: "",
    gender: "",
    state: null,
    city: null,
  });
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
    zipCode: "",
    gender: "",
    state: null,
    city: null,
    captchaError: "",
  });
  const [responseStateValue, setResponseStateValue] = useState(useLocation().state?.responseValue ?? null);
  const [verifyOtpResponse, setVerifyOtpResponse] = useState(useLocation().state?.verifyOtpResponse ?? null);
  const [captchaChecked, setCaptchaChecked] = useState(false);
  const [secondVerified, setSecondVerified] = useState(false);
  const [verifyBtnDis, setVerifyBtnDis] = useState(false);
  const [clickedType, setClickedType] = useState();
  const [recountTimer, setRecountTimer] = useState(false);
  const [onSendRequestSuccessPopup, setOnSendRequestSuccessPopup] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [timer, setTimer] = useState(60);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [changeNumberResponse, setChangeNumberResponse] = useState();
  const [successMsg, setSuccessMsg] = useState("");

  const handleKeyPress = event => {
    if (event.key === "Enter" && !loading) {
      handleRegister();
    }
  };

  const getStateList = async () => {
    try {
      let response = await getStatesListApi();
      if (response) {
        if (response.data.res === true) {
          setStateList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getCityList = async stateId => {
    try {
      const formData = new FormData();
      if (stateId) {
        formData.append("state[]", stateId);
      }
      let response = await getCityListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setCityList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const handleStateChange = value => {
    setStateVal(value);
    getCityList(value[0]?.id);
  };

  const validateField = (field, value) => {
    // Perform validation based on your requirements
    switch (field) {
      case "firstName":
        return value.trim() === "" ? "Please enter your First Name." : "";
      case "lastName":
        return value.trim() === "" ? "Please enter your Last Name." : "";
      case "mobileNumber":
        return !/^\d{10}$/.test(value) ? "Please enter a valid 10-digit Mobile Number." : "";
      case "emailAddress":
        return !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(value) ? "Please enter a valid Email Address." : "";
      case "zipCode":
        return value.trim() === "" ? "Please enter your Zip Code." : "";
      case "gender":
        return value.trim() === "" ? "Please enter your Gender." : "";
      case "state":
        return !value || value.length === 0 ? "Please select a State." : "";
      case "city":
        return !value || value.length === 0 ? "Please select a City." : "";
      default:
        return "";
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);

      // Set focus on the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4);

    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      for (let i = 0; i < 4; i++) {
        newOtp[i] = pastedData[i] || "";
      }
      return newOtp;
    });

    if (pastedData?.length < 4) {
      inputRefs[pastedData?.length].current.focus();
    } else {
      inputRefs[3].current.focus();
    }

    // Check if all four digits are present in the pasted data
    if (pastedData.length === 4 && pastedData.match(/^\d{4}$/)) {
      let newValue = pastedData.split("");
      handleOtpSubmit(newValue);
    }
  };

  const handleOtpInputChange = (index, e) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    // Check if all OTP digits are entered
    if (index === inputRefs.length - 1 && newOtp.every(digit => digit !== "")) {
      setValidationError("");
      handleOtpSubmit(newOtp);
    }
  };

  const handleSendOtp = async type => {
    setClickedType(type);
    setRecountTimer(!recountTimer);
    if (type === "mobile") {
      if (formData.mobileNumber === "" || !/^\d{10}$/.test(formData.mobileNumber)) {
        NotificationManager.warning("Please enter a valid phone number.");
        return false;
      } else {
        const isValid = /^\d{10}$/.test(formData.mobileNumber);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid phone number.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let fd = new FormData();
            fd.append("user_id", responseDataForLocal?.data?.id);
            fd.append("mobile", formData.mobileNumber);
            setTimer(60);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                // setSecondVerified(true);
                inputRefs[0].current.focus();
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else if (type === "email") {
      if (formData.emailAddress === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress)) { 
        NotificationManager.warning("Please enter a valid Email Address.");
        return false;
      } else {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid email.");
        } else {
          setValidationError("");
          try {
            let fd = new FormData();
            fd.append("user_id", responseDataForLocal?.data?.id);
            fd.append("email", formData.emailAddress);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                setTimer(60);
                // setSecondVerified(true);
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else {
    }
  };

  const handleResendOTP = async () => {
    setRecountTimer(!recountTimer);
    let type = clickedType;
    if (type === "mobile") {
      if (formData.mobileNumber === "" || !/^\d{10}$/.test(formData.mobileNumber)) {
        NotificationManager.warning("Please enter a valid phone number.");
        return false;
      } else {
        const isValid = /^\d{10}$/.test(formData.mobileNumber);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid phone number.");
        } else {
          setValidationError("");
          setVerifyBtnDis(true);
          try {
            let fd = new FormData();
            fd.append("user_id", responseDataForLocal?.data?.id);
            fd.append("mobile", formData.mobileNumber);
            setTimer(60);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                // setSecondVerified(true);
                inputRefs[0].current.focus();
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else if (type === "email") {
      if (formData.emailAddress === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress)) {
        NotificationManager.warning("Please enter a valid Email Address.");
        return false;
      } else {
        const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress);

        if (!isValid) {
          NotificationManager.warning("Please enter a valid email.");
        } else {
          setValidationError("");
          try {
            let fd = new FormData();
            fd.append("user_id", responseDataForLocal?.data?.id);
            fd.append("email", formData.emailAddress);
            // Api call
            let response = await updateOtpApi(fd);
            if (response) {
              if (response.data.res === true) {
                // navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
                setChangeNumberResponse(response.data.otp);
                setShowOtpPopup(true);
                setTimer(60);
                // setSecondVerified(true);
              } else {
                NotificationManager.error(response.data.msg);
                // setValidationError(response.data.msg);
              }
              setVerifyBtnDis(false);
            }
          } catch (error) {
            setVerifyBtnDis(false);
          }
        }
        return true;
      }
    } else {
    }
  };

  const handleOtpSubmit = async otp => {
    if (otp.every(digit => digit === "")) {
      setValidationError("Please enter Your Otp.");
    } else {
      let newVal = otp.join("");
      setValidationError("");
      // setLoading(true);
      try {
        let fd = new FormData();
        fd.append("user_id", responseDataForLocal?.data?.id);
        if (responseDataForLocal?.data?.email) {
          fd.append("email", responseDataForLocal?.data?.email);
          fd.append("mobile", formData?.mobileNumber);
        }
        if (responseDataForLocal?.data?.mobile) {
          fd.append("mobile", responseDataForLocal?.data?.mobile);
          fd.append("email", formData?.emailAddress);
        }
        fd.append("otp", newVal);

        let response = await verifyEmailOrMobileApi(fd);
        if (response) {
          if (response.data.res === true) {
            setValidationError("");
            // setSuccessMsg(response.data.msg);
            NotificationManager.success(response.data.msg);
            setShowOtpPopup(false);
            setSecondVerified(true);
          } else {
            setValidationError(response.data.msg);
            setSuccessMsg("");
          }
        }
      } catch (error) {}
    }
  };

  const onCaptchaChange = value => {
    setCaptchaChecked(true);
    setValidationErrors(prevErrors => ({...prevErrors, captchaError: ""}));
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      firstName: "",
      lastName: "",
      mobileNumber: "",
      emailAddress: "",
      address: "",
      zipCode: "",
      gender: "",
      state: null,
      city: null,
      captchaError: "",
    };

    // if (formData.firstName === "") {
    //   errors.firstName = "Please enter your First Name.";
    //   isValid = false;
    // }

    // if (formData.lastName === "") {
    //   errors.lastName = "Please enter your Last Name.";
    //   isValid = false;
    // }

    // if (formData.mobileNumber === "" || !/^\d{10}$/.test(formData.mobileNumber)) {
    //   errors.mobileNumber = "Please enter a valid 10-digit Mobile Number.";
    //   isValid = false;
    // }

    // if (formData.emailAddress === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress)) {
    //   errors.emailAddress = "Please enter a valid Email Address.";
    //   isValid = false;
    // }

    // if (!formData.address) {
    //   errors.address = "Please enter your address.";
    //   isValid = false;
    // }

    // if (formData.zipCode === "") {
    //   errors.zipCode = "Please enter your Zip Code.";
    //   isValid = false;
    // }

    // if (formData.gender === "") {
    //   errors.gender = "Please enter your Gender.";
    //   isValid = false;
    // }

    // if (!formData.state || formData.state.length === 0) {
    //   errors.state = "Please select a State.";
    //   isValid = false;
    // }

    // if (!formData.city || formData.city.length === 0) {
    //   errors.city = "Please select a City.";
    //   isValid = false;
    // }

    if (responseDataForLocal?.data?.mobile && formData?.emailAddress && !secondVerified) {
      errors.emailAddress = `Please verify your email address.`;
      isValid = false;
    }

    if (responseDataForLocal?.data?.email && formData?.mobileNumber && !secondVerified) {
      errors.mobileNumber = `Please verify your phone number.`;
      isValid = false;
    }

    if (!captchaChecked) {
      errors.captchaError = "Please check the 'I am not a robot' checkbox";
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const handleInputChange = (field, value) => {
    // Update form data
    setFormData(prevData => ({...prevData, [field]: value}));

    // Validate the field
    setValidationErrors(prevErrors => ({...prevErrors, [field]: ""}));
    // setValidationErrors(prevErrors => ({...prevErrors, [field]: validateField(field, value)}));
  };

  const handleRegister = async () => {
    // Validate all fields before proceeding
    // const errors = {};
    // Object.keys(formData).forEach(field => {
    //   errors[field] = validateField(field, formData[field]);
    // });

    // // Update validation errors state
    // setValidationErrors(errors);

    // Check if any validation errors exist
    // if (Object.values(errors).every(error => error === "")) {
    if (validateForm()) {
      setLoading(true);
      try {
        let apiFormData = new FormData();
        apiFormData.append("user_id", responseStateValue.customer);
        apiFormData.append("first_name", formData.firstName ? formData.firstName : "");
        apiFormData.append("last_name", formData.lastName ? formData.lastName : "");
        apiFormData.append("state", formData.state ? formData.state : "");
        apiFormData.append("city", formData.city ? formData.city : "");
        apiFormData.append("zipcode", formData.zipCode ? formData.zipCode : "");
        apiFormData.append("gender", formData.gender ? formData.gender : "");
        apiFormData.append("email", formData.emailAddress ? formData.emailAddress : "");
        apiFormData.append("address", formData.address ? formData.address : "");
        apiFormData.append("mobile", formData.mobileNumber ? formData.mobileNumber : "");
        // Api call
        let response = await customerRegisterApi(apiFormData);
        if (response) {
          if (response.data.res === true) {
            responseDataForLocal.data.email = formData.emailAddress;
            responseDataForLocal.data.mobile = formData.mobileNumber;
            localStorage.setItem("WMTUserData", JSON.stringify(responseDataForLocal));
            setUserData(response.data);
            if (from) {
              navigate(from);
            } else {
              navigate("/");
            }
            NotificationManager.success(response.data.msg);
          } else {
            setValidationError(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        NotificationManager.error("Something went wrong. Please try again later.");
      }
    }
  };

  // Resend Counter useEffect
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [recountTimer]);

  useEffect(() => {
    if (showOtpPopup) {
      inputRefs[0].current.focus();
    }
  }, [showOtpPopup]);

  useEffect(() => {
    getStateList();
    getCityList();
    if (verifyOtpResponse) {
      let tempFormData = {...formData};
      if (verifyOtpResponse?.email !== null) {
        tempFormData.emailAddress = verifyOtpResponse?.email;
      } else if (verifyOtpResponse?.mobile !== null) {
        tempFormData.mobileNumber = verifyOtpResponse?.mobile;
      } else {
      }
      setFormData(tempFormData);
    }
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Wish My Tour`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Home page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="loginDiv">
            <h2>Registration</h2>

            <ul className="loginList">
              <li>
                <input
                  type="text"
                  onKeyDown={handleKeyPress}
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={e => handleInputChange("firstName", e.target.value)}
                />
                {validationErrors.firstName && <span className="validationErrorMsg">{validationErrors.firstName}</span>}
              </li>
              <li>
                <input
                  type="text"
                  onKeyDown={handleKeyPress}
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={e => handleInputChange("lastName", e.target.value)}
                />
                {validationErrors.lastName && <span className="validationErrorMsg">{validationErrors.lastName}</span>}
              </li>
              <li>
                <input
                  type="text"
                  className="form-control paddingRight"
                  onKeyDown={handleKeyPress}
                  placeholder="Mobile Number"
                  value={formData.mobileNumber}
                  disabled={verifyOtpResponse?.mobile !== null || secondVerified}
                  onChange={e => handleInputChange("mobileNumber", e.target.value)}
                />
                {responseDataForLocal?.data?.mobile === null && formData?.mobileNumber?.length === 10 && !secondVerified ? (
                  <>
                    <button className="verifyLink" onClick={() => handleSendOtp("mobile")} disabled={verifyBtnDis}>
                      Verify
                    </button>
                  </>
                ) : null}
                {responseDataForLocal?.data?.mobile === null && secondVerified ? <span className="otpVerified">Verified</span> : null}
                {validationErrors.mobileNumber && <span className="validationErrorMsg">{validationErrors.mobileNumber}</span>}
              </li>
              <li>
                <input
                  type="text"
                  onKeyDown={handleKeyPress}
                  placeholder="Email Address"
                  value={formData.emailAddress}
                  disabled={verifyOtpResponse?.email !== null}
                  onChange={e => handleInputChange("emailAddress", e.target.value)}
                />
                {responseDataForLocal?.data?.email === null && !secondVerified && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.emailAddress) ? (
                  <>
                    <button className="verifyLink" onClick={() => handleSendOtp("email")} disabled={verifyBtnDis}>
                      Verify
                    </button>
                  </>
                ) : null}
                {responseDataForLocal?.data?.email === null && secondVerified ? <span className="otpVerified">Verified</span> : null}
                {validationErrors.emailAddress && <span className="validationErrorMsg">{validationErrors.emailAddress}</span>}
              </li>
              <li>
                <input
                  type="text"
                  onKeyDown={handleKeyPress}
                  placeholder="Address"
                  value={formData.address}
                  onChange={e => handleInputChange("address", e.target.value)}
                />
                {validationErrors.address && <span className="validationErrorMsg">{validationErrors.address}</span>}
              </li>
              <li>
                {/* Assuming Typeahead is used for State selection */}
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  onChange={selected => {
                    handleInputChange("state", selected?.[0]?.id);
                    handleStateChange(selected);
                    setFormData(prev => ({...prev, city: null}));
                    setCityVal([]);
                  }}
                  onInputChange={text => {
                    if (!text) {
                      handleInputChange("state", null);
                    } else {
                      const matchingState = stateList.find(state => state.name.toLowerCase() === text.toLowerCase());
                      if (matchingState) {
                        handleInputChange("state", matchingState.id);
                        handleStateChange([matchingState]);
                        setFormData(prev => ({...prev, city: null}));
                        setCityVal([]);
                      } else {
                        handleInputChange("state", null);
                      }
                    }
                  }}
                  options={stateList}
                  placeholder="State"
                  selected={stateVal}
                />
                {validationErrors.state && <span className="validationErrorMsg">{validationErrors.state}</span>}
              </li>
              <li>
                {/* Assuming Typeahead is used for City selection */}
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="city"
                  onChange={selected => {
                    handleInputChange("city", selected?.[0]?.id);
                    setCityVal(selected);
                  }}
                  onInputChange={text => {
                    if (!text) {
                      handleInputChange("city", null);
                    } else {
                      const matchingCity = cityList.find(city => city.city.toLowerCase() === text.toLowerCase());
                      if (matchingCity) {
                        handleInputChange("city", matchingCity.id);
                      } else {
                        handleInputChange("city", null);
                      }
                    }
                  }}
                  options={cityList}
                  placeholder="City"
                  selected={cityVal}
                />
                {validationErrors.city && <span className="validationErrorMsg">{validationErrors.city}</span>}
              </li>
              <li>
                <input
                  type="text"
                  onKeyDown={handleKeyPress}
                  placeholder="Zip Code"
                  value={formData.zipCode}
                  onChange={e => handleInputChange("zipCode", e.target.value)}
                />
                {validationErrors.zipCode && <span className="validationErrorMsg">{validationErrors.zipCode}</span>}
              </li>
              <li>
                <select placeholder="Gender" value={formData.gender} onChange={e => handleInputChange("gender", e.target.value)}>
                  <option hidden selected>
                    Gender
                  </option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                  <option value={"other"}>Other</option>
                </select>
                {/* <input
                  type="text"
                  onKeyDown={handleKeyPress}
                  placeholder="Gender"
                  value={formData.gender}
                  onChange={e => handleInputChange("gender", e.target.value)}
                /> */}
                {validationErrors.gender && <span className="validationErrorMsg">{validationErrors.gender}</span>}
              </li>
              <li>
                <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
                {validationErrors.captchaError && <span className="validationErrorMsg">{validationErrors.captchaError}</span>}
              </li>
              <li>
                {loading ? <button disabled>Please wait...</button> : <button onClick={() => handleRegister()}>Register Now</button>}
                {validationError && <p className="validationErrorMsg">{validationError}</p>}
              </li>
            </ul>

            <div className="orDiv">
              <span>Or Login/Signup With</span>
            </div>
            <p>
              <Link to="/">
                <img src={google} alt="" />
              </Link>
              <Link to="/">
                <img src={facebook} alt="" />
              </Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />

      <div className="hotelSliderPopup" style={{display: showOtpPopup ? "block" : "none"}}>
        <div className="sliderflex">
          <div className="popInner loginDetailsOtp loginDiv">
            <h2>OTP Verification</h2>
            {clickedType === "mobile" ? <h3>Check text messages for your OTP</h3> : <h3>Check email inbox for your OTP</h3>}
            {/* <h5>Your Otp is - {changeNumberResponse}</h5> */}
            <ul className="otpList">
              <li>
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={e => handleOtpInputChange(index, e)}
                    onKeyUp={e => handleBackspace(index, e)}
                    onPaste={handlePaste}
                    ref={inputRefs[index]}
                  />
                ))}
              </li>
              <li>00:{parseInt(timer) < 10 ? "0" + timer : timer}</li>
              <li>
                <h4>
                  Not Received OTP?{" "}
                  {parseInt(timer) === 0 ? (
                    <Link to="javascript:void(0);" onClick={() => handleResendOTP()}>
                      Resend Now
                    </Link>
                  ) : (
                    "Resend Now"
                  )}
                </h4>
                {validationError && <p className="validationErrorMsg">{validationError}</p>}
                {successMsg && <p className="successMsg">{successMsg}</p>}
              </li>
              <li>
                <button onClick={() => handleOtpSubmit(otp)}>Submit</button>
              </li>
            </ul>
            <button className="closePopupBtn" onClick={() => setShowOtpPopup(false)}>
              <img src={crossIcon2} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
