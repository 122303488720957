import React, {Component, useState, useRef, useEffect} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import RangeSliderComponent from "../component/RangeSliderComponent";
import {Link, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {loginApi, vendorLoginApi} from "../services/ApiService";
import {CAPTCHA_SITE_KEY} from "../utils/commonUrls";
import ReCAPTCHA from "react-google-recaptcha";

import google from "../assets/img/google-icon.svg";
import facebook from "../assets/img/facebook-icon2.svg";
import VendorRegistrationLogo from "../assets/img/vendor-registration-logo.svg";

const VendorLoginEmail = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaChecked, setCaptchaChecked] = useState(false);

  const handleInputChange = e => {
    setEmail(e.target.value);
  };

  const handleKeyPress = event => {
    if (event.key === "Enter" && !loading) {
      handleContinueWithOTP();
    }
  };

  const onCaptchaChange = value => {
    setCaptchaChecked(true);
    setValidationError("");
  };

  const handleContinueWithOTP = async () => {
    const isValid = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(email);

    if (!email) {
      setValidationError(`Please enter email address.`);
    } else if (!isValid) {
      setValidationError("Please enter a valid email.");
    } else if (!captchaChecked) {
      setValidationError(`Please check the 'I am not a robot' checkbox.`);
    } else {
      setValidationError("");
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("email", email);
        // Api call
        let response = await vendorLoginApi(formData);
        if (response) {
          if (response.data.res === true) {
            navigate("/business/login/email/verify-otp", {state: {loginValue: email, responseValue: response.data.data}});
          } else {
            setValidationError(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div className="main">
      <div className="innerDiv RegistrationInnerDiv">
        <div className="mainDiv">
          <div className="VendorRegistrationLogoSec">
            <Link to={"/"}>
              <img src={VendorRegistrationLogo} alt="" />
            </Link>
            <div className="loginDiv">
              <h3>Tour Operator Registration</h3>
              <h2>Enter Your Email</h2>
              <ul className="loginList">
                <li>
                  <input type="text" onKeyDown={handleKeyPress} placeholder="Email" value={email} onChange={handleInputChange} />
                </li>
                <li>
                  <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
                </li>
                <li>
                  {loading ? <button disabled>Please wait...</button> : <button onClick={() => handleContinueWithOTP()}>Continue with OTP</button>}
                  {validationError && <p className="validationErrorMsg">{validationError}</p>}
                </li>
                <li>
                  <Link className="chooseTypeLink" to={"/business/login/phone"}>
                    Login With Phone Number
                  </Link>
                </li>
                <p className="privacyText">
                  By proceeding, you agree to WishMyTour's{" "}
                  <Link to="/privacy-policy" target="_blank">
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link to="/terms-of-use" target="_blank">
                    T&Cs
                  </Link>
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorLoginEmail;
