import React, {Component, useState, useRef, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useUserContext} from "../context/UserContext";
import {
  addWishlistApi,
  getBookingDetailsApi,
  getPackageDetailsApi,
  getRelatedPackagesApi,
  getTaxDetailsApi,
  getVendoronRequestDetailsApi,
  vendorOnRequestApproveApi,
} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import StarRatings from "react-star-ratings";
import ImageUploader from "./../component/ImageUploader";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Helmet} from "react-helmet";
import {IMAGE_URL} from "../utils/commonUrls";
import {Form, Button, Dropdown, Tabs, Tab, Modal} from "react-bootstrap";
import LoadingPage from "../component/loadingPage";
import {TimeConverter} from "../utils/commonFunctions";

const arrow = require("./../assets/img/left-arrow.svg").default;
const flightIcon = require("./../assets/img/flight-icon.svg").default;
const flightIcon2 = require("./../assets/img/plane-icon.svg").default;
const localIcon = require("./../assets/img/train-icon.svg").default;
const hotelIcon = require("./../assets/img/hotel-icon.svg").default;
const mealIcon = require("./../assets/img/meal-icon.svg").default;
const sightIcon = require("./../assets/img/sight-icon.svg").default;
const trainIcon = require("./../assets/img/train-icon2.svg").default;
const trainIcon2 = require("./../assets/img/train-icon.svg").default;
const hotelImg = require("./../assets/img/hotel-img.png");
const ratingImg = require("./../assets/img/star-icon.svg").default;
const carImg = require("./../assets/img/local-icon.svg").default;
const crossIcon = require("./../assets/img/cross-icon.svg").default;
const socialIcons = require("./../assets/img/social-icons.svg").default;
const crossIcon2 = require("./../assets/img/cross.svg").default;
const carIcon = require("./../assets/img/car-icon3.svg").default;

const VendorOnRequestQuoteDetails = () => {
  const [key, setKey] = useState("tab1");
  const {packageId, bookingId} = useParams();
  const {userData, wishListCount, setWishListCount} = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [packageDetails, setPackageDetails] = useState();
  const [dateSelectedByClick, setdateSelectedByClick] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [wishBtn, setWishBtn] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  const [section2Images, setSection2Images] = useState([]);
  const [section1Images, setSection1Images] = useState([]);
  const [editingValues, setEditingValues] = useState({});
  const [allDetails, setAllDetails] = useState();
  const [priceDetails, setPriceDetails] = useState({
    basePrice: 0,
    addOnPrice: 0,
    taxPrice: 0,
    gstTax: 0,
    tcsTax: 0,
    gstTaxIncludePrice: 0,
    tcsTaxIncludePrice: 0,
    bulkDiscountPrice: 0,
    subTotalPrice: 0,
    platformCharges: 0,
    platformChargesIncludePrice: 0,
    tdsCharges: 0,
    tdsIncludePrice: 0,
    netPayAmt: 0,
    totalCancelledPersonCount: 0,
    totalCancelledPrice: 0,
    totalCancellationChargePrice: 0,
    totalGstPrice: 0,
    afterCancelPrice: 0,
    totalRefundAmount: 0,
  });
  const [passengersArray, setPassengersArray] = useState([]);
  const [taxDetails, setTaxDetails] = useState();
  const [roomDetails, setRoomDetails] = useState({roomCount: 0, adultCount: 0, childCount: 0});
  const [accBtnDis, setAccBtnDis] = useState(false);

  const addHotel = () => {
    setShowPopup(true);
  };
  const addLocal = () => {
    setShowPopup2(true);
  };

  const handleImagesChange = (newImages, sectionTitle) => {
    if (sectionTitle === "Section 1") {
      setSection1Images(newImages);
    } else if (sectionTitle === "Section 2") {
      setSection2Images(newImages);
      setEditingValues({
        ...editingValues,
        hotelImages: newImages,
      });
      setSection2Images([]);
    }
    // You can perform any other necessary actions here if needed
  };

  //-------------------------Package Itinerary starts-------------------------
  const itinerary = [
    {
      day: "Day-1",
      title: "Leh From: Day-1",
      subTitle: "Day 1 - Arrive Leh : 3505 meters / 11567 feet",
      content:
        "Arrive Kushok Bakula airport Leh - 3500m above sea level. Transfer to hotel. Breakfast at the hotel before 09.30 am. Half Day at rest for acclimatization. Lunch at the hotel. After Lunch drive to Visit Shanti Stupa & Leh Palace, Later in the evening walk around local market. Overnight stay at the Hotel.",
      details: [
        {
          type: "Flight",
          name: "Air India",
          code: "AI 445",
          station: "New Delhi",
          classCategory: "Economy",
          destination: "Leh",
        },
        {
          type: "Train",
          name: "Rajdhani Express",
          code: "12301",
          station: "Howrah",
          classCategory: "3 tier AC",
          destination: "NDLS",
        },
        {
          type: "Sightseeing",
          morning: "Morning: Visit Shanti Stupa & Leh Palace",
          afternoon: "Afternoon: Visit Shanti..",
        },
        {
          type: "Hotel",
          desc: "Check In For 1 Nights",
          hotelName: "Tiaraa Hotels and Resorts Manali",
          afternoon: "Afternoon: Visit Shanti..",
          //images: ["url_of_image_1.jpg", "url_of_image_2.jpg"],
        },
        {
          type: "Meal",
          breakfast: "Breakfast",
          lunch: "Lunch",
          dinner: "Dinner",
        },
        {
          type: "Local Transport",
          seat: "4 Seater",
          carName: "Swift Dezire",
        },
      ],
    },
    {
      day: "Day-2",
      title: "Leh From: Day-2",
      subTitle: "Day 2 - Arrive Leh : 3505 meters / 11567 feet",
      content:
        "Arrive Kushok Bakula airport Leh - 3500m above sea level. Transfer to hotel. Breakfast at the hotel before 09.30 am. Half Day at rest for acclimatization. Lunch at the hotel. After Lunch drive to Visit Shanti Stupa & Leh Palace, Later in the evening walk around local market. Overnight stay at the Hotel.",
      details: [
        {
          type: "Flight",
          name: "Air India",
          code: "AI 445",
          station: "New Delhi",
          classCategory: "Economy",
          destination: "Leh",
        },
        {
          type: "Train",
          name: "Rajdhani Express",
          code: "12301",
          station: "Howrah",
          classCategory: "3 tier AC",
          destination: "NDLS",
        },
        {
          type: "Sightseeing",
          morning: "Morning: Visit Shanti Stupa & Leh Palace",
          afternoon: "Afternoon: Visit Shanti..",
        },
        {
          type: "Hotel",
          desc: "Check In For 1 Nights",
          hotelName: "Tiaraa Hotels and Resorts Manali",
          afternoon: "Afternoon: Visit Shanti..",
          //images: ["url_of_image_1.jpg", "url_of_image_2.jpg"],
        },
        {
          type: "Meal",
          breakfast: "Breakfast",
          lunch: "Lunch",
          dinner: "Dinner",
        },
        {
          type: "Local Transport",
          seat: "4 Seater",
          carName: "Swift Dezire",
        },
      ],
    },
    {
      day: "Day-3",
      title: "Leh From: Day-3",
      subTitle: "Day 3 - Arrive Leh : 3505 meters / 11567 feet",
      content:
        "Arrive Kushok Bakula airport Leh - 3500m above sea level. Transfer to hotel. Breakfast at the hotel before 09.30 am. Half Day at rest for acclimatization. Lunch at the hotel. After Lunch drive to Visit Shanti Stupa & Leh Palace, Later in the evening walk around local market. Overnight stay at the Hotel.",
      details: [
        {
          type: "Flight",
          name: "Air India",
          code: "AI 445",
          station: "New Delhi",
          classCategory: "Economy",
          destination: "Leh",
        },
        {
          type: "Train",
          name: "Rajdhani Express",
          code: "12301",
          station: "Howrah",
          classCategory: "3 tier AC",
          destination: "NDLS",
        },
        {
          type: "Sightseeing",
          morning: "Morning: Visit Shanti Stupa & Leh Palace",
          afternoon: "Afternoon: Visit Shanti..",
        },
        {
          type: "Hotel",
          desc: "Check In For 1 Nights",
          hotelName: "Tiaraa Hotels and Resorts Manali",
          afternoon: "Afternoon: Visit Shanti..",
          //images: ["url_of_image_1.jpg", "url_of_image_2.jpg"],
        },
        {
          type: "Meal",
          breakfast: "Breakfast",
          lunch: "Lunch",
          dinner: "Dinner",
        },
        {
          type: "Local Transport",
          seat: "4 Seater",
          carName: "Swift Dezire",
        },
      ],
    },
  ];

  const initialAccordionState = Array.from({length: itinerary.length}, (_, index) => index === 0);
  const [accordionOpen, setAccordionOpen] = useState(initialAccordionState);

  const toggleAccordion = index => {
    setAccordionOpen(prev => prev.map((isOpen, i) => (i === index ? !isOpen : false)));
  };

  const hotelSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
  };

  //-------------------------Package Itinerary Ends-------------------------

  //-------------------------Smooth Scroll Starts-------------------------
  const [activeLink, setActiveLink] = useState(null);
  const photosRef = useRef(null);
  const packageRef = useRef(null);
  const departureRef = useRef(null);
  const inclusionsRef = useRef(null);
  const exclusionsRef = useRef(null);
  const termsRef = useRef(null);

  const scrollToSection = (ref, link) => {
    ref.current.scrollIntoView({behavior: "smooth"});
    setActiveLink(link);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust as needed
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setActiveLink("photos");
      }
    }, options);

    if (photosRef.current) {
      observer.observe(photosRef.current);
    }

    // Clean up the observer
    return () => {
      if (photosRef.current) {
        observer.unobserve(photosRef.current);
      }
    };
  }, [photosRef]);
  //-------------------------Smooth Scroll Ends-------------------------

  //-------------------------Share Popup Starts-------------------------
  const [selectedShareIndex, setSelectedShareIndex] = useState(0);
  const [showSharePopup, setShowSharePopup] = useState(false);

  const openSharePopup = index => {
    setSelectedShareIndex(index);
    setShowSharePopup(true);

    // Copy the current URL to the clipboard
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(
      () => {
        // console.log("URL successfully copied to clipboard:", currentUrl);
      },
      error => {
        console.error("Unable to copy URL to clipboard", error);
      }
    );
  };

  const closeSharePopup = () => {
    setShowSharePopup(false);
  };
  //-------------------------Share Popup Ends-------------------------

  //-------------------------Query Popup Starts-------------------------
  const [selectedQueryIndex, setSelectedQueryIndex] = useState(0);
  const [showQueryPopup, setShowQueryPopup] = useState(false);
  const openQueryPopup = index => {
    setSelectedQueryIndex(index);
    setShowQueryPopup(true);
  };

  const closeQueryPopup = () => {
    setShowQueryPopup(false);
  };
  //-------------------------Query Popup Ends-------------------------

  //-------------------------Developement code Starts-------------------------

  // Calculate minDate as the current date
  const minDate = new Date();

  // Calculate maxDate as the same month next year
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  const formattedDateFunc = date => {
    const originalDateString = date;

    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);

    // Months array for mapping month indexes to month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get the month, day, and year from the parsed date
    const monthIndex = originalDate.getMonth();
    const day = originalDate.getDate();
    const year = originalDate.getFullYear();

    // Format the date string
    const formattedDateString = `${day < 10 ? "0" + day : day} ${months[monthIndex]},  ${year}`;
    return formattedDateString;
  };

  const getBookingDetails = async () => {
    setScreenLoading(true);
    try {
      let response = await getVendoronRequestDetailsApi(bookingId);
      if (response) {
        if (response.data.res === true) {
          // let parsedRoomDetails = JSON.parse(response.data.data.booking.room_details);
          // response.data.data.booking.room_details = parsedRoomDetails;
          setAllDetails(response.data.data);
          setPriceDetails(prev => ({
            ...prev,
            basePrice: response.data.data.booking?.final_price,
          }));

          let tempArray = [];

          if (response.data.data.booking?.room_details?.length > 0) {
            response.data.data.booking?.room_details?.map((rItem, rIndex) => {});
          }

          const roomCount = response.data.data.booking?.room_details.length;
          const adultCount = response.data.data.booking?.room_details.map(room => parseInt(room.adults, 10)).reduce((acc, val) => acc + val, 0);
          const childCount = response.data.data.booking?.room_details.map(room => parseInt(room.children, 10)).reduce((acc, val) => acc + val, 0);

          setRoomDetails({roomCount, adultCount, childCount});

          response.data.data.booking_rooms.map((roomItem, roomIndex) => {
            const roomData = {room: "Room " + (roomIndex + 1), adults: [], children: []};
            response.data.data.booking_passengers.map((passItem, passIndex) => {
              if (roomItem.id === passItem.booking_room_id) {
                const passenger = {
                  title: passItem.title,
                  first_name: passItem.first_name,
                  last_name: passItem.last_name,
                  dob: passItem.dob,
                  gender: passItem.gender,
                  status: passItem.status,
                };

                if (passItem.is_adult === 1) {
                  roomData.adults.push(passenger);
                } else {
                  roomData.children.push(passenger);
                }
              }
            });
            tempArray.push(roomData);
          });

          setPassengersArray(tempArray);

          let basePrice = response.data.data.booking.final_price;
          let addOnPrice = response.data.data.booking.addon_total_price;
          let taxes = 0;
          let gstTax = parseInt(response.data.data.booking.gst_percent);
          let tcsTax = 0;
          let taxIncludePrice = 0;
          let gstTaxIncludePrice = 0;
          let tcsTaxIncludePrice = 0;
          let bulkDiscountPrice = 0;
          let subTotal = 0;
          let platformCharges = parseInt(response.data.data.booking.website_percent);
          let platformChargesIncludePrice = 0;
          let tdsCharges = 0;
          let tdsIncludePrice = 0;
          let netPayAmt = 0;
          let totalCancelledPersonCount = 0;
          let totalCancelledPrice = 0;
          let totalCancellationChargePrice = 0;
          let totalGstPrice = 0;
          let afterCancelPrice = 0;
          let totalRefundAmount = 0;

          try {
            const formData = new FormData();
            formData.append("package_id", response.data.data.booking.package_id);
            let response1 = await getTaxDetailsApi(formData);
            if (response1 && response1.data.res === true) {
              setTaxDetails(response1.data.data);
              tdsCharges = response1.data.data.tds.value ? parseInt(response1.data.data.tds.value) : 0;
              // gstTax = response1.data.data.gst.value ? parseInt(response1.data.data.gst.value) : 0;
              // tcsTax = response1.data.data.tcs.value ? parseInt(response1.data.data.tcs.value) : 0;
              // platformCharges = response1.data.data.platform_charges.value ? parseInt(response1.data.data.platform_charges.value) : 0;
            }
          } catch (error) {
            console.error("Error fetching package details:", error);
          }

          response.data?.data?.bookingCancellation?.map((item, index) => {
            totalCancelledPersonCount += parseInt(item?.no_of_pax);
            totalCancelledPrice += parseFloat(item?.pax_charge);
            totalCancellationChargePrice += parseFloat(item?.cancellation_charge);
            totalGstPrice += parseFloat(item?.gst_charge);
            totalRefundAmount += parseFloat(item?.refund_amount);
          });

          if (addOnPrice) {
            basePrice = parseFloat(basePrice) + parseFloat(addOnPrice);
          }

          if (totalCancelledPersonCount) {
            afterCancelPrice = parseFloat(basePrice) - parseFloat(totalCancelledPrice) - parseFloat(totalCancellationChargePrice) - parseFloat(totalGstPrice);
          }

          taxes = gstTax + tcsTax;

          if (taxes > 0) {
            taxIncludePrice = parseFloat(basePrice) + (parseFloat(basePrice) * taxes) / 100;
          }
          if (gstTax > 0) {
            gstTaxIncludePrice = (parseFloat(basePrice) * gstTax) / 100;
          }
          if (tcsTax > 0) {
            tcsTaxIncludePrice = (parseFloat(basePrice) * tcsTax) / 100;
          }

          subTotal = parseFloat(taxes ? taxIncludePrice : basePrice);

          let redeemVendorBasePrice = totalRefundAmount
            ? parseFloat(response.data.data.booking.final_price) - totalRefundAmount
            : parseFloat(response.data.data.booking.final_price);

          console.log(redeemVendorBasePrice);

          if (tdsCharges > 0) {
            tdsIncludePrice = (parseFloat(redeemVendorBasePrice) * tdsCharges) / 100;
          }

          platformChargesIncludePrice = (parseFloat(redeemVendorBasePrice) * platformCharges) / 100;

          netPayAmt = parseFloat(redeemVendorBasePrice) - parseFloat(platformChargesIncludePrice);
          // netPayAmt = parseFloat(redeemVendorBasePrice) - parseFloat(platformChargesIncludePrice) - parseFloat(tdsIncludePrice);

          setPriceDetails(prev => ({
            ...prev,
            basePrice: parseFloat(basePrice).toFixed(2),
            taxPrice: taxes,
            gstTax: gstTax,
            tcsTax: tcsTax,
            gstTaxIncludePrice: parseFloat(gstTaxIncludePrice).toFixed(2),
            tcsTaxIncludePrice: parseFloat(tcsTaxIncludePrice).toFixed(2),
            subTotalPrice: parseFloat(subTotal).toFixed(2),
            platformCharges: platformCharges,
            platformChargesIncludePrice: parseFloat(platformChargesIncludePrice).toFixed(2),
            netPayAmt: parseFloat(netPayAmt).toFixed(2),
            tdsCharges: tdsCharges,
            tdsIncludePrice: parseFloat(tdsIncludePrice).toFixed(2),
            totalCancelledPersonCount: totalCancelledPersonCount,
            totalCancelledPrice: parseFloat(totalCancelledPrice).toFixed(2),
            totalCancellationChargePrice: parseFloat(totalCancellationChargePrice).toFixed(2),
            totalGstPrice: parseFloat(totalGstPrice).toFixed(2),
            afterCancelPrice: parseFloat(afterCancelPrice).toFixed(2),
            totalRefundAmount: parseFloat(totalRefundAmount).toFixed(2),
          }));
        } else {
        }
        setScreenLoading(false);
      }
    } catch (error) {
      setScreenLoading(false);
    }
  };

  const handleAccept = async () => {
    setAccBtnDis(true);
    try {
      let formData = new FormData();
      formData.append("id", bookingId);
      // Api call
      let response = await vendorOnRequestApproveApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          getBookingDetails();
        } else {
          NotificationManager.warning(response.data.msg);
        }
        setAccBtnDis(false);
      }
    } catch (error) {
      setAccBtnDis(false);
    }
  };

  useEffect(() => {
    getBookingDetails();
  }, [packageId]);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Quotations`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Quotation List Tour page" />
      </Helmet>
      <VenderHeader />
      <div className="venderInnerDiv">
        <VenderSidebar />
        <div className="venderRight">
          {screenLoading && <LoadingPage />}
          <div className="hdrSec">
            <h2>
              <Button variant="link" onClick={() => navigate("/business/custom-tours")}>
                <img src={arrow} alt="" />
              </Button>
              On Request Quote Details
            </h2>
          </div>

          <div className="onreqTop">
            <div className="venderMain">
              <div className="vendarTop onreDtls">
                <Tabs id="controlled-tabs" activeKey={key} onSelect={k => setKey(k)}>
                  <Tab eventKey="tab1" title="Customer Details">
                    <div className="QuotationListTableSec">
                      <ul>
                        <li>
                          <label>Customer Name:</label>
                          {allDetails?.customer?.first_name} {allDetails?.customer?.last_name}
                        </li>
                        <li>
                          <label>Email:</label>
                          {allDetails?.customer?.email}
                        </li>
                        <li>
                          <label>Address:</label>
                          {allDetails?.customer?.address}
                        </li>
                        <li>
                          <label>State:</label>
                          {allDetails?.customer?.state_name}
                        </li>
                        <li>
                          <label>Phone No:</label>
                          {allDetails?.customer?.mobile}
                        </li>
                      </ul>
                    </div>
                  </Tab>
                  <Tab eventKey="tab2" title="Tour Package Details">
                    <div className="QuotationListTableSec">
                      <ul>
                        <li>
                          <label>Package ID:</label>
                          <span className="rdClr">#{allDetails?.booking?.package_id}</span>
                        </li>
                        <li>
                          <label>Tour Package:</label>
                          <Link to="">{allDetails?.package_name}</Link>
                        </li>
                        <li>
                          <label>Tour Start Date:</label>
                          {formattedDateFunc(allDetails?.booking?.start_date)}
                        </li>
                        <li>
                          <label>Duration:</label>
                          {allDetails?.total_days}
                        </li>
                        <li>
                          <label>Adult(s):</label>
                          {roomDetails?.adultCount}
                        </li>
                        {roomDetails?.childCount > 0 ? (
                          <li>
                            <label>Children(s):</label>
                            {roomDetails?.childCount}
                          </li>
                        ) : null}
                        <li>
                          <label>Room(s):</label>
                          {roomDetails?.roomCount}
                        </li>
                      </ul>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>

            <div className="venderMain">
              <div className="quoteInfo">
                <h3>Quotation Information</h3>
                <ul>
                  <li>
                    <label>Quote ID:</label>
                    <span className="rdClr">#{allDetails?.booking?.id}</span>
                  </li>
                  <li>
                    <label>Quote Date:</label>
                    {formattedDateFunc(allDetails?.booking?.created_at)}
                  </li>
                  <li>
                    <label>Status:</label>
                    {allDetails?.booking?.status === 0 ? (
                      <span className="ylClr">Received</span>
                    ) : allDetails?.booking?.status === 1 ? (
                      <span className="greenColorSts">Sent</span>
                    ) : null}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="venderMain onrequestDay">
            <div className="overviewDiv package" ref={packageRef}>
              <h2>Reply Quote</h2>

              <div className="itinerayDiv">
                <h4 className="updteHdn">Update Itinerary Information</h4>
                <h5>Total Days of Tour: 06</h5>
                <div className="itaContent">
                  {allDetails &&
                    allDetails?.itinerary?.map((item, index) => (
                      <div className="accordion" key={index}>
                        <div className={`accHeader ${accordionOpen[index] ? "open" : ""}`}>
                          <h2>
                            Day {index + 1} - {item?.place_city_name?.city}
                          </h2>
                          <button className="accordion-btn" onClick={() => toggleAccordion(index)}></button>
                        </div>
                        {accordionOpen[index] && (
                          <div className="accordion-content">
                            <h3>{item.itinerary_title}</h3>
                            <p>{item.itinerary_description}</p>
                            <ul>
                              {item?.flights?.length > 0 ? (
                                <li className="Flight">
                                  <div className="accoInner">
                                    <div className="transportDiv">
                                      <img src={flightIcon} alt="" />
                                      Flight
                                    </div>

                                    {/* <div className="transportName">
                                        <h4>{ detail.name}</h4>
                                        <p>{detail.code}</p>
                                      </div> */}

                                    <div className="stationName">
                                      <h4>{item?.flights[0]?.depart_destination.id}</h4>
                                      <h4>{TimeConverter(item?.flights[0]?.depart_datetime)}</h4>
                                    </div>

                                    <div className="classCategory">
                                      <img src={flightIcon2} alt="" />
                                      <h4>{item?.flights[0]?.class}</h4>
                                    </div>

                                    <div className="destinationName">
                                      <h4>{item?.flights[0]?.arrive_destination.id}</h4>
                                      <h4>{TimeConverter(item?.flights[0]?.arrive_datetime)}</h4>
                                    </div>
                                  </div>
                                </li>
                              ) : (
                                ""
                              )}
                              {item?.trains?.length > 0 ? (
                                <li className="Train">
                                  <div className="accoInner">
                                    <div className="transportDiv">
                                      <img src={trainIcon2} alt="" />
                                      Train
                                    </div>

                                    <div className="transportName">
                                      <h4>{item?.trains[0]?.train_name}</h4>
                                      <p>{item?.trains[0]?.train_number}</p>
                                    </div>

                                    <div className="stationName">
                                      <h4>{item?.trains[0]?.depart_destination.id}</h4>
                                      <h4>{TimeConverter(item?.trains[0]?.depart_datetime)}</h4>
                                      {/* <h4>{item?.trains[0]?.depart_destination.name}</h4> */}
                                    </div>

                                    <div className="classCategory">
                                      <img src={trainIcon} alt="" />
                                      <h4>{item?.trains[0]?.class}</h4>
                                    </div>

                                    <div className="destinationName">
                                      <h4>{item?.trains[0]?.arrive_destination.id}</h4>
                                      <h4>{TimeConverter(item?.trains[0]?.arrive_datetime)}</h4>
                                      {/* <h4>{item?.trains[0]?.arrive_destination.name}</h4> */}
                                    </div>
                                  </div>
                                </li>
                              ) : (
                                ""
                              )}
                              {item?.hotels?.length > 0 ? (
                                <li className="Hotel">
                                  <div className="accoInner">
                                    <div className="hotelHdnDiv">
                                      <img src={hotelIcon} alt="" />
                                      <div className="nameDesc">
                                        Hotel
                                        <p>{item?.hotels[0]?.description}</p>
                                      </div>
                                    </div>

                                    <div className="hotelSlider">
                                      {/* <Slider {...hotelSliderSettings}>
                                        {detail.images.map(
                                          (image, imgIndex) => (
                                            <div key={imgIndex}>
                                              <img
                                                src={image}
                                                alt={`Hotel Image ${
                                                  imgIndex + 1
                                                }`}
                                              />
                                            </div>
                                          )
                                        )}
                                      </Slider> */}
                                      <Slider {...hotelSliderSettings}>
                                        {item?.hotels[0]?.hotel_gallery?.map((hotelItem, hotelIndex) => (
                                          <div key={hotelIndex}>
                                            <img
                                              src={IMAGE_URL + hotelItem?.path}
                                              alt=""
                                              // onClick={() => openHotelPopup(item?.hotels[0]?.hotel_gallery, hotelIndex)}
                                            />
                                          </div>
                                        ))}
                                      </Slider>
                                    </div>

                                    <div className="hotelDtls">
                                      {/* <img src={ratingImg} alt="" /> */}
                                      <StarRatings
                                        rating={item?.hotels[0]?.star} // Initial rating value
                                        starRatedColor="#F8B84E" // Color of the filled stars
                                        numberOfStars={5} // Total number of stars
                                        name="rating" // Unique name for the component
                                        starDimension="18px"
                                        starSpacing="2px"
                                      />
                                      <h3>{item?.hotels[0]?.hotel_name} or Similar</h3>
                                      {item?.hotels[0]?.is_other_place === 1 ? (
                                        <div className="nameDesc">
                                          <p>
                                            {item?.hotels[0]?.place_name} | {item?.hotels[0]?.distance_from_main_town}
                                          </p>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </li>
                              ) : (
                                ""
                              )}
                              {item?.meal !== null ? (
                                <li className="Meal">
                                  <div className="accoInner">
                                    <div className="transportDiv">
                                      <img src={mealIcon} alt="" />
                                      Meal
                                    </div>
                                    {item?.meal?.split(",")?.map((mealItem, mealIndex) => (
                                      <div className="mealServeDiv" key={mealIndex}>
                                        <h4 style={{textTransform: "capitalize"}}> {mealItem}</h4>
                                      </div>
                                    ))}
                                  </div>
                                </li>
                              ) : (
                                ""
                              )}
                              {item?.sightseeing?.length > 0 ? (
                                <li className="Sightseeing">
                                  <div className="accoInner sgtAco">
                                    <div className="transportDiv">
                                      <img src={sightIcon} alt="" />
                                      Sightseeing
                                    </div>
                                    <div className="trSight">
                                      {item?.sightseeing[0]?.morning ? (
                                        <div className="sightDiv">
                                          <h4>{item?.sightseeing[0]?.morning}</h4>
                                        </div>
                                      ) : null}
                                      {item?.sightseeing[0]?.afternoon ? (
                                        <div className="sightDiv">
                                          <h4>{item?.sightseeing[0]?.afternoon}</h4>
                                        </div>
                                      ) : null}
                                      {item?.sightseeing[0]?.evening ? (
                                        <div className="sightDiv">
                                          <h4>{item?.sightseeing[0]?.evening}</h4>
                                        </div>
                                      ) : null}
                                      {item?.sightseeing[0]?.night ? (
                                        <div className="sightDiv">
                                          <h4>{item?.sightseeing[0]?.night}</h4>
                                        </div>
                                      ) : null}
                                    </div>
                                    {item?.sightseeing[0]?.sightseeing_gallery?.length > 0 ? (
                                      <div className="hotelSlider">
                                        <Slider {...hotelSliderSettings}>
                                          {item?.sightseeing[0]?.sightseeing_gallery?.map((hotelItem, hotelIndex) => (
                                            <div key={hotelIndex}>
                                              <img
                                                src={IMAGE_URL + hotelItem?.path}
                                                alt=""
                                                // onClick={() => openHotelPopup(item?.sightseeing[0]?.sightseeing_gallery, hotelIndex)}
                                              />
                                            </div>
                                          ))}
                                        </Slider>
                                      </div>
                                    ) : null}
                                  </div>
                                </li>
                              ) : (
                                ""
                              )}
                              {item?.local_transport?.length > 0 ? (
                                <li className="Local Transport">
                                  <div className="accoInner">
                                    <div className="transportDiv localTrans">
                                      <img src={carImg} alt="" />
                                      Local Transport
                                    </div>

                                    <div className="transportName">
                                      <h4>Car</h4>
                                      <p>{item?.local_transport[0]?.capacity} Seater</p>
                                    </div>

                                    <div className="carName">
                                      <img src={carIcon} alt="" />
                                      {item?.local_transport[0]?.car} {item?.local_transport[0]?.model}
                                    </div>
                                  </div>
                                </li>
                              ) : (
                                ""
                              )}

                              {/* <li className="Hotel">
                                <div className="accoInner">
                                  <div className="hotelHdnDiv">
                                    <img src={hotelIcon} alt="" />
                                    <div className="nameDesc">
                                      Hotel
                                      <p>{item?.hotels[0]?.description}</p>
                                    </div>
                                  </div>

                                  <div className="onBtns">
                                    <button className="addHotel" onClick={() => addHotel()}>
                                      Add Hotel
                                    </button>
                                  </div>
                                </div>
                              </li>

                              <li className="Local Transport">
                                <div className="accoInner">
                                  <div className="transportDiv localTrans">
                                    <img src={carImg} alt="" />
                                    Local Transport
                                  </div>

                                  <div className="onBtns">
                                    <button className="addVehicle" onClick={() => addLocal()}>
                                      Add Vehicle
                                    </button>
                                  </div>
                                </div>
                              </li> */}
                            </ul>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>

            {/* <div className="addForm">
              <h3>Pricing</h3>
              <div className="ItineraryLoop">
                <h4 className="bscPrc">Basic Price</h4>
                <ul className="formList">
                  <li>
                    <label>Package Cost Per Adult (above 12 yrs) - Twin Sharing</label>
                    <input type="number" placeholder="20,000" />
                    <span className="symbl">INR</span>
                  </li>
                  <li>
                    <label>Discount for Child (5 to 12 yrs)</label>
                    <input type="number" placeholder="10000" />
                    <span className="symbl">INR</span>
                  </li>
                  <li>
                    <label>Additional cost for Single occupancy</label>
                    <input type="number" placeholder="5000" />
                    <span className="symbl">INR</span>
                  </li>
                </ul>
              </div>
            </div> */}

            {allDetails?.booking?.status === 0 ? (
              <>
                <button className="onReqDecline">Decline</button>

                <button className="onReqSubmit" disabled={accBtnDis} onClick={() => handleAccept()}>
                  Accept
                </button>
              </>
            ) : null}

            {showPopup && (
              <div className={`popup addPopup`}>
                <div className="sliderflex addHotelPop">
                  <div className="popInner2">
                    <h3 className="addHdnHtl">Add Hotel</h3>
                    <ul>
                      <li>
                        <input type="text" value="" placeholder="Name of Hotel / Resort" />
                      </li>
                      <li className="fullLIst ratingSlct">
                        <h3>
                          Rate this Hotel
                          <StarRatings rating={5} starRatedColor="yellow" numberOfStars={5} name={`rating`} starDimension="25px" starSpacing="2px" />
                        </h3>
                      </li>
                      <li className="fullLIst">
                        <h4>Add Hotel Photos</h4>
                        <ImageUploader sectionTitle="Section 1" onImagesChange={images => handleImagesChange(images, "Section 1")} />

                        <p className="dragtxt2">
                          Images size should be below 1mb for each. Allowed file extensions are (png, jpeg, and jpg). Maximum 5 images are allowed.
                        </p>
                      </li>

                      <li className="fullLIst">
                        <button className="addBtn">Add</button>
                      </li>
                    </ul>

                    <button className="closePopupBtn2" onClick={() => setShowPopup(false)}>
                      <img src={crossIcon2} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            )}

            {showPopup2 && (
              <div className={`popup addPopup`}>
                <div className="sliderflex addHotelPop">
                  <div className="popInner2">
                    <h3 className="addHdnHtl">Add Local Transport</h3>
                    <ul>
                      <li>
                        <select>
                          <option selected hidden>
                            Transport
                          </option>
                          <option>Car</option>
                          <option>Jeep</option>
                          <option>Traveller</option>
                          <option>Bus</option>
                        </select>
                      </li>

                      <li>
                        <input type="text" value="" placeholder="Model" />
                      </li>
                      <li>
                        <select>
                          <option selected hidden>
                            Capacity
                          </option>
                          <option>4 Seater</option>
                          <option>7 Seater</option>
                          <option>14 Seater</option>
                        </select>
                      </li>
                      <li>
                        <div class="checkboxes-and-radios">
                          <input type="checkbox" name="" />
                          <label>AC</label>
                          <input type="checkbox" name="" value="" />
                        </div>
                      </li>
                      <li className="fullLIst">
                        <button className="addBtn">Add</button>
                      </li>
                    </ul>

                    <button className="closePopupBtn2" onClick={() => setShowPopup2(false)}>
                      <img src={crossIcon2} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorOnRequestQuoteDetails;
