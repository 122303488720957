import React, {Component, useState, useRef, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";

import {NotificationManager} from "react-notifications";
import {useNavigate, useParams} from "react-router";
import {Helmet} from "react-helmet";
import {Form, Button, Dropdown} from "react-bootstrap";
import DataTable from "react-data-table-component";
import dotsImg from "../assets/img/3dots.svg";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {confirmBookingApi, getBookingDetailsApi, getTaxDetailsApi, vendorCancelApproveApi} from "../services/ApiService";
import {useStateManager} from "react-select";
import {useUserContext} from "../context/UserContext";
import LoadingPage from "../component/loadingPage";
import StarRatings from "react-star-ratings";
import {TimeConverter} from "../utils/commonFunctions";

const arrow = require("./../assets/img/left-arrow.svg").default;
const reviewImg = require("./../assets/img/review-img.svg").default;

const VendorBookingDetails = () => {
  const navigate = useNavigate();
  const {bookingId} = useParams();
  const {userData} = useUserContext();

  const [allDetails, setAllDetails] = useState();
  const [taxDetails, setTaxDetails] = useState();
  const [screenLoading, setScreenLoading] = useState(false);
  const [confirmBtnDis, setConfirmBtnDis] = useState(false);
  const [priceDetails, setPriceDetails] = useState({
    basePrice: 0,
    addOnPrice: 0,
    taxPrice: 0,
    gstTax: 0,
    tcsTax: 0,
    gstTaxIncludePrice: 0,
    tcsTaxIncludePrice: 0,
    bulkDiscountPrice: 0,
    subTotalPrice: 0,
    platformCharges: 0,
    platformChargesIncludePrice: 0,
    tdsCharges: 0,
    tdsIncludePrice: 0,
    netPayAmt: 0,
    totalCancelledPersonCount: 0,
    totalCancelledPrice: 0,
    totalCancellationChargePrice: 0,
    totalGstPrice: 0,
    afterCancelPrice: 0,
    totalRefundAmount: 0,
    gstTcsPercent: 0,
    gstTcsIncludePrice: 0,
    paidAmount: null,
    refundToVendorPlatformCharge: 0,
    refundToVendorPlatformChargeGst: 0,
    refundToVendorGSTCharge: 0,
    refundToVendorGSTTcsCharge: 0,
    refundToVendorTdsCharge: 0,
    netPayableToVendor: 0,
  });
  const [passengersArray, setPassengersArray] = useState([]);

  const formatDateFormat = date => {
    const originalDateString = date;

    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);

    // Months array for mapping month indexes to month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get the month, day, and year from the parsed date
    const monthIndex = originalDate.getMonth();
    const day = originalDate.getDate();
    const year = originalDate.getFullYear();

    // Format the date string
    const formattedDateString = `${months[monthIndex]} ${day}, ${year}`;
    return formattedDateString;
  };

  const formatDateFormat1 = date1 => {
    const dateString = date1;

    const date = new Date(dateString);

    // Format the date as "January 26, 2024"
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

    // Format the time as "05:00 AM"
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;
    return formattedDate;
  };

  const formatTimeFormat1 = date1 => {
    const dateString = date1;

    const date = new Date(dateString);

    // Format the date as "January 26, 2024"
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

    // Format the time as "05:00 AM"
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;

    return formattedTime;
  };

  function calculateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - dob.getFullYear();

    // Check if the birthday hasn't occurred yet in the current year
    if (currentDate.getMonth() < dob.getMonth() || (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  }

  const getBookingDetails = async () => {
    setScreenLoading(true);
    try {
      let response = await getBookingDetailsApi(bookingId);
      if (response) {
        if (response.data.res === true) {
          setAllDetails(response.data.data);
          setPriceDetails(prev => ({
            ...prev,
            basePrice: response.data.data.booking?.final_price,
          }));

          let tempArray = [];

          response.data.data.booking_rooms.map((roomItem, roomIndex) => {
            const roomData = {room: "Room " + (roomIndex + 1), adults: [], children: []};
            response.data.data.booking_passengers.map((passItem, passIndex) => {
              if (roomItem.id === passItem.booking_room_id) {
                const passenger = {
                  title: passItem.title,
                  first_name: passItem.first_name,
                  last_name: passItem.last_name,
                  dob: passItem.dob,
                  gender: passItem.gender,
                  status: passItem.status,
                };

                if (passItem.is_adult === 1) {
                  roomData.adults.push(passenger);
                } else {
                  roomData.children.push(passenger);
                }
              }
            });
            tempArray.push(roomData);
          });

          setPassengersArray(tempArray);

          let basePrice = response.data.data.booking.base_amount;
          let addOnPrice = response.data.data.booking.addon_total_price;
          let taxes = 0;
          let gstTax = parseInt(response.data.data.booking.gst_percent);
          let tcsTax = 0;
          let taxIncludePrice = 0;
          let gstTaxIncludePrice = 0;
          let tcsTaxIncludePrice = 0;
          let bulkDiscountPrice = 0;
          let subTotal = 0;
          let platformCharges = parseInt(response.data.data.booking.website_percent);
          let platformChargesIncludePrice = 0;
          let tdsCharges = 0;
          let tdsIncludePrice = 0;
          let netPayAmt = 0;
          let totalCancelledPersonCount = 0;
          let totalCancelledPrice = 0;
          let totalCancellationChargePrice = 0;
          let totalGstPrice = 0;
          let afterCancelPrice = 0;
          let totalRefundAmount = 0;
          let paidAmount = 0;
          let gstTcsPercent = 0;
          let gstTcsIncludePrice = 0;
          let refundToVendorPlatformCharge = 0;
          let refundToVendorPlatformChargeGst = 0;
          let refundToVendorGSTCharge = 0;
          let refundToVendorGSTTcsCharge = 0;
          let refundToVendorTdsCharge = 0;
          let netPayableToVendor = 0;

          if (response.data?.data?.booking?.payments?.length > 0) {
            response.data?.data?.booking?.payments?.map(item => {
              paidAmount += parseFloat(item?.paid_amount);
            });
          } else {
            paidAmount = 0;
          }

          try {
            const formData = new FormData();
            formData.append("package_id", response.data.data.booking.package_id);
            let response1 = await getTaxDetailsApi(formData);
            if (response1 && response1.data.res === true) {
              setTaxDetails(response1.data.data);
              tdsCharges = response1.data.data.tds.value ? parseInt(response1.data.data.tds.value) : 0;
              gstTcsPercent = response1.data.data.gst_tcs.value ? parseInt(response1.data.data.gst_tcs.value) : 0;
              // gstTax = response1.data.data.gst.value ? parseInt(response1.data.data.gst.value) : 0;
              // tcsTax = response1.data.data.tcs.value ? parseInt(response1.data.data.tcs.value) : 0;
              // platformCharges = response1.data.data.platform_charges.value ? parseInt(response1.data.data.platform_charges.value) : 0;
            }
          } catch (error) {
            console.error("Error fetching package details:", error);
          }

          response.data?.data?.bookingCancellation?.map((item, index) => {
            if (item?.cancellation_type === "full") {
              totalCancelledPersonCount += parseInt(item?.no_of_pax);
              totalCancelledPrice = parseFloat(item?.pax_charge);
              totalCancellationChargePrice = parseFloat(item?.cancellation_charge);
              totalGstPrice = parseFloat(item?.gst_charge);
              totalRefundAmount = parseFloat(item?.refund_amount);
            } else {
              totalCancelledPersonCount += parseInt(item?.no_of_pax);
              totalCancelledPrice += parseFloat(item?.pax_charge);
              totalCancellationChargePrice += parseFloat(item?.cancellation_charge);
              totalGstPrice += parseFloat(item?.gst_charge);
              totalRefundAmount += parseFloat(item?.refund_amount);
            }
          });

          if (addOnPrice) {
            basePrice = parseFloat(basePrice) + parseFloat(addOnPrice);
          }

          if (totalCancelledPersonCount) {
            afterCancelPrice = parseFloat(basePrice) - parseFloat(totalCancelledPrice) - parseFloat(totalCancellationChargePrice) - parseFloat(totalGstPrice);
          }

          taxes = gstTax + tcsTax;

          if (taxes > 0) {
            taxIncludePrice = parseFloat(basePrice) + (parseFloat(basePrice) * taxes) / 100;
          }
          if (gstTax > 0) {
            gstTaxIncludePrice = (parseFloat(basePrice) * gstTax) / 100;
          }
          if (tcsTax > 0) {
            tcsTaxIncludePrice = (parseFloat(basePrice) * tcsTax) / 100;
          }

          subTotal = parseFloat(taxes ? taxIncludePrice : basePrice);

          let redeemVendorBasePrice = totalRefundAmount
            ? parseFloat(response.data.data.booking.final_price) - totalRefundAmount
            : parseFloat(response.data.data.booking.final_price);

          console.log("redeemVendorBasePrice", redeemVendorBasePrice);
          let redeemVendorBasePriceWithoutGST = redeemVendorBasePrice;

          if (parseFloat(gstTaxIncludePrice)) {
            redeemVendorBasePriceWithoutGST -= parseFloat(gstTaxIncludePrice);
          }
          console.log("aftet", redeemVendorBasePriceWithoutGST, redeemVendorBasePrice);
          if (tdsCharges > 0) {
            tdsIncludePrice = (parseFloat(redeemVendorBasePriceWithoutGST) * tdsCharges) / 100;
          }

          if (gstTcsPercent > 0) {
            gstTcsIncludePrice = (parseFloat(redeemVendorBasePriceWithoutGST) * gstTcsPercent) / 100;
          }

          platformChargesIncludePrice = (parseFloat(paidAmount) * platformCharges) / 100;
          // platformChargesIncludePrice = (parseFloat(redeemVendorBasePrice) * platformCharges) / 100;

          netPayAmt = parseFloat(paidAmount) - parseFloat(platformChargesIncludePrice);

          if (tdsIncludePrice) {
            netPayAmt -= tdsIncludePrice;
          }

          if (gstTcsIncludePrice) {
            netPayAmt -= gstTcsIncludePrice;
          }

          // Refundable to vendor
          refundToVendorPlatformCharge = (totalCancellationChargePrice * platformCharges) / 100;
          refundToVendorPlatformChargeGst = (refundToVendorPlatformCharge * gstTax) / 100;
          refundToVendorGSTTcsCharge = (totalCancellationChargePrice * gstTcsPercent) / 100;
          refundToVendorTdsCharge = (totalCancellationChargePrice * tdsCharges) / 100;

          netPayableToVendor = refundToVendorPlatformCharge + refundToVendorPlatformChargeGst + refundToVendorGSTTcsCharge + refundToVendorTdsCharge;

          console.log(refundToVendorPlatformCharge, refundToVendorPlatformChargeGst, refundToVendorGSTTcsCharge);

          // netPayAmt = parseFloat(redeemVendorBasePrice) - parseFloat(platformChargesIncludePrice);
          // netPayAmt = parseFloat(redeemVendorBasePrice) - parseFloat(platformChargesIncludePrice) - parseFloat(tdsIncludePrice);

          setPriceDetails(prev => ({
            ...prev,
            basePrice: parseFloat(basePrice).toFixed(2),
            taxPrice: taxes,
            gstTax: gstTax,
            tcsTax: tcsTax,
            gstTaxIncludePrice: parseFloat(gstTaxIncludePrice).toFixed(2),
            tcsTaxIncludePrice: parseFloat(tcsTaxIncludePrice).toFixed(2),
            subTotalPrice: parseFloat(subTotal).toFixed(2),
            platformCharges: platformCharges,
            platformChargesIncludePrice: parseFloat(platformChargesIncludePrice).toFixed(2),
            netPayAmt: parseFloat(netPayAmt).toFixed(2),
            tdsCharges: tdsCharges,
            tdsIncludePrice: parseFloat(tdsIncludePrice).toFixed(2),
            totalCancelledPersonCount: totalCancelledPersonCount,
            totalCancelledPrice: parseFloat(totalCancelledPrice).toFixed(2),
            totalCancellationChargePrice: parseFloat(totalCancellationChargePrice).toFixed(2),
            totalGstPrice: parseFloat(totalGstPrice).toFixed(2),
            afterCancelPrice: parseFloat(afterCancelPrice).toFixed(2),
            totalRefundAmount: parseFloat(totalRefundAmount).toFixed(2),
            paidAmount: paidAmount,
            gstTcsPercent: gstTcsPercent,
            gstTcsIncludePrice: parseFloat(gstTcsIncludePrice).toFixed(2),
            refundToVendorPlatformCharge: parseFloat(refundToVendorPlatformCharge).toFixed(2),
            refundToVendorPlatformChargeGst: parseFloat(refundToVendorPlatformChargeGst).toFixed(2),
            refundToVendorGSTTcsCharge: parseFloat(refundToVendorGSTTcsCharge).toFixed(2),
            refundToVendorTdsCharge: parseFloat(refundToVendorTdsCharge).toFixed(2),
            netPayableToVendor: parseFloat(netPayableToVendor).toFixed(2),
          }));
        } else {
        }
        setScreenLoading(false);
      }
    } catch (error) {
      setScreenLoading(false);
    }
  };

  const handleConfirmBooking = async () => {
    setConfirmBtnDis(true);
    try {
      const formData = new FormData();
      formData.append("booking_id", bookingId);
      let response = await confirmBookingApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          getBookingDetails();
        } else {
          NotificationManager.error(response.data.msg);
        }
        setConfirmBtnDis(false);
      }
    } catch (error) {
      setConfirmBtnDis(false);
      NotificationManager.error("Something went wrong, please try again.");
    }
  };

  const handleConfirmCancellationBooking = async id => {
    setConfirmBtnDis(true);
    try {
      const formData = new FormData();
      formData.append("booking_id", bookingId);
      formData.append("cancel_id", id);
      let response = await vendorCancelApproveApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          getBookingDetails();
        } else {
          NotificationManager.error(response.data.msg);
        }
        setConfirmBtnDis(false);
      }
    } catch (error) {
      setConfirmBtnDis(false);
      NotificationManager.error("Something went wrong, please try again.");
    }
  };

  const generateInvoice = pdfUrl => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "Booking_Invoice.pdf";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("PDF URL is not available");
    }
  };

  useEffect(() => {
    getBookingDetails();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Booking Details`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Booking Details page" />
      </Helmet>
      <VenderHeader />
      <div className="venderInnerDiv">
        <VenderSidebar />

        <div className="venderRight">
          {screenLoading && <LoadingPage />}
          <div className="hdrSec">
            <h2>
              <Button variant="link" onClick={() => navigate("/business/bookings")}>
                <img src={arrow} alt="" />
              </Button>
              Booking Details
            </h2>
          </div>
          <div className="bookMainDiv">
            <div className="bookLeft">
              <div className="bookInner">
                <h2>Basic Details</h2>
                <ul className="bookLst">
                  <li>
                    <label>Package ID:</label>
                    <Link to={`/vendor-package-preview/${allDetails?.booking?.package_id}`} className="rdTagClr" target="_blank">
                      #{allDetails?.booking?.package_id}
                    </Link>
                  </li>
                  <li>
                    <label>Tour Package:</label>
                    <span>
                      <Link to="javascript:void(0);">{allDetails?.booking?.vendor_fullname}</Link>
                    </span>
                  </li>
                  <li>
                    <label>Customer Name:</label>
                    <span>{allDetails?.booking?.booking_customer_name}</span>
                  </li>
                  <li>
                    <label>Pan Number:</label>
                    <span>{allDetails?.booking?.booking_customer_pan_number}</span>
                  </li>
                  <li>
                    <label>Email:</label>
                    <span>
                      <Link to={`mailto:${allDetails?.booking?.booking_customer_email}`}>{allDetails?.booking?.booking_customer_email}</Link>
                    </span>
                  </li>
                  <li>
                    <label>Phone:</label>
                    <span>{allDetails?.booking?.booking_customer_phone_number}</span>
                  </li>
                  <li>
                    <label>Address:</label>
                    <span>{allDetails?.booking?.booking_customer_address}</span>
                  </li>
                  <li>
                    <label>State:</label>
                    <span>{allDetails?.booking?.booking_customer_state}</span>
                  </li>
                  {/* <li>
                    <label>City:</label>
                    <span>Kolkata</span>
                  </li>
                  <li>
                    <label>Zip code:</label>
                    <span>711110</span>
                  </li> */}
                  <li>
                    <label>Tour Start Date:</label>
                    <span>{formatDateFormat(allDetails?.booking?.first_booking_date)}</span>
                  </li>
                  <li>
                    <label>Tour End Date:</label>
                    <span>{formatDateFormat(allDetails?.booking?.last_booking_date)}</span>
                    {/* <span>February 03, 2024</span> */}
                  </li>
                </ul>
              </div>

              <div className="bookInner">
                <h2>Passenger Details</h2>
                {passengersArray?.map((item, index) => (
                  <div key={index}>
                    <h3>{item?.room}</h3>
                    {item?.adults?.map((adultItem, adultIndex) => (
                      <>
                        <h3>Adult {adultIndex + 1}</h3>
                        <ul className="bookLst">
                          <li>
                            <label>Name:</label>
                            <span>
                              {adultItem?.title} {adultItem?.first_name} {adultItem?.last_name}
                              {adultItem?.status === 0 ? <span className="canBtn">CANCELLED</span> : null}
                            </span>
                          </li>
                          <li>
                            <label>Age:</label>
                            <span>{calculateAge(adultItem?.dob)} years old</span>
                          </li>
                          {/* <li>
                            <label>Age:</label>
                            <span>42 years old</span>
                          </li> */}
                          <li>
                            <label>Gender:</label>
                            <span style={{textTransform: "capitalize"}}>{adultItem?.gender}</span>
                          </li>
                        </ul>
                      </>
                    ))}
                    {item?.children?.map((childItem, childIndex) => (
                      <>
                        <h3>Children {childIndex + 1}</h3>
                        <ul className="bookLst">
                          <li>
                            <label>Name:</label>
                            <span>
                              {childItem?.title} {childItem?.first_name} {childItem?.last_name}
                              {childItem?.status === 0 ? <span className="canBtn">CANCELLED</span> : null}
                            </span>
                          </li>
                          <li>
                            <label>Age:</label>
                            <span>{calculateAge(childItem?.dob)} years old</span>
                          </li>
                          {/* <li>
                            <label>Age:</label>
                            <span>42 years old</span>
                          </li> */}
                          <li>
                            <label>Gender:</label>
                            <span style={{textTransform: "capitalize"}}>{childItem?.gender}</span>
                          </li>
                        </ul>
                      </>
                    ))}
                  </div>
                ))}
                {/* <h3>Adult 1</h3>
                <ul className="bookLst">
                  <li>
                    <label>Name:</label>
                    <span>Mr. Arnab Ganguly</span>
                  </li>
                  <li>
                    <label>Age:</label>
                    <span>42 years old</span>
                  </li>
                  <li>
                    <label>Gender:</label>
                    <span>Male</span>
                  </li>
                </ul>

                <h3>Adult 2</h3>
                <ul className="bookLst">
                  <li>
                    <label>Name:</label>
                    <span>
                      Mr. Arnab Ganguly
                    </span>
                  </li>
                  <li>
                    <label>Age:</label>
                    <span>42 years old</span>
                  </li>
                  <li>
                    <label>Gender:</label>
                    <span>Male</span>
                  </li>
                </ul>

                <h3>Children</h3>
                <ul className="bookLst">
                  <li>
                    <label>Name:</label>
                    <span>Mr. Arnab Ganguly</span>
                  </li>
                  <li>
                    <label>Age:</label>
                    <span>5 years old</span>
                  </li>
                  <li>
                    <label>Gender:</label>
                    <span>Male</span>
                  </li>
                </ul> */}
              </div>

              {allDetails?.booking_flight?.length > 0 || allDetails?.booking_train?.length > 0 || allDetails?.booking_LocalTransport?.length > 0 ? (
                <div className="bookInner">
                  <h2>Transportation Details</h2>
                  {allDetails?.booking_flight?.length > 0 ? (
                    <>
                      <h3>FLIGHT</h3>
                      <ul className="bookLst">
                        {/* <li>
                        <label>Airline:</label>
                        <span>Air India</span>
                      </li>
                      <li>
                        <label>Flight Number:</label>
                        <span>852456753159</span>
                      </li>
                      <li>
                        <label>Class:</label>
                        <span>Economy</span>
                      </li> */}
                        <li>
                          <label>Departure:</label>
                          <span>{allDetails?.booking_flight[0]?.depart_destination}</span>
                        </li>
                        <li>
                          <label>Arrival:</label>
                          <span>{allDetails?.booking_flight[0]?.arrive_destination}</span>
                        </li>
                        {/* <li>
                          <label>Departure Date:</label>
                          <span>{formatDateFormat1(allDetails?.booking_flight[0]?.depart_datetime)}</span>
                        </li> */}
                        <li>
                          <label>Departure Time:</label>
                          <span>{TimeConverter(allDetails?.booking_flight[0]?.depart_datetime)}</span>
                        </li>
                        {/* <li>
                          <label>Arrival Date:</label>
                          <span>{formatDateFormat1(allDetails?.booking_flight[0]?.arrive_datetime)}</span>
                        </li> */}
                        <li>
                          <label>Arrival Time:</label>
                          <span>{TimeConverter(allDetails?.booking_flight[0]?.arrive_datetime)}</span>
                        </li>
                        {/* <li>
                        <label>Stoppage:</label>
                        <span>Non Stop</span>
                      </li> */}
                      </ul>
                    </>
                  ) : null}

                  {allDetails?.booking_train?.length > 0 ? (
                    <>
                      <h3>TRAIN</h3>
                      <ul className="bookLst">
                        <li>
                          <label>Train Name:</label>
                          <span>{allDetails?.booking_train[0]?.train_name}</span>
                        </li>
                        <li>
                          <label>Train Number:</label>
                          <span>{allDetails?.booking_train[0]?.train_number}</span>
                        </li>
                        <li>
                          <label>Class:</label>
                          <span>{allDetails?.booking_train[0]?.class}</span>
                        </li>
                        <li>
                          <label>Departure:</label>
                          <span>{allDetails?.booking_train[0]?.from_station}</span>
                        </li>
                        <li>
                          <label>Arrival:</label>
                          <span>{allDetails?.booking_train[0]?.to_station}</span>
                        </li>
                        <li>
                          <label>Departure Date:</label>
                          <span>{formatDateFormat1(allDetails?.booking_train[0]?.depart_datetime)}</span>
                        </li>
                        <li>
                          <label>Departure Time:</label>
                          <span>{formatTimeFormat1(allDetails?.booking_train[0]?.depart_datetime)}</span>
                        </li>
                        <li>
                          <label>Arrival Date:</label>
                          <span>{formatDateFormat1(allDetails?.booking_train[0]?.arrive_datetime)}</span>
                        </li>
                        <li>
                          <label>Arrival Time:</label>
                          <span>{formatTimeFormat1(allDetails?.booking_train[0]?.arrive_datetime)}</span>
                        </li>
                      </ul>
                    </>
                  ) : null}

                  {allDetails?.booking_LocalTransport?.length > 0 ? (
                    <>
                      <h3>LOCAL TRANSPORT</h3>
                      <ul className="bookLst">
                        <li>
                          <label>Type:</label>
                          <span>{allDetails?.booking_LocalTransport[0]?.car}</span>
                        </li>
                        <li>
                          <label>Model:</label>
                          <span>{allDetails?.booking_LocalTransport[0]?.model}</span>
                        </li>
                        <li>
                          <label>Capacity:</label>
                          <span>{allDetails?.booking_LocalTransport[0]?.capacity}</span>
                        </li>
                        <li>
                          <label>Air Condition:</label>
                          <span>{parseInt(allDetails?.booking_LocalTransport[0]?.AC) === 1 ? "Yes" : "No"}</span>
                        </li>
                      </ul>
                    </>
                  ) : null}
                </div>
              ) : null}

              {allDetails?.packageAddon?.length > 0 ? (
                <>
                  <div className="bookInner">
                    <h2>Add Ons</h2>

                    {allDetails?.packageAddon?.map((item, index) => (
                      <>
                        <h3>{item?.title}</h3>
                        <ul className="bookLst">
                          <li>
                            <label>Price:</label>
                            <span>₹{item?.price} INR</span>
                          </li>
                          <li>
                            <label>Description:</label>
                            <span>{item?.description}</span>
                          </li>
                        </ul>
                      </>
                    ))}

                    {/* <h3>ADD ON TITLE GOES HERE</h3>
                <ul className="bookLst">
                  <li>
                    <label>Price:</label>
                    <span>₹500 INR</span>
                  </li>
                  <li>
                    <label>Description:</label>
                    <span>Lorem Ipsum is a simple dummy text for typing</span>
                  </li>
                </ul> */}
                  </div>
                </>
              ) : null}
            </div>

            <div className="bookRight">
              <div className="bookInner">
                <h2>Booking Information</h2>

                <ul className="bookLst">
                  <li>
                    <label>Booking ID:</label>
                    <span className="rdTagClr">#{allDetails?.booking?.id}</span>
                  </li>
                  <li>
                    <label>Booking Date:</label>
                    <span>{formatDateFormat(allDetails?.booking?.created_at)}</span>
                    {/* <span>Jan 05, 2024</span> */}
                  </li>
                  <li>
                    <label>Status:</label>
                    <span
                      className={
                        allDetails?.booking?.bookings_status_id === 1
                          ? "rdTagClr"
                          : allDetails?.booking?.bookings_status_id === 2
                          ? "yellowTagColor"
                          : allDetails?.booking?.bookings_status_id === 5
                          ? "violetStatusText"
                          : allDetails?.booking?.bookings_status_id === 4
                          ? "redStatusText"
                          : ""
                      }
                      style={{textTransform: "capitalize"}}>
                      {allDetails?.booking?.booking_status}
                    </span>
                  </li>
                  {/* {allDetails?.booking?.bookings_status_id === 1 ? (
                    <li>
                      {confirmBtnDis ? (
                        <button className="confirmBtn" disabled>
                          Please wait...
                        </button>
                      ) : (
                        <button className="confirmBtn" onClick={() => handleConfirmBooking()}>
                          Confirm Booking
                        </button>
                      )}
                    </li>
                  ) : null} */}
                  {allDetails?.booking?.bookings_status_id === 4 || allDetails?.booking?.bookings_status_id === 5
                    ? allDetails?.bookingCancellation?.map((item, index) =>
                        item?.status === 0 ? (
                          <>
                            <li>
                              <label>Cancel Initiate Date:</label>
                              <span>{formatDateFormat(item?.created_at)}</span>
                            </li>
                            <li>
                              {confirmBtnDis ? (
                                <button className="confirmBtn" disabled>
                                  Accept Cancellation
                                </button>
                              ) : (
                                <button className="confirmBtn" onClick={() => handleConfirmCancellationBooking(item?.id)}>
                                  Accept Cancellation
                                </button>
                              )}
                            </li>
                          </>
                        ) : null
                      )
                    : null}
                  {parseFloat(allDetails?.booking?.final_price) === parseFloat(priceDetails?.paidAmount) ? (
                    <li>
                      <button onClick={() => generateInvoice(allDetails?.pdfFilePath)}>Generate Invoice</button>
                    </li>
                  ) : null}
                </ul>
              </div>

              <div className="bookInner">
                <h2>Payment Information </h2>

                <ul className="bookLst">
                  <li>
                    <strong>Initial Booking</strong>
                  </li>
                  <li>
                    <label>Tour Package Cost</label>
                    <span>₹{parseFloat(allDetails?.booking?.base_amount).toFixed(2)}</span>
                    {/* <span>₹{priceDetails.basePrice}</span> */}
                  </li>
                  {allDetails?.booking?.addon_total_price ? (
                    <li>
                      <label>Add on Total</label>
                      <span>₹{parseFloat(allDetails?.booking?.addon_total_price).toFixed(2)}</span>
                      {/* <span>₹{priceDetails.basePrice}</span> */}
                    </li>
                  ) : null}

                  {/* <li>
                    <label>Platform Charges (2%)</label>
                    <span>₹00,000</span>
                  </li> */}
                  {priceDetails?.gstTax > 0 ? (
                    <li>
                      <label>GST Addition ({priceDetails?.gstTax}%)</label>
                      <span>₹{priceDetails?.gstTaxIncludePrice}</span>
                    </li>
                  ) : null}

                  {priceDetails?.tcsTax > 0 ? (
                    <li>
                      <label>TCS ({priceDetails?.tcsTax}%)</label>
                      <span>₹{priceDetails?.tcsTaxIncludePrice}</span>
                    </li>
                  ) : null}
                  {parseFloat(allDetails?.booking?.final_price) !== parseFloat(priceDetails?.paidAmount) ? (
                    <li>
                      <label>Paid Amount</label>
                      <span>₹{parseFloat(priceDetails?.paidAmount).toFixed(2)}</span>
                    </li>
                  ) : null}
                  <li className="grssLine">
                    <label>
                      <strong>Gross Due</strong>
                    </label>
                    <span>₹{parseFloat(priceDetails?.paidAmount).toFixed(2)}</span>
                    {/* <span>₹{priceDetails?.subTotalPrice}</span> */}
                  </li>

                  {parseFloat(allDetails?.booking?.final_price) === parseFloat(priceDetails?.paidAmount) ? (
                    <>
                      <li>
                        <strong>Redemption to Vendor</strong>
                      </li>
                      <li>
                        <label>Platform Charges({priceDetails?.platformCharges}%)</label>
                        <span>(-) ₹{priceDetails?.platformChargesIncludePrice}</span>
                      </li>
                      <li>
                        <label>GST TCS ({priceDetails?.gstTcsPercent}%)</label>
                        <span>(-) ₹{priceDetails?.gstTcsIncludePrice}</span>
                      </li>
                      <li>
                        <label>TDS({priceDetails?.tdsCharges}%)</label>
                        <span>(-) ₹{priceDetails?.tdsIncludePrice}</span>
                      </li>
                      {/* <li>
                        <label>Vendor TDS (0%)</label>
                        <span>(-) ₹00,000</span>
                        </li> */}
                      {/* <li>
                          <label>Vendor TDS ({priceDetails?.tdsCharges}%)</label>
                          <span>(-) ₹{priceDetails?.tdsIncludePrice}</span>
                        </li> */}
                      <li className="grssLine">
                        <label>
                          <strong>Net Payable to Vendor</strong>
                        </label>
                        <span>₹{priceDetails?.netPayAmt}</span>
                      </li>
                    </>
                  ) : null}

                  {allDetails?.refundAmountSum ? (
                    <>
                      <li>
                        <strong>Refund to Customer</strong>
                      </li>
                      <li>
                        <label>Package Cost</label>
                        <span>₹{parseFloat(allDetails?.booking?.final_price).toFixed(2)}</span>
                      </li>
                      <li>
                        <label>No. of Person x {priceDetails?.totalCancelledPersonCount}</label>
                        <span>₹{priceDetails.totalCancelledPrice}</span>
                      </li>
                      {/* <li>
                        <label>GST</label>
                        <span>₹00,000</span>
                      </li> */}
                      <li>
                        <label>Cancellation Charge({parseInt(allDetails?.bookingCancellation[0]?.cancellation_percent)}%)</label>
                        <span>(-) ₹{priceDetails?.totalCancellationChargePrice}</span>
                      </li>
                      {allDetails?.bookingCancellation[0]?.gst_percent ? (
                        <li>
                          <label>GST({parseInt(allDetails?.bookingCancellation[0]?.gst_percent)}%)</label>
                          <span>(-) ₹{priceDetails?.totalGstPrice}</span>
                        </li>
                      ) : null}

                      <li className="grssLine">
                        <label>
                          <strong>Net Payment Due</strong>
                        </label>
                        <span>₹{priceDetails?.totalRefundAmount}</span>
                        {/* <span>₹{priceDetails?.afterCancelPrice}</span> */}
                      </li>
                    </>
                  ) : null}

                  {allDetails?.refundAmountSum ? (
                    <>
                      <li>
                        <strong>Refund to Vendor</strong>
                      </li>
                      <li>
                        <label>Platform Charges({priceDetails?.platformCharges}%)</label>
                        <span>(+) ₹{priceDetails?.refundToVendorPlatformCharge}</span>
                      </li>
                      <li>
                        <label>GST({priceDetails?.gstTax}%)</label>
                        <span>(+) ₹{priceDetails?.refundToVendorPlatformChargeGst}</span>
                      </li>
                      <li>
                        <label>GST TCS ({priceDetails?.gstTcsPercent}%)</label>
                        <span>(+) ₹{priceDetails?.refundToVendorGSTTcsCharge}</span>
                      </li>
                      <li>
                        <label>TDS({priceDetails?.tdsCharges}%)</label>
                        <span>(+) ₹{priceDetails?.refundToVendorTdsCharge}</span>
                      </li>
                      {/* <li>
                        <label>Vendor TDS (0%)</label>
                        <span>(-) ₹00,000</span>
                        </li> */}
                      {/* <li>
                          <label>Vendor TDS ({priceDetails?.tdsCharges}%)</label>
                          <span>(-) ₹{priceDetails?.tdsIncludePrice}</span>
                        </li> */}
                      <li className="grssLine">
                        <label>
                          <strong>Net Payable to Vendor</strong>
                        </label>
                        <span>₹{priceDetails?.netPayableToVendor}</span>
                      </li>
                    </>
                  ) : null}
                </ul>
              </div>

              <div className="bookInner">
                <h2>Client Review</h2>
                {allDetails?.booking?.rating ? (
                  <div className="rvwImg">
                    <div className="bookDtlsInner">
                      <StarRatings
                        rating={allDetails?.booking?.rating ? parseInt(allDetails?.booking?.rating) : 0} // Initial rating value
                        starRatedColor="#F8B84E" // Color of the filled stars
                        numberOfStars={5} // Total number of stars
                        name="rating" // Unique name for the component
                        starDimension="30px"
                        starSpacing="2px"
                      />
                      {allDetails?.booking?.feedback ? (
                        <ul className="bookContainer cancelIssue feedbackText">
                          <li>{allDetails?.booking?.feedback}</li>
                        </ul>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="rvwImg">
                    <img src={reviewImg} alt="" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorBookingDetails;
