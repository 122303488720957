import React, {Component, useState, useRef, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploader from "./../component/ImageUploader";
// import StarRating from "./../component/StarRating";
import {
  addPackageApi,
  deleteAddonsApi,
  deleteExclusionListApi,
  deleteFlightApi,
  deleteGalleryImageApi,
  deleteHotelApi,
  deleteInclusionListApi,
  deleteItineraryApi,
  deleteLocalTransportApi,
  deleteMealApi,
  deleteMediaLinkApi,
  deleteSightseeingApi,
  deleteTrainApi,
  editPackageApi,
  fetchPlacesApi,
  fetchLocationsApi,
  getCityListApi,
  getPackageDetailsApi,
  getStatesListApi,
  getThemesListApi,
  getTripListApi,
  getTypeOfTourPackagesListApi,
  tourismCircuitsApi,
  fetchExclusionsApi,
  fetchInclusionsApi,
  vendorApprovalApi,
  changePackageStatusApi,
  getvendorPackageDetailsApi,
} from "../services/ApiService";
import {Typeahead} from "react-bootstrap-typeahead";
import {NotificationManager} from "react-notifications";
import StarRatings from "react-star-ratings";
import {useNavigate, useParams} from "react-router";
import {IMAGE_URL} from "../utils/commonUrls";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {Helmet} from "react-helmet";
import {Button} from "react-bootstrap";
import Select from "react-select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import LoadingPage from "../component/loadingPage";
import {NonFullScreenPageMode} from "pdf-lib";
import {Modal} from "react-bootstrap";
import VendorRegisteredSuccessIcon from "../assets/img/Vendor-registered-success-icon.svg";

const arrow = require("./../assets/img/left-arrow.svg").default;
const plusIcon = require("./../assets/img/plus-icon.svg").default;
const minusIcon = require("./../assets/img/minus-icon.svg").default;
const dragIcon = require("./../assets/img/drag-icon.svg").default;
const crossIcon2 = require("./../assets/img/cross.svg").default;
const carImg = require("./../assets/img/local-icon.svg").default;
const carIcon = require("./../assets/img/car-icon3.svg").default;
const editIcon = require("./../assets/img/edit-icon.svg").default;
const flightIcon = require("./../assets/img/flight-icon.svg").default;
const flightIcon2 = require("./../assets/img/plane-icon.svg").default;
const trainIcon = require("./../assets/img/train-icon2.svg").default;
const trainIcon2 = require("./../assets/img/train-icon.svg").default;
const tour1 = require("./../assets/img/tour-details1.png");
const hotelIcon = require("./../assets/img/hotel-icon.svg").default;
const ratingImg = require("./../assets/img/rating-img.svg").default;
const ratingImgBlank = require("./../assets/img/rating-blank.svg").default;
const mealIcon = require("./../assets/img/meal-icon.svg").default;
const sightIcon = require("./../assets/img/sight-icon.svg").default;
const messageIcon = require("./../assets/img/message-icon.svg").default;

const EditTourPackage = popupIdentifier => {
  const {packageId} = useParams();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [mediaLinks, setMediaLinks] = useState([]);
  const [prevMediaLinks, setPrevMediaLinks] = useState([]);
  const [inclustionList, setInclustionList] = useState([]);
  const [exclusionList, setExclusionList] = useState([]);
  const [tripList, setTripList] = useState([
    {
      id: 1,
      name: "Domestic",
    },
    // {
    //   id: 2,
    //   name: "International",
    // },
  ]);
  const [typeOfTourPackagesList, setTypeOfTourPackagesList] = useState([
    {
      id: 1,
      name: "Standard",
    },
    // {
    //   id: 2,
    //   name: "Weekend",
    // },
  ]);
  const [cityList, setCityList] = useState([]);
  const [allCityList, setAllCityList] = useState([]);
  const [themesList, setThemesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [addPackformData, setAddPackFormData] = useState({
    packageTitle: "",
    tourPackageType: "",
    tripType: "",
    state: "",
    city: "",
    tourCircuit: "",
    location: "",
    origin: "",
    themes: "",
    keywords: "",
    overview: "",
    termsAndCondition: "",
    numberOfPaxBulk: "",
    discountPercentBulk: "",
    basicPriceAboveTwelve: "",
    childPrice: "",
    infantPrice: "",
    basicPriceAdditional: "",
    tripleSharingDiscount: "",
    offSeasonPrice: "",
    onSeasonPrice: "",
    onToDate: "",
    onFromDate: "",
    toDate: "",
    fromDate: "",
  });
  const [mediaLinksText, setMediaLinksText] = useState("");
  const [inclutionText, setInclutionText] = useState("");
  const [newInclustionSec, setNewInclustionSec] = useState(false);
  const [exclusionText, setExclusionText] = useState("");
  const [newExclutionSec, setNewExclutionSec] = useState(false);
  const [activePackage, setActivePackage] = useState(true);
  const [bulkDiscountActive, setBulkDiscountActive] = useState(false);
  const [btnDis, setBtnDis] = useState(false);
  const [aproveBtnDis, setAproveBtnDis] = useState(false);
  const [packageDetails, setPackageDetails] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Flight");
  const [tabValues, setTabValues] = useState({});
  const [inclusions, setInclusions] = useState([]);
  const [itineraryArray, setItineraryArray] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [editingValues, setEditingValues] = useState({});
  const [selectedInclusionIndex, setSelectedInclusionIndex] = useState(null);
  const [totalDays, setTotalDays] = useState(1);
  const [popupRatings, setPopupRatings] = useState(Array(100).fill(0));
  const [ratingVisible, setRatingVisible] = useState(false); // State to manage visibility
  const [uploadedImages, setUploadedImages] = useState([]);
  const [section1Images, setSection1Images] = useState([]);
  const [section1PrevImages, setSection1PrevImages] = useState([]);
  const [section2Images, setSection2Images] = useState([]);
  const [section3Images, setSection3Images] = useState([]);
  const [section2PrevImages, setSection2PrevImages] = useState([]);
  const [section3PrevImages, setSection3PrevImages] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [bulkDiscountsArray, setBulkDiscountsArray] = useState([{pax: "", percent: ""}]);

  //-------------------------Calendar Starts-------------------------
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [defaultSeat, setDefaultSeat] = useState();
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDates1, setSelectedDates1] = useState([]);
  const [selectedDateObj, setSelectedDateObj] = useState();
  const [editSeatPopup, setEditSeatPopup] = useState(false);
  const today = new Date(); // Today's date

  // Sample calendar data, replace it with your actual data
  const [calendarData, setCalendarData] = useState([]);
  const [calendarData1, setCalendarData1] = useState([]);
  const [tourCircuitList, setTourCircuitList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [keywordsList, setKeywordsList] = useState([]);
  const [defaultTouCircuitValue, setDefaultTouCircuitValue] = useState(null);
  const [defaultLocationValue, setDefaultLocationValue] = useState(null);
  const [defaultKeywordsValue, setDefaultKeywordsValue] = useState([]);
  const [inclusionListFromApi, setInclusionListFromApi] = useState([]);
  const [exclusionListFromApi, setExclusionListFromApi] = useState([]);
  const [cancellationPoliciesDefault, setCancellationPoliciesDefault] = useState({from: "", percentage: ""});
  const [cancellationPolicies, setCancellationPolicies] = useState([]);
  const [paymentPoliciesDefault, setPaymentPoliciesDefault] = useState({from: "", percentage: ""});
  const [paymentPolicies, setPaymentPolicies] = useState([]);
  const [messageCount, setMessageCount] = useState();
  const [packageVerified, setPackageVerified] = useState(false);
  const [viewPreview, setViewPreview] = useState(false);
  const [seasonPriceRanges, setSeasonPriceRanges] = useState([]);

  const [isSticky, setIsSticky] = useState(false);
  const [vendorApproveModal, setVendorApproveModal] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 250) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const bulkDiscountOptions = [
    {value: 5, label: "5+"},
    {value: 10, label: "10+"},
    {value: 20, label: "20+"},
    {value: 30, label: "30+"},
  ];

  // Function to handle changes in the date pickers
  const handleDateChange = (index, field, date) => {
    const newFormData = [...seasonPriceRanges];
    newFormData[index][field] = date;
    setSeasonPriceRanges(newFormData);
  };

  // Function to handle changes in the price input
  const handlePriceChange = (index, value) => {
    const newFormData = [...seasonPriceRanges];
    newFormData[index].price = value;
    setSeasonPriceRanges(newFormData);
  };

  // Function to add a new entry
  const addEntry = () => {
    setSeasonPriceRanges([...seasonPriceRanges, {onToDate: null, onFromDate: null, price: ""}]);
  };

  // Function to remove an entry
  const removeEntry = index => {
    const newFormData = seasonPriceRanges.filter((_, i) => i !== index);
    setSeasonPriceRanges(newFormData);
  };

  const handleAddPolicy = () => {
    if (cancellationPolicies.length < 3) {
      setCancellationPolicies([...cancellationPolicies, {from: "", to: "", percentage: ""}]);
    }
  };

  const handleRemovePolicy = index => {
    const policies = [...cancellationPolicies];
    policies.splice(index, 1);
    setCancellationPolicies(policies);
  };

  const handlePolicyDefaultInputChange = (field, value) => {
    const maxLength = field === "percentage" ? 3 : 2;
    if (value.length <= maxLength) {
      if (field === "percentage" && parseFloat(value) > 100) {
        value = 100;
      }
      setCancellationPoliciesDefault(prevPolicies => ({
        ...prevPolicies,
        [field]: value,
      }));
    }
  };

  const handlePolicyInputChange = (index, field, value) => {
    const maxLength = field === "percentage" ? 3 : 2;
    if (value.length <= maxLength) {
      if (field === "percentage" && parseFloat(value) > 100) {
        value = 100;
      }
      const policies = [...cancellationPolicies];
      policies[index][field] = value;
      setCancellationPolicies(policies);
    }
  };

  const handleAddPaymentPolicy = () => {
    if (paymentPolicies.length < 2) {
      setPaymentPolicies([...paymentPolicies, {from: "", to: "", percentage: ""}]);
    }
  };

  const handleRemovePaymentPolicy = index => {
    const policies = [...paymentPolicies];
    policies.splice(index, 1);
    setPaymentPolicies(policies);
  };

  const handlePaymentPolicyDefaultInputChange = (field, value) => {
    const maxLength = field === "percentage" ? 3 : 2;
    if (value.length <= maxLength) {
      if (field === "percentage" && parseFloat(value) > 100) {
        value = 100;
      }
      setPaymentPoliciesDefault(prevPolicies => ({
        ...prevPolicies,
        [field]: value,
      }));
    }
  };

  const handlePaymentPolicyInputChange = (index, field, value) => {
    const maxLength = field === "percentage" ? 3 : 2;
    if (value.length <= maxLength) {
      if (field === "percentage" && parseFloat(value) > 100) {
        value = 100;
      }
      const policies = [...paymentPolicies];
      policies[index][field] = value;
      setPaymentPolicies(policies);
    }
  };

  const getDayStatus = date => {
    const day = calendarData.find(d => d.date.getTime() === date.getTime());
    return day ? day.status : null;
  };

  const getDayPrice = date => {
    const day = calendarData.find(d => d.date.getTime() === date.getTime());
    return day ? day.price : null;
  };

  const getSeatValue = date => {
    const day = calendarData.find(d => isSameDay(d.date, date));
    return day ? day.seat + " Seat" : null;
  };

  const getSeatValue1 = date => {
    const day = calendarData1.find(d => isSameDay(d.date, date));
    return day ? "Not Run" : null;
  };

  const getDateAllValue = date => {
    const formattedDate = date.toLocaleDateString("en-IN");
    const day = calendarData.find(d => d.date.getTime() === date.getTime() && selectedDates.includes(formattedDate));
  };

  const handleDateClick = date => {
    setSelectedDate(date);

    const formattedDate = date.toLocaleDateString("en-IN");

    if (!selectedDates.includes(formattedDate)) {
      setSelectedDates([...selectedDates, formattedDate]);

      setCalendarData([
        ...calendarData,
        {
          date,
          status: "Lowest Price",
          price: addPackformData?.basicPriceAboveTwelve ? parseInt(addPackformData?.basicPriceAboveTwelve) : 10000,
          seat: defaultSeat,
        },
      ]);
    } else {
      handleEditSeatClick(date);
    }
  };

  const handleDateClick1 = date => {
    setSelectedDate1(date);
    console.log(date);

    const formattedDate = date.toLocaleDateString("en-IN");

    if (!selectedDates1.includes(formattedDate)) {
      setSelectedDates1([...selectedDates1, formattedDate]);

      // setCalendarData([...calendarData, {date, status: "Lowest Price", price: 25000, seat: defaultSeat}]);
      setCalendarData1([
        ...calendarData1,
        {
          date,
          status: "Sold Out",
          price: addPackformData?.basicPriceAboveTwelve ? parseInt(addPackformData?.basicPriceAboveTwelve) : 10000,
          seat: defaultSeat,
        },
      ]);
    } else {
    }
  };

  const handleEditSeatClick = date => {
    const selectedDateObj = calendarData.find(entry => isSameDay(entry.date, date));
    if (selectedDateObj) {
      const selectedDate = new Date(selectedDateObj?.date);
      const formattedMonth = selectedDate.toLocaleString("default", {month: "long"});
      const formattedDay = selectedDate.toLocaleString("default", {day: "numeric"});
      const formattedYear = selectedDate.getFullYear();
      const formattedWeekday = selectedDate.toLocaleString("default", {weekday: "long"});
      selectedDateObj.selectedMonth = formattedMonth;
      selectedDateObj.newFormat = `${formattedDay} ${formattedYear}, ${formattedWeekday}`;
      setSelectedDateObj(selectedDateObj);
      setEditSeatPopup(true);
    }
  };

  const handleSeatInputChange = (field, value) => {
    if (selectedDateObj) {
      const updatedDateObj = {
        ...selectedDateObj,
        [field]: value,
      };

      setSelectedDateObj(updatedDateObj);
    }
  };

  const handleUpdateSeatDetails = () => {
    if (selectedDateObj) {
      if (parseInt(selectedDateObj.seat) <= parseInt(defaultSeat)) {
        setCalendarData(prevCalendarData => {
          return prevCalendarData.map(item =>
            item.date.getTime() === selectedDateObj.date.getTime() ? {...item, seat: selectedDateObj.seat, price: selectedDateObj.price} : item
          );
        });
        setEditSeatPopup(false);
      } else {
        NotificationManager.warning("Seat should not be greater than default seat.");
      }
    }
  };

  const tileContent = ({date}) => {
    const status = getDayStatus(date);
    const price = getDayPrice(date);
    const seat = getSeatValue(date);
    // setSelectedDateObj
    return (
      <div className="eachDtls">
        <p className="prc">{seat}</p>
        {/* <p className="sts">{status}</p> */}
        <p className="sts" onClick={() => handleEditSeatClick(date)}>
          Edit Seat
        </p>
      </div>
    );
  };

  const tileContent1 = ({date}) => {
    const status = getDayStatus(date);
    const price = getDayPrice(date);
    const seat = getSeatValue1(date);
    return (
      <div className="eachDtls">
        <p className="prc">{seat}</p>
        <p className="sts">Not Run</p>
      </div>
    );
  };

  const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  };

  const getStatusForDate = date => {
    const dataForDate = calendarData.find(entry => isSameDay(entry.date, date));

    if (dataForDate) {
      return dataForDate.status.toLowerCase().replace(" ", "-");
    }

    return "";
  };

  const getStatusForDate1 = date => {
    const dataForDate = calendarData1.find(entry => isSameDay(entry.date, date));

    if (dataForDate) {
      return dataForDate.status.toLowerCase().replace(" ", "-");
    }

    return "";
  };

  const tileClassName = ({date}) => {
    const status = getStatusForDate(date);
    const isCurrentDate =
      date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear();

    // Check if the date is before the present day
    const isPreviousDate = date < new Date();

    // Return a string of classes separated by a space
    return `${status} ${isCurrentDate ? "current-date" : ""} ${isPreviousDate ? "previous-date" : ""} selected-date`.trim();
  };

  const tileClassName1 = ({date}) => {
    const status = getStatusForDate1(date);
    const isCurrentDate =
      date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear();

    // Check if the date is before the present day
    const isPreviousDate = date < new Date();

    // Return a string of classes separated by a space
    return `${status} ${isCurrentDate ? "current-date" : ""} ${isPreviousDate ? "previous-date" : ""} selected-date`.trim();
  };

  const handleDefaultSeatChange = e => {
    const newDefaultSeat = parseInt(e.target.value, 10) || 0;
    setDefaultSeat(newDefaultSeat);

    const updatedCalendarData = calendarData.map(entry => ({
      ...entry,
      seat: newDefaultSeat,
    }));

    setCalendarData(updatedCalendarData);
  };

  //-------------------------Calendar Ends-------------------------

  const getPackageDetails = async () => {
    setScreenLoading(true);
    setSection1PrevImages([]);
    setMediaLinks([]);
    setInclustionList([]);
    setInclustionList([]);
    setExclusionList([]);
    setAddOns([]);
    setSeasonPriceRanges([]);
    setCancellationPolicies([]);
    setPaymentPolicies([]);
    setBulkDiscountsArray([]);
    try {
      let response = await getvendorPackageDetailsApi(packageId);
      if (response) {
        if (response.data.res === true) {
          getStateList();
          getCityList(response.data.data.destination_state_id);
          setPackageDetails(response.data.data);
          if (response.data.data?.admin_verified == 1 && response.data.data?.vendor_verified == 1) {
            setPackageVerified(true);
          } else {
            setPackageVerified(false);
          }
          setMessageCount(response.data.messagesCount ? response.data.messagesCount : "");
          setAddPackFormData({
            packageTitle: response.data.data.package_name,
            tourPackageType: response.data.data.trip_type,
            tripType: response.data.data.tour_type,
            state: response.data.data.destination_state_id,
            city: response.data.data.destination_city_id,
            tourCircuit: response.data.data.tour_circuit,
            location: response.data.data.region_id,
            // location: response.data.data.location,
            origin: response.data.data.origin,
            themes: response.data.data.themes[0].id,
            keywords: response.data.data.keywords,
            overview: response.data.data.overview,
            paymentPolicy: response.data.data.payment_policy ? response.data.data.payment_policy : "100% in advance",
            termsAndCondition: response.data.data.terms_and_condition,
            numberOfPaxBulk: response.data.data.bulk_no_of_pax,
            discountPercentBulk: response.data.data.pax_discount_percent,
            basicPriceAboveTwelve: response.data.data.starting_price,
            childPrice: response.data.data.child_price,
            infantPrice: response.data.data.infant_price,
            basicPriceAdditional: response.data.data.single_occupancy_price,
            tripleSharingDiscount: response.data.data.triple_occupancy_price,
            offSeasonPrice: response.data.data.offseason_price,
            onSeasonPrice: response.data.data.onseason_price,
            onToDate: response.data.data.onseason_to_date ? new Date(response.data.data.onseason_to_date) : null,
            onFromDate: response.data.data.onseason_from_date ? new Date(response.data.data.onseason_from_date) : null,
            toDate: response.data.data.offseason_to_date ? new Date(response.data.data.offseason_to_date) : null,
            fromDate: response.data.data.offseason_from_date ? new Date(response.data.data.offseason_from_date) : null,
          });

          setDefaultSeat(response.data.data.total_seat);

          if (response.data.data?.gallery_images?.length > 0) {
            response.data.data?.gallery_images?.map(galItem => {
              setSection1PrevImages(prev => [...prev, {id: galItem.id, path: IMAGE_URL + galItem.path}]);
            });
          }

          if (response.data.data.status == 1) {
            setActivePackage(true);
          } else {
            setActivePackage(false);
          }

          // if (response.data.data.bulk_no_of_pax || response.data.data.pax_discount_percent) {
          //   setBulkDiscountActive(true);
          // }

          if (response.data.data.media_link.length > 0) {
            response.data.data.media_link.map(item => {
              setMediaLinks(prev => [...prev, {id: item?.id, media_link: item?.media_link}]);
              // setPrevMediaLinks(prev => [...prev, item]);
            });
          }
          if (response.data.data.inclusions_list.length > 0) {
            response.data.data.inclusions_list.map(item => {
              setInclustionList(prev => [...prev, {id: item?.id, text: item?.name}]);
            });
          }
          if (response.data.data.exclusions_list.length > 0) {
            response.data.data.exclusions_list.map(item => {
              setExclusionList(prev => [...prev, {id: item?.id, text: item?.name}]);
            });
          }
          if (response.data.data.addons.length > 0) {
            response.data.data.addons.map(item => {
              setAddOns(prev => [...prev, {id: item?.id, title: item?.title, price: item?.price, description: item?.description}]);
            });
          } else {
            setAddOns([]);
            // setAddOns([{title: "", price: "", description: ""}]);
          }

          if (response.data.data.packageRates.length > 0) {
            response.data.data.packageRates.map(item => {
              setSeasonPriceRanges(prev => [...prev, {onToDate: new Date(item?.end_date), onFromDate: new Date(item?.start_date), price: item?.price}]);
            });
          } else {
            setSeasonPriceRanges([]);
            // setAddOns([{title: "", price: "", description: ""}]);
          }

          // let tempCalArray = [];

          if (response.data.data.cancellation_policy.length > 0) {
            response.data.data.cancellation_policy.map((policy, index) => {
              const [from, to, percentage] = policy.split("-");
              if (index === 0) {
                setCancellationPoliciesDefault({from, percentage});
              } else {
                setCancellationPolicies(prevPolicies => [...prevPolicies, {from, to, percentage}]);
              }
            });
          }

          if (response.data.data.payment_policy.length > 0) {
            response.data.data.payment_policy.map((policy, index) => {
              const [from, to, percentage] = policy.split("-");
              if (index === 0) {
                setPaymentPoliciesDefault({from, percentage});
              } else {
                setPaymentPolicies(prevPolicies => [...prevPolicies, {from, to, percentage}]);
              }
            });
          }

          if (response.data.data.bulk_discounts.length > 0) {
            setBulkDiscountActive(true);
            response.data.data.bulk_discounts.map((discItem, discIndex) => {
              setBulkDiscountsArray(prev => [...prev, {pax: discItem?.min_pax, percent: Math.round(discItem?.discount)}]);
            });
          } else {
            setBulkDiscountsArray([{pax: "", percent: ""}]);
          }

          const formatdateFunc = iniDate => {
            let date = new Date(iniDate);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            return formattedDate;
          };

          const calPromises = response.data.data.seat_availability.map(async item => {
            const date = item?.date ? new Date(item?.date) : null;
            const price = parseFloat(item?.cost);
            const seat = parseInt(item?.seat);
            let withTFormat = date.toLocaleDateString("en-IN");
            setSelectedDates([...selectedDates, withTFormat]);
            return {
              id: item?.id,
              date: date,
              newFormatDate: formatdateFunc(date),
              status: "Lowest Price",
              price: isNaN(price) ? 0 : price, // Ensure a valid number is set
              seat: isNaN(seat) ? 0 : seat, // Ensure a valid number is set
            };
          });

          const tempCalArray = await Promise.all(calPromises);

          setCalendarData(tempCalArray);

          const calPromisesForNotRun = response.data.data.seat_unavailable.map(async item => {
            const date = item?.date ? new Date(item?.date) : null;
            let withTFormat = date.toLocaleDateString("en-IN");
            setSelectedDates1([...selectedDates1, withTFormat]);
            return {
              id: item?.id,
              date: date,
              newFormatDate: formatdateFunc(date),
              status: "Sold Out",
            };
          });

          const tempCalArrayForNotRun = await Promise.all(calPromisesForNotRun);

          setCalendarData1(tempCalArrayForNotRun);

          // let tempItineraryArray = [...itineraryArray];
          let tempItineraryArray = [];
          if (response.data.data.itinerary.length > 0) {
            response.data.data.itinerary.map(item => {
              tempItineraryArray.push({
                itinerary_id: item?.itinerary_id,
                placeName: item?.place_name,
                placeCityId: item?.place_name,
                itineraryTitle: item?.itinerary_title,
                itineraryDescription: item?.itinerary_description,
              });
            });
          } else {
            tempItineraryArray = [];
          }

          setItineraryArray(tempItineraryArray);

          let tempInclusionArray = [];
          if (response.data.data.itinerary.length > 0) {
            await Promise.all(
              response.data.data.itinerary.map(async (item, index) => {
                let inclusionMainArray = [];
                let inclusionObject = {};
                function convertDatetimeFormat(inputDatetimeString) {
                  const inputDate = new Date(inputDatetimeString);

                  const year = inputDate.getFullYear();
                  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
                  const day = String(inputDate.getDate()).padStart(2, "0");
                  const hours = String(inputDate.getHours()).padStart(2, "0");
                  const minutes = String(inputDate.getMinutes()).padStart(2, "0");

                  return `${year}-${month}-${day}T${hours}:${minutes}`;
                }
                // Check if trains are available
                if (item.flights && item.flights.length > 0) {
                  inclusionObject.flightId = item.flights[0].id;
                  inclusionObject.departureCity = item.flights[0].depart_destination.id;
                  inclusionObject.arrivalCity = item.flights[0].arrive_destination.id;
                  inclusionObject.flightClass = item.flights[0].flight_class;
                  inclusionObject.flightNumber = item.flights[0].flight_number;
                  inclusionObject.flightNonStop = item.flights[0].flight_nonstop;
                  inclusionObject.departureTime = item.flights[0].depart_datetime ? item.flights[0].depart_datetime : null;
                  inclusionObject.arrivalTime = item.flights[0].arrive_datetime ? item.flights[0].arrive_datetime : null;
                  inclusionObject.flightOvernight = item.flights[0]?.number_0f_nights;
                  // inclusionObject.flightNightCount = item.flights[0]?.flightNightCount ? item.flights[0].flightNightCount : 1;
                  // if (item.flights[0]?.flightNightCount) {
                  //   inclusionObject.flightOvernight = item.flights[0]?.flightNightCount ? 1 : 0;
                  // }
                  // inclusionObject.departureTime = item.flights[0].depart_datetime ? convertDatetimeFormat(item.flights[0].depart_datetime) : null;
                  // inclusionObject.arrivalTime = item.flights[0].arrive_datetime ? convertDatetimeFormat(item.flights[0].arrive_datetime) : null;
                  // inclusionObject.trainNumber = item.flights[0].train_number;
                  // inclusionObject.trainClass = item.flights[0].class;
                  // inclusionObject.trainName = item.flights[0].train_name;
                  // inclusionObject.trainArrivalCity = item.flights[0].to_station;
                  // inclusionObject.trainDepartureCity = item.flights[0].from_station;
                  inclusionMainArray.push({
                    day: index,
                    itinerary_id: item?.itinerary_id,
                    flightId: item.flights[0].id,
                    tab: "Flight",
                    values: inclusionObject,
                  });
                }

                // Check if trains are available
                if (item.trains && item.trains.length > 0) {
                  inclusionObject.trainId = item.trains[0].id;
                  inclusionObject.trainNumber = item.trains[0].train_number;
                  inclusionObject.trainClass = item.trains[0].class;
                  inclusionObject.trainName = item.trains[0].train_name;
                  inclusionObject.trainArrivalCity = item.trains[0].arrive_destination.id;
                  inclusionObject.trainDepartureCity = item.trains[0].depart_destination.id;

                  // inclusionObject.trainArrivalCity = parseInt(item.trains[0].arrive_destination.id);
                  // inclusionObject.trainDepartureCity = parseInt(item.trains[0].depart_destination.id);

                  // inclusionObject.trainDepartureCity = item.trains[0].from_station ? parseInt(item.trains[0].from_station) : null;
                  inclusionObject.trainDepartureTime = item.trains[0].depart_datetime ? item.trains[0].depart_datetime : null;
                  inclusionObject.trainArrivalTime = item.trains[0].arrive_datetime ? item.trains[0].arrive_datetime : null;
                  inclusionObject.trainOvernight = item.trains[0]?.number_of_nights;
                  // inclusionObject.trainNightCount = item.trains[0]?.trainNightCount ? item.trains[0].trainNightCount : 1;
                  // if (item.trains[0]?.trainNightCount) {
                  //   inclusionObject.trainOvernight = item.trains[0]?.trainNightCount ? 1 : 0;
                  // }
                  // inclusionObject.trainDepartureTime = item.trains[0].depart_datetime ? convertDatetimeFormat(item.trains[0].depart_datetime) : null;
                  // inclusionObject.trainArrivalTime = item.trains[0].arrive_datetime ? convertDatetimeFormat(item.trains[0].arrive_datetime) : null;
                  inclusionObject.hotelName = item.trains[0].name;
                  inclusionObject.hotelStar = item.trains[0].rating;
                  inclusionMainArray.push({
                    day: index,
                    itinerary_id: item?.itinerary_id,
                    trainId: item.trains[0].id,
                    tab: "Train",
                    values: inclusionObject,
                  });
                }

                if (item.meal) {
                  let mealValues = item.meal.split(",");
                  let mealObject = {
                    day: index,
                    itinerary_id: item?.itinerary_id,
                    tab: "Meal",
                    values: {},
                  };

                  if (mealValues.includes("breakfast")) {
                    mealObject.values.breakfast = "breakfast";
                  }

                  if (mealValues.includes("lunch")) {
                    mealObject.values.lunch = "lunch";
                  }

                  if (mealValues.includes("dinner")) {
                    mealObject.values.dinner = "dinner";
                  }

                  inclusionMainArray.push(mealObject);
                }

                // Check if sightseeing are available
                if (item.sightseeing && item.sightseeing.length > 0) {
                  let inclusionObject = {
                    sightSeeingImages: [],
                  };
                  if (item.sightseeing[0].morning) {
                    inclusionObject.morning = "morning";
                    inclusionObject.morningText = item.sightseeing[0].morning;
                  }
                  if (item.sightseeing[0].afternoon) {
                    inclusionObject.afternoon = "afternoon";
                    inclusionObject.afternoonText = item.sightseeing[0].afternoon;
                  }
                  if (item.sightseeing[0].evening) {
                    inclusionObject.evening = "evening";
                    inclusionObject.eveningText = item.sightseeing[0].evening;
                  }
                  if (item.sightseeing[0].night) {
                    inclusionObject.night = "night";
                    inclusionObject.nightText = item.sightseeing[0].night;
                  }
                  inclusionObject.sightseeingId = item.sightseeing[0].id;

                  if (item.sightseeing[0]?.sightseeing_gallery?.length > 0) {
                    let tempSightseeingImages = [];

                    const fetchAndAppendImage = async (imageUrl, imageId, imageIndex) => {
                      try {
                        const response = await fetch(imageUrl);
                        const blob = await response.blob();
                        // Determine file extension from imageUrl
                        const fileExtension = imageUrl.split(".").pop(); // Get extension from URL
                        const file = new File([blob], `${imageId}.${fileExtension}`, {type: `image/${fileExtension}`});
                        tempSightseeingImages.push({id: imageId, preview: imageUrl, file});
                      } catch (error) {
                        console.error("Error fetching image:", error);
                      }
                    };

                    const promises = item.sightseeing[0]?.sightseeing_gallery?.map(async (image, imageIndex) => {
                      const imageUrl = IMAGE_URL + image.path;
                      if (imageUrl) {
                        await fetchAndAppendImage(imageUrl, image.id, imageIndex);
                      }
                    });

                    // Wait for all promises to be resolved before continuing
                    await Promise.all(promises);

                    inclusionObject.sightSeeingImages = tempSightseeingImages;
                  }

                  inclusionMainArray.push({
                    day: index,
                    itinerary_id: item?.itinerary_id,
                    sightseeingId: item.sightseeing[0].id,
                    tab: "Sightseeing",
                    values: inclusionObject,
                  });
                }

                // Check if local transport are available
                if (item.local_transport && item.local_transport.length > 0) {
                  inclusionObject.localTransportId = item.local_transport[0].id;
                  inclusionObject.carType = item.local_transport[0].car;
                  inclusionObject.carModel = item.local_transport[0].model;
                  inclusionObject.carCapacity = item.local_transport[0].capacity;
                  inclusionObject.localCarAc = item.local_transport[0].AC == "1" ? true : false;

                  inclusionMainArray.push({
                    day: index,
                    itinerary_id: item?.itinerary_id,
                    localTransportId: item.local_transport[0].id,
                    tab: "Local Transport",
                    values: inclusionObject,
                  });
                }

                // Check if hotel are available
                if (item.hotels && item.hotels.length > 0) {
                  let tempHotelPrevImages = [];
                  let inclusionObject = {
                    hotelId: item.hotels[0].hotel_id,
                    hotelName: item.hotels[0].hotel_name,
                    hotelStar: item.hotels[0].star,
                    hotelCheckbox: item.hotels[0].is_other_place,
                    hotelPlaceName: item.hotels[0].place_name,
                    hotelDistance: item.hotels[0].distance_from_main_town,
                    hotelImages: [],
                  };

                  if (item.hotels[0]?.hotel_gallery?.length > 0) {
                    const fetchAndAppendImage = async (imageUrl, imageId, imageIndex) => {
                      try {
                        const response = await fetch(imageUrl);
                        const blob = await response.blob();
                        // Determine file extension from imageUrl
                        const fileExtension = imageUrl.split(".").pop(); // Get extension from URL
                        const file = new File([blob], `${imageId}.${fileExtension}`, {type: `image/${fileExtension}`});
                        tempHotelPrevImages.push({id: imageId, preview: imageUrl, file});
                      } catch (error) {
                        console.error("Error fetching image:", error);
                      }
                    };

                    const promises = item.hotels[0]?.hotel_gallery?.map(async (image, imageIndex) => {
                      const imageUrl = IMAGE_URL + image.path;
                      if (imageUrl) {
                        await fetchAndAppendImage(imageUrl, image.id, imageIndex);
                      }
                    });

                    // Wait for all promises to be resolved before continuing
                    await Promise.all(promises);

                    inclusionObject.hotelImages = tempHotelPrevImages;
                  } else {
                  }

                  inclusionMainArray.push({
                    day: index,
                    itinerary_id: item?.itinerary_id,
                    hotelId: item.hotels[0].hotel_id,
                    tab: "Hotel",
                    values: inclusionObject,
                  });
                } else {
                }

                tempInclusionArray.push(inclusionMainArray);
              })
            );
          }

          let flattenedArray = [].concat(...tempInclusionArray);

          const typeToIndex = {
            Flight: 0,
            Train: 1,
            Hotel: 2,
            Meal: 3,
            Sightseeing: 4,
            "Local Transport": 5,
          };

          flattenedArray.sort((a, b) => typeToIndex[a.tab] - typeToIndex[b.tab]);

          setInclusions(flattenedArray);
        }
        setScreenLoading(false);
      }
    } catch (error) {
      setScreenLoading(false);
    }
  };

  const handleDragEnter = e => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  // const handleDrop = e => {
  //   e.preventDefault();
  //   setDragging(false);

  //   const droppedFiles = e.dataTransfer.files;
  //   const newImages = Array.from(droppedFiles).map(file => ({
  //     file,
  //     preview: URL.createObjectURL(file),
  //   }));

  //   const validatedImages = newImages.filter(image => {
  //     const extension = image.file.name.split(".").pop().toLowerCase();
  //     const allowedExtensions = ["png", "jpeg", "jpg"];
  //     const allowedDimension = 800;
  //     const maxSizeInBytes = 400 * 1024; // 400kb

  //     // Check file extension
  //     if (!allowedExtensions.includes(extension)) {
  //       NotificationManager.warning(`File '${image.file.name}' has invalid extension. Allowed extensions are png, jpeg, and jpg.`);
  //       return false;
  //     }

  //     // Check image dimensions
  //     // const img = new Image();
  //     // img.src = image.preview;
  //     // img.onload = () => {
  //     //   if (img.width !== allowedDimension || img.height !== allowedDimension) {
  //     //     NotificationManager.warning(`Image '${image.file.name}' dimensions should be ${allowedDimension}x${allowedDimension} pixels.`);
  //     //     return false;
  //     //   }
  //     // };

  //     // Check file size
  //     if (image.file.size > maxSizeInBytes) {
  //       NotificationManager.warning(`File '${image.file.name}' size exceeds the maximum allowed size of 400kb.`);
  //       return false;
  //     }

  //     return true;
  //   });

  //   setSection1Images(prevImages => [...prevImages, ...validatedImages]);
  // };

  const handleFiles = files => {
    if (section1Images.length + section1PrevImages.length + files?.length > 5) {
      NotificationManager.warning(`You can upload a maximum of 5 images.`);
      return;
    }

    const newImages = Array.from(files).map(file => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    const allowedExtensions = ["png", "jpeg", "jpg"];
    const allowedDimension = 800;
    const maxSizeInBytes = 1024 * 1024; // 1mb

    const validateImage = image => {
      return new Promise((resolve, reject) => {
        const extension = image.file.name.split(".").pop().toLowerCase();

        // Check file extension
        if (!allowedExtensions.includes(extension)) {
          NotificationManager.warning(`File '${image.file.name}' has an invalid extension. Allowed extensions are png, jpeg, and jpg.`);
          resolve(false);
        }

        // Check image dimensions
        const img = new Image();
        img.onload = () => {
          // if (img.width < allowedDimension || img.height < allowedDimension) {
          //   NotificationManager.warning(`Image '${image.file.name}' dimensions should be ${allowedDimension}x${allowedDimension} pixels.`);
          //   resolve(false);
          // } else {
          // Check file size
          if (image.file.size > maxSizeInBytes) {
            NotificationManager.warning(`File '${image.file.name}' size exceeds the maximum allowed size of 1mb.`);
            resolve(false);
          } else {
            resolve(true);
          }
          // }
        };
        img.onerror = () => {
          resolve(false);
        };
        img.src = image.preview;
      });
    };

    const validatedImagesPromises = newImages.map(image => validateImage(image));

    Promise.all(validatedImagesPromises)
      .then(validatedResults => {
        const validatedImages = newImages.filter((_, index) => validatedResults[index]);
        setSection1Images(prevImages => [...prevImages, ...validatedImages]);
      })
      .catch(error => {
        console.error("Error validating images:", error);
      });
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = e => {
    handleFiles(e.target.files);
  };

  const handleDrop = e => {
    e.preventDefault();
    setDragging(false);
    handleFiles(e.dataTransfer.files);
    // const droppedFiles = e.dataTransfer.files;
    // const newImages = Array.from(droppedFiles).map(file => ({
    //   file,
    //   preview: URL.createObjectURL(file),
    // }));

    // const allowedExtensions = ["png", "jpeg", "jpg"];
    // const allowedDimension = 800;
    // const maxSizeInBytes = 1024 * 1024; // 1mb

    // const validateImage = image => {
    //   return new Promise((resolve, reject) => {
    //     const extension = image.file.name.split(".").pop().toLowerCase();

    //     // Check file extension
    //     if (!allowedExtensions.includes(extension)) {
    //       NotificationManager.warning(`File '${image.file.name}' has an invalid extension. Allowed extensions are png, jpeg, and jpg.`);
    //       resolve(false);
    //     }

    //     // Check image dimensions
    //     const img = new Image();
    //     img.onload = () => {
    //       // if (img.width < allowedDimension || img.height < allowedDimension) {
    //       //   NotificationManager.warning(`Image '${image.file.name}' dimensions should be ${allowedDimension}x${allowedDimension} pixels.`);
    //       //   resolve(false);
    //       // } else {
    //       // Check file size
    //       if (image.file.size > maxSizeInBytes) {
    //         NotificationManager.warning(`File '${image.file.name}' size exceeds the maximum allowed size of 400kb.`);
    //         resolve(false);
    //       } else {
    //         resolve(true);
    //       }
    //       // }
    //     };
    //     img.onerror = () => {
    //       // NotificationManager.warning(`Failed to load image '${image.file.name}'.`);
    //       resolve(false);
    //     };
    //     img.src = image.preview;
    //   });
    // };

    // const validatedImagesPromises = newImages.map(image => validateImage(image));

    // Promise.all(validatedImagesPromises)
    //   .then(validatedResults => {
    //     const validatedImages = newImages.filter((_, index) => validatedResults[index]);
    //     setSection1Images(prevImages => [...prevImages, ...validatedImages]);
    //   })
    //   .catch(error => {
    //     console.error("Error validating images:", error);
    //   });
  };

  const handleRemoveImage = (e, index) => {
    e.stopPropagation();
    const updatedImages = [...section1Images];
    updatedImages.splice(index, 1);
    setSection1Images(updatedImages);
  };

  const handleRemovePrevImage = async (e, index) => {
    e.stopPropagation();
    try {
      let response = await deleteGalleryImageApi(section1PrevImages[index].id);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          setSection1PrevImages(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
          });
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddMediaLink = () => {
    // setMediaLinks(prevLinks => [...prevLinks, ""]);
    const updatedLinks = [...mediaLinks];
    updatedLinks[0] = "";
    setMediaLinks(updatedLinks);
  };

  const handlePrevRemoveMediaLink = async index => {
    try {
      let response = await deleteMediaLinkApi(mediaLinks[index].id);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          const updatedLinks = [...mediaLinks];
          updatedLinks.splice(index, 1);
          setMediaLinks(updatedLinks);
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {}
  };

  const handleRemoveMediaLink = index => {
    const updatedLinks = [...mediaLinks];
    updatedLinks.splice(index, 1);
    setMediaLinks(updatedLinks);
  };

  const handleMediaLinkAdd = e => {
    const isValidYoutubeLink = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/).+$/;
    const isValidVimeoLink = /^(https?:\/\/)?(www\.)?(vimeo\.com\/)(\d{8,})$/;

    if (mediaLinksText) {
      if (isValidYoutubeLink.test(mediaLinksText)) {
        const updatedLinks = [...mediaLinks];
        updatedLinks.push({media_link: mediaLinksText});
        setMediaLinks(updatedLinks);
        setMediaLinksText("");
      } else {
        NotificationManager.warning("Please enter a valid YouTube or Vimeo link.");
      }
    } else {
      NotificationManager.warning("Please enter value to add media links.");
    }
  };

  const handleInclusionAdd = e => {
    if (inclutionText) {
      const updatedArray = [...inclustionList];
      updatedArray.push({text: inclutionText});
      setInclustionList(updatedArray);
      setInclutionText("");
      setNewInclustionSec(false);
    } else {
      NotificationManager.warning("Please enter value to add inclusion.");
    }
  };

  const handleExclusionAdd = e => {
    if (exclusionText) {
      const updatedArray = [...exclusionList];
      updatedArray.push({text: exclusionText});
      setExclusionList(updatedArray);
      setExclusionText("");
      setNewExclutionSec(false);
    } else {
      NotificationManager.warning("Please enter value to add exclusion.");
    }
  };

  const handleMediaLinkChange = (index, value) => {
    const updatedLinks = [...mediaLinks];

    if (updatedLinks[index].id) {
      updatedLinks[index] = {id: updatedLinks[index].id, media_link: value};
    } else {
      updatedLinks[index] = {media_link: value};
    }

    setMediaLinks(updatedLinks);
  };

  const handleAddMediaLink2 = () => {
    setInclustionList(prevLinks2 => [...prevLinks2, ""]);
  };

  const handleRemovePrevInclusionFromList = async index => {
    try {
      const updatedLinks2 = [...inclustionList];
      // Api call
      let response = await deleteInclusionListApi(updatedLinks2[index].id);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          updatedLinks2.splice(index, 1);
          setInclustionList(updatedLinks2);
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {}
  };

  const handleRemoveInclusionFromList = index => {
    const updatedLinks2 = [...inclustionList];
    updatedLinks2.splice(index, 1);
    setInclustionList(updatedLinks2);
  };

  const handleInclusionChange = (index, value) => {
    const updatedLinks2 = [...inclustionList];
    if (updatedLinks2[index].id) {
      updatedLinks2[index] = {id: updatedLinks2[index].id, text: value};
    } else {
      updatedLinks2[index] = {text: value};
    }

    setInclustionList(updatedLinks2);
  };

  const handleAddMediaLink3 = () => {
    setExclusionList(prevLinks3 => [...prevLinks3, ""]);
  };

  const handleRemovePrevExclusionFromList = async index => {
    try {
      const updatedLinks3 = [...exclusionList];
      // Api call
      let response = await deleteExclusionListApi(updatedLinks3[index].id);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          updatedLinks3.splice(index, 1);
          setExclusionList(updatedLinks3);
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {}
  };

  const handleRemoveExclusionFromList = index => {
    const updatedLinks3 = [...exclusionList];
    updatedLinks3.splice(index, 1);
    setExclusionList(updatedLinks3);
  };

  const handleExclusionChange = (index, value) => {
    const updatedLinks3 = [...exclusionList];

    if (updatedLinks3[index].id) {
      updatedLinks3[index] = {id: updatedLinks3[index].id, text: value};
    } else {
      updatedLinks3[index] = {text: value};
    }

    setExclusionList(updatedLinks3);
  };

  // const handleIncrementDays = () => {
  //   setTotalDays(prevDays => prevDays + 1);
  // };

  // const handleDecrementDays = () => {
  //   if (totalDays > 1) {
  //     setTotalDays(prevDays => prevDays - 1);
  //   }
  // };

  const handleIncrementDays = () => {
    setItineraryArray([...itineraryArray, {}]); // Add a new day when incrementing
  };

  const handleDecrementDays = async () => {
    if (itineraryArray.length > 0) {
      const lastItineraryId = itineraryArray[itineraryArray.length - 1]?.itinerary_id;
      const updatedInclusions = inclusions.filter(inclusion => inclusion.day !== itineraryArray.length - 1);
      setInclusions(updatedInclusions);

      // if (lastItineraryId) {
      //   try {
      //     // Api call
      //     let response = await deleteItineraryApi(lastItineraryId);
      //     if (response) {
      //       if (response.data.res === true) {
      //         NotificationManager.success(response.data.msg);

      //         getPackageDetails();
      //       } else {
      //         NotificationManager.error(response.data.msg);
      //       }
      //     }
      //   } catch (error) {}
      // }
      setItineraryArray(itineraryArray.slice(0, -1)); // Remove the last day when decrementing
    }
  };

  const handleTabClick = tab => {
    setSelectedTab(tab);
  };

  const handleAddInclusionForDay = dayIndex => {
    setSelectedDay(dayIndex);
    setEditingValues({});
    setShowPopup(true);
  };

  useEffect(() => {
    // Set the visibility based on the rating of the current instance
    setRatingVisible(popupRatings[popupIdentifier - 1] > 0);
  }, [popupRatings, popupIdentifier]);

  const handlePopupRatingChange = newRating => {
    // Handle the rating change for the specific instance
    const updatedRatings = [...popupRatings];
    updatedRatings[popupIdentifier - 1] = newRating;
    setPopupRatings(updatedRatings);
    // ... (any additional logic you want to perform)
  };

  const handleAddInclusion = fromTab => {
    if (selectedDay !== null) {
      if (
        fromTab === "flight" &&
        // !editingValues?.flightType ||
        // !editingValues?.flightNumber ||
        // !editingValues?.flightClass ||
        (!editingValues?.departureCity ||
          !editingValues?.arrivalCity ||
          // !editingValues?.departureDate ||
          !editingValues?.departureTime ||
          // !editingValues?.arrivalDate ||
          !editingValues?.arrivalTime)
      ) {
        // Display an error message or handle the validation error as needed
        NotificationManager.warning("Please fill in all flight required fields");
        return;
      }

      if (
        fromTab === "train" &&
        (!editingValues?.trainName ||
          !editingValues?.trainNumber ||
          !editingValues?.trainClass ||
          !editingValues?.trainDepartureCity ||
          !editingValues?.trainArrivalCity ||
          // !editingValues?.trainDepartureDate ||
          !editingValues?.trainDepartureTime ||
          // !editingValues?.trainArrivalDate ||
          !editingValues?.trainArrivalTime)
      ) {
        // Display an error message or handle the validation error as needed
        NotificationManager.warning("Please fill in all train required fields");
        return;
      }

      if (
        fromTab === "hotel" &&
        (!editingValues?.hotelName ||
          !editingValues?.hotelStar ||
          editingValues?.hotelStar === 0 ||
          !editingValues?.hotelImages ||
          editingValues?.hotelImages.length === 0 ||
          (editingValues?.hotelCheckbox === 1 && !editingValues?.hotelPlaceName) ||
          (editingValues?.hotelCheckbox === 1 && !editingValues?.hotelDistance))
      ) {
        // Display an error message or handle the validation error as needed
        NotificationManager.warning("Please fill in all required fields for the Hotel section");
        return;
      }

      if (fromTab === "meal" && !editingValues.breakfast && !editingValues.lunch && !editingValues.dinner) {
        // Display an error message or handle the validation error as needed
        NotificationManager.warning("Please select at least one meal type");
        return;
      }

      if (fromTab === "sightseeing" && !editingValues.morning && !editingValues.afternoon && !editingValues.evening && !editingValues.night) {
        // Display an error message or handle the validation error as needed
        NotificationManager.warning("Please check at least one activity");
        return;
      }

      if (
        (fromTab === "sightseeing" && editingValues?.morning && !editingValues.morningText) ||
        (editingValues?.afternoon && !editingValues.afternoonText) ||
        (editingValues?.evening && !editingValues.eveningText) ||
        (editingValues?.night && !editingValues.nightText)
      ) {
        // Display an error message or handle the validation error as needed
        NotificationManager.warning("Please provide details for the checked activities");
        return;
      }

      if (fromTab === "localtransport" && (!editingValues.carType || !editingValues.carModel || !editingValues.carCapacity)) {
        // Display an error message or handle the validation error as needed
        NotificationManager.warning("Please fill in all required fields for the Local Transport section");
        return;
      }

      const existingInclusionIndex = inclusions.findIndex(inc => inc.day === selectedDay && inc.tab === selectedTab);

      const updatedInclusions = [...inclusions];

      const typeToIndex = {
        Flight: 0,
        Train: 1,
        Hotel: 2,
        Meal: 3,
        Sightseeing: 4,
        "Local Transport": 5,
      };

      if (existingInclusionIndex !== -1) {
        // Update existing inclusion
        updatedInclusions[existingInclusionIndex] = {
          day: selectedDay,
          tab: selectedTab,
          values: editingValues,
        };
      } else {
        // Add new inclusion
        updatedInclusions.push({
          day: selectedDay,
          tab: selectedTab,
          values: editingValues,
        });
      }

      updatedInclusions.sort((a, b) => typeToIndex[a.tab] - typeToIndex[b.tab]);

      setInclusions(updatedInclusions);
      setEditingValues({});
      setShowPopup(false);
      // setSelectedDay(null);

      // If you want to display the rating when an inclusion is added, set the visibility here
      setRatingVisible(popupRatings[popupIdentifier - 1] > 0);
    }
  };

  const handleEditInclusion = index => {
    const selectedInclusion = inclusions[index];

    setSelectedDay(selectedInclusion.day);
    setSelectedTab(selectedInclusion.tab);
    setEditingValues({...selectedInclusion.values});
    if (selectedInclusion?.values?.hotelPrevImages?.length > 0) {
      if (selectedInclusion?.values?.hotelPrevImages) {
        setSection2PrevImages(selectedInclusion?.values?.hotelPrevImages);
      }
    }
    if (selectedInclusion?.values?.sightseeingPrevImages?.length > 0) {
      if (selectedInclusion?.values?.sightseeingPrevImages) {
        setSection3PrevImages(selectedInclusion?.values?.sightseeingPrevImages);
      }
    }
    if (selectedInclusion?.values?.hotelImages?.length > 0) {
      setSection2Images(selectedInclusion?.values?.hotelImages);
    } else {
      setSection2Images([]);
    }
    if (selectedInclusion?.values?.sightSeeingImages?.length > 0) {
      setSection3Images(selectedInclusion?.values?.sightSeeingImages);
    } else {
      setSection3Images([]);
    }
    setShowPopup(true);
  };

  const formatToYYYYMMDD = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleInputChange = (field, value) => {
    // const formattedDate = value ? formatToYYYYMMDD(value) : "";
    setEditingValues({
      ...editingValues,
      [field]: value,
    });
  };

  const handleRemoveInclusion = async index => {
    // const updatedInclusions = [...inclusions];
    // return;
    if (inclusions[index].tab === "Flight" && inclusions[index].flightId) {
      // try {
      //   // Api call
      //   let response = await deleteFlightApi(inclusions[index].flightId);
      //   if (response) {
      //     if (response.data.res === true) {
      //       NotificationManager.success(response.data.msg);
      //     } else {
      //       NotificationManager.error(response.data.msg);
      //     }
      //   }
      // } catch (error) {}
    }
    if (inclusions[index].tab === "Sightseeing" && inclusions[index].sightseeingId) {
      // try {
      //   // Api call
      //   let response = await deleteSightseeingApi(inclusions[index].sightseeingId);
      //   if (response) {
      //     if (response.data.res === true) {
      //       NotificationManager.success(response.data.msg);
      //     } else {
      //       NotificationManager.error(response.data.msg);
      //     }
      //   }
      // } catch (error) {}
    }
    if (inclusions[index].tab === "Meal" && inclusions[index].itinerary_id) {
      // try {
      //   // Api call
      //   let response = await deleteMealApi(inclusions[index].itinerary_id);
      //   if (response) {
      //     if (response.data.res === true) {
      //       NotificationManager.success(response.data.msg);
      //     } else {
      //       NotificationManager.error(response.data.msg);
      //     }
      //   }
      // } catch (error) {}
    }
    if (inclusions[index].tab === "Hotel" && inclusions[index].hotelId) {
      // try {
      //   // Api call
      //   let response = await deleteHotelApi(inclusions[index].hotelId);
      //   if (response) {
      //     if (response.data.res === true) {
      //       NotificationManager.success(response.data.msg);
      //     } else {
      //       NotificationManager.error(response.data.msg);
      //     }
      //   }
      // } catch (error) {}
    }
    if (inclusions[index].tab === "Train" && inclusions[index].trainId) {
      // try {
      //   // Api call
      //   let response = await deleteTrainApi(inclusions[index].trainId);
      //   if (response) {
      //     if (response.data.res === true) {
      //       NotificationManager.success(response.data.msg);
      //     } else {
      //       NotificationManager.error(response.data.msg);
      //     }
      //   }
      // } catch (error) {}
    }
    if (inclusions[index].tab === "Local Transport" && inclusions[index].localTransportId) {
      // try {
      //   // Api call
      //   let response = await deleteLocalTransportApi(inclusions[index].localTransportId);
      //   if (response) {
      //     if (response.data.res === true) {
      //       NotificationManager.success(response.data.msg);
      //     } else {
      //       NotificationManager.error(response.data.msg);
      //     }
      //   }
      // } catch (error) {}
    }
    const updatedInclusions = [...inclusions];
    updatedInclusions.splice(index, 1);
    setInclusions(updatedInclusions);
  };

  const handleImagesChange = (newImages, sectionTitle) => {
    if (sectionTitle === "Section 1") {
      setSection1Images(newImages);
    }
    if (sectionTitle === "Section 3") {
      setSection3Images(newImages);
      setEditingValues({
        ...editingValues,
        sightSeeingImages: newImages,
      });
      setSection3Images([]);
    } else if (sectionTitle === "Section 2") {
      setSection2Images(newImages);
      setEditingValues({
        ...editingValues,
        hotelImages: newImages,
      });
      setSection2Images([]);
    }
    // You can perform any other necessary actions here if needed
  };

  const handleAddOnClick = () => {
    setAddOns([...addOns, {title: "", price: "", description: ""}]);
  };

  const handleAddBlukDiscClick = () => {
    setBulkDiscountsArray([...bulkDiscountsArray, {pax: "", percent: ""}]);
  };

  const handleDeletePrevAddOnClick = async index => {
    try {
      const updatedAddOns = [...addOns];
      // Api call
      let response = await deleteAddonsApi(updatedAddOns[index]?.id);
      if (response) {
        if (response.data.res === true) {
          updatedAddOns.splice(index, 1);
          setAddOns(updatedAddOns);
          NotificationManager.success(response.data.msg);
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {}
  };

  const handleDeleteAddOnClick = index => {
    const updatedAddOns = [...addOns];
    updatedAddOns.splice(index, 1);
    setAddOns(updatedAddOns);
  };

  const handleDeleteBulkDiscOnClick = index => {
    const updatedBulkDiscounts = [...bulkDiscountsArray];
    updatedBulkDiscounts.splice(index, 1);
    setBulkDiscountsArray(updatedBulkDiscounts);
  };

  const handleInputChange3 = (index, field, value) => {
    const updatedAddOns = [...addOns];
    updatedAddOns[index][field] = value;
    setAddOns(updatedAddOns);
  };

  const getCityList = async stateId => {
    try {
      const formData = new FormData();
      if (stateId) {
        formData.append("state[]", stateId);
      }
      let response = await getCityListApi(formData);
      if (response) {
        if (response.data.res === true) {
          // setCityList(response.data.data);
          let tempArray = [];
          response.data.data.map(item => tempArray.push(item?.city));
          setCityList(tempArray);
        } else {
        }
      }
    } catch (error) {}
  };

  const getAllCityList = async () => {
    try {
      let response = await getCityListApi();
      if (response) {
        if (response.data.res === true) {
          setAllCityList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getTripList = async () => {
    try {
      let response = await getTripListApi();
      if (response) {
        if (response.data.res === true) {
          // setTripList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getTypeOfTourPackagesList = async () => {
    try {
      let response = await getTypeOfTourPackagesListApi();
      if (response) {
        if (response.data.res === true) {
          // setTypeOfTourPackagesList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getThemesList = async () => {
    try {
      let response = await getThemesListApi();
      if (response) {
        if (response.data.res === true) {
          setThemesList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getStateList = async () => {
    try {
      let response = await getStatesListApi();
      if (response) {
        if (response.data.res === true) {
          setStateList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getTourismCircuits = async () => {
    try {
      let response = await tourismCircuitsApi();
      if (response) {
        if (response.data.res === true) {
          let tempArray = [];
          tempArray = response.data.data.map(item => ({
            value: item.name,
            label: item.name,
          }));
          setTourCircuitList(tempArray);
        } else {
        }
      }
    } catch (error) {}
  };

  const fetchBroadLocations = async () => {
    try {
      let response = await fetchLocationsApi();
      if (response) {
        if (response.data.res === true) {
          let tempArray = [];
          tempArray = response.data.data.map(item => ({
            value: item.id,
            label: item.name,
          }));
          setLocationList(tempArray);
        } else {
        }
      }
    } catch (error) {}
  };

  const fetchInclusions = async () => {
    try {
      let response = await fetchInclusionsApi();
      if (response) {
        if (response.data.res === true) {
          // let tempArray = [];
          // tempArray = response.data.data.map(item => ({
          //   value: item.id,
          //   label: item.name,
          // }));
          setInclusionListFromApi(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const fetchExclusions = async () => {
    try {
      let response = await fetchExclusionsApi();
      if (response) {
        if (response.data.res === true) {
          // let tempArray = [];
          // tempArray = response.data.data.map(item => ({
          //   value: item.id,
          //   label: item.name,
          // }));
          setExclusionListFromApi(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getKeywords = async locationId => {
    try {
      let response = await fetchPlacesApi(locationId);
      if (response) {
        if (response.data.res === true) {
          let tempArray = [];
          // tempArray = response.data.data.map(item => ({
          //   value: item,
          //   label: item,
          // }));
          setKeywordsList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const handleStateChange = event => {
    const {value} = event.target;
    getCityList(value);
  };

  const handleAddPackageInputChange = (field, value) => {
    setAddPackFormData(prevData => ({...prevData, [field]: value}));
  };

  const handleBulkDiscChange = (field, value, index) => {
    let tempArray = [...bulkDiscountsArray];
    tempArray[index][field] = value;
    setBulkDiscountsArray(tempArray);
  };

  const getAvailableBulkOptions = index => {
    const selectedValues = new Set(bulkDiscountsArray.map(item => parseInt(item.pax)));
    return bulkDiscountOptions.filter(
      option => !selectedValues.has(parseInt(option.value)) || parseInt(option.value) === parseInt(bulkDiscountsArray[index].pax)
    );
  };

  const handleitineraryValueChange = (fieldName, dayIndex, value) => {
    let tempArray = [...itineraryArray];
    tempArray[dayIndex][fieldName] = value;
    setItineraryArray(tempArray);
  };

  const handleAddInclusionChange = (inclutype, fieldName, value) => {
    let tempObject = {};
    if (!tempObject[inclutype]) {
      tempObject[inclutype] = {};
    }

    // Set the fieldName on tempObject[inclutype]
    tempObject[inclutype][fieldName] = value;
  };

  const validateLastItinerary = () => {
    // Select all ItineraryLoop elements
    const itineraryLoops = document.querySelectorAll(".addAllIncl .ItineraryLoop");

    // Check if there are any ItineraryLoop elements
    if (itineraryLoops.length === 0) {
      NotificationManager.warning("Please enter Itinerary Information.");
      return false;
    }

    // Get the last ItineraryLoop element
    const lastItineraryLoop = itineraryLoops[itineraryLoops.length - 1];

    // Check if the last ItineraryLoop contains any inclusions with the 'Hotel' class
    const hotelInclusions = lastItineraryLoop.querySelectorAll(".inclusion.Hotel");

    if (hotelInclusions.length > 0) {
      NotificationManager.warning("Remove hotel from last itinerary.");
      return false;
    }

    return true;
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      packageTitle: "",
      tourPackageType: "",
      tripType: "",
      state: "",
      city: "",
      tourCircuit: "",
      location: "",
      origin: "",
      themes: "",
      keywords: "",
      overview: "",
      paymentPolicy: "",
      termsAndCondition: "",
      numberOfPaxBulk: "",
      discountPercentBulk: "",
      basicPriceAboveTwelve: "",
      childPrice: "",
      infantPrice: "",
      basicPriceAdditional: "",
      tripleSharingDiscount: "",
      offSeasonPrice: "",
      onSeasonPrice: "",
    };

    let tempCanPolicyDaysArray = [cancellationPoliciesDefault.from];
    let tempCanPolicyPercentageArray = [cancellationPoliciesDefault.percentage];

    if (cancellationPolicies.length > 0) {
      cancellationPolicies.map(item => {
        tempCanPolicyDaysArray.push(item.from);
        tempCanPolicyDaysArray.push(item.to);
        tempCanPolicyPercentageArray.push(item.percentage);
      });
    }
    let tempCanPolicyDaysError = false;
    let tempCanPolicyPercentageError = false;

    for (let i = 1; i < tempCanPolicyDaysArray.length; i++) {
      let currentValue = parseInt(tempCanPolicyDaysArray[i]);
      let previousValue = parseInt(tempCanPolicyDaysArray[i - 1]);
      if (currentValue >= previousValue) {
        tempCanPolicyDaysError = true;
      }
    }

    for (let i = 1; i < tempCanPolicyPercentageArray.length; i++) {
      let currentValue = parseInt(tempCanPolicyPercentageArray[i]);
      let previousValue = parseInt(tempCanPolicyPercentageArray[i - 1]);
      if (currentValue <= previousValue) {
        tempCanPolicyPercentageError = true;
      }
    }

    let tempPayPolicyDaysArray = [paymentPoliciesDefault.from];
    let tempPayPolicyPercentageArray = [paymentPoliciesDefault.percentage];
    if (paymentPolicies.length > 0) {
      paymentPolicies.map(item => {
        tempPayPolicyDaysArray.push(item.from);
        tempPayPolicyDaysArray.push(item.to);
        tempPayPolicyPercentageArray.push(item.percentage);
      });
    }
    let tempPayPolicyDaysError = false;
    let tempPayPolicyPercentageError = false;

    for (let i = 1; i < tempPayPolicyDaysArray.length; i++) {
      let currentValue = parseInt(tempPayPolicyDaysArray[i]);
      let previousValue = parseInt(tempPayPolicyDaysArray[i - 1]);
      if (currentValue >= previousValue) {
        tempPayPolicyDaysError = true;
      }
    }

    for (let i = 1; i < tempPayPolicyPercentageArray.length; i++) {
      let currentValue = parseInt(tempPayPolicyPercentageArray[i]);
      let previousValue = parseInt(tempPayPolicyPercentageArray[i - 1]);
      if (currentValue <= previousValue) {
        tempPayPolicyPercentageError = true;
      }
    }

    if (addPackformData.packageTitle === "") {
      errors.packageTitle = "Please enter package title.";
      NotificationManager.warning("Please enter package title.");
      isValid = false;
    } else if (addPackformData.tourPackageType === "") {
      errors.tourPackageType = "Please select tour package type.";
      NotificationManager.warning("Please select tour package type.");
      isValid = false;
    } else if (addPackformData.tripType === "") {
      errors.tripType = "Please select trip type.";
      NotificationManager.warning("Please select trip type.");
      isValid = false;
    } else if (addPackformData.state === "") {
      errors.state = "Please select trip state.";
      NotificationManager.warning("Please select trip state.");
      isValid = false;
      // } else if (addPackformData.city === "") {
      //   errors.city = "Please select trip city.";
      //   NotificationManager.warning("Please select trip city.");
      //   isValid = false;
      // } else if (addPackformData.tourCircuit === "") {
      //   errors.tourCircuit = "Please enter tour circuit.";
      //   NotificationManager.warning("Please enter tour circuit.");
      //   isValid = false;
      // } else if (addPackformData.location === "") {
      //   errors.location = "Please enter location.";
      //   NotificationManager.warning("Please enter location.");
      //   isValid = false;
    } else if (!addPackformData.origin) {
      errors.origin = "Please enter origin.";
      NotificationManager.warning("Please enter origin.");
      isValid = false;
    } else if (addPackformData.themes === "") {
      errors.themes = "Please enter themes.";
      NotificationManager.warning("Please enter themes.");
      isValid = false;
      // } else if (addPackformData.keywords === "") {
      //   errors.keywords = "Please enter keywords.";
      //   NotificationManager.warning("Please enter keywords.");
      //   isValid = false;
      // } else if (addPackformData.overview === "") {
      //   errors.overview = "Please enter overview.";
      //   NotificationManager.warning("Please enter overview.");
      //   isValid = false;
    } else if (section1Images.length === 0 && section1PrevImages.length === 0) {
      errors.overview = "Please select atleast one image.";
      NotificationManager.warning("Please select atleast one image.");
      isValid = false;
      // } else if (mediaLinks.length === 0 && prevMediaLinks.length === 0) {
      //   errors.overview = "Please select atleast one media link.";
      //   NotificationManager.warning("Please select atleast media link.");
      //   isValid = false;
    } else if (!addPackformData.basicPriceAboveTwelve) {
      errors.overview = "Please enter package cost for per adult (above 12 yrs)";
      NotificationManager.warning("Please enter package cost for per adult (above 12 yrs)");
      isValid = false;
    } else if (!defaultSeat) {
      NotificationManager.warning("Please enter default seat numbers.");
      isValid = false;
      // } else if (!addPackformData.childPrice) {
      //   errors.overview = "Please enter discount for child (5 to 12 yrs)";
      //   NotificationManager.warning("Please enter discount for child (5 to 12 yrs)");
      //   isValid = false;
      // } else if (addPackformData.childPrice && parseInt(addPackformData.childPrice) > 100) {
      //   errors.overview = "The discount amount for a child cannot exceed 100%.";
      //   NotificationManager.warning("The discount amount for a child cannot exceed 100%.");
      //   isValid = false;
      // } else if (
      //   addPackformData?.basicPriceAboveTwelve &&
      //   addPackformData?.childPrice &&
      //   parseInt(addPackformData.childPrice) > parseInt(addPackformData.basicPriceAboveTwelve)
      // ) {
      //   // errors.overview = "Please enter package cost for per adult (above 12 yrs)";
      //   NotificationManager.warning("The discount amount for a child cannot exceed adult package cost.");
      //   isValid = false;
      // } else if (addPackformData.fromDate && addPackformData.toDate && addPackformData.fromDate > addPackformData.toDate) {
      //   errors.overview = "The discount amount for a child cannot exceed 100%.";
      //   NotificationManager.warning("Off Season from date should not be later than to date.");
      //   isValid = false;
      // } else if (addPackformData.onFromDate && addPackformData.onToDate && addPackformData.onFromDate > addPackformData.onToDate) {
      //   errors.overview = "The discount amount for a child cannot exceed 100%.";
      //   NotificationManager.warning("On Season from date should not be later than to date.");
      //   isValid = false;
      // } else if (bulkDiscountActive && (!addPackformData.discountPercentBulk || !addPackformData.numberOfPaxBulk)) {
      //   errors.overview = "Please fill bulk discount fields.";
      //   NotificationManager.warning("Please fill bulk discount fields.");
      //   isValid = false;
    } else if (seasonPriceRanges?.length > 0 && !seasonPriceRanges.every(value => value.onToDate && value.onFromDate && value.price)) {
      NotificationManager.warning("Please fill On Season/ Off Season fields.");
      isValid = false;
    } else if (bulkDiscountActive && !bulkDiscountsArray.every(value => value.pax && value.percent)) {
      // errors.overview = "Please fill bulk discount fields.";
      NotificationManager.warning("Please fill bulk discount fields.");
      isValid = false;
    } else if (bulkDiscountActive && parseInt(addPackformData.discountPercentBulk) > 100) {
      errors.overview = "Please fill bulk discount fields.";
      NotificationManager.warning("Set Discount Per Cent could not be greater that 100%");
      isValid = false;
    } else if (
      addOns.length > 0 &&
      !addOns.every(value => {
        // Validate that title and description are not empty and do not contain invalid characters
        const isTitleValid = value.title && !/[\/-]/.test(value.title);
        const isDescriptionValid = value.description && !/[\/-]/.test(value.description);

        return isTitleValid && isDescriptionValid;
      })
    ) {
      NotificationManager.warning("Please enter valid Addons details. Titles and descriptions cannot contain '/' or '-'.");
      isValid = false;
      // } else if (!addPackformData.paymentPolicy) {
      //   NotificationManager.warning("Please enter payment policy.");
      //   isValid = false;
    } else if (!paymentPoliciesDefault.from || !paymentPoliciesDefault.percentage) {
      NotificationManager.warning("Please enter payment policy values.");
      isValid = false;
    } else if (paymentPolicies.length > 0 && !paymentPolicies.every(value => value.from && value.to && value.percentage)) {
      NotificationManager.warning("Please enter payment policy values.");
      isValid = false;
    } else if (tempPayPolicyDaysError) {
      NotificationManager.warning("Each payment policy days value should be lesser than the previous value!");
      isValid = false;
    } else if (tempPayPolicyPercentageError) {
      NotificationManager.warning("Each payment policy Percentage value should be greater than the previous value!");
      isValid = false;
    } else if (!cancellationPoliciesDefault.from || !cancellationPoliciesDefault.percentage) {
      NotificationManager.warning("Please enter cancellation policy values.");
      isValid = false;
    } else if (cancellationPolicies.length > 0 && !cancellationPolicies.every(value => value.from && value.to && value.percentage)) {
      NotificationManager.warning("Please enter cancellation policy values.");
      isValid = false;
    } else if (tempCanPolicyDaysError) {
      NotificationManager.warning("Each cancellation days value should be lesser than the previous value!");
      isValid = false;
    } else if (tempCanPolicyPercentageError) {
      NotificationManager.warning("Each cancellation Percentage value should be greater than the previous value!");
      isValid = false;
    } else if (itineraryArray?.length === 0) {
      NotificationManager.warning("Please enter Itinerary Information.");
      isValid = false;
    } else if (itineraryArray?.length > 0) {
      itineraryArray.forEach((item, index) => {
        if (!item.placeCityId || !item.itineraryTitle || !item.itineraryDescription) {
          isValid = false;
          NotificationManager.warning(`Please fill all fields in itinerary day ${index + 1}.`);
        }
      });
    } else {
      isValid = true;
    }

    // Place the call to validateLastItinerary at the end of your main checks
    const lastItineraryValid = validateLastItinerary();
    if (!lastItineraryValid) {
      isValid = false;
    }

    // setValidationErrors(errors);
    return isValid;
  };

  const handleSavePackage = async () => {
    if (validateForm()) {
      setBtnDis(true);
      try {
        addPackformData.images = section1Images;
        addPackformData.active = activePackage;
        addPackformData.addOns = addOns;
        if (addPackformData.fromDate) {
          addPackformData.offSeasonStartDate = formatToYYYYMMDD(addPackformData.fromDate);
        }
        if (addPackformData.toDate) {
          addPackformData.offSeasonEndDate = formatToYYYYMMDD(addPackformData.toDate);
        }
        if (addPackformData.onFromDate) {
          addPackformData.onSeasonStartDate = formatToYYYYMMDD(addPackformData.onFromDate);
        }
        if (addPackformData.onToDate) {
          addPackformData.onSeasonEndDate = formatToYYYYMMDD(addPackformData.onToDate);
        }
        addPackformData.itinerary = itineraryArray;

        const formData = new FormData();

        // itineraryArray?.forEach((element, key) => {
        //   inclusions.forEach((incluElm, incluKey) => {
        //     formData.append(`itinerary[${key}][flights][${incluKey}][depart_destination]`, incluElm.values.departureCity);
        //   });
        // });

        formData.append("package_id", packageId);
        formData.append("total_days", itineraryArray?.length);
        formData.append("destination_state_id", addPackformData?.state ? addPackformData?.state : "");
        formData.append("destination_city_id", addPackformData?.city ? addPackformData?.city : "");
        formData.append("tour_type", addPackformData?.tripType);
        formData.append("trip_type", addPackformData?.tourPackageType);
        formData.append("themes_id", addPackformData?.themes);
        formData.append("overview", addPackformData?.overview ? addPackformData?.overview : "");
        formData.append("payment_policy", addPackformData?.paymentPolicy ? addPackformData?.paymentPolicy : "");
        formData.append("terms_and_condition", addPackformData?.termsAndCondition ? addPackformData?.termsAndCondition : "");
        if (addPackformData?.images?.length > 0) {
          addPackformData?.images?.map(gallItem => {
            formData.append("gallery_images[]", gallItem?.file);
          });
        }
        if (bulkDiscountActive && bulkDiscountsArray?.length > 0) {
          bulkDiscountsArray?.map((discItem, discIndex) => {
            formData.append(`bulk_discount_tiers[${discIndex}][pax]`, discItem?.pax);
            formData.append(`bulk_discount_tiers[${discIndex}][discount]`, discItem?.percent);
          });
        }
        formData.append("name", addPackformData?.packageTitle);
        formData.append("starting_price", addPackformData?.basicPriceAboveTwelve);
        formData.append("child_price", addPackformData?.childPrice ? addPackformData?.childPrice : "");
        formData.append("infant_price", addPackformData?.infantPrice ? addPackformData?.infantPrice : "");
        formData.append("single_occupancy_price", addPackformData?.basicPriceAdditional ? addPackformData?.basicPriceAdditional : "");
        formData.append("triple_occupancy_price", addPackformData?.tripleSharingDiscount ? addPackformData?.tripleSharingDiscount : "");

        // formData.append("offseason_from_date", addPackformData?.offSeasonStartDate ? addPackformData?.offSeasonStartDate : "");
        // formData.append("offseason_to_date", addPackformData?.offSeasonEndDate ? addPackformData?.offSeasonEndDate : "");
        // formData.append("offseason_price", addPackformData?.offSeasonPrice ? addPackformData?.offSeasonPrice : "");
        // formData.append("onseason_from_date", addPackformData?.onSeasonStartDate ? addPackformData?.onSeasonStartDate : "");
        // formData.append("onseason_to_date", addPackformData?.onSeasonEndDate ? addPackformData?.onSeasonEndDate : "");
        // formData.append("onseason_price", addPackformData?.onSeasonPrice ? addPackformData?.onSeasonPrice : "");

        // formData.append("bulk_no_of_pax", addPackformData?.numberOfPaxBulk ? addPackformData?.numberOfPaxBulk : "");
        // formData.append("pax_discount_percent", addPackformData?.discountPercentBulk ? addPackformData?.discountPercentBulk : "");
        formData.append("tour_circuit", addPackformData?.tourCircuit ? addPackformData?.tourCircuit : "");
        formData.append("keywords", addPackformData?.keywords ? addPackformData?.keywords : "");
        formData.append("region_id", addPackformData?.location ? addPackformData?.location : "");
        // formData.append("location", addPackformData?.location);
        formData.append("origin", addPackformData?.origin);
        formData.append("status", activePackage ? "1" : "0");
        formData.append("total_seat", defaultSeat);

        let tempCalenderData = [...calendarData];
        let processedDates = new Set(); // Set to keep track of processed dates

        tempCalenderData = tempCalenderData.filter(item => {
          // Format date if newFormatDate is undefined
          if (!item.newFormatDate) {
            let date = new Date(item.date);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            item.newFormatDate = `${year}-${month}-${day}`;
          }

          if (processedDates.has(item.newFormatDate)) {
            return false; // Remove duplicate item
          } else {
            processedDates.add(item.newFormatDate); // Add newFormatDate to processedDates
            return true;
          }
        });

        // Loop through filtered data to update formData
        tempCalenderData.forEach((item, index) => {
          formData.append(`seat_availability[${index}][date]`, item.newFormatDate);
          formData.append(`seat_availability[${index}][seat]`, item.seat.toString());
          formData.append(`seat_availability[${index}][cost]`, "");
          // formData.append(`seat_availability[${index}][cost]`, item.price);
        });

        let tempCalenderDataForNotRun = [...calendarData1];
        let processedDatesForNotRun = new Set(); // Set to keep track of processed dates

        tempCalenderDataForNotRun = tempCalenderDataForNotRun.filter(item => {
          // Format date if newFormatDate is undefined
          if (!item.newFormatDate) {
            let date = new Date(item.date);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            item.newFormatDate = `${year}-${month}-${day}`;
          }

          if (processedDatesForNotRun.has(item.newFormatDate)) {
            return false; // Remove duplicate item
          } else {
            processedDatesForNotRun.add(item.newFormatDate); // Add newFormatDate to processedDatesForNotRun
            return true;
          }
        });

        // Loop through filtered data to update formData
        tempCalenderDataForNotRun.forEach((item, index) => {
          formData.append(`seat_unavailable[${index}][date]`, item.newFormatDate);
        });

        // let tempAddonWithoutId = [];

        // tempAddonWithoutId = addPackformData?.addOns?.filter(item => !item?.id);

        if (addOns?.length > 0) {
          addOns.forEach((item, index) => {
            Object.entries(item).forEach(([key, value]) => {
              formData.append(`addons[${index}][${key}]`, value);
            });
          });
        }

        if (seasonPriceRanges?.length > 0) {
          seasonPriceRanges?.forEach((item, index) => {
            formData.append(`rates[${index}][start_date]`, formatToYYYYMMDD(item?.onFromDate));
            formData.append(`rates[${index}][end_date]`, formatToYYYYMMDD(item?.onToDate));
            formData.append(`rates[${index}][price]`, item?.price);
          });
        }

        // let tempMediaLinksWithoutId = [];

        // tempMediaLinksWithoutId = mediaLinks?.filter(item => !item?.id);

        mediaLinks?.forEach((item, index) => {
          formData.append(`media_links[${index}]`, item.media_link);
        });

        inclustionList?.forEach((value, index) => {
          formData.append(`inclusion_list[${index}][text]`, value.text);
        });

        exclusionList?.forEach((value, index) => {
          formData.append(`exclusion_list[${index}][text]`, value.text);
        });

        itineraryArray?.forEach((item, index) => {
          formData.append(`itinerary[${index}][place_name]`, item.placeName);
          // formData.append(`itinerary[${index}][place_name]`, item.placeName);
          formData.append(`itinerary[${index}][itinerary_title]`, item.itineraryTitle);
          formData.append(`itinerary[${index}][itinerary_description]`, item.itineraryDescription);
          formData.append(`stay_plan[${index}][cities]`, item.placeName);
        });

        itineraryArray?.forEach((iterItem, iterIndex) => {
          formData.append(`itinerary[${iterIndex}][day]`, "Day" + parseInt(iterIndex + 1));
        });

        // Initialize an array to store the result
        const resultArray = [];

        // Loop through each day in daysArray
        itineraryArray.forEach((day, dayIndex) => {
          // Filter inclusionsArray based on the current day
          const inclusionsForDay = inclusions.filter(inclusion => inclusion.day === dayIndex);

          // Push the result for the current day
          resultArray.push({
            day: dayIndex,
            inclusion: inclusionsForDay,
          });
        });

        // Common function for Y-M-D H:M:S format

        function formatDatetime(inputDatetimeString) {
          const inputDate = new Date(inputDatetimeString);

          const year = inputDate.getFullYear();
          const month = String(inputDate.getMonth() + 1).padStart(2, "0");
          const day = String(inputDate.getDate()).padStart(2, "0");
          const hours = String(inputDate.getHours()).padStart(2, "0");
          const minutes = String(inputDate.getMinutes()).padStart(2, "0");
          const seconds = String(inputDate.getSeconds()).padStart(2, "0");

          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }

        resultArray?.forEach(async (mainItem, mainIndex) => {
          const dayIndex = mainIndex;

          mainItem?.inclusion?.forEach(async (item, index) => {
            // Flights
            if (item.tab === "Flight") {
              formData.append(`itinerary[${dayIndex}][flights][${index}][depart_destination]`, item.values.departureCity);
              formData.append(`itinerary[${dayIndex}][flights][${index}][arrive_destination]`, item.values.arrivalCity);
              formData.append(`itinerary[${dayIndex}][flights][${index}][depart_datetime]`, item.values.departureTime);
              formData.append(`itinerary[${dayIndex}][flights][${index}][arrive_datetime]`, item.values.arrivalTime);
              formData.append(`itinerary[${dayIndex}][flights][${index}][number_of_nights]`, item.values.flightOvernight ? item.values.flightOvernight : 0);
              // if (item.values.flightOvernight === 1) {
              //   formData.append(`itinerary[${dayIndex}][flights][${index}][number_of_nights]`, item.values.flightNightCount ? item.values.flightNightCount : 1);
              // }
              // formData.append(`itinerary[${dayIndex}][flights][${index}][depart_datetime]`, formatDatetime(item.values.departureTime));
              // formData.append(`itinerary[${dayIndex}][flights][${index}][arrive_datetime]`, formatDatetime(item.values.arrivalTime));

              // formData.append(
              //   `itinerary[${dayIndex}][flights][${index}][arrive_date]`,
              //   item.values.arrivalDate ? formatToYYYYMMDD(item.values.arrivalDate) : null
              // );
              // formData.append(
              //   `itinerary[${dayIndex}][flights][${index}][depart_date]`,
              //   item.values.departureDate ? formatToYYYYMMDD(item.values.departureDate) : null
              // );
              // formData.append(`itinerary[${dayIndex}][flights][${index}][depart_time]`, item.values.departureTime);
              // formData.append(`itinerary[${dayIndex}][flights][${index}][arrive_time]`, item.values.arrivalTime);

              // formData.append(`itinerary[${dayIndex}][flights][${index}][flight_class]`, item.values.flightClass);
              // formData.append(`itinerary[${dayIndex}][flights][${index}][flight_number]`, item.values.flightNumber);
              // formData.append(`itinerary[${dayIndex}][flights][${index}][flight_nonstop]`, item.values.flightNonStop);
            }

            // Trains
            if (item.tab === "Train") {
              formData.append(`itinerary[${dayIndex}][trains][${index}][train_number]`, item.values.trainNumber);
              formData.append(`itinerary[${dayIndex}][trains][${index}][class]`, item.values.trainClass);
              formData.append(`itinerary[${dayIndex}][trains][${index}][train_name]`, item.values.trainName);
              formData.append(`itinerary[${dayIndex}][trains][${index}][to_station]`, item.values.trainArrivalCity);
              formData.append(`itinerary[${dayIndex}][trains][${index}][from_station]`, item.values.trainDepartureCity);
              formData.append(`itinerary[${dayIndex}][trains][${index}][depart_datetime]`, item.values.trainDepartureTime);
              formData.append(`itinerary[${dayIndex}][trains][${index}][arrive_datetime]`, item.values.trainArrivalTime);
              formData.append(`itinerary[${dayIndex}][trains][${index}][number_of_nights]`, item.values.trainOvernight ? item.values.trainOvernight : 0);
              // if (item.values.trainOvernight === 1) {
              //   formData.append(`itinerary[${dayIndex}][trains][${index}][number_of_nights]`, item.values.trainNightCount ? item.values.trainNightCount : 1);
              // }
              // formData.append(`itinerary[${dayIndex}][trains][${index}][depart_datetime]`, formatDatetime(item.values.trainDepartureTime));
              // formData.append(`itinerary[${dayIndex}][trains][${index}][arrive_datetime]`, formatDatetime(item.values.trainArrivalTime));

              //   formData.append(
              //     `itinerary[${dayIndex}][trains][${index}][arrive_date]`,
              //     item.values.trainArrivalDate ? formatToYYYYMMDD(item.values.trainArrivalDate) : null
              //   );
              //   formData.append(
              //     `itinerary[${dayIndex}][trains][${index}][depart_date]`,
              //     item.values.trainDepartureDate ? formatToYYYYMMDD(item.values.trainDepartureDate) : null
              //   );
              //   formData.append(`itinerary[${dayIndex}][trains][${index}][depart_time]`, item.values.trainDepartureTime);
              //   formData.append(`itinerary[${dayIndex}][trains][${index}][arrive_time]`, item.values.trainArrivalTime);
            }

            // Hotel
            if (item.tab === "Hotel") {
              formData.append(`itinerary[${dayIndex}][hotel][0][name]`, item.values.hotelName);
              formData.append(`itinerary[${dayIndex}][hotel][0][is_other_place]`, item.values.hotelCheckbox ? item.values.hotelCheckbox : 0);
              formData.append(`itinerary[${dayIndex}][hotel][0][place_name]`, item.values.hotelCheckbox === 1 ? item.values.hotelPlaceName : "");
              formData.append(`itinerary[${dayIndex}][hotel][0][distance_from_main_town]`, item.values.hotelCheckbox === 1 ? item.values.hotelDistance : "");
              formData.append(`itinerary[${dayIndex}][hotel][0][rating]`, item.values.hotelStar);

              item.values.hotelImages.forEach((image, imageIndex) => {
                formData.append(`itinerary[${dayIndex}][hotel][0][gallery_images][${imageIndex}]`, image.file);
                // If you want to append the preview URLs as well, you can do:
                // formData.append(`itinerary[${dayIndex}][hotel][${index}][gallery_images_preview][${imageIndex}]`, image.preview);
              });

              // const fetchAndAppendImage = async (imageUrl, imageId, imageIndex) => {
              //   console.log("Fetching image:", imageUrl, imageId, imageIndex);
              //   try {
              //     const response = await fetch(imageUrl);
              //     const blob = await response.blob();
              //     const file = new File([blob], imageId, {type: "image/*"});
              //     console.log(file);
              //     formData.append(`itinerary[${dayIndex}][hotel][0][gallery_images][${imageIndex}]`, file);
              //   } catch (error) {
              //     console.error("Error fetching image:", error);
              //   }
              // };

              // const promises = item.values.hotelImages.map(async (image, imageIndex) => {
              //   const imageUrl = image.preview.startsWith(IMAGE_URL) ? image.preview.replace(IMAGE_URL, "") : "";
              //   console.log(imageUrl);
              //   if (imageUrl) {
              //     return fetchAndAppendImage(imageUrl, image.id, imageIndex);
              //   } else {
              //     formData.append(`itinerary[${dayIndex}][hotel][0][gallery_images][${imageIndex}]`, image.file);
              //     return null; // or any other value that fits your logic
              //   }
              // });

              // await Promise.all(promises);

              // Gallery Images
              // item.values.hotelImages.forEach((image, imageIndex) => {
              //   const imageUrl = image.preview.startsWith(IMAGE_URL) && image.preview.replace(IMAGE_URL, "");
              //   if (imageUrl) {
              //     // Fetch the image file from the URL
              //     fetch(imageUrl)
              //       .then(response => response.blob())
              //       .then(blob => {
              //         const file = new File([blob], image.id, {type: "image/*"});
              //         console.log(file);
              //         formData.append(`itinerary[${dayIndex}][hotel][0][gallery_images_preview][${imageIndex}]`, file);
              //       })
              //       .catch(error => {
              //         console.error("Error fetching image:", error);
              //       });
              //   } else {
              //     formData.append(`itinerary[${dayIndex}][hotel][0][gallery_images][${imageIndex}]`, image.file);
              //   }
              // });
            }

            // Meal
            if (item.tab === "Meal") {
              const meals = Object.values(item.values).join(",");
              formData.append(`itinerary[${dayIndex}][meal]`, meals);
            }

            // return;

            // Sightseeing
            if (item.tab === "Sightseeing") {
              // Check if morning exists
              if (item.values.morning) {
                formData.append(`itinerary[${dayIndex}][siteseeing][0][morning]`, item.values.morningText);
              }

              // Check if afternoon exists
              if (item.values.afternoon) {
                formData.append(`itinerary[${dayIndex}][siteseeing][0][afternoon]`, item.values.afternoonText);
              }

              // Check if evening exists
              if (item.values.evening) {
                formData.append(`itinerary[${dayIndex}][siteseeing][0][evening]`, item.values.eveningText);
              }

              // Check if night exists
              if (item.values.night) {
                formData.append(`itinerary[${dayIndex}][siteseeing][0][night]`, item.values.nightText);
              }

              // Gallery Images
              if (item?.values?.sightSeeingImages?.length > 0) {
                item?.values?.sightSeeingImages?.forEach((image, imageIndex) => {
                  formData.append(`itinerary[${dayIndex}][siteseeing][0][gallery_images][${imageIndex}]`, image.file);
                  // If you want to append the preview URLs as well, you can do:
                  // formData.append(`itinerary[${dayIndex}][hotel][${index}][gallery_images_preview][${imageIndex}]`, image.preview);
                });
              }
            }

            // Local Transport
            if (item.tab === "Local Transport") {
              formData.append(`itinerary[${dayIndex}][local_transports][0][car]`, item.values.carType);
              formData.append(`itinerary[${dayIndex}][local_transports][0][model]`, item.values.carModel);
              formData.append(`itinerary[${dayIndex}][local_transports][0][capacity]`, item.values.carCapacity);
              formData.append(`itinerary[${dayIndex}][local_transports][0][AC]`, item.values.localCarAc ? "1" : "0");
            }
          });

          // Add more conditions for other tabs as needed
        });

        let tempPaymentPolicy = [`${paymentPoliciesDefault.from}-0-${paymentPoliciesDefault.percentage}`];

        if (paymentPolicies.length > 0) {
          paymentPolicies.map(item => {
            tempPaymentPolicy.push(`${item.from}-${item.to}-${item.percentage}`);
            // tempCanPolicyDaysArray.push(item.from);
            // tempCanPolicyDaysArray.push(item.to);
            // tempCanPolicyPercentageArray.push(item.percentage);
          });
        }

        tempPaymentPolicy?.forEach(canDayItem => {
          formData.append(`payment_policy[]`, canDayItem);
        });

        let tempCancellationPolicy = [`${cancellationPoliciesDefault.from}-0-${cancellationPoliciesDefault.percentage}`];

        // let tempCanPolicyDaysArray = [cancellationPoliciesDefault.from];
        // let tempCanPolicyPercentageArray = [cancellationPoliciesDefault.percentage];

        if (cancellationPolicies.length > 0) {
          cancellationPolicies.map(item => {
            tempCancellationPolicy.push(`${item.from}-${item.to}-${item.percentage}`);
            // tempCanPolicyDaysArray.push(item.from);
            // tempCanPolicyDaysArray.push(item.to);
            // tempCanPolicyPercentageArray.push(item.percentage);
          });
        }

        tempCancellationPolicy?.forEach(canDayItem => {
          formData.append(`cancellation_policy[]`, canDayItem);
        });

        // tempCanPolicyDaysArray?.forEach(canDayItem => {
        //   formData.append(`cancellation_days[]`, parseInt(canDayItem));
        // });

        // tempCanPolicyPercentageArray?.forEach(canPerItem => {
        //   formData.append(`cancellation_percentages[]`, parseInt(canPerItem));
        // });

        // for (let [key, value] of formData) {
        //   console.log(`${key}: ${value}`);
        // }

        let response = await editPackageApi(formData);
        if (response) {
          if (response.data.res === true) {
            NotificationManager.success(response.data.msg);
            // navigate("/business/package/add");
          } else {
            NotificationManager.error(response.data.msg);
          }
          setBtnDis(false);
        }
      } catch (error) {
        setBtnDis(false);
      }
    }
  };

  const handleVendorApproval = async () => {
    setAproveBtnDis(true);
    try {
      const formData = new FormData();
      formData.append("package_id", packageId);
      let response = await vendorApprovalApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          // let tempObj = {...packageDetails};
          // tempObj.vendor_verified = 1;
          // setPackageDetails(tempObj);
          getPackageDetails();
          setVendorApproveModal(true);
        } else {
        }
        setAproveBtnDis(false);
      }
    } catch (error) {
      setAproveBtnDis(true);
    }
  };

  const handleActionChange = async status => {
    try {
      const formData = new FormData();
      formData.append("package_ids[]", packageId);

      formData.append("status", status ? status : "");

      let response = await changePackageStatusApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          if (activePackage == 1) {
            setActivePackage(0);
          } else {
            setActivePackage(1);
          }
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (tourCircuitList) {
      const selectedOption = tourCircuitList.find(option => option.label === addPackformData.tourCircuit);
      if (selectedOption) {
        setDefaultTouCircuitValue(selectedOption);
      }
    }
  }, [addPackformData.tourCircuit, tourCircuitList]);

  useEffect(() => {
    if (locationList) {
      const selectedOption = locationList.find(option => option.value === addPackformData.location);
      if (selectedOption) {
        setDefaultLocationValue(selectedOption);
        // getKeywords(selectedOption.value);
      }
    }
  }, [addPackformData.location, locationList]);

  useEffect(() => {
    if (keywordsList) {
      if (addPackformData.keywords) {
        let newArray = addPackformData.keywords.split(",");
        let updatedArray = newArray.map(item => ({
          value: item,
          label: item,
        }));
        if (newArray.length > 0) {
          setDefaultKeywordsValue(newArray);
        }
      }
    }
  }, [addPackformData.keywords, keywordsList]);

  useEffect(() => {
    const fetchData = async () => {
      await getTourismCircuits();
      await getPackageDetails();
      // getTripList();
      // getTypeOfTourPackagesList();
      getAllCityList();
      getThemesList();
      // getStateList();
      fetchBroadLocations();
      fetchInclusions();
      fetchExclusions();
    };

    fetchData();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Edit Tour Package`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is edit Package page" />
      </Helmet>
      <VenderHeader />
      <div className="venderInnerDiv">
        <VenderSidebar />

        <div className="venderRight">
          {screenLoading && <LoadingPage />}
          <div className={isSticky ? "hdrSec stcky" : "hdrSec"}>
            <h2>
              <Button variant="link" onClick={() => navigate("/business/packages")}>
                <img src={arrow} alt="" />
              </Button>
              Edit Tour Package
              {/* <span class="checkboxes-and-radios activePackage onHeader">
                <input
                  type="checkbox"
                  name="checkbox-cats2"
                  id="checkbox-2"
                  value="2"
                  checked={activePackage}
                  onChange={e => (e.target.checked ? handleActionChange("1") : handleActionChange("0"))}
                />
                <label for="checkbox-2">{activePackage ? "Active" : "Deactive"}</label>
                <input type="checkbox" name="checkbox-cats3" id="checkbox-3" value="3" checked />
              </span> */}
            </h2>

            <div className="btnsHdn">
              {packageVerified ? (
                <>
                  <button className="saveBtn" onClick={() => handleSavePackage()} disabled={btnDis}>
                    {btnDis ? "Please wait" : "Save"}
                  </button>
                  {/* <span className="validationErrorMsg">You can't edit beacuse this package is already verified. </span> */}
                </>
              ) : // <button className="discardBtn" disabled>
              //   Discard
              // </button>
              packageDetails?.admin_verified == 1 && packageDetails?.vendor_verified == 0 ? (
                <></>
              ) : (
                <button className="discardBtn" onClick={() => navigate("/business/packages")}>
                  Discard
                </button>
              )}

              <div className="messageSectionIconCont" onClick={() => navigate(`/business/message/${packageId}`)}>
                {/* <img src={messageIcon} className="msgIcon" alt="" /> */}
                <button className="discardBtn msgIcon">Message to Admin</button>
                {messageCount ? <span className="msgCount">{messageCount}</span> : null}
              </div>

              {packageDetails?.admin_verified == 1 && packageDetails?.vendor_verified == 1 ? (
                <></>
              ) : (
                // <button className="saveBtn" disabled>
                //   {btnDis ? "Please wait" : "Save"}
                // </button>
                <>
                  {packageDetails?.admin_verified == 1 && packageDetails?.vendor_verified == 0 ? (
                    <button className="discardBtn" onClick={() => navigate("/business/packages")}>
                      Decline
                    </button>
                  ) : null}

                  {packageDetails?.admin_verified == 1 && packageDetails?.vendor_verified == 0 ? (
                    <button className="saveBtn" onClick={() => handleVendorApproval()}>
                      {aproveBtnDis ? "Please wait" : "Accept"}
                    </button>
                  ) : (
                    <button className="saveBtn" onClick={() => handleSavePackage()} disabled={btnDis}>
                      {btnDis ? "Please wait" : "Save"}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="pricingDetails">
            {/* <h3>Our Price: ₹{packageDetails?.starting_price}</h3>
            <h3>
              Website Price: <span>₹{packageDetails?.website_price ? parseFloat(packageDetails?.website_price).toFixed(2) : "-"}</span>
            </h3> */}
          </div>
          <div className="venderMain">
            {packageDetails?.admin_verified == 1 && packageDetails?.vendor_verified == 0 ? (
              <div className="venderMainOverlay approvalSection">
                <div className="descriptionSection">
                  <h3 className="sectionTitle">Action Required: Accept Your Tour Package Pricing</h3>
                  {/* <p className="tourPackagePrice">Tour Package Price: ₹{packageDetails?.starting_price}</p> */}
                  <p className="convenienceFees">
                    Convenience Fees: {packageDetails?.platform_charges}%
                    {/* Convenience Fees ({packageDetails?.platform_charges}%): ₹{packageDetails?.platform_charges_amount} */}
                  </p>

                  <p className="totalPriceLabel">
                    <strong>Example:</strong>
                  </p>
                  <ul className="payoutDetailsList">
                    <li>
                      Standard package cost: ₹{packageDetails?.starting_price} (per adult on twin sharing, excluding any add on, surcharge and discount, GST if
                      any)
                    </li>

                    <li>
                      + Convenience fees @{packageDetails?.platform_charges}% on total package price: ₹{packageDetails?.platform_charges_amount}
                    </li>
                    {/* <li>- GST({packageDetails?.gst_rate}%)</li> */}
                    {/* <li>+ Convenience fees ₹{packageDetails?.platform_charges_amount}</li> */}
                    <li>+ GST(18%) on convenience fees: ₹{packageDetails?.platform_charges_gst}</li>
                    <li>
                      + GST ({packageDetails?.gst_rate}%): ₹{packageDetails?.gst_amount}
                    </li>
                    <li>= Total package cost: ₹{packageDetails?.website_price}</li>
                  </ul>
                  {/* <p className="totalPriceBreakdown">
                    Standard package cost per adult on twin sharing (excluding any add on, surcharge and discount, GST if any) ₹{packageDetails?.starting_price}{" "}
                    + Convenience fees ₹{packageDetails?.platform_charges_amount} + GST(18%) on convenience fees + GST
                  </p> */}
                  <p className="payoutDetailsLabel">
                    <strong>Payout Details:</strong>
                  </p>
                  <p className="payoutDetailsIntro">You will receive:</p>
                  <ul className="payoutDetailsList">
                    <li>Booking Price</li>

                    <li>- Convenience fees on total package price: {packageDetails?.platform_charges}%</li>
                    {/* <li>- GST({packageDetails?.gst_rate}%)</li> */}
                    <li>- GST TDS(2%)</li>
                    <li>- TDS ({packageDetails?.tds_rate_text})</li>
                    {/* <li>- TCS ({packageDetails?.tcs_rate}%)</li> */}
                  </ul>
                  <p className="approvalInstruction">If you agree, please click "Accept" in the top right corner.</p>
                  <p className="note">
                    <strong>Note:</strong> This applies to both GST registered and unregistered operators/vendors.
                  </p>
                </div>
              </div>
            ) : null}

            <div className="vendarTop">
              <h2>Package Information</h2>

              {/* <div class="checkboxes-and-radios activePackage">
                <input
                  type="checkbox"
                  name="checkbox-cats2"
                  id="checkbox-2"
                  value="2"
                  checked={activePackage}
                  onChange={e => setActivePackage(e.target.checked)}
                />
                <label for="checkbox-2">{activePackage ? "Active" : "Deactive"}</label>
                <input type="checkbox" name="checkbox-cats3" id="checkbox-3" value="3" checked />
              </div> */}
            </div>

            <div className="addForm">
              <h3>Basic Information</h3>
              <ul className="formList">
                <li className="fullWid">
                  <label>
                    Package Title <span className="starSpan">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Type here"
                    value={addPackformData.packageTitle}
                    disabled={packageVerified}
                    onChange={e => handleAddPackageInputChange("packageTitle", e.target.value)}
                  />
                </li>
                <li>
                  <label>
                    Trip Type <span className="starSpan">*</span>
                  </label>
                  <select value={addPackformData?.tripType} disabled={packageVerified} onChange={e => handleAddPackageInputChange("tripType", e.target.value)}>
                    <option hidden selected>
                      - Select -
                    </option>
                    {tripList?.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </li>
                <li>
                  <label>
                    Tour Package Type <span className="starSpan">*</span>
                  </label>
                  <select
                    value={addPackformData?.tourPackageType}
                    disabled={packageVerified}
                    onChange={e => handleAddPackageInputChange("tourPackageType", e.target.value)}>
                    <option hidden selected>
                      - Select -
                    </option>
                    {typeOfTourPackagesList?.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </li>
                {/* <li>
                  <label>City</label>
                  <select value={addPackformData?.city} onChange={e => handleAddPackageInputChange("city", e.target.value)}>
                    <option hidden selected>
                      - Select -
                    </option>
                    {cityList?.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.city}
                      </option>
                    ))}
                  </select>
                </li>*/}

                <li>
                  <label>Region (Optional , select if applicable)</label>
                  {/* <label>Broad location (Destination)</label> */}
                  {/* <input
                    type="text"
                    placeholder="Type here"
                    value={addPackformData?.location}
                    onChange={e => handleAddPackageInputChange("location", e.target.value)}
                  /> */}

                  {/* <Select
                      // isMulti
                      name="colors"
                      options={locationList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={value => {
                        handleAddPackageInputChange("location", value.label);
                        getKeywords(value.value);
                      }}
                      placeholder="Type Here"
                    />
                     */}
                  <div className="multipleSelectBox">
                    <Autocomplete
                      // multiple
                      disabled={packageVerified}
                      id="single-select-autocomplete"
                      options={locationList}
                      getOptionLabel={option => option.label}
                      value={defaultLocationValue}
                      onChange={(event, newValue) => {
                        // setAddPackFormData(prev => ({...prev, keywords: ""}));
                        // setKeywordsList([]);
                        // setDefaultKeywordsValue([]);
                        handleAddPackageInputChange("location", newValue.value);
                        // getKeywords(newValue?.value);
                        // setDefaultLocationValue(newValue);
                      }}
                      renderInput={params => <TextField {...params} placeholder="Type Here" />}
                    />
                  </div>
                </li>

                <li>
                  <label>
                    State <span className="starSpan">*</span> <span className="infoTxt">(Select the state where the first night of stay is located.)</span>
                  </label>
                  <select
                    value={addPackformData?.state}
                    disabled={packageVerified}
                    onChange={e => {
                      handleStateChange(e);
                      handleAddPackageInputChange("state", e.target.value);
                    }}>
                    <option hidden selected>
                      - Select -
                    </option>
                    {stateList?.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </li>
                <li>
                  <label>Tour Circuit</label>
                  {/* <input
                    type="text"
                    placeholder="Type here"
                    value={addPackformData?.tourCircuit}
                    onChange={e => handleAddPackageInputChange("tourCircuit", e.target.value)}
                  /> */}
                  <div className="multipleSelectBox">
                    <Autocomplete
                      // multiple
                      disabled={packageVerified}
                      id="single-select-autocomplete"
                      options={tourCircuitList}
                      getOptionLabel={option => option.label}
                      value={defaultTouCircuitValue}
                      onChange={(event, newValue) => {
                        handleAddPackageInputChange("tourCircuit", newValue?.value);
                        setDefaultTouCircuitValue(newValue);
                      }}
                      renderInput={params => <TextField {...params} placeholder="Type Here" />}
                    />
                  </div>
                </li>
                <li>
                  <label>
                    Themes <span className="starSpan">*</span>
                  </label>
                  <select value={addPackformData?.themes} disabled={packageVerified} onChange={e => handleAddPackageInputChange("themes", e.target.value)}>
                    <option selected hidden>
                      - Select-
                    </option>
                    {themesList?.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </li>
                <li className="fullWid">
                  <label>Origin</label>
                  {/* <input
                    type="text"
                    placeholder="Type here"
                    value={addPackformData?.origin}
                    onChange={e => handleAddPackageInputChange("origin", e.target.value)}
                  /> */}
                  <Typeahead
                    id="basic-typeahead-single_flight_destination"
                    labelKey="city"
                    onChange={selected => {
                      handleAddPackageInputChange("origin", selected[0]?.id ? selected[0].id : null);
                    }}
                    onInputChange={text => {
                      if (!text) {
                        handleAddPackageInputChange("origin", null);
                      } else {
                        const matchingCity = allCityList.find(city => city.city.toLowerCase() === text.toLowerCase());
                        if (matchingCity) {
                          handleAddPackageInputChange("origin", matchingCity.id);
                        } else {
                          handleAddPackageInputChange("origin", null);
                        }
                      }
                    }}
                    disabled={packageVerified}
                    options={allCityList}
                    placeholder="Type Here"
                    selected={addPackformData?.origin ? allCityList.filter(item => parseInt(item.id) === parseInt(addPackformData?.origin)) : ""}
                  />
                </li>

                {/* <li className="fullWid">
                  <label>Keywords</label> */}
                {/* <input
                    type="text"
                    placeholder="Type here"
                    value={addPackformData?.keywords}
                    onChange={e => handleAddPackageInputChange("keywords", e.target.value)}
                  /> */}
                {/* <div className="multipleSelectBox"> */}
                {/* <Autocomplete
                      multiple
                      id="tags-standard"
                      options={keywordsList}
                      getOptionLabel={option => option}
                      value={defaultKeywordsValue}
                      onChange={(event, newValue) => {
                        let tempArray = newValue.map(item => item);
                        let explodeArray = tempArray.join(",");
                        handleAddPackageInputChange("keywords", explodeArray);
                        setDefaultKeywordsValue(newValue);
                      }}
                      renderInput={params => <TextField {...params} placeholder="Type Here" />}
                    /> */}

                {/* <Autocomplete
                      multiple
                      id="tags-standard"
                      options={cityList}
                      getOptionLabel={option => option}
                      value={defaultKeywordsValue}
                      onChange={(event, newValue) => {
                        let tempArray = newValue.map(item => item);
                        let explodeArray = tempArray.join(",");
                        handleAddPackageInputChange("keywords", explodeArray);
                        setDefaultKeywordsValue(newValue);
                      }}
                      renderInput={params => <TextField {...params} placeholder="Type Here" />}
                    />
                  </div>
                </li> */}
                <li className="fullWid">
                  <label>Overview</label>
                  <textarea
                    value={addPackformData?.overview}
                    disabled={packageVerified}
                    onChange={e => handleAddPackageInputChange("overview", e.target.value)}
                    placeholder="Type here"></textarea>
                </li>
                <li className="fullWid">
                  <label>
                    Images <span className="starSpan">*</span>
                  </label>

                  {/* {packageVerified ? (
                    <>
                      <div className={`dragUpload`}>
                        <div className="uploadedImagesContainer">
                          {section1PrevImages.length > 0 &&
                            section1PrevImages.map((image, index) => (
                              <div key={index} className="uploadedImage">
                                <img src={image.path} alt={`Uploaded ${index + 1}`} />
                              </div>
                            ))}

                          {section1Images.length > 0 &&
                            section1Images.map((image, index) => (
                              <div key={index} className="uploadedImage">
                                <img src={image.preview} alt={`Uploaded ${index + 1}`} />
                                <button onClick={() => handleRemoveImage(index)}></button>
                              </div>
                            ))}
                        </div>
                        {section1Images.length === 0 && section1PrevImages.length === 0 && (
                          <div className="dragImg">
                            <img src={dragIcon} alt="" />
                            Drag and drop here
                          </div>
                        )}
                      </div>
                      <p className="dragtxt">Images size should be below 1mb for each. Allowed file extensions are (png, jpeg, and jpg)</p>
                    </>
                  ) : (
                    <> */}
                  <div
                    className={`dragUpload ${dragging ? "dragging" : ""}`}
                    onDragOver={e => handleDragEnter(e)}
                    onDragLeave={handleDragLeave}
                    onDrop={e => handleDrop(e)}
                    onClick={handleUploadClick}>
                    <input type="file" ref={fileInputRef} style={{display: "none"}} multiple onChange={handleFileInputChange} />
                    <div className="uploadedImagesContainer">
                      {section1PrevImages.length > 0 &&
                        section1PrevImages.map((image, index) => (
                          <div key={index} className="uploadedImage">
                            <img src={image.path} alt={`Uploaded ${index + 1}`} />
                            <button onClick={e => handleRemovePrevImage(e, index)}></button>
                          </div>
                        ))}

                      {section1Images.length > 0 &&
                        section1Images.map((image, index) => (
                          <div key={index} className="uploadedImage">
                            <img src={image.preview} alt={`Uploaded ${index + 1}`} />
                            <button onClick={e => handleRemoveImage(e, index)}></button>
                          </div>
                        ))}
                    </div>
                    {section1Images.length === 0 && section1PrevImages.length === 0 && (
                      <div className="dragImg">
                        <img src={dragIcon} alt="" />
                        Drag and drop here
                      </div>
                    )}
                  </div>

                  {/* <ImageUploader sectionTitle="Section 1" onImagesChange={images => handleImagesChange(images, "Section 1")} section1PrevImages={section1PrevImages} /> */}
                  <p className="dragtxt">
                    Images size should be below 1mb for each. Allowed file extensions are (png, jpeg, and jpg). Maximum 5 images are allowed.
                  </p>
                  {/* </>
                  )} */}
                </li>
                <li className="fullMedia">
                  <label>Add Media Links</label>
                  <div className="mediaLoop">
                    <input type="text" placeholder="Type here" value={mediaLinksText} onChange={e => setMediaLinksText(e.target.value)} />
                  </div>
                  {/* {prevMediaLinks.map((link, index) => (
                    <div key={index} className="mediaLoop">
                      <input type="text" placeholder="Type here" value={link.media_link} onChange={e => handleMediaLinkChange(index, e.target.value)} />

                      <button className="mediaAddBtn" onClick={() => handlePrevRemoveMediaLink(index)}>
                        <img src={minusIcon} alt="" />
                      </button>
                    </div>
                  ))} */}
                  {mediaLinks.map((link, index) => (
                    <div key={index} className="mediaLoop">
                      <input
                        type="text"
                        placeholder="Type here"
                        // disabled={packageVerified}
                        value={link.media_link}
                        onChange={e => handleMediaLinkChange(index, e.target.value)}
                      />
                      {/* {index > 0 && ( */}
                      {/* {link?.id ? (
                        <button className="mediaAddBtn" onClick={() => handlePrevRemoveMediaLink(index)}>
                          <img src={minusIcon} alt="" />
                        </button>
                      ) : ( */}
                      {/* {!packageVerified ? ( */}
                      <button className="mediaAddBtn" onClick={() => handleRemoveMediaLink(index)}>
                        <img src={minusIcon} alt="" />
                      </button>
                      {/* ) : null} */}

                      {/* )} */}

                      {/* )} */}
                    </div>
                  ))}

                  {!packageVerified ? (
                    <button
                      className="mediaAddBtn firstMediaBtn"
                      onClick={() => {
                        // handleAddMediaLink();
                        handleMediaLinkAdd();
                        // handleMediaLinkChange(mediaLinks.length, mediaLinks[0]);
                      }}>
                      <img src={plusIcon} alt="" />
                    </button>
                  ) : null}
                </li>
              </ul>
            </div>

            <div className="addForm addAllIncl">
              <h3>Itinerary Information</h3>
              <div className="daysCount">
                <h3>Total Days of Tour</h3>
                <div className="daysFlex">
                  <button className="minusDays" disabled={packageVerified} onClick={handleDecrementDays}></button>
                  <button className="plusDays" disabled={packageVerified} onClick={handleIncrementDays}></button>
                  <span>{itineraryArray.length.toString().padStart(2, "0")}</span>
                </div>
              </div>

              {/* {Array.from({length: totalDays}).map((_, dayIndex) => ( */}
              {itineraryArray?.map((dayItem, dayIndex) => (
                <div key={dayIndex} className="ItineraryLoop">
                  <h4 className="hdnItar">Day {dayIndex + 1}</h4>
                  <ul className="formList">
                    <li>
                      <label>
                        Place Name or Nearest Major Town/City <span className="starSpan">*</span>
                      </label>
                      {/* <input
                        type="text"
                        placeholder="Type here"
                        value={dayItem?.placeName}
                        onChange={selected => {
                          handleitineraryValueChange("placeName", dayIndex, selected[0]?.city ? selected[0].city : null);
                        }}
                      /> */}

                      <Typeahead
                        id="basic-typeahead-place-name"
                        labelKey="city"
                        disabled={packageVerified}
                        onChange={selected => {
                          handleitineraryValueChange("placeName", dayIndex, selected[0]?.id ? selected[0].id : null);
                          handleitineraryValueChange("placeCityId", dayIndex, selected[0]?.id ? selected[0].id : null);
                        }}
                        onInputChange={text => {
                          if (!text) {
                            handleitineraryValueChange("placeName", dayIndex, null);
                            handleitineraryValueChange("placeCityId", dayIndex, null);
                          } else {
                            const matchingCity = allCityList.find(city => city.city.toLowerCase() === text.toLowerCase());
                            if (matchingCity) {
                              handleitineraryValueChange("placeName", dayIndex, matchingCity?.city ? matchingCity?.city : null);
                              handleitineraryValueChange("placeCityId", dayIndex, matchingCity?.id ? matchingCity?.id : null);
                            } else {
                              handleitineraryValueChange("placeName", dayIndex, null);
                              handleitineraryValueChange("placeCityId", dayIndex, null);
                            }
                          }
                        }}
                        options={allCityList}
                        placeholder="Type here"
                        selected={dayItem?.placeName ? allCityList.filter(item => parseInt(item.id) === parseInt(dayItem?.placeName)) : ""}
                      />
                    </li>
                    <li>
                      <label>
                        Major Activity <span className="starSpan">*</span> <span>(This will be displayed as the itinerary heading.)</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Type here"
                        disabled={packageVerified}
                        value={dayItem?.itineraryTitle}
                        onChange={e => handleitineraryValueChange("itineraryTitle", dayIndex, e.target.value)}
                      />
                    </li>
                    <li className="fullWid">
                      <label>
                        Itinerary Description <span className="starSpan">*</span>
                      </label>
                      <textarea
                        value={dayItem?.itineraryDescription}
                        disabled={packageVerified}
                        onChange={e => handleitineraryValueChange("itineraryDescription", dayIndex, e.target.value)}
                        placeholder="Type here"></textarea>
                    </li>
                  </ul>
                  {!packageVerified ? (
                    <div className="addInclusion" onClick={() => handleAddInclusionForDay(dayIndex)}>
                      <img src={plusIcon} alt="" /> Add Activity
                    </div>
                  ) : null}

                  <div className="inclusionsContainer accordion-content incAll">
                    <ul>
                      {inclusions.map(
                        (inclusion, index) =>
                          inclusion.day === dayIndex && (
                            <li key={index} className={`inclusion ${inclusion.tab}`}>
                              {inclusion.tab === "Flight" && (
                                <div className="accoInner">
                                  <div className="transportDiv">
                                    <img src={flightIcon} alt="" />
                                    Flight
                                  </div>

                                  <div className="transportName">
                                    <h4>{inclusion.values.flightType}</h4>
                                    <p>{inclusion.values.flightNumber}</p>
                                  </div>

                                  <div className="stationName">
                                    <h4>{inclusion.values.departureCity}</h4>
                                    {/* <h4>{allCityList.filter(item => item.id === inclusion.values.departureCity)[0]?.city}</h4> */}
                                  </div>

                                  <div className="classCategory">
                                    <img src={flightIcon2} alt="" />
                                    <h4>{inclusion.values.flightClass}</h4>
                                  </div>

                                  <div className="destinationName">
                                    <h4>{inclusion.values.arrivalCity}</h4>
                                    {/* <h4>{allCityList.filter(item => item.id === inclusion.values.arrivalCity)[0]?.city}</h4> */}
                                  </div>
                                </div>
                              )}

                              {inclusion.tab === "Train" && (
                                <div className="accoInner">
                                  <div className="transportDiv">
                                    <img src={trainIcon2} alt="" />
                                    Train
                                  </div>

                                  <div className="transportName">
                                    <h4>{inclusion.values.trainName}</h4>
                                    <p>{inclusion.values.trainNumber}</p>
                                  </div>

                                  <div className="stationName">
                                    <h4>{inclusion.values.trainDepartureCity}</h4>
                                    {/* <h4>{allCityList.filter(item => item.id === inclusion.values.trainDepartureCity)[0]?.city}</h4> */}
                                  </div>

                                  <div className="classCategory">
                                    <img src={trainIcon} alt="" />
                                    <h4>{inclusion.values.trainClass}</h4>
                                  </div>

                                  <div className="destinationName">
                                    <h4>{inclusion.values.trainArrivalCity}</h4>
                                    {/* <h4>{allCityList.filter(item => item.id === inclusion.values.trainArrivalCity)[0]?.city}</h4> */}
                                  </div>
                                </div>
                              )}

                              {inclusion.tab === "Hotel" && (
                                <div className="accoInner">
                                  <div className="hotelHdnDiv">
                                    <img src={hotelIcon} alt="" />
                                    <div className="nameDesc">Hotel</div>
                                  </div>
                                  <div className="hotelNme">
                                    <h3>{inclusion.values.hotelName}</h3>
                                  </div>

                                  {inclusion.values.hotelStar && (
                                    <div className={`ratingSelect ratingSelect${index + 1}`}>
                                      {/* {Array.from({length: 5}).map((_, index) => (
                                        <img
                                          key={index}
                                          src={index < popupRatings[popupIdentifier - 1] ? ratingImg : ratingImgBlank}
                                          alt={`Star ${index + 1}`}
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                            marginRight: "2px",
                                          }}
                                        />
                                      ))} */}
                                      <StarRatings
                                        rating={inclusion.values.hotelStar}
                                        starRatedColor="yellow"
                                        // changeRating={inclusion.values.hotelStar}
                                        numberOfStars={5}
                                        name={`rating-${index}`}
                                        starDimension="25px"
                                        starSpacing="2px"
                                        isSelectable={false}
                                      />
                                    </div>
                                  )}

                                  <div className="dropImages ">
                                    {/* {inclusion?.values?.hotelPrevImages?.map((image, index) => (
                                      <img key={index} src={image.path} alt={`Uploaded ${index + 1}`} />
                                    ))} */}
                                    {inclusion.values.hotelImages.map((image, index) => (
                                      <img key={index} src={image.preview} alt={`Uploaded ${index + 1}`} />
                                    ))}
                                  </div>
                                </div>
                              )}

                              {inclusion.tab === "Meal" && (
                                <div className="accoInner">
                                  <div className="transportDiv">
                                    <img src={mealIcon} alt="" />
                                    Meal
                                  </div>

                                  <div className="mealServeDiv">
                                    <h4>{inclusion.values.breakfast}</h4>
                                  </div>

                                  <div className="mealServeDiv">
                                    <h4>{inclusion.values.lunch}</h4>
                                  </div>

                                  <div className="mealServeDiv">
                                    <h4>{inclusion.values.dinner}</h4>
                                  </div>
                                </div>
                              )}

                              {inclusion.tab === "Sightseeing" && (
                                <div className="accoInner">
                                  <div className="transportDiv">
                                    <img src={sightIcon} alt="" />
                                    Sightseeing
                                  </div>
                                  <div className="sightVndr">
                                    {inclusion.values.morning ? (
                                      <div className="sightDiv">
                                        <h4>
                                          {inclusion.values.morning}: {inclusion.values.morningText}
                                        </h4>
                                      </div>
                                    ) : null}

                                    {inclusion.values.afternoon ? (
                                      <div className="sightDiv">
                                        <h4>
                                          {inclusion.values.afternoon}: {inclusion.values.afternoonText}
                                        </h4>
                                      </div>
                                    ) : null}

                                    {inclusion.values.evening ? (
                                      <div className="sightDiv">
                                        <h4>
                                          {inclusion.values.evening}: {inclusion.values.eveningText}
                                        </h4>
                                      </div>
                                    ) : null}

                                    {inclusion.values.night ? (
                                      <div className="sightDiv">
                                        <h4>
                                          {inclusion.values.night}: {inclusion.values.nightText}
                                        </h4>
                                      </div>
                                    ) : null}
                                  </div>

                                  {inclusion.values.sightSeeingImages?.length > 0 ? (
                                    <div className="dropImages">
                                      {inclusion.values.sightSeeingImages?.map((sightItem, sightIndex) => (
                                        <img key={sightIndex} src={sightItem.preview} alt={`Uploaded ${sightIndex + 1}`} />
                                      ))}
                                    </div>
                                  ) : null}
                                </div>
                              )}

                              {inclusion.tab === "Local Transport" && (
                                <div className="accoInner">
                                  <div className="transportDiv localTrans">
                                    <img src={carImg} alt="" />
                                    Local Transport
                                  </div>

                                  <div className="transportName">
                                    <h4>{inclusion.values.carType}</h4>
                                    <p>{inclusion.values.carCapacity}</p>
                                  </div>

                                  <div className="carName">
                                    <img src={carIcon} alt="" />
                                    {inclusion.values.carModel}
                                  </div>
                                </div>
                              )}

                              {/* Common inclusion buttons */}
                              {!packageVerified ? (
                                <div className="inclusionButtons">
                                  <button onClick={() => handleEditInclusion(index)}>
                                    <img src={editIcon} alt="" />
                                  </button>
                                  <button onClick={() => handleRemoveInclusion(index)}>
                                    <img src={minusIcon} alt="" />
                                  </button>
                                </div>
                              ) : null}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                </div>
              ))}
            </div>

            <div className="addForm">
              <h3>Pricing</h3>
              <div className="ItineraryLoop">
                <h4 className="bscPrc">Basic Price (Per person)</h4>
                <ul className="formList">
                  <li>
                    <label>
                      Adult (above 12 yrs) - Double Occupancy <span className="starSpan">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder=""
                      value={addPackformData?.basicPriceAboveTwelve}
                      onChange={e => handleAddPackageInputChange("basicPriceAboveTwelve", e.target.value)}
                    />
                    <span className="symbl">INR</span>
                  </li>
                  <li>
                    <label>Child (5-12 Years) - with extra bed</label>
                    <input
                      type="number"
                      placeholder=""
                      value={addPackformData?.childPrice}
                      onChange={e => handleAddPackageInputChange("childPrice", e.target.value)}
                    />
                    <span className="symbl">INR</span>
                  </li>
                  <li>
                    <label>Infant & Child (Up to 5 Years)</label>
                    <input
                      type="number"
                      placeholder=""
                      value={addPackformData?.infantPrice}
                      onChange={e => handleAddPackageInputChange("infantPrice", e.target.value)}
                    />
                    <span className="symbl">INR</span>
                  </li>
                  <li>
                    <label>Adult (above 12 yrs) - Single occupancy</label>
                    <input
                      type="number"
                      placeholder=""
                      value={addPackformData?.basicPriceAdditional}
                      onChange={e => handleAddPackageInputChange("basicPriceAdditional", e.target.value)}
                    />
                    <span className="symbl">INR</span>
                  </li>
                  <li>
                    <label>Adult (above 12 yrs) - Triple occupancy</label>
                    <input
                      type="number"
                      placeholder=""
                      value={addPackformData?.tripleSharingDiscount}
                      onChange={e => handleAddPackageInputChange("tripleSharingDiscount", e.target.value)}
                    />
                    <span className="symbl">INR</span>
                  </li>
                </ul>
              </div>
              <div className="ItineraryLoop addOnDiv onOffSeasonSec">
                <h3>On Season/ Off Season (Per person)</h3>
                <div className="infoSection">
                  <span className="infoTxt">
                    Please specify any changes in package pricing, such as a price hike or price cut, for the specified time period.
                  </span>{" "}
                  <br />
                  <span className="infoTxt">Use a negative sign (-) before the amount to indicate a discount.</span>
                  <br />
                  <span className="infoTxt">Example: -₹500 for a discount of ₹500.</span>
                </div>
                <div className="bulkCheck">
                  <div className="addInclusion" onClick={addEntry}>
                    Add <img src={plusIcon} alt="" />
                  </div>
                </div>
                {seasonPriceRanges.map((entry, index) => (
                  <>
                    <ul className="formList onSeasonForm">
                      <li>
                        <label>On Season/ Off Season Date</label>
                        <div className="datePckr2">
                          <DatePicker
                            selected={entry.onToDate}
                            onChange={date => handleDateChange(index, "onToDate", date)}
                            dateFormat="MMMM d, yyyy"
                            placeholderText="To"
                            minDate={entry.onFromDate ? entry.onFromDate : new Date()}
                            disabled={!entry.onFromDate}
                          />
                        </div>
                        <div className="datePckr2">
                          <DatePicker
                            selected={entry.onFromDate}
                            onChange={date => handleDateChange(index, "onFromDate", date)}
                            dateFormat="MMMM d, yyyy"
                            placeholderText="From"
                            minDate={new Date()}
                          />
                        </div>
                      </li>
                      <li>
                        <label>Adult (above 12 yrs) - Price hike / discount</label>
                        <input type="number" placeholder="" value={entry.price} onChange={e => handlePriceChange(index, e.target.value)} />
                        <span className="symbl">INR</span>
                      </li>
                    </ul>
                    <div className="addInclusion deleteAddon">
                      Delete <img src={minusIcon} alt="" onClick={() => removeEntry(index)} />
                    </div>
                  </>
                ))}
              </div>
            </div>

            <div className="addForm addPackageCalendar">
              <h3>Tour Run Date and Availability Information</h3>
              <ul className="formList">
                <li>
                  <label>
                    Default Seat Availability <span className="smlText">(Default number of available seats for all available dates)</span>
                  </label>
                  <input type="number" placeholder="Type here number of seats" value={defaultSeat} onChange={e => handleDefaultSeatChange(e)} />
                  <span className="symbl">Seats</span>
                </li>
              </ul>
              <div className="ItineraryLoop">
                <div className="overviewDiv">
                  <div className="calendarTop">
                    {/* <ul className="dateStatus">
                      <li className="greenColor">
                        <span></span>Lowest Price
                      </li>
                      <li className="blueColor">
                        <span></span>Regular Price
                      </li>
                      <li className="redColor">
                        <span></span>Sold Out
                      </li>
                      <li className="blackColor">
                        <span></span>On Request
                      </li>
                      <li className="orangeColor">
                        <span></span>Selling Fast
                      </li>
                    </ul> */}
                  </div>

                  <div className="calendarSec">
                    <Calendar
                      tileClassName={tileClassName}
                      tileContent={tileContent}
                      onClickDay={handleDateClick}
                      value={selectedDate || new Date()}
                      minDate={new Date()}
                    />
                    {/* {selectedDate && (
                      <div>
                        <p>Date: {selectedDate.toDateString()}</p>
                        <p>Status: {getDayStatus(selectedDate)}</p>
                      </div>
                    )} */}
                    <p className="infoTxt">
                      Note: Click a date in the calendar first, then "Edit Seat" to update seat availability. Set availability to 0 to make dates unavailable.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="addForm addPackageCalendar">
              <h3>Tour Not Run Date Selection</h3>
              <div className="ItineraryLoop">
                <div className="overviewDiv">
                  <div className="calendarSec">
                    <Calendar
                      tileClassName={tileClassName1}
                      tileContent={tileContent1}
                      onClickDay={handleDateClick1}
                      value={selectedDate1 || new Date()}
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <ul className="formList">
                  <li>
                    <p>Note*: Please Select dates in which you don't want to run the package.</p>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="addForm blkDis">
              <h3>Bulk Discount</h3>
              <div className="bulkCheck">
                <div class="checkboxes-and-radios">
                  <input
                    type="checkbox"
                    name="checkbox-cats4"
                    id="checkbox-4"
                    value="4"
                    disabled={packageVerified}
                    checked={bulkDiscountActive}
                    onChange={e => setBulkDiscountActive(e.target.checked)}
                  />
                  <label for="checkbox-4">{bulkDiscountActive ? "On" : "Off"}</label>
                  <input type="checkbox" name="checkbox-cats4" id="checkbox-4" value="4" checked />
                </div>
              </div>
              {bulkDiscountActive ? (
                <>
                  {bulkDiscountOptions?.length > bulkDiscountsArray?.length && !packageVerified ? (
                    <div className="bulkCheck addBulkDisc">
                      <div className="addInclusion" onClick={handleAddBlukDiscClick}>
                        Add Another
                        <img src={plusIcon} alt="" />
                      </div>
                    </div>
                  ) : null}
                  <div className="ItineraryLoop">
                    <ul className="formList formList2">
                      {bulkDiscountsArray?.map((item, index) => (
                        <>
                          <li key={index} className={index === 0 ? "withoutMinus" : "halfList"}>
                            <label>
                              Number of Pax <span className="starSpan">*</span>
                            </label>
                            <select value={item?.pax} disabled={packageVerified} onChange={e => handleBulkDiscChange("pax", e.target.value, index)}>
                              <option>No. of Pax</option>
                              {getAvailableBulkOptions(index).map(option => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                              {/* {bulkDiscountOptions?.map((item, index) => (
                                <option key={index} value={item.value}>
                                  {item?.label}
                                </option>
                              ))} */}
                            </select>
                          </li>
                          <li className={index === 0 ? "withoutMinus" : "halfList"}>
                            <label>
                              Set Discount Per Cent <span className="starSpan">*</span>
                            </label>
                            <input
                              type="number"
                              placeholder="00"
                              disabled={packageVerified}
                              value={item?.percent}
                              onChange={e => handleBulkDiscChange("percent", e.target.value, index)}
                            />
                            <span className="symbl">%</span>
                          </li>
                          {bulkDiscountsArray?.length > 1 && index > 0 && !packageVerified ? (
                            <li className="addInclusion deleteAddon halfList2" onClick={() => handleDeleteBulkDiscOnClick(index)}>
                              <img src={minusIcon} alt="" />
                            </li>
                          ) : null}
                        </>
                      ))}
                    </ul>
                    {/* <ul className="formList">
                    <li>
                      <label>Number of Pax</label>
                      <select value={addPackformData?.numberOfPaxBulk} onChange={e => handleAddPackageInputChange("numberOfPaxBulk", e.target.value)}>
                        <option>No. of Pax</option>
                        <option value={"3"}>3</option>
                        <option value={"5"}>5</option>
                        <option value={"10"}>10</option>
                        <option value={"15"}>15</option>
                        <option value={"20"}>20</option>
                      </select>
                    </li>
                    <li>
                      <label>Set Discount Per Cent</label>
                      <input
                        type="number"
                        placeholder="00"
                        value={addPackformData?.discountPercentBulk}
                        onChange={e => handleAddPackageInputChange("discountPercentBulk", e.target.value)}
                      />
                      <span className="symbl">%</span>
                    </li>
                  </ul> */}
                  </div>
                </>
              ) : null}
            </div>

            <div className="addForm addOnDiv">
              <h3>Add on</h3>
              <div className="bulkCheck">
                {/* {!packageVerified ? ( */}
                <div className="addInclusion" onClick={handleAddOnClick}>
                  Add Another Add On <img src={plusIcon} alt="" />
                </div>
                {/* ) : null} */}
              </div>
              {addOns.map((addOn, index) => (
                <div className="ItineraryLoop" key={index}>
                  <ul className="formList">
                    <li>
                      <label>
                        Add On Title <span className="starSpan">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Title"
                        // disabled={packageVerified}
                        value={addOn.title}
                        onChange={e => handleInputChange3(index, "title", e.target.value)}
                      />

                      <label className="addPrc">
                        Add on Price <span className="starSpan">*</span>
                      </label>
                      <input
                        type="number"
                        placeholder="₹00.00"
                        value={addOn.price}
                        // disabled={packageVerified}
                        onChange={e => handleInputChange3(index, "price", e.target.value)}
                      />
                      <span className="symbl">INR</span>
                    </li>
                    <li>
                      <label>
                        Add On Description <span className="starSpan">*</span>
                      </label>
                      <textarea value={addOn.description} onChange={e => handleInputChange3(index, "description", e.target.value)} />
                    </li>
                  </ul>
                  {/* {addOn?.id ? (
                    <div className="addInclusion deleteAddon" onClick={() => handleDeletePrevAddOnClick(index)}>
                      Delete Add On <img src={minusIcon} alt="" />
                    </div>
                  ) : ( */}
                  {/* {!packageVerified ? ( */}
                  <div className="addInclusion deleteAddon" onClick={() => handleDeleteAddOnClick(index)}>
                    Delete Add On <img src={minusIcon} alt="" />
                  </div>
                  {/* ) : null} */}
                  {/* )} */}
                </div>
              ))}
            </div>

            <div className="addForm addOnDiv">
              <h3>Inclusions</h3>

              <ul className="formList">
                <li className="fullMedia">
                  <label>
                    Add Inclusions <span>(Please select an option from the list. After selection, you can edit it as needed.)</span>
                  </label>
                  <div className="mediaLoop">
                    {newInclustionSec ? (
                      <input type="text" placeholder="Type here" value={inclutionText} onChange={e => setInclutionText(e.target.value)} />
                    ) : (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        onChange={selected => {
                          // setInclutionText(selected[0]?.name);
                          if (selected.length > 0) {
                            const selectedOption = selected[0];
                            if (selectedOption.name === "Create New") {
                              // Handle create new option selected
                              setInclutionText(""); // Clear existing selection
                              setNewInclustionSec(true);
                              // You can show/hide or manage visibility of input field here
                            } else {
                              setInclutionText(selectedOption.name);
                              setNewInclustionSec(false);
                              // You can optionally add selected option to exclusionList
                              // setExclusionList([...exclusionList, selectedOption.name]);
                            }
                          } else {
                            setInclutionText("");
                          }
                        }}
                        disabled={packageVerified}
                        options={inclusionListFromApi}
                        placeholder="Select here"
                        selected={inclutionText ? [inclutionText] : []}
                      />
                    )}
                    {/* <input type="text" placeholder="Type here" value={inclutionText} onChange={e => setInclutionText(e.target.value)} /> */}
                  </div>
                  {inclustionList.map((link, index) => (
                    <div key={index} className="mediaLoop">
                      <input
                        type="text"
                        placeholder="Select here"
                        disabled={packageVerified}
                        value={link.text}
                        onChange={e => handleInclusionChange(index, e.target.value)}
                      />

                      {/* {link?.id ? (
                        <button className="mediaAddBtn" onClick={() => handleRemovePrevInclusionFromList(index)}>
                          <img src={minusIcon} alt="" />
                        </button>
                      ) : ( */}
                      {!packageVerified ? (
                        <button className="mediaAddBtn" onClick={() => handleRemoveInclusionFromList(index)}>
                          <img src={minusIcon} alt="" />
                        </button>
                      ) : null}

                      {/* )} */}
                    </div>
                  ))}
                  {!packageVerified ? (
                    <button
                      className="mediaAddBtn firstMediaBtn"
                      onClick={() => {
                        handleInclusionAdd();
                        // handleAddMediaLink2();
                        // handleInclusionChange(inclustionList.length, inclustionList[0]);
                      }}>
                      <img src={plusIcon} alt="" />
                    </button>
                  ) : null}
                </li>
              </ul>
            </div>

            <div className="addForm addOnDiv">
              <h3>Exclusions</h3>

              <ul className="formList">
                <li className="fullMedia">
                  <label>
                    Add Exclusions <span>(Please select an option from the list. After selection, you can edit it as needed.)</span>
                  </label>
                  <div className="mediaLoop">
                    {newExclutionSec ? (
                      <input type="text" placeholder="Type here" value={exclusionText} onChange={e => setExclusionText(e.target.value)} />
                    ) : (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        onChange={selected => {
                          // setExclusionText(selected[0]?.name);
                          if (selected.length > 0) {
                            const selectedOption = selected[0];
                            if (selectedOption.name === "Create New") {
                              // Handle create new option selected
                              setExclusionText(""); // Clear existing selection
                              setNewExclutionSec(true);
                              // You can show/hide or manage visibility of input field here
                            } else {
                              setExclusionText(selectedOption.name);
                              setNewExclutionSec(false);
                              // You can optionally add selected option to exclusionList
                              // setExclusionList([...exclusionList, selectedOption.name]);
                            }
                          } else {
                            setExclusionText("");
                          }
                        }}
                        disabled={packageVerified}
                        options={exclusionListFromApi}
                        placeholder="Select here"
                        selected={exclusionText ? [exclusionText] : []}
                      />
                    )}
                    {/* <input type="text" placeholder="Type here" value={exclusionText} onChange={e => setExclusionText(e.target.value)} /> */}
                  </div>
                  {exclusionList.map((link, index) => (
                    <div key={index} className="mediaLoop">
                      <input
                        type="text"
                        placeholder="Select here"
                        disabled={packageVerified}
                        value={link?.text}
                        onChange={e => handleExclusionChange(index, e.target.value)}
                      />
                      {/* {link?.id ? (
                        <button className="mediaAddBtn" onClick={() => handleRemovePrevExclusionFromList(index)}>
                          <img src={minusIcon} alt="" />
                        </button>
                      ) : ( */}
                      {!packageVerified ? (
                        <button className="mediaAddBtn" onClick={() => handleRemoveExclusionFromList(index)}>
                          <img src={minusIcon} alt="" />
                        </button>
                      ) : null}

                      {/* )} */}
                    </div>
                  ))}
                  {!packageVerified ? (
                    <button
                      className="mediaAddBtn firstMediaBtn"
                      onClick={() => {
                        handleExclusionAdd();
                        // handleAddMediaLink3();
                        // handleExclusionChange(exclusionList.length, exclusionList[0]);
                      }}>
                      <img src={plusIcon} alt="" />
                    </button>
                  ) : null}
                </li>
              </ul>
            </div>

            <div className="addForm addOnDiv">
              <h3>Terms & Condition</h3>

              <ul className="formList">
                <li className="fullMedia">
                  <label>Terms & Condition</label>
                  <textarea
                    disabled={packageVerified}
                    value={addPackformData?.termsAndCondition}
                    onChange={e => handleAddPackageInputChange("termsAndCondition", e.target.value)}></textarea>
                </li>
              </ul>
            </div>

            {/* <div className="addForm addOnDiv">
              <h3>Payment Policy</h3>

              <ul className="formList">
                <li className="fullMedia">
                  <label>Payment Policy</label>
                  <input
                    type="text"
                    placeholder="Type here"
                    readOnly
                    disabled={packageVerified}
                    value={addPackformData.paymentPolicy}
                    onChange={e => handleAddPackageInputChange("paymentPolicy", e.target.value)}
                  />
                </li>
              </ul>
            </div> */}

            <div className="addForm addOnDiv">
              <h3>Payment Policy</h3>

              <ul className="formList">
                <li className="fullMedia">
                  <label>
                    Payment Policy <span className="starSpan">*</span>
                    <p className="infoTxt">(You can split the payment policy into a maximum of 3 intervals based on days before departure.)</p>
                  </label>
                  <div className="cancelSection">
                    <select
                      className="smallInput"
                      name="from"
                      value={paymentPoliciesDefault.from}
                      disabled={packageVerified}
                      onChange={e => handlePaymentPolicyDefaultInputChange("from", e.target.value)}>
                      <option selected hidden>
                        {" "}
                      </option>
                      {[...Array(120).keys()].map(day => (
                        <option key={day + 1} value={day + 1}>
                          {day + 1}
                        </option>
                      ))}
                    </select>{" "}
                    <span className="lebelSpan">or more days before departure a payment of:</span>{" "}
                    <select
                      className="smallInput"
                      name="percentage"
                      value={paymentPoliciesDefault.percentage}
                      disabled={packageVerified}
                      onChange={e => handlePaymentPolicyDefaultInputChange("percentage", e.target.value)}>
                      <option selected hidden>
                        {" "}
                      </option>
                      {[...Array(101).keys()].map(percent => (
                        <option key={percent} value={percent}>
                          {percent}
                        </option>
                      ))}
                    </select>{" "}
                    <span className="lebelSpan">% of the total package cost is required.</span>
                    {paymentPolicies.length < 2 && !packageVerified && (
                      <button className="mediaAddBtn firstMediaBtn cancelAddBtn" onClick={handleAddPaymentPolicy}>
                        <img src={plusIcon} alt="" />
                      </button>
                    )}
                  </div>
                </li>

                {paymentPolicies.map((policy, index) => (
                  <li className="fullMedia" key={index}>
                    <div className="cancelSection">
                      <span className="lebelSpan">Between</span>
                      <select
                        className="smallInput"
                        name="from"
                        value={policy.from}
                        disabled={packageVerified}
                        onChange={e => handlePaymentPolicyInputChange(index, "from", e.target.value)}>
                        <option selected hidden>
                          {" "}
                        </option>
                        {[...Array(120).keys()].map(day => (
                          <option key={day + 1} value={day + 1}>
                            {day + 1}
                          </option>
                        ))}
                      </select>
                      {/* <input
                      type="number"
                      className="smallInput"
                      name="from"
                      value={policy.from}
                      onChange={e => handlePolicyInputChange(index, "from", e.target.value)}
                    /> */}
                      <span className="lebelSpan">to</span>
                      <select
                        className="smallInput"
                        name="to"
                        disabled={packageVerified}
                        value={policy.to}
                        onChange={e => handlePaymentPolicyInputChange(index, "to", e.target.value)}>
                        <option selected hidden>
                          {" "}
                        </option>
                        {[...Array(120).keys()].map(day => (
                          <option key={day + 1} value={day + 1}>
                            {day + 1}
                          </option>
                        ))}
                      </select>
                      <span className="lebelSpan">days before departure a payment of:</span>
                      {/* <input
                      type="number"
                      className="smallInput"
                      name="to"
                      value={policy.to}
                      onChange={e => handlePolicyInputChange(index, "to", e.target.value)}
                    />
                    <span className="lebelSpan">days before departure:</span> */}
                      <select
                        className="smallInput"
                        name="percentage"
                        value={policy.percentage}
                        disabled={packageVerified}
                        onChange={e => handlePaymentPolicyInputChange(index, "percentage", e.target.value)}>
                        <option selected hidden>
                          {" "}
                        </option>
                        {[...Array(101).keys()].map(percent => (
                          <option key={percent} value={percent}>
                            {percent}
                          </option>
                        ))}
                      </select>
                      {/* <input
                      type="number"
                      className="smallInput"
                      name="percentage"
                      value={policy.percentage}
                      onChange={e => handlePolicyInputChange(index, "percentage", e.target.value)}
                    /> */}
                      <span className="lebelSpan">% of the total package cost is required.</span>
                      {/* {index !== 0 && ( */}
                      {!packageVerified ? (
                        <button className="mediaAddBtn firstMediaBtn cancelAddBtn" onClick={() => handleRemovePaymentPolicy(index)}>
                          <img src={minusIcon} alt="" />
                        </button>
                      ) : null}

                      {/* )} */}
                    </div>
                  </li>
                ))}

                {/* <li className="fullMedia">
                  <span className="lebelSpan">Between </span> <input type="number" className="smallInput" />
                  <span className="lebelSpan"> to </span>
                  <input type="number" className="smallInput" /> <span className="lebelSpan">days before departure:</span>{" "}
                  <input type="number" className="smallInput" /> <span className="lebelSpan">%</span>
                  <button className="mediaAddBtn firstMediaBtn cancelAddBtn">
                    <img src={minusIcon} alt="" />
                  </button>
                </li> */}
              </ul>
            </div>

            <div className="addForm addOnDiv">
              <h3>Cancellation Policy</h3>

              <ul className="formList">
                <li className="fullMedia">
                  <label>
                    Cancellation Policy <span className="starSpan">*</span>
                    <p className="infoTxt">(You can split the cancellation policy into a maximum of 4 intervals based on days before departure.)</p>
                  </label>
                  <div className="cancelSection">
                    <select
                      className="smallInput"
                      name="from"
                      value={cancellationPoliciesDefault.from}
                      disabled={packageVerified}
                      onChange={e => handlePolicyDefaultInputChange("from", e.target.value)}>
                      <option selected hidden>
                        {" "}
                      </option>
                      {[...Array(120).keys()].map(day => (
                        <option key={day + 1} value={day + 1}>
                          {day + 1}
                        </option>
                      ))}
                    </select>{" "}
                    {/* <input
                    className="smallInput"
                    type="number"
                    name="from"
                    value={cancellationPoliciesDefault.from}
                    onChange={e => handlePolicyDefaultInputChange("from", e.target.value)}
                  />{" "} */}
                    <span className="lebelSpan">or more days before departure a payment of:</span>{" "}
                    <select
                      className="smallInput"
                      name="percentage"
                      value={cancellationPoliciesDefault.percentage}
                      disabled={packageVerified}
                      onChange={e => handlePolicyDefaultInputChange("percentage", e.target.value)}>
                      <option selected hidden>
                        {" "}
                      </option>
                      {[...Array(101).keys()].map(percent => (
                        <option key={percent} value={percent}>
                          {percent}
                        </option>
                      ))}
                    </select>{" "}
                    {/* <input
                    type="number"
                    name="percentage"
                    className="smallInput"
                    value={cancellationPoliciesDefault.percentage}
                    onChange={e => handlePolicyDefaultInputChange("percentage", e.target.value)}
                  />{" "} */}
                    <span className="lebelSpan">% of the total package cost is required.</span>
                    {cancellationPolicies.length < 3 && !packageVerified && (
                      <button className="mediaAddBtn firstMediaBtn cancelAddBtn" onClick={handleAddPolicy}>
                        <img src={plusIcon} alt="" />
                      </button>
                    )}
                  </div>
                  {/* <input
                    className="smallInput"
                    type="number"
                    name="from"
                    disabled={packageVerified}
                    value={cancellationPoliciesDefault.from}
                    onChange={e => handlePolicyDefaultInputChange("from", e.target.value)}
                  />{" "}
                  <span className="lebelSpan">or more days before departure:</span>{" "}
                  <input
                    type="number"
                    name="percentage"
                    className="smallInput"
                    disabled={packageVerified}
                    value={cancellationPoliciesDefault.percentage}
                    onChange={e => handlePolicyDefaultInputChange("percentage", e.target.value)}
                  />{" "}
                  <span className="lebelSpan">%</span>
                  {cancellationPolicies.length < 3 && !packageVerified && (
                    <button className="mediaAddBtn firstMediaBtn cancelAddBtn" onClick={handleAddPolicy}>
                      <img src={plusIcon} alt="" />
                    </button>
                  )} */}
                </li>

                {cancellationPolicies.map((policy, index) => (
                  <li className="fullMedia" key={index}>
                    <div className="cancelSection">
                      <span className="lebelSpan">Between</span>
                      <select
                        className="smallInput"
                        name="from"
                        disabled={packageVerified}
                        value={policy.from}
                        onChange={e => handlePolicyInputChange(index, "from", e.target.value)}>
                        <option selected hidden>
                          {" "}
                        </option>
                        {[...Array(120).keys()].map(day => (
                          <option key={day + 1} value={day + 1}>
                            {day + 1}
                          </option>
                        ))}
                      </select>
                      {/* <input
                      type="number"
                      className="smallInput"
                      name="from"
                      value={policy.from}
                      onChange={e => handlePolicyInputChange(index, "from", e.target.value)}
                    /> */}
                      <span className="lebelSpan">to</span>
                      <select
                        className="smallInput"
                        name="to"
                        disabled={packageVerified}
                        value={policy.to}
                        onChange={e => handlePolicyInputChange(index, "to", e.target.value)}>
                        <option selected hidden>
                          {" "}
                        </option>
                        {[...Array(120).keys()].map(day => (
                          <option key={day + 1} value={day + 1}>
                            {day + 1}
                          </option>
                        ))}
                      </select>
                      <span className="lebelSpan">days before departure a payment of:</span>
                      {/* <input
                      type="number"
                      className="smallInput"
                      name="to"
                      value={policy.to}
                      onChange={e => handlePolicyInputChange(index, "to", e.target.value)}
                    />
                    <span className="lebelSpan">days before departure:</span> */}
                      <select
                        className="smallInput"
                        name="percentage"
                        value={policy.percentage}
                        disabled={packageVerified}
                        onChange={e => handlePolicyInputChange(index, "percentage", e.target.value)}>
                        <option selected hidden>
                          {" "}
                        </option>
                        {[...Array(101).keys()].map(percent => (
                          <option key={percent} value={percent}>
                            {percent}
                          </option>
                        ))}
                      </select>
                      {/* <input
                      type="number"
                      className="smallInput"
                      name="percentage"
                      value={policy.percentage}
                      onChange={e => handlePolicyInputChange(index, "percentage", e.target.value)}
                    /> */}
                      <span className="lebelSpan">% of the total package cost is required.</span>
                      {/* {index !== 0 && ( */}
                      {!packageVerified ? (
                        <button className="mediaAddBtn firstMediaBtn cancelAddBtn" onClick={() => handleRemovePolicy(index)}>
                          <img src={minusIcon} alt="" />
                        </button>
                      ) : null}

                      {/* )} */}
                    </div>
                  </li>
                ))}

                {/* {cancellationPolicies.map((policy, index) => (
                  <li className="fullMedia" key={index}>
                    <span className="lebelSpan">Between</span>
                    <input
                      type="number"
                      className="smallInput"
                      name="from"
                      disabled={packageVerified}
                      value={policy.from}
                      onChange={e => handlePolicyInputChange(index, "from", e.target.value)}
                    />
                    <span className="lebelSpan">to</span>
                    <input
                      type="number"
                      className="smallInput"
                      name="to"
                      disabled={packageVerified}
                      value={policy.to}
                      onChange={e => handlePolicyInputChange(index, "to", e.target.value)}
                    />
                    <span className="lebelSpan">days before departure:</span>
                    <input
                      type="number"
                      className="smallInput"
                      name="percentage"
                      disabled={packageVerified}
                      value={policy.percentage}
                      onChange={e => handlePolicyInputChange(index, "percentage", e.target.value)}
                    />
                    <span className="lebelSpan">%</span>
                    {!packageVerified ? (
                      <button className="mediaAddBtn firstMediaBtn cancelAddBtn" onClick={() => handleRemovePolicy(index)}>
                        <img src={minusIcon} alt="" />
                      </button>
                    ) : null}
                  </li>
                ))} */}
              </ul>
            </div>

            {packageVerified ? (
              <div className="addForm addOnDiv timelineDiv">
                <h3>Timeline</h3>

                <ul className="formList timelineList">
                  <li className="fullMedia timelineItem">
                    <label>Approved On: {packageDetails?.admin_verified_at}</label>
                    {/* <p>{packageDetails?.admin_verified_at}</p> */}
                  </li>
                  <li className="fullMedia timelineItem">
                    <label>Accepted On: {packageDetails?.vendor_verified_at}</label>
                    {/* <p>{packageDetails?.vendor_verified_at}</p> */}
                  </li>
                </ul>

                <div className="invoiceDetails">
                  <p className="invoiceExampleLabel">
                    <strong>Accepted package pricing</strong>
                  </p>
                  <p>Convenience Fees: {packageDetails?.platform_charges}%</p>
                  <p className="invoiceExampleLabel">
                    <strong>Example:</strong>
                  </p>
                  <ul className="invoiceDetailsList">
                    <li>Standard package cost (per adult on twin sharing, excluding any add on, surcharge and discount, GST if any)</li>
                    <li>
                      + Convenience fees @{packageDetails?.platform_charges}% on total package price: ₹{packageDetails?.platform_charges_amount}
                    </li>
                    {/* <li>+ Convenience fees ₹{packageDetails?.platform_charges_amount}</li> */}
                    <li>+ GST(18%) on convenience fees: ₹{packageDetails?.platform_charges_gst}</li>
                    <li>
                      + GST ({packageDetails?.gst_rate}%): ₹{packageDetails?.gst_amount}
                    </li>
                    <li>= Total package cost: ₹{packageDetails?.website_price}</li>
                  </ul>
                  <p className="invoicePayoutLabel">
                    <strong>Payout Details:</strong>
                  </p>
                  <p className="invoicePayoutIntro">You will receive:</p>
                  <ul className="invoicePayoutList">
                    <li>Booking Price</li>
                    <li>- Convenience fees on total package price: {packageDetails?.platform_charges}%</li>
                    <li>- GST TDS(2%)</li>
                    <li>- TDS ({packageDetails?.tds_rate_text})</li>
                  </ul>
                </div>
              </div>
            ) : null}

            {showPopup && (
              <div className={`popup addPopup`} id={`popup${popupIdentifier}`}>
                <div className="sliderflex">
                  <div className="popInner2">
                    <h2>
                      Add Activity{" "}
                      <span className="infoTxt">
                        (Please fill up the form and click the <strong>Add</strong> button to save each activity.)
                      </span>
                    </h2>
                    <div className="tabs">
                      {["Flight", "Train", "Hotel", "Meal", "Sightseeing", "Local Transport"].map(tab => (
                        <div key={tab} className={`tab ${tab === selectedTab ? "active" : ""}`} onClick={() => handleTabClick(tab)}>
                          {tab}
                        </div>
                      ))}
                    </div>
                    <div className="tabContent">
                      {selectedTab === "Flight" && selectedDay !== null && (
                        <div>
                          <ul>
                            {/* <li>
                              <select value={editingValues?.flightType || ""} onChange={e => handleInputChange("flightType", e.target.value)}>
                                <option>-- Select Airline --</option>
                                <option>Air Asia</option>
                                <option>Indigo</option>
                                <option>Vistara</option>
                              </select>
                            </li>
                            <li>
                              <input
                                type="text"
                                value={editingValues?.flightNumber || ""}
                                placeholder="Flight Number"
                                onChange={e => handleInputChange("flightNumber", e.target.value)}
                                // onChange={e => handleAddInclusionChange("flight", "flightNumber", e.target.value)}
                              />
                            </li>

                            <li>
                              <select value={editingValues?.flightClass || ""} onChange={e => handleInputChange("flightClass", e.target.value)}>
                                <option>-- Select Class --</option>
                                <option>Economy</option>
                                <option>Business</option>
                              </select>
                            </li>
                            <li>
                              <div class="checkboxes-and-radios">
                                <input
                                  type="checkbox"
                                  name={`checkbox-catsday${selectedDay}`}
                                  id={`checkbox-day${selectedDay}`}
                                  value={selectedDay}
                                  checked={editingValues?.flightNonStop ? true : false}
                                  onChange={e => handleInputChange("flightNonStop", e.target.checked)}
                                />
                                <label for={`checkbox-day${selectedDay}`}>Non Stop</label>
                                <input type="checkbox" name={`checkbox-catsday${selectedDay}`} id={`checkbox-day${selectedDay}`} value={selectedDay} checked />
                              </div>
                            </li> */}
                            <li className="fullLIst">
                              <h3>Set Flight Destination</h3>
                            </li>
                            <li>
                              {/* <select value={editingValues?.departureCity || ""} onChange={e => handleInputChange("departureCity", e.target.value)}>
                                <option>-- Depart--</option>
                                <option>Kolkata</option>
                                <option>Mumbai</option>
                                <option>Delhi</option>
                              </select> */}

                              {/* <Typeahead
                                id="basic-typeahead-single_flight_destination"
                                labelKey="city"
                                onChange={selected => {
                                  handleInputChange("departureCity", selected[0]?.id ? selected[0].id : null);
                                }}
                                options={allCityList}
                                placeholder="-- Depart --"
                                selected={editingValues?.departureCity ? allCityList.filter(item => item.id === editingValues?.departureCity) : ""}
                              /> */}
                              <input
                                type="text"
                                value={editingValues?.arrivalCity || ""}
                                placeholder="-- Arrival --"
                                onChange={e => handleInputChange("arrivalCity", e.target.value)}
                              />
                            </li>
                            <li>
                              {/* <select value={editingValues?.arrivalCity || ""} onChange={e => handleInputChange("arrivalCity", e.target.value)}>
                                <option>-- Arrive--</option>
                                <option>Kolkata</option>
                                <option>Mumbai</option>
                                <option>Delhi</option>
                              </select> */}

                              {/* <Typeahead
                                id="basic-typeahead-single_flight_destination"
                                labelKey="city"
                                onChange={selected => {
                                  // Check if selected is truthy and has at least one element
                                  if (selected && selected.length > 0) {
                                    handleInputChange("arrivalCity", selected[0].id ? selected[0].id : null);
                                  } else {
                                    // If selected is falsy or has no elements, handle it accordingly
                                    handleInputChange("arrivalCity", null);
                                  }
                                }}
                                options={allCityList}
                                placeholder="-- Arrive --"
                                selected={editingValues?.arrivalCity ? allCityList.filter(item => item.id === editingValues?.arrivalCity) : ""}
                              /> */}

                              <input
                                type="text"
                                value={editingValues?.departureCity || ""}
                                placeholder="-- Depart --"
                                onChange={e => handleInputChange("departureCity", e.target.value)}
                              />
                            </li>
                            <li className="fullLIst">
                              <h3>Flight Timings</h3>
                            </li>
                            <li className="dateHolder">
                              <h4>Departure Time</h4>
                              <div className="datePckr">
                                {/* <DatePicker
                                  selected={editingValues.departureDate}
                                  onChange={date => handleInputChange("departureDate", date)}
                                  dateFormat="MMMM d, yyyy"
                                  placeholderText="Select Date"
                                  minDate={new Date()}
                                /> */}
                                <input
                                  type="time"
                                  placeholder="00:00"
                                  value={editingValues.departureTime || ""}
                                  onChange={e => {
                                    handleInputChange("departureTime", e.target.value);
                                  }}
                                />
                              </div>
                              {/* <div className="timePckr">
                                <input
                                  type="datetime-local"
                                  placeholder="00:00"
                                  value={editingValues.departureTime || ""}
                                  onChange={e => {
                                    handleInputChange("departureTime", e.target.value);
                                  }}
                                />
                              </div> */}
                            </li>
                            <li className="dateHolder">
                              <h4>Arrival Time</h4>
                              <div className="datePckr">
                                {/* <DatePicker
                                  selected={editingValues.arrivalDate}
                                  onChange={date => handleInputChange("arrivalDate", date)}
                                  dateFormat="MMMM d, yyyy"
                                  placeholderText="Select Date"
                                  minDate={new Date()}
                                /> */}
                                <input
                                  type="time"
                                  placeholder="00:00"
                                  value={editingValues.arrivalTime || ""}
                                  onChange={e => handleInputChange("arrivalTime", e.target.value)}
                                />
                              </div>
                              {/* <div className="timePckr">
                                <input
                                  type="datetime-local"
                                  placeholder="00:00"
                                  value={editingValues.arrivalTime || ""}
                                  onChange={e => handleInputChange("arrivalTime", e.target.value)}
                                />
                              </div> */}
                            </li>
                            <li className="fullLIst hotelCheckSection">
                              <input
                                type="checkbox"
                                className="checkBox"
                                checked={editingValues?.flightOvernight === 1 ? true : false || ""}
                                onChange={e => handleInputChange("flightOvernight", e.target.checked ? 1 : 0)}
                              />
                              <label>Is Overnight?</label>
                            </li>
                            <li></li>
                            {/* {editingValues?.flightOvernight === 1 ? (
                              <>
                                <li>
                                  <select value={editingValues?.flightNightCount || ""} onChange={e => handleInputChange("flightNightCount", e.target.value)}>
                                    <option value={1}>1 Night</option>
                                    <option value={2}>2 Nights</option>
                                    <option value={3}>3 Nights</option>
                                  </select>
                                </li>
                              </>
                            ) : null} */}
                            <li className="fullLIst">
                              <button onClick={() => handleAddInclusion("flight")} className="addBtn">
                                Add
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}

                      {selectedTab === "Train" && selectedDay !== null && (
                        <div>
                          <ul>
                            <li>
                              <input
                                type="text"
                                value={editingValues?.trainName || ""}
                                placeholder="Train Name"
                                onChange={e => handleInputChange("trainName", e.target.value)}
                              />
                              {/* <select value={editingValues?.trainName || ""} onChange={e => handleInputChange("trainName", e.target.value)}>
                                <option>Train Name</option>
                                <option>Bande Bharat</option>
                                <option>Kormandal Express</option>
                              </select> */}
                            </li>
                            <li>
                              <input
                                type="text"
                                value={editingValues?.trainNumber || ""}
                                placeholder="Train Number"
                                onChange={e => handleInputChange("trainNumber", e.target.value)}
                              />
                            </li>

                            <li>
                              <select value={editingValues?.trainClass || ""} onChange={e => handleInputChange("trainClass", e.target.value)}>
                                <option>-- Select Class --</option>
                                <option>General</option>
                                <option>3 Tier Ac</option>
                                <option>2 Tier Ac</option>
                              </select>
                            </li>

                            <li className="fullLIst">
                              <h3>Set Train Destination</h3>
                            </li>
                            <li>
                              {/* <select value={editingValues?.trainDepartureCity || ""} onChange={e => handleInputChange("trainDepartureCity", e.target.value)}>
                                <option>-- From Station--</option>
                                <option>Kolkata</option>
                                <option>Mumbai</option>
                                <option>Delhi</option>
                              </select> */}

                              <input
                                type="text"
                                value={editingValues?.trainDepartureCity || ""}
                                placeholder="-- From Station --"
                                onChange={e => handleInputChange("trainDepartureCity", e.target.value)}
                              />

                              {/* <Typeahead
                                id="basic-typeahead-single_flight_destination"
                                labelKey="city"
                                onChange={selected => {
                                  handleInputChange("trainDepartureCity", selected[0].id ? selected[0].id : null);
                                }}
                                options={allCityList}
                                placeholder="-- From Station --"
                                selected={editingValues?.trainDepartureCity ? allCityList.filter(item => item.id === editingValues?.trainDepartureCity) : ""}
                              /> */}
                            </li>
                            <li>
                              {/* <select value={editingValues?.trainArrivalCity || ""} onChange={e => handleInputChange("trainArrivalCity", e.target.value)}>
                                <option>-- To Station--</option>
                                <option>Kolkata</option>
                                <option>Mumbai</option>
                                <option>Delhi</option>
                              </select> */}

                              <input
                                type="text"
                                value={editingValues?.trainArrivalCity || ""}
                                placeholder="-- To Station --"
                                onChange={e => handleInputChange("trainArrivalCity", e.target.value)}
                              />

                              {/* <Typeahead
                                id="basic-typeahead-single_flight_destination"
                                labelKey="city"
                                onChange={selected => {
                                  handleInputChange("trainArrivalCity", selected[0].id ? selected[0].id : null);
                                }}
                                options={allCityList}
                                placeholder="-- To Station --"
                                selected={editingValues?.trainArrivalCity ? allCityList.filter(item => item.id === editingValues?.trainArrivalCity) : ""}
                              /> */}
                            </li>
                            <li className="fullLIst">
                              <h3>Train Timings</h3>
                            </li>
                            <li className="dateHolder">
                              <h4>Arrival Time</h4>
                              <div className="datePckr">
                                {/* <DatePicker
                                  selected={editingValues.trainArrivalDate}
                                  onChange={date => handleInputChange("trainArrivalDate", date)}
                                  dateFormat="MMMM d, yyyy"
                                  placeholderText="Select Date"
                                  minDate={new Date()}
                                /> */}
                                <input
                                  type="time"
                                  placeholder="00:00"
                                  value={editingValues.trainArrivalTime || ""}
                                  onChange={e => handleInputChange("trainArrivalTime", e.target.value)}
                                />
                              </div>
                              {/* <div className="timePckr">
                                <input
                                  type="datetime-local"
                                  placeholder="00:00"
                                  value={editingValues.trainArrivalTime || ""}
                                  onChange={e => handleInputChange("trainArrivalTime", e.target.value)}
                                />
                              </div> */}
                            </li>
                            <li className="dateHolder">
                              <h4>Departure Time</h4>
                              <div className="datePckr">
                                {/* <DatePicker
                                  selected={editingValues.trainDepartureDate}
                                  onChange={date => handleInputChange("trainDepartureDate", date)}
                                  dateFormat="MMMM d, yyyy"
                                  placeholderText="Select Date"
                                  minDate={new Date()}
                                /> */}
                                <input
                                  type="time"
                                  placeholder="00:00"
                                  value={editingValues.trainDepartureTime || ""}
                                  onChange={e => handleInputChange("trainDepartureTime", e.target.value)}
                                />
                              </div>
                              {/* <div className="timePckr">
                                <input
                                  type="datetime-local"
                                  placeholder="00:00"
                                  value={editingValues.trainDepartureTime || ""}
                                  onChange={e => handleInputChange("trainDepartureTime", e.target.value)}
                                />
                              </div> */}
                            </li>
                            <li className="fullLIst hotelCheckSection">
                              <input
                                type="checkbox"
                                className="checkBox"
                                checked={editingValues?.trainOvernight === 1 ? true : false || ""}
                                onChange={e => handleInputChange("trainOvernight", e.target.checked ? 1 : 0)}
                              />
                              <label>Is Overnight?</label>
                            </li>
                            {/* {editingValues?.trainOvernight === 1 ? (
                              <>
                                <li>
                                  <select value={editingValues?.trainNightCount || ""} onChange={e => handleInputChange("trainNightCount", e.target.value)}>
                                    <option value={1}>1 Night</option>
                                    <option value={2}>2 Nights</option>
                                    <option value={3}>3 Nights</option>
                                  </select>
                                </li>
                              </>
                            ) : null} */}
                            <li className="fullLIst">
                              <button onClick={() => handleAddInclusion("train")} className="addBtn">
                                Add
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}

                      {selectedTab === "Hotel" && selectedDay !== null && (
                        <div>
                          <ul>
                            <li>
                              <input
                                type="text"
                                value={editingValues?.hotelName || ""}
                                placeholder="Name of Hotel / Resort"
                                onChange={e => handleInputChange("hotelName", e.target.value)}
                              />
                            </li>
                            <li className="fullLIst hotelCheckSection">
                              <input
                                type="checkbox"
                                className="checkBox"
                                checked={editingValues?.hotelCheckbox === 1 ? true : false || ""}
                                onChange={e => handleInputChange("hotelCheckbox", e.target.checked ? 1 : 0)}
                              />
                              <label>The hotel is located in a place other than the main town</label>
                            </li>
                            {editingValues?.hotelCheckbox === 1 ? (
                              <>
                                <li>
                                  <input
                                    type="text"
                                    value={editingValues?.hotelPlaceName || ""}
                                    placeholder="Place name"
                                    onChange={e => handleInputChange("hotelPlaceName", e.target.value)}
                                  />
                                </li>
                                <li>
                                  <input
                                    type="text"
                                    value={editingValues?.hotelDistance || ""}
                                    placeholder="Distance from main town"
                                    onChange={e => handleInputChange("hotelDistance", e.target.value)}
                                  />
                                </li>
                              </>
                            ) : null}
                            <li className="fullLIst ratingSlct">
                              <h3>
                                Rate this Hotel
                                {/* <StarRating onRatingChange={handlePopupRatingChange} identifier={popupIdentifier} /> */}
                                <StarRatings
                                  rating={editingValues?.hotelStar || 0}
                                  starRatedColor="yellow"
                                  changeRating={star => handleInputChange("hotelStar", star)}
                                  numberOfStars={5}
                                  name={`rating-${selectedDay}`}
                                  starDimension="25px"
                                  starSpacing="2px"
                                />
                              </h3>
                            </li>
                            <li className="fullLIst">
                              <h4>Add Hotel Photos</h4>

                              <ImageUploader
                                sectionTitle="Section 1"
                                onImagesChange={images => handleImagesChange(images, "Section 2")}
                                propsPreviousImage={section2Images}
                                propsSection2PrevImages={section2PrevImages}
                              />

                              <p className="dragtxt2">
                                Images size should be below 1mb for each. Allowed file extensions are (png, jpeg, and jpg). Maximum 5 images are allowed.
                              </p>
                            </li>

                            <li className="fullLIst">
                              <button onClick={() => handleAddInclusion("hotel")} className="addBtn">
                                Add
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}

                      {selectedTab === "Meal" && selectedDay !== null && (
                        <div>
                          <ul className="mealPop">
                            <li className="boxMeal">
                              <input
                                type="checkbox"
                                checked={editingValues?.breakfast !== undefined}
                                onChange={e => handleInputChange("breakfast", e.target.checked ? "breakfast" : undefined)}
                              />
                              Breakfast
                            </li>
                            <li className="boxMeal">
                              <input
                                type="checkbox"
                                checked={editingValues?.lunch !== undefined}
                                onChange={e => handleInputChange("lunch", e.target.checked ? "lunch" : undefined)}
                              />
                              Lunch
                            </li>
                            <li className="boxMeal">
                              <input
                                type="checkbox"
                                checked={editingValues?.dinner !== undefined}
                                onChange={e => handleInputChange("dinner", e.target.checked ? "dinner" : undefined)}
                              />
                              Dinner
                            </li>
                            <li className="fullLIst">
                              <button onClick={() => handleAddInclusion("meal")} className="addBtn">
                                Add
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}

                      {selectedTab === "Sightseeing" && selectedDay !== null && (
                        <div>
                          <ul className="sightPop">
                            <li>
                              <div className="sightInput">
                                <input
                                  type="checkbox"
                                  checked={editingValues?.morning !== undefined}
                                  onChange={e => handleInputChange("morning", e.target.checked ? "morning" : undefined)}
                                />
                                Morning
                              </div>

                              <input
                                type="text"
                                value={editingValues?.morningText || ""}
                                placeholder="Type the name of sightseeing"
                                onChange={e => handleInputChange("morningText", e.target.value)}
                              />
                            </li>
                            <li>
                              <div className="sightInput">
                                <input
                                  type="checkbox"
                                  checked={editingValues?.afternoon !== undefined}
                                  onChange={e => handleInputChange("afternoon", e.target.checked ? "afternoon" : undefined)}
                                />
                                Afternoon
                              </div>
                              <input
                                type="text"
                                value={editingValues?.afternoonText || ""}
                                placeholder="Type the name of sightseeing"
                                onChange={e => handleInputChange("afternoonText", e.target.value)}
                              />
                            </li>
                            <li>
                              <div className="sightInput">
                                <input
                                  type="checkbox"
                                  checked={editingValues?.evening !== undefined}
                                  onChange={e => handleInputChange("evening", e.target.checked ? "evening" : undefined)}
                                />
                                Evening
                              </div>
                              <input
                                type="text"
                                value={editingValues?.eveningText || ""}
                                placeholder="Type the name of sightseeing"
                                onChange={e => handleInputChange("eveningText", e.target.value)}
                              />
                            </li>
                            <li>
                              <div className="sightInput">
                                <input
                                  type="checkbox"
                                  checked={editingValues?.night !== undefined}
                                  onChange={e => handleInputChange("night", e.target.checked ? "night" : undefined)}
                                />
                                Night
                              </div>
                              <input
                                type="text"
                                value={editingValues?.nightText || ""}
                                placeholder="Type the name of sightseeing"
                                onChange={e => handleInputChange("nightText", e.target.value)}
                              />
                            </li>
                            <li className="fullLIst sightPhtos">
                              <h4>Add Sightseeing Photos</h4>

                              {/* <ImageUploader
                                  sectionTitle="Section 3"
                                  onImagesChange={images => handleImagesChange(images, "Section 3")}
                                  propsPreviousImage={section3Images}
                                /> */}

                              <ImageUploader
                                sectionTitle="Section 3"
                                onImagesChange={images => handleImagesChange(images, "Section 3")}
                                propsPreviousImage={section3Images}
                                propsSection2PrevImages={section3PrevImages}
                              />

                              <p className="dragtxt2">
                                Images size should be below 1mb for each. Allowed file extensions are (png, jpeg, and jpg). Maximum 5 images are allowed.
                              </p>
                            </li>
                            <li className="fullLIst">
                              <button onClick={() => handleAddInclusion("sightseeing")} className="addBtn">
                                Add
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}

                      {selectedTab === "Local Transport" && selectedDay !== null && (
                        <div>
                          <ul>
                            <li>
                              <select value={editingValues?.carType || ""} onChange={e => handleInputChange("carType", e.target.value)}>
                                <option selected hidden>
                                  Transport
                                </option>
                                <option>Car</option>
                                <option>Jeep</option>
                                <option>Traveller</option>
                                <option>Bus</option>
                              </select>
                            </li>

                            <li>
                              <input
                                type="text"
                                value={editingValues?.carModel || ""}
                                placeholder="Model"
                                onChange={e => handleInputChange("carModel", e.target.value)}
                              />
                            </li>
                            <li>
                              <select value={editingValues?.carCapacity || ""} onChange={e => handleInputChange("carCapacity", e.target.value)}>
                                <option selected hidden>
                                  Capacity
                                </option>
                                {[...Array(60).keys()].map(count => (
                                  <option key={count + 1} value={count + 1}>
                                    {count + 1} Seater
                                  </option>
                                ))}
                              </select>
                            </li>
                            <li>
                              <div class="checkboxes-and-radios">
                                <input
                                  type="checkbox"
                                  name={`checkbox-catstran${selectedDay}`}
                                  id={`checkbox-tran${selectedDay}`}
                                  value={selectedDay}
                                  checked={editingValues?.localCarAc ? true : false}
                                  onChange={e => handleInputChange("localCarAc", e.target.checked)}
                                />
                                <label for={`checkbox-tran${selectedDay}`}>AC</label>
                                <input
                                  type="checkbox"
                                  name={`checkbox-catstran${selectedDay}`}
                                  id={`checkbox-tran${selectedDay}`}
                                  value={selectedDay}
                                  checked
                                />
                              </div>
                            </li>
                            <li className="fullLIst">
                              <button onClick={() => handleAddInclusion("localtransport")} className="addBtn">
                                Add
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <button className="closePopupBtn2" onClick={() => setShowPopup(false)}>
                      <img src={crossIcon2} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            )}

            {editSeatPopup ? (
              <div className={`popup addPopup`} id={`popup${popupIdentifier}`}>
                <div className="sliderflex">
                  <div className="popInner2 seatPopup">
                    <h2>Edit Seat Availability</h2>
                    <div className="dateTime">
                      <p>
                        <strong>{selectedDateObj?.selectedMonth}</strong>
                      </p>
                      <p>{selectedDateObj?.newFormat}</p>
                    </div>
                    <div className="tabContent">
                      <div>
                        <ul>
                          <li>
                            <label>Seat Availability</label>
                            <input type="text" value={selectedDateObj?.seat} placeholder="Seat" onChange={e => handleSeatInputChange("seat", e.target.value)} />
                          </li>
                          {/* <li>
                            <label>Package cost for Per Adult</label>
                            <input
                              type="number"
                              value={selectedDateObj?.price}
                              placeholder="Price"
                              onChange={e => handleSeatInputChange("price", e.target.value)}
                            />
                          </li> */}

                          <li className="fullLIst">
                            <button onClick={() => handleUpdateSeatDetails()} className="addBtn">
                              Update
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <button className="closePopupBtn2" onClick={() => setEditSeatPopup(false)}>
                      <img src={crossIcon2} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            <Modal show={vendorApproveModal} className="CmnModal SendRequestModal">
              <Modal.Header>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="congratsClass">
                  <img src={VendorRegisteredSuccessIcon} alt="" className="SuccessIconImg" />
                  <h2>Package is Now Live!</h2>
                  <p>
                    Thank you for partnering with us. We are delighted to inform you that your tour package is now live on our platform. Customers can now view
                    and book your travel package directly through our website.
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="modalFooterButton"
                  onClick={() => {
                    setVendorApproveModal(false);
                  }}>
                  Continue
                </button>
              </Modal.Footer>
            </Modal>
            <Modal show={false} className="CmnModal SendRequestModal">
              <Modal.Header>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="congratsClass">
                  <img src={VendorRegisteredSuccessIcon} alt="" className="SuccessIconImg" />
                  <h2>Package is Now Live!</h2>
                  <p>
                    Thank you for partnering with us. We are delighted to inform you that your tour package is now live on our platform. Customers can now view
                    and book your travel package directly through our website.
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="modalFooterButton"
                  onClick={() => {
                    setVendorApproveModal(false);
                  }}>
                  Cancel
                </button>
                <button
                  className="modalFooterButton"
                  onClick={() => {
                    setVendorApproveModal(false);
                  }}>
                  Accept
                </button>
                <button
                  className="modalFooterButton"
                  onClick={() => {
                    setVendorApproveModal(false);
                  }}>
                  Decline
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTourPackage;
