import React, {Component, useState, useRef, useEffect} from "react";
import Header from "../component/Header";
import Footer from "./../component/Footer";
import {
  addBookingApi,
  addPackageApi,
  addonListByPackageApi,
  bookingOnRequestApi,
  getCityListApi,
  getCustomerDetailsApi,
  getPackageDetailsApi,
  getStatesListApi,
  getTaxDetailsApi,
  getThemesListApi,
  getTripListApi,
  getTypeOfTourPackagesListApi,
} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useNavigate, useParams} from "react-router";
import {Helmet} from "react-helmet";
import {Form, Button, Dropdown, Tabs, Tab, Modal} from "react-bootstrap";
import Calendar from "react-calendar";

import panImg from "../assets/img/pan-image.png";
import EmptyImg from "../assets/img/empty-img.svg";
import CancelChequeImg from "../assets/img/cancel-cheque-img.png";
import AuthoriedLetterImg from "../assets/img/Authoried-letter-img.png";
import deleteImg from "../assets/img/delete-img.svg";
import MinusButton from "../assets/img/minus-button.svg";
import PlusButton from "../assets/img/plus-button.svg";
import {useUserContext} from "../context/UserContext";
import VendorRegisteredSuccessIcon from "../assets/img/Vendor-registered-success-icon.svg";
import VendorRegistrationLogo from "../assets/img/vendor-registration-logo.svg";
const crossIcon2 = require("./../assets/img/cross.svg").default;

const UsersRoomsAndTravellersSelection = () => {
  const navigate = useNavigate();
  const {userData} = useUserContext();
  const {packageId} = useParams();

  const [key, setKey] = useState("tab1");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [addonList, setAddonList] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [childrenWithAge, setChildrenWithAge] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [bookingObjFromSession, setBookingObjFromSession] = useState();
  const [formatSelectedDate, setFormatSelectedDate] = useState();
  // Sample calendar data, replace it with your actual data
  const calendarData1 = [
    {date: new Date(2024, 2, 2), status: "Lowest Price", price: "₹21,000"},
    {date: new Date(2024, 2, 4), status: "Lowest Price", price: "₹11,999"},
    {date: new Date(2024, 2, 5), status: "Lowest Price", price: "₹25,000"},
    {date: new Date(2024, 2, 6), status: "Lowest Price", price: "₹12,999"},
    {date: new Date(2024, 2, 10), status: "Regular Price", price: "₹18,999"},
    {date: new Date(2024, 2, 16), status: "Sold Out", price: "₹16,999"},
    {date: new Date(2024, 2, 22), status: "On Request", price: "₹17,250"},
    {date: new Date(2024, 2, 12), status: "Selling Fast", price: "₹32,999"},
    // Add more dates with different statuses
  ];

  const childrenAgeArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const [calendarData, setCalendarData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isRoomsSelected, setIsRoomsSelected] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [selectedDateFromCal, setSelectedDateFromCal] = useState();
  const [packageDetails, setPackageDetails] = useState();
  const [selectedTourStartDateObj, setSelectedTourStartDateObj] = useState();
  const [onSendRequestPopup, setOnSendRequestPopup] = useState(false);
  const [onSendRequestSuccessPopup, setOnSendRequestSuccessPopup] = useState(false);
  const [dateSelectedByClick, setdateSelectedByClick] = useState(false);
  const [roomPersonError, setRoomPersonError] = useState();
  const [btnDis, setBtnDis] = useState(false);
  const [tourDateRange, setTourDateRange] = useState({startDate: "", endDate: ""});
  const [priceDetails, setPriceDetails] = useState({
    basePrice: 0,
    addOnPrice: 0,
    taxPrice: 0,
    gstTax: 0,
    tcsTax: 0,
    gstTaxIncludePrice: 0,
    tcsTaxIncludePrice: 0,
    bulkDiscountPrice: 0,
    bulkDiscountPercent: 0,
    subTotalPrice: 0,
  });
  const [taxDetails, setTaxDetails] = useState();
  const [customerDetails, setCustomerDetails] = useState({name: "", address: "", email: "", phoneNumber: "", state: "", panNumber: ""});
  const [custDetails, setCustDetails] = useState();
  const [dependencyForEffect, setDependencyForEffect] = useState(false);
  const [bulkDiscAvail, setBulkDiscAvail] = useState(false);

  const getDayStatus = date => {
    const day = calendarData.find(entry => isSameDay(entry.date, date));
    return day ? (day.status ? day.status : "") : null;
  };

  const getDayPrice = date => {
    const day = calendarData.find(entry => isSameDay(entry.date, date));
    return day ? (day.price ? "₹" + day.price : "") : null;
  };

  const handleDateClick = date => {
    setdateSelectedByClick(true);

    const selectedDay = calendarData.find(entry => isSameDay(entry.date, date));

    const dateObj = new Date(date);
    setSelectedDateFromCal(date);
    const isoString = dateObj.toISOString();
    const formattedDate = new Date(isoString).toLocaleDateString("en-US", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    setFormatSelectedDate(formattedDate);
    setSelectedDate(date);
    setSelectedTourStartDateObj(selectedDay);
  };

  const tileContent = ({date}) => {
    const status = getDayStatus(date);
    const price = getDayPrice(date);
    return status === "Lowest Price" || status === "Regular Price" ? (
      <div className="eachDtls">
        <p className="prc">{price}</p>
        <p className="sts">{status}</p>
      </div>
    ) : status === "On Request" ? (
      <div className="eachDtls">
        <p className="prc">On Request</p>
        <p className="sts">Contact Seller</p>
      </div>
    ) : (
      <div className="eachDtls">
        {/* <p className="prc">On Request</p> */}
        {/* <p className="sts">Contact Seller</p> */}
      </div>
    );
  };

  const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  };

  const getStatusForDate = date => {
    const dataForDate = calendarData.find(entry => isSameDay(entry.date, date));

    if (dataForDate) {
      return dataForDate.status.toLowerCase().replace(" ", "-");
    }

    return "";
  };

  const tileClassName = ({date}) => {
    const status = getStatusForDate(date);
    const isCurrentDate =
      date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear();

    // Check if the date is before the present day
    const isPreviousDate = date < new Date();

    // Return a string of classes separated by a space
    return `${status} ${isCurrentDate ? "current-date" : ""} ${isPreviousDate ? "previous-date" : ""} ${
      dateSelectedByClick && selectedDate && date.getDate() === selectedDate.getDate() ? "selected-date" : ""
    }`.trim();
  };

  // Calculate minDate as the current date
  const minDate = new Date();

  // Calculate maxDate as the same month next year
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  const getPackageDetails = async () => {
    try {
      let response = await getPackageDetailsApi(packageId);
      if (response && response.data.res === true) {
        setPackageDetails(response.data.data);

        const getAllDatesInRange = (startDate, endDate) => {
          const dates = [];
          let currentDate = new Date(startDate);

          while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }

          return dates;
        };

        const seatAvailability = response.data.data.seat_availability;
        const seatNotAvailable = response.data.data.seat_unavailable;
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        // const calendarRange = getAllDatesInRange(new Date(currentYear, 0, 1), new Date(currentYear, 11, 31));
        const endDate = new Date(currentYear + 1, currentMonth, new Date(currentYear, currentMonth + 1, 0).getDate());

        // Use the adjusted start and end dates in getAllDatesInRange
        const calendarRange = getAllDatesInRange(new Date(currentYear, currentMonth, 1), endDate);

        const onSeasoncalendarRange = getAllDatesInRange(new Date(response.data.data.onseason_from_date), new Date(response.data.data.onseason_to_date));

        const offSeasoncalendarRange = getAllDatesInRange(new Date(response.data.data.offseason_from_date), new Date(response.data.data.offseason_to_date));

        const onSeasoncalendarRangeFormat = onSeasoncalendarRange.map(date => {
          const dateString = date.toISOString();
          return dateString;
        });

        const offSeasoncalendarRangeFormat = offSeasoncalendarRange.map(date => {
          const dateString = date.toISOString();
          return dateString;
        });

        const packageRatesDates = response.data.data.packageRates.flatMap(rate => {
          const rateDates = getAllDatesInRange(new Date(rate.start_date), new Date(rate.end_date));
          // console.log(rateDates);
          return rateDates.map(date => ({
            date: date.toISOString(),
            price: parseInt(rate.price),
            starting_price: parseInt(rate.starting_price),
            single_occupancy_price: parseInt(rate.single_occupancy_price),
            triple_occupancy_price: parseInt(rate.triple_occupancy_price),
          }));
        });

        const finalCalArray = calendarRange.map(date => {
          const dateString = date.toISOString();
          const dateString1 = date.toISOString().split("T")[0];

          const seatAvailabilityItem = seatAvailability.find(item => {
            const seatDate = new Date(item.date);
            return seatDate.toDateString() === date.toDateString();
          });

          const matchedRate = packageRatesDates.find(rateDate => {
            const seatDate = new Date(rateDate.date);
            return seatDate.toDateString() === date.toDateString();
          });

          // if (seatAvailabilityItem) {
          //   return {
          //     date: date,
          //     status: "Regular Price",
          //     price: parseFloat(seatAvailabilityItem.cost),
          //     seat: parseInt(seatAvailabilityItem.seat),
          //   };
          // } else if (matchedRate) {
          //   return {
          //     date: date,
          //     status: parseInt(response.data.data.starting_price) > parseInt(matchedRate.price) ? "Regular Price" : "Lowest Price",
          //     price: parseInt(matchedRate.price),
          //     seat: response.data.data.total_seat,
          //   };
          // } else {
          //   return {
          //     date: date,
          //     status: onSeasoncalendarRangeFormat.includes(dateString)
          //       ? "Regular Price"
          //       : offSeasoncalendarRangeFormat.includes(dateString)
          //       ? "Lowest Price"
          //       : "",
          //     // : "On Request",
          //     price: onSeasoncalendarRangeFormat.includes(dateString)
          //       ? Math.floor(parseInt(response.data.data.starting_price) + parseInt(response.data.data.onseason_price))
          //       : offSeasoncalendarRangeFormat.includes(dateString)
          //       ? Math.floor(parseInt(response.data.data.starting_price) - response.data.data.offseason_price)
          //       : null,
          //     seat: response.data.data.total_seat,
          //   };
          // }

          const seatNotAvailabilityItem = seatNotAvailable.find(item => {
            const seatDate = new Date(item.date);
            return seatDate.toDateString() === date.toDateString();
          });

          if (seatNotAvailabilityItem) {
            return {
              date: date,
              status: "", // No status is set when seatNotAvailabilityItem exists
            };
          } else if (seatAvailabilityItem) {
            if (matchedRate) {
              const startingPrice = parseInt(response.data.data.starting_price);
              const matchedRatePrice = parseInt(matchedRate.price);
              let finalPrice;
              let status;

              if (matchedRatePrice > 0) {
                finalPrice = startingPrice + matchedRatePrice;
                status = "Regular Price";
              } else {
                finalPrice = startingPrice + matchedRatePrice; // since matchedRatePrice is negative, it will effectively be a subtraction
                status = "Lowest Price";
              }
              return {
                date: date,
                status: status,
                price: parseInt(matchedRate?.starting_price),
                seat: parseInt(seatAvailabilityItem.seat),
                matchedRatePrice: parseInt(matchedRate.price),
                starting_price: parseInt(matchedRate.starting_price),
                single_occupancy_price: parseInt(matchedRate.single_occupancy_price),
                triple_occupancy_price: parseInt(matchedRate.triple_occupancy_price),
              };
              // return {
              //   date: date,
              //   status: parseInt(matchedRate.price) < parseInt(response.data.data.starting_price) ? "Lowest Price" : "Regular Price",
              //   price: parseFloat(matchedRate.price),
              //   seat: parseInt(seatAvailabilityItem.seat), // Update the seat count from seatAvailabilityItem
              // };
            } else {
              return {
                date: date,
                status: "On Request", // Default to empty status when matchedRate does not exist
                price: parseInt(response.data.data.starting_price),
                seat: parseInt(seatAvailabilityItem.seat),
              };
            }
          } else {
            if (matchedRate) {
              const startingPrice = parseInt(response.data.data.starting_price);
              const matchedRatePrice = parseInt(matchedRate.price);
              let finalPrice;
              let status;

              if (matchedRatePrice > 0) {
                finalPrice = startingPrice + matchedRatePrice;
                status = "Regular Price";
              } else {
                finalPrice = startingPrice + matchedRatePrice; // since matchedRatePrice is negative, it will effectively be a subtraction
                status = "Lowest Price";
              }
              return {
                date: date,
                status: status,
                price: parseInt(matchedRate?.starting_price),
                seat: response.data.data.total_seat,
                matchedRatePrice: parseInt(matchedRate.price),
                starting_price: parseInt(matchedRate.starting_price),
                single_occupancy_price: parseInt(matchedRate.single_occupancy_price),
                triple_occupancy_price: parseInt(matchedRate.triple_occupancy_price),
              };
            } else {
              return {
                date: date,
                status: "On Request",
                price: parseInt(response.data.data.starting_price),
                seat: response.data.data.total_seat,
              };
            }
            // const isOnSeason = onSeasoncalendarRangeFormat.includes(dateString);
            // const isOffSeason = offSeasoncalendarRangeFormat.includes(dateString);

            // return {
            //   date: date,
            //   status: isOnSeason ? "Regular Price" : isOffSeason ? "Lowest Price" : "On Request",
            //   price: isOnSeason
            //     ? Math.floor(parseInt(response.data.data.website_price) + parseInt(response.data.data.onseason_price))
            //     : isOffSeason
            //     ? Math.floor(parseInt(response.data.data.website_price) - parseInt(response.data.data.offseason_price))
            //     : null,
            //   seat: response.data.data.total_seat,
            // };
          }
        });

        // const finalCalArray = [...tempCalArray, ...unavailableDatesData];
        setCalendarData(finalCalArray);
      }
    } catch (error) {
      console.error("Error fetching package details:", error);
    }
  };

  const getAddOnsList = async () => {
    try {
      let formData = new FormData();
      formData.append("package_id", packageId);
      // Api call
      let response = await addonListByPackageApi(formData);
      if (response) {
        if (response.data.res === true) {
          setAddonList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getTaxDetails = async () => {
    let WMTBookingObj = JSON.parse(sessionStorage.getItem("WMTBookingObj"));

    let basePrice = 0;
    let totalAdult = 0;
    let totalChildren = 0;

    if (WMTBookingObj?.rooms?.length > 0) {
      WMTBookingObj?.rooms?.map(roomItem => {
        if (roomItem?.adults) {
          totalAdult += roomItem.adults;
        }
        if (roomItem?.children) {
          totalChildren += roomItem.children;
        }
      });
    }

    const calculateTotalPrice = WMTBookingObj => {
      let totalPrice = 0;

      const singleOccupancyCost = parseFloat(WMTBookingObj.single_occupancy_cost);
      const tripleSharingDiscount = parseFloat(WMTBookingObj.triple_sharing_discount);
      const childDiscount = parseFloat(WMTBookingObj.child_discount);
      const infantPrice = parseFloat(WMTBookingObj.infant_price);
      const baseTourPrice = parseFloat(WMTBookingObj.tourPrice);

      WMTBookingObj.rooms.forEach(room => {
        if (room.adults === 1) {
          // Single occupancy
          totalPrice += singleOccupancyCost;
        } else if (room.adults === 3) {
          // Triple sharing
          totalPrice += tripleSharingDiscount * room.adults;
        } else if (room.adults === 2) {
          // Standard double occupancy
          totalPrice += baseTourPrice * room.adults;
        } else {
          // Handle other cases, if any
          totalPrice += baseTourPrice * room.adults;
        }

        // Add cost for children
        room.childrenArray.forEach(child => {
          if (child.age < 3) {
            totalPrice += infantPrice;
          } else {
            totalPrice += childDiscount;
          }
        });
      });

      return totalPrice;
    };

    basePrice = calculateTotalPrice(WMTBookingObj);

    let addOnPrice = 0;
    let taxes = 0;
    let gstTax = 0;
    let tcsTax = 0;
    let taxIncludePrice = 0;
    let gstTaxIncludePrice = 0;
    let tcsTaxIncludePrice = 0;
    let bulkDiscountPrice = 0;
    let bulkDiscountPercent = 0;
    let subTotal = 0;

    try {
      const formData = new FormData();
      formData.append("package_id", packageId);
      let response = await getTaxDetailsApi(formData);
      if (response && response.data.res === true) {
        setTaxDetails(response.data.data);
        gstTax = response.data.data.gst.value ? parseInt(response.data.data.gst.value) : 0;
        tcsTax = response.data.data.tcs.value ? parseInt(response.data.data.tcs.value) : 0;
      }
    } catch (error) {
      console.error("Error fetching package details:", error);
    }

    if (WMTBookingObj?.selectedAddons?.length > 0) {
      WMTBookingObj?.selectedAddons?.map(item => {
        addOnPrice += parseFloat(item?.price);
      });
    }

    // Calculate addon for per person
    addOnPrice = addOnPrice * (totalAdult + totalChildren);

    // Sort bulk discounts array in descending order based on min_pax
    WMTBookingObj.bulk_discounts.sort((a, b) => b.min_pax - a.min_pax);

    const matchedBulkDiscount = WMTBookingObj?.bulk_discounts.find(discount => parseInt(totalAdult) + parseInt(totalChildren) >= parseInt(discount.min_pax));

    if (matchedBulkDiscount) {
      setBulkDiscAvail(true);
      bulkDiscountPercent = parseInt(matchedBulkDiscount?.discount);
      bulkDiscountPrice = (basePrice * parseInt(matchedBulkDiscount?.discount)) / 100;
      basePrice = basePrice - bulkDiscountPrice;
    }

    // if (parseInt(totalAdult) + parseInt(totalChildren) >= parseInt(WMTBookingObj?.bulk_no_of_pax)) {
    //   setBulkDiscAvail(true);
    //   bulkDiscountPrice = (basePrice * parseInt(WMTBookingObj?.pax_discount_percent)) / 100;
    //   basePrice = basePrice - bulkDiscountPrice;
    // }

    // GST and tcs calculation
    taxes = gstTax + tcsTax;
    let basepriceWithAddon = basePrice + parseFloat(addOnPrice);

    if (taxes > 0) {
      taxIncludePrice = basepriceWithAddon + (basepriceWithAddon * taxes) / 100;
    }
    if (gstTax > 0) {
      gstTaxIncludePrice = (basepriceWithAddon * gstTax) / 100;
    }
    if (tcsTax > 0) {
      tcsTaxIncludePrice = (basepriceWithAddon * tcsTax) / 100;
    }

    subTotal = parseFloat(taxes ? taxIncludePrice : basepriceWithAddon);
    // subTotal = Math.round(taxIncludePrice) + Math.round(addOnPrice);

    console.log(basePrice, addOnPrice, taxes, gstTaxIncludePrice, subTotal);

    setPriceDetails(prev => ({
      ...prev,
      basePrice: basePrice,
      addOnPrice: addOnPrice,
      taxPrice: taxes,
      gstTax: gstTax,
      tcsTax: tcsTax,
      gstTaxIncludePrice: parseFloat(gstTaxIncludePrice).toFixed(2),
      tcsTaxIncludePrice: parseFloat(tcsTaxIncludePrice).toFixed(2),
      bulkDiscountPrice: parseFloat(bulkDiscountPrice).toFixed(2),
      bulkDiscountPercent: bulkDiscountPercent,
      subTotalPrice: subTotal.toFixed(2),
    }));

    return {basePrice, addOnPrice, gstTax, gstTaxIncludePrice: parseFloat(gstTaxIncludePrice).toFixed(2), subTotalPrice: subTotal.toFixed(2)};
  };

  const addRoom = () => {
    setRooms([...rooms, {adults: 0, children: 0, childrenArray: []}]);
    setChildrenWithAge(prev => [...prev, []]);
  };

  const handleAdultsChange = (index, value) => {
    const updatedRooms = [...rooms];
    const newAdultsCount = updatedRooms[index].adults + value;
    if (newAdultsCount <= 3) {
      updatedRooms[index].adults = Math.max(0, newAdultsCount);
      setRooms(updatedRooms);
    }
  };

  const handleChildrenChange = (index, value) => {
    const updatedRooms = [...rooms];
    const newChildrenCount = updatedRooms[index].children + value;
    if (newChildrenCount <= 3) {
      if (value === 1) {
        updatedRooms[index].children = Math.max(0, newChildrenCount);
        updatedRooms[index].childrenArray.push({age: 0}); // Push to childrenArray property
      } else {
        if (value === -1) {
          updatedRooms[index].children = Math.max(0, newChildrenCount);
          updatedRooms[index].childrenArray.pop();
        }
      }
      setRooms(updatedRooms);
    }
  };

  const handleAgeChange = (roomIndex, childIndex, event) => {
    const {value} = event.target;
    const updatedRooms = [...rooms];
    updatedRooms[roomIndex].childrenArray[childIndex].age = parseInt(value);
    setRooms(updatedRooms);
  };

  const deleteRoom = index => {
    const updatedRooms = rooms.filter((room, i) => i !== index);
    setRooms(updatedRooms);
  };

  const handleCheckboxChange = addonId => {
    if (selectedAddons.includes(addonId)) {
      setSelectedAddons(selectedAddons.filter(id => id !== addonId));
    } else {
      setSelectedAddons([...selectedAddons, addonId]);
    }
  };

  const isAtLeastOnePersonAdded = () => {
    for (const room of rooms) {
      if (room.adults > 0 || room.children > 0) {
        return true;
      }
    }
    return false;
  };

  const handleProceed = async () => {
    let roomIndexWithInsufficientCapacity = -1;
    let childrenUnder10 = 0;
    let childrenAbove10 = 0;
    let ageError = false;
    // console.log(childrenAgeArray);
    // childrenWithAge.forEach(child => {
    //   if (child.age === 0) {
    //     ageError = true;
    //     return;
    //   }
    //   if (child.age < 10) {
    //     childrenUnder10++;
    //   } else {
    //     childrenAbove10++;
    //   }
    // });

    // rooms.map(item => {
    //   item.childrenArray.map(childItem => {
    //     if (childItem.age <= 0) {
    //       ageError = true;
    //     }
    //     if (childItem.age < 10) {
    //       childrenUnder10++;
    //     } else {
    //       childrenAbove10++;
    //     }
    //   });
    // });

    // for (const room in rooms) {
    //   console.log(room)
    //   for (const child in parseInt(room).childrenArray) {
    //     console.log(child)
    //     if (child.age <= 0) {
    //       ageError = true;
    //     }
    //   }
    // }

    // rooms.forEach((room, index) => {
    //   if (room.adults === 3 && room.children > 0) {
    //     roomIndexWithInsufficientCapacity = index;
    //   } else if (room.adults === 2 && room.children > 2) {
    //     roomIndexWithInsufficientCapacity = index;
    //   } else if (room.adults === 2 && childrenAbove10 > 1) {
    //     roomIndexWithInsufficientCapacity = index;
    //   } else if (room.adults === 2 && childrenUnder10 > 2) {
    //     roomIndexWithInsufficientCapacity = index;
    //   }
    // });

    rooms.forEach((room, index) => {
      // Reset child count for each room
      let roomChildrenUnder10 = 0;
      let roomChildrenAbove10 = 0;

      room.childrenArray.forEach(child => {
        if (child.age <= 0) {
          ageError = true;
        }
        if (child.age < 10) {
          roomChildrenUnder10++;
        } else {
          roomChildrenAbove10++;
        }
      });

      childrenUnder10 += roomChildrenUnder10;
      childrenAbove10 += roomChildrenAbove10;

      if (room.adults === 3 && room.children > 0) {
        roomIndexWithInsufficientCapacity = index;
        setRoomPersonError(null);
      } else if (room.adults === 2 && room.children > 2) {
        roomIndexWithInsufficientCapacity = index;
        setRoomPersonError(null);
      } else if (room.adults === 2 && roomChildrenAbove10 > 1) {
        roomIndexWithInsufficientCapacity = index;
        setRoomPersonError("Room capacity exceeded: Maximum of 1 child over 10 years allowed with 2 adults.");
      } else if (room.adults === 2 && roomChildrenUnder10 > 2) {
        roomIndexWithInsufficientCapacity = index;
        setRoomPersonError("Room capacity exceeded: Maximum of 2 children under 10 years allowed with 2 adults.");
      }
    });

    if (rooms.length === 0) {
      NotificationManager.warning("Please add atleast one room.");
    } else if (!isAtLeastOnePersonAdded()) {
      NotificationManager.warning("Please add atleast one person.");
    } else if (ageError) {
      NotificationManager.warning("Please select ages for all children.");
    } else if (roomIndexWithInsufficientCapacity !== -1) {
      NotificationManager.warning("Please add another room.");
    } else if (!isDateSelected && !selectedTourStartDateObj) {
      NotificationManager.warning("Please select tour start date from calender.");
    } else if (!isDateSelected && !selectedTourStartDateObj?.status) {
      NotificationManager.warning("Please select tour start date from calender.");
    } else {
      let selectedAddonArray = [];
      if (selectedAddons?.length > 0) {
        if (packageDetails?.addons?.length > 0) {
          packageDetails?.addons?.map(item => {
            if (selectedAddons.includes(item.id)) {
              selectedAddonArray.push(item);
            }
          });
        }
      }
      // if (!isDateSelected && selectedTourStartDateObj?.status === "On Request") {
      //   alert("On request not done yet.");
      //   // setOnSendRequestPopup(true);
      // } else if (isDateSelected && bookingObjFromSession?.status === "On Request") {
      //   alert("On request not done yet.");
      //   // setOnSendRequestPopup(true);
      // } else {
      let tempObj = {...bookingObjFromSession};
      // if (tempObj?.status === "On Request") {
      if (isDateSelected) {
        if (tempObj?.status === "On Request") {
          tempObj.rooms = rooms;
          tempObj.selectedAddons = selectedAddonArray;
          sessionStorage.setItem("WMTBookingObj", JSON.stringify(tempObj));
          // setIsRoomsSelected(true);
          if (!userData) {
            navigate(`/login`, {state: {from: `/package/${packageId}/rooms-selection`}});
          } else {
            // setIsRoomsSelected(true);
            setOnSendRequestPopup(true);
          }
          window.scrollTo({top: 0, behavior: "smooth"});
        } else {
          tempObj.rooms = rooms;
          tempObj.selectedAddons = selectedAddonArray;
          sessionStorage.setItem("WMTBookingObj", JSON.stringify(tempObj));
          // setIsRoomsSelected(true);
          window.scrollTo({top: 0, behavior: "smooth"});
          navigate(`/package/${packageId}/review`);
        }
      } else {
        if (selectedTourStartDateObj?.status === "On Request") {
          setDependencyForEffect(!dependencyForEffect);
          tempObj.rooms = rooms;
          tempObj.selectedAddons = selectedAddonArray;
          tempObj.selectedTourStartDate = selectedDateFromCal;
          tempObj.status = selectedTourStartDateObj?.status;
          if (selectedTourStartDateObj?.status !== "On Request") {
            tempObj.tourPrice = selectedTourStartDateObj.price;
            tempObj.single_occupancy_cost = selectedTourStartDateObj?.single_occupancy_price;
            tempObj.triple_sharing_discount = selectedTourStartDateObj?.triple_occupancy_price;
          } else {
            tempObj.tourPrice = selectedTourStartDateObj.price;
            tempObj.single_occupancy_cost = selectedTourStartDateObj?.single_occupancy_price;
            tempObj.triple_sharing_discount = selectedTourStartDateObj?.triple_occupancy_price;
          }
          sessionStorage.setItem("WMTBookingObj", JSON.stringify(tempObj));
          if (!userData) {
            navigate(`/login`, {state: {from: `/package/${packageId}/rooms-selection`}});
          } else {
            // setIsRoomsSelected(true);
            setOnSendRequestPopup(true);
          }
          window.scrollTo({top: 0, behavior: "smooth"});
        } else {
          tempObj.rooms = rooms;
          tempObj.selectedAddons = selectedAddonArray;
          tempObj.selectedTourStartDate = selectedDateFromCal;
          if (selectedTourStartDateObj?.status !== "On Request") {
            tempObj.tourPrice = selectedTourStartDateObj.price;
            tempObj.single_occupancy_cost = selectedTourStartDateObj?.single_occupancy_price;
            tempObj.triple_sharing_discount = selectedTourStartDateObj?.triple_occupancy_price;
          }
          sessionStorage.setItem("WMTBookingObj", JSON.stringify(tempObj));
          // setIsRoomsSelected(true);
          window.scrollTo({top: 0, behavior: "smooth"});
          navigate(`/package/${packageId}/review`);
        }
      }
      // } else {
      //   if (isDateSelected) {
      //     tempObj.rooms = rooms;
      //     tempObj.selectedAddons = selectedAddonArray;
      //     sessionStorage.setItem("WMTBookingObj", JSON.stringify(tempObj));
      //     // setIsRoomsSelected(true);
      //     window.scrollTo({top: 0, behavior: "smooth"});
      //     navigate(`/package/${packageId}/review`);
      //   } else {
      //     tempObj.rooms = rooms;
      //     tempObj.selectedAddons = selectedAddonArray;
      //     tempObj.selectedTourStartDate = selectedDateFromCal;
      //     if (selectedTourStartDateObj?.status !== "On Request") {
      //       tempObj.tourPrice = selectedTourStartDateObj.price;
      //     }
      //     sessionStorage.setItem("WMTBookingObj", JSON.stringify(tempObj));
      //     // setIsRoomsSelected(true);
      //     window.scrollTo({top: 0, behavior: "smooth"});
      //     navigate(`/package/${packageId}/review`);
      //   }
      // }
      // }
    }
  };

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustDetails(response.data);
          if (response.data.data.id_type === "pancard") {
            setCustomerDetails({
              name: response.data.data.first_name + " " + response.data.data.last_name,
              address: response.data.data.address,
              email: response.data.User.email,
              phoneNumber: response.data.User.mobile,
              state: response.data.data.state,
              panNumber: response.data.data.id_number,
            });
          } else {
            setCustomerDetails({
              name: response.data.data.first_name + " " + response.data.data.last_name,
              address: response.data.data.address,
              email: response.data.User.email,
              phoneNumber: response.data.User.mobile,
              state: response.data.data.state,
              panNumber: "",
            });
          }
        } else {
        }
      }
    } catch (error) {}
  };

  const handleSendRequest = async () => {
    setBtnDis(true);
    try {
      let allCalDetails = await getTaxDetails();
      console.log(allCalDetails);
      const formData = new FormData();

      let addOnIds = "";
      if (bookingObjFromSession?.selectedAddons?.length > 0) {
        bookingObjFromSession?.selectedAddons?.map(item => {
          addOnIds = bookingObjFromSession?.selectedAddons?.map(pkg => pkg.id).join(",");
        });
      }
      if (addOnIds) {
        formData.append("add_on_id", addOnIds);
      }
      formData.append("status", "0"); // 1 for in-process status
      formData.append("package_id", packageId);
      formData.append("price", bookingObjFromSession?.tourPrice);
      formData.append("rooms", JSON.stringify(bookingObjFromSession?.rooms));

      const startDate = new Date(tourDateRange.startDate);
      const endDate = new Date(tourDateRange.endDate);

      // // Function to format date into "yy-mm-dd" format
      const formatDate = date => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      endDate.setDate(endDate.getDate() + 1);

      // // Array to store formatted dates
      const formattedDates = [];

      // // Push each date into the array in "yy-mm-dd" format
      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        formattedDates.push(formatDate(d));
      }
      formData.append("start_date", formatDate(new Date(startDate)));
      formData.append("end_date", formatDate(new Date(endDate)));

      let tempBasePrice;
      tempBasePrice = parseFloat(allCalDetails?.subTotalPrice) - parseFloat(allCalDetails?.gstTaxIncludePrice);

      formData.append("base_amount", tempBasePrice.toFixed(2));

      if (allCalDetails?.addOnPrice) {
        formData.append("addon_total_price", parseFloat(allCalDetails?.addOnPrice).toFixed(2));
      }

      if (allCalDetails?.gstTaxIncludePrice) {
        formData.append("gst_price", parseFloat(allCalDetails?.gstTaxIncludePrice).toFixed(2));
      }

      if (allCalDetails?.gstTax) {
        formData.append("gst_percent", allCalDetails?.gstTax);
      }

      if (allCalDetails?.tcsTaxIncludePrice) {
        formData.append("tcs", parseFloat(allCalDetails?.tcsTaxIncludePrice).toFixed(2));
      }

      formData.append("final_price", allCalDetails?.subTotalPrice);

      // formattedDates.map((item, index) => {
      //   formData.append(`seats[${index}][booking_date]`, item);
      //   formData.append(`seats[${index}][cost]`, "");
      // });

      // formData.append("customer_name", customerDetails?.name);
      // formData.append("customer_address", customerDetails?.address);
      // formData.append("customer_email", customerDetails?.email);
      // formData.append("customer_phone_number", customerDetails?.phoneNumber);
      // formData.append("customer_state_id", customerDetails?.state);
      // formData.append("customer_pan_number", customerDetails?.panNumber);
      // formData.append("customer_booking_for", 1);
      // formData.append("booking_status", 6);

      for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }
      // return

      let response = await bookingOnRequestApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          // navigate("/package/booked", {state: {pdfUrl: response.data.data}});
          setOnSendRequestPopup(false);
          setOnSendRequestSuccessPopup(true);
          sessionStorage.removeItem("WMTBookingObj");
          setTimeout(() => {
            setOnSendRequestSuccessPopup(false);
            navigate("/quotations", {state: {from: "onRequest"}});
          }, 3000);
        } else {
          NotificationManager.error(response.data.msg);
        }
        setBtnDis(false);
      }
    } catch (error) {
      setBtnDis(false);
    }
    // setTimeout(() => {
    //   setOnSendRequestSuccessPopup(false);
    //   navigate("/");
    //   sessionStorage.removeItem("WMTBookingObj");
    // }, 3000);
  };

  useEffect(() => {
    if (packageId) {
      getAddOnsList();
      getPackageDetails();
      getCustomerDetails();
      let WMTBookingObj = JSON.parse(sessionStorage.getItem("WMTBookingObj"));
      let startDate;
      let endDate;

      if (WMTBookingObj?.selectedTourStartDate && WMTBookingObj?.stay_plan?.length > 0) {
        const dates = [];
        let currentDate = new Date(WMTBookingObj?.selectedTourStartDate);
        let totalTourDays = 0; // Initialize total tour days

        const stayPlanFormatDateFunc = dateString => {
          const date = new Date(dateString);

          // Get day of the week
          const optionsDayOfWeek = {weekday: "short"};
          const dayOfWeek = new Intl.DateTimeFormat("en-US", optionsDayOfWeek).format(date).toLowerCase();

          // Get day of the month
          const day = date.getDate();

          // Get month
          const optionsMonth = {month: "short"};
          const month = new Intl.DateTimeFormat("en-US", optionsMonth).format(date).toLowerCase();
          return `${dayOfWeek}-${day}-${month}`;
        };

        WMTBookingObj?.stay_plan.forEach(city => {
          const cityStartDate = new Date(currentDate);
          const cityEndDate = new Date(currentDate);

          // Add the number of days for the city's stay plan
          cityEndDate.setDate(cityEndDate.getDate() + city.total_nights - 1); // Subtract 1 to include start date in the count
          stayPlanFormatDateFunc(cityStartDate.toISOString());
          // Push the start and end dates for the city
          dates.push({
            city_name: city.city_name,
            hotel_name: city.hotel_name,
            total_days: city.total_nights,
            start_date: cityStartDate.toISOString(),
            start_date_formated: stayPlanFormatDateFunc(cityStartDate.toISOString()),
            end_date: cityEndDate.toISOString(),
            end_date_formated: stayPlanFormatDateFunc(cityEndDate.toISOString()),
          });

          // Move to the next city's start date
          currentDate.setDate(cityEndDate.getDate() + 1); // Move to the next day after the end date

          // Update total tour days
          totalTourDays += city.total_nights;
        });

        // Calculate tour end date based on total tour days
        const tourEndDate = new Date(WMTBookingObj?.selectedTourStartDate);
        tourEndDate.setDate(tourEndDate.getDate() + totalTourDays - 1); // Subtract 1 to include start date in the count

        startDate = new Date(WMTBookingObj?.selectedTourStartDate);
        endDate = tourEndDate;
        setTourDateRange({startDate: startDate, endDate: endDate});
      }

      setBookingObjFromSession(WMTBookingObj);
      if (WMTBookingObj?.rooms) {
        setRooms(WMTBookingObj?.rooms);
      } else {
        setRooms([{adults: 0, children: 0, childrenArray: []}]);
      }

      if (WMTBookingObj?.selectedAddons) {
        let tempSelectedAddonArray = [];
        if (WMTBookingObj?.selectedAddons?.length > 0) {
          WMTBookingObj?.selectedAddons?.map(item => {
            tempSelectedAddonArray.push(item.id);
          });
        }
        setSelectedAddons(tempSelectedAddonArray);
      } else {
        setSelectedAddons([]);
      }

      if (WMTBookingObj?.selectedTourStartDate) {
        setIsDateSelected(true);
        if (!WMTBookingObj?.selectedTourStartDate || parseInt(packageId) !== parseInt(WMTBookingObj.packageId)) {
          navigate("/");
        } else {
          const dateString = WMTBookingObj.selectedTourStartDate;
          const date = new Date(dateString);
          const date1 = new Date(WMTBookingObj?.selectedTourStartDate);

          // Set the selected date directly, without formatting
          setSelectedDate(date1);

          const formattedDate = date.toLocaleDateString("en-US", {
            weekday: "short",
            day: "2-digit",
            month: "short",
            year: "numeric",
          });
          setFormatSelectedDate(formattedDate);
        }
      } else {
        const currentDate = new Date();

        // Format current date in the desired format
        const formattedCurrentDate = currentDate.toISOString();
        const formattedDate = new Date(formattedCurrentDate).toLocaleDateString("en-US", {
          weekday: "short",
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
        // setFormatSelectedDate(formattedDate);
        setIsDateSelected(false);
      }

      let serachValues = JSON.parse(sessionStorage.getItem("wishmytourSearchValues"));
      if (serachValues?.month) {
        const [month, year] = serachValues.month.split(" ");
        // Create a Date object for June 1st, 2024
        const selectedDate = new Date(year, monthIndex(month), 1);

        // Function to convert month name to its index
        function monthIndex(monthName) {
          return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].indexOf(monthName);
        }
        setSelectedDate(selectedDate);
      } else {
        setSelectedDate(new Date());
      }
    } else {
      navigate("/");
    }
  }, [dependencyForEffect]);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Booking - Room selection`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Booking list page" />
      </Helmet>
      <Header />
      <div className="clearfix"></div>
      <div className="innerDiv">
        <div className="UserAfterLogin">
          {/* {!isRoomsSelected ? ( */}
          {isDateSelected ? (
            <div className="mainDiv">
              <h3>Select Rooms and Travellers</h3>
              <span className="SubHeaderText">Departure Date Selected - {formatSelectedDate ? formatSelectedDate : "Date not selected yet."}</span>
              <div className="UserWhiteBox">
                <div className="InnerBox">
                  <ul className="row InnerBoxList">
                    <li className="col-md-6">
                      <div className="BorderBox">
                        <h3>Rooms & Travellers:</h3>
                        <h4>Maximum 3 guests are allowed per room.</h4>
                        <ul className="RoomsList">
                          {rooms.map((room, index) => (
                            <li key={index}>
                              <ul className="SubRoomsList">
                                <li>
                                  <h4>Room {index + 1}</h4>
                                  <a onClick={() => deleteRoom(index)}>
                                    <img src={deleteImg} alt="" />
                                  </a>
                                </li>
                                <li>
                                  <label>Adults</label>
                                  <div className="NumBox">
                                    <button className="MinusButton" onClick={() => handleAdultsChange(index, -1)}>
                                      <img src={MinusButton} alt="" />
                                    </button>
                                    <span className="NumSpan">{room.adults}</span>
                                    <button className="PlusButton" onClick={() => handleAdultsChange(index, 1)}>
                                      <img src={PlusButton} alt="" />
                                    </button>
                                  </div>
                                  <span className="noteSpan">(12+ Years)</span>
                                </li>
                                <li>
                                  <label>Children</label>
                                  <div className="NumBox">
                                    <button className="MinusButton" onClick={() => handleChildrenChange(index, -1)}>
                                      <img src={MinusButton} alt="" />
                                    </button>
                                    <span className="NumSpan">{room.children}</span>
                                    <button className="PlusButton" onClick={() => handleChildrenChange(index, 1)}>
                                      <img src={PlusButton} alt="" />
                                    </button>
                                  </div>
                                  <span className="noteSpan">(Below 12 Years)</span>
                                </li>
                              </ul>
                              {Array.from({length: room.children}, (_, childIndex) => (
                                <ul className="SubRoomsList AgeSelection" key={childIndex}>
                                  <li>
                                    <h4>{`Age of children ${childIndex + 1}`}</h4>
                                  </li>
                                  <li>
                                    <span className="NumSpan">
                                      <div className="SelectWrapper">
                                        <select
                                          className="SelectBox"
                                          value={rooms[index].childrenArray[childIndex].age}
                                          onChange={event => handleAgeChange(index, childIndex, event)}>
                                          <option selected hidden>
                                            Select Age
                                          </option>
                                          {childrenAgeArray?.map(item => (
                                            <option value={item} key={item}>
                                              {item}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </span>
                                  </li>
                                </ul>
                              ))}
                              {roomPersonError ? (
                                <ul className="SubRoomsList AgeSelection">
                                  <li className="validationErrorMsg">{roomPersonError} </li>
                                </ul>
                              ) : null}
                            </li>
                          ))}
                        </ul>
                        <p className="text-center">
                          <button className="AddAnotherRoomButton" onClick={addRoom}>
                            Add another room
                          </button>
                        </p>
                      </div>
                    </li>
                    {addonList?.length > 0 ? (
                      <li className="col-md-6">
                        <div className="BorderBox">
                          <h3>Add On:</h3>
                          <ul className="AddOnList">
                            {addonList?.map((item, index) => (
                              <li key={index}>
                                <div className="SubBorderBox">
                                  <div className="InputBox">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input form-input-check"
                                        type="checkbox"
                                        value={item.id}
                                        checked={selectedAddons.includes(item.id)}
                                        onChange={() => handleCheckboxChange(item.id)}
                                      />
                                    </div>
                                  </div>
                                  <div className="TextBox">
                                    <h3>{item?.title}</h3>
                                    <p>{item?.description}</p>
                                    <h4>₹{item?.price}</h4>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    ) : null}
                  </ul>
                </div>
                <div className="InnerBox LastInnerBox">
                  <button className="btn btn-primary CmnBtn" onClick={() => handleProceed()}>
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="mainDiv">
              <h3>Check Price & Availability</h3>
              {/* <h3>Check Price & Availability from New Delhi</h3> */}
              <span className="SubHeaderText">Departure Date Selected - {formatSelectedDate ? formatSelectedDate : "Date not selected yet."}</span>
              <div className="UserWhiteBox">
                <div className="InnerBox">
                  <ul className="row InnerBoxList">
                    <li className="col-md-6">
                      <div className="BorderBox">
                        <h3>Rooms & Travellers:</h3>
                        <h4>Maximum 3 guests are allowed per room.</h4>
                        <ul className="RoomsList">
                          {rooms.map((room, index) => (
                            <li key={index}>
                              <ul className="SubRoomsList">
                                <li>
                                  <h4>Room {index + 1}</h4>
                                  <a onClick={() => deleteRoom(index)}>
                                    <img src={deleteImg} alt="" />
                                  </a>
                                </li>
                                <li>
                                  <label>Adults</label>
                                  <div className="NumBox">
                                    <button className="MinusButton" onClick={() => handleAdultsChange(index, -1)}>
                                      <img src={MinusButton} alt="" />
                                    </button>
                                    <span className="NumSpan">{room.adults}</span>
                                    <button className="PlusButton" onClick={() => handleAdultsChange(index, 1)}>
                                      <img src={PlusButton} alt="" />
                                    </button>
                                  </div>
                                  <span className="noteSpan">(12+ Years)</span>
                                </li>
                                <li>
                                  <label>Children</label>
                                  <div className="NumBox">
                                    <button className="MinusButton" onClick={() => handleChildrenChange(index, -1)}>
                                      <img src={MinusButton} alt="" />
                                    </button>
                                    <span className="NumSpan">{room.children}</span>
                                    <button className="PlusButton" onClick={() => handleChildrenChange(index, 1)}>
                                      <img src={PlusButton} alt="" />
                                    </button>
                                  </div>
                                  <span className="noteSpan">(Below 12 Years)</span>
                                </li>
                              </ul>
                              {Array.from({length: room.children}, (_, childIndex) => (
                                <ul className="SubRoomsList AgeSelection" key={childIndex}>
                                  <li>
                                    <h4>{`Age of children ${childIndex + 1}`}</h4>
                                  </li>
                                  <li>
                                    <span className="NumSpan">
                                      <div className="SelectWrapper">
                                        <select className="SelectBox" onChange={event => handleAgeChange(index, childIndex, event)}>
                                          <option selected hidden>
                                            Select Age
                                          </option>
                                          {childrenAgeArray?.map(item => (
                                            <option value={item} key={item}>
                                              {item}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </span>
                                  </li>
                                </ul>
                              ))}
                              {roomPersonError ? (
                                <ul className="SubRoomsList AgeSelection">
                                  <li className="validationErrorMsg">{roomPersonError} </li>
                                </ul>
                              ) : null}
                            </li>
                          ))}
                        </ul>
                        <p className="text-center">
                          <button className="AddAnotherRoomButton" onClick={addRoom}>
                            Add another room
                          </button>
                        </p>
                      </div>
                    </li>
                    <li className="col-md-6">
                      <div className="BorderBox">
                        <ul className="row bookingOptionList">
                          <li className="LowestPriceList">
                            <span></span> Lowest Price
                          </li>
                          <li className="RegularPriceList">
                            <span></span> Regular Price
                          </li>
                          <li className="SoldOutList">
                            <span></span> Sold Out
                          </li>
                          <li className="OnRequestList">
                            <span></span> On Request
                          </li>
                          <li className="SellingFastList">
                            <span></span> Selling Fast
                          </li>
                        </ul>
                        <div className="calendarSec halfCalender">
                          {/* <Calendar tileClassName={tileClassName} tileContent={tileContent} onClickDay={handleDateClick} /> */}
                          <Calendar
                            tileClassName={tileClassName}
                            tileContent={tileContent}
                            onClickDay={handleDateClick}
                            value={selectedDate}
                            // value={selectedDate || new Date()}
                            minDate={minDate}
                            maxDate={maxDate}
                          />
                          {/* {selectedDate && (
                          <div>
                            <p>Date: {selectedDate.toDateString()}</p>
                            <p>Status: {getDayStatus(selectedDate)}</p>
                          </div>
                        )} */}
                        </div>
                      </div>
                    </li>
                  </ul>

                  {addonList?.length > 0 ? (
                    <div className="AddBottomSec">
                      <h4>Add On:</h4>
                      <ul className="row AddOnList">
                        {addonList?.map((item, index) => (
                          <li className="col-md-4" key={index}>
                            <div className="SubBorderBox">
                              <div className="InputBox">
                                <div className="form-check">
                                  <input
                                    className="form-check-input form-input-check"
                                    type="checkbox"
                                    value={item.id}
                                    checked={selectedAddons.includes(item.id)}
                                    onChange={() => handleCheckboxChange(item.id)}
                                  />
                                </div>
                              </div>
                              <div className="TextBox">
                                <h3>{item?.title}</h3>
                                <p>{item?.description}</p>
                                <h4>₹{item?.price}</h4>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </div>
                <div className="InnerBox LastInnerBox">
                  {/* <button className="btn btn-primary CmnBtn" onClick={() => navigate(`/package/${packageId}/review`)}> */}
                  <button className="btn btn-primary CmnBtn" onClick={() => handleProceed()}>
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          )}
          <Footer />
        </div>
      </div>

      <Modal show={onSendRequestPopup} onHide={() => setOnSendRequestPopup(false)} className="CmnModal SendRequestModal">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>Send Request</h2>
          <p>Are you sure you want to send custom date request to seller?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary CmnBtn" onClick={() => handleSendRequest()}>
            Send
          </button>
          <button className="btn btn-primary CmnBlackBtn" onClick={() => setOnSendRequestPopup(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={onSendRequestSuccessPopup} className="CmnModal SendRequestModal">
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="congratsClass">
            <img src={VendorRegisteredSuccessIcon} alt="" className="SuccessIconImg" />
            <h2>Congratulations!</h2>
            <p>Your request is successfully sent to the tour operator. You will be notified upon confirmation.</p>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default UsersRoomsAndTravellersSelection;
