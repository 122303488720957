import React, {useState, useEffect} from "react";
import Auth from "./ProtectedRoutes/Auth";
import NotLogInAuth from "./ProtectedRoutes/NotLogInAuth";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {Route, Routes, Navigate} from "react-router-dom";
import {NotificationContainer} from "react-notifications";
import Home from "./pages/home";
import CompareTourListing from "./pages/compare-tour-listing";
import Compare from "./pages/compare";
import Login from "./pages/login";
import Otp from "./pages/otp-verification";
import CustomTourPackage from "./pages/custom-tour-package";
import UpcomingTour from "./pages/my-accounts-my-trips-upcoming-tour";
import CompletedTour from "./pages/my-accounts-my-trips-completed-tour";
import CancelledTour from "./pages/my-accounts-my-trips-cancelled-tour";
import Quotation from "./pages/my-account-quotation";
import PersonalInformation from "./pages/my-account-personal-information";
import LoginDetails from "./pages/my-account-login-details";
import MyRewards from "./pages/my-account-my-rewards";
import Notifications from "./pages/my-account-notifications";
import Membership from "./pages/my-account-membership";
import CompareTourListingSpecial from "./pages/compare-tour-listing-special";
import TourDetails from "./pages/tour-details";
import VendorLoginEmail from "./vender/vendor-login-email";
import VendorEmailOtpVerification from "./vender/vendor-email-otp-verification";
import VendorLoginPhone from "./vender/vendor-login-phone";
import VendorPhoneOtpVerification from "./vender/vendor-phone-otp-verification";
import Registration from "./pages/registration";
import AddTourPackage from "./vender/add-tour-package";
import EditTourPackage from "./vender/edit-tour-package";
import VendorPackageList from "./vender/vendor-package-list";
import VendorAccountSetting from "./vender/vendor-account-setting";
import VendorRegistrationGstProvided from "./vender/vendor-registration-gst-provided";
import VendorThankYouPage from "./vender/vendor-thank-you-page";
import UsersRoomsAndTravellersSelection from "./pages/users-rooms-and-travellers-selection";
import UsersAvailibility from "./pages/users-availibility";
import VendorEditProfile from "./vender/vendor-edit-profile";
import ReviewFlights from "./pages/review-flights";
import Travellers from "./pages/travellers";
import PackageBooked from "./pages/package-booked";
import "react-notifications/lib/notifications.css";
import DuplicateTourPackage from "./vender/duplicate-tour-package";
import VendorNotificationList from "./vender/vendor-notification-list";
import VendorBookingList from "./vender/vendor-booking-list";
import VendorBookingDetails from "./vender/vendor-booking-details";
import AgentLoginPhone from "./agent/agent-login-phone";
import AgentPhoneOtpVerification from "./agent/agent-phone-otp-verification";
import AgentLoginEmail from "./agent/agent-login-email";
import AgentEmailOtpVerification from "./agent/agent-email-otp-verification";
import AgentRegistration from "./agent/agent-registration";
import AgentThankYou from "./agent/agent-thank-you";
import AgentAccountSetting from "./agent/agent-account-setting";
import AgentBookingList from "./agent/agent-booking-list";
import AgentBookingDetails from "./agent/agent-booking-details";
import AgentNotificationList from "./agent/agent-notification-list";
import UpcomingTourDetails from "./pages/my-accounts-my-trips-upcoming-tour-details";
import BookingPartialCancellation from "./pages/booking-partial-cancellation";
import PaymentHistory from "./pages/payment-history";
import MyWishList from "./pages/my-accounts-my-wishlist";
import VendorTourPreview from "./vender/vendor-tour-preview";
import BookingCancellation from "./pages/booking-cancellation";
import VendorPackageMessage from "./vender/package-messages";
import QuotationList from "./pages/QuotationList";
import VendorReports from "./vender/vendor-reports";
import VendorReportsInventory2 from "./vender/vendor-reports-inventory2";
import QuotationDetailsSend from "./pages/QuotationDetailsSend";
import OnRequestQuoteDetails from "./pages/on-request-quote-details";
import VendorOnRequestQuoteDetails from "./vender/vendor-on-request-quote-details";
import VendorCustomTourList from "./vender/vendor-custom-tour-list";
import PreviewEditTourPackage from "./vender/preview-edit-tour-package";
import PrivacyPolicy from "./pages/privacy-policy";
import Support from "./pages/support";
import ContactUs from "./pages/contact-us";
import Offers from "./pages/offers";
import BlogNews from "./pages/blog";
import PopularDestination from "./pages/popular-destination";
import BlogDetails from "./pages/blog-details";
import TermsAndUse from "./pages/terms-and-use";
import CancellationPolicy from "./pages/cancellation-policy";
import UserAgreement from "./pages/user-agreement";
import AgentCommisionList from "./agent/agent-commision-list";
import AgentUpcomingTourDetails from "./agent/agent-my-accounts-my-trips-upcoming-tour-details";
import Error from "./pages/404";
import AgentCommisionDetails from "./agent/agent-commision-details";

function App() {
  useEffect(() => {
    // Function to prevent default scroll behavior
    const handleWheel = event => {
      if (event.target.type === "number") {
        event.preventDefault();
      }
    };

    // Add event listener to prevent scrolling on text inputs
    window.addEventListener("wheel", handleWheel, {passive: false});

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div>
      <Routes>
        {/* <Route element={<NotLogInAuth />}> */}
        <Route path="/" element={<Home />} />
        <Route path="/packages" element={<CompareTourListing />} />
        <Route path="/tour-list-special" element={<CompareTourListingSpecial />} />
        {/* </Route> */}
        <Route path="/compare" element={<Compare />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/otp-verification" element={<Otp />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<Support />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/news" element={<BlogNews />} />
        <Route path="/news/:id" element={<BlogDetails />} />
        <Route path="/popular-destination" element={<PopularDestination />} />
        <Route path="/terms-of-use" element={<TermsAndUse />} />
        <Route path="/cancellation-policy" element={<CancellationPolicy />} />
        <Route path="/user-agreement" element={<UserAgreement />} />

        {/* Customer routes */}
        <Route element={<Auth allowedRoles={[2]} />}>
          <Route path="/tour/custom" element={<CustomTourPackage />} />
          <Route path="/tour/upcoming" element={<UpcomingTour />} />
          {/* <Route path="/booked-tour-details/:bookingId" element={<UpcomingTourDetails />} /> */}
          <Route path="/tour/completed" element={<CompletedTour />} />
          <Route path="/tour/cancelled" element={<CancelledTour />} />
          <Route path="/my-account-quotation" element={<Quotation />} />
          <Route path="/profile" element={<PersonalInformation />} />
          <Route path="/profile/login-details" element={<LoginDetails />} />
          <Route path="/profile/my-rewards" element={<MyRewards />} />
          <Route path="/profile/membership" element={<Membership />} />
          {/* <Route path="/booking/:bookingId/partial-cancellation" element={<BookingPartialCancellation />} />
          <Route path="/booking/:bookingId/cancellation" element={<BookingCancellation />} /> */}
          <Route path="/wishlist" element={<MyWishList />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/payment-history" element={<PaymentHistory />} />
          <Route path="/quotations" element={<QuotationList />} />
          <Route path="/quotation/:bookingId" element={<OnRequestQuoteDetails />} />
        </Route>

        {/* For both customer and agent */}
        <Route element={<Auth allowedRoles={[2, 4]} />}>
          <Route path="/package/:packageId/travellers" element={<Travellers />} />
          <Route path="/package/booked" element={<PackageBooked />} />
          <Route path="/booking/detail/:bookingId" element={<UpcomingTourDetails />} />booking/detail/
          <Route path="/booking/:bookingId/partial-cancellation" element={<BookingPartialCancellation />} />
          <Route path="/booking/:bookingId/cancellation" element={<BookingCancellation />} />
        </Route>

        <Route path="/package/:packageId" element={<TourDetails />} />
        <Route path="/vendor-package-preview/:packageId" element={<VendorTourPreview />} />

        <Route path="/package/:packageId/rooms-selection" element={<UsersRoomsAndTravellersSelection />} />
        <Route path="/package/:packageId/users-availibility" element={<UsersAvailibility />} />
        <Route path="/package/:packageId/review" element={<ReviewFlights />} />

        {/* Vendor routes */}
        <Route path="business">
          <Route path="login/phone" element={<VendorLoginPhone />} />
          <Route path="login/phone/verify-otp" element={<VendorPhoneOtpVerification />} />
          <Route path="login/email" element={<VendorLoginEmail />} />
          <Route path="login/email/verify-otp" element={<VendorEmailOtpVerification />} />
          <Route path="registration" element={<VendorRegistrationGstProvided />} />
          <Route path="thank-you" element={<VendorThankYouPage />} />
        </Route>

        {/* Role 3 for Vendor */}
        <Route element={<Auth allowedRoles={[3]} />}>
          <Route path="business">
            <Route path="package/add" element={<AddTourPackage />} />
            <Route path="package/edit/:packageId" element={<EditTourPackage />} />
            <Route path="package/edit/preview/:packageId" element={<PreviewEditTourPackage />} />
            <Route path="package/duplicate/:packageId" element={<DuplicateTourPackage />} />
            <Route path="notifications" element={<VendorNotificationList />} />
            <Route path="bookings" element={<VendorBookingList />} />
            <Route path="booking/:bookingId" element={<VendorBookingDetails />} />
            <Route path="packages" element={<VendorPackageList />} />
            <Route path="profile" element={<VendorAccountSetting />} />
            <Route path="profile/edit" element={<VendorEditProfile />} />
            <Route path="message/:packageId" element={<VendorPackageMessage />} />
            <Route path="reports" element={<VendorReports />} />
            <Route path="vendor-reports-inventory2" element={<VendorReportsInventory2 />} />
            <Route path="custom-tours" element={<VendorCustomTourList />} />
            <Route path="quotation/:bookingId" element={<VendorOnRequestQuoteDetails />} />
          </Route>
        </Route>

        {/* Agent routes */}
        <Route path="partners">
          <Route path="login/phone" element={<AgentLoginPhone />} />
          <Route path="login/phone/verify-otp" element={<AgentPhoneOtpVerification />} />
          <Route path="login/email" element={<AgentLoginEmail />} />
          <Route path="login/email/verify-otp" element={<AgentEmailOtpVerification />} /> 
          <Route path="registration" element={<AgentRegistration />} />
          <Route path="thank-you" element={<AgentThankYou />} />
        </Route>

        {/* Role 4 for Agent */}
        <Route element={<Auth allowedRoles={[4]} />}>
          <Route path="partners">
            <Route path="profile" element={<AgentAccountSetting />} />
            <Route path="bookings" element={<AgentBookingList />} />
            <Route path="booking/:bookingId" element={<AgentBookingDetails />} />
            <Route path="notifications" element={<AgentNotificationList />} />
            <Route path="commissions" element={<AgentCommisionList />} />
            <Route path="commissions/:invoiceNo" element={<AgentCommisionDetails />} />
            <Route path="booking/detail/:bookingId" element={<AgentUpcomingTourDetails />} />
          </Route>
        </Route>

        <Route path="*" element={<Error />} />
      </Routes>
      <NotificationContainer />
    </div>
  );
}

export default App;
