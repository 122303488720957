import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {fetchVendorNotificationsListApi, getCustomerDetailsApi, notificationMarkAsReadsApi, notificationRemoveAllApi} from "../services/ApiService";

const Notifications = () => {
  const [customerData, setCustomerData] = useState();
  const [notiResp, setNotiResp] = useState();
  const [loading, setLoading] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustomerData(response.data);
        }
      }
    } catch (error) {}
  };
  const fetchVendorNotificationsList = async () => {
    setScreenLoading(true);
    try {
      let response = await fetchVendorNotificationsListApi();
      if (response) {
        setNotiResp(response.data);
      }
      setScreenLoading(false);
    } catch (error) {
      setScreenLoading(false);
    }
  };

  const notificationMarkAsReads = async () => {
    try {
      let response = await notificationMarkAsReadsApi();
      if (response) {
      }
    } catch (error) {}
  };

  const handleNotificationRemoveAll = async () => {
    try {
      let response = await notificationRemoveAllApi();
      if (response) {
        setNotiResp(null);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCustomerDetails();
    fetchVendorNotificationsList();
    notificationMarkAsReads();
  }, []);

  return (
    <div className="main">
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="accountName">
              <h2>
                {customerData?.data?.first_name} {customerData?.data?.last_name}
              </h2>
              <h3>{customerData?.User?.mobile}</h3>
            </div>
            <div className="pointDiv">
              <h2>
                Wishmytour Points <span>0</span>
              </h2>
            </div>

            <div className="breadCmb customerNotiBtnClass">
              <p>
                <Link to="/profile">My Account</Link>Notifications
              </p>
              {notiResp?.notifications?.length > 0 ? (
                <div className="btnsHdn">
                  <button className="saveBtn saveBtn2" onClick={() => handleNotificationRemoveAll()}>
                    Remove All
                  </button>
                </div>
              ) : null}
            </div>

            <div className="upcomingInner">
              <div className="uptabContent">
                <div className="notificationDiv">
                  <ul>
                    {notiResp?.notifications?.length > 0 ? (
                      notiResp?.notifications?.map((item, index) => (
                        <li key={index}>
                          <p>{item?.message}</p>
                          <p>
                            <strong>
                              {new Date(item?.created_at).toLocaleString("en-US", {
                                month: "long",
                                day: "numeric",
                                year: "numeric",
                              })}
                            </strong>
                          </p>
                        </li>
                      ))
                    ) : !loading ? (
                      <p>No notification found.</p>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Notifications;
