import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {getPackageListApi} from "../services/ApiService";
import {IMAGE_URL} from "../utils/commonUrls";

const transferIcon2 = require("./../assets/img/car-icon2.svg").default;
const mealIcon2 = require("./../assets/img/restaurant-icon2.svg").default;
const hotelIcon2 = require("./../assets/img/bed-icon2.svg").default;
const optionalIcon2 = require("./../assets/img/optional-icon2.svg").default;
const special1 = require("./../assets/img/special1.png");
const trainIcon = require("./../assets/img/train.svg").default;
const binocularIcon = require("./../assets/img/binoculars.svg").default;

const CompareTourListingSpecial = () => {
  const location = useLocation();
  const [tourPackageList, setTourPackageList] = useState();

  const getPackageList = async () => {
    try {
      let response = await getPackageListApi();
      if (response) {
        if (response.data.res === true) {
          setTourPackageList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const formattedStayPlan = stayPlan => (
    <p>
      {stayPlan.map((stayItem, stayIndex) => (
        <span key={stayIndex}>
          {stayItem.city_name} ({stayItem.total_nights}N){stayIndex < stayPlan.length - 1 && " → "}
        </span>
      ))}
    </p>
  );

  useEffect(() => {
    getPackageList();
  }, []);

  return (
    <div className="main">
      <Header />

      <div className="innerDiv">
        <div className="compareDiv">
          <div className="mainDiv">
            <div className="leftCompare">
              <h2>FILTERS</h2>
              <div className="compareBtn">
                <h3>Compare Package</h3>

                <div className="checkboxes-and-radios">
                  <input type="checkbox" name="checkbox-cats2" id="checkbox-2" value="2" />
                  <label for="checkbox-2"></label>
                  <input type="checkbox" name="checkbox-cats3" id="checkbox-3" value="3" checked />
                </div>
              </div>

              <div className="sideFilter">
                <h3>Destination State</h3>
                <ul>
                  <li>
                    <input type="checkbox" />
                    West Bengal
                  </li>
                  <li>
                    <input type="checkbox" />
                    Kerala
                  </li>
                  <li>
                    <input type="checkbox" />
                    Rajasthan
                  </li>
                  <li>
                    <input type="checkbox" />
                    Punjab
                  </li>
                  <li>
                    <input type="checkbox" />
                    Tamil Nadu
                  </li>
                </ul>
                <Link to="/">Show More</Link>
              </div>

              <div className="sideFilter">
                <h3>Destination City</h3>
                <ul>
                  <li>
                    <input type="checkbox" />
                    West Bengal
                  </li>
                  <li>
                    <input type="checkbox" />
                    Durgapur
                  </li>
                  <li>
                    <input type="checkbox" />
                    Howrah
                  </li>
                  <li>
                    <input type="checkbox" />
                    Siliguri
                  </li>
                  <li>
                    <input type="checkbox" />
                    Darjeeling
                  </li>
                </ul>
                <Link to="/">Show More</Link>
              </div>

              <div className="sideFilter">
                <h3>Transportation</h3>
                <ul>
                  <li>
                    <input type="checkbox" />
                    Flight
                  </li>
                  <li>
                    <input type="checkbox" />
                    Train
                  </li>
                </ul>
              </div>

              <div className="sideFilter">
                <h3>Hotel Star</h3>
                <ul>
                  <li>
                    <input type="checkbox" />5 Star
                  </li>
                  <li>
                    <input type="checkbox" />4 Star
                  </li>
                  <li>
                    <input type="checkbox" />
                    Up To 3 Star
                  </li>
                </ul>
              </div>

              <div className="sideFilter">
                <h3>Religion</h3>
                <ul>
                  <li>
                    <input type="checkbox" />
                    Hinduism
                  </li>
                  <li>
                    <input type="checkbox" />
                    Islam
                  </li>
                  <li>
                    <input type="checkbox" />
                    Christianity
                  </li>
                </ul>
                <Link to="/">Show More</Link>
              </div>

              <div className="sideFilter">
                <h3>Trip</h3>
                <ul>
                  <li>
                    <input type="checkbox" />
                    Domestic
                  </li>
                  <li>
                    <input type="checkbox" />
                    International
                  </li>
                </ul>
              </div>

              <div className="sideFilter">
                <h3>Type of Tour Packages</h3>
                <ul>
                  <li>
                    <input type="checkbox" />
                    Standard
                  </li>
                  <li>
                    <input type="checkbox" />
                    Weekend
                  </li>
                </ul>
              </div>

              <div className="sideFilter">
                <h3>Themes</h3>
                <ul>
                  <li>
                    <input type="checkbox" />
                    Hill
                  </li>
                  <li>
                    <input type="checkbox" />
                    Beach
                  </li>
                  <li>
                    <input type="checkbox" />
                    Jungle
                  </li>
                  <li>
                    <input type="checkbox" />
                    Nature
                  </li>
                </ul>
              </div>

              <div className="sideFilter">
                <h3>Days</h3>
                <div className="daysRange">
                  <RangeSliderComponent minLimit={2} maxLimit={21} stepIncrement={1} label="days" />
                </div>
              </div>

              <div className="sideFilter">
                <h3>Budget</h3>
                <div className="budgetRange">
                  <RangeSliderComponent minLimit={5000} maxLimit={185000} stepIncrement={100} label="rupees" />
                </div>
                <ul>
                  <li>
                    <input type="checkbox" />
                    ₹25,000 <span>(46)</span>
                  </li>
                  <li>
                    <input type="checkbox" />
                    25,000 - ₹35,000 <span>(37)</span>
                  </li>
                  <li>
                    <input type="checkbox" />
                    35,000 - ₹45,000 <span>(34)</span>
                  </li>
                  <li>
                    <input type="checkbox" />
                    ₹45,000 <span>(34)</span>
                  </li>
                </ul>
                <Link to="/">Show More</Link>
              </div>
            </div>

            <div className="rightCompare">
              {tourPackageList &&
                tourPackageList?.map((item, index) => (
                  <div className="compareLoop" key={index}>
                    <div className="specialDiv">
                      <div className="specialBox">
                        <img src={IMAGE_URL + item?.featured_image_path} alt="Image 1" className="specialSliderImg" />
                        <div className="bannerTxt">
                          <div className="compareLabel">
                            <input type="checkbox" /> Compare
                          </div>
                          <h2>{item?.package_name}</h2>
                          <h4>{item?.vendor_name ? <>{/* By: <span>{item?.vendor_name}</span> */}</> : null}</h4>
                          <h3>{item?.total_days}</h3>
                          <ul>
                            {/* {item?.inclusions_in_package?.map((incluItem, incluIndex) => (
                              <li key={incluIndex}>
                                {incluItem.slug === "transfer" && <img src={transferIcon2} alt="Transfer Icon" />}
                                {incluItem.slug === "meals" && <img src={mealIcon2} alt="Meals Icon" />}
                                {incluItem.slug === "flight" && <img src={optionalIcon2} alt="Flight Icon" />}
                                {incluItem.slug === "hotel" && <img src={hotelIcon2} alt="Flight Icon" />}
                                {incluItem.name}
                              </li>
                            ))} */}
                            {item?.is_flight === 1 ? (
                              <li>
                                <img src={optionalIcon2} alt="Image 1" />
                                Flight
                              </li>
                            ) : null}
                            {item?.is_train === 1 ? (
                              <li>
                                <img src={trainIcon} alt="Image 1" />
                                Train
                              </li>
                            ) : null}
                            {item?.is_hotel === 1 ? (
                              <li>
                                <img src={hotelIcon2} alt="Image 1" />
                                Hotel
                              </li>
                            ) : null}
                            {item?.is_meal === 1 ? (
                              <li>
                                <img src={mealIcon2} alt="Image 1" />
                                Meals
                              </li>
                            ) : null}
                            {item?.is_transport === 1 ? (
                              <li>
                                <img src={transferIcon2} alt="Image 1" />
                                Transfer
                              </li>
                            ) : null}
                            {item?.is_sightseeing === 1 ? (
                              <li>
                                <img src={binocularIcon} alt="Image 1" />
                                Sightseeing
                              </li>
                            ) : null}
                          </ul>
                          {formattedStayPlan(item?.stay_plan)}
                          <h5>
                            ₹{item?.starting_price.toLocaleString()} <span>(Starting From)</span>
                          </h5>
                          <Link to={`/package/${item?.package_id}`}>View Details</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              <div className="comparePackage">
                <p>4/4 Package Selected</p>
                <button>Compare</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CompareTourListingSpecial;
