import React, {Component, useState, useRef, useEffect} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {Link, useNavigate, useParams} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Accordion, Modal} from "react-bootstrap";
import ImageUploader from "../component/ImageUploader";
import {
  addBookingPaymentApi,
  addFeedbackApi,
  addReportApi,
  agentBookingDetailsApi,
  customerBookingDetailsApi,
  getCustomerDetailsApi,
  getTaxDetailsApi,
  upcomingBookingApi,
} from "../services/ApiService";

import {Helmet} from "react-helmet";
import StarRatings from "react-star-ratings";
import {IMAGE_URL} from "../utils/commonUrls";
import {useUserContext} from "../context/UserContext";
import {NotificationManager} from "react-notifications";
import {TimeConverter} from "../utils/commonFunctions";

const transferIcon2 = require("./../assets/img/car-icon2.svg").default;
const mealIcon2 = require("./../assets/img/restaurant-icon2.svg").default;
const hotelIcon2 = require("./../assets/img/bed-icon2.svg").default;
const optionalIcon2 = require("./../assets/img/optional-icon2.svg").default;
const special1 = require("./../assets/img/special1.png");
const trainIcon2 = require("./../assets/img/train-icon2.svg").default;
const trainIcon = require("./../assets/img/train.svg").default;
const binocularIcon = require("./../assets/img/binoculars.svg").default;
const FlightFromImg = require("./../assets/img/flightfromimg.svg").default;
const HotelImg = require("./../assets/img/hotel-img.png");
const crossIcon2 = require("./../assets/img/cross.svg").default;
const dragIcon = require("./../assets/img/drag-icon.svg").default;

const AgentUpcomingTourDetails = () => {
  const {bookingId} = useParams();
  const navigate = useNavigate();
  const {userData} = useUserContext();
  const [customerData, setCustomerData] = useState();
  const [bookingData, setBookingData] = useState();
  const [totalAdult, setTotalAdult] = useState();
  const [passengersArray, setPassengersArray] = useState([]);
  const [priceDetails, setPriceDetails] = useState({
    basePrice: 0,
    addOnPrice: 0,
    taxPrice: 0,
    gstTax: 0,
    tcsTax: 0,
    gstTaxIncludePrice: 0,
    tcsTaxIncludePrice: 0,
    bulkDiscountPrice: 0,
    bulkDiscountPercent: 0,
    subTotalPrice: 0,
    totalPartialCalAmt: 0,
    latestPartialCancelDate: null,
    paidAmount: null,
    payOption: null,
    payAmount: 0,
    remainingAmmount: 0,
  });
  const [taxDetails, setTaxDetails] = useState();
  const [startDateFromToday, setStartDateFromToday] = useState();
  const [rating, setRating] = useState(1);
  const [feedback, setFeedback] = useState();
  const [showRatePopup, setShowRatePopup] = useState(false);
  const [reportBtnDis, setReportBtnDis] = useState(false);
  const [showReportButton, setShowReportButton] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [reportIssueText, setReportIssueText] = useState();
  const [reportIssueImages, setReportIssueImages] = useState([]);
  const [payAnotherOptionShow, setPayAnotherOptionShow] = useState(false);
  const [payBtnDis, setPayBtnDis] = useState(false);

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustomerData(response.data);
        }
      }
    } catch (error) {}
  };

  const getBookingDetails = async () => {
    try {
      let response = await agentBookingDetailsApi(bookingId);
      if (response) {
        if (response.data.res === true) {
          setBookingData(response.data);
          setRating(response.data.data[0].rating ? parseInt(response.data.data[0].rating) : 0);
          setFeedback(response.data.data[0].feedback);
          setPriceDetails(prev => ({
            ...prev,
            basePrice: response.data.data[0].base_amount,
          }));
          if (response.data.booking_rooms.length > 0) {
            let adults = 0;
            response.data.booking_rooms.map(item => {
              adults += item?.adults;
            });
            setTotalAdult(adults);
          }

          let tempArray = [];

          response.data.booking_rooms.map((roomItem, roomIndex) => {
            const roomData = {room: "Room " + (roomIndex + 1), adults: [], children: []};
            response.data.booking_passengers.map((passItem, passIndex) => {
              if (roomItem.id === passItem.booking_room_id) {
                const passenger = {
                  title: passItem.title,
                  first_name: passItem.first_name,
                  last_name: passItem.last_name,
                  dob: passItem.dob,
                  gender: passItem.gender,
                  status: passItem.status,
                };

                if (passItem.is_adult === 1) {
                  roomData.adults.push(passenger);
                } else {
                  roomData.children.push(passenger);
                }
              }
            });
            tempArray.push(roomData);
          });

          setPassengersArray(tempArray);

          let basePrice = response.data.data[0].base_amount;
          let addOnPrice = response.data.data[0].addon_total_price;
          let taxes = 0;
          let gstTax = 0;
          let tcsTax = 0;
          let taxIncludePrice = 0;
          let gstTaxIncludePrice = 0;
          let tcsTaxIncludePrice = 0;
          let bulkDiscountPrice = 0;
          let bulkDiscountPercent = 0;
          let subTotal = 0;
          let totalPartialCalAmt = 0;
          let latestPartialCancelDate = null;
          let paidAmount = 0;
          let remainingAmmount = 0;

          if (response.data?.bookingCancellation?.length > 0) {
            response.data?.bookingCancellation?.map(item => {
              totalPartialCalAmt += parseFloat(item?.cancellation_charge);
            });
            let lastItemDate = response.data?.bookingCancellation?.slice(-1)[0]?.created_at;
            latestPartialCancelDate = lastItemDate;
          }

          if (response.data?.data[0]?.payments?.length > 0) {
            response.data?.data[0]?.payments?.map(item => {
              paidAmount += parseFloat(item?.paid_amount);
            });
          } else {
            paidAmount = 0;
          }

          if (response.data?.bookingCancellation?.length > 0) {
            response.data?.bookingCancellation?.map(item => {
              totalPartialCalAmt += parseFloat(item?.cancellation_charge);
            });
            let lastItemDate = response.data?.bookingCancellation?.slice(-1)[0]?.created_at;
            latestPartialCancelDate = lastItemDate;
          }

          // if (response.data?.packageAddon?.length > 0) {
          //   response.data?.packageAddon?.map(item => {
          //     addOnPrice += parseFloat(item?.price);
          //   });
          // }

          try {
            const formData = new FormData();
            formData.append("package_id", response.data.data[0].package_id);
            let response1 = await getTaxDetailsApi(formData);
            if (response1 && response1.data.res === true) {
              setTaxDetails(response1.data.data);
              gstTax = response1.data.data.gst.value ? parseInt(response1.data.data.gst.value) : 0;
              tcsTax = response1.data.data.tcs.value ? parseInt(response1.data.data.tcs.value) : 0;
            }
          } catch (error) {
            console.error("Error fetching package details:", error);
          }

          // GST and tcs calculation
          taxes = gstTax + tcsTax;

          let basepriceWithAddon = 0;
          if (parseFloat(addOnPrice)) {
            basepriceWithAddon = parseFloat(basePrice) + parseFloat(addOnPrice);
          } else {
            basepriceWithAddon = parseFloat(basePrice);
          }

          if (taxes > 0) {
            taxIncludePrice = parseFloat(basepriceWithAddon) + (parseFloat(basepriceWithAddon) * taxes) / 100;
          }
          if (gstTax > 0) {
            gstTaxIncludePrice = (basepriceWithAddon * gstTax) / 100;
          }
          if (tcsTax > 0) {
            tcsTaxIncludePrice = (basepriceWithAddon * tcsTax) / 100;
          }

          subTotal = parseFloat(taxes ? taxIncludePrice : basepriceWithAddon);

          // Calculate day difference between booking date and today
          const today = new Date();
          const bookingDate = new Date(response.data.data[0].first_booking_date);
          const timeDiff = bookingDate.getTime() - today.getTime();
          const dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));

          // Calculate days remaining for payment policy
          // Parse paymeny policy
          const parsedPolicy = response.data.package.payment_policy.map(policy => {
            const parts = policy.split("-");
            return {
              startDay: parseInt(parts[0], 10),
              endDay: parseInt(parts[1], 10),
              percentage: parseInt(parts[2], 10),
            };
          });

          // Find applicable paymeny policy
          const applicablePolicy = parsedPolicy.find(policy => {
            if (policy.startDay <= policy.endDay) {
              return dayDifference <= policy.startDay && dayDifference >= policy.endDay;
            } else {
              if (policy.endDay === 0) {
                return dayDifference >= policy.startDay;
              } else {
                return (
                  (dayDifference <= policy.startDay && dayDifference >= policy.endDay) || (dayDifference >= policy.startDay && dayDifference < policy.endDay)
                );
              }
            }
          });

          console.log(applicablePolicy);

          let payAmount = 0;

          remainingAmmount = parseFloat(subTotal) - parseFloat(paidAmount);

          console.log(remainingAmmount);
          if (applicablePolicy && applicablePolicy?.percentage !== 100) {
            payAmount = (remainingAmmount * applicablePolicy?.percentage) / 100;
            setPayAnotherOptionShow(true);
          } else {
            payAmount = remainingAmmount;
            setPayAnotherOptionShow(false);
          }

          console.log(subTotal, paidAmount);

          setPriceDetails(prev => ({
            ...prev,
            basePrice: basePrice,
            addOnPrice: addOnPrice,
            taxPrice: taxes,
            gstTax: gstTax,
            tcsTax: tcsTax,
            gstTaxIncludePrice: parseFloat(gstTaxIncludePrice).toFixed(2),
            tcsTaxIncludePrice: parseFloat(tcsTaxIncludePrice).toFixed(2),
            bulkDiscountPrice: parseFloat(bulkDiscountPrice).toFixed(2),
            bulkDiscountPercent: bulkDiscountPercent,
            subTotalPrice: subTotal.toFixed(2),
            totalPartialCalAmt: parseFloat(totalPartialCalAmt).toFixed(2),
            latestPartialCancelDate: latestPartialCancelDate,
            paidAmount: paidAmount,
            payAmount: payAmount,
            remainingAmmount: remainingAmmount,
          }));

          // Calculate the difference in time (in milliseconds)
          const diffTime = Math.abs(new Date() - new Date(response?.data?.data[0]?.last_booking_date));

          // Convert milliseconds to days
          const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

          if (diffDays === 1) {
            setShowReportButton(true);
          } else {
            setShowReportButton(false);
          }
        }
      }
    } catch (error) {}
  };

  const formatDateFormat = date => {
    const originalDateString = date;

    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);

    // Months array for mapping month indexes to month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get the month, day, and year from the parsed date
    const monthIndex = originalDate.getMonth();
    const day = originalDate.getDate();
    const year = originalDate.getFullYear();

    // Format the date string
    const formattedDateString = `${day} ${months[monthIndex]}, ${year}`;
    return formattedDateString;
  };

  const formattedStayPlan = (stayPlan, trainNightCount) => (
    <p className="packDay">
      {trainNightCount ? "Train (1N) → " : null}
      {stayPlan?.map((stayItem, stayIndex) => (
        <span key={stayIndex}>
          {stayItem.city_name} ({stayItem.total_nights}N){stayIndex < stayPlan.length - 1 && " → "}
        </span>
      ))}
    </p>
  );

  const formatDate = inputDate => {
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-GB"); // 'en-GB' uses day/month/year format
  };

  const handleDragEnter = e => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = e => {
    e.preventDefault();
    setDragging(false);

    const droppedFiles = e.dataTransfer.files;
    const newImages = Array.from(droppedFiles).map(file => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    const allowedExtensions = ["png", "jpeg", "jpg"];
    const allowedDimension = 800;
    const maxSizeInBytes = 1024 * 1024; // 400kb

    const validateImage = image => {
      return new Promise((resolve, reject) => {
        const extension = image.file.name.split(".").pop().toLowerCase();

        // Check file extension
        if (!allowedExtensions.includes(extension)) {
          NotificationManager.warning(`File '${image.file.name}' has an invalid extension. Allowed extensions are png, jpeg, and jpg.`);
          resolve(false);
        }

        // Check image dimensions
        const img = new Image();
        img.onload = () => {
          // if (allowedExtensions.includes(extension) && (img.width < allowedDimension || img.height < allowedDimension)) {
          //   NotificationManager.warning(`Image '${image.file.name}' dimensions should be ${allowedDimension}x${allowedDimension} pixels.`);
          //   resolve(false);
          // } else {
          // Check file size
          if (image.file.size > maxSizeInBytes) {
            NotificationManager.warning(`File '${image.file.name}' size exceeds the maximum allowed size of 1mb.`);
            resolve(false);
          } else {
            resolve(true);
            // }
          }
        };
        img.onerror = () => {
          // NotificationManager.warning(`Failed to load image '${image.file.name}'.`);
          resolve(false);
        };
        img.src = image.preview;
      });
    };

    const validatedImagesPromises = newImages.map(image => validateImage(image));

    Promise.all(validatedImagesPromises)
      .then(validatedResults => {
        const validatedImages = newImages.filter((_, index) => validatedResults[index]);
        setReportIssueImages(prevImages => [...prevImages, ...validatedImages]);
      })
      .catch(error => {
        console.error("Error validating images:", error);
      });
  };

  const handleRemoveReportImage = index => {
    setReportIssueImages(prevImages => {
      const updatedImages = [...reportIssueImages];
      updatedImages.splice(index, 1);
      return updatedImages; // Return the updated array to setImages
    });
    // NotificationManager.success("Image deleted successfully");
  };

  const handleSubmitFeedback = async () => {
    if (!rating) {
      NotificationManager.warning("Please provide rating.");
    } else {
      setReportBtnDis(true);
      try {
        const formData = new FormData();
        formData.append("booking_id", bookingId);
        formData.append("feedback", feedback);
        formData.append("rating", rating);
        let response = await addFeedbackApi(formData);
        if (response) {
          if (response.data.res === true) {
            setShowRatePopup(false);
            NotificationManager.success(response.data.msg);
            getBookingDetails();
          } else {
            NotificationManager.error(response.data.msg);
          }
          setReportBtnDis(false);
        }
      } catch (error) {
        NotificationManager.error("Something went wrong, please try again.");
        setReportBtnDis(false);
      }
    }
  };

  const handleSubmitReport = async () => {
    if (!reportIssueText) {
      NotificationManager.warning("Please enter your complain.");
    } else if (reportIssueImages?.length === 0) {
      NotificationManager.warning("Please upload atleast one image.");
    } else {
      setReportBtnDis(true);
      try {
        const formData = new FormData();
        formData.append("booking_id", bookingId);
        formData.append("report", reportIssueText);
        reportIssueImages?.map(item => {
          formData.append("gallery_images[]", item?.file);
        });
        let response = await addReportApi(formData);
        if (response) {
          if (response.data.res === true) {
            setShowPopup(false);
            NotificationManager.success(response.data.msg);
            getBookingDetails();
            window.scrollTo({top: 0, behavior: "smooth"});
          } else {
            NotificationManager.error(response.data.msg);
          }
          setReportBtnDis(false);
        }
      } catch (error) {
        NotificationManager.error("Something went wrong, please try again.");
        setReportBtnDis(false);
      }
    }
  };

  const hotelSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
  };

  const [showPopup, setShowPopup] = useState(false);
  const [section1Images, setSection1Images] = useState([]);
  const [section2Images, setSection2Images] = useState([]);
  const [editingValues, setEditingValues] = useState({});

  const handleAddInclusion = () => {
    setShowPopup(true); // Fixing the syntax
  };

  const handleImagesChange = (newImages, sectionTitle) => {
    if (sectionTitle === "Section 1") {
      setSection1Images(newImages);
    } else if (sectionTitle === "Section 2") {
      setSection2Images(newImages);
      setEditingValues({
        ...editingValues,
        hotelImages: newImages,
      });
      setSection2Images([]);
    }
    // You can perform any other necessary actions here if needed
  };

  const handlePayNow = async () => {
    try {
      setPayBtnDis(true);
      let formData = new FormData();
      formData.append("booking_id", bookingId);
      formData.append("paid_amount", priceDetails.payOption === 1 ? priceDetails.payAmount : priceDetails.remainingAmmount);

      let response = await addBookingPaymentApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          getBookingDetails();
          setPriceDetails(prev => ({...prev, payOption: null}));
        } else {
          NotificationManager.error(response.data.msg);
        }
        setPayBtnDis(false);
      }
    } catch (error) {
      NotificationManager.error("Something went wrong, please try again.");
      setPayBtnDis(false);
    }
  };

  useEffect(() => {
    getCustomerDetails();
    getBookingDetails();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Booked Tour Details`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Booked Tour Details page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="accountName">
              <h2>
                {customerData?.data?.first_name} {customerData?.data?.last_name}
              </h2>
              <h3>{customerData?.User?.mobile}</h3>
            </div>
            {/* <div className="pointDiv">
              <h2>
                Wishmytour Points <span>0</span>
              </h2>
            </div> */}

            <div className="breadCmb">
              <p>Details for Booking ID: #{bookingData?.data[0]?.id}</p>
            </div>

            <div className="upcomingInner">
              <div className="uptabContent tourDtl">
                <div className="tourListing">
                  {/* <h3 className="dtsBkId">Booking ID # {bookingData?.data[0]?.id}</h3> */}
                  <div className="specialBox">
                    <div className="tourBoxImg">
                      {/* <Link to="/"> */}
                      <img src={IMAGE_URL + bookingData?.package?.gallery_images[0]?.path} alt="Image 1" className="specialSliderImg" />
                      {/* </Link> */}
                    </div>
                    <div className="bannerTxt">
                      {/* <div className="bookingStatus">Booking Pending</div> */}
                      <h2>
                        <Link>{bookingData?.package?.package_name}</Link>
                      </h2>
                      {/* <h4>
                        By: <span>Ayan Dutta Tour & Traveller</span>
                      </h4> */}
                      <h3>{bookingData?.package?.total_days}</h3>

                      {formattedStayPlan(bookingData?.package?.stay_plan, bookingData?.package?.total_train_nights_count)}

                      <ul>
                        {bookingData?.package?.is_flight ? (
                          <li>
                            <img src={optionalIcon2} alt="Image 1" />
                            Flight
                          </li>
                        ) : null}

                        {bookingData?.package?.is_train ? (
                          <li>
                            <img src={trainIcon} alt="Image 1" />
                            Train
                          </li>
                        ) : null}

                        {bookingData?.package?.is_hotel ? (
                          <li>
                            <img src={hotelIcon2} alt="Image 1" />
                            Hotel
                          </li>
                        ) : null}

                        {bookingData?.package?.is_meal ? (
                          <li>
                            <img src={mealIcon2} alt="Image 1" />
                            Meals
                          </li>
                        ) : null}

                        {bookingData?.package?.is_transport ? (
                          <li>
                            <img src={transferIcon2} alt="Image 1" />
                            Transfer
                          </li>
                        ) : null}

                        {bookingData?.package?.is_sightseeing ? (
                          <li>
                            <img src={binocularIcon} alt="Image 1" />
                            Sightseeing
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* {bookingData?.data[0]?.bookings_status_id === 3 ? (
                <>
                  <div className="bookingDetails">
                    <h3>
                      How was your experience?{" "}
                      {!bookingData?.data[0]?.rating ? (
                        <span className="rateLink" onClick={() => setShowRatePopup(true)}>
                          Rate us!
                        </span>
                      ) : null}
                    </h3>
                    <div className="bookDtlsInner">
                      <StarRatings
                        rating={bookingData?.data[0]?.rating ? parseInt(bookingData?.data[0]?.rating) : 0} // Initial rating value
                        starRatedColor="#F8B84E" // Color of the filled stars
                        numberOfStars={5} // Total number of stars
                        name="rating" // Unique name for the component
                        starDimension="30px"
                        starSpacing="2px"
                      />
                      {bookingData?.data[0]?.feedback ? (
                        <ul className="bookContainer cancelIssue feedbackText">
                          <li>{bookingData?.data[0]?.feedback}</li>
                        </ul>
                      ) : null}
                    </div>
                  </div>

                  {bookingData?.data[0]?.report ? (
                    <>
                      <div className="bookingDetails">
                        <h3>Report Details</h3>
                        <div className="bookDtlsInner">
                          {bookingData?.data[0]?.report ? (
                            <ul className="bookContainer cancelIssue feedbackText">
                              <li>{bookingData?.data[0]?.report}</li>
                            </ul>
                          ) : null}

                          {bookingData?.reportGalleryImages?.length > 0 ? (
                            <div className={`dragUpload withoutBorder`}>
                              <div className="uploadedImagesContainer">
                                {bookingData?.reportGalleryImages?.map((image, index) => (
                                  <div key={index} className="uploadedImage">
                                    <img src={IMAGE_URL + image.path} alt={`Uploaded ${index + 1}`} />
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null} */}

              {/* {bookingData?.data[0]?.bookings_status_id === 4 ? (
                <div className="bookingDetails">
                  <h3>Booking Cancellation Issue</h3>
                  <div className="bookDtlsInner">
                    <ul className="bookContainer cancelIssue">
                      <li>{bookingData?.bookingCancellation.find(item => item?.cancellation_type === "full")?.cancellation_reason}</li>
                    </ul>
                  </div>
                </div>
              ) : null} */}

              <div className="bookingDetails">
                <h3>Booking Details</h3>
                <div className="bookDtlsInner">
                  <ul className="bookContainer">
                    <li>
                      <label>Tour Date:</label>
                      {formatDateFormat(bookingData?.data[0]?.first_booking_date)} - {formatDateFormat(bookingData?.data[0]?.last_booking_date)}
                    </li>
                    <li>
                      <label>Room:</label>
                      {bookingData?.booking_rooms?.length}
                    </li>
                    <li>
                      <label>Adult:</label>
                      {totalAdult ? totalAdult : 0}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bookingDetails">
                <h3>Customer Details</h3>
                <div className="bookDtlsInner">
                  <ul className="bookContainer">
                    <li>
                      <label>Name:</label>
                      {bookingData?.data[0]?.booking_customer_name}
                    </li>
                    <li>
                      <label>Address:</label>
                      {bookingData?.data[0]?.booking_customer_address}
                    </li>
                    <li>
                      <label>Email:</label>
                      {bookingData?.data[0]?.booking_customer_email}
                    </li>
                    <li>
                      <label>Phone Number:</label>
                      {bookingData?.data[0]?.booking_customer_phone_number}
                    </li>
                    <li>
                      <label>State:</label>
                      {bookingData?.data[0]?.booking_customer_state}
                    </li>
                    <li>
                      <label>PAN Number:</label>
                      {bookingData?.data[0]?.booking_customer_pan_number}
                    </li>
                  </ul>
                </div>
              </div>

              {bookingData?.booking_flight?.length > 0 || bookingData?.booking_train?.length > 0 ? (
                <div className="bookingDetails">
                  <h3>Transport Details</h3>
                  {/* <div className="bookDtlsInner bookFlgt">
                    <div className="DetailsWhiteBox">
                      <div className="FlightListSec">
                        <ul className="row FlightList">
                          <li>
                            <h4>Air India</h4>
                            <h5>AI 445</h5>
                          </li>
                          <li>
                            <h4>new Delhi</h4>
                          </li>
                          <li className="text-center">
                            <img src={FlightFromImg} alt="" className="FlightFromImg" />
                            <span className="FlightClass">hgg</span>
                          </li>
                          <li>gjgjg</li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  {bookingData?.booking_flight?.map((item, index) => (
                    <div className="bookDtlsInner bookFlgt" key={index}>
                      <div className="DetailsWhiteBox">
                        <div className="FlightListSec">
                          <ul className="row FlightList">
                            {/* <li>
                              <h4>Air India</h4>
                              <h5>AI 445</h5>
                            </li> */}
                            <li>
                              <h4>{item?.depart_destination}</h4>
                              <h4>{TimeConverter(item?.depart_datetime)}</h4>
                            </li>
                            <li className="text-center">
                              <img src={FlightFromImg} alt="" className="FlightFromImg" />
                              {/* <span className="FlightClass">{item?.flights[0]?.class}</span> */}
                            </li>
                            <li>
                              <h4>{item?.arrive_destination}</h4>
                              <h4>{TimeConverter(item?.arrive_datetime)}</h4>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                  {bookingData?.booking_train?.map((item, index) => (
                    <div className="bookDtlsInner bookFlgt" key={index}>
                      <div className="DetailsWhiteBox">
                        <div className="FlightListSec">
                          <ul className="row FlightList">
                            {/* <li>
                              <h4>Air India</h4>
                              <h5>AI 445</h5>
                            </li>
                            <li>
                              <h4>{item?.from_station}</h4>
                            </li>
                            <li className="text-center">
                              <img src={trainIcon} alt="" className="FlightFromImg" />
                              <span className="FlightClass">df</span>
                            </li>
                            <li>{item?.to_station}</li> */}

                            <li class="Train trnDtls">
                              <div class="accoInner">
                                <div class="transportName">
                                  <h4>{item?.train_name}</h4>
                                  <p>{item?.train_number}</p>
                                </div>
                                <div class="stationName">
                                  <h4>{item?.from_station}</h4>
                                </div>
                                <div class="classCategory">
                                  <img src={trainIcon2} />
                                  <h4>{item?.class}</h4>
                                </div>
                                <div class="destinationName">
                                  <h4>{item?.to_station}</h4>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}

              {passengersArray.length > 0 ? (
                <div className="bookingDetails">
                  <h3>Travellers</h3>
                  <div className="bookDtlsInner travlerkContainer">
                    {passengersArray?.map((item, index) => (
                      <div className="loopBook" key={index}>
                        <h2>{item?.room}</h2>
                        <ul>
                          {item?.adults?.length > 0
                            ? item?.adults?.map((chItem, chIndex) => (
                                <li>
                                  <div className="trvlrDiv">
                                    <h4>
                                      {chItem?.first_name} {chItem?.last_name} <span>Adult</span>
                                    </h4>
                                    <h5 style={{textTransform: "capitalize"}}>
                                      {chItem?.gender} | {formatDate(chItem?.dob)}
                                    </h5>
                                  </div>
                                  {chItem?.status === 0 ? (
                                    <div className="bookAction">
                                      <button>Cancelled</button>
                                    </div>
                                  ) : null}
                                </li>
                              ))
                            : null}
                          {item?.children?.length > 0
                            ? item?.children?.map((chItem, chIndex) => (
                                <li>
                                  <div className="trvlrDiv">
                                    <h4>
                                      {chItem?.first_name} {chItem?.last_name} <span>Children</span>
                                    </h4>
                                    <h5>
                                      {chItem?.gender} | {formatDate(chItem?.dob)}
                                    </h5>
                                  </div>
                                  {chItem?.status === 0 ? (
                                    <div className="bookAction">
                                      <button>Cancelled</button>
                                    </div>
                                  ) : null}
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}

              {bookingData?.packageAddon?.length > 0 ? (
                <div className="bookingDetails">
                  <h3>Add On</h3>

                  <div className="bookDtlsInner addTrvl">
                    <ul>
                      {bookingData?.packageAddon?.map((item, index) => (
                        <>
                          <li>
                            <h3>{item?.title}</h3>
                            <p>{item?.description}</p>
                            <h4>₹{item?.price}</h4>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}

              <div className="itternaryBooking">
                <h3>Itinerary</h3>

                <div className="ConditionAccordion">
                  <Accordion defaultActiveKey={bookingData?.package?.itinerary?.map((_, index) => index.toString())}>
                    {/* <Accordion defaultActiveKey={["0", "1", "2", "3", "4"]}> */}
                    {bookingData?.package?.itinerary?.map((item, index) => (
                      <Accordion eventKey={index.toString()} key={index}>
                        <div className="accordion-item">
                          <Accordion.Header>Day {index + 1}</Accordion.Header>
                          <Accordion.Body>
                            <ul className="ItineraryList">
                              <li>
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="BorderBox">
                                      <h3>Day</h3>
                                      <h4>{index + 1}</h4>
                                    </div>
                                  </div>
                                  <div className="col-md-10 ItineraryRightContent">
                                    <h3>{item?.itinerary_title}</h3>
                                    {item?.meal ? (
                                      <h4>
                                        Meals: <span>{item?.meal}</span>{" "}
                                      </h4>
                                    ) : null}

                                    <p>{item.itinerary_description}</p>

                                    {item?.flights?.length > 0 ? (
                                      <>
                                        <h5>Flight</h5>
                                        <div className="FlightListSec">
                                          <ul className="row FlightList">
                                            {/* <li>
                                              <h4>Air India</h4>
                                              <h5>AI 445</h5>
                                            </li> */}
                                            <li>
                                              <h4>{item?.flights[0]?.depart_destination.id}</h4>
                                              <h4>{TimeConverter(item?.flights[0]?.depart_datetime)}</h4>
                                            </li>
                                            <li className="text-center">
                                              <img src={FlightFromImg} alt="" className="FlightFromImg" />
                                              <span className="FlightClass">{item?.flights[0]?.class}</span>
                                            </li>
                                            <li className="flightWidth">
                                              <h4>{item?.flights[0]?.arrive_destination.id}</h4>
                                              <h4>{TimeConverter(item?.flights[0]?.arrive_datetime)}</h4>
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {item?.sightseeing?.length > 0 ? (
                                      <>
                                        <h5>Sightseeing</h5>
                                        <ul className="row GreenArrowList">
                                          <li>Visit Shanti Stupa & Leh Palace</li>
                                        </ul>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {item?.sightseeing?.length > 0 ? (
                                      <>
                                        <h5>Sightseeing</h5>
                                        <ul className="row GreenArrowList">
                                          {item?.sightseeing?.map((sight, index) => (
                                            <React.Fragment key={index}>
                                              {sight.morning && <li>{sight?.morning}</li>}
                                              {sight.afternoon && <li>{sight?.afternoon}</li>}
                                              {sight.evening && <li>{sight?.evening}</li>}
                                              {sight.night && <li>{sight?.night}</li>}
                                            </React.Fragment>
                                          ))}
                                        </ul>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {item?.hotels?.length > 0 ? (
                                      <div className="HotelDesSec">
                                        <h3>Hotel</h3>
                                        {/* <p>Check In For 1 Nights</p> */}
                                        <ul className="row HotelDesSecList">
                                          <li className="col-md-4">
                                            <Slider {...hotelSliderSettings}>
                                              {item?.hotels[0]?.hotel_gallery?.map((hotelItem, hotelIndex) => (
                                                <div key={hotelIndex}>
                                                  <img
                                                    src={IMAGE_URL + hotelItem?.path}
                                                    alt=""
                                                    // onClick={() => openHotelPopup(item?.hotels[0]?.hotel_gallery, hotelIndex)}
                                                  />
                                                </div>
                                              ))}
                                            </Slider>
                                          </li>
                                          <li className="col-md-8 TextRightSec">
                                            <StarRatings
                                              rating={item?.hotels[0]?.star} // Initial rating value
                                              starRatedColor="#F8B84E" // Color of the filled stars
                                              numberOfStars={5} // Total number of stars
                                              name="rating" // Unique name for the component
                                              starDimension="18px"
                                              starSpacing="2px"
                                            />
                                            <h3>{item?.hotels[0]?.hotel_name} or Similar</h3>
                                            {item?.hotels[0]?.is_other_place === 1 ? (
                                              <div className="nameDesc">
                                                <p>
                                                  {item?.hotels[0]?.place_name} | {item?.hotels[0]?.distance_from_main_town}
                                                </p>
                                              </div>
                                            ) : null}
                                          </li>
                                        </ul>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </Accordion.Body>
                        </div>
                      </Accordion>
                    ))}
                  </Accordion>
                </div>
              </div>

              <div className="itternaryBooking">
                <div className="ConditionAccordion">
                  <ul className="bookPricing">
                    <li>
                      <div className="thHdn">Base Price</div>
                      <div className="tdHdn">
                        ₹
                        {(parseFloat(priceDetails?.basePrice) + parseFloat(priceDetails?.bulkDiscountPrice)).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </li>
                    {parseFloat(priceDetails?.addOnPrice) ? (
                      <li>
                        <div className="thHdn">Add On</div>
                        <div className="tdHdn">
                          ₹{parseFloat(priceDetails?.addOnPrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </li>
                    ) : null}

                    {priceDetails.gstTax > 0 ? (
                      <li>
                        <div className="thHdn">GST({priceDetails?.gstTax}%)</div>
                        <div className="tdHdn">
                          ₹{Number(priceDetails?.gstTaxIncludePrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </li>
                    ) : null}
                    {priceDetails.tcsTax > 0 ? (
                      <li>
                        <div className="thHdn">GST({priceDetails?.tcsTax}%)</div>
                        <div className="tdHdn">
                          ₹{Number(priceDetails?.tcsTaxIncludePrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </li>
                    ) : null}
                    <li>
                      <div className="thHdn">
                        <h3>Total Amount</h3>
                      </div>
                      <div className="tdHdn">
                        <h4>
                          ₹
                          {priceDetails?.subTotalPrice
                            ? Number(priceDetails.subTotalPrice).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "0.00"}
                        </h4>
                      </div>
                    </li>
                    {priceDetails.paidAmount && parseFloat(priceDetails?.subTotalPrice) !== parseFloat(priceDetails?.paidAmount) ? (
                      <>
                        {bookingData?.data[0]?.payments?.map((item, index) => (
                          <li key={index}>
                            <div className="thHdn">Paid Amount on {formatDateFormat(item?.payment_date)}</div>
                            <div className="tdHdn">
                              ₹{parseFloat(item?.paid_amount).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </div>
                          </li>
                        ))}
                      </>
                    ) : null}
                    {bookingData?.refund_amount_sum ? (
                      <>
                        <li>
                          <div className="thHdn">Refunded Amount</div>
                          <div className="tdHdn">
                            ₹{Number(bookingData?.refund_amount_sum).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                          </div>
                        </li>
                        {/* <li>
                          <div className="thHdn">
                            <h3>Balance Amount</h3>
                          </div>
                          <div className="tdHdn">
                            <h4>
                              ₹
                              {Number(parseFloat(priceDetails?.subTotalPrice) - parseFloat(bookingData?.refund_amount_sum)).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </h4>
                          </div>
                        </li> */}
                      </>
                    ) : null}

                    {priceDetails.paidAmount && parseFloat(priceDetails?.subTotalPrice) !== parseFloat(priceDetails?.paidAmount) ? (
                      <>
                        <li>
                          <div className="thHdn">
                            <h3>Pending Amount</h3>
                          </div>
                          <div className="tdHdn">
                            <h4>
                              ₹
                              {(parseFloat(priceDetails?.subTotalPrice) - parseFloat(priceDetails?.paidAmount)).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </h4>
                          </div>
                        </li>
                      </>
                    ) : null}
                  </ul>
                  {/* {parseFloat(priceDetails?.subTotalPrice) !== parseFloat(priceDetails?.paidAmount) ? (
                    <>
                      <ul className="bookPricing">
                        <li>
                          {payAnotherOptionShow ? (
                            <>
                              <div className="thHdn">
                                <div className="form-check coupons">
                                  <div>
                                    <input
                                      className="form-check-input form-input-radio"
                                      type="radio"
                                      name="couponCode"
                                      checked={priceDetails?.payOption === 2 ? true : false}
                                      onClick={() => {
                                        setPriceDetails(prev => ({...prev, payOption: 2}));
                                      }}
                                    />
                                    <label className="form-check-label payOption">
                                      <strong>Pay Remaining Amount Now </strong>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="tdHdn">-Or-</div>
                            </>
                          ) : null}

                          <div className="tdHdn">
                            <div className="form-check coupons">
                              <div>
                                <input
                                  className="form-check-input form-input-radio"
                                  type="radio"
                                  name="couponCode"
                                  checked={priceDetails?.payOption === 1 ? true : false}
                                  onClick={() => {
                                    setPriceDetails(prev => ({...prev, payOption: 1}));
                                  }}
                                />
                                <label className="form-check-label payOption">
                                  <strong>Now Pay (₹{priceDetails?.payAmount})</strong>
                                </label>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <ul className="paybuttonUl">
                        <li className="fullLIst sbmt">
                          {payBtnDis ? (
                            <button disabled className="addBtn">
                              Please wait...
                            </button>
                          ) : (
                            <button disabled={!priceDetails.payOption} onClick={() => handlePayNow()} className="addBtn">
                              Pay Now
                            </button>
                          )}
                        </li>
                      </ul>
                    </>
                  ) : null} */}
                </div>
              </div>

              {/* <div className="itternaryBooking">
                <div className="ConditionAccordion">
                  <ul className="bookPricing">
                    <li>
                      <div className="thHdn">
                        <input type="checkbox" /> Pay full
                      </div>
                      <div className="tdHdn">
                        <input type="checkbox" /> Pay full
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}

              <div className="bookingStatus">
                <h3>Status</h3>

                <ul>
                  {bookingData?.data[0]?.bookings_status_id === 2 ? (
                    <>
                      <li>
                        <div className="crcl">
                          <span></span>
                        </div>
                        <h3>Booking</h3>
                        <p>{formatDateFormat(bookingData?.data[0]?.created_at)}</p>
                        {/* <Link to="/">View Invoice</Link> */}
                      </li>
                      <li>
                        <div className="crcl2">
                          <span></span>
                        </div>
                        <h3>
                          Booking Confirmed{" "}
                          <span>
                            ₹
                            {Number(
                              bookingData?.data[0]?.addon_total_price
                                ? parseFloat(bookingData?.data[0]?.addon_total_price) + parseFloat(bookingData?.data[0]?.base_amount)
                                : bookingData?.data[0]?.final_price
                            ).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                          </span>
                        </h3>
                        <p>{formatDateFormat(bookingData?.data[0]?.created_at)}</p>
                        <Link to="/">View Invoice</Link>
                      </li>
                    </>
                  ) : bookingData?.data[0]?.bookings_status_id === 3 ? (
                    <>
                      <li>
                        <div className="crcl">
                          <span></span>
                        </div>
                        <h3>Booking</h3>
                        <p>{formatDateFormat(bookingData?.data[0]?.created_at)}</p>
                        {/* <Link to="/">View Invoice</Link> */}
                      </li>
                      <li>
                        <div className="crcl2">
                          <span></span>
                        </div>
                        <h3>
                          Booking Confirmed{" "}
                          <span>
                            ₹
                            {Number(
                              bookingData?.data[0]?.addon_total_price
                                ? parseFloat(bookingData?.data[0]?.addon_total_price) + parseFloat(bookingData?.data[0]?.final_price)
                                : bookingData?.data[0]?.final_price
                            ).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                          </span>
                        </h3>
                        <p>{formatDateFormat(bookingData?.data[0]?.created_at)}</p>
                        <Link to="/">View Invoice</Link>
                      </li>
                      <li>
                        <div className="crcl2">
                          <span></span>
                        </div>
                        <h3>Package Completed</h3>
                        <p>{formatDateFormat(bookingData?.data[0]?.last_booking_date)}</p>
                      </li>
                    </>
                  ) : bookingData?.data[0]?.bookings_status_id === 1 ? (
                    <li>
                      <div className="crcl">
                        <span></span>
                      </div>
                      <h3>Booking</h3>
                      <p>{formatDateFormat(bookingData?.data[0]?.created_at)}</p>
                      <Link to="/">View Invoice</Link>
                    </li>
                  ) : bookingData?.data[0]?.bookings_status_id === 4 || bookingData?.data[0]?.bookings_status_id === 5 ? (
                    <>
                      <li>
                        <div className="crcl">
                          <span></span>
                        </div>
                        <h3>Booking</h3>
                        <p>{formatDateFormat(bookingData?.data[0]?.created_at)}</p>
                        <Link to="/">View Invoice</Link>
                      </li>
                      <li>
                        <div className="crcl2">
                          <span></span>
                        </div>
                        <h3>
                          Booking Confirmed{" "}
                          <span>
                            ₹
                            {Number(
                              bookingData?.data[0]?.addon_total_price
                                ? parseFloat(bookingData?.data[0]?.addon_total_price) + parseFloat(bookingData?.data[0]?.final_price)
                                : bookingData?.data[0]?.final_price
                            ).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                          </span>
                        </h3>
                        <p>{formatDateFormat(bookingData?.data[0]?.created_at)}</p>
                      </li>
                      {bookingData?.bookingCancellation?.map(item => (
                        <>
                          {item?.cancellation_type === "partial" ? (
                            <>
                              <li>
                                <div className="crcl3">
                                  <span></span>
                                </div>
                                <h3>
                                  Partial Cancelled{" "}
                                  <span>₹{Number(item?.refund_amount).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                                </h3>
                                <p>{formatDateFormat(item?.created_at)}</p>
                              </li>
                              {item?.status === 1 ? (
                                <li>
                                  <div className="crcl">
                                    <span></span>
                                  </div>
                                  <h3>
                                    Refund Processed{" "}
                                    <span>₹{Number(item?.refund_amount).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                                  </h3>
                                  <p>{formatDateFormat(item?.updated_at)}</p>
                                </li>
                              ) : null}
                            </>
                          ) : item?.cancellation_type === "full" ? (
                            <>
                              <li>
                                <div className="crcl3">
                                  <span></span>
                                </div>
                                <h3>
                                  Package Cancelled{" "}
                                  {Number(item?.refund_amount) ? (
                                    <span>₹{Number(item?.refund_amount).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                                  ) : null}
                                </h3>
                                <p>{formatDateFormat(item?.created_at)}</p>
                              </li>
                              {item?.status === 1 ? (
                                <li>
                                  <div className="crcl">
                                    <span></span>
                                  </div>
                                  <h3>
                                    Refund Processed{" "}
                                    <span>₹{Number(item?.refund_amount).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                                  </h3>
                                  <p>{formatDateFormat(item?.updated_at)}</p>
                                </li>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ))}

                      {/* <li>
                        <div className="crcl3">
                          <span></span>
                        </div>
                        <h3>
                          Partial Cancelled{" "}
                          <span>₹{Number(priceDetails?.totalPartialCalAmt).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                        </h3>
                        <p>{formatDateFormat(priceDetails?.latestPartialCancelDate)}</p>
                      </li> */}
                    </>
                  ) : (
                    <li>
                      <div className="crcl">
                        <span></span>
                      </div>
                      <h3>Booking</h3>
                      <p>{formatDateFormat(bookingData?.data[0]?.created_at)}</p>
                      <Link to="/">View Invoice</Link>
                    </li>
                  )}
                  {/* <li>
                    <div className="crcl">
                      <span></span>
                    </div>
                    <h3>Booking</h3>
                    <p>{formatDateFormat(bookingData?.data[0]?.created_at)}</p>
                    <Link to="/">View Invoice</Link>
                  </li> */}
                  {/* <li>
                    <div className="crcl2">
                      <span></span>
                    </div>
                    <h3>
                      Booking Confirmed <span>₹97,800</span>
                    </h3>
                    <p>14 Oct,2023</p>
                  </li>
                  <li>
                    <div className="crcl">
                      <span></span>
                    </div>
                    <h3>
                      Partial Cancelled<span>₹20,800</span>
                    </h3>
                    <p>20 Oct,2023</p>
                  </li>
                  <li>
                    <div className="crcl">
                      <span></span>
                    </div>
                    <h3>
                      Refund Processed <span>₹207,800</span>
                    </h3>
                    <p>25 Oct,2023</p>
                  </li> */}
                </ul>
              </div>

              <div className="itternaryBooking">
                <div className="supportNumber">
                  <h5>support Number</h5>
                  <a href="javascirpt:void(0);">
                    {/* <Link to="tel:02268727374"> */}
                    <h4>+91 33 2358 1262</h4>
                  </a>
                </div>
              </div>

              <div className="overviewDiv itternaryBooking">
                <h3>Cancellation Policy</h3>
                <div className="inclusDiv">
                  <ul>
                    {bookingData?.package?.cancellation_policy.map((policy, index) => {
                      let [from, to, percentage] = policy.split("-");
                      return (
                        <li key={index}>
                          {index === 0
                            ? `${from} or more days before departure: ${percentage}% of total package cost`
                            : `Between ${from} to ${to} days before departure: ${percentage}% of total package cost`}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              {/* <div className="itternaryBooking">
                <h3>Cancellation Policy</h3>
                <div className="ConditionAccordion">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                    centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release
                    of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including
                    versions of Lorem Ipsum.
                  </p>
                  <p>
                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of
                    using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it
                    look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a
                    search for 'lorem ipsum' will uncover many web sites still in their infancy.
                  </p>
                </div>
              </div> */}

              {/* {bookingData?.data[0].bookings_status_id === 2 || bookingData?.data[0].bookings_status_id === 5 ? (
                <>
                  <div className="bookingBtns">
                    <button onClick={() => navigate(`/booking/${bookingId}/partial-cancellation`, {state: {from: "agent"}})}>Partial Cancel</button>
                    <button className="cancelBook" onClick={() => navigate(`/booking/${bookingId}/cancellation`, {state: {from: "agent"}})}>
                      Cancel
                    </button>
                  </div>
                </>
              ) : null} */}

              {/* {bookingData?.data[0]?.bookings_status_id === 3 && showReportButton && !bookingData?.data[0]?.report ? (
                <>
                  <div className="bookingBtns">
                    <button className="reportBook" onClick={() => setShowPopup(true)}>
                      Report
                    </button>
                  </div>
                </>
              ) : null} */}

              {showPopup && (
                <div className="popup addPopup">
                  <div className="sliderflex">
                    <div className="popInner2 reportFrm">
                      <h2>Report</h2>

                      <ul>
                        <li className="fullLIst">
                          <textarea
                            placeholder="Enter your complain here"
                            maxLength={500}
                            value={reportIssueText}
                            onChange={e => setReportIssueText(e.target.value)}></textarea>
                          <span className="wordCount">{reportIssueText ? reportIssueText?.length : 0}/500</span>
                        </li>

                        <li className="fullLIst">
                          <h4>Images</h4>

                          <div
                            className={`dragUpload ${dragging ? "dragging" : ""}`}
                            onDragOver={e => handleDragEnter(e)}
                            onDragLeave={handleDragLeave}
                            onDrop={e => handleDrop(e)}>
                            {reportIssueImages.length > 0 && (
                              <div className="uploadedImagesContainer">
                                {reportIssueImages.map((image, index) => (
                                  <div key={index} className="uploadedImage">
                                    <img src={image.preview} alt={`Uploaded ${index + 1}`} />
                                    <button onClick={() => handleRemoveReportImage(index)}></button>
                                  </div>
                                ))}
                              </div>
                            )}

                            {reportIssueImages.length === 0 && (
                              <div className="dragImg">
                                <img src={dragIcon} alt="" />
                                Drag and drop here
                              </div>
                            )}
                          </div>

                          <p className="dragtxt2">
                            Images size should be below 1mb for each. Allowed file extensions are (png, jpeg, and jpg). Maximum 5 images are allowed.
                          </p>
                        </li>

                        <li className="fullLIst sbmt">
                          {reportBtnDis ? (
                            <button disabled className="addBtn">
                              Please wait...
                            </button>
                          ) : (
                            <button onClick={() => handleSubmitReport()} className="addBtn">
                              Submit
                            </button>
                          )}
                        </li>
                      </ul>

                      <button className="closePopupBtn2" onClick={() => setShowPopup(false)}>
                        <img src={crossIcon2} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {showRatePopup && (
                <div className="popup addPopup">
                  <div className="sliderflex">
                    <div className="popInner2 reportFrm">
                      <h2>Rate Us!</h2>

                      <ul>
                        <li className="fullLIst">
                          <StarRatings
                            rating={rating} // Initial rating value
                            starRatedColor="#F8B84E" // Color of the filled stars
                            numberOfStars={5} // Total number of stars
                            name="rating" // Unique name for the component
                            starDimension="30px"
                            starSpacing="2px"
                            changeRating={rating => setRating(rating)}
                          />
                        </li>
                        <li className="fullLIst">
                          <textarea
                            placeholder="Enter your feedback here"
                            maxLength={500}
                            value={feedback}
                            onChange={e => setFeedback(e.target.value)}></textarea>
                          <span className="wordCount">{feedback ? feedback?.length : 0}/500</span>
                        </li>
                        <li className="fullLIst sbmt">
                          {reportBtnDis ? (
                            <button disabled className="addBtn">
                              Please wait...
                            </button>
                          ) : (
                            <button onClick={() => handleSubmitFeedback()} className="addBtn">
                              Submit
                            </button>
                          )}
                        </li>
                      </ul>

                      <button className="closePopupBtn2" onClick={() => setShowRatePopup(false)}>
                        <img src={crossIcon2} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AgentUpcomingTourDetails;
