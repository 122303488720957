import React, {useEffect} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {useLocation, useNavigate} from "react-router";
import {Helmet} from "react-helmet";

import GreenCircleTickIcon from "../assets/img/green-circle-tick-icon.svg";

const AgentThankYou = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 3000);
  }, []);

  return (
    <div className="main">
      <Header />
      <div className="innerDiv">
        <div className="UserAfterLogin">
          <div className="container">
            <div className="CongratulationsBox">
              <img src={GreenCircleTickIcon} alt="" />
              <h3>Thank You!</h3>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AgentThankYou;
