import React, {Component, useState, useRef, useEffect} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {Link, useNavigate} from "react-router-dom";

const blog = require("./../assets/img/blog1.png");

const BlogDetails = () => {
  return ( 
    <div className="main">
      <Header />

      <div className="innerDiv">
        <div className="pplrDstn offersDiv">
          <div className="mainDiv">
            <div className="blogDetails">
              <img src={blog} alt="" className="mainBlogImg" />
              <div className="blogInner">
                <h3>Kenya vs Tanzania Safari: The Better African Safari Experience</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                  the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting, remaining essentially unchanged
                </p>
                <p>
                  It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                  software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <ul>
                  <li>"Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li>"Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li>"Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                  <li>"Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
                </ul>
                <h4>
                  “Sed viverra ipsum nunc aliquet bibendum enim facilisis gravida. Diam phasellus vestibulum lorem sed risus ultricies. Magna sit amet purus
                  gravida quis blandit. Arcu cursus vitae congue mauris.“
                </h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                  the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting, remaining essentially unchanged
                </p>
                <p>
                  It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                  software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <img src={blog} alt="" />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
                  the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting, remaining essentially unchanged
                </p>
                <p>
                  It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                  software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetails;
