import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import {getCmsPageDetailsApi} from "../services/ApiService";
import {Helmet} from "react-helmet";

const TermsAndUse = () => {
  const [allDetails, setAllDetails] = useState();
  const getCmsPageDetails = async () => {
    try {
      const fd = new FormData();
      fd.append("slug", "terms-and-use");
      let response = await getCmsPageDetailsApi(fd);
      if (response) {
        setAllDetails(response.data.page);
        if (window.location.hash === "#cancellation_policy") {
          const waitForElement = async () => {
            let element = null;
            while (!element) {
              element = document.getElementById("cancellation_policy");
              if (!element) {
                await new Promise(resolve => setTimeout(resolve, 500)); // Adjust timeout as needed
              }
            }
            return element;
          };

          // Call waitForElement and scrollIntoView once element is available
          waitForElement().then(element => {
            // console.log(element); // Log the element to verify
            if (element) {
              element.scrollIntoView({behavior: "smooth"});
            }
          });
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCmsPageDetails();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Terms And Use`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Terms And Use page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="cmsDiv" dangerouslySetInnerHTML={{__html: allDetails?.content}}></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndUse;
