import React, {Component, useState, useRef, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";
import {
  addPackageApi,
  editVendorInformationApi,
  getCityListApi,
  getDetailsFromPincodeApi,
  getStatesListApi,
  getThemesListApi,
  getTripListApi,
  getTypeOfTourPackagesListApi,
  typeOfVendorListApi,
  vendorDetailsApi,
} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import {Form, Button, Dropdown, Tabs, Tab, Modal} from "react-bootstrap";

import panImg from "../assets/img/pan-image.png";
import EmptyImg from "../assets/img/empty-img.svg";
import CancelChequeImg from "../assets/img/cancel-cheque-img.png";
import AuthoriedLetterImg from "../assets/img/Authoried-letter-img.png";
import MinusIcon from "../assets/img/minus-icon3.svg";
import PlusIcon from "../assets/img/plus-icon3.svg";
import OrangeCross from "../assets/img/orange-cross.svg";
import {useUserContext} from "../context/UserContext";
import {IMAGE_URL} from "../utils/commonUrls";
import LoadingPage from "../component/loadingPage";

const arrow = require("./../assets/img/left-arrow.svg").default;

const VendorEditProfile = () => {
  const navigate = useNavigate();
  const {userData, setUserData} = useUserContext();

  const [organizationTypeArray] = useState([
    {id: 1, name: "Proprietorship"},
    {id: 2, name: "Partnership"},
    {id: 3, name: "Private Limited"},
  ]);

  const [key, setKey] = useState("tab1");
  const [vendorDetail, setVendorDetail] = useState();

  const [vendorFormData, setVendorFormData] = useState({
    typeOfVendor: "",
    gstNumber: "",
    gstRate: "",
    organizationName: "",
    organizationTypeName: "",
    prosName: "",
    prosPhoneNumber: "",
    prosPanNumber: "",
    prosAddress: "",
    address: "",
    pincode: "",
    country: "",
    state: "",
    city: "",
    prosPhoto: "",
  });
  const [organizationType, setOrganizationType] = useState(2);
  const [gstDetailsAvail, setGstDetailsAvail] = useState("yes");
  const [stateList, setStateList] = useState([]);
  const [broadLocationPrevArray, setBroadLocationPrevArray] = useState([]);
  const [broadLocationArray, setBroadLocationArray] = useState([]);
  const [partnersArray, setPartnersArray] = useState([{name: null, phoneNumber: null, panNumber: null, address: null, photo: null}]);
  const [privateLimitedArray, setPrivateLimitedArray] = useState([{name: null, phoneNumber: null, panNumber: null, address: null, photo: null}]);

  const [validationErrors, setValidationErrors] = useState({
    fullName: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
    panPhoto: "",
    panNumber: "",
  });
  const [loading, setLoading] = useState(false);
  const [bankButtonDis, setBankButtonDis] = useState(false);
  const [profileCompletion, setProfileCompletion] = useState(0);
  const [typeOfVendorList, setTypeOfVendorList] = useState([]);
  const [pincodeError, setPincodeError] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getStateList = async () => {
    try {
      let response = await getStatesListApi();
      if (response) {
        if (response.data.res === true) {
          setStateList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getTypeOfVendorList = async () => {
    try {
      let response = await typeOfVendorListApi();
      if (response) {
        if (response.data.res === true) {
          setTypeOfVendorList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const getVendorDetails = async () => {
    setScreenLoading(true);
    try {
      let response = await vendorDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setVendorDetail(response.data);

          let tempCompleteValue = 0;
          if (response.data.data) {
            let tempUserData = JSON.parse(localStorage.getItem("WMTUserData"));
            if (response.data.data.fullname) {
              tempUserData.data.fullname = response.data.data.fullname;
              localStorage.setItem("WMTUserData", JSON.stringify(tempUserData));
              setUserData(tempUserData);
            }

            const countFilledFields = () => {
              let filledFields = 0;
              if (response.data?.data?.name) filledFields++;
              if (response.data?.user?.email) filledFields++;
              if (response.data?.user?.mobile) filledFields++;
              // if (response.data?.user?.email || userData?.data?.email) filledFields++;
              // if (response.data?.user?.mobile || userData?.data?.mobile) filledFields++;
              if (response.data?.data?.address) filledFields++;
              if (response.data?.data?.zip_code) filledFields++;
              if (response.data?.data?.pan_number) filledFields++;
              if (response.data?.data?.pan_card_file) filledFields++;
              if (response.data.data.contact_person_name) filledFields++;
              if (response.data.data.bank_account_number) filledFields++;
              if (response.data.data.ifsc_code) filledFields++;
              if (response.data.data.bank_name) filledFields++;
              if (response.data.data.branch_name) filledFields++;
              if (response.data.data.cancelled_cheque) filledFields++;
              // if (response.data.data.authorization_letter) filledFields++;
              if (response.data.data.have_gst === 1) {
                if (response.data.data.gst_rate) {
                  filledFields++;
                }
              }
              return filledFields;
            };

            const calculateCompletionPercentage = () => {
              const totalFields = response.data.data.have_gst === 1 ? 14 : 13; // Total number of fields
              const filledFields = countFilledFields();
              return (filledFields / totalFields) * 100;
            };

            setProfileCompletion(Math.floor(calculateCompletionPercentage()));

            setVendorFormData(prev => ({
              ...prev,
              typeOfVendor: response.data.data.type_of_vendor,
              gstNumber: response.data.data.gst_number,
              gstRate: response.data.data.gst_rate,
              organizationName: response.data.data.organization_name,
              organizationTypeName: response.data.organization_type,
              address: response.data.data.address,
              pincode: response.data.data.zip_code,
              country: response.data.data.country,
              state: response.data.data.state,
              city: response.data.data.city,
            }));

            setGstDetailsAvail(parseInt(response.data.data.have_gst) === 1 ? "yes" : "no");
            setOrganizationType(response.data.data.organization_type);
            if (response.data.data.organization_type === 1) {
              setVendorFormData(prev => ({
                ...prev,
                prosName: response.data.data.details[0].name,
                prosPhoneNumber: response.data.data.details[0].phone_number,
                prosPanNumber: response.data.data.details[0].pan_number,
                prosAddress: response.data.data.details[0].address,
                prosPhoto: {name: response.data.data.details[0].photo.split("proprietor_photo_")[1]},
              }));
            }

            const fetchAndAppendImage = async (imageUrl, imageName) => {
              try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const file = new File([blob], imageName, {type: "image/*"});
                return file;
              } catch (error) {
                console.error("Error fetching image:", error);
              }
            };

            if (response.data.data.organization_type === 2) {
              if (response.data.data.details?.length > 0) {
                const updatedArray = await Promise.all(
                  response.data.data.details.map(async partItem => {
                    const photo = partItem?.photo ? await fetchAndAppendImage(IMAGE_URL + partItem?.photo, partItem?.photo?.split("partner_photo_")[1]) : null;
                    return {
                      id: partItem?.id,
                      name: partItem?.name,
                      phoneNumber: partItem?.phone_number,
                      panNumber: partItem?.pan_number,
                      address: partItem?.address,
                      photo: photo, // Updated photo object with blob and file info
                    };
                  })
                );
                setPartnersArray(updatedArray);
                // setPartnersArray(prevPartnersArray =>
                //   response.data.data.details?.map(partItem => ({
                //     id: partItem?.id,
                //     name: partItem?.name,
                //     phoneNumber: partItem?.phone_number,
                //     panNumber: partItem?.pan_number,
                //     address: partItem?.address,
                //     photo: {name: partItem?.photo?.split("partner_photo_")[1]},
                //   }))
                // );
              } else {
                setPartnersArray([{name: null, phoneNumber: null, panNumber: null, address: null, photo: null}]);
              }
            }

            if (response.data.data.organization_type === 3) {
              if (response.data.data.details?.length > 0) {
                const updatedArray = await Promise.all(
                  response.data.data.details?.map(async partItem => {
                    const photo = partItem?.photo ? await fetchAndAppendImage(IMAGE_URL + partItem?.photo, partItem?.photo?.split("director_photo_")[1]) : null;
                    return {
                      id: partItem?.id,
                      name: partItem?.name,
                      phoneNumber: partItem?.phone_number,
                      panNumber: partItem?.pan_number,
                      address: partItem?.address,
                      photo: photo,
                    };
                  })
                );
                setPrivateLimitedArray(updatedArray);
                // setPrivateLimitedArray(prevPrivateLimitedArray =>
                //   response.data.data.details.map(partItem => ({
                //     id: partItem?.id,
                //     name: partItem?.name,
                //     phoneNumber: partItem?.phone_number,
                //     panNumber: partItem?.pan_number,
                //     address: partItem?.address,
                //     photo: {name: partItem?.photo?.split("director_photo_")[1]},
                //   }))
                // );
              } else {
                setPrivateLimitedArray([{name: null, phoneNumber: null, panNumber: null, address: null, photo: null}]);
              }
            }
          } else {
            setVendorFormData(prev => ({...prev, mobileNumber: userData?.data.mobile, emailAddress: userData?.data.email}));
          }

          if (response.data.vendorBroadLocations?.length > 0) {
            setBroadLocationPrevArray(response.data.vendorBroadLocations);
          } else {
            setBroadLocationPrevArray([...broadLocationArray, {state: null, city: null}]);
          }
        } else {
        }
        setScreenLoading(false);
      }
    } catch (error) {
      setScreenLoading(false);
    }
  };

  const handleOrganizationTypeChange = event => {
    setOrganizationType(parseInt(event.target.value));
    setVendorFormData(prev => ({
      ...prev,
      prosName: "",
      prosPhoneNumber: "",
      prosPanNumber: "",
      prosAddress: "",
    }));

    setPartnersArray([{name: null, phoneNumber: null, panNumber: null, address: null, photo: null}]);

    setPrivateLimitedArray([{name: null, phoneNumber: null, panNumber: null, address: null, photo: null}]);
  };

  const handleInputChange = (field, value) => {
    if (field === "prosPhoto") {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (value && !validImageTypes.includes(value.type)) {
        NotificationManager.warning("Please upload a valid image file (JPEG or PNG).");
        return;
      }
    }
    setVendorFormData(prevData => ({...prevData, [field]: value}));
  };

  const handleRemoveBroadLocation = (item, index) => {
    let tempArray = [...broadLocationPrevArray];
    tempArray.splice(index, 1);
    setBroadLocationPrevArray(tempArray);
  };

  const handleStateChange = async (event, index) => {
    const {value} = event.target;
    let tempArray = [...broadLocationArray];
    tempArray[index].state = value;
    setBroadLocationArray(tempArray);

    try {
      const formData = new FormData();
      if (value) {
        formData.append("state[]", value);
      }
      let response = await getCityListApi(formData);
      if (response && response.data.res === true) {
        tempArray[index].cities = response.data.data;
        setBroadLocationArray([...tempArray]);
      }
    } catch (error) {
      console.error("Error fetching city list:", error);
    }
  };

  const handleCityChange = (event, index) => {
    const {value} = event.target;
    let tempArray = [...broadLocationArray];
    tempArray[index].city = value;
    setBroadLocationArray(tempArray);
  };

  const handlePincodeChange = async value => {
    try {
      let response = await getDetailsFromPincodeApi(value);
      if (response && response.data[0].Status === "Success") {
        setVendorFormData(prevData => ({
          ...prevData,
          country: response.data[0].PostOffice[0].Country,
          state: response.data[0].PostOffice[0].State,
          city: response.data[0].PostOffice[0].District,
        }));
        setPincodeError(false);
      } else {
        setVendorFormData(prevData => ({...prevData, country: "", state: "", city: ""}));
        setPincodeError(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addPartner = () => {
    setPartnersArray([...partnersArray, {name: null, phoneNumber: null, panNumber: null, address: null, photo: null}]);
  };

  const deletePartner = index => {
    const updatedPartners = [...partnersArray];
    updatedPartners.splice(index, 1);
    setPartnersArray(updatedPartners);
  };

  const addPrivateLimited = () => {
    setPrivateLimitedArray([...privateLimitedArray, {name: null, phoneNumber: null, panNumber: null, address: null, photo: null}]);
  };

  const deletePrivateLimited = index => {
    const updatedPartners = [...privateLimitedArray];
    updatedPartners.splice(index, 1);
    setPrivateLimitedArray(updatedPartners);
  };

  const handlePartnerChange = (index, field, value) => {
    if (field === "photo") {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (value && !validImageTypes.includes(value.type)) {
        NotificationManager.warning("Please upload a valid image file (JPEG or PNG).");
        return;
      }
    }
    const updatedPartners = [...partnersArray];
    updatedPartners[index][field] = value;
    console.log(updatedPartners);
    setPartnersArray(updatedPartners);
  };

  const handleDirectorChange = (index, field, value) => {
    if (field === "photo") {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (value && !validImageTypes.includes(value.type)) {
        NotificationManager.warning("Please upload a valid image file (JPEG or PNG).");
        return;
      }
    }
    const updatedDirectors = [...privateLimitedArray];
    updatedDirectors[index][field] = value;
    setPrivateLimitedArray(updatedDirectors);
  };

  const validationForm = () => {
    let isValid = true;
    // if (!vendorFormData?.typeOfVendor) {
    //   NotificationManager.warning("Please select vendor type.");
    //   isValid = false;
    // } else
    if (!vendorFormData?.pincode) {
      NotificationManager.warning("Please enter pincode.");
      isValid = false;
    } else if (vendorFormData?.pincode && !vendorFormData.country && !vendorFormData.state && !vendorFormData.city) {
      NotificationManager.warning("Please enter valid pincode.");
      isValid = false;
      // } else if (!broadLocationArray.every(location => location.state && location.city)) {
      //   NotificationManager.warning("Please select broad location details for all locations.");
      //   isValid = false;
    } else if (gstDetailsAvail === "yes" && !vendorFormData?.gstNumber) {
      NotificationManager.warning("Please enter GST number.");
      isValid = false;
    } else if (gstDetailsAvail === "yes" && (!vendorFormData?.gstRate || isNaN(vendorFormData.gstRate))) {
      NotificationManager.warning("Please enter a valid GST rate.");
      isValid = false;
    } else if (!vendorFormData?.organizationName) {
      // } else if (gstDetailsAvail === "no" && organizationType !== 1 && !vendorFormData?.organizationName) {
      NotificationManager.warning("Please enter organization name.");
      isValid = false;
    } else if (
      organizationType === 1 &&
      !vendorFormData?.prosName &&
      !vendorFormData?.prosPhoneNumber &&
      !vendorFormData?.prosPanNumber &&
      !vendorFormData?.prosAddress &&
      !vendorFormData?.prosPhoto
    ) {
      NotificationManager.warning("Please enter all proprietor details.");
      isValid = false;
    } else if (organizationType === 1 && !/^\d{10}$/.test(vendorFormData.prosPhoneNumber)) {
      NotificationManager.warning("Please enter a valid 10-digit Mobile Number.");
      isValid = false;
    } else if (
      organizationType === 2 &&
      partnersArray.some(
        partner => !partner.name || !partner.phoneNumber || !partner.panNumber || !partner.address || !/^\d{10}$/.test(partner.phoneNumber) || !partner.photo
      )
    ) {
      NotificationManager.warning("Please enter all partner details and valid phone numbers.");
      isValid = false;
    } else if (
      organizationType === 3 &&
      privateLimitedArray.some(
        director =>
          !director.name || !director.phoneNumber || !director.panNumber || !director.address || !/^\d{10}$/.test(director.phoneNumber) || !director.photo
      )
    ) {
      NotificationManager.warning("Please enter all directors details and valid phone numbers.");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (validationForm()) {
      setLoading(true);
      try {
        let formData = new FormData();
        // formData.append("type_of_vendor", vendorFormData.typeOfVendor);
        formData.append("zip_code", vendorFormData.pincode);
        formData.append("country", vendorFormData.country);
        formData.append("state", vendorFormData.state);
        formData.append("city", vendorFormData.city);
        formData.append("address", vendorFormData.address);
        // const mergedBroadLocations = [...broadLocationPrevArray, ...broadLocationArray];
        // mergedBroadLocations.forEach((item, index) => {
        //   formData.append(`vendor_broad_location[${index}][state]`, item.state);
        //   formData.append(`vendor_broad_location[${index}][city]`, item.city);
        // });
        if (gstDetailsAvail === "yes") {
          formData.append("gst_number", vendorFormData?.gstNumber);
          formData.append("gst_rate", vendorFormData?.gstRate);
        }
        formData.append("organization_name", vendorFormData.organizationName);
        // formData.append("organization_name", organizationType !== 1 ? vendorFormData.organizationName : "");
        formData.append("organization_type", organizationType.toString());
        if (organizationType === 1) {
          formData.append("proprietor_name", vendorFormData.prosName);
          formData.append("proprietor_phone_number", vendorFormData.prosPhoneNumber);
          formData.append("proprietor_pan_number", vendorFormData.prosPanNumber);
          formData.append("proprietor_address", vendorFormData.prosAddress);
          console.log(vendorFormData.prosPhoto);
          if (vendorFormData.prosPhoto.type) {
            formData.append("proprietor_photo", vendorFormData.prosPhoto);
          }
        } else if (organizationType === 2) {
          formData.append("proprietor_name", "");
          formData.append("proprietor_phone_number", "");
          formData.append("proprietor_pan_number", "");
          formData.append("proprietor_address", "");
          formData.append("proprietor_photo", "");

          formData.append("no_of_partners", partnersArray?.length);
          partnersArray?.map((item, index) => {
            formData.append(`vendor_partners[${index}][id]`, item.id ? item.id : "");
            formData.append(`vendor_partners[${index}][name]`, item.name);
            formData.append(`vendor_partners[${index}][phone_number]`, item.phoneNumber);
            formData.append(`vendor_partners[${index}][pan_number]`, item.panNumber);
            formData.append(`vendor_partners[${index}][address]`, item.address);
            formData.append(`vendor_partners[${index}][photo]`, item.photo);
          });
        } else if (organizationType === 3) {
          formData.append("proprietor_name", "");
          formData.append("proprietor_phone_number", "");
          formData.append("proprietor_pan_number", "");
          formData.append("proprietor_address", "");
          formData.append("proprietor_photo", "");

          formData.append("no_of_directors", privateLimitedArray?.length);
          privateLimitedArray?.map((item, index) => {
            formData.append(`vendor_directors[${index}][name]`, item.name);
            formData.append(`vendor_directors[${index}][phone_number]`, item.phoneNumber);
            formData.append(`vendor_directors[${index}][pan_number]`, item.panNumber);
            formData.append(`vendor_directors[${index}][address]`, item.address);
            formData.append(`vendor_directors[${index}][photo]`, item.photo);
          });
        } else {
        }

        formData.append("status", 1);
        // Api call
        let response = await editVendorInformationApi(formData);
        if (response) {
          if (response.data.res === true) {
            setBroadLocationArray([]);
            NotificationManager.success(response.data.msg);
            getVendorDetails();
          } else {
            NotificationManager.warning(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        NotificationManager.error("Something went wrong, please try again.");
      }
    }
  };

  useEffect(() => {
    getVendorDetails();
    getStateList();
    // getTypeOfVendorList();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Account Settings`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Account settings page" />
      </Helmet>
      <VenderHeader />
      <div className="venderInnerDiv">
        <VenderSidebar />
        <div className="venderRight">
          {screenLoading && <LoadingPage />}
          <div className="hdrSec">
            <h2>
              <Button className="btnBack" variant="link" onClick={() => navigate("/business/profile")}>
                <img src={arrow} alt="" />
              </Button>
              Account Settings
            </h2>
            <div className="top-progress-sec">
              <h4>
                Profile Status: <span>{profileCompletion}%</span>
              </h4>
              <div className="progressbar">
                <div className="progressbar-inner" style={{width: `${profileCompletion}%`}}></div>
              </div>
            </div>
          </div>
          <div className="venderMain venderMain2">
            <div className="seller-accounts">
              <div className="normal-accrounts-sec">
                <h3>Edit Vendor Profile</h3>
                <ul className="row edit-profile-form-list edit-profile-form-list2">
                  {/* <li className="col-md-4">
                    <label>Type of Vendor</label>
                    <select className="form-select" value={vendorFormData?.typeOfVendor} onChange={e => handleInputChange("typeOfVendor", e.target.value)}>
                      <option selected hidden>
                        Type of Vendor
                      </option>
                      {typeOfVendorList?.map((item, index) => (
                        <option key={index} value={item?.vendor_type}>
                          {item?.vendor_type}
                        </option>
                      ))}
                    </select>
                  </li> */}

                  {gstDetailsAvail === "yes" ? (
                    <li className="col-md-4">
                      <label>GST Number</label>
                      <input
                        type="text"
                        name=""
                        readOnly
                        className="form-control"
                        value={vendorFormData?.gstNumber}
                        onChange={e => handleInputChange("gstNumber", e.target.value)}
                      />
                    </li>
                  ) : null}

                  {gstDetailsAvail === "yes" ? (
                    <li className="col-md-4">
                      <label>GST Rate</label>
                      <input
                        type="number"
                        name=""
                        className="form-control"
                        value={vendorFormData?.gstRate}
                        onChange={e => handleInputChange("gstRate", e.target.value)}
                      />
                    </li>
                  ) : null}

                  {organizationType === 1 || organizationType === 2 || organizationType === 3 ? (
                    <>
                      <li className="col-md-4">
                        <label>Organization Name</label>
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          value={vendorFormData?.organizationName}
                          onChange={e => handleInputChange("organizationName", e.target.value)}
                        />
                      </li>
                      <li className="col-md-4">
                        <label>Address</label>
                        <input
                          type="text"
                          name=""
                          value={vendorFormData.address}
                          className="form-control"
                          onChange={e => handleInputChange("address", e.target.value)}
                        />
                      </li>
                      <li className="col-md-4">
                        <label>Pincode</label>
                        <input
                          type="text"
                          name=""
                          value={vendorFormData.pincode}
                          className="form-control"
                          onChange={e => {
                            handleInputChange("pincode", e.target.value);
                            handlePincodeChange(e.target.value);
                          }}
                        />
                        {pincodeError ? <span className="validationErrorMsg">Please enter valid pincode.</span> : null}
                      </li>
                      <li className="col-md-4">
                        <label>Country</label>
                        <input type="text" name="" value={vendorFormData.country} className="form-control" readOnly />
                      </li>
                      <li className="col-md-4">
                        <label>State</label>
                        <input type="text" name="" value={vendorFormData.state} className="form-control" readOnly />
                      </li>
                      <li className="col-md-4">
                        <label>City</label>
                        <input type="text" name="" value={vendorFormData.city} className="form-control" readOnly />
                      </li>
                    </>
                  ) : null}

                  {gstDetailsAvail === "no" ? (
                    <li className="col-md-4">
                      <label>Organization Type</label>
                      {/* <select className="form-select" value={organizationType} onChange={e => handleOrganizationTypeChange(e)}>
                        {organizationTypeArray?.map((item, index) => (
                          <option key={index} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </select> */}
                      <input type="text" name="" value={vendorFormData?.organizationTypeName} className="form-control" readOnly />
                    </li>
                  ) : null}

                  {/* {organizationType === 2 || organizationType === 3 ? (
                    <li className="col-md-4">
                      <div className="count-sec">
                        <span className="LabelSpan">{organizationType === 2 ? "Number of Partners" : organizationType === 3 ? "Number of Directors" : ""}</span>
                        <div className="Count-Box">
                          <img src={MinusIcon} alt="" />
                          <img src={PlusIcon} alt="" />
                          <span className="CountSpan">02</span>
                        </div>
                      </div>
                    </li>
                  ) : null} */}
                </ul>

                {organizationType === 1 ? (
                  <div className="EditFormBox">
                    <h5>Add Proprietor Details</h5>
                    <div className="gray-box">
                      <ul className="row VendorRegistrationList">
                        <li className="col-md-12">
                          <input
                            type="text"
                            placeholder="Name"
                            className="form-control"
                            value={vendorFormData?.prosName}
                            onChange={e => handleInputChange("prosName", e.target.value)}
                          />
                        </li>
                        <li className="col-md-6">
                          <input
                            type="tel"
                            placeholder="Phone Number"
                            className="form-control"
                            value={vendorFormData?.prosPhoneNumber}
                            onChange={e => handleInputChange("prosPhoneNumber", e.target.value)}
                          />
                        </li>
                        <li className="col-md-6">
                          <input
                            type="text"
                            placeholder="PAN Number"
                            className="form-control"
                            readOnly={vendorFormData?.prosPanNumber ? true : false}
                            value={vendorFormData?.prosPanNumber}
                            onChange={e => handleInputChange("prosPanNumber", e.target.value)}
                          />
                        </li>
                        <li className="col-md-6">
                          <input
                            type="text"
                            placeholder="Address"
                            className="form-control"
                            value={vendorFormData?.prosAddress}
                            onChange={e => handleInputChange("prosAddress", e.target.value)}
                          />
                        </li>
                        <li className="col-md-6">
                          <div className="upload-file-box">
                            <input type="file" className="input-file" accept="image/*" onChange={e => handleInputChange("prosPhoto", e.target.files[0])} />
                            <input
                              type="text"
                              name=""
                              value={
                                vendorFormData?.prosPhoto?.name?.length > 23
                                  ? vendorFormData?.prosPhoto?.name?.slice(0, 23) + "..."
                                  : vendorFormData?.prosPhoto?.name
                              }
                              placeholder="Upload Photo"
                              class="form-control disabled-form-control"
                              disabled
                            />
                            <button variant="primary" className="upload-btn">
                              Upload
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : null}

                {organizationType === 2 ? (
                  <>
                    <ul className="row edit-profile-form-list edit-profile-form-list2">
                      <li className="col-md-12">
                        <div className="count-sec">
                          <span className="LabelSpan">Number of Partners</span>
                          <div className="Count-Box">
                            <img src={MinusIcon} alt="" onClick={() => deletePartner(partnersArray.length - 1)} />
                            <img src={PlusIcon} alt="" onClick={() => addPartner()} />
                            <span className="CountSpan">{partnersArray?.length > 10 ? partnersArray?.length : "0" + partnersArray?.length}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="EditFormBox">
                      <h5>Add Partner Details</h5>
                      {partnersArray?.map((item, index) => (
                        <div className="gray-box" key={index}>
                          <ul className="row VendorRegistrationList">
                            <li className="col-md-12">
                              <h5>Partner {index + 1}</h5>
                            </li>
                            <li className="col-md-12">
                              <input
                                type="text"
                                placeholder="Name"
                                className="form-control"
                                value={item.name || ""}
                                onChange={e => handlePartnerChange(index, "name", e.target.value)}
                              />
                            </li>
                            <li className="col-md-6">
                              <input
                                type="tel"
                                placeholder="Phone Number"
                                className="form-control"
                                value={item.phoneNumber || ""}
                                onChange={e => handlePartnerChange(index, "phoneNumber", e.target.value)}
                              />
                            </li>
                            <li className="col-md-6">
                              <input
                                type="text"
                                placeholder="PAN Number"
                                className="form-control"
                                // readOnly={item.panNumber ? true : false}
                                value={item.panNumber || ""}
                                onChange={e => handlePartnerChange(index, "panNumber", e.target.value)}
                              />
                            </li>
                            <li className="col-md-6">
                              <input
                                type="text"
                                placeholder="Address"
                                className="form-control"
                                value={item.address || ""}
                                onChange={e => handlePartnerChange(index, "address", e.target.value)}
                              />
                            </li>
                            <li className="col-md-6">
                              <div className="upload-file-box">
                                <input
                                  type="file"
                                  className="input-file"
                                  accept="image/*"
                                  onChange={e => handlePartnerChange(index, "photo", e.target.files[0])}
                                />
                                <input
                                  type="text"
                                  name=""
                                  value={item?.photo?.name?.length > 23 ? item?.photo?.name?.slice(0, 23) + "..." : item?.photo?.name}
                                  placeholder="Upload Photo"
                                  class="form-control disabled-form-control"
                                  disabled
                                />
                                <button variant="primary" className="upload-btn">
                                  Upload
                                </button>
                              </div>
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}

                {organizationType === 3 ? (
                  <>
                    <ul className="row edit-profile-form-list edit-profile-form-list2">
                      <li className="col-md-12">
                        <div className="count-sec">
                          <span className="LabelSpan">Number of Directors</span>
                          <div className="Count-Box">
                            <img src={MinusIcon} alt="" onClick={() => deletePrivateLimited(privateLimitedArray.length - 1)} />
                            <img src={PlusIcon} alt="" onClick={() => addPrivateLimited()} />
                            <span className="CountSpan">
                              {privateLimitedArray?.length > 10 ? privateLimitedArray?.length : "0" + privateLimitedArray?.length}
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="EditFormBox">
                      <h5>Add Director Details</h5>
                      {privateLimitedArray?.map((item, index) => (
                        <div className="gray-box" key={index}>
                          <ul className="row VendorRegistrationList">
                            <li className="col-md-12">
                              <h5>Director {index + 1}</h5>
                            </li>
                            <li className="col-md-12">
                              <input
                                type="text"
                                placeholder="Name"
                                className="form-control"
                                value={item.name || ""}
                                onChange={e => handleDirectorChange(index, "name", e.target.value)}
                              />
                            </li>
                            <li className="col-md-6">
                              <input
                                type="tel"
                                placeholder="Phone Number"
                                className="form-control"
                                value={item.phoneNumber || ""}
                                onChange={e => handleDirectorChange(index, "phoneNumber", e.target.value)}
                              />
                            </li>
                            <li className="col-md-6">
                              <input
                                type="text"
                                placeholder="PAN Number"
                                className="form-control"
                                // readOnly={item.panNumber ? true : false}
                                value={item.panNumber || ""}
                                onChange={e => handleDirectorChange(index, "panNumber", e.target.value)}
                              />
                            </li>
                            <li className="col-md-6">
                              <input
                                type="text"
                                placeholder="Address"
                                className="form-control"
                                value={item.address || ""}
                                onChange={e => handleDirectorChange(index, "address", e.target.value)}
                              />
                            </li>
                            <li className="col-md-6">
                              <div className="upload-file-box">
                                <input
                                  type="file"
                                  className="input-file"
                                  accept="image/*"
                                  onChange={e => handleDirectorChange(index, "photo", e.target.files[0])}
                                />
                                <input
                                  type="text"
                                  name=""
                                  value={item?.photo?.name?.length > 23 ? item?.photo?.name?.slice(0, 23) + "..." : item?.photo?.name}
                                  placeholder="Upload Photo"
                                  class="form-control disabled-form-control"
                                  disabled
                                />
                                <button variant="primary" className="upload-btn">
                                  Upload
                                </button>
                              </div>
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}

                <div className="EditFormBox">
                  {/* <h5>Broad Locations</h5>
                  <ul className="SelectLocationList">
                    {broadLocationPrevArray?.map((item, index) => (
                      <li key={index}>
                        <div className="OrangeBox">
                          <div className="textDiv">
                            <h4>{item?.state_name}</h4>
                            <p>{item?.city_name}</p>
                          </div>
                          <button className="crossButton" onClick={() => handleRemoveBroadLocation(item, index)}>
                            <img src={OrangeCross} alt="" />
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul> */}
                  {/* <h6>Add New Location</h6> */}
                  <ul className="row VendorRegistrationList">
                    {/* {broadLocationArray?.map((item, index) => (
                      <>
                        <li className="col-md-6">
                          <select className="form-select" value={item.state} onChange={e => handleStateChange(e, index)}>
                            <option>Select States</option>
                            {stateList?.map((stateItem, stateIndex) => (
                              <option key={stateIndex} value={stateItem.id}>
                                {stateItem.name}
                              </option>
                            ))}
                          </select>
                        </li>
                        <li className="col-md-6">
                          <select className="form-select" value={item.city} onChange={e => handleCityChange(e, index)}>
                            <option>Select Cities</option>
                            {item.cities?.map((cityItem, cityIndex) => (
                              <option key={cityIndex} value={cityItem.id}>
                                {cityItem.city}
                              </option>
                            ))}
                          </select>
                        </li>
                      </>
                    ))} */}

                    {/* <li className="col-md-12">
                      <button className="AddAnotherLocationButton" onClick={() => setBroadLocationArray([...broadLocationArray, {state: null, city: null}])}>
                        Add another location
                      </button>
                    </li> */}
                    <li className="col-md-12">
                      {loading ? (
                        <button className="btn btn-primary CmnBtn" disabled>
                          Please wait...
                        </button>
                      ) : (
                        <button className="btn btn-primary CmnBtn" onClick={() => handleSubmit()}>
                          Save
                        </button>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Add Bank Modal Start */}
            <Modal show={show} onHide={handleClose} className="CmnModal AddBankdModal">
              <Modal.Header closeButton>
                <Modal.Title>Add Bank Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul className="row company-form-list">
                  <li className="col-md-12">
                    <label>Full Name*</label>
                    <input type="text" name="" value="" placeholder="Full Name*" className="form-control" />
                  </li>
                  <li className="col-md-6">
                    <label>Bank Account No.*</label>
                    <input type="text" name="" value="" placeholder="Bank Account No.*" className="form-control" />
                  </li>
                  <li className="col-md-6">
                    <label>IFSC Code*</label>
                    <input type="text" name="" value="" placeholder="IFSC Code*" className="form-control" />
                  </li>
                  <li className="col-md-6">
                    <label>Bank Name*</label>
                    <input type="text" name="" value="" placeholder="Bank Name*" className="form-control" />
                  </li>
                  <li className="col-md-6">
                    <label>Branch Name*</label>
                    <input type="text" name="" value="" placeholder="Branch Name*" className="form-control" />
                  </li>
                  <li className="col-md-6">
                    <label>Upload Cancelled Check*</label>
                    <div className="upload-file-box">
                      <input type="file" className="input-file" />
                      <input type="text" name="" value="" placeholder="Upload Cancelled Check*" className="form-control disabled-form-control" disabled />
                      <button variant="primary" className="upload-btn">
                        Upload
                      </button>
                    </div>
                  </li>
                  <li className="col-md-6">
                    <label>Upload Authorization Letter</label>
                    <div className="upload-file-box">
                      <input type="file" className="input-file" />
                      <input type="text" name="" value="" placeholder="Authorization Letter" className="form-control disabled-form-control" disabled />
                      <button variant="primary" className="upload-btn">
                        Upload
                      </button>
                    </div>
                  </li>
                  <li className="col-md-12">
                    <label>Captcha</label>
                  </li>
                </ul>
              </Modal.Body>
              <Modal.Footer>
                <button variant="primary" onClick={handleClose} className="btn btn-primary CmnBtn">
                  Add
                </button>
              </Modal.Footer>
            </Modal>
            {/* Add Bank Modal End */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorEditProfile;
