import React, {useState, useRef, useEffect} from "react";

const BudgetRangeSliderComponent = ({minLimit, maxLimit, stepIncrement, label, identifier, handleSlideChange}) => {
  const minValue = minLimit || 0;
  const maxValue = maxLimit || 100;
  const [minRange, setMinRange] = useState(minValue);
  const [maxRange, setMaxRange] = useState(maxValue);
  const [isDraggingMin, setIsDraggingMin] = useState(false);
  const [isDraggingMax, setIsDraggingMax] = useState(false);
  const rangeRef = useRef(null);
  const thumbMinRef = useRef(null);
  const thumbMaxRef = useRef(null);

  const step = stepIncrement || 1;
  const unit = label === "days" ? "D" : "₹";

  useEffect(() => {
    const handleMouseMove = e => {
      if (isDraggingMin) {
        const rect = rangeRef.current.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        let newValue = Math.round(((offsetX / rect.width) * (maxValue - minValue)) / step) * step + minValue;
        newValue = Math.max(newValue, minValue);
        newValue = Math.min(newValue, maxRange - step);
        setMinRange(newValue);
      }
      if (isDraggingMax) {
        const rect = rangeRef.current.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        let newValue = Math.round(((offsetX / rect.width) * (maxValue - minValue)) / step) * step + minValue;
        newValue = Math.max(newValue, minRange + step);
        newValue = Math.min(newValue, maxValue);
        setMaxRange(newValue);
      }
    };

    const handleMouseUp = e => {
      setIsDraggingMin(false);
      setIsDraggingMax(false);
      if (e.target === rangeRef.current || rangeRef.current.contains(e.target)) {
        let originalStringMin = rangeRef.current.querySelector(".thumb-min").textContent;
        const digitMin = originalStringMin.replace(/\D/g, "");
        let originalStringMax = rangeRef.current.querySelector(".thumb-max").textContent;
        const digitMax = originalStringMax.replace(/\D/g, "");
        handleSlideChange("day", parseInt(digitMin), parseInt(digitMax));
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDraggingMin, isDraggingMax, maxValue, minValue, maxRange, minRange, step]);
  return (
    <div>
      <div
        className="range-slider"
        ref={rangeRef}
        onMouseDown={e => {
          const rect = rangeRef.current.getBoundingClientRect();
          const offsetX = e.clientX - rect.left;
          const minThumbDistance = Math.abs(((minRange - minValue) / (maxValue - minValue)) * rect.width - offsetX);
          const maxThumbDistance = Math.abs(((maxRange - minValue) / (maxValue - minValue)) * rect.width - offsetX);
          if (minThumbDistance < maxThumbDistance) {
            setIsDraggingMin(true);
          } else {
            setIsDraggingMax(true);
          }
        }}>
        <div
          className="progress"
          style={{
            left: `${((minRange - minValue) / (maxValue - minValue)) * 100}%`,
            right: `${100 - ((maxRange - minValue) / (maxValue - minValue)) * 100}%`,
          }}></div>
        <div
          className="thumb thumb-min"
          ref={thumbMinRef}
          style={{
            left: `${((minRange - minValue) / (maxValue - minValue)) * 95}%`,
          }}
          onMouseDown={() => setIsDraggingMin(true)}>{`${unit}${minRange}`}</div>
        <div
          className="thumb thumb-max"
          ref={thumbMaxRef}
          style={{
            left: `${((maxRange - minValue) / (maxValue - minValue)) * 95}%`,
          }}
          onMouseDown={() => setIsDraggingMax(true)}>{`${unit}${maxRange}`}</div>
      </div>
      <div className="numberVal budgetRangeNumber">
        <input type="text" value={`${unit}${minRange}`} readOnly />
        <input type="text" value={`${unit}${maxRange}`} readOnly />
      </div>
    </div>
  );
};

export default BudgetRangeSliderComponent;
