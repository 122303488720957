import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useUserContext} from "../context/UserContext";
import {agentDetailsViewApi, fetchVendorNotificationsListApi, logoutApi, vendorDetailsApi} from "../services/ApiService";
import Dropdown from "react-bootstrap/Dropdown";
import {NotificationManager} from "react-notifications";

import logo from "./../assets/img/logo.svg";

const AgentHeader = () => {
  const {userData, setUserData} = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [notiResp, setNotiResp] = useState();

  const getVendorDetails = async () => {
    try {
      let response = await agentDetailsViewApi();
      if (response) {
        if (response.data.res === true) {
          // if (location.pathname !== "/business/profile/edit") {
          if (!response.data.data.bank_acc_no || !response.data.User.email || !response.data.User.mobile) {
            navigate("/partners/profile");
            if (location.pathname !== "/partners/profile") {
              NotificationManager.warning("Please add your bank details.");
            }
          } else {
            if (response.data?.data.bank_acc_no && parseInt(response.data.data.bank_verified) !== 1) {
              navigate("/partners/profile");
              if (location.pathname !== "/partners/profile") {
                NotificationManager.warning("Your bank details have not been verified yet.");
              }
            } else if (response.data?.data.is_verified !== 1) {
              navigate("/partners/profile");
              if (location.pathname !== "/partners/profile") {
                NotificationManager.warning("Your account have not been verified yet.");
              }
            } else {
            }
          }
          // }
          let tempUserData = JSON.parse(localStorage.getItem("WMTUserData"));
          if (response.data.data.fullname) {
            tempUserData.data.agentFirstName = response.data.data.first_name;
            tempUserData.data.agentLastName = response.data.data.last_name;
            localStorage.setItem("WMTUserData", JSON.stringify(tempUserData));
            setUserData(tempUserData);
          }
        } else {
        }
      }
    } catch (error) {}
  };

  const fetchVendorNotificationsList = async () => {
    try {
      let response = await fetchVendorNotificationsListApi();
      if (response) {
        setNotiResp(response.data);
      }
    } catch (error) {}
  };

  const handleLogout = async () => {
    setUserData(null);
    navigate("/");
    localStorage.removeItem("WMTUserData");
    localStorage.removeItem("WMTLastActivityTimestamp");
    NotificationManager.success("You have been successfully logged out.");
    try {
      let response = await logoutApi();
      if (response) {
        if (response.data.res === true) {
        } else {
        }
      }
    } catch (error) {}
  };

  const checkAndRemoveUserData = () => {
    const lastActivityTimestamp = localStorage.getItem("WMTLastActivityTimestamp");
    if (lastActivityTimestamp && Date.now() - parseInt(lastActivityTimestamp) > 24 * 60 * 60 * 1000) {
      localStorage.removeItem("WMTUserData");
      localStorage.removeItem("WMTLastActivityTimestamp");
      setUserData(null);
      navigate("/");
    }
  };

  useEffect(() => {
    getVendorDetails();
    fetchVendorNotificationsList();
    checkAndRemoveUserData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(fetchVendorNotificationsList, 8000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="venderHeader">
      <div className="logoLft">
        <Link to={"/partners/profile"}>
          <img src={logo} alt="" />
        </Link>
      </div>

      <div className="hdrRgt notificationHdr">
        <Dropdown className="HeaderDropdown arrowHdr newNotifiaction">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div className="notifBtn">{notiResp?.unread_count ? <span>{notiResp?.unread_count ? notiResp?.unread_count : 0}</span> : null}</div>
          </Dropdown.Toggle>
          {notiResp?.notifications?.length > 0 ? (
            <Dropdown.Menu>
              <div className="dropdown-menu-section">
                {notiResp?.notifications?.map((item, index) => (
                  <p key={index} className="dropdown-item">
                    {item?.message}
                  </p>
                ))}
              </div>
              <Link to={"/partners/notifications"} className="dropdown-item">
                See All
              </Link>
            </Dropdown.Menu>
          ) : null}
        </Dropdown>

        <div className="topAcct">
          {/* <img src={accountImg} alt="" />
          Alexander */}
          <Dropdown className="HeaderDropdown agentHeaderDropdown">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <span className="UserBrand">{userData?.data?.agentFirstName ? userData?.data?.agentFirstName?.charAt(0) : "A"}</span>{" "}
              <span className="userNeme">{userData?.data?.agentFirstName ? userData?.data?.agentFirstName : "Agent"}</span>
            </Dropdown.Toggle>
            {/* <Dropdown.Menu>
              <Link to={"/"} className="my-home-item dropdown-item">
                Home
              </Link>
              <Link to={"/partners/profile"} className="account-settings-item dropdown-item">
                My Profile
              </Link>
              <button onClick={() => handleLogout()} className="logout-item dropdown-item">
                Logout
              </button>
            </Dropdown.Menu> */}
          </Dropdown>
        </div>
        <div>
          <Link to={"/"} className="btn btn-primary edit-btn CmnBtn agentBookBtn">
            Book Tour
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AgentHeader;
