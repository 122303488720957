import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Accordion, Modal} from "react-bootstrap";
import {cancelBookingApi, customerBookingDetailsApi, getCustomerDetailsApi, getTaxDetailsApi, upcomingBookingApi} from "../services/ApiService";
import {Helmet} from "react-helmet";
import {NotificationManager} from "react-notifications";

const BookingCancellation = () => {
  const navigate = useNavigate();
  const {bookingId} = useParams();
  const {from} = useLocation().state ?? {};
  const [customerData, setCustomerData] = useState();
  const [bookingData, setBookingData] = useState();
  const [passengersArray, setPassengersArray] = useState([]);
  const [passengersArrayForCal, setPassengersArrayForCal] = useState([]);
  const [passengersArrayForBase, setPassengersArrayForBase] = useState([]);
  const [originalPassengersArrayForBase, setOriginalPassengersArrayForBase] = useState([]);
  const [bankAccountType, setBankAccountType] = useState(1);
  const [cancellationIssue, setCancellationIssue] = useState();
  const [bankDetails, setBankDetails] = useState({accountHolderName: "", accountNumber: "", ifscCode: ""});
  const [priceDetails, setPriceDetails] = useState({
    basePrice: "",
    perPersonPrice: "",
    cancellationPercent: "",
    cancellationCharges: "",
    websiteCharges: 0,
    websiteChargesIncludePrice: 0,
    numberOfCancelPerson: "",
    refundedAmount: "",
    refundableAmount: "",
    platformCharges: "",
    gstTax: 0,
    gstTaxIncludePrice: 0,
    addOnPrice: 0,
    paidAmount: 0,
  });
  const [partialCancelChecked, setPartialCancelChecked] = useState([]);
  const [availableForCancel, setAvailableForCancel] = useState([]);
  const [isAgreed, setIsAgreed] = useState(0);
  const [btnDis, setBtnDis] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);

  const getCustomerDetails = async () => {
    try {
      let response = await getCustomerDetailsApi();
      if (response) {
        if (response.data.res === true) {
          setCustomerData(response.data);
        }
      }
    } catch (error) {}
  };

  const getBookingDetails = async () => {
    try {
      let response = await customerBookingDetailsApi(bookingId);
      if (response) {
        if (response.data.res === true) {
          setBookingData(response.data);
          // getTaxDetails(response.data.package.package_id);
          setPassengersArrayForBase(response.data.booking_passengers);
          setOriginalPassengersArrayForBase(response.data.booking_passengers);
          let tempArray = [];
          response.data.booking_rooms.map((roomItem, roomIndex) => {
            const roomData = {room: "Room " + (roomIndex + 1), adults: [], children: []};
            response.data.booking_passengers.map((passItem, passIndex) => {
              if (roomItem.id === passItem.booking_room_id) {
                const passenger = {
                  title: passItem.title,
                  first_name: passItem.first_name,
                  last_name: passItem.last_name,
                  dob: passItem.dob,
                  gender: passItem.gender,
                  id: passItem.id,
                  status: passItem.status,
                };

                if (passItem.is_adult === 1) {
                  roomData.adults.push(passenger);
                } else {
                  roomData.children.push(passenger);
                }
              }
            });
            tempArray.push(roomData);
          });

          let paidAmount = 0;
          if (response.data?.data[0]?.payments?.length > 0) {
            response.data?.data[0]?.payments?.map(item => {
              paidAmount += parseFloat(item?.paid_amount);
            });
          } else {
            paidAmount = 0;
          }

          setPriceDetails(prev => ({
            ...prev,
            basePrice: parseFloat(response.data.data[0]?.final_price),
            refundedAmount: parseFloat(response.data?.refund_amount_sum),
            gstTax: parseInt(response.data.data[0]?.gst_percent),
            websiteCharges: parseInt(response.data.data[0]?.website_percent),
            paidAmount: paidAmount,
          }));
          setPassengersArray(tempArray);
          setPassengersArrayForCal(tempArray);

          // If already cancelled then pushing inside setAvailableForCancel array
          let tempPartialCancelArray = [];
          response.data.booking_passengers.map(item => {
            if (item?.status === 1) {
              tempPartialCancelArray.push(parseInt(item.id));
            }
          });
          console.log(tempPartialCancelArray);
          setPartialCancelChecked(tempPartialCancelArray);
        }
      }
    } catch (error) {}
  };

  const formatDate = inputDate => {
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-GB"); // 'en-GB' uses day/month/year format
  };

  const handlePartialCancelChange = (event, index) => {
    const {checked, value} = event.target;
    if (checked) {
      setPartialCancelChecked(prev => [...prev, parseInt(value)]);
    } else {
      setPartialCancelChecked(prev => prev.filter(item => parseInt(item) !== parseInt(value)));

      // Add back the unchecked passenger to the original position
      const passengerToAddBack = originalPassengersArrayForBase.find(passenger => parseInt(passenger.id) === parseInt(value));
      if (passengerToAddBack) {
        const updatedPassengersArray = [...passengersArrayForBase];
        const insertIndex = originalPassengersArrayForBase.findIndex(passenger => passenger.id === passengerToAddBack.id);
        updatedPassengersArray.splice(insertIndex, 0, passengerToAddBack);
        setPassengersArrayForBase(updatedPassengersArray);
      }
    }
  };

  const getTaxDetails = async packageId => {
    let gstTax = 0;

    try {
      const formData = new FormData();
      formData.append("package_id", packageId);
      let response = await getTaxDetailsApi(formData);
      if (response && response.data.res === true) {
        gstTax = response.data.data.gst.value ? parseInt(response.data.data.gst.value) : 0;
      }
    } catch (error) {
      console.error("Error fetching package details:", error);
    }

    setPriceDetails(prev => ({
      ...prev,
      gstTax: gstTax,
    }));
  };

  const handleShowPopup = async () => {
    if (partialCancelChecked?.length > 0) {
      //For Adult available checking
      let error = 0;
      passengersArrayForCal?.map(item => {
        if (item?.adults?.length > 0 || item?.children?.length > 0) {
          if (item?.adults?.length === 0) {
            error = 1;
          }
        }
      });

      if (!cancellationIssue) {
        NotificationManager.warning("Please enter cancellation issue.");
        return;
      } else if (availableForCancel?.length === partialCancelChecked?.length) {
        NotificationManager.warning("Sorry, you can't cancel all person from partial cancellation page.");
        return;
      } else if (error === 1) {
        NotificationManager.warning("Only child not allowed alone. Please add one adult.");
        return;
        // } else if (isAgreed !== 1) {
        //   NotificationManager.warning("Please accept cancellation policy.");
        //   return;
      } else {
        setShowCancelPopup(true);
      }
    }
  };

  const handleCancel = async () => {
    if (partialCancelChecked?.length > 0) {
      //For Adult available checking
      let error = 0;
      passengersArrayForCal?.map(item => {
        if (item?.adults?.length > 0 || item?.children?.length > 0) {
          if (item?.adults?.length === 0) {
            error = 1;
          }
        }
      });

      //Removing empty rooms
      let tempArray = passengersArrayForCal?.filter(item => {
        return item?.adults?.length > 0 || item?.children?.length > 0;
      });

      if (!cancellationIssue) {
        NotificationManager.warning("Please enter cancellation issue.");
        return;
      } else if (availableForCancel?.length === partialCancelChecked?.length) {
        NotificationManager.warning("Sorry, you can't cancel all person from partial cancellation page.");
        return;
      } else if (error === 1) {
        NotificationManager.warning("Only child not allowed alone. Please add one adult.");
        return;
        // } else if (isAgreed !== 1) {
        //   NotificationManager.warning("Please accept cancellation policy.");
        //   return;
      } else {
        setBtnDis(true);

        let basePrice = priceDetails.perPersonPrice ? priceDetails.perPersonPrice : 0;

        let totalAdult = 0;
        let totalChildren = 0;

        if (passengersArrayForCal.length > 0) {
          passengersArrayForCal?.map(roomItem => {
            if (roomItem?.adults?.length) {
              totalAdult += roomItem?.adults?.length;
            }
            if (roomItem?.children?.length) {
              totalChildren += roomItem?.children?.length;
            }
          });
        }

        if (totalAdult > 0) {
          basePrice = parseFloat(basePrice) * parseInt(totalAdult);
        }

        if (totalChildren > 0) {
          let newChildDisc = parseFloat(priceDetails.perPersonPrice) - parseFloat(bookingData?.package?.child_discount);
          basePrice = parseFloat(basePrice) + parseInt(totalChildren) * newChildDisc;
        }

        passengersArrayForCal.forEach(roomItem => {
          if (roomItem.totalAdults === 1) {
            basePrice += parseFloat(bookingData?.package?.single_occupancy_cost);
          } else if (roomItem.totalAdults === 3) {
            if (bookingData?.package?.triple_sharing_discount) {
              basePrice -= parseFloat(bookingData?.package?.triple_sharing_discount);
            }
          }
        });

        // console.log(priceDetails);
        // console.log(basePrice);

        // let cancelPersonsArray = [];

        // partialCancelChecked?.map(item => {
        //   let findItem = bookingData?.booking_passengers?.find(chitem => parseInt(chitem?.id) === parseInt(item));
        //   cancelPersonsArray.push(findItem);
        // });
        // console.log(cancelPersonsArray);

        // console.log(tempArray);

        try {
          const formData = new FormData();
          formData.append("booking_id", bookingId);
          formData.append("cancellation_reason", cancellationIssue);
          formData.append("cancellation_type", "full");
          formData.append("booking_price", parseFloat(priceDetails?.basePrice).toFixed(2));
          formData.append("cancellation_percent", priceDetails?.cancellationPercent);
          formData.append("cancellation_charge", parseFloat(priceDetails?.cancellationCharges).toFixed(2));
          formData.append("website_percent", priceDetails?.websiteCharges);
          formData.append("website_charge", parseFloat(priceDetails?.websiteChargesIncludePrice).toFixed(2));
          formData.append("gst_percent", priceDetails?.gstTax ? priceDetails?.gstTax : "");
          formData.append("gst_charge", priceDetails?.gstTaxIncludePrice ? parseFloat(priceDetails?.gstTaxIncludePrice).toFixed(2) : "");
          formData.append("refund_amount", parseFloat(priceDetails?.refundableAmount).toFixed(2));
          formData.append("passenger_id", partialCancelChecked?.join(","));
          formData.append("no_of_pax", partialCancelChecked?.length);
          formData.append("pax_charge", parseFloat(partialCancelChecked?.length * parseFloat(priceDetails?.perPersonPrice)).toFixed(2));

          for (const value of formData.values()) {
            console.log(value);
          }
          // return;
          let response = await cancelBookingApi(formData);
          if (response && response.data.res === true) {
            NotificationManager.success(response.data.msg);
            if (from === "agent") {
              navigate(`/partners/booking/detail/${bookingId}`);
            } else {
              navigate(`/booking/detail/${bookingId}`);
            }
          } else {
            NotificationManager.error(response.data.msg);
          }
          setBtnDis(false);
        } catch (error) {
          console.error("Error fetching package details:", error);
          setBtnDis(false);
        }
      }
    }
  };

  useEffect(() => {
    getCustomerDetails();
    getBookingDetails();
  }, []);

  useEffect(() => {
    if (bookingData && bookingData.res === true) {
      // Clear the temporary array
      let tempArray = [];
      let totalPerson = 0;
      let perPersonPrice = 0;
      let cancellationCharges = 0;
      let cancellationPercent = 0;
      let numberOfCancelPerson = 0;
      let refundableAmount = 0;
      let afterCancelBasePrice = 0;
      let totalAdult = 0;
      let totalChildren = 0;
      let addOnPrice = bookingData?.data[0]?.addon_total_price;
      let tempBookingRooms = [...bookingData.booking_rooms];
      let tempBookingPassengers = [...passengersArrayForBase];

      bookingData.booking_rooms.map((roomItem, roomIndex) => {
        const roomData = {room: "Room " + (roomIndex + 1), adults: [], children: []};
        bookingData.booking_passengers.map((passItem, passIndex) => {
          totalPerson = passIndex;
          if (roomItem.id === passItem.booking_room_id) {
            const passenger = {
              title: passItem.title,
              first_name: passItem.first_name,
              last_name: passItem.last_name,
              dob: passItem.dob,
              gender: passItem.gender,
              id: passItem.id,
              is_adult: passItem.is_adult,
            };

            if (passItem.is_adult === 1) {
              roomData.adults.push(passenger);
              totalAdult += 1;
            } else {
              roomData.children.push(passenger);
              totalChildren += 1;
            }
          }
        });
        tempArray.push(roomData);
        totalPerson += 1;
      });

      // setOriginalPassengersArrayForBase(tempBookingPassengers);

      let tempPassArray = [...passengersArrayForBase];

      // partialCancelChecked.map(item => {
      //   tempPassArray = tempBookingPassengers.filter(item1 => parseInt(item1.id) !== parseInt(item));
      // });

      console.log(tempPassArray);
      setPassengersArrayForBase(tempPassArray);

      let tempArrayForBase = [];
      let totalAdult1 = 0;
      let totalChildren1 = 0;
      let totalPerson1 = 0;
      tempBookingRooms.map((roomItem, roomIndex) => {
        let roomAdultCount = 0;
        let roomChildrenCount = 0;
        const roomData = {room: "Room " + (roomIndex + 1), adults: [], children: [], totalAdults: 0, totalChildrens: 0};
        tempPassArray.map((passItem, passIndex) => {
          totalPerson1 = passIndex;
          if (roomItem.id === passItem.booking_room_id) {
            const passenger = {
              title: passItem.title,
              first_name: passItem.first_name,
              last_name: passItem.last_name,
              dob: passItem.dob,
              gender: passItem.gender,
              id: passItem.id,
              is_adult: passItem.is_adult,
            };

            if (passItem.is_adult === 1) {
              roomData.adults.push(passenger);
              totalAdult1 += 1;
              roomAdultCount += 1;
            } else {
              roomData.children.push(passenger);
              totalChildren1 += 1;
              roomChildrenCount += 1;
            }
          }
        });
        roomData.totalAdults = roomAdultCount;
        roomData.totalChildrens = roomChildrenCount;
        tempArrayForBase.push(roomData);
        totalPerson1 += 1;
      });

      console.log("tempArrayForBase", tempArrayForBase);
      setPassengersArrayForCal(tempArrayForBase);

      // Calculate per person price
      // perPersonPrice =
      //   parseFloat(
      //     bookingData.refund_amount_sum
      //       ? parseFloat(bookingData.data[0]?.final_price) - parseFloat(bookingData?.refund_amount_sum)
      //       : bookingData.data[0]?.final_price
      //   ) / totalPerson;

      tempPassArray?.map(pasItem => {
        perPersonPrice += parseFloat(pasItem?.price);
      });

      let gstAmountForSelected = 0;
      if (parseFloat(bookingData?.data[0]?.gst_price)) {
        if (tempPassArray.length > 0) {
          gstAmountForSelected = parseFloat(bookingData?.data[0]?.gst_price) / tempPassArray.length;
        }
      }

      console.log("gstAmountForSelected", gstAmountForSelected, tempPassArray.length);

      if (parseFloat(addOnPrice)) {
        perPersonPrice += parseFloat(addOnPrice);
      }

      // if (parseFloat(gstAmountForSelected)) {
      //   perPersonPrice += parseFloat(gstAmountForSelected);
      // }

      // perPersonPrice = parseFloat(bookingData.data[0]?.final_price) / totalPerson;

      // Calculate day difference between booking date and today
      const today = new Date();
      const bookingDate = new Date(bookingData?.data[0]?.first_booking_date);
      const timeDiff = bookingDate.getTime() - today.getTime();
      const dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));

      // Parse cancellation policy
      const parsedPolicy = bookingData.package.cancellation_policy.map(policy => {
        const parts = policy.split("-");
        return {
          startDay: parseInt(parts[0], 10),
          endDay: parseInt(parts[1], 10),
          percentage: parseInt(parts[2], 10),
        };
      });

      // Find applicable cancellation policy
      const applicablePolicy = parsedPolicy.find(policy => {
        if (policy.startDay <= policy.endDay) {
          return dayDifference <= policy.startDay && dayDifference >= policy.endDay;
        } else {
          if (policy.endDay === 0) {
            return dayDifference >= policy.startDay;
          } else {
            return (dayDifference <= policy.startDay && dayDifference >= policy.endDay) || (dayDifference >= policy.startDay && dayDifference < policy.endDay);
          }
        }
      });

      // Set cancellation percent from applicable policy
      cancellationPercent = applicablePolicy ? applicablePolicy.percentage : 100;
      if (cancellationPercent) {
        cancellationCharges = (parseFloat(perPersonPrice) * cancellationPercent) / 100;
        // cancellationCharges = (parseFloat(perPersonPrice) * partialCancelChecked.length * cancellationPercent) / 100;
      }

      // Calculate plaform / website charges price
      let platformChargesPrice = 0;
      // platformChargesPrice = ((parseFloat(priceDetails.paidAmount) - cancellationCharges) * priceDetails.websiteCharges) / 100;
      // platformChargesPrice = ((parseFloat(priceDetails.basePrice) - cancellationCharges) * priceDetails.websiteCharges) / 100;

      // Calculate gst price
      let gstPrice = 0;
      gstPrice = ((parseFloat(cancellationCharges) + parseFloat(platformChargesPrice)) * priceDetails.gstTax) / 100;

      refundableAmount = parseFloat(priceDetails.paidAmount) - parseFloat(cancellationCharges);
      // refundableAmount = parseFloat(perPersonPrice) * partialCancelChecked.length - parseFloat(cancellationCharges);
      if (gstPrice) {
        refundableAmount -= gstPrice;
      }
      if (platformChargesPrice) {
        refundableAmount -= platformChargesPrice;
      }

      // Set price details in state
      setPriceDetails(prev => ({
        ...prev,
        perPersonPrice: perPersonPrice ? perPersonPrice : parseFloat(0),
        cancellationPercent: cancellationPercent ? cancellationPercent : 0,
        numberOfCancelPerson: partialCancelChecked.length ? partialCancelChecked.length : 0,
        cancellationCharges: cancellationCharges ? cancellationCharges : "0.00",
        refundableAmount: refundableAmount,
        gstTaxIncludePrice: gstPrice,
        websiteChargesIncludePrice: platformChargesPrice,
      }));
    }
  }, [partialCancelChecked]);

  console.log(priceDetails);

  const hotelSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
  };

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Booking Cancellation`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Booking Cancellation page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="upcomingInner partialCancel">
              <div className="bookingDetails">
                <h3>Booking Cancellation</h3>
                <div className="bookDtlsInner">
                  <ul className="bookContainer">
                    <li className="partialTextarea">
                      <textarea placeholder="Enter Your Cancellation Issue" onChange={e => setCancellationIssue(e.target.value)}></textarea>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bookingDetails">
                <div className="bookDtlsInner travlerkContainer">
                  {passengersArray?.map((item, index) => (
                    <div className="loopBook">
                      {index === 0 ? (
                        <>
                          <h3 className="bookTravellersHead">Travellers</h3>
                          {/* <h4 className="travTxt">Check the person you want to delete.</h4> */}
                        </>
                      ) : null}

                      <h2>{item?.room}</h2>
                      <ul>
                        {item?.adults?.length > 0
                          ? item?.adults?.map((chItem, chIndex) => (
                              <li className="trvlActive">
                                <div className="trvlrDiv">
                                  <h4>
                                    {chItem?.first_name} {chItem?.last_name} <span>Adult</span>
                                  </h4>
                                  <h5 style={{textTransform: "capitalize"}}>
                                    {chItem?.gender} | {formatDate(chItem?.dob)}
                                  </h5>
                                </div>

                                {chItem?.status === 0 ? (
                                  <div className="bookAction">
                                    <button>Cancelled</button>
                                  </div>
                                ) : (
                                  <div className="bookAction checkCustom4">
                                    <input
                                      type="checkbox"
                                      name="partialCancelCheck"
                                      value={chItem?.id}
                                      checked
                                      disabled
                                      // onChange={event => handlePartialCancelChange(event, chIndex)}
                                    />
                                    <span className="checkmark5"></span>
                                  </div>
                                )}
                              </li>
                            ))
                          : null}
                        {item?.children?.length > 0
                          ? item?.children?.map((chItem, chIndex) => (
                              <li className="trvlActive">
                                <div className="trvlrDiv">
                                  <h4>
                                    {chItem?.first_name} {chItem?.last_name} <span>Children</span>
                                  </h4>
                                  <h5>
                                    {chItem?.gender} | {formatDate(chItem?.dob)}
                                  </h5>
                                </div>

                                {chItem?.status === 0 ? (
                                  <div className="bookAction">
                                    <button>Cancelled</button>
                                  </div>
                                ) : (
                                  <div className="bookAction checkCustom4">
                                    <input
                                      type="checkbox"
                                      name="partialCancelCheck"
                                      value={chItem?.id}
                                      checked
                                      disabled
                                      // onChange={event => handlePartialCancelChange(event, chIndex)}
                                    />
                                    <span className="checkmark5"></span>
                                  </div>
                                )}
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  ))}

                  {/* <div className="loopBook">
                    <h2>Room-2</h2>
                    <ul>
                      <li className="trvlActive">
                        <div className="trvlrDiv">
                          <h4>
                            Manoj Karmakar <span>Adult</span>
                          </h4>
                          <h5>Male | 10/08/1995</h5>
                        </div>
                        <div className="bookAction checkCustom4">
                          <input type="checkbox" name="" />
                          <span className="checkmark5"></span>
                        </div>
                      </li>
                      <li className="trvlActive">
                        <div className="trvlrDiv">
                          <h4>
                            Manoj Karmakar <span>Adult</span>
                          </h4>
                          <h5>Male | 10/08/1995</h5>
                        </div>
                        <div className="bookAction checkCustom4">
                          <input type="checkbox" name="" />
                          <span className="checkmark5"></span>
                        </div>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>

              {/* {priceDetails?.numberOfCancelPerson > 0 ? ( */}
              <div className="itternaryBooking">
                <h3>Refund Summary</h3>
                <div className="ConditionAccordion">
                  <ul className="bookPricing">
                    <li>
                      <div className="thHdn">Tour price</div>
                      <div className="tdHdn">
                        ₹
                        {parseFloat(priceDetails?.basePrice).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </li>
                    {parseFloat(priceDetails?.basePrice) - parseFloat(priceDetails?.paidAmount) !== parseFloat(priceDetails?.basePrice) ? (
                      <li>
                        <div className="thHdn">Paid Amount</div>
                        <div className="tdHdn">
                          ₹
                          {parseFloat(priceDetails?.paidAmount).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </li>
                    ) : null}
                    {/* {priceDetails?.refundedAmount ? (
                      <li>
                        <div className="thHdn">Refunded Amount</div>
                        <div className="tdHdn">
                          ₹
                          {parseFloat(priceDetails?.refundedAmount).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </li>
                    ) : null} */}
                    {/* <li>
                      <div className="thHdn">No. of Person(s) x {priceDetails.numberOfCancelPerson}</div>
                      <div className="tdHdn">
                        ₹
                        {parseFloat(priceDetails?.perPersonPrice).toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        {(parseFloat(priceDetails?.perPersonPrice) * priceDetails?.numberOfCancelPerson).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </div>
                    </li> */}
                    <li>
                      <div className="thHdn">
                        Cancellation Charges({priceDetails?.cancellationPercent}%) per person(s) x {priceDetails.numberOfCancelPerson}
                      </div>
                      <div className="tdHdn">
                        ₹-{parseFloat(priceDetails.cancellationCharges).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                      </div>
                    </li>
                    {/* {parseFloat(priceDetails?.websiteCharges) ? (
                      <li>
                        <div className="thHdn">Convenience Fees({priceDetails?.websiteCharges}%)</div>
                        <div className="tdHdn">
                          ₹-{parseFloat(priceDetails.websiteChargesIncludePrice).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                        </div>
                      </li>
                    ) : null} */}
                    {priceDetails.gstTax ? (
                      <li>
                        <div className="thHdn">GST({priceDetails.gstTax}%)</div>
                        <div className="tdHdn">
                          ₹-
                          {parseFloat(priceDetails?.gstTaxIncludePrice).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </div>
                      </li>
                    ) : null}
                    {parseFloat(priceDetails.refundableAmount) ? (
                      <li>
                        <div className="thHdn">
                          <h3>Refundable Amount</h3>
                        </div>
                        <div className="tdHdn">
                          <h4>₹{parseFloat(priceDetails.refundableAmount).toLocaleString("en-IN", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h4>
                        </div>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
              {/* ) : null} */}

              {/* <div className="itternaryBooking">
                <h3>Select a Bank Account to Get Refund</h3>

                <ul className="custmRd">
                  <li className="radioCustom4">
                    <input
                      type="radio"
                      name="acct"
                      checked={bankAccountType === 1 ? true : false}
                      onClick={() => {
                        setBankAccountType(1);
                        getCustomerDetails();
                      }}
                    />
                    <span className="checkmark4"></span>Revert to same account
                  </li>
                  <li className="radioCustom4">
                    <input
                      type="radio"
                      name="acct"
                      checked={bankAccountType === 2 ? true : false}
                      onClick={() => {
                        setBankAccountType(2);
                        setBankDetails({accountHolderName: "", accountNumber: "", ifscCode: ""});
                      }}
                    />
                    <span className="checkmark4 chekcAntr"></span>Another account
                  </li>
                </ul>
                <div className="ConditionAccordion">
                  <ul className="bookPricing gtRfnd">
                    <li>
                      <input type="text" value="Account Holder Name" />
                    </li>
                    <li>
                      <input type="text" value="Account Number" />
                    </li>
                    <li>
                      <input type="text" value="Bank IFSC" />
                    </li>
                  </ul>
                </div>
              </div> */}

              <div className="itternaryBooking">
                <h3>Cancellation Policy</h3>

                <div className="ConditionAccordion">
                  <div className="inclusDiv">
                    <ul>
                      {bookingData?.package?.cancellation_policy.map((policy, index) => {
                        let [from, to, percentage] = policy.split("-");
                        return (
                          <li key={index}>
                            {index === 0
                              ? `${from} or more days before departure: ${percentage}% of total package cost`
                              : `Between ${from} to ${to} days before departure: ${percentage}% of total package cost`}{" "}
                            {/* + Convenience fees {priceDetails?.websiteCharges}% */}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {/* <ul className="custmRd">
                    <li className="radioCustom4" onClick={() => setIsAgreed(1)}>
                      <input type="radio" name="Agree" checked={isAgreed === 1} />
                      <span className="checkmark4"></span>Agree
                    </li>
                    <li className="radioCustom4" onClick={() => setIsAgreed(2)}>
                      <input type="radio" name="Agree" checked={isAgreed === 2} />
                      <span className="checkmark4 chekcAntr"></span>Disagree
                    </li>
                  </ul> */}
                </div>
              </div>

              <div className="bookingBtns">
                <button className="canBoking" disabled={partialCancelChecked?.length === 0} onClick={() => handleShowPopup()}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showCancelPopup} onHide={() => setShowCancelPopup(false)} className="CmnModal SendRequestModal">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2>Confirm?</h2>
          <p>Are you sure you want to cancel this package?</p>
        </Modal.Body>
        <Modal.Footer>
          {btnDis ? (
            <button className="btn btn-primary CmnBtn" disabled>
              Please wait...
            </button>
          ) : (
            <button className="btn btn-primary CmnBtn" onClick={() => handleCancel()}>
              Confirm
            </button>
          )}
          <button className="btn btn-primary CmnBlackBtn" onClick={() => setShowCancelPopup(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </div>
  );
};

export default BookingCancellation;
