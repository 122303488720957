import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useUserContext} from "../context/UserContext";
import { logoutApi } from "../services/ApiService";
import {NotificationManager} from "react-notifications";

const AgentSideBar = () => {
  const {userData, setUserData} = useUserContext();
  const navigate = useNavigate();

  const handleLogout = async () => {
    setUserData(null);
    navigate("/");
    localStorage.removeItem("WMTUserData");
    localStorage.removeItem("WMTLastActivityTimestamp");
    NotificationManager.success("You have been successfully logged out.");
    try {
      let response = await logoutApi();
      if (response) {
        if (response.data.res === true) {
        } else {
        }
      }
    } catch (error) {}
  };

  const location = useLocation();
  return (
    <div className="venderSidebar">
      <ul>
        <li className={location.pathname === "/partners/profile" || location.pathname.includes("/agent-edit-profile") ? "active" : ""}>
          <Link to="/partners/profile">My Profile</Link>
        </li>
        <li className={location.pathname === "/partners/bookings" || location.pathname.includes("/partners/booking/") ? "active" : ""}>
          <Link to="/partners/bookings">Bookings</Link>
        </li>
        <li className={location.pathname === "/partners/commissions" ? "active" : ""}>
          <Link to="/partners/commissions">Commission</Link>
        </li>
        <li>
          <Link to="javascript:void(0);" onClick={() => handleLogout()}>
            Logout
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AgentSideBar;
