import React, {Component, useState, useRef, useEffect} from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {loginApi, verifyOtpApi} from "../services/ApiService";
import {useUserContext} from "../context/UserContext";
import {Helmet} from "react-helmet";

const google = require("./../assets/img/google-icon.svg").default;
const facebook = require("./../assets/img/facebook-icon2.svg").default;

const Otp = () => {
  const navigate = useNavigate();
  const {setUserData} = useUserContext();
  const {loginValue, from} = useLocation().state ?? {};
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(60);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [validationError, setValidationError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [recountTimer, setRecountTimer] = useState(false);
  const [otpResponseValue, setOtpResponseValue] = useState(useLocation().state?.responseValue ?? null);

  const handleInputChange = (index, e) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    // Check if all OTP digits are entered
    if (index === inputRefs.length - 1 && newOtp.every(digit => digit !== "")) {
      setValidationError("");
      handleSubmit(newOtp);
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);

      // Set focus on the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4);

    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      for (let i = 0; i < 4; i++) {
        newOtp[i] = pastedData[i] || "";
      }
      return newOtp;
    });

    if (pastedData?.length < 4) {
      inputRefs[pastedData?.length].current.focus();
    } else {
      inputRefs[3].current.focus();
    }

    // Check if all four digits are present in the pasted data
    if (pastedData.length === 4 && pastedData.match(/^\d{4}$/)) {
      let newValue = pastedData.split("");
      handleSubmit(newValue);
    }
  };

  const handleResendOTP = async () => {
    if (loginValue) {
      setRecountTimer(!recountTimer);
      setValidationError("");
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("login", loginValue);
        // Api call
        let response = await loginApi(formData);
        if (response) {
          if (response.data.res === true) {
            setTimer(60);
            setSuccessMsg(response.data.msg);
            setOtpResponseValue(response.data.data);
            setTimeout(() => {
              setSuccessMsg("");
            }, 3000);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      setValidationError("Please enter a valid email or mobile number.");
    }
  };

  const handleSubmit = async otp => {
    if (otp.every(digit => digit === "")) {
      setValidationError("Please enter Your Otp.");
    } else {
      let newVal = otp.join("");
      setValidationError("");
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("customer", otpResponseValue?.customer);
        formData.append("action", otpResponseValue?.action);
        formData.append("otp", newVal);

        let response = await verifyOtpApi(formData);
        if (response) {
          if (response.data.res === true) {
            setValidationError("");
            setSuccessMsg(response.data.msg);
            if (response.data.data.customerDetail === "exist") {
              if (otpResponseValue?.action === "register") {
                navigate("/registration", {
                  state: {responseValue: otpResponseValue, verifyOtpResponse: response.data.data, from: from, responseDataForLocal: response.data},
                });
              } else {
                localStorage.setItem("WMTUserData", JSON.stringify(response.data));
                setUserData(response.data);
                if (from) {
                  navigate(from);
                } else {
                  navigate("/");
                }
              }
            } else {
              navigate("/registration", {
                state: {responseValue: otpResponseValue, verifyOtpResponse: response.data.data, from: from, responseDataForLocal: response.data},
              });
            }
          } else {
            setValidationError(response.data.msg);
            setSuccessMsg("");
          }
        }
      } catch (error) {}
    }
  };

  // Resend Counter useEffect
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [recountTimer]);

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Wish My Tour`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Home page" />
      </Helmet>
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="loginDiv">
            <h2>OTP Verification</h2>
            <h3>Check email inbox or text messages for your OTP</h3>
            {/* <h5>Your Otp is - {otpResponseValue?.otp}</h5> */}
            <ul className="otpList">
              <li>
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={value}
                    onChange={e => handleInputChange(index, e)}
                    onKeyUp={e => handleBackspace(index, e)}
                    onPaste={handlePaste}
                    ref={inputRefs[index]}
                  />
                ))}
              </li>
              <li>00:{parseInt(timer) < 10 ? "0" + timer : timer}</li>
              <li>
                <h4>
                  Not Received OTP?{" "}
                  {parseInt(timer) === 0 ? (
                    <Link to="javascript:void(0);" onClick={() => handleResendOTP()}>
                      Resend Now
                    </Link>
                  ) : (
                    "Resend Now"
                  )}
                </h4>
                {validationError && <p className="validationErrorMsg">{validationError}</p>}
                {successMsg && <p className="successMsg">{successMsg}</p>}
              </li>
              <li>
                <button onClick={() => handleSubmit(otp)}>Submit</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Otp;
