import React, {Component, useState, useRef, useEffect} from "react";
import Header from "../component/Header";
import {
  addPackageApi,
  getCityListApi,
  getPackageDetailsApi,
  getStatesListApi,
  getThemesListApi,
  getTripListApi,
  getTypeOfTourPackagesListApi,
} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useNavigate, useParams} from "react-router";
import {Helmet} from "react-helmet";
import {Form, Button, Dropdown, Tabs, Tab, Modal} from "react-bootstrap";
import Calendar from "react-calendar";

import panImg from "../assets/img/pan-image.png";
import EmptyImg from "../assets/img/empty-img.svg";
import CancelChequeImg from "../assets/img/cancel-cheque-img.png";
import AuthoriedLetterImg from "../assets/img/Authoried-letter-img.png";
import deleteImg from "../assets/img/delete-img.svg";
import MinusButton from "../assets/img/minus-button.svg";
import PlusButton from "../assets/img/plus-button.svg";

const UsersAvailibility = () => {
  const navigate = useNavigate();
  const {packageId} = useParams();

  const [key, setKey] = useState("tab1");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedDates, setSelectedDates] = useState([new Date(2024, 2, 16), new Date(2024, 2, 20)]);
  const [selectedDate, setSelectedDate] = useState(null);

  // Sample calendar data, replace it with your actual data
  const calendarData1 = [
    {date: new Date(2024, 2, 2), status: "Lowest Price", price: "₹21,000"},
    {date: new Date(2024, 2, 4), status: "Lowest Price", price: "₹11,999"},
    {date: new Date(2024, 2, 5), status: "Lowest Price", price: "₹25,000"},
    {date: new Date(2024, 2, 6), status: "Lowest Price", price: "₹12,999"},
    {date: new Date(2024, 2, 10), status: "Regular Price", price: "₹18,999"},
    {date: new Date(2024, 2, 16), status: "Sold Out", price: "₹16,999"},
    {date: new Date(2024, 2, 22), status: "On Request", price: "₹17,250"},
    {date: new Date(2024, 2, 12), status: "Selling Fast", price: "₹32,999"},
    // Add more dates with different statuses
  ];
  const [calendarData, setCalendarData] = useState([]);

  const getDayStatus = date => {
    const day = calendarData.find(entry => isSameDay(entry.date, date));
    return day ? (day.status ? "Available" : "") : null;
  };

  const getDayPrice = date => {
    const day = calendarData.find(entry => isSameDay(entry.date, date));
    return day ? (day.price ? "₹" + day.price : "") : null;
  };

  const handleDateClick = date => {
    const selectedDay = calendarData.find(entry => isSameDay(entry.date, date));
    // setSelectedDate(date);
  };

  const tileContent = ({date}) => {
    const status = getDayStatus(date);
    const price = getDayPrice(date);
    return (
      <div className="eachDtls">
        <p className="prc">{price}</p>
        <p className="sts">{status}</p>
      </div>
    );
  };

  const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  };

  const getStatusForDate = date => {
    const dataForDate = calendarData.find(entry => isSameDay(entry.date, date));

    if (dataForDate) {
      return dataForDate.status.toLowerCase().replace(" ", "-");
    }

    return "";
  };

  const tileClassName = ({date}) => {
    const status = getStatusForDate(date);
    const isCurrentDate =
      date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear();

    // Check if the date is before the present day
    const isPreviousDate = date < new Date();

    // Return a string of classes separated by a space
    return `${status} ${isCurrentDate ? "current-date" : ""} ${isPreviousDate ? "previous-date" : ""}`.trim();
  };

  const getPackageDetails = async () => {
    try {
      let response = await getPackageDetailsApi(packageId);
      if (response) {
        if (response.data.res === true) {
          const calPromises = response.data.data.seat_availability.map(async item => {
            const date = item?.date ? new Date(item?.date) : null;
            const price = parseFloat(item?.cost);
            const seat = parseInt(item?.seat);
            let withTFormat = date.toLocaleDateString("en-IN");

            return {
              id: item?.id,
              date: date,
              status: "Lowest Price",
              price: isNaN(price) ? 0 : price, // Ensure a valid number is set
              seat: isNaN(seat) ? 0 : seat, // Ensure a valid number is set
            };
          });

          const tempCalArray = await Promise.all(calPromises);

          setCalendarData(tempCalArray);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (packageId) {
      getPackageDetails();
      let WMTBookingObj = JSON.parse(sessionStorage.getItem("WMTBookingObj"));
      if (!WMTBookingObj?.selectedTourStartDate || parseInt(packageId) !== parseInt(WMTBookingObj.packageId)) {
        navigate("/");
      } else {
        let WMTBookingObj = JSON.parse(sessionStorage.getItem("WMTBookingObj"));
        const date = new Date(WMTBookingObj?.selectedTourStartDate);

        // Set the selected date directly, without formatting
        setSelectedDate(date);
      }
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Booking`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Booking list page" />
      </Helmet>
      <Header />
      <div className="innerDiv">
        <div className="UserAfterLogin">
          <div className="container">
            <h3>Check Price & Availability</h3>
            {/* <h3>Check Price & Availability from New Delhi</h3> */}
            <span className="SubHeaderText">Departure Date Selected - Sat, 30 Nov, 2023</span>
            <div className="UserWhiteBox">
              <div className="InnerBox">
                <ul className="row InnerBoxList">
                  <li className="col-md-6">
                    <div className="BorderBox">
                      <h3>Rooms & Travellers:</h3>
                      <ul className="RoomsList">
                        <li>
                          <ul className="SubRoomsList">
                            <li>
                              <h4>Room 1</h4>
                              <a>
                                <img src={deleteImg} alt="" />
                              </a>
                            </li>
                            <li>
                              <label>Adults</label>
                              <div className="NumBox">
                                <button className="PlusButton">
                                  <img src={MinusButton} alt="" />
                                </button>
                                <span className="NumSpan">0</span>
                                <button className="MinusButton">
                                  <img src={PlusButton} alt="" />
                                </button>
                              </div>
                              <span className="noteSpan">(12+ Years)</span>
                            </li>
                            <li>
                              <label>Children</label>
                              <div className="NumBox">
                                <button className="PlusButton">
                                  <img src={MinusButton} alt="" />
                                </button>
                                <span className="NumSpan">0</span>
                                <button className="MinusButton">
                                  <img src={PlusButton} alt="" />
                                </button>
                              </div>
                              <span className="noteSpan">(Below 12 Years)</span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <ul className="SubRoomsList">
                            <li>
                              <h4>Room 1</h4>
                              <a>
                                <img src={deleteImg} alt="" />
                              </a>
                            </li>
                            <li>
                              <label>Adults</label>
                              <div className="NumBox">
                                <button className="PlusButton">
                                  <img src={MinusButton} alt="" />
                                </button>
                                <span className="NumSpan">0</span>
                                <button className="MinusButton">
                                  <img src={PlusButton} alt="" />
                                </button>
                              </div>
                              <span className="noteSpan">(12+ Years)</span>
                            </li>
                            <li>
                              <label>Children</label>
                              <div className="NumBox">
                                <button className="PlusButton">
                                  <img src={MinusButton} alt="" />
                                </button>
                                <span className="NumSpan">0</span>
                                <button className="MinusButton">
                                  <img src={PlusButton} alt="" />
                                </button>
                              </div>
                              <span className="noteSpan">(Below 12 Years)</span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <p className="text-center">
                        <button className="AddAnotherRoomButton">Add another room</button>
                      </p>
                    </div>
                  </li>
                  <li className="col-md-6">
                    <div className="BorderBox">
                      <ul className="row bookingOptionList">
                        <li className="LowestPriceList">
                          <span></span> Lowest Price
                        </li>
                        <li className="RegularPriceList">
                          <span></span> Regular Price
                        </li>
                        <li className="SoldOutList">
                          <span></span> Sold Out
                        </li>
                        <li className="OnRequestList">
                          <span></span> On Request
                        </li>
                        <li className="SellingFastList">
                          <span></span> Selling Fast
                        </li>
                      </ul>
                      <div className="calendarSec">
                        {/* <Calendar tileClassName={tileClassName} tileContent={tileContent} onClickDay={handleDateClick} /> */}
                        <Calendar tileClassName={tileClassName} tileContent={tileContent} onClickDay={handleDateClick} value={selectedDate || new Date()} />
                        {/* {selectedDate && (
                  <div>
                    <p>Date: {selectedDate.toDateString()}</p>
                    <p>Status: {getDayStatus(selectedDate)}</p>
                  </div>
                )} */}
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="AddBottomSec">
                  <h4>Add On:</h4>
                  <ul className="row AddOnList">
                    <li className="col-md-4">
                      <div className="SubBorderBox">
                        <div className="InputBox">
                          <div className="form-check">
                            <input className="form-check-input form-input-check" type="checkbox" value="" />
                          </div>
                        </div>
                        <div className="TextBox">
                          <h3>Lorem Ipsum is simply</h3>
                          <p>It is a long established fact a reader will be</p>
                          <h4>₹500</h4>
                        </div>
                      </div>
                    </li>
                    <li className="col-md-4">
                      <div className="SubBorderBox">
                        <div className="InputBox">
                          <div className="form-check">
                            <input className="form-check-input form-input-check" type="checkbox" value="" />
                          </div>
                        </div>
                        <div className="TextBox">
                          <h3>Lorem Ipsum is simply</h3>
                          <p>It is a long established fact a reader will be</p>
                          <h4>₹500</h4>
                        </div>
                      </div>
                    </li>
                    <li className="col-md-4">
                      <div className="SubBorderBox">
                        <div className="InputBox">
                          <div className="form-check">
                            <input className="form-check-input form-input-check" type="checkbox" value="" />
                          </div>
                        </div>
                        <div className="TextBox">
                          <h3>Lorem Ipsum is simply</h3>
                          <p>It is a long established fact a reader will be</p>
                          <h4>₹500</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="InnerBox LastInnerBox">
                <button className="btn btn-primary CmnBtn">Proceed</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersAvailibility;
