import React, {Component, useState, useRef, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";
import {
  addBankingAccountAgentApi,
  addPackageApi,
  addVendorBankDetailsApi,
  agentDetailsViewApi,
  editAgentDetailsApi,
  editVendorDetailsApi,
  getCityListApi,
  getStatesListApi,
  getThemesListApi,
  getTripListApi,
  getTypeOfTourPackagesListApi,
  vendorDetailsApi,
} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import {Form, Button, Dropdown, Tabs, Tab, Modal} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import panImg from "../assets/img/pan-image.png";
import EmptyImg from "../assets/img/empty-img.svg";
import CancelChequeImg from "../assets/img/cancel-cheque-img.png";
import AuthoriedLetterImg from "../assets/img/Authoried-letter-img.png";
import PdfImg from "../assets/img/pdf-1.png";
import {CAPTCHA_SITE_KEY, IMAGE_URL} from "../utils/commonUrls";
import {useUserContext} from "../context/UserContext";
import {Link} from "react-router-dom";
import AgentSideBar from "../component/Agent-Sidebar";
import AgentHeader from "../component/Agent-Header";
import LoadingPage from "../component/loadingPage";

const arrow = require("./../assets/img/left-arrow.svg").default;

const AgentAccountSetting = () => {
  const navigate = useNavigate();
  const {userData, setUserData} = useUserContext();
  const [key, setKey] = useState("tab1");
  const [show, setShow] = useState(false);
  const [showSection, setShowSection] = useState("main");
  const [vendorDetail, setVendorDetail] = useState();
  const [vendorFormData, setVendorFormData] = useState({
    fisrtName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    fisrtName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
    address: "",
  });
  const [changePassFormData, setChangePassFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [changePassValidationErrors, setChangePassValidationErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [bankFormData, setBankFormData] = useState({
    fullName: "",
    accNumber: "",
    ifscCode: "",
    bankName: "",
    branchName: "",
    cancelCheque: "",
    authorizationLetter: "",
  });
  const [bankValidationErrors, setBankValidationErrors] = useState({
    fullName: "",
    accNumber: "",
    ifscCode: "",
    bankName: "",
    branchName: "",
    cancelCheque: "",
    authorizationLetter: "",
    captchaError: "",
  });
  const [loading, setLoading] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);
  const [bankButtonDis, setBankButtonDis] = useState(false);
  const [profileCompletion, setProfileCompletion] = useState(0);
  const [captchaChecked, setCaptchaChecked] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
    setCaptchaChecked(false);
  };

  const getAgentDetails = async () => {
    setScreenLoading(true);
    try {
      let response = await agentDetailsViewApi();
      if (response) {
        if (response.data.res === true) {
          setVendorDetail(response.data);

          if (response.data.data) {
            let tempUserData = JSON.parse(localStorage.getItem("WMTUserData"));
            if (response.data.data.first_name || response.data.data.last_name) {
              tempUserData.data.agentFirstName = response.data.data.first_name;
              tempUserData.data.agentLastName = response.data.data.last_name;
              localStorage.setItem("WMTUserData", JSON.stringify(tempUserData));
              setUserData(tempUserData);
            }

            setVendorFormData({
              fisrtName: response.data.data.first_name,
              lastName: response.data.data.last_name,
              address: response.data.data.address,
              mobileNumber: response.data.User.mobile,
              emailAddress: response.data.User.email,
            });

            setBankFormData({
              fullName: response.data.data.bank_person_name,
              accNumber: response.data.data.bank_acc_no,
              ifscCode: response.data.data.ifsc_code,
              bankName: response.data.data.bank_name,
              branchName: response.data.data.branch_name,
              cancelCheque: response.data.data.cancelled_cheque,
              authorizationLetter: response.data.data.authorization_letter,
            });

            const fetchAndAppendImage = async (imageUrl, imageId) => {
              try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const file = new File([blob], imageId, {type: "image/*"});
                return file;
              } catch (error) {
                console.error("Error fetching image:", error);
                // Handle the error if needed
                return null;
              }
            };

            // const promises = () => {
            //   const imageUrl = IMAGE_URL + response.data.data.cancelled_cheque;
            //   if (imageUrl) {
            //     return fetchAndAppendImage(imageUrl, response.data.data.cancelled_cheque);
            //   }
            // };

            const handleImageFetching = async (imageKey, imageId) => {
              const imageUrl = IMAGE_URL + response.data.data[imageKey];
              if (imageUrl) {
                try {
                  const file = await fetchAndAppendImage(imageUrl, imageId);
                  return file;
                } catch (error) {
                  console.error("Error fetching image:", error);
                  // Handle the error if needed
                  return null;
                }
              }
              return null;
            };
            Promise.all([
              response.data.data.cancelled_cheque && handleImageFetching("cancelled_cheque", response.data.data.cancelled_cheque?.split("banking_details/")[1]),
              response.data.data.authorization_letter &&
                handleImageFetching("authorization_letter", response.data.data.authorization_letter?.split("banking_details/")[1]),
            ])
              .then(files => {
                const [cancelChequeFile, authorizationLetterFile] = files;
                // Update state with fetched files
                setBankFormData(prevFormData => ({
                  ...prevFormData,
                  cancelCheque: cancelChequeFile,
                  authorizationLetter: authorizationLetterFile,
                }));
              })
              .catch(error => {
                console.error("Error fetching images:", error);
              });

            // Promise.all([promises()])
            //   .then(files => {
            //     const file = files[0];
            //     setBankFormData({
            //       ...bankFormData, cancelCheque: file
            //     })
            //     console.log(file);
            //   })
            //   .catch(error => {
            //     console.error("Error:", error);
            //   });
          } else {
            setVendorFormData(prev => ({...prev, mobileNumber: userData?.data.mobile, emailAddress: userData?.data.email}));
          }
        } else {
        }
        setScreenLoading(false);
      }
    } catch (error) {
      setScreenLoading(false);
    }
  };

  const handleDownload = async (imageUrl, name) => {
    if (imageUrl) {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      NotificationManager.success("Downloaded successfully.");
    }
  };

  const onCaptchaChange = value => {
    // console.log("Captcha value:", value);
    setCaptchaChecked(true);
    setBankValidationErrors(prevErrors => ({...prevErrors, captchaError: ""}));
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      fisrtName: "",
      lastName: "",
      address: "",
      mobileNumber: "",
      emailAddress: "",
    };

    if (vendorFormData.fisrtName === "") {
      errors.fisrtName = "Please enter your fisrt name.";
      isValid = false;
    }

    if (vendorFormData.lastName === "") {
      errors.lastName = "Please enter your last name.";
      isValid = false;
    }

    if (vendorFormData.address === "") {
      errors.address = "Please enter your last name.";
      isValid = false;
    }

    if (vendorFormData.mobileNumber === "" || !/^\d{10}$/.test(vendorFormData.mobileNumber)) {
      errors.mobileNumber = "Please enter a valid 10-digit Mobile Number.";
      isValid = false;
    }

    if (vendorFormData.emailAddress === "" || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(vendorFormData.emailAddress)) {
      errors.emailAddress = "Please enter a valid Email Address.";
      isValid = false;
    }

    setValidationErrors(errors);
    return isValid;
  };

  const changePassValidateForm = () => {
    let isValid = true;
    const errors = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };

    if (changePassFormData.oldPassword === "") {
      errors.oldPassword = "Please enter old password.";
      isValid = false;
    }

    if (changePassFormData.newPassword === "") {
      errors.newPassword = "Please enter new password.";
      isValid = false;
    }

    if (changePassFormData.confirmPassword === "") {
      errors.confirmPassword = "Please enter confirm password.";
      isValid = false;
    }

    setChangePassValidationErrors(errors);
    return isValid;
  };

  const bankValidateForm = () => {
    let isValid = true;
    const errors = {
      fullName: "",
      accNumber: "",
      ifscCode: "",
      bankName: "",
      branchName: "",
      cancelCheque: "",
      authorizationLetter: "",
      captchaError: "",
    };

    if (!bankFormData.fullName) {
      errors.fullName = "Please enter full name.";
      isValid = false;
    }

    if (!bankFormData.accNumber) {
      errors.accNumber = "Please enter account number.";
      isValid = false;
    }

    if (!bankFormData.ifscCode) {
      errors.ifscCode = "Please enter IFSC code.";
      isValid = false;
    }

    if (!bankFormData.bankName) {
      errors.bankName = "Please enter bank name.";
      isValid = false;
    }

    if (!bankFormData.branchName) {
      errors.branchName = "Please enter branch name.";
      isValid = false;
    }

    if (!bankFormData.cancelCheque) {
      errors.cancelCheque = "Please select cancel cheque.";
      isValid = false;
    }

    if (!bankFormData.authorizationLetter) {
      errors.authorizationLetter = "Please select authorization letter.";
      isValid = false;
    }

    if (!captchaChecked) {
      errors.captchaError = "Please check the 'I am not a robot' checkbox";
      isValid = false;
    }

    setBankValidationErrors(errors);
    return isValid;
  };

  const handleInputChange = (field, value) => {
    setVendorFormData(prevData => ({...prevData, [field]: value}));
    setValidationErrors(prevErrors => ({...prevErrors, [field]: ""}));
  };

  const handleBankDetailsInputChange = (field, value) => {
    setBankFormData(prevData => ({...prevData, [field]: value}));
    setBankValidationErrors(prevErrors => ({...prevErrors, [field]: ""}));
  };

  const handleEditVendorDetails = async () => {
    if (validateForm()) {
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("first_name", vendorFormData.fisrtName);
        formData.append("last_name", vendorFormData.lastName);
        formData.append("address", vendorFormData.address);
        formData.append("email", vendorFormData.emailAddress);
        formData.append("mobile", vendorFormData.mobileNumber);
        // Api call
        let response = await editAgentDetailsApi(formData);
        if (response) {
          if (response.data.res === true) {
            NotificationManager.success(response.data.msg);
            getAgentDetails();
            setShowSection("main");
          } else {
            // setValidationError(response.data.msg);
            NotificationManager.warning(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleSaveBankDetails = async () => {
    if (bankValidateForm()) {
      setBankButtonDis(true);
      try {
        let formData = new FormData();
        formData.append("bank_person_name", bankFormData.fullName);
        formData.append("bank_acc_no", bankFormData.accNumber);
        formData.append("ifsc_code", bankFormData.ifscCode);
        formData.append("bank_name", bankFormData.bankName);
        formData.append("branch_name", bankFormData.branchName);
        formData.append("cancelled_cheque", bankFormData.cancelCheque);
        // formData.append("authorization_letter", bankFormData.authorizationLetter);
        formData.append("bank_verified", "0");
        // Api call
        let response = await addBankingAccountAgentApi(formData);
        if (response) {
          if (response.data.res === true) {
            NotificationManager.success(response.data.msg);
            getAgentDetails();
            handleClose();
          } else {
            // setValidationError(response.data.msg);
            NotificationManager.warning(response.data.msg);
          }
          setBankButtonDis(false);
        }
      } catch (error) {
        setBankButtonDis(false);
      }
    }
  };

  useEffect(() => {
    getAgentDetails();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Account settings`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Account settings page" />
      </Helmet>
      <AgentHeader />
      <div className="venderInnerDiv">
        <AgentSideBar />

        <div className="venderRight">
          {screenLoading && <LoadingPage />}
          <div className="hdrSec">
            <h2>
              {showSection === "edit" || showSection === "changePassword" ? (
                <Button className="btnBack" variant="link" onClick={() => setShowSection("main")}>
                  <img src={arrow} alt="" />
                </Button>
              ) : null}
              My Profile
            </h2>
          </div>
          <div className="venderMain venderMain2">
            <div className="OverlaySec">
              <div className="seller-accounts">
                {showSection === "main" ? (
                  <div className="hide-tabs-sec">
                    {/* <h4>Your Profile</h4> */}
                    <ul className="row profile-list">
                      <li className="col-md-4">
                        <label>First Name</label>
                        <span className="InputValue">{vendorDetail?.data?.first_name}</span>
                      </li>
                      <li className="col-md-4">
                        <label>Last Name</label>
                        <span className="InputValue">{vendorDetail?.data?.last_name}</span>
                      </li>
                      <li className="col-md-4">
                        <label>Email</label>
                        <span className="InputValue">{vendorDetail?.User?.email ? vendorDetail?.User?.email : null}</span>
                      </li>
                      <li className="col-md-4">
                        <label>Phone Number</label>
                        <span className="InputValue">{vendorDetail?.User?.mobile ? vendorDetail?.User?.mobile : null}</span>
                      </li>
                      <li className="col-md-4">
                        <label>Address</label>
                        <span className="InputValue">{vendorDetail?.data?.address}</span>
                      </li>
                      <li className="col-md-4">
                        <label>Agent Code</label>
                        <span className="InputValue">{vendorDetail?.data?.agent_code}</span>
                      </li>
                      <li className="col-md-4">
                        <label>Commission Rate</label>
                        <span className="InputValue">{vendorDetail?.agentCommission ? vendorDetail?.agentCommission : 0}%</span>
                      </li>
                      <li className="col-md-4">
                        <label>TDS Rate</label>
                        <span className="InputValue">{vendorDetail?.tdsRate ? vendorDetail?.tdsRate : 0}%</span>
                      </li>
                      <li className="col-md-4">
                        {/* <label>Address</label>
                          <span className="InputValue">{vendorDetail?.data?.address}</span> */}
                        <div className="hideMobile">
                          {/* <br /> */}
                          <label>PAN Number</label>
                          <span className="InputValue">{vendorDetail?.data?.pan_number}</span>
                        </div>
                      </li>
                      {vendorDetail?.data?.pan_number ? (
                        <li className="col-md-4">
                          <label>PAN Photo</label>
                          {vendorDetail?.data?.pan_card_file?.endsWith(".pdf") ? (
                            <div className="download-box">
                              <img src={PdfImg} alt="" />
                              <button
                                className="btn download-btn"
                                type="button"
                                onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.pan_card_file, "pancard.pdf")}>
                                Download
                              </button>
                            </div>
                          ) : (
                            <div className="download-box">
                              <img src={IMAGE_URL + vendorDetail?.data?.pan_card_file} alt="" />
                              <button
                                className="btn download-btn"
                                type="button"
                                onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.pan_card_file, "pancard.jpg")}>
                                Download
                              </button>
                            </div>
                          )}
                        </li>
                      ) : null}

                      <li className="col-md-4 hideWeb">
                        <label>PAN Number</label>
                        <span className="InputValue">{vendorDetail?.data?.pan_number}</span>
                      </li>
                      <li className="col-md-12">
                        {/* <a className="btn btn-primary edit-btn CmnBtn" onClick={() => setShowSection("edit")}>
                          Edit
                        </a> */}
                        {/* <a className="btn change-password-btn OutLineBtn" onClick={() => setShowSection("changePassword")}>
                        Change Password
                      </a> */}
                      </li>
                    </ul>
                    <hr />
                    <div className="OverlaySec">
                      <div className="body-header-box">
                        <h3>Financial Details</h3>
                        {vendorDetail?.data?.bank_verified === 0 ? (
                          !vendorDetail?.data?.bank_acc_no ? (
                            <button className="btn OutLineBtn" onClick={handleShow}>
                              Add Bank Details
                            </button>
                          ) : (
                            <button className="btn OutLineBtn" onClick={handleShow}>
                              Update Bank Details
                            </button>
                          )
                        ) : null}
                      </div>
                      {!vendorDetail?.data?.bank_acc_no ? (
                        <div className="empty-financial-details">
                          <img src={EmptyImg} alt="" />
                        </div>
                      ) : (
                        <ul className="row profile-list">
                          <li className="col-md-4">
                            <label>Full Namer</label>
                            <span className="InputValue">{vendorDetail?.data?.bank_person_name}</span>
                          </li>
                          <li className="col-md-4">
                            <label>Bank Account No.</label>
                            <span className="InputValue">{vendorDetail?.data?.bank_acc_no}</span>
                          </li>
                          <li className="col-md-4">
                            <label>IFSC Code</label>
                            <span className="InputValue">{vendorDetail?.data?.ifsc_code}</span>
                          </li>
                          <li className="col-md-4">
                            <label>Bank Name</label>
                            <span className="InputValue">{vendorDetail?.data?.bank_name}</span>
                          </li>
                          <li className="col-md-4">
                            <label>Branch Name</label>
                            <span className="InputValue">{vendorDetail?.data?.branch_name}</span>
                          </li>
                          <li className="col-md-4">&nbsp;</li>
                          <li className="col-md-4">
                            <label>Cancelled Check</label>
                            <div className="download-box">
                              <img src={IMAGE_URL + vendorDetail?.data?.cancelled_cheque} alt="" />
                              <button
                                className="btn download-btn"
                                type="button"
                                onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.cancelled_cheque, "cancelled_cheque.jpg")}>
                                Download
                              </button>
                            </div>
                          </li>
                          {/* <li className="col-md-4">
                            <label>Authorization Letter</label>
                            <div className="download-box">
                              <img src={IMAGE_URL + vendorDetail?.data?.authorization_letter} alt="" />
                              <button
                                className="btn download-btn"
                                type="button"
                                onClick={() => handleDownload(IMAGE_URL + vendorDetail?.data?.authorization_letter, "authorization_letter.jpg")}>
                                Download
                              </button>
                            </div>
                          </li> */}
                        </ul>
                      )}
                      {vendorDetail?.data.bank_acc_no && vendorDetail?.data.bank_verified !== 1 ? (
                        <div className="OverlayTextSec">
                          <span>Bank Details Not Verified Yet.</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {showSection === "edit" ? (
                  <div className="normal-accrounts-sec">
                    <h3>Edit Profile</h3>
                    <ul className="row edit-profile-form-list">
                      <li className="col-md-4">
                        <label>Fisrt Name</label>
                        <input
                          type="text"
                          name=""
                          value={vendorFormData.fisrtName}
                          className="form-control"
                          onChange={e => handleInputChange("fisrtName", e.target.value)}
                        />
                        {validationErrors.fisrtName && <span className="validationErrorMsg">{validationErrors.fisrtName}</span>}
                      </li>
                      <li className="col-md-4">
                        <label>Last Name</label>
                        <input
                          type="text"
                          name=""
                          value={vendorFormData.lastName}
                          className="form-control"
                          onChange={e => handleInputChange("lastName", e.target.value)}
                        />
                        {validationErrors.lastName && <span className="validationErrorMsg">{validationErrors.lastName}</span>}
                      </li>
                      <li className="col-md-4">
                        <label>Address</label>
                        <input
                          type="text"
                          name=""
                          value={vendorFormData.address}
                          className="form-control"
                          onChange={e => handleInputChange("address", e.target.value)}
                        />
                        {validationErrors.address && <span className="validationErrorMsg">{validationErrors.address}</span>}
                      </li>
                      <li className="col-md-4">
                        <label>Email</label>
                        <input
                          type="email"
                          name=""
                          readOnly={vendorDetail?.User?.email ? true : false}
                          value={vendorFormData.emailAddress}
                          className="form-control"
                          onChange={e => handleInputChange("emailAddress", e.target.value)}
                        />
                        {validationErrors.emailAddress && <span className="validationErrorMsg">{validationErrors.emailAddress}</span>}
                      </li>
                      <li className="col-md-4">
                        <label>Phone Number</label>
                        <input
                          type="tel"
                          name=""
                          readOnly={vendorDetail?.User?.mobile ? true : false}
                          value={vendorFormData.mobileNumber}
                          className="form-control"
                          onChange={e => handleInputChange("mobileNumber", e.target.value)}
                        />
                        {validationErrors.mobileNumber && <span className="validationErrorMsg">{validationErrors.mobileNumber}</span>}
                      </li>
                      <li className="col-md-12">
                        {loading ? (
                          <input type="submit" name="" value="Please wait.." className="btn btn-primary CmnBtn" />
                        ) : (
                          <input type="submit" name="" value="Save" className="btn btn-primary CmnBtn" onClick={() => handleEditVendorDetails()} />
                        )}
                      </li>
                    </ul>
                  </div>
                ) : null}

                {showSection === "changePassword" ? (
                  <div className="normal-accrounts-sec">
                    <h3>Change Password</h3>
                    <ul className="row edit-profile-form-list">
                      <li className="col-md-4">
                        <label>Existing Password</label>
                        <input type="password" name="" value="" placeholder="Existing Password" className="form-control" />
                      </li>
                      <li className="col-md-4">
                        <label>New Password</label>
                        <input type="password" name="" value="" placeholder="New Password" className="form-control" />
                      </li>
                      <li className="col-md-4">
                        <label>Confirm New Password</label>
                        <input type="password" name="" value="" placeholder="Confirm New Password" className="form-control" />
                      </li>
                      <li className="col-md-12">
                        <input type="submit" name="" value="Save" className="btn btn-primary CmnBtn" />
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              {vendorDetail?.data.is_verified !== 1 ? (
                <div className="OverlayTextSec agentOverlay">
                  <span>Your Account is Not Verified Yet.</span>
                </div>
              ) : null}
            </div>

            {/* Add Bank Modal Start */}
            <Modal show={show} onHide={handleClose} className="CmnModal AddBankdModal">
              <Modal.Header closeButton>
                <Modal.Title>{!vendorDetail?.data?.bank_acc_no ? "Add Bank Details" : "Update Bank Details"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul className="row company-form-list">
                  <li className="col-md-12">
                    <label>Full Name*</label>
                    <input
                      type="text"
                      name=""
                      value={bankFormData?.fullName}
                      placeholder="Full Name*"
                      className="form-control"
                      onChange={e => handleBankDetailsInputChange("fullName", e.target.value)}
                    />
                    {bankValidationErrors.fullName && <span className="validationErrorMsg">{bankValidationErrors.fullName}</span>}
                  </li>
                  <li className="col-md-6">
                    <label>Bank Account No.*</label>
                    <input
                      type="text"
                      name=""
                      value={bankFormData?.accNumber}
                      placeholder="Bank Account No.*"
                      className="form-control"
                      onChange={e => handleBankDetailsInputChange("accNumber", e.target.value)}
                    />
                    {bankValidationErrors.accNumber && <span className="validationErrorMsg">{bankValidationErrors.accNumber}</span>}
                  </li>
                  <li className="col-md-6">
                    <label>IFSC Code*</label>
                    <input
                      type="text"
                      name=""
                      value={bankFormData?.ifscCode}
                      placeholder="IFSC Code*"
                      className="form-control"
                      onChange={e => handleBankDetailsInputChange("ifscCode", e.target.value)}
                    />
                    {bankValidationErrors.ifscCode && <span className="validationErrorMsg">{bankValidationErrors.ifscCode}</span>}
                  </li>
                  <li className="col-md-6">
                    <label>Bank Name*</label>
                    <input
                      type="text"
                      name=""
                      value={bankFormData?.bankName}
                      placeholder="Bank Name*"
                      className="form-control"
                      onChange={e => handleBankDetailsInputChange("bankName", e.target.value)}
                    />
                    {bankValidationErrors.bankName && <span className="validationErrorMsg">{bankValidationErrors.bankName}</span>}
                  </li>
                  <li className="col-md-6">
                    <label>Branch Name*</label>
                    <input
                      type="text"
                      name=""
                      value={bankFormData?.branchName}
                      placeholder="Branch Name*"
                      className="form-control"
                      onChange={e => handleBankDetailsInputChange("branchName", e.target.value)}
                    />
                    {bankValidationErrors.branchName && <span className="validationErrorMsg">{bankValidationErrors.branchName}</span>}
                  </li>
                  <li className="col-md-6">
                    <label>Upload Cancelled Check*</label>
                    <div className="upload-file-box">
                      <input
                        type="file"
                        className="input-file"
                        accept="image/*"
                        onChange={e => handleBankDetailsInputChange("cancelCheque", e.target.files[0])}
                      />
                      <input
                        type="text"
                        name=""
                        readOnly
                        value={bankFormData?.cancelCheque?.name && bankFormData?.cancelCheque?.name !== "undefined" ? bankFormData?.cancelCheque?.name : ""}
                        placeholder="Upload Cancelled Check*"
                        className="form-control"
                      />
                      <button variant="primary" className="upload-btn">
                        Upload
                      </button>
                    </div>
                    {bankValidationErrors.cancelCheque && <span className="validationErrorMsg">{bankValidationErrors.cancelCheque}</span>}
                  </li>
                  <li className="col-md-6">
                    <label>Upload Authorization Letter</label>
                    <div className="upload-file-box">
                      <input
                        type="file"
                        className="input-file"
                        accept="image/*"
                        onChange={e => handleBankDetailsInputChange("authorizationLetter", e.target.files[0])}
                      />
                      <input
                        type="text"
                        name=""
                        readOnly
                        value={
                          bankFormData?.authorizationLetter?.name && bankFormData?.authorizationLetter?.name !== "undefined"
                            ? bankFormData?.authorizationLetter?.name
                            : ""
                        }
                        placeholder="Authorization Letter"
                        className="form-control"
                      />
                      <button variant="primary" className="upload-btn">
                        Upload
                      </button>
                    </div>
                    {bankValidationErrors.authorizationLetter && <span className="validationErrorMsg">{bankValidationErrors.authorizationLetter}</span>}
                  </li>
                  <li className="col-md-12">
                    <label>Captcha</label>
                    <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
                    {bankValidationErrors.captchaError && <span className="validationErrorMsg">{bankValidationErrors.captchaError}</span>}
                  </li>
                </ul>
              </Modal.Body>
              <Modal.Footer>
                {bankButtonDis ? (
                  <button variant="primary" disabled className="btn btn-primary CmnBtn">
                    Please wait ...
                  </button>
                ) : (
                  <button variant="primary" onClick={() => handleSaveBankDetails()} className="btn btn-primary CmnBtn">
                    {!vendorDetail?.data?.bank_acc_no ? "Add" : "Update"}
                  </button>
                )}
              </Modal.Footer>
            </Modal>
            {/* Add Bank Modal End */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentAccountSetting;
