import React, {useState} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {Link, useNavigate} from "react-router-dom";

const theme1 = require("./../assets/img/theme1.png");

const Support = () => {
  const Accordion = ({items}) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const onTitleClick = index => {
      setActiveIndex(activeIndex === index ? null : index);
    };

    const renderedItems = items.map((item, index) => {
      const active = index === activeIndex ? "active" : "";

      return (
        <div key={item.title} className="item">
          <div className={`title ${active}`} onClick={() => onTitleClick(index)}>
            <i className="dropdown icon"></i>
            {item.title}
          </div>
          <div className={`content ${active}`}>
            <p>{item.content}</p>
          </div>
        </div>
      );
    });

    return <div className="ui styled accordion">{renderedItems}</div>;
  };

  const accordionItems = [
    {
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words",
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words",
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words",
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words",
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words",
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words",
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words",
    },
    {
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting industry?",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words",
    },
  ];

  return (
    <div className="main">
      <Header />

      <div className="innerDiv">
        <div className="themeDiv pplrthemeDiv">
          <div className="mainDiv">
            <h2>Support</h2>
            <div className="supportDiv">
              <Accordion items={accordionItems} />
              <Link to="" className="supportviewBtn">
                Load More
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Support;
