import React, {useState, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";
import {agentBookingListApi, changePackageStatusApi, getBookingListApi} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import {Form, Button, Dropdown} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AgentHeader from "../component/Agent-Header";
import AgentSideBar from "../component/Agent-Sidebar";
import LoadingPage from "../component/loadingPage";
const crossIcon2 = require("./../assets/img/cross.svg").default;
const filterBtn = require("./../assets/img/filter-icon.svg").default;

const AgentBookingList = () => {
  const navigate = useNavigate();
  const [packageList, setPackageList] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [screenLoading, setScreenLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [dynamicTableKey, setDynamicTableKey] = useState(1);
  const [filterParameter, setFilterParameter] = useState({
    filterType: 1,
    startDate: null,
    endDate: null,
    isCancelledBooking: false,
    bookingStatus: null,
    phoneNumber: null,
    customerName: null,
    destination: null,
    originDestination: null,
    bookingId: null,
  });
  const [applyFilterBtn, setApplyFilterBtn] = useState(false);

  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const [bookingStatusArray, setBookingStatusArray] = useState([
    {id: 1, value: "In Process"},
    {id: 2, value: "Confirmed"},
    {id: 3, value: "Completed"},
    {id: 4, value: "Cancelled"},
  ]);

  const columns = [
    {
      name: "Booking ID",
      selector: row => row.booking_id,
      sortable: true,
      cell: row => (
        <Link to={`/partners/booking/detail/${row.booking_id}`} target="_blank">
          #{row.booking_id}
        </Link>
      ),
      minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: "Booking Date",
      selector: row => {
        const originalDateString = row.created_at;

        // Parse the original date string into a Date object
        const originalDate = new Date(originalDateString);

        // Months array for mapping month indexes to month names
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Get the month, day, and year from the parsed date
        const monthIndex = originalDate.getMonth();
        const day = originalDate.getDate();
        const year = originalDate.getFullYear();

        // Format the date string
        const formattedDateString = `${day < 10 ? "0" + day : day} ${months[monthIndex]},  ${year}`;
        return formattedDateString;
      },
      sortable: true,
      minWidth: "135px",
      maxWidth: "13%",
    },
    {
      name: "Tour Operator",
      selector: row => {
        const maxLength = 30; // Maximum number of characters before adding ellipsis
        const packageName = row.vendor_fullname;
        return packageName.length > maxLength ? packageName.slice(0, maxLength) + "..." : packageName;
      },
      sortable: true,
      minWidth: "140px",
      maxWidth: "14%",
    },
    {
      name: "Travel Date",
      selector: row => {
        const originalDateString = row.first_booking_date;

        // Parse the original date string into a Date object
        const originalDate = new Date(originalDateString);

        // Months array for mapping month indexes to month names
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Get the month, day, and year from the parsed date
        const monthIndex = originalDate.getMonth();
        const day = originalDate.getDate();
        const year = originalDate.getFullYear();

        // Format the date string
        const formattedDateString = `${day < 10 ? "0" + day : day} ${months[monthIndex]},  ${year}`;
        return formattedDateString;
      },
      sortable: true,
      minWidth: "135px",
      maxWidth: "13%",
    },
    // {
    //   name: "Package Title",
    //   selector: row => {
    //     const maxLength = 30; // Maximum number of characters before adding ellipsis
    //     const packageName = row.package_name;
    //     return packageName.length > maxLength ? packageName.slice(0, maxLength) + "..." : packageName;
    //   },
    //   sortable: true,
    //   minWidth: "240px",
    //   maxWidth: "14%",
    // },
    {
      name: "Customer Name",
      selector: row => row.name,
      sortable: true,
      minWidth: "149px",
      maxWidth: "17%",
    },
    {
      name: "Pax",
      selector: row => row.total_pax,
      cell: row => (row.total_pax < 10 ? "0" + row.total_pax : row.total_pax),
      sortable: true,
      minWidth: "85px",
      maxWidth: "12%",
    },
    {
      name: "Booking Status",
      selector: row => {
        const bookingStatus = row.booking_status;
        return (
          <span
            className={
              bookingStatus === "Completed"
                ? `blueStatusText`
                : bookingStatus === "Ongoing"
                ? `greenStatusText`
                : bookingStatus === "Confirmed" || bookingStatus === "Upcoming"
                ? // : bookingStatus === "Upcoming"
                  `yellowStatusText`
                : bookingStatus === "Cancelled"
                ? `redStatusText`
                : bookingStatus === "In process"
                ? `pinkStatusText`
                : bookingStatus === "Modified"
                ? `violetStatusText`
                : ``
            }>
            {bookingStatus}
          </span>
        );
      },
      // selector: (row) => row.booking_status,
      sortable: true,
      minWidth: "120px",
      maxWidth: "12%",
    },
    {
      name: "Payment Status",
      // selector: row => row?.payment_status,
      selector: row => {
        const paymentStatus = row.payment_status;
        return (
          <span className={paymentStatus === "Partially Paid" ? `yellowStatusText` : paymentStatus === "Paid" ? `greenStatusText` : ``}>{paymentStatus}</span>
        );
      },
      sortable: true,
      minWidth: "100px",
      maxWidth: "12%",
    },
    {
      name: "Amount",
      selector: row => "₹" + Math.round(row.booking_cost),
      sortable: true,
      minWidth: "100px",
      maxWidth: "12%",
    },
  ];

  const [showPopup, setShowPopup] = useState(false);

  const [showPopup2, setShowPopup2] = useState(false);

  const [showPopup3, setShowPopup3] = useState(false);
  const filterPopup = () => {
    setShowPopup3(true);
  };

  const getBookingList = async status => {
    if (status === "initial" && setLoading(true));
    setScreenLoading(true);
    function formatDate(date) {
      return new Intl.DateTimeFormat("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(date);
    }
    const formData = new FormData();
    try {
      const formData = new FormData();
      {
        status && status !== "initial" && formData.append("status", status ? status : "");
      }

      if (filterParameter.filterType === 1 && filterParameter.startDate && !filterParameter.endDate) {
        NotificationManager.warning("Please select an end date.");
        setLoading(false);
        setScreenLoading(false);
        return;
      }
      if (filterParameter.filterType === 1 && !filterParameter.startDate && filterParameter.endDate) {
        NotificationManager.warning("Please select an start date.");
        setScreenLoading(false);
        return;
      }
      formData.append("start_date", filterParameter?.startDate ? formatDate(new Date(filterParameter?.startDate)) : "");
      formData.append("end_date", filterParameter?.endDate ? formatDate(new Date(filterParameter?.endDate)) : "");
      formData.append("booking_id", filterParameter?.bookingId ? filterParameter?.bookingId : "");
      formData.append("phone_number", filterParameter?.phoneNumber ? filterParameter?.phoneNumber : "");
      formData.append("name", filterParameter?.customerName ? filterParameter?.customerName : "");
      formData.append("booking_status", filterParameter?.bookingStatus ? filterParameter?.bookingStatus : "");

      let response = await agentBookingListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setPackageList(response.data.data);
        } else {
        }
        setLoading(false);
        setScreenLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setScreenLoading(false);
    }
  };

  // Filter packageList based on the search input
  const filteredPackageList = packageList.filter(
    item =>
      item?.booking_status?.toString().toLowerCase().includes(search?.toLowerCase()) ||
      item?.booking_id?.toString().toLowerCase().includes(search?.toLowerCase()) ||
      item?.payment_status?.toString().toLowerCase().includes(search?.toLowerCase())
  );

  const handleCheckBoxChange = state => {
    let tempArray = [];
    if (state?.selectedRows?.length > 0) {
      state?.selectedRows?.map(item => {
        tempArray.push(item?.package_id);
      });
    }
    setSelectedCheckboxIds(tempArray);
  };

  const handleActionChange = async (actionType, status) => {
    setDynamicTableKey(prev => prev + 1);
    try {
      const formData = new FormData();
      if (selectedCheckboxIds?.length > 0) {
        selectedCheckboxIds?.map(item => {
          formData.append("package_ids[]", item);
        });
      }
      formData.append("status", status ? status : "");

      let response = await changePackageStatusApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          if (activeTab === "active" || activeTab === "inactive") {
            const updatedPackageList = packageList.filter(item => !selectedCheckboxIds.includes(parseInt(item.package_id)));
            setPackageList(updatedPackageList);
          } else {
            if (status === "3") {
              const updatedPackageList = packageList.filter(item => !selectedCheckboxIds.includes(parseInt(item.package_id)));
              setPackageList(updatedPackageList);
            } else if (status === "0") {
              const updatedPackageList = packageList.map(item => {
                if (selectedCheckboxIds.includes(parseInt(item.package_id))) {
                  return {...item, status: "Inactive"}; // Assuming 'status' is the property to update
                }
                return item;
              });
              setPackageList(updatedPackageList);
            } else if (status === "1") {
              const updatedPackageList = packageList.map(item => {
                if (selectedCheckboxIds.includes(parseInt(item.package_id))) {
                  return {...item, status: "Active"}; // Assuming 'status' is the property to update
                }
                return item;
              });
              setPackageList(updatedPackageList);
            } else {
            }
          }
          setSelectedCheckboxIds([]);
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {}
  };

  const handleDuplicate = async () => {
    if (selectedCheckboxIds.length > 1) {
      NotificationManager.warning("You have to select only one package to duplicate.");
    } else {
      navigate("/business/package/duplicate/" + selectedCheckboxIds[0]);
    }
  };

  useEffect(() => {
    // Clear selected checkboxes when packageList is updated
    setSelectedCheckboxIds([]);
    getBookingList("initial");
  }, [applyFilterBtn]);

  const [selectedTab, setSelectedTab] = useState("Date Range");
  const handleTabClick = tab => {
    setSelectedTab(tab);
  };

  const handleReserFilter = async () => {
    setFilterParameter(prev => ({
      ...prev,
      startDate: null,
      endDate: null,
      isCancelledBooking: false,
      bookingStatus: null,
      phoneNumber: null,
      customerName: null,
      destination: null,
      originDestination: null,
      bookingId: null,
    }));
    setApplyFilterBtn(!applyFilterBtn);
    setShowPopup3(false);
  };

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Booking List`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Booking list page" />
      </Helmet>
      <AgentHeader />
      <div className="venderInnerDiv">
        <AgentSideBar />

        <div className="venderRight">
          {screenLoading && <LoadingPage />}
          <div className="hdrSec">
            <h2>Bookings</h2>
            {/* <div className="btnsHdn">
              <Link to="/" className="offLink">
                Offline Booking
              </Link>
              <button className="saveBtn saveBtn2" onClick={() => uploadCSV()}>
                Upload CSV
              </button>
            </div> */}
          </div>
          <div className="venderMain">
            {/* <ul className="navbar packages-list">
              <li className={activeTab === "all" ? "active" : ""}>
                <Link
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("all");
                    getBookingList();
                  }}>
                  All
                </Link>
              </li>
              <li className={activeTab === "active" ? "active" : ""}>
                <Link
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("active");
                    getBookingList("1");
                  }}>
                  In Process
                </Link>
              </li>
              <li className={activeTab === "inactive" ? "active" : ""}>
                <Link
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("inactive");
                    getBookingList("0");
                  }}>
                  Cancellation Approved
                </Link>
              </li>
            </ul> */}
            <div className="data-table-section bookDataSec">
              {!loading ? (
                <DataTable
                  key={activeTab + dynamicTableKey}
                  className="Tbl packages-listing booking-listing"
                  columns={columns}
                  data={filteredPackageList}
                  fixedHeader
                  // fixedHeaderScrollHeight="300px"
                  highlightOnHover
                  pagination
                  //paginationComponentOptions={paginationComponentOptions}
                  responsive
                  selectableRows
                  selectableRowsHighlight
                  subHeader
                  subHeaderAlign="left"
                  onSelectedRowsChange={handleCheckBoxChange}
                  // onRowClicked={row => {
                  //   const url = `/partners/booking/detail/${row.booking_id}`;
                  //   window.open(url, "_blank"); // Opens in a new tab
                  // }}
                  // onRowClicked={row => navigate(`/booking/detail/${row.booking_id}`)}
                  // onRowClicked={row => navigate(`/partners/booking/${row.booking_id}`)}
                  subHeaderComponent={
                    <>
                      <div className="search-box">
                        <img src={filterBtn} alt="" className="filterBtn" onClick={() => filterPopup()} />
                        <Form.Control type="text" className="SrchInpt" placeholder="Search here" value={search} onChange={e => setSearch(e.target.value)} />
                      </div>
                      {/* <div className="ActionRow">
                        <span className="LftField">
                          <span>Action:</span>{" "}
                          <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 || activeTab === "inactive" ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 || activeTab === "inactive" ? true : false}
                            onClick={() => handleActionChange("inactive", "0")}>
                            Generate Invoice
                          </button>
                        </span>
                      </div> */}
                    </>
                  }
                />
              ) : null}
            </div>
          </div>
        </div>

        {showPopup && (
          <div className={`popup addPopup`}>
            <div className="sliderflex">
              <div className="popInner2 bookingPopup">
                <h2>Message Admin</h2>
                <ul className="formList">
                  <li className="fullWd">
                    <textarea>Type message</textarea>
                  </li>
                  <li className="halfWd">
                    <label>Package</label>
                    <select>
                      <option>Sun Kissed GOA Escaped</option>
                    </select>
                  </li>
                  <li className="smlWd">
                    <label>Package ID</label>
                    <input type="text" placeholder="#551236" />
                  </li>
                  <li className="halfWd">
                    <label>Run Date</label>
                    <select>
                      <option>March 20, 2024</option>
                    </select>
                  </li>
                  <li className="fullWd">
                    <button className="addBtn addBtn2">Send Message</button>
                  </li>
                </ul>
                <button className="closePopupBtn2" onClick={() => setShowPopup(false)}>
                  <img src={crossIcon2} alt="" />
                </button>
              </div>
            </div>
          </div>
        )}

        {showPopup2 && (
          <div className={`popup addPopup`}>
            <div className="sliderflex">
              <div className="popInner2 bookingPopup">
                <h2>Upload CSV</h2>
                <ul className="formList">
                  <li className="fullWd">
                    <label>File</label>

                    <input type="file" />
                  </li>

                  <li className="fullWd">
                    <button className="addBtn">Save</button>
                  </li>
                </ul>
                <button className="closePopupBtn2" onClick={() => setShowPopup2(false)}>
                  <img src={crossIcon2} alt="" />
                </button>
              </div>
            </div>
          </div>
        )}

        {showPopup3 && (
          <div className={`popup addPopup`}>
            <div className="sliderflex">
              <div className="popInner2 filterPopup">
                <h2>Filter</h2>

                <div className="tabs">
                  {["Date Range", "Single Booking"].map(tab => (
                    <div key={tab} className={`tab ${tab === selectedTab ? "active" : ""}`} onClick={() => handleTabClick(tab)}>
                      {tab}
                    </div>
                  ))}
                </div>

                <div className="tabContent">
                  {selectedTab === "Date Range" && (
                    <div>
                      <ul className="formList">
                        <li>
                          <div className="datePckr2">
                            <DatePicker
                              selected={filterParameter.startDate}
                              onChange={date =>
                                setFilterParameter({
                                  ...filterParameter,
                                  startDate: date,
                                })
                              }
                              // dateFormat="yyyy-MM-dd"
                              dateFormat="MMMM d, yyyy"
                              placeholderText="Booking Date From"
                              // minDate={new Date()}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="datePckr2">
                            <DatePicker
                              selected={filterParameter.endDate}
                              onChange={date =>
                                setFilterParameter({
                                  ...filterParameter,
                                  endDate: date,
                                })
                              }
                              // dateFormat="yyyy-MM-dd"
                              dateFormat="MMMM d, yyyy"
                              placeholderText="Booking Date To"
                              // minDate={new Date()}
                            />
                          </div>
                        </li>
                        {/* <li>
                          <label class="checkCustom">
                            Cancelled Booking
                            <input
                              type="checkbox"
                              value={filterParameter.isCancelledBooking}
                              onChange={e =>
                                setFilterParameter({
                                  ...filterParameter,
                                  isCancelledBooking: e.target.checked,
                                })
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li> */}
                        {activeTab === "all" ? (
                          <li>
                            <select
                              value={filterParameter.bookingStatus}
                              onChange={e =>
                                setFilterParameter({
                                  ...filterParameter,
                                  bookingStatus: e.target.value,
                                })
                              }>
                              <option selected hidden>
                                Booking Status
                              </option>
                              {bookingStatusArray?.map((item, index) => (
                                <option key={index} value={item?.id}>
                                  {item?.value}
                                </option>
                              ))}
                            </select>
                          </li>
                        ) : null}

                        <li>
                          <input
                            type="number"
                            placeholder="Phone Number"
                            value={filterParameter.phoneNumber}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                phoneNumber: e.target.value,
                              })
                            }
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Customer Name"
                            value={filterParameter.customerName}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                customerName: e.target.value,
                              })
                            }
                          />
                        </li>
                        {/* <li>
                          <input
                            type="text"
                            placeholder="Destination"
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                destination: e.target.value,
                              })
                            }
                          />
                        </li> */}

                        <li className="fullWd appBtn">
                          <button
                            onClick={() => {
                              setApplyFilterBtn(!applyFilterBtn);
                              setShowPopup3(false);
                            }}>
                            Apply Filters
                          </button>
                        </li>
                        <li className="fullWd resetFilterBtn">
                          <a
                            href="javascript:void(0);"
                            onClick={() => {
                              handleReserFilter();
                            }}>
                            Reset Filters
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}

                  {selectedTab === "Single Booking" && (
                    <div>
                      <ul className="formList">
                        <li>
                          <input
                            type="text"
                            placeholder="Booking ID"
                            value={filterParameter.bookingId}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                bookingId: e.target.value,
                              })
                            }
                          />
                        </li>
                        <li>
                          <select
                            value={filterParameter.bookingStatus}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                bookingStatus: e.target.value,
                              })
                            }>
                            <option selected hidden>
                              Booking Status
                            </option>
                            {bookingStatusArray?.map((item, index) => (
                              <option key={index} value={item?.id}>
                                {item?.value}
                              </option>
                            ))}
                          </select>
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Phone Number"
                            value={filterParameter.phoneNumber}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                phoneNumber: e.target.value,
                              })
                            }
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Customer Name"
                            value={filterParameter.customerName}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                customerName: e.target.value,
                              })
                            }
                          />
                        </li>
                        {/* <li>
                          <input
                            type="text"
                            placeholder="Destination"
                            value={filterParameter.destination}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                destination: e.target.value,
                              })
                            }
                          />
                        </li> */}
                        {/* <li>
                          <input
                            type="text"
                            placeholder="Origin Destination"
                            value={filterParameter.originDestination}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                originDestination: e.target.value,
                              })
                            }
                          />
                        </li> */}

                        <li className="fullWd appBtn">
                          <button
                            onClick={() => {
                              setApplyFilterBtn(!applyFilterBtn);
                              setShowPopup3(false);
                            }}>
                            Apply Filters
                          </button>
                        </li>
                        <li className="fullWd resetFilterBtn">
                          <a
                            href="javascript:void(0);"
                            onClick={() => {
                              handleReserFilter();
                            }}>
                            Reset Filters
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <button className="closePopupBtn2" onClick={() => setShowPopup3(false)}>
                  <img src={crossIcon2} alt="" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgentBookingList;
