import React, { Component, useState, useRef, useEffect } from "react";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const transferIcon2 = require("./../assets/img/car-icon2.svg").default;
const mealIcon2 = require("./../assets/img/restaurant-icon2.svg").default;
const hotelIcon2 = require("./../assets/img/bed-icon2.svg").default;
const optionalIcon2 = require("./../assets/img/optional-icon2.svg").default;
const special1 = require("./../assets/img/special1.png");

const Quotation = () => {
  return (
    <div className="main">
      <Header />

      <div className="innerDiv">
        <div className="mainDiv">
          <div className="upcomingTourDiv">
            <div className="accountName">
              <h2>Subhendu Bhattacharjee</h2>
              <h3>7980267218</h3>
            </div>
            <div className="pointDiv">
              <h2>
                Wishmytour Points <span>100</span>
              </h2>
            </div>

            <div className="breadCmb">
              <p>
                <Link to="/">My Account</Link>Quotation
              </p>
            </div>

            <div className="upcomingInner">
              <div className="uptabContent">
                <div className="tourListing">
                  <div className="specialBox">
                    <div className="tourBoxImg">
                      <Link to="/">
                        <img
                          src={special1}
                          alt="Image 1"
                          className="specialSliderImg"
                        />
                      </Link>
                    </div>
                    <div className="bannerTxt">
                      <h2>
                        <Link to="/">Chadar And Lingshed Village Trek</Link>
                      </h2>
                      <h4>
                        {/* By: <span>Ayan Dutta Tour & Traveller</span> */}
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>16 Jan - 19 Jan</h5>
                    </div>
                  </div>

                  <div className="specialBox">
                    <div className="tourBoxImg">
                      <Link to="/">
                        <img
                          src={special1}
                          alt="Image 1"
                          className="specialSliderImg"
                        />
                      </Link>
                    </div>
                    <div className="bannerTxt">
                      <h2>
                        <Link to="/">Chadar And Lingshed Village Trek</Link>
                      </h2>
                      <h4>
                        {/* By: <span>Ayan Dutta Tour & Traveller</span> */}
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>16 Jan - 19 Jan</h5>
                    </div>
                  </div>

                  <div className="specialBox">
                    <div className="tourBoxImg">
                      <Link to="/">
                        <img
                          src={special1}
                          alt="Image 1"
                          className="specialSliderImg"
                        />
                      </Link>
                    </div>
                    <div className="bannerTxt">
                      <h2>
                        <Link to="/">Chadar And Lingshed Village Trek</Link>
                      </h2>
                      <h4>
                        {/* By: <span>Ayan Dutta Tour & Traveller</span> */}
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>16 Jan - 19 Jan</h5>
                    </div>
                  </div>

                  <div className="specialBox">
                    <div className="tourBoxImg">
                      <Link to="/">
                        <img
                          src={special1}
                          alt="Image 1"
                          className="specialSliderImg"
                        />
                      </Link>
                    </div>
                    <div className="bannerTxt">
                      <h2>
                        <Link to="/">Chadar And Lingshed Village Trek</Link>
                      </h2>
                      <h4>
                        {/* By: <span>Ayan Dutta Tour & Traveller</span> */}
                      </h4>
                      <h3>3 Nights & 4 Days</h3>
                      <ul>
                        <li>
                          <img src={transferIcon2} alt="Image 1" />
                          Transfer
                        </li>
                        <li>
                          <img src={mealIcon2} alt="Image 1" />
                          Meals
                        </li>
                        <li>
                          <img src={hotelIcon2} alt="Image 1" />
                          Hotel
                        </li>
                        <li>
                          <img src={optionalIcon2} alt="Image 1" />
                          Flight
                        </li>
                      </ul>

                      <h5>16 Jan - 19 Jan</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Quotation;
