import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useUserContext} from "../context/UserContext";
import Cookies from "js-cookie";
import {getCompanyDetailsApi} from "../services/ApiService";

const logo = require("./../assets/img/logo.svg").default;
const facebook = require("./../assets/img/facebook-icon.svg").default;
const linkedin = require("./../assets/img/linkedin-icon.svg").default;
const instagram = require("./../assets/img/instagram-icon.svg").default;

const Header = props => {
  const navigate = useNavigate();
  const {userData} = useUserContext();

  const [allDetails, setAllDetails] = useState();
  const [cookiesDivVisible, setCookiesDivVisible] = useState(() => {
    return !Cookies.get("WMTcookieAccepted");
  });

  const handleAcceptCookies = () => {
    Cookies.set("WMTcookieAccepted", "1", {expires: 30, path: "/"}); // 30 day expiration
    setCookiesDivVisible(false);
  };

  const handleDisableCookies = () => {
    Cookies.set("WMTcookieAccepted", "1", {expires: 30, path: "/"}); // 30 day expiration
    setCookiesDivVisible(false);
  };

  const getCompanyDetails = async () => {
    try {
      let response = await getCompanyDetailsApi();
      if (response) {
        setAllDetails(response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  return (
    <div className="footer" ref={props?.footerRef}>
      <div className="mainDiv">
        <div className="footTop">
          <div className="footLft">
            <img src={logo} alt="" />
          </div>
          <div className="footRgt">
            <img src={facebook} alt="" />
            <img src={linkedin} alt="" />
            <img src={instagram} alt="" />
          </div>
        </div>

        <div className="footMiddle">
          <div className="footNav">
            <h2>Packages By Theme</h2>
            <ul>
              <li>
                <Link to="/">Group Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Wildlife Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Camping Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Religious Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Beach Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Hiking Tour Packages</Link>
              </li>
            </ul>
          </div>
          <div className="footNav">
            <h2>Packages By Duration</h2>
            <ul>
              <li>
                <Link to="/">2 Days Tour Packages</Link>
              </li>
              <li>
                <Link to="/">3 Days Tour Packages</Link>
              </li>
              <li>
                <Link to="/">4 Days Tour Packages</Link>
              </li>
              <li>
                <Link to="/">5 Days Tour Packages</Link>
              </li>
              <li>
                <Link to="/">6 Days Tour Packages</Link>
              </li>
              <li>
                <Link to="/">7 Days Tour Packages</Link>
              </li>
            </ul>
          </div>
          <div className="footNav">
            <h2>Packages By Cities</h2>
            <ul>
              <li>
                <Link to="/">Andaman Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Himachal Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Kerala Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Rajastan Tour Packages</Link>
              </li>
              <li>
                <Link to="/">jammu And Kashmir Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Ladakh Tour Packages</Link>
              </li>
            </ul>
          </div>
          <div className="footNav">
            <h2>South Tourism</h2>
            <ul>
              <li>
                <Link to="/">Uttarakhand Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Sikkim Tour Packages</Link>
              </li>
              <li>
                <Link to="/">West Bengal Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Assam Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Gujarat Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Madhya Pradesh Tour Packages</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="footMiddle">
          <div className="footNav">
            <h2>South Tourism</h2>
            <ul>
              <li>
                <Link to="/">Karnataka Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Tamil Nadu Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Bhutan Tour Packages</Link>
              </li>
            </ul>
          </div>
          <div className="footNav">
            <ul className="trMrgn">
              <li>
                <Link to="/">Sri Lanka Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Nepal Tour Packages</Link>
              </li>
              <li>
                <Link to="/">Goa Tour Packages</Link>
              </li>
            </ul>
          </div>
          <div className="footNav footNav2">
            <h2>Top Cities</h2>
            <ul>
              <li>
                <Link to="/">Srinagar</Link>
              </li>
              <li>
                <Link to="/">Leh</Link>
              </li>
              <li>
                <Link to="/">Kargil</Link>
              </li>
              <li>
                <Link to="/">Dalhousie</Link>
              </li>
              <li>
                <Link to="/">Dharamsala</Link>
              </li>
              <li>
                <Link to="/">Kangra</Link>
              </li>
              <li>
                <Link to="/">Manali</Link>
              </li>
              <li>
                <Link to="/">Kullu</Link>
              </li>
            </ul>
            {!userData ? (
              <>
                <h2 className="partnerHeader">Partner's login or Sign up</h2>
                <ul>
                  <li>
                    {" "}
                    <Link to="/business/login/email">Tour operator</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/partners/login/email">Agent</Link>
                  </li>
                </ul>
              </>
            ) : null}
          </div>
        </div>
        <div className="CmpnyDtls">
          <h3>{allDetails ? allDetails[0]?.value : ""}</h3>
          <h4>{allDetails ? allDetails[1]?.value : ""}</h4>
        </div>
        <div className="footBtm">
          <div className="footBtmLft">
            <p>
              Copyright © {new Date().getFullYear()} <strong>WishMytour</strong>. All Rights Reserved
            </p>
          </div>
          <div className="footBtmRgt">
            <p>
              <Link to="/terms-of-use" target="_blank">
                Terms of Use
              </Link>
              <Link to="/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
              <Link to="/privacy-policy#cookie_policy" target="_blank">Cookie Policy</Link>
              <Link to="/terms-of-use#cancellation_policy" target="_blank">
                Cancellation Policy
              </Link>
              <Link to="/contact-us" target="_blank">
                Contact Us
              </Link>
            </p>
          </div>
        </div>
        {cookiesDivVisible && (
          <div className="cookiesDiv">
            <div className="mainDiv">
              <div className="cookieInner">
                <p>
                  We use cookies and similar technologies on this website to improve its performance and enhance your user experience. By clicking "Accept
                  Cookies" below, you consent to the use of these technologies and our{" "}
                  <Link to={"/privacy-policy#cookie_policy"} target="_blank">
                    Cookie Policy
                  </Link>
                  .
                </p>
                <div className="cookieBtns">
                  <button className="disableBtn" onClick={handleDisableCookies}>
                    Reject Cookies
                  </button>
                  <button className="accepteBtn" onClick={handleAcceptCookies}>
                    Accept Cookies
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
