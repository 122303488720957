import React, {Component, useState, useRef, useEffect} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import RangeSliderComponent from "../component/RangeSliderComponent";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {agentVerifyOtpApi, loginApi, vendorVerifyOtpApi, verifyOtpApi} from "../services/ApiService";
import {useUserContext} from "../context/UserContext";

import google from "../assets/img/google-icon.svg";
import facebook from "../assets/img/facebook-icon2.svg";
import VendorRegistrationLogo from "../assets/img/vendor-registration-logo.svg";

const AgentPhoneOtpVerification = () => {
  const navigate = useNavigate();
  const {setUserData} = useUserContext();
  const {loginValue} = useLocation().state ?? {};
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(60);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [validationError, setValidationError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [recountTimer, setRecountTimer] = useState(false);
  const [otpResponseValue, setOtpResponseValue] = useState(useLocation().state?.responseValue ?? null);

  const handleInputChange = (index, e) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    // Check if all OTP digits are entered
    if (index === inputRefs.length - 1 && newOtp.every(digit => digit !== "")) {
      setValidationError("");
      handleSubmit(newOtp);
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);

      // Set focus on the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 4);

    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      for (let i = 0; i < 4; i++) {
        newOtp[i] = pastedData[i] || "";
      }
      return newOtp;
    });

    if (pastedData?.length < 4) {
      inputRefs[pastedData?.length].current.focus();
    } else {
      inputRefs[3].current.focus();
    }

    // Check if all four digits are present in the pasted data
    if (pastedData.length === 4 && pastedData.match(/^\d{4}$/)) {
      let newValue = pastedData.split("");
      handleSubmit(newValue);
    }
  };

  const handleResendOTP = async () => {
    if (loginValue) {
      setRecountTimer(!recountTimer);
      setValidationError("");
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("login", loginValue);
        // Api call
        let response = await loginApi(formData);
        if (response) {
          if (response.data.res === true) {
            setTimer(60);
            setSuccessMsg(response.data.msg);
            setOtpResponseValue(response.data.data);
            setTimeout(() => {
              setSuccessMsg("");
            }, 3000);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      setValidationError("Please enter a valid email or mobile number.");
    }
  };

  const handleSubmit = async otp => {
    if (otp.every(digit => digit === "")) {
      setValidationError("Please enter Your Otp.");
    } else {
      let newVal = otp.join("");
      setValidationError("");
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("agent", otpResponseValue?.agent);
        formData.append("action", otpResponseValue?.action);
        formData.append("otp", newVal);

        let response = await agentVerifyOtpApi(formData);
        if (response) {
          if (response.data.res === true) {
            setValidationError("");
            setSuccessMsg(response.data.msg);
            if (response.data.data.agentDetails === "exist") {
              if (otpResponseValue?.action === "register") {
                navigate("/partners/registration", {
                  state: {responseValue: otpResponseValue, verifyOtpResponse: response.data.data, responseDataForLocal: response.data},
                });
              } else {
                localStorage.setItem("WMTUserData", JSON.stringify(response.data));
                localStorage.setItem("WMTLastActivityTimestamp", Date.now());
                setUserData(response.data);
                if (parseInt(response.data.data.bankAccNoExists) === 1) {
                  navigate("/partners/profile");
                } else {
                  navigate("/partners/profile");
                }
              }
            } else {
              navigate("/partners/registration", {
                state: {responseValue: otpResponseValue, verifyOtpResponse: response.data.data, responseDataForLocal: response.data},
              });
            }
          } else {
            setValidationError(response.data.msg);
            setSuccessMsg("");
          }
        }
      } catch (error) {}
    }
  };

  // Resend Counter useEffect
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [recountTimer]);

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  return (
    <div className="main">
      <div className="innerDiv RegistrationInnerDiv">
        <div className="mainDiv">
          <div className="VendorRegistrationLogoSec">
            <Link to={"/"}>
              <img src={VendorRegistrationLogo} alt="" />
            </Link>
            <div className="loginDiv">
              <h3>Agent Registration</h3>
              <h2>OTP Verification</h2>
              <h4>Check text messages for your OTP</h4>
              {/* <h5>Your Otp is - {otpResponseValue?.otp}</h5> */}
              <ul className="otpList">
                <li>
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      value={value}
                      onChange={e => handleInputChange(index, e)}
                      onKeyUp={e => handleBackspace(index, e)}
                      onPaste={handlePaste}
                      ref={inputRefs[index]}
                    />
                  ))}
                </li>
                <li>00:{parseInt(timer) < 10 ? "0" + timer : timer}</li>
                <li>
                  <h5>
                    Not Received OTP?{" "}
                    {parseInt(timer) === 0 ? (
                      <Link to="javascript:void(0);" onClick={() => handleResendOTP()}>
                        Resend Now
                      </Link>
                    ) : (
                      "Resend Now"
                    )}
                  </h5>
                  {validationError && <p className="validationErrorMsg">{validationError}</p>}
                  {successMsg && <p className="successMsg">{successMsg}</p>}
                </li>
                <li>
                  <button onClick={() => handleSubmit(otp)}>Submit</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentPhoneOtpVerification;
