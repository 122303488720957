import React, {Component, useState, useRef, useEffect} from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import RangeSliderComponent from "./../component/RangeSliderComponent";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {addWishlistApi, getPackageDetailsApi, getRelatedPackagesApi} from "../services/ApiService";
import {IMAGE_URL} from "../utils/commonUrls";
import StarRatings from "react-star-ratings";
import {Helmet} from "react-helmet";
import {useUserContext} from "../context/UserContext";
import {NotificationManager} from "react-notifications";
import VenderSidebar from "./../component/Vender-Sidebar";
import VenderHeader from "./../component/Vender-Header";
import {Button} from "react-bootstrap";
import LoadingPage from "../component/loadingPage";
import {TimeConverter} from "../utils/commonFunctions";

const arrow = require("./../assets/img/left-arrow.svg").default;
const share = require("./../assets/img/share-icon.svg").default;
const transferIcon2 = require("./../assets/img/car-icon2.svg").default;
const mealIcon2 = require("./../assets/img/restaurant-icon2.svg").default;
const hotelIcon2 = require("./../assets/img/bed-icon2.svg").default;
const optionalIcon2 = require("./../assets/img/optional-icon2.svg").default;

const hillIcon = require("./../assets/img/hill-icon.svg").default;
const beachIcon = require("./../assets/img/beach-icon.svg").default;
const jungleIcon = require("./../assets/img/forest-icon.svg").default;
const natureIcon = require("./../assets/img/nature-icon.svg").default;

const flightIcon = require("./../assets/img/flight-icon.svg").default;
const flightIcon2 = require("./../assets/img/plane-icon.svg").default;
const localIcon = require("./../assets/img/train-icon.svg").default;
const hotelIcon = require("./../assets/img/hotel-icon.svg").default;
const mealIcon = require("./../assets/img/meal-icon.svg").default;
const sightIcon = require("./../assets/img/sight-icon.svg").default;
const trainIcon = require("./../assets/img/train-icon2.svg").default;
const trainIcon2 = require("./../assets/img/train-icon.svg").default;
const hotelImg = require("./../assets/img/hotel-img.png");
const ratingImg = require("./../assets/img/star-icon.svg").default;
const carImg = require("./../assets/img/local-icon.svg").default;
const crossIcon = require("./../assets/img/cross-icon.svg").default;
const socialIcons = require("./../assets/img/social-icons.svg").default;
const crossIcon2 = require("./../assets/img/cross.svg").default;
const carIcon = require("./../assets/img/car-icon3.svg").default;

const tour1 = require("./../assets/img/tour-details1.png");
const tour2 = require("./../assets/img/tour-details1.png");
const tour3 = require("./../assets/img/tour-details1.png");
const tour4 = require("./../assets/img/tour-details1.png");
const tour5 = require("./../assets/img/tour-details1.png");
const tour6 = require("./../assets/img/tour-details1.png");
const tour7 = require("./../assets/img/tour-details1.png");
const tour8 = require("./../assets/img/tour-details1.png");
const special1 = require("./../assets/img/special1.png");
const trainIcon3 = require("./../assets/img/train.svg").default;
const binocularIcon = require("./../assets/img/binoculars.svg").default;
const facebookIcon = require("./../assets/img/facebook-link-icon.svg").default;
const googleIcon = require("./../assets/img/google-link-icon.svg").default;
const whatsappIcon = require("./../assets/img/whatsapp-link-icon.svg").default;

const TourPreviewByVendor = ({packageId}) => {
  //   const {packageId} = useParams();
  const {userData, wishListCount, setWishListCount} = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [packageDetails, setPackageDetails] = useState();
  const [dateSelectedByClick, setdateSelectedByClick] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [wishBtn, setWishBtn] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);

  //-------------------------Package Itinerary starts-------------------------
  const itinerary = [
    {
      day: "Day-1",
      title: "Leh From: Day-1",
      subTitle: "Day 1 - Arrive Leh : 3505 meters / 11567 feet",
      content:
        "Arrive Kushok Bakula airport Leh - 3500m above sea level. Transfer to hotel. Breakfast at the hotel before 09.30 am. Half Day at rest for acclimatization. Lunch at the hotel. After Lunch drive to Visit Shanti Stupa & Leh Palace, Later in the evening walk around local market. Overnight stay at the Hotel.",
      details: [
        {
          type: "Flight",
          name: "Air India",
          code: "AI 445",
          station: "New Delhi",
          classCategory: "Economy",
          destination: "Leh",
        },
        {
          type: "Train",
          name: "Rajdhani Express",
          code: "12301",
          station: "Howrah",
          classCategory: "3 tier AC",
          destination: "NDLS",
        },
        {
          type: "Sightseeing",
          morning: "Morning: Visit Shanti Stupa & Leh Palace",
          afternoon: "Afternoon: Visit Shanti..",
        },
        {
          type: "Hotel",
          desc: "Check In For 1 Nights",
          hotelName: "Tiaraa Hotels and Resorts Manali",
          afternoon: "Afternoon: Visit Shanti..",
          //images: ["url_of_image_1.jpg", "url_of_image_2.jpg"],
        },
        {
          type: "Meal",
          breakfast: "Breakfast",
          lunch: "Lunch",
          dinner: "Dinner",
        },
        {
          type: "Local Transport",
          seat: "4 Seater",
          carName: "Swift Dezire",
        },
      ],
    },
    {
      day: "Day-2",
      title: "Leh From: Day-2",
      subTitle: "Day 2 - Arrive Leh : 3505 meters / 11567 feet",
      content:
        "Arrive Kushok Bakula airport Leh - 3500m above sea level. Transfer to hotel. Breakfast at the hotel before 09.30 am. Half Day at rest for acclimatization. Lunch at the hotel. After Lunch drive to Visit Shanti Stupa & Leh Palace, Later in the evening walk around local market. Overnight stay at the Hotel.",
      details: [
        {
          type: "Flight",
          name: "Air India",
          code: "AI 445",
          station: "New Delhi",
          classCategory: "Economy",
          destination: "Leh",
        },
        {
          type: "Train",
          name: "Rajdhani Express",
          code: "12301",
          station: "Howrah",
          classCategory: "3 tier AC",
          destination: "NDLS",
        },
        {
          type: "Sightseeing",
          morning: "Morning: Visit Shanti Stupa & Leh Palace",
          afternoon: "Afternoon: Visit Shanti..",
        },
        {
          type: "Hotel",
          desc: "Check In For 1 Nights",
          hotelName: "Tiaraa Hotels and Resorts Manali",
          afternoon: "Afternoon: Visit Shanti..",
          //images: ["url_of_image_1.jpg", "url_of_image_2.jpg"],
        },
        {
          type: "Meal",
          breakfast: "Breakfast",
          lunch: "Lunch",
          dinner: "Dinner",
        },
        {
          type: "Local Transport",
          seat: "4 Seater",
          carName: "Swift Dezire",
        },
      ],
    },
    {
      day: "Day-3",
      title: "Leh From: Day-3",
      subTitle: "Day 3 - Arrive Leh : 3505 meters / 11567 feet",
      content:
        "Arrive Kushok Bakula airport Leh - 3500m above sea level. Transfer to hotel. Breakfast at the hotel before 09.30 am. Half Day at rest for acclimatization. Lunch at the hotel. After Lunch drive to Visit Shanti Stupa & Leh Palace, Later in the evening walk around local market. Overnight stay at the Hotel.",
      details: [
        {
          type: "Flight",
          name: "Air India",
          code: "AI 445",
          station: "New Delhi",
          classCategory: "Economy",
          destination: "Leh",
        },
        {
          type: "Train",
          name: "Rajdhani Express",
          code: "12301",
          station: "Howrah",
          classCategory: "3 tier AC",
          destination: "NDLS",
        },
        {
          type: "Sightseeing",
          morning: "Morning: Visit Shanti Stupa & Leh Palace",
          afternoon: "Afternoon: Visit Shanti..",
        },
        {
          type: "Hotel",
          desc: "Check In For 1 Nights",
          hotelName: "Tiaraa Hotels and Resorts Manali",
          afternoon: "Afternoon: Visit Shanti..",
          //images: ["url_of_image_1.jpg", "url_of_image_2.jpg"],
        },
        {
          type: "Meal",
          breakfast: "Breakfast",
          lunch: "Lunch",
          dinner: "Dinner",
        },
        {
          type: "Local Transport",
          seat: "4 Seater",
          carName: "Swift Dezire",
        },
      ],
    },
  ];

  const initialAccordionState = Array.from({length: itinerary.length}, (_, index) => index === 0);
  const [accordionOpen, setAccordionOpen] = useState(initialAccordionState);

  const toggleAccordion = index => {
    setAccordionOpen(prev => prev.map((isOpen, i) => (i === index ? !isOpen : false)));
  };

  const hotelSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
  };

  //-------------------------Package Itinerary Ends-------------------------

  //-------------------------Hotel Slider Popup Ends-------------------------
  const [selectedHotelIndex, setSelectedHotelIndex] = useState(0);
  const [showHotelPopup, setShowHotelPopup] = useState(false);
  const [showHotelPopupItem, setShowHotelPopupItem] = useState();
  const [hotelGalleryForPopup, setHotelGalleryForPopup] = useState([]);

  const openHotelPopup = (item, index) => {
    setSelectedHotelIndex(index);
    setShowHotelPopup(true);
    const newGallery = [...item]; // Copy the item array
    const selectedImage = newGallery.splice(index, 1)[0]; // Remove the selected image
    newGallery.unshift(selectedImage); // Insert the selected image at the beginning
    setHotelGalleryForPopup(newGallery); // Update the state with the new image order
  };

  const closeHotelPopup = () => {
    setShowHotelPopup(false);
  };
  //-------------------------Hotel Slider Popup Ends-------------------------

  //-------------------------Main Slider Starts-------------------------
  const mainSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
  };

  const thumbnailSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const handleThumbnailClick = index => {
    setSelectedImageIndex(index);
    mainSlider.slickGoTo(index);
  };

  let mainSlider;
  //-------------------------Main Slider Ends-------------------------

  //-------------------------Related Slider Starts-------------------------
  const slider1Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  //-------------------------Related Slider Starts-------------------------

  //-------------------------Calendar Starts-------------------------
  const [selectedDate, setSelectedDate] = useState(new Date());
  const today = new Date(); // Today's date

  // Sample calendar data, replace it with your actual data
  const calendarData1 = [
    {date: new Date(2024, 1, 3), status: "Lowest Price", price: "₹21,000"},
    {date: new Date(2024, 1, 4), status: "Lowest Price", price: "₹11,999"},
    {date: new Date(2024, 1, 5), status: "Lowest Price", price: "₹25,000"},
    {date: new Date(2024, 1, 6), status: "Lowest Price", price: "₹12,999"},
    {date: new Date(2024, 1, 10), status: "Regular Price", price: "₹18,999"},
    {date: new Date(2024, 1, 16), status: "Sold Out", price: "₹16,999"},
    {date: new Date(2024, 1, 22), status: "On Request", price: "₹17,250"},
    {date: new Date(2024, 2, 12), status: "Selling Fast", price: "₹32,999"},
    // Add more dates with different statuses
  ];
  const [calendarData, setCalendarData] = useState([]);
  const [selectedTourStartDateObj, setSelectedTourStartDateObj] = useState();

  const getDayStatus = date => {
    const day = calendarData.find(entry => isSameDay(entry.date, date));
    return day ? day.status : null;
  };

  const getDayPrice = date => {
    const day = calendarData.find(entry => isSameDay(entry.date, date));
    return day ? (day.price ? "₹" + day.price : "") : null;
  };

  const handleDateClick = date => {
    setdateSelectedByClick(true);

    // Check if the selected date has the status "Lowest Price"
    const selectedDay = calendarData.find(entry => isSameDay(entry.date, date));
    setSelectedDate(date);
    if (selectedDate) {
      const date11 = new Date(date);
      // Convert Date object to ISO string
      const isoString = date11.toISOString();
      let tempSessionObj = {};
      tempSessionObj.selectedTourStartDate = isoString;
      tempSessionObj.packageId = packageId;
      tempSessionObj.stay_plan = packageDetails?.stay_plan;
      tempSessionObj.vendor_name = packageDetails?.vendor_name;
      let tempFlightArrayList = [];
      if (packageDetails?.itinerary?.length > 0) {
        packageDetails?.itinerary?.map(item => {
          if (item?.flights?.length > 0) {
            tempFlightArrayList.push(item?.flights[0]);
          }
        });
      }
      tempSessionObj.flightList = tempFlightArrayList;
      sessionStorage.setItem("WMTBookingObj", JSON.stringify(tempSessionObj));
      // navigate(`/package/${packageId}/rooms-selection`);
    }
    setSelectedTourStartDateObj(selectedDay);
    // if (selectedDay && selectedDay.status !== "Lowest Price") {
    //   // If the status is not "Lowest Price", show an alert
    //   alert("This date does not have the lowest price.");
    // } else {
    //   // If the status is "Lowest Price" or the date is not available, proceed to the next step
    //   setSelectedDate(date);
    //   // Add your code to proceed to the next step here
    // }
  };

  const tileContent = ({date}) => {
    const status = getDayStatus(date);
    const price = getDayPrice(date);
    return status === "Lowest Price" || status === "Regular Price" ? (
      <div className="eachDtls">
        <p className="prc">{price}</p>
        <p className="sts">{status}</p>
      </div>
    ) : status === "On Request" ? (
      <div className="eachDtls">
        <p className="prc">On Request</p>
        <p className="sts">Contact Seller</p>
      </div>
    ) : (
      <div className="eachDtls">
        {/* <p className="prc">On Request</p> */}
        {/* <p className="sts">Contact Seller</p> */}
      </div>
    );
  };

  const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  };

  const getStatusForDate = date => {
    const dataForDate = calendarData.find(entry => isSameDay(entry.date, date));

    if (dataForDate) {
      return dataForDate.status.toLowerCase().replace(" ", "-");
    }

    return "";
  };

  const tileClassName = ({date}) => {
    const status = getStatusForDate(date);
    const isCurrentDate =
      date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear();

    // Check if the date is before the present day
    const isPreviousDate = date < new Date();

    // Return a string of classes separated by a space
    return `${status} ${isCurrentDate ? "current-date" : ""} ${isPreviousDate ? "previous-date" : ""} ${
      dateSelectedByClick && selectedDate && date.getDate() === selectedDate.getDate() ? "selected-date" : ""
    }`.trim();
  };
  //-------------------------Calendar Ends-------------------------

  //-------------------------Smooth Scroll Starts-------------------------
  const [activeLink, setActiveLink] = useState(null);
  const photosRef = useRef(null);
  const packageRef = useRef(null);
  const departureRef = useRef(null);
  const inclusionsRef = useRef(null);
  const exclusionsRef = useRef(null);
  const termsRef = useRef(null);

  const scrollToSection = (ref, link) => {
    ref.current.scrollIntoView({behavior: "smooth"});
    setActiveLink(link);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust as needed
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setActiveLink("photos");
      }
    }, options);

    if (photosRef.current) {
      observer.observe(photosRef.current);
    }

    // Clean up the observer
    return () => {
      if (photosRef.current) {
        observer.unobserve(photosRef.current);
      }
    };
  }, [photosRef]);
  //-------------------------Smooth Scroll Ends-------------------------

  //-------------------------Share Popup Starts-------------------------
  const [selectedShareIndex, setSelectedShareIndex] = useState(0);
  const [showSharePopup, setShowSharePopup] = useState(false);

  const openSharePopup = index => {
    setSelectedShareIndex(index);
    setShowSharePopup(true);

    // Copy the current URL to the clipboard
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(
      () => {
        // console.log("URL successfully copied to clipboard:", currentUrl);
      },
      error => {
        console.error("Unable to copy URL to clipboard", error);
      }
    );
  };

  const closeSharePopup = () => {
    setShowSharePopup(false);
  };
  //-------------------------Share Popup Ends-------------------------

  //-------------------------Query Popup Starts-------------------------
  const [selectedQueryIndex, setSelectedQueryIndex] = useState(0);
  const [showQueryPopup, setShowQueryPopup] = useState(false);
  const openQueryPopup = index => {
    setSelectedQueryIndex(index);
    setShowQueryPopup(true);
  };

  const [allSliderDetails, setAllSliderDetails] = useState([]);

  const [relatedPackagesList, setRelatedPackagesList] = useState([]);

  const closeQueryPopup = () => {
    setShowQueryPopup(false);
  };
  //-------------------------Query Popup Ends-------------------------

  //-------------------------Developement code Starts-------------------------

  // Calculate minDate as the current date
  const minDate = new Date();

  // Calculate maxDate as the same month next year
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);

  const getPackageDetails = async () => {
    setScreenLoading(true);
    try {
      let response = await getPackageDetailsApi(packageId);
      if (response && response.data.res === true) {
        setPackageDetails(response.data.data);

        let tempArray = [];

        tempArray = response.data.data?.gallery_images
          .map((image, index) => ({
            ...image,
            type: "image",
          }))
          .concat(
            response.data.data?.media_link.map((video, index) => ({
              src: `https://img.youtube.com/vi/${getYouTubeVideoId(video.media_link)}/0.jpg`,
              link: video.media_link,
              alt: `Thumbnail ${index + 1}`,
              type: "video",
            }))
          );

        setAllSliderDetails(tempArray);

        setAccordionOpen(Array.from({length: response.data.data.itinerary.length}, (_, index) => index === 0));

        const getAllDatesInRange = (startDate, endDate) => {
          const dates = [];
          let currentDate = new Date(startDate);

          while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
          }

          return dates;
        };

        const seatAvailability = response.data.data.seat_availability;
        const seatNotAvailable = response.data.data.seat_unavailable;
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        // const calendarRange = getAllDatesInRange(new Date(currentYear, 0, 1), new Date(currentYear, 11, 31));
        const endDate = new Date(currentYear + 1, currentMonth, new Date(currentYear, currentMonth + 1, 0).getDate());

        // Use the adjusted start and end dates in getAllDatesInRange
        const calendarRange = getAllDatesInRange(new Date(currentYear, currentMonth, 1), endDate);

        const onSeasoncalendarRange = getAllDatesInRange(new Date(response.data.data.onseason_from_date), new Date(response.data.data.onseason_to_date));

        const offSeasoncalendarRange = getAllDatesInRange(new Date(response.data.data.offseason_from_date), new Date(response.data.data.offseason_to_date));

        const onSeasoncalendarRangeFormat = onSeasoncalendarRange.map(date => {
          const dateString = date.toISOString();
          return dateString;
        });

        const offSeasoncalendarRangeFormat = offSeasoncalendarRange.map(date => {
          const dateString = date.toISOString();
          return dateString;
        });

        const packageRatesDates = response.data.data.packageRates.flatMap(rate => {
          const rateDates = getAllDatesInRange(new Date(rate.start_date), new Date(rate.end_date));
          // console.log(rateDates);
          return rateDates.map(date => ({
            date: date.toISOString(),
            price: parseInt(rate.price),
            starting_price: parseInt(rate.starting_price),
            single_occupancy_price: parseInt(rate.single_occupancy_price),
            triple_occupancy_price: parseInt(rate.triple_occupancy_price),
          }));
        });

        // const unavailableDatesData = calendarRange
        //   .filter(date => !seatAvailability.some(availableDate => isSameDay(date, availableDate)))
        //   .map(date => {
        //     const dateString = date.toISOString();
        //     return {
        //       date: date,
        //       status: onSeasoncalendarRangeFormat.includes(dateString) ? "Regular Price" : "On Request",
        //     };
        //   });

        // const tempCalArray = response.data.data.seat_availability.map(item => ({
        //   id: item.id,
        //   date: new Date(item.date),
        //   status: "Lowest Price",
        //   price: parseFloat(item.cost),
        //   seat: parseInt(item.seat),
        // }));

        // const finalCalArray = calendarRange.map(date => {
        //   const dateString = date.toISOString();
        //   return {
        //     date: date,
        //     status: onSeasoncalendarRangeFormat.includes(dateString)
        //       ? "Regular Price"
        //       : offSeasoncalendarRangeFormat.includes(dateString)
        //       ? "Lowest Price"
        //       : "On Request",
        //     price: onSeasoncalendarRangeFormat.includes(dateString)
        //       ? Math.floor(parseInt(response.data.data.onseason_price))
        //       : offSeasoncalendarRangeFormat.includes(dateString)
        //       ? Math.floor(parseInt(response.data.data.offseason_price))
        //       : null,
        //     seat: response.data.data.total_seat,
        //   };
        // });
        // 2024-04-15T18:30:00.000Z
        // console.log(seatAvailability);
        // console.log(new Date("2024-04-24").toISOString());

        const finalCalArray = calendarRange.map(date => {
          const dateString = date.toISOString();
          const dateString1 = date.toISOString().split("T")[0];

          const seatAvailabilityItem = seatAvailability.find(item => {
            const seatDate = new Date(item.date);
            return seatDate.toDateString() === date.toDateString();
          });

          const matchedRate = packageRatesDates.find(rateDate => {
            const seatDate = new Date(rateDate.date);
            return seatDate.toDateString() === date.toDateString();
          });

          // if (seatAvailabilityItem) {
          //   return {
          //     date: date,
          //     status: "Regular Price",
          //     price: parseFloat(seatAvailabilityItem.cost),
          //     seat: parseInt(seatAvailabilityItem.seat),
          //   };
          // } else {
          //   return {
          //     date: date,
          //     status: onSeasoncalendarRangeFormat.includes(dateString)
          //       ? "Regular Price"
          //       : offSeasoncalendarRangeFormat.includes(dateString)
          //       ? "Lowest Price"
          //       : "",
          //     // : "On Request",
          //     price: onSeasoncalendarRangeFormat.includes(dateString)
          //       ? Math.floor(parseInt(response.data.data.starting_price) + parseInt(response.data.data.onseason_price))
          //       : offSeasoncalendarRangeFormat.includes(dateString)
          //       ? Math.floor(parseInt(response.data.data.starting_price) - response.data.data.offseason_price)
          //       : null,
          //     seat: response.data.data.total_seat,
          //   };
          // }

          const seatNotAvailabilityItem = seatNotAvailable.find(item => {
            const seatDate = new Date(item.date);
            return seatDate.toDateString() === date.toDateString();
          });

          if (seatNotAvailabilityItem) {
            return {
              date: date,
              status: "", // No status is set when seatNotAvailabilityItem exists
            };
          } else if (seatAvailabilityItem) {
            if (matchedRate) {
              const startingPrice = parseInt(response.data.data.starting_price);
              const matchedRatePrice = parseInt(matchedRate.price);
              let finalPrice;
              let status;

              if (matchedRatePrice > 0) {
                finalPrice = startingPrice + matchedRatePrice;
                status = "Regular Price";
              } else {
                finalPrice = startingPrice + matchedRatePrice; // since matchedRatePrice is negative, it will effectively be a subtraction
                status = "Lowest Price";
              }
              return {
                date: date,
                status: status,
                price: parseInt(matchedRate?.starting_price),
                seat: parseInt(seatAvailabilityItem.seat),
                matchedRatePrice: parseInt(matchedRate.price),
                starting_price: parseInt(matchedRate.starting_price),
                single_occupancy_price: parseInt(matchedRate.single_occupancy_price),
                triple_occupancy_price: parseInt(matchedRate.triple_occupancy_price),
              };
              // return {
              //   date: date,
              //   status: parseInt(matchedRate.price) < parseInt(response.data.data.starting_price) ? "Lowest Price" : "Regular Price",
              //   price: parseFloat(matchedRate.price),
              //   seat: parseInt(seatAvailabilityItem.seat), // Update the seat count from seatAvailabilityItem
              // };
            } else {
              return {
                date: date,
                status: "On Request", // Default to empty status when matchedRate does not exist
                price: parseInt(response.data.data.starting_price),
                seat: parseInt(seatAvailabilityItem.seat),
              };
            }
          } else {
            if (matchedRate) {
              const startingPrice = parseInt(response.data.data.starting_price);
              const matchedRatePrice = parseInt(matchedRate.price);
              let finalPrice;
              let status;

              if (matchedRatePrice > 0) {
                finalPrice = startingPrice + matchedRatePrice;
                status = "Regular Price";
              } else {
                finalPrice = startingPrice + matchedRatePrice; // since matchedRatePrice is negative, it will effectively be a subtraction
                status = "Lowest Price";
              }
              return {
                date: date,
                status: status,
                price: parseInt(matchedRate?.starting_price),
                seat: response.data.data.total_seat,
                matchedRatePrice: parseInt(matchedRate.price),
                starting_price: parseInt(matchedRate.starting_price),
                single_occupancy_price: parseInt(matchedRate.single_occupancy_price),
                triple_occupancy_price: parseInt(matchedRate.triple_occupancy_price),
              };
            } else {
              return {
                date: date,
                status: "On Request",
                price: parseInt(response.data.data.starting_price),
                seat: response.data.data.total_seat,
              };
            }
            // const isOnSeason = onSeasoncalendarRangeFormat.includes(dateString);
            // const isOffSeason = offSeasoncalendarRangeFormat.includes(dateString);

            // return {
            //   date: date,
            //   status: isOnSeason ? "Regular Price" : isOffSeason ? "Lowest Price" : "On Request",
            //   price: isOnSeason
            //     ? Math.floor(parseInt(response.data.data.website_price) + parseInt(response.data.data.onseason_price))
            //     : isOffSeason
            //     ? Math.floor(parseInt(response.data.data.website_price) - parseInt(response.data.data.offseason_price))
            //     : null,
            //   seat: response.data.data.total_seat,
            // };
          }
        });

        // const finalCalArray = [...tempCalArray, ...unavailableDatesData];
        setCalendarData(finalCalArray);
        setScreenLoading(false);
      }
    } catch (error) {
      console.error("Error fetching package details:", error);
      setScreenLoading(false);
    }
  };

  const getRelatedPackages = async () => {
    try {
      let formData = new FormData();
      formData.append("package_id", packageId);
      // Api call
      let response = await getRelatedPackagesApi(packageId);
      if (response) {
        if (response.data.res === true) {
          setRelatedPackagesList(response.data.data);
        } else {
        }
      }
    } catch (error) {}
  };

  const formattedStayPlan = (stayPlan, trainNightCount) => (
    <>
      {trainNightCount ? "Train (1N) → " : null}
      {stayPlan?.map((stayItem, stayIndex) => (
        <>
          {stayItem.city_name} ({stayItem.total_nights}N){stayIndex < stayPlan.length - 1 && " → "}
        </>
      ))}
    </>
  );

  const formattedStayPlanRelated = stayPlan => (
    <p>
      {stayPlan.map((stayItem, stayIndex) => (
        <span key={stayIndex}>
          {stayItem.city_name} ({stayItem.total_nights}N){stayIndex < stayPlan.length - 1 && " → "}
        </span>
      ))}
    </p>
  );

  const formattedStayPlan2 = stayPlan => (
    <>
      {stayPlan?.map((stayItem, stayIndex) => (
        <li key={stayIndex}>
          {stayItem.city_name} - {stayItem.total_nights}
          {parseInt(stayItem.total_nights) > 1 ? "Nights" : "Night"}
        </li>
      ))}
    </>
  );

  const handleBookNow = async () => {
    // if (!selectedTourStartDateObj) {
    //   NotificationManager.warning("PLease select a tour start date to continue.");
    // } else {
    let tempSessionObj = {};

    if (selectedTourStartDateObj && selectedTourStartDateObj?.status) {
      tempSessionObj.selectedTourStartDate = selectedTourStartDateObj.date;
      if (selectedTourStartDateObj?.status !== "On Request") {
        tempSessionObj.tourPrice = selectedTourStartDateObj.price;
      }
    }

    tempSessionObj.packageId = packageId;
    tempSessionObj.stay_plan = packageDetails?.stay_plan;
    tempSessionObj.vendor_name = packageDetails?.vendor_name;
    tempSessionObj.package_name = packageDetails?.package_name;
    tempSessionObj.single_occupancy_cost = packageDetails?.single_occupancy_cost;
    tempSessionObj.child_discount = packageDetails?.child_discount;
    tempSessionObj.bulk_no_of_pax = packageDetails?.bulk_no_of_pax;
    tempSessionObj.pax_discount_percent = packageDetails?.pax_discount_percent;
    tempSessionObj.status = selectedTourStartDateObj?.status;
    tempSessionObj.total_days_count = packageDetails?.total_days_count;
    tempSessionObj.triple_sharing_discount = packageDetails?.triple_sharing_discount;
    tempSessionObj.bulk_discounts = packageDetails?.bulk_discounts;
    let tempFlightArrayList = [];
    if (packageDetails?.itinerary?.length > 0) {
      packageDetails?.itinerary?.map(item => {
        if (item?.flights?.length > 0) {
          tempFlightArrayList.push(item?.flights[0]);
        }
      });
    }
    tempSessionObj.flightList = tempFlightArrayList;
    sessionStorage.setItem("WMTBookingObj", JSON.stringify(tempSessionObj));
    navigate(`/package/${packageId}/rooms-selection`);
    // }

    // if (!userData) {
    //   NotificationManager.warning("Please login to continue.");
    //   navigate("/login", {state: {from: location.pathname}});
    // } else if (!selectedTourStartDateObj) {
    //   NotificationManager.warning("PLease select a tour start date to continue.");
    // } else {
    //   let tempSessionObj = {};
    //   tempSessionObj.selectedTourStartDate = selectedTourStartDateObj.date;
    //   tempSessionObj.packageId = packageId;
    //   tempSessionObj.stay_plan = packageDetails?.stay_plan;
    //   tempSessionObj.vendor_name = packageDetails?.vendor_name;
    //   let tempFlightArrayList = [];
    //   if (packageDetails?.itinerary?.length > 0) {
    //     packageDetails?.itinerary?.map(item => {
    //       if (item?.flights?.length > 0) {
    //         tempFlightArrayList.push(item?.flights[0]);
    //       }
    //     });
    //   }
    //   tempSessionObj.flightList = tempFlightArrayList;
    //   sessionStorage.setItem("WMTBookingObj", JSON.stringify(tempSessionObj));
    //   console.log(tempSessionObj);
    //   navigate(`/package/${packageId}/rooms-selection`);
    // }
  };

  const getYouTubeVideoId = url => {
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return "invalid";
    }
  };

  const shareViaWhatsApp = () => {
    const url = encodeURIComponent(window.location.href);
    window.open(`https://wa.me/?text=${url}`, "_blank");
  };

  const shareViaFacebook = () => {
    const url = encodeURIComponent(window.location.href);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, "_blank");
  };

  const shareViaGmail = () => {
    const subject = encodeURIComponent("Check out this link");
    const body = encodeURIComponent(window.location.href);
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  const handleAddWishlist = async () => {
    setWishBtn(true);
    try {
      let formData = new FormData();
      formData.append("package_ids", packageId);
      // Api call
      let response = await addWishlistApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          setWishListCount(prev => prev + 1);
        } else {
          NotificationManager.warning(response.data.msg);
        }
        setWishBtn(false);
      }
    } catch (error) {
      setWishBtn(false);
    }
  };

  useEffect(() => {
    getPackageDetails();
    getRelatedPackages();
    sessionStorage.removeItem("WMTBookingObj");

    let serachValues = JSON.parse(sessionStorage.getItem("wishmytourSearchValues"));
    if (serachValues?.month) {
      const [month, year] = serachValues.month.split(" ");
      // Create a Date object for June 1st, 2024
      const selectedDate = new Date(year, monthIndex(month), 1);

      // Function to convert month name to its index
      function monthIndex(monthName) {
        return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].indexOf(monthName);
      }
      setSelectedDate(selectedDate);
    } else {
      setSelectedDate(new Date());
    }
  }, [packageId]);

  return (
    <>
      <div className="mainDiv">
        <div className="tourDetails">
          <h2>{packageDetails?.package_name}</h2>
          <p className="brdCmb">
            <span>{packageDetails?.total_days}</span>
            {/* By: <Link to=""> {packageDetails?.vendor_name}</Link>  */}
            {formattedStayPlan(packageDetails?.stay_plan, packageDetails?.total_train_nights_count)}
          </p>

          <div className="photosDiv" ref={photosRef}>
            <div className="photosLft">
              <div className="tourNav">
                <ul>
                  <li className={activeLink === "photos" ? "active" : ""}>
                    <button onClick={() => scrollToSection(photosRef, "photos")}>Photos</button>
                  </li>
                  <li className={activeLink === "package" ? "active" : ""}>
                    <button onClick={() => scrollToSection(packageRef, "package")}>Package Itinerary</button>
                  </li>
                  <li className={activeLink === "departure" ? "active" : ""}>
                    <button onClick={() => scrollToSection(departureRef, "departure")}>Departure Dates</button>
                  </li>
                  <li className={activeLink === "inclusions" ? "active" : ""}>
                    <button onClick={() => scrollToSection(inclusionsRef, "inclusions")}>Inclusions</button>
                  </li>
                  <li className={activeLink === "exclusions" ? "active" : ""}>
                    <button onClick={() => scrollToSection(exclusionsRef, "exclusions")}>Exclusions</button>
                  </li>
                  <li className={activeLink === "terms" ? "active" : ""}>
                    <button onClick={() => scrollToSection(termsRef, "terms")}>T & C</button>
                  </li>
                </ul>
              </div>

              <div className="tourSlider">
                {/* <Slider {...mainSliderSettings} ref={slider => (mainSlider = slider)}>
                    {[tour1, tour2, tour3, tour4, tour5, tour6, tour7, tour8].map((image, index) => (
                      <div key={index}>
                        <img src={image} alt={`Image ${index + 1}`} />
                      </div>
                    ))}
                  </Slider> */}
                <Slider {...mainSliderSettings} ref={slider => (mainSlider = slider)}>
                  {allSliderDetails?.map((item, index) => (
                    <div key={index}>
                      {item?.type === "image" ? <img src={IMAGE_URL + item.path} alt={`Image ${index + 1}`} /> : null}
                      {item?.type === "video" ? (
                        <iframe
                          title={`Video ${index + 1}`}
                          width="847"
                          height="488"
                          src={`https://www.youtube.com/embed/${getYouTubeVideoId(item.link)}?rel=0`}
                          frameBorder="0"
                          allowFullScreen></iframe>
                      ) : null}
                    </div>
                  ))}
                  {/* {packageDetails?.gallery_images &&
                      packageDetails?.gallery_images?.map((image, index) => (
                        <div key={index}>
                          <img src={IMAGE_URL + image.path} alt={`Image ${index + 1}`} />
                        </div>
                      ))}
                    {packageDetails?.media_link?.length > 0
                      ? packageDetails?.media_link?.map((video, videoIndex) => (
                          <div key={videoIndex}>
                            <iframe
                              title={`Video ${videoIndex + 1}`}
                              width="847"
                              height="488"
                              src={`https://www.youtube.com/embed/${getYouTubeVideoId(video.media_link)}?rel=0`}
                              frameBorder="0"
                              allowFullScreen></iframe>
                          </div>
                        ))
                      : null} */}
                </Slider>
              </div>

              <div className="thumbnailSlider">
                {/* <Slider {...thumbnailSliderSettings}>
                    {[tour1, tour2, tour3, tour4, tour5, tour6, tour7, tour8].map((image, index) => (
                      <div
                        key={index}
                        className={`thumbnail ${selectedImageIndex === index ? "active" : ""}`}
                        onClick={() => handleThumbnailClick(index)}
                        style={{width: "114px"}}>
                        <img src={image} alt={`Thumbnail ${index + 1}`} />
                      </div>
                    ))}
                  </Slider> */}
                <Slider {...thumbnailSliderSettings}>
                  {allSliderDetails?.map((item, index) => (
                    <div
                      key={index}
                      className={`thumbnail ${selectedImageIndex === index ? "active" : ""}`}
                      onClick={() => handleThumbnailClick(index)}
                      style={{width: "114px"}}>
                      {item?.type === "image" ? <img src={IMAGE_URL + item.path} alt={`Image ${index + 1}`} /> : null}
                      {item?.type === "video" ? <img src={item.src} alt={`Thumbnail ${index + 1}`} /> : null}
                    </div>
                  ))}
                  {/* {packageDetails?.gallery_images.map((image, index) => (
                      <div
                        key={index}
                        className={`thumbnail ${selectedImageIndex === index ? "active" : ""}`}
                        onClick={() => handleThumbnailClick(index)}
                        style={{width: "114px"}}>
                        <img src={IMAGE_URL + image.path} alt={`Thumbnail ${index + 1}`} />
                      </div>
                    ))}
                    {packageDetails?.media_link?.map((video, videoIndex) => (
                      <div key={videoIndex}>
                        <img src={`https://img.youtube.com/vi/${getYouTubeVideoId(video.media_link)}/0.jpg`} alt={`Thumbnail ${videoIndex + 1}`} />
                      </div>
                    ))} */}
                </Slider>
              </div>
            </div>

            <div className="photosRgt">
              <div className="photosInnerRgt">
                <h3>Starting From</h3>
                <h4>₹{packageDetails?.starting_price.toLocaleString()}</h4>
                <p>Including On GST</p>

                <button className="bookNowBtn">Book Now</button>
                {/* <button className="queryBtn" onClick={() => openQueryPopup()}>
                    Send Query
                  </button> */}
                <button className="wishBtn">Add To Wishlist</button>

                <button className="shareBtn">
                  <img src={share} alt="" />
                </button>
              </div>

              <div className="photosInnerRgt">
                <h2>Stay Plan</h2>

                <ul>
                  {/* <li>Leh- 2Nights</li>
                    <li>Nubra-1Night</li>
                    <li>Pangong-1Night</li> */}
                  {formattedStayPlan2(packageDetails?.stay_plan)}
                </ul>
              </div>
            </div>
          </div>

          <div className="iclusionDiv">
            <div className="innerInclusion">
              <h2>Inclusions</h2>

              <ul>
                {/* // packageDetails?.inclusions_in_package?.map((incluItem, incluIndex) => (
                  //   <li key={incluIndex}>
                  //     {incluItem.slug === "transfer" && <img src={transferIcon2} alt="Transfer Icon" />}
                  //     {incluItem.slug === "meals" && <img src={mealIcon2} alt="Meals Icon" />}
                  //     {incluItem.slug === "flight" && <img src={optionalIcon2} alt="Flight Icon" />}
                  //     {incluItem.slug === "hotel" && <img src={hotelIcon2} alt="Flight Icon" />}
                  //     {incluItem.name}
                  //   </li>
                  // )) */}
                {packageDetails?.is_flight === 1 ? (
                  <li>
                    <img src={optionalIcon2} alt="Image 1" />
                    Flight
                  </li>
                ) : null}
                {packageDetails?.is_train === 1 ? (
                  <li>
                    <img src={trainIcon3} alt="Image 1" />
                    Train
                  </li>
                ) : null}
                {packageDetails?.is_hotel === 1 ? (
                  <li>
                    <img src={hotelIcon2} alt="Image 1" />
                    Hotel
                  </li>
                ) : null}
                {packageDetails?.is_meal === 1 ? (
                  <li>
                    <img src={mealIcon2} alt="Image 1" />
                    Meals
                  </li>
                ) : null}
                {packageDetails?.is_transport === 1 ? (
                  <li>
                    <img src={transferIcon2} alt="Image 1" />
                    Transfer
                  </li>
                ) : null}
                {packageDetails?.is_sightseeing === 1 ? (
                  <li>
                    <img src={binocularIcon} alt="Image 1" />
                    Sightseeing
                  </li>
                ) : null}
              </ul>
            </div>

            <div className="innerInclusion">
              <h2>Themes</h2>

              <ul>
                {packageDetails &&
                  packageDetails?.themes?.map((themesItem, themesIndex) => (
                    <li key={themesIndex}>
                      <img src={IMAGE_URL + themesItem?.icon} alt="Image 1" />
                      {themesItem?.name}
                      {/* {themesItem.name === "Hill" && <img src={transferIcon2} alt="Transfer Icon" />}
                        {themesItem.name === "Beach" && <img src={mealIcon2} alt="Meals Icon" />}
                        {themesItem.name === "flight" && <img src={optionalIcon2} alt="Flight Icon" />}
                        {themesItem.name === "hotel" && <img src={hotelIcon2} alt="Flight Icon" />}
                        {themesItem.name} */}
                    </li>
                  ))}
                {/* <li>
                    <img src={hillIcon} alt="Image 1" />
                    Hill
                  </li>
                  <li>
                    <img src={beachIcon} alt="Image 1" />
                    Beach
                  </li>
                  <li>
                    <img src={jungleIcon} alt="Image 1" />
                    Jungle
                  </li>
                  <li>
                    <img src={natureIcon} alt="Image 1" />
                    Nature
                  </li> */}
              </ul>
            </div>
          </div>

          <div className="overviewDiv">
            <h2>Overview</h2>

            <p className="overTxt">{packageDetails?.overview}</p>
          </div>

          <div className="overviewDiv package" ref={packageRef}>
            <h2>Package Itinerary</h2>

            <div className="itinerayDiv">
              <ul className="tabHeader">
                {packageDetails &&
                  packageDetails?.itinerary?.map((item, index) => (
                    <li key={index} className={accordionOpen[index] ? "active" : ""}>
                      <button onClick={() => toggleAccordion(index)}>Day -{index + 1}</button>
                    </li>
                  ))}
                {/* {itinerary.map((item, index) => (
                    <li key={index} className={accordionOpen[index] ? "active" : ""}>
                      <button onClick={() => toggleAccordion(index)}>{item.day}</button>
                    </li>
                  ))} */}
              </ul>

              <div className="itaContent">
                {packageDetails &&
                  packageDetails?.itinerary?.map((item, index) => (
                    <div className="accordion" key={index}>
                      <div className={`accHeader ${accordionOpen[index] ? "open" : ""}`}>
                        <h2>
                          Day {index + 1} - {item?.place_city_name?.city}
                        </h2>
                        <button className="accordion-btn" onClick={() => toggleAccordion(index)}></button>
                      </div>
                      {accordionOpen[index] && (
                        <div className="accordion-content">
                          <h3>{item.itinerary_title}</h3>
                          <p>{item.itinerary_description}</p>
                          <ul>
                            {item?.flights?.length > 0 ? (
                              <li className="Flight">
                                <div className="accoInner">
                                  <div className="transportDiv">
                                    <img src={flightIcon} alt="" />
                                    Flight
                                  </div>

                                  {/* <div className="transportName">
                                        <h4>{ detail.name}</h4>
                                        <p>{detail.code}</p>
                                      </div> */}

                                  <div className="stationName">
                                    <h4>{item?.flights[0]?.depart_destination.id}</h4>
                                    <h4>{TimeConverter(item?.flights[0]?.depart_datetime)}</h4>
                                  </div>

                                  <div className="classCategory">
                                    <img src={flightIcon2} alt="" />
                                    <h4>{item?.flights[0]?.class}</h4>
                                  </div>

                                  <div className="destinationName">
                                    <h4>{item?.flights[0]?.arrive_destination.id}</h4>
                                    <h4>{TimeConverter(item?.flights[0]?.arrive_datetime)}</h4>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}
                            {item?.trains?.length > 0 ? (
                              <li className="Train">
                                <div className="accoInner">
                                  <div className="transportDiv">
                                    <img src={trainIcon2} alt="" />
                                    Train
                                  </div>

                                  <div className="transportName">
                                    <h4>{item?.trains[0]?.train_name}</h4>
                                    <p>{item?.trains[0]?.train_number}</p>
                                  </div>

                                  <div className="stationName">
                                    <h4>{item?.trains[0]?.depart_destination.id}</h4>
                                    <h4>{TimeConverter(item?.trains[0]?.depart_datetime)}</h4>
                                    {/* <h4>{item?.trains[0]?.depart_destination.name}</h4> */}
                                  </div>

                                  <div className="classCategory">
                                    <img src={trainIcon} alt="" />
                                    <h4>{item?.trains[0]?.class}</h4>
                                  </div>

                                  <div className="destinationName">
                                    <h4>{item?.trains[0]?.arrive_destination.id}</h4>
                                    <h4>{TimeConverter(item?.trains[0]?.arrive_datetime)}</h4>
                                    {/* <h4>{item?.trains[0]?.arrive_destination.name}</h4> */}
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}
                            {item?.hotels?.length > 0 ? (
                              <li className="Hotel">
                                <div className="accoInner">
                                  <div className="hotelHdnDiv">
                                    <img src={hotelIcon} alt="" />
                                    <div className="nameDesc">
                                      Hotel
                                      <p>{item?.hotels[0]?.description}</p>
                                    </div>
                                  </div>

                                  <div className="hotelSlider">
                                    {/* <Slider {...hotelSliderSettings}>
                                        {detail.images.map(
                                          (image, imgIndex) => (
                                            <div key={imgIndex}>
                                              <img
                                                src={image}
                                                alt={`Hotel Image ${
                                                  imgIndex + 1
                                                }`}
                                              />
                                            </div>
                                          )
                                        )}
                                      </Slider> */}
                                    <Slider {...hotelSliderSettings}>
                                      {item?.hotels[0]?.hotel_gallery?.map((hotelItem, hotelIndex) => (
                                        <div key={hotelIndex}>
                                          <img
                                            src={IMAGE_URL + hotelItem?.path}
                                            alt=""
                                            onClick={() => openHotelPopup(item?.hotels[0]?.hotel_gallery, hotelIndex)}
                                          />
                                        </div>
                                      ))}
                                    </Slider>
                                  </div>

                                  <div className="hotelDtls">
                                    {/* <img src={ratingImg} alt="" /> */}
                                    <StarRatings
                                      rating={item?.hotels[0]?.star} // Initial rating value
                                      starRatedColor="#F8B84E" // Color of the filled stars
                                      numberOfStars={5} // Total number of stars
                                      name="rating" // Unique name for the component
                                      starDimension="18px"
                                      starSpacing="2px"
                                    />
                                    <h3>{item?.hotels[0]?.hotel_name} or Similar</h3>
                                    {item?.hotels[0]?.is_other_place === 1 ? (
                                      <div className="nameDesc">
                                        <p>
                                          {item?.hotels[0]?.place_name} | {item?.hotels[0]?.distance_from_main_town}
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}
                            {item?.meal !== null ? (
                              <li className="Meal">
                                <div className="accoInner">
                                  <div className="transportDiv">
                                    <img src={mealIcon} alt="" />
                                    Meal
                                  </div>
                                  {item?.meal?.split(",")?.map((mealItem, mealIndex) => (
                                    <div className="mealServeDiv" key={mealIndex}>
                                      <h4 style={{textTransform: "capitalize"}}> {mealItem}</h4>
                                    </div>
                                  ))}
                                </div>
                              </li>
                            ) : (
                              ""
                            )}
                            {item?.sightseeing?.length > 0 ? (
                              <li className="Sightseeing">
                                <div className="accoInner sgtAco">
                                  <div className="transportDiv">
                                    <img src={sightIcon} alt="" />
                                    Sightseeing
                                  </div>
                                  <div className="trSight">
                                    {item?.sightseeing[0]?.morning ? (
                                      <div className="sightDiv">
                                        <h4>{item?.sightseeing[0]?.morning}</h4>
                                      </div>
                                    ) : null}
                                    {item?.sightseeing[0]?.afternoon ? (
                                      <div className="sightDiv">
                                        <h4>{item?.sightseeing[0]?.afternoon}</h4>
                                      </div>
                                    ) : null}
                                    {item?.sightseeing[0]?.evening ? (
                                      <div className="sightDiv">
                                        <h4>{item?.sightseeing[0]?.evening}</h4>
                                      </div>
                                    ) : null}
                                    {item?.sightseeing[0]?.night ? (
                                      <div className="sightDiv">
                                        <h4>{item?.sightseeing[0]?.night}</h4>
                                      </div>
                                    ) : null}
                                  </div>
                                  {item?.sightseeing[0]?.sightseeing_gallery?.length > 0 ? (
                                    <div className="hotelSlider">
                                      <Slider {...hotelSliderSettings}>
                                        {item?.sightseeing[0]?.sightseeing_gallery?.map((hotelItem, hotelIndex) => (
                                          <div key={hotelIndex}>
                                            <img
                                              src={IMAGE_URL + hotelItem?.path}
                                              alt=""
                                              onClick={() => openHotelPopup(item?.sightseeing[0]?.sightseeing_gallery, hotelIndex)}
                                            />
                                          </div>
                                        ))}
                                      </Slider>
                                    </div>
                                  ) : null}
                                </div>
                              </li>
                            ) : (
                              ""
                            )}
                            {item?.local_transport?.length > 0 ? (
                              <li className="Local Transport">
                                <div className="accoInner">
                                  <div className="transportDiv localTrans">
                                    <img src={carImg} alt="" />
                                    Local Transport
                                  </div>

                                  <div className="transportName">
                                    <h4>Car</h4>
                                    <p>{item?.local_transport[0]?.capacity} Seater</p>
                                  </div>

                                  <div className="carName">
                                    <img src={carIcon} alt="" />
                                    {item?.local_transport[0]?.car} {item?.local_transport[0]?.model}
                                  </div>
                                </div>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="overviewDiv" ref={departureRef}>
            <div className="calendarTop">
              <h2>Departure Dates</h2>
              <ul className="dateStatus">
                <li className="greenColor">
                  <span></span>Lowest Price
                </li>
                <li className="blueColor">
                  <span></span>Regular Price
                </li>
                <li className="redColor">
                  <span></span>Sold Out
                </li>
                <li className="blackColor">
                  <span></span>On Request
                </li>
                <li className="orangeColor">
                  <span></span>Selling Fast
                </li>
              </ul>
            </div>

            <div className="calendarSec">
              <Calendar
                tileClassName={tileClassName}
                tileContent={tileContent}
                onClickDay={handleDateClick}
                value={selectedDate}
                minDate={minDate}
                maxDate={maxDate}
              />
              {/* {selectedDate && (
                  <div>
                    <p>Date: {selectedDate.toDateString()}</p>
                    <p>Status: {getDayStatus(selectedDate)}</p>
                  </div>
                )} */}
            </div>
            <div className="bookNowBtn2">
              <button className="bookNowBtn">Book Now</button>
            </div>
          </div>

          <div className="overviewDiv" ref={inclusionsRef}>
            <h2>Inclusions</h2>

            <div className="inclusDiv">
              <ul>
                {packageDetails?.inclusions_list?.map((incliListItem, incliListIndex) => (
                  <li key={incliListIndex}>{incliListItem?.name}</li>
                ))}

                {/* <li>Assistance upon arrival/departure at Airport</li> */}
                {/* <li>03 Nights accommodation on twin sharing basis at Leh.</li>
                  <li>01 Night accommodation on twin sharing basis at Nubra.</li>
                  <li>01 Night accommodation on twin sharing basis at Pangong.</li>
                  <li>05 Breakfastand 05 Dinners and 3 Lunch. Transportation and sightseeing by Non-ac Tempo Traveller or similar.</li>
                  <li>01 Bottle of packaged drinking water every day.</li>
                  <li>Oxygen Cylinder in each vehicleduring the tour.</li> */}
              </ul>
            </div>
          </div>

          <div className="overviewDiv" ref={exclusionsRef}>
            <h2>Exclusions</h2>

            <div className="inclusDiv">
              <ul>
                {packageDetails?.exclusions_list?.map((excluListItem, excluListIndex) => (
                  <li key={excluListIndex}>{excluListItem?.name}</li>
                ))}
                {/* <li>GST 5%.</li>
                  <li>Any Kind of Personal Expenses or Optional Tours/ Extra Meals Ordered.</li>
                  <li>Anything not specifically mentioned under the head "Prices included".</li>
                  <li>Tips, Insurance, Laundry, Phone Calls.</li>
                  <li>Any Kind of Drinks (Alcoholic, Mineral, Aerated).</li>
                  <li>Camera fee, Sort or Hand drinks, Starters.</li>
                  <li>Medical & Travel Insurance.</li>
                  <li>Tips, Insurance, Laundry, Phone Calls.</li> */}
              </ul>
            </div>
          </div>

          <div className="overviewDiv">
            <h2>Payment Policy</h2>

            <div className="inclusDiv">
              <ul>
                {packageDetails?.payment_policy.map((policy, index) => {
                  let [from, to, percentage] = policy.split("-");
                  return (
                    <li key={index}>
                      {index === 0 ? `${from} or more days before departure: ${percentage}%` : `Between ${from} to ${to} days before departure: ${percentage}%`}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="overviewDiv">
            <h2>Cancellation Policy</h2>
            <div className="inclusDiv">
              <ul>
                {packageDetails?.cancellation_policy.map((policy, index) => {
                  let [from, to, percentage] = policy.split("-");
                  return (
                    <li key={index}>
                      {index === 0 ? `${from} or more days before departure: ${percentage}% of total package cost` : `Between ${from} to ${to} days before departure: ${percentage}% of total package cost`}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="overviewDiv" ref={termsRef}>
            <h2>Terms & Conditions</h2>
            <div className="termstxt">
              <p style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: packageDetails?.terms_and_condition}} />
            </div>
            <h4>General Terms & Conditions</h4>
            <div className="termstxt">
              <p style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: packageDetails?.default_terms_and_condition}} />
            </div>
          </div>
        </div>
      </div>

      <div className="hotelSliderPopup" style={{display: showHotelPopup ? "block" : "none"}}>
        <div className="sliderflex">
          <div className="sliderInner">
            <Slider {...hotelSliderSettings}>
              {hotelGalleryForPopup?.map((hotelItem, hotelIndex) => (
                <div key={hotelIndex}>
                  <img src={IMAGE_URL + hotelItem?.path} alt="" />
                </div>
              ))}
            </Slider>
            <button className="closePopupBtn" onClick={closeHotelPopup}>
              <img src={crossIcon} alt="" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TourPreviewByVendor;
