import React, {useState, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";
import {changePackageStatusApi, getBookingListApi, vendorCitiesApi} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import {Form, Button, Dropdown} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Typeahead} from "react-bootstrap-typeahead";
import LoadingPage from "../component/loadingPage";

const crossIcon2 = require("./../assets/img/cross.svg").default;
const filterBtn = require("./../assets/img/filter-icon.svg").default;

const VendorBookingList = () => {
  const navigate = useNavigate();
  const [bookingList, setBookingList] = useState([]);
  const [originCityList, setOriginCityList] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("all");
  const [dynamicTableKey, setDynamicTableKey] = useState(1);
  const [screenLoading, setScreenLoading] = useState(false);

  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState();
  const [adminMsgText, setAdminMsgText] = useState();
  const [hasCancelledOrModified, setHasCancelledOrModified] = useState(0);
  const [filterParameter, setFilterParameter] = useState({
    filterType: 1,
    startDate: null,
    endDate: null,
    isCancelledBooking: false,
    bookingStatus: null,
    phoneNumber: null,
    customerName: null,
    destination: null,
    originDestination: null,
    bookingId: null,
  });
  const [applyFilterBtn, setApplyFilterBtn] = useState(false);
  const [bookingStatusArray, setBookingStatusArray] = useState([
    {id: 1, value: "In Process"},
    {id: 2, value: "Confirmed"},
    {id: 3, value: "Completed"},
    {id: 4, value: "Cancelled"},
  ]);

  const columns = [
    {
      name: "Booking ID",
      selector: row => "#" + row.booking_id,
      cell: row => <Link to={`/business/booking/${row.booking_id}`}>#{row.booking_id}</Link>,
      sortable: true,
      minWidth: "117px",
      maxWidth: "13%",
    },
    // {
    //   name: "Pack ID",
    //   selector: row => "#" + row.package_id,
    //   sortable: true,
    //   minWidth: "100px",
    //   maxWidth: "13%",
    // },
    {
      name: "Package Title",
      selector: row => {
        const maxLength = 30; // Maximum number of characters before adding ellipsis
        const packageName = row.package_name;
        return packageName.length > maxLength ? packageName.slice(0, maxLength) + "..." : packageName;
      },
      sortable: true,
      minWidth: "225px",
      maxWidth: "14%",
      sortable: true,
      minWidth: "245px",
      maxWidth: "22%",
    },
    // {
    //   name: "Customer Name",
    //   selector: row => row.name,
    //   sortable: true,
    //   minWidth: "149px",
    //   maxWidth: "17%",
    // },
    {
      name: "Phone No.",
      selector: row => row.phone_number,
      sortable: true,
      minWidth: "115px",
      maxWidth: "10%",
    },
    {
      name: "Origin Date",
      selector: row => {
        const originalDateString = row.first_booking_date;

        // Parse the original date string into a Date object
        const originalDate = new Date(originalDateString);

        // Months array for mapping month indexes to month names
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Get the month, day, and year from the parsed date
        const monthIndex = originalDate.getMonth();
        const day = originalDate.getDate();
        const year = originalDate.getFullYear();

        // Format the date string
        const formattedDateString = `${day < 10 ? "0" + day : day} ${months[monthIndex]},  ${year}`;
        return formattedDateString;
      },
      sortable: true,
      minWidth: "125px",
      maxWidth: "12%",
    },
    {
      name: "Pax",
      selector: row => (row.total_pax < 10 ? "0" + row.total_pax : row.total_pax),
      sortable: true,
      minWidth: "20px",
      maxWidth: "12%",
    },
    {
      name: "Amount",
      selector: row => "₹" + Math.round(row.booking_cost),
      sortable: true,
      minWidth: "100px",
      maxWidth: "12%",
    },
    {
      name: "Status",
      selector: row => {
        const maxLength = 35; // Maximum number of characters before adding ellipsis
        const bookingStatus = row.booking_status;
        return (
          <span
            className={
              bookingStatus === "Completed"
                ? `blueStatusText`
                : bookingStatus === "Ongoing"
                ? `greenStatusText`
                : bookingStatus === "Confirmed"
                ? // : bookingStatus === "Upcoming"
                  `yellowStatusText`
                : bookingStatus === "Cancelled"
                ? `redStatusText`
                : bookingStatus === "In process"
                ? `pinkStatusText`
                : bookingStatus === "Modified"
                ? `violetStatusText`
                : ``
            }>
            {bookingStatus}
          </span>
        );
      },
      // selector: (row) => row.booking_status,
      sortable: true,
      minWidth: "60px",
      maxWidth: "12%",
    },
  ];

  const data = [
    {
      quoteID: 1157820,
      reatilerName: "Attilio Gatti",
      businessLegalName: "Maroob Perfume Store",
      RetailerInternalID: "Perfume & Cosmetic",
      quoteDate: "Amman",
      totalQuote: "150 orders",
      quoteItems: "1532.00 JOD",
    },
    {
      quoteID: 1157821,
      reatilerName: "M. Hossain",
      businessLegalName: "Qalb Grocery Store",
      RetailerInternalID: "Grocery",
      quoteDate: "Zarqa",
      totalQuote: "298 orders",
      quoteItems: "2117.00 JOD",
    },
    {
      quoteID: 1157822,
      reatilerName: "Firoja Begum",
      businessLegalName: "New Habibi Amman",
      RetailerInternalID: "Perfume & Cosmetic",
      quoteDate: "Na’ur",
      totalQuote: "432 orders",
      quoteItems: "32115.00 JOD",
    },
    {
      quoteID: 1157823,
      reatilerName: "Jafar Javeed",
      businessLegalName: "Jafar & Sons",
      RetailerInternalID: "Grocery",
      quoteDate: "Beit Raus",
      totalQuote: "298 orders",
      quoteItems: "2117.00 JOD",
    },
    {
      quoteID: 1157824,
      reatilerName: "Sk. Sahanawaz",
      businessLegalName: "Big Grocery",
      RetailerInternalID: "Grocery",
      quoteDate: "Amman",
      totalQuote: "732 orders",
      quoteItems: "4117.00 JOD",
    },
    {
      quoteID: 1157825,
      reatilerName: "Makbul Hossain",
      businessLegalName: "Mood Fragrances",
      RetailerInternalID: "Perfume & Cosmetic",
      quoteDate: "Fuheis",
      totalQuote: "385 orders",
      quoteItems: "2536.00 JOD",
    },
    {
      quoteID: 1157826,
      reatilerName: "Irfaan Khan",
      businessLegalName: "Grocery Market Store",
      RetailerInternalID: "Grocery",
      orderDate: "Fuheis",
      totalQuote: "98 orders",
      quoteItems: "186.00 JOD",
    },
    {
      quoteID: 1157827,
      reatilerName: "Rana Al Abiad",
      businessLegalName: "Food&Food Hub",
      RetailerInternalID: "Grocery",
      orderDate: "Beit Raus",
      totalQuote: "315 orders",
      quoteItems: "5123.00 JOD",
    },
  ];

  const formattedDateFunc = date => {
    const originalDateString = date;

    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);

    // Months array for mapping month indexes to month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get the month, day, and year from the parsed date
    const monthIndex = originalDate.getMonth();
    const day = originalDate.getDate();
    const year = originalDate.getFullYear();

    // Format the date string
    const formattedDateString = `${day < 10 ? "0" + day : day} ${months[monthIndex]},  ${year}`;
    return formattedDateString;
  };

  const handleReserFilter = async () => {
    setFilterParameter(prev => ({
      ...prev,
      startDate: null,
      endDate: null,
      isCancelledBooking: false,
      bookingStatus: null,
      phoneNumber: null,
      customerName: null,
      destination: null,
      originDestination: null,
      bookingId: null,
    }));
    setApplyFilterBtn(!applyFilterBtn);
    setShowPopup3(false);
  };

  const getBookingList = async status => {
    setScreenLoading(true);
    function formatDate(date) {
      return new Intl.DateTimeFormat("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(date);
    }
    const formData = new FormData();
    if (status === "initial" && setLoading(true));

    try {
      {
        status && status !== "initial" && formData.append("flag_status", status ? (status === "5" ? "4,5" : status) : "");
      }

      if (filterParameter.filterType === 1 && filterParameter.startDate && !filterParameter.endDate) {
        NotificationManager.warning("Please select an end date.");
        setLoading(false);
        setScreenLoading(false);
        return;
      }
      if (filterParameter.filterType === 1 && !filterParameter.startDate && filterParameter.endDate) {
        NotificationManager.warning("Please select an start date.");
        setScreenLoading(false);
        return;
      }
      formData.append("start_date", filterParameter?.startDate ? formatDate(new Date(filterParameter?.startDate)) : "");
      formData.append("end_date", filterParameter?.endDate ? formatDate(new Date(filterParameter?.endDate)) : "");
      formData.append("booking_id", filterParameter?.bookingId ? filterParameter?.bookingId : "");
      formData.append("phone_number", filterParameter?.phoneNumber ? filterParameter?.phoneNumber : "");
      formData.append("name", filterParameter?.customerName ? filterParameter?.customerName : "");
      formData.append("origin_city_id", filterParameter?.originDestination ? filterParameter?.originDestination[0]?.id : "");
      formData.append("booking_status", filterParameter?.bookingStatus ? filterParameter?.bookingStatus : "");

      let response = await getBookingListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setBookingList(response.data.data);
          setHasCancelledOrModified(response.data.hasCancelledOrModified);
        } else {
        }
        setScreenLoading(false);
        setLoading(false);
      }
    } catch (error) {
      setScreenLoading(false);
      setLoading(false);
    }
  };

  const fetchVendorCities = async () => {
    try {
      let response = await vendorCitiesApi();
      if (response) {
        if (response.data.res === true) {
          setOriginCityList(response.data.data);
        } else {
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Filter bookingList based on the search input
  const filteredPackageList = bookingList.filter(item => item?.package_name?.toLowerCase().includes(search?.toLowerCase()));

  const handleCheckBoxChange = state => {
    let tempArray = [];
    if (state?.selectedRows?.length > 0) {
      state?.selectedRows?.map(item => {
        tempArray.push(item?.booking_id);
      });
    }
    setSelectedCheckboxIds(tempArray);
  };

  const handleActionChange = async (actionType, status) => {
    setDynamicTableKey(prev => prev + 1);
    try {
      const formData = new FormData();
      if (selectedCheckboxIds?.length > 0) {
        selectedCheckboxIds?.map(item => {
          formData.append("package_ids[]", item);
        });
      }
      formData.append("status", status ? status : "");

      let response = await changePackageStatusApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          if (activeTab === "active" || activeTab === "inactive") {
            const updatedPackageList = bookingList.filter(item => !selectedCheckboxIds.includes(parseInt(item.booking_id)));
            setBookingList(updatedPackageList);
          } else {
            if (status === "3") {
              const updatedPackageList = bookingList.filter(item => !selectedCheckboxIds.includes(parseInt(item.booking_id)));
              setBookingList(updatedPackageList);
            } else if (status === "0") {
              const updatedPackageList = bookingList.map(item => {
                if (selectedCheckboxIds.includes(parseInt(item.booking_id))) {
                  return {...item, status: "Inactive"}; // Assuming 'status' is the property to update
                }
                return item;
              });
              setBookingList(updatedPackageList);
            } else if (status === "1") {
              const updatedPackageList = bookingList.map(item => {
                if (selectedCheckboxIds.includes(parseInt(item.booking_id))) {
                  return {...item, status: "Active"}; // Assuming 'status' is the property to update
                }
                return item;
              });
              setBookingList(updatedPackageList);
            } else {
            }
          }
          setSelectedCheckboxIds([]);
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {}
  };

  const handleDuplicate = async () => {
    if (selectedCheckboxIds.length > 1) {
      NotificationManager.warning("You have to select only one package to duplicate.");
    } else {
      navigate("/business/package/duplicate/" + selectedCheckboxIds[0]);
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  const messageAdmin = () => {
    let tempArray = [...bookingList];
    let selectedData = tempArray.find(item => parseInt(item?.booking_id) === selectedCheckboxIds[0]);
    // console.log(selectedCheckboxIds);
    // console.log(selectedData);
    setSelectedBooking(selectedData);
    setShowPopup(true);
  };

  const [showPopup2, setShowPopup2] = useState(false);

  const uploadCSV = () => {
    setShowPopup2(true);
  };

  const [showPopup3, setShowPopup3] = useState(false);
  const filterPopup = () => {
    setShowPopup3(true);
  };

  const [selectedTab, setSelectedTab] = useState("Date Range");

  const handleTabClick = tab => {
    setSelectedTab(tab);
    setFilterParameter(prev => ({
      ...prev,
      filterType: tab === "Date Range" ? 1 : 2,
      startDate: null,
      endDate: null,
      isCancelledBooking: false,
      bookingStatus: null,
      phoneNumber: null,
      customerName: null,
      destination: null,
      originDestination: null,
      bookingId: null,
    }));
  };

  const handleSendMsgToAdmin = async () => {};

  useEffect(() => {
    // Clear selected checkboxes when bookingList is updated
    setSelectedCheckboxIds([]);
  }, [bookingList]);

  useEffect(() => {
    const result = data.filter(item => {
      //return item.title.toLowerCase().match(search.toLowerCase());
    });
    setFilter(result);
  }, [search]);

  useEffect(() => {
    getBookingList("initial");
  }, [applyFilterBtn]);

  useEffect(() => {
    fetchVendorCities();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Booking List`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Booking list page" />
      </Helmet>
      <VenderHeader />
      <div className="venderInnerDiv">
        <VenderSidebar />

        <div className="venderRight">
          {screenLoading && <LoadingPage />}
          <div className="hdrSec">
            <h2>Your Bookings</h2>
            <div className="btnsHdn">
              <Link to="" className="offLink">
                Offline Booking
              </Link>
              <button className="saveBtn saveBtn2" onClick={() => uploadCSV()}>
                Upload CSV
              </button>
            </div>
          </div>
          <div className="venderMain venderMain2">
            <ul className="navbar packages-list">
              <li className={activeTab === "all" ? "active" : ""}>
                <Link
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("all");
                    getBookingList();
                  }}>
                  All
                </Link>
              </li>
              <li className={activeTab === "inprocess" ? "active" : ""}>
                <Link
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("inprocess");
                    getBookingList("1");
                  }}>
                  In Process
                </Link>
              </li>
              {/* {hasCancelledOrModified === 1 ? ( */}
                <li className={activeTab === "cancel" ? "active" : ""}>
                  <Link
                    onClick={() => {
                      setSelectedCheckboxIds([]);
                      setActiveTab("cancel");
                      getBookingList("5");
                    }}>
                    Cancellation
                  </Link>
                </li>
              {/* ) : null} */}

              {/* <li className={activeTab === "inactive" ? "active" : ""}>
                <Link
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("inactive");
                    getBookingList("0");
                  }}>
                  Cancellation Approved
                </Link>
              </li> */}
            </ul>
            <div className="data-table-section bookDataSec">
              {!loading ? (
                <DataTable
                  key={activeTab + dynamicTableKey}
                  className="Tbl packages-listing booking-listing"
                  columns={columns}
                  data={filteredPackageList}
                  fixedHeader
                  //fixedHeaderScrollHeight="300px"
                  highlightOnHover
                  pagination
                  //paginationComponentOptions={paginationComponentOptions}
                  responsive
                  selectableRows
                  selectableRowsHighlight
                  subHeader
                  subHeaderAlign="left"
                  onSelectedRowsChange={handleCheckBoxChange}
                  onRowClicked={row => navigate(`/business/booking/${row.booking_id}`)}
                  subHeaderComponent={
                    <>
                      <div className="search-box">
                        <img src={filterBtn} alt="" className="filterBtn" onClick={() => filterPopup()} />
                        <Form.Control type="text" className="SrchInpt" placeholder="Search here" value={search} onChange={e => setSearch(e.target.value)} />
                      </div>
                      <div className="ActionRow">
                        <span className="LftField">
                          <span>Action:</span>{" "}
                          <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 || activeTab === "inactive" ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 || activeTab === "inactive" ? true : false}
                            onClick={() => handleActionChange("inactive", "0")}>
                            Generate Invoice
                          </button>
                          {/* <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 || activeTab === "active" ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 || selectedCheckboxIds?.length > 1 ? true : false}
                            onClick={() => messageAdmin()}>
                            Message to Admin
                          </button>
                          <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 || selectedCheckboxIds?.length > 1 ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 || selectedCheckboxIds?.length > 1 ? true : false}
                            onClick={() => handleDuplicate()}>
                            Cancel
                          </button> */}
                        </span>
                      </div>
                      {/* <p className='SelectedRow'>08 Selected</p> */}
                    </>
                  }
                />
              ) : null}
            </div>
          </div>
        </div>

        {showPopup && (
          <div className={`popup addPopup`}>
            <div className="sliderflex">
              <div className="popInner2 bookingPopup">
                <h2>Message Admin</h2>
                <ul className="formList">
                  <li className="fullWd">
                    <textarea onChange={e => setAdminMsgText(e.target.value)}>Type message</textarea>
                  </li>
                  <li className="halfWd">
                    <label>Package</label>
                    <select>
                      <option value={selectedBooking?.package_name}>{selectedBooking?.package_name}</option>
                    </select>
                  </li>
                  <li className="smlWd">
                    <label>Package ID</label>
                    <input readOnly type="text" placeholder="#551236" value={"#" + selectedBooking?.package_id} />
                  </li>
                  <li className="halfWd">
                    <label>Run Date</label>
                    <select>
                      {/* <option>March 20, 2024</option> */}
                      <option value={selectedBooking?.first_booking_date}>{formattedDateFunc(selectedBooking?.first_booking_date)}</option>
                    </select>
                  </li>
                  <li className="fullWd">
                    <button className="addBtn addBtn2" disabled={!adminMsgText} onClick={() => handleSendMsgToAdmin()}>
                      Send Message
                    </button>
                  </li>
                </ul>
                <button className="closePopupBtn2" onClick={() => setShowPopup(false)}>
                  <img src={crossIcon2} alt="" />
                </button>
              </div>
            </div>
          </div>
        )}

        {showPopup2 && (
          <div className={`popup addPopup`}>
            <div className="sliderflex">
              <div className="popInner2 bookingPopup">
                <h2>Upload CSV</h2>
                <ul className="formList">
                  <li className="fullWd">
                    <label>File</label>

                    <input type="file" />
                  </li>

                  <li className="fullWd">
                    <button className="addBtn">Save</button>
                  </li>
                </ul>
                <button className="closePopupBtn2" onClick={() => setShowPopup2(false)}>
                  <img src={crossIcon2} alt="" />
                </button>
              </div>
            </div>
          </div>
        )}

        {showPopup3 && (
          <div className={`popup addPopup`}>
            <div className="sliderflex">
              <div className="popInner2 filterPopup">
                <h2>Filter</h2>

                <div className="tabs">
                  {["Date Range", "Single Booking"].map(tab => (
                    <div key={tab} className={`tab ${tab === selectedTab ? "active" : ""}`} onClick={() => handleTabClick(tab)}>
                      {tab}
                    </div>
                  ))}
                </div>

                <div className="tabContent">
                  {selectedTab === "Date Range" && (
                    <div>
                      <ul className="formList">
                        <li>
                          <div className="datePckr2">
                            <DatePicker
                              selected={filterParameter.startDate}
                              onChange={date =>
                                setFilterParameter({
                                  ...filterParameter,
                                  startDate: date,
                                })
                              }
                              // dateFormat="yyyy-MM-dd"
                              dateFormat="MMMM d, yyyy"
                              placeholderText="Booking Date From"
                              // minDate={new Date()}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="datePckr2">
                            <DatePicker
                              selected={filterParameter.endDate}
                              onChange={date =>
                                setFilterParameter({
                                  ...filterParameter,
                                  endDate: date,
                                })
                              }
                              // dateFormat="yyyy-MM-dd"
                              dateFormat="MMMM d, yyyy"
                              placeholderText="Booking Date To"
                              // minDate={new Date()}
                            />
                          </div>
                        </li>
                        {/* <li>
                          <label class="checkCustom">
                            Cancelled Booking
                            <input
                              type="checkbox"
                              value={filterParameter.isCancelledBooking}
                              onChange={e =>
                                setFilterParameter({
                                  ...filterParameter,
                                  isCancelledBooking: e.target.checked,
                                })
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li> */}
                        {activeTab === "all" ? (
                          <li>
                            <select
                              value={filterParameter.bookingStatus}
                              onChange={e =>
                                setFilterParameter({
                                  ...filterParameter,
                                  bookingStatus: e.target.value,
                                })
                              }>
                              <option selected hidden>
                                Booking Status
                              </option>
                              {bookingStatusArray?.map((item, index) => (
                                <option key={index} value={item?.id}>
                                  {item?.value}
                                </option>
                              ))}
                            </select>
                          </li>
                        ) : null}

                        <li>
                          <input
                            type="number"
                            placeholder="Phone Number"
                            value={filterParameter.phoneNumber}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                phoneNumber: e.target.value,
                              })
                            }
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Customer Name"
                            value={filterParameter.customerName}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                customerName: e.target.value,
                              })
                            }
                          />
                        </li>
                        {/* <li>
                          <input
                            type="text"
                            placeholder="Destination"
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                destination: e.target.value,
                              })
                            }
                          />
                        </li> */}
                        <li>
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="city"
                            onChange={selected => {
                              setFilterParameter({
                                ...filterParameter,
                                originDestination: selected,
                              });
                            }}
                            options={originCityList}
                            placeholder="Origin Destination"
                            selected={filterParameter.originDestination}
                          />
                          {/* <input
                            type="text"
                            placeholder="Origin Destination"
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                originDestination: e.target.value,
                              })
                            }
                          /> */}
                        </li>

                        <li className="fullWd appBtn">
                          <button
                            onClick={() => {
                              setApplyFilterBtn(!applyFilterBtn);
                              setShowPopup3(false);
                            }}>
                            Apply Filters
                          </button>
                        </li>
                        <li className="fullWd resetFilterBtn">
                          <a
                            href="javascript:void(0);"
                            onClick={() => {
                              handleReserFilter();
                            }}>
                            Reset Filters
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}

                  {selectedTab === "Single Booking" && (
                    <div>
                      <ul className="formList">
                        <li>
                          <input
                            type="text"
                            placeholder="Booking ID"
                            value={filterParameter.bookingId}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                bookingId: e.target.value,
                              })
                            }
                          />
                        </li>
                        <li>
                          <select
                            value={filterParameter.bookingStatus}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                bookingStatus: e.target.value,
                              })
                            }>
                            <option selected hidden>
                              Booking Status
                            </option>
                            {bookingStatusArray?.map((item, index) => (
                              <option key={index} value={item?.id}>
                                {item?.value}
                              </option>
                            ))}
                          </select>
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Phone Number"
                            value={filterParameter.phoneNumber}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                phoneNumber: e.target.value,
                              })
                            }
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Customer Name"
                            value={filterParameter.customerName}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                customerName: e.target.value,
                              })
                            }
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Destination"
                            value={filterParameter.destination}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                destination: e.target.value,
                              })
                            }
                          />
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Origin Destination"
                            value={filterParameter.originDestination}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                originDestination: e.target.value,
                              })
                            }
                          />
                        </li>

                        <li className="fullWd appBtn">
                          <button
                            onClick={() => {
                              setApplyFilterBtn(!applyFilterBtn);
                              setShowPopup3(false);
                            }}>
                            Apply Filters
                          </button>
                        </li>
                        <li className="fullWd resetFilterBtn">
                          <a
                            href="javascript:void(0);"
                            onClick={() => {
                              handleReserFilter();
                            }}>
                            Reset Filters
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <button className="closePopupBtn2" onClick={() => setShowPopup3(false)}>
                  <img src={crossIcon2} alt="" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VendorBookingList;
