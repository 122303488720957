import React, {useState, useEffect} from "react";
import VenderHeader from "./../component/Vender-Header";
import VenderSidebar from "./../component/Vender-Sidebar";
import {
  agentBookingCommissionClaimApi,
  agentCommissionListApi,
  agentPaymentHistoryApi,
  changePackageStatusApi,
  getBookingListApi,
  vendorCitiesApi,
} from "../services/ApiService";
import {NotificationManager} from "react-notifications";
import {useNavigate} from "react-router";
import {Helmet} from "react-helmet";
import {Form, Button, Dropdown} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Typeahead} from "react-bootstrap-typeahead";
import LoadingPage from "../component/loadingPage";
import AgentHeader from "../component/Agent-Header";
import AgentSideBar from "../component/Agent-Sidebar";

const crossIcon2 = require("./../assets/img/cross.svg").default;
const filterBtn = require("./../assets/img/filter-icon.svg").default;

const AgentCommisionList = () => {
  const navigate = useNavigate();
  const [bookingList, setBookingList] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [originCityList, setOriginCityList] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("current");
  const [dynamicTableKey, setDynamicTableKey] = useState(1);
  const [screenLoading, setScreenLoading] = useState(false);

  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState();
  const [adminMsgText, setAdminMsgText] = useState();
  const [hasCancelledOrModified, setHasCancelledOrModified] = useState(0);
  const [filterParameter, setFilterParameter] = useState({
    filterType: 1,
    startDate: null,
    endDate: null,
    isCancelledBooking: false,
    bookingStatus: null,
    paymentStatus: null,
    customerName: null,
    bookingId: null,
  });
  const [applyFilterBtn, setApplyFilterBtn] = useState(false);
  const [bookingStatusArray, setBookingStatusArray] = useState([
    {id: 1, value: "In Process"},
    {id: 2, value: "Confirmed"},
    {id: 3, value: "Completed"},
    {id: 4, value: "Cancelled"},
  ]);
  const [paymentStatusArray, setPaymentStatusArray] = useState([
    {id: "0", value: "Pending"},
    {id: 1, value: "Processing"},
    {id: 2, value: "Paid"},
  ]);

  const columns = [
    {
      name: "Booking ID",
      selector: row => "#" + row.booking_id,
      sortable: true,
      cell: row => (
        <Link to={`/partners/booking/detail/${row.booking_id}`} target="_blank">
          #{row.booking_id}
        </Link>
      ),
      // minWidth: "90px",
      // maxWidth: "13%",
    },
    // {
    //   name: "Booking date",
    //   selector: row => {
    //     const originalDateString = row.first_booking_date;

    //     // Parse the original date string into a Date object
    //     const originalDate = new Date(originalDateString);

    //     // Months array for mapping month indexes to month names
    //     const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    //     // Get the month, day, and year from the parsed date
    //     const monthIndex = originalDate.getMonth();
    //     const day = originalDate.getDate();
    //     const year = originalDate.getFullYear();

    //     // Format the date string
    //     const formattedDateString = `${day < 10 ? "0" + day : day} ${months[monthIndex]},  ${year}`;
    //     return formattedDateString;
    //   },
    //   sortable: true,
    //   // minWidth: "135px",
    //   maxWidth: "13%",
    // },
    // {
    //   name: "Package Name",
    //   selector: row => {
    //     const maxLength = 30; // Maximum number of characters before adding ellipsis
    //     const packageName = row.package_name;
    //     return packageName.length > maxLength ? packageName.slice(0, maxLength) + "..." : packageName;
    //   },
    //   sortable: true,
    //   minWidth: "225px",
    //   maxWidth: "14%",
    //   sortable: true,
    //   // minWidth: "245px",
    //   maxWidth: "22%",
    // },
    // {
    //   name: "Payment Status",
    //   selector: row => row.payment_status,
    //   selector: row => {
    //     const maxLength = 35; // Maximum number of characters before adding ellipsis
    //     const paymentStatus = row.payment_status;
    //     return (
    //       <span
    //         className={paymentStatus === "Paid" ? `greenStatusText` : paymentStatus === "Pending" || paymentStatus === "Processing" ? `yellowStatusText` : ``}>
    //         {paymentStatus}
    //       </span>
    //     );
    //   },
    //   sortable: true,
    //   // minWidth: "115px",
    //   maxWidth: "10%",
    // },
    {
      name: "Completion/Cancellation Date",
      selector: row => {
        const originalDateString = row.commission_date;

        // Parse the original date string into a Date object
        const originalDate = new Date(originalDateString);

        // Months array for mapping month indexes to month names
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Get the month, day, and year from the parsed date
        const monthIndex = originalDate.getMonth();
        const day = originalDate.getDate();
        const year = originalDate.getFullYear();

        // Format the date string
        const formattedDateString = `${day < 10 ? "0" + day : day} ${months[monthIndex]},  ${year}`;
        return formattedDateString;
      },
      sortable: true,
      // minWidth: "135px",
      // maxWidth: "13%",
    },
    // {
    //   name: "Travel Status",
    //   selector: row => {
    //     const maxLength = 35; // Maximum number of characters before adding ellipsis
    //     const bookingStatus = row.booking_status;
    //     return (
    //       <span
    //         className={
    //           bookingStatus === "Completed"
    //             ? `blueStatusText`
    //             : bookingStatus === "Ongoing"
    //             ? `greenStatusText`
    //             : bookingStatus === "Confirmed"
    //             ? // : bookingStatus === "Upcoming"
    //               `yellowStatusText`
    //             : bookingStatus === "Cancelled"
    //             ? `redStatusText`
    //             : bookingStatus === "In process"
    //             ? `pinkStatusText`
    //             : bookingStatus === "Modified"
    //             ? `violetStatusText`
    //             : ``
    //         }>
    //         {bookingStatus}
    //       </span>
    //     );
    //   },
    //   sortable: true,
    //   // minWidth: "100px",
    //   maxWidth: "17%",
    // },
    {
      name: "Basic Amt.(WT)",
      selector: row => "₹" + Math.round(row.base_price),
      sortable: true,
      // minWidth: "100px",
      // maxWidth: "12%",
    },
    // {
    //   name: "Total Amt Inc. GST",
    //   selector: row => "₹" + Math.round(row.final_price),
    //   sortable: true,
    //   minWidth: "100px",
    //   maxWidth: "12%",
    // },
    // {
    //   name: "Paid Amt",
    //   selector: row => "₹" + Math.round(row.paid_amount),
    //   sortable: true,
    //   minWidth: "100px",
    //   maxWidth: "12%",
    // },
    // {
    //   name: "Payable Amt",
    //   selector: row => "₹" + Math.round(row.payable_amount),
    //   sortable: true,
    //   minWidth: "100px",
    //   maxWidth: "12%",
    // },
    {
      name: "Cancelled Amt.(WT)",
      selector: row => "₹" + Math.round(row.cancelled_amount),
      sortable: true,
      // minWidth: "100px",
      // maxWidth: "12%",
    },
    {
      name: "Net Basic Amt.(WT)",
      selector: row => "₹" + Math.round(row.net_amount),
      sortable: true,
      // minWidth: "100px",
      // maxWidth: "12%",
    },
    {
      name: "Commission",
      selector: row => "₹" + Math.round(row.commission),
      sortable: true,
      // minWidth: "100px",
      // maxWidth: "12%",
    },
    {
      name: "Incentive",
      selector: row => "₹" + Math.round(row.incentive),
      sortable: true,
      // minWidth: "100px",
      // maxWidth: "12%",
    },
    {
      name: "Total",
      selector: row => "₹" + Math.round(row.total_commission),
      sortable: true,
      // minWidth: "100px",
      // maxWidth: "12%",
    },
    // {
    //   name: "Action",
    //   selector: row => {
    //     const maxLength = 35; // Maximum number of characters before adding ellipsis
    //     const bookingStatus = row.booking_status;
    //     const paymentStatus = row.payment_status;
    //     return bookingStatus === "Upcoming" && paymentStatus === "Pending" ? (
    //       <>
    //         <button className="clmBtn" disabled>
    //           Claim Commission
    //         </button>
    //       </>
    //     ) : (bookingStatus === "In process" || bookingStatus === "Modified" || bookingStatus === "Confirmed") &&
    //       (paymentStatus === "Pending" || paymentStatus === "Paid") ? (
    //       <>
    //         <button className="clmBtn" disabled>
    //           Claim Commission
    //         </button>
    //       </>
    //     ) : bookingStatus === "Completed" && paymentStatus === "Pending" ? (
    //       <>
    //         <button className="clmBtn" onClick={() => handleClaimCommision(row.booking_id)}>
    //           Claim Commission
    //         </button>
    //       </>
    //     ) : bookingStatus === "Completed" && paymentStatus === "Processing" ? (
    //       <>
    //         <button className="claimedBtn">Claimed</button>
    //       </>
    //     ) : bookingStatus === "Completed" && paymentStatus === "Paid" ? (
    //       <>
    //         <button disabled className="withdrawnBtn">
    //           Paid
    //         </button>
    //       </>
    //     ) : bookingStatus === "Cancelled" ? (
    //       <>
    //         <button disabled className="cancelledBtn">
    //           Cancelled
    //         </button>
    //       </>
    //     ) : null;
    //   },
    //   // selector: row => row.claim_status,
    //   sortable: true,
    //   // minWidth: "60px",
    //   maxWidth: "12%",
    // },
    {
      name: "Status",
      selector: row => row.payment_status,
      cell: row => {
        const maxLength = 35; // Maximum number of characters before adding ellipsis
        const bookingStatus = row.payment_status;
        return (
          <span
            className={
              bookingStatus === "Paid"
                ? `greenStatusText`
                : bookingStatus === "Open"
                ? // : bookingStatus === "Upcoming"
                  `yellowStatusText`
                : bookingStatus === "In Process"
                ? `pinkStatusText`
                : ``
            }>
            {bookingStatus}
          </span>
        );
      },
      sortable: true,
      // minWidth: "100px",
      // maxWidth: "17%",
    },
  ];

  const columns1 = [
    {
      name: "Date",
      selector: row => {
        if (row.date) {
          const originalDateString = row.date;

          // Parse the original date string into a Date object
          const originalDate = new Date(originalDateString);

          // Months array for mapping month indexes to month names
          const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

          // Get the month, day, and year from the parsed date
          const monthIndex = originalDate.getMonth();
          const day = originalDate.getDate();
          const year = originalDate.getFullYear();

          // Format the date string
          const formattedDateString = `${day < 10 ? "0" + day : day} ${months[monthIndex]},  ${year}`;
          return formattedDateString;
        }
      },
      sortable: true,
      // minWidth: "90px",
      maxWidth: "13%",
    },
    {
      name: "Invoice No.",
      selector: row => {
        return row?.invoice_number;
      },
      cell: row => (
        <Link to={`/partners/commissions/${row.invoice_number}`} target="_blank">
          #{row.invoice_number}
        </Link>
      ),
      sortable: true,
      minWidth: "225px",
      maxWidth: "14%",
      sortable: true,
      // minWidth: "245px",
      maxWidth: "22%",
    },
    {
      name: "Month",
      selector: row => row.month,
      sortable: true,
      // minWidth: "100px",
      maxWidth: "12%",
    },
    {
      name: "Commission",
      selector: row => "₹" + Math.round(row.total_commission),
      sortable: true,
      // minWidth: "100px",
      maxWidth: "12%",
    },
    {
      name: "Incentive",
      selector: row => "₹" + Math.round(row.incentive),
      // selector: row => {
      //   const originalDateString = row.payment_date;

      //   // Parse the original date string into a Date object
      //   const originalDate = new Date(originalDateString);

      //   // Months array for mapping month indexes to month names
      //   const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      //   // Get the month, day, and year from the parsed date
      //   const monthIndex = originalDate.getMonth();
      //   const day = originalDate.getDate();
      //   const year = originalDate.getFullYear();

      //   // Format the date string
      //   const formattedDateString = `${day < 10 ? "0" + day : day} ${months[monthIndex]},  ${year}`;
      //   return formattedDateString;
      // },
      sortable: true,
      // minWidth: "135px",
      maxWidth: "13%",
    },
    {
      name: "Total Payable",
      selector: row => "₹" + Math.round(row.total_payable),
      sortable: true,
      // minWidth: "100px",
      maxWidth: "12%",
    },
    {
      name: "TDS",
      selector: row => "₹" + Math.round(row.tds),
      sortable: true,
      // minWidth: "100px",
      maxWidth: "12%",
    },
    {
      name: "Processed Amt.",
      selector: row => "₹" + Math.round(row.processed_amount),
      sortable: true,
      // minWidth: "100px",
      maxWidth: "12%",
    },
    {
      name: "Date of Processing",
      selector: row => "-",
      sortable: true,
      // minWidth: "100px",
      maxWidth: "12%",
    },
    {
      name: "Voucher No.",
      selector: row => "-",
      sortable: true,
      // minWidth: "100px",
      maxWidth: "12%",
    },
    {
      name: "Voucher",
      selector: row => {
        return <button className="clmBtn">Download</button>;
      },
      sortable: true,
      // minWidth: "100px",
      maxWidth: "12%",
    },
    {
      name: "Invoice",
      // selector: row => "₹" + Math.round(row.commission),
      selector: row => {
        return (
          <a href={row?.pdfFilePath} download target="_blank" className="clmBtn aBtn">
            Download
          </a>
        );
      },
      sortable: true,
      // minWidth: "100px",
      maxWidth: "12%",
    },
  ];

  const data = [
    {
      quoteID: 1157820,
      reatilerName: "Attilio Gatti",
      businessLegalName: "Maroob Perfume Store",
      RetailerInternalID: "Perfume & Cosmetic",
      quoteDate: "Amman",
      totalQuote: "150 orders",
      quoteItems: "1532.00 JOD",
    },
    {
      quoteID: 1157821,
      reatilerName: "M. Hossain",
      businessLegalName: "Qalb Grocery Store",
      RetailerInternalID: "Grocery",
      quoteDate: "Zarqa",
      totalQuote: "298 orders",
      quoteItems: "2117.00 JOD",
    },
    {
      quoteID: 1157822,
      reatilerName: "Firoja Begum",
      businessLegalName: "New Habibi Amman",
      RetailerInternalID: "Perfume & Cosmetic",
      quoteDate: "Na’ur",
      totalQuote: "432 orders",
      quoteItems: "32115.00 JOD",
    },
    {
      quoteID: 1157823,
      reatilerName: "Jafar Javeed",
      businessLegalName: "Jafar & Sons",
      RetailerInternalID: "Grocery",
      quoteDate: "Beit Raus",
      totalQuote: "298 orders",
      quoteItems: "2117.00 JOD",
    },
    {
      quoteID: 1157824,
      reatilerName: "Sk. Sahanawaz",
      businessLegalName: "Big Grocery",
      RetailerInternalID: "Grocery",
      quoteDate: "Amman",
      totalQuote: "732 orders",
      quoteItems: "4117.00 JOD",
    },
    {
      quoteID: 1157825,
      reatilerName: "Makbul Hossain",
      businessLegalName: "Mood Fragrances",
      RetailerInternalID: "Perfume & Cosmetic",
      quoteDate: "Fuheis",
      totalQuote: "385 orders",
      quoteItems: "2536.00 JOD",
    },
    {
      quoteID: 1157826,
      reatilerName: "Irfaan Khan",
      businessLegalName: "Grocery Market Store",
      RetailerInternalID: "Grocery",
      orderDate: "Fuheis",
      totalQuote: "98 orders",
      quoteItems: "186.00 JOD",
    },
    {
      quoteID: 1157827,
      reatilerName: "Rana Al Abiad",
      businessLegalName: "Food&Food Hub",
      RetailerInternalID: "Grocery",
      orderDate: "Beit Raus",
      totalQuote: "315 orders",
      quoteItems: "5123.00 JOD",
    },
  ];

  const formattedDateFunc = date => {
    const originalDateString = date;

    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);

    // Months array for mapping month indexes to month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Get the month, day, and year from the parsed date
    const monthIndex = originalDate.getMonth();
    const day = originalDate.getDate();
    const year = originalDate.getFullYear();

    // Format the date string
    const formattedDateString = `${day < 10 ? "0" + day : day} ${months[monthIndex]},  ${year}`;
    return formattedDateString;
  };

  const handleResetFilter = async () => {
    setFilterParameter(prev => ({
      ...prev,
      startDate: null,
      endDate: null,
      isCancelledBooking: false,
      bookingStatus: null,
      paymentStatus: null,
      customerName: null,
      bookingId: null,
    }));
    setApplyFilterBtn(!applyFilterBtn);
    setShowPopup3(false);
  };

  const getBookingList = async status => {
    setScreenLoading(true);
    function formatDate(date) {
      return new Intl.DateTimeFormat("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(date);
    }
    const formData = new FormData();
    setLoading(true);

    try {
      {
        activeTab && activeTab !== "current" && formData.append("payment_status", activeTab === "history" ? 2 : "");
      }

      if (filterParameter.filterType === 1 && filterParameter.startDate && !filterParameter.endDate) {
        NotificationManager.warning("Please select an end date.");
        setLoading(false);
        setScreenLoading(false);
        return;
      }
      if (filterParameter.filterType === 1 && !filterParameter.startDate && filterParameter.endDate) {
        NotificationManager.warning("Please select an start date.");
        setScreenLoading(false);
        return;
      }
      formData.append("start_date", filterParameter?.startDate ? formatDate(new Date(filterParameter?.startDate)) : "");
      formData.append("end_date", filterParameter?.endDate ? formatDate(new Date(filterParameter?.endDate)) : "");
      formData.append("booking_id", filterParameter?.bookingId ? filterParameter?.bookingId : "");
      formData.append("booking_status", filterParameter?.bookingStatus ? filterParameter?.bookingStatus : "");
      formData.append("payment_status", filterParameter?.paymentStatus ? filterParameter?.paymentStatus : "");
      formData.append("name", filterParameter?.customerName ? filterParameter?.customerName : "");

      let response = await agentCommissionListApi(formData);
      if (response) {
        if (response.data.res === true) {
          setBookingList(response.data.data);
          setHasCancelledOrModified(response.data.hasCancelledOrModified);
        } else {
        }
        setScreenLoading(false);
        setLoading(false);
      }
    } catch (error) {
      setScreenLoading(false);
      setLoading(false);
    }
  };

  const getPaymentHistory = async status => {
    setScreenLoading(true);
    function formatDate(date) {
      return new Intl.DateTimeFormat("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(date);
    }
    const formData = new FormData();
    setLoading(true);

    try {
      {
        activeTab && activeTab !== "current" && formData.append("payment_status", activeTab === "history" ? 2 : "");
      }

      if (filterParameter.filterType === 1 && filterParameter.startDate && !filterParameter.endDate) {
        NotificationManager.warning("Please select an end date.");
        setLoading(false);
        setScreenLoading(false);
        return;
      }
      if (filterParameter.filterType === 1 && !filterParameter.startDate && filterParameter.endDate) {
        NotificationManager.warning("Please select an start date.");
        setScreenLoading(false);
        return;
      }
      formData.append("start_date", filterParameter?.startDate ? formatDate(new Date(filterParameter?.startDate)) : "");
      formData.append("end_date", filterParameter?.endDate ? formatDate(new Date(filterParameter?.endDate)) : "");
      formData.append("booking_id", filterParameter?.bookingId ? filterParameter?.bookingId : "");
      formData.append("booking_status", filterParameter?.bookingStatus ? filterParameter?.bookingStatus : "");
      formData.append("payment_status", filterParameter?.paymentStatus ? filterParameter?.paymentStatus : "");
      formData.append("name", filterParameter?.customerName ? filterParameter?.customerName : "");

      let response = await agentPaymentHistoryApi({});
      if (response) {
        if (response.data.res === true) {
          setPaymentHistory(response.data.data);
          setHasCancelledOrModified(response.data.hasCancelledOrModified);
        } else {
        }
        setScreenLoading(false);
        setLoading(false);
      }
    } catch (error) {
      setScreenLoading(false);
      setLoading(false);
    }
  };

  const fetchVendorCities = async () => {
    try {
      let response = await vendorCitiesApi();
      if (response) {
        if (response.data.res === true) {
          setOriginCityList(response.data.data);
        } else {
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClaimCommision = async bookingID => {
    try {
      let response = await agentBookingCommissionClaimApi(bookingID);
      if (response) {
        if (response.data.res === true) {
          let tempArray = [...bookingList];
          let selectedVal = tempArray.find(item => parseInt(item.booking_id) === parseInt(bookingID));
          selectedVal.payment_status = "Processing";
          setBookingList(tempArray);
          NotificationManager.success(response.data.msg);
        } else {
        }
      }
    } catch (error) {}
  };

  // Filter bookingList based on the search input
  const filteredPackageList = bookingList.filter(
    item =>
      // item?.booking_status?.toString().toLowerCase().includes(search?.toLowerCase()) ||
      item?.booking_id?.toString().toLowerCase().includes(search?.toLowerCase()) ||
      item?.payment_status?.toString().toLowerCase().includes(search?.toLowerCase())
  );

  const handleCheckBoxChange = state => {
    let tempArray = [];
    if (state?.selectedRows?.length > 0) {
      state?.selectedRows?.map(item => {
        tempArray.push(item?.booking_id);
      });
    }
    setSelectedCheckboxIds(tempArray);
  };

  const handleActionChange = async (actionType, status) => {
    setDynamicTableKey(prev => prev + 1);
    try {
      const formData = new FormData();
      if (selectedCheckboxIds?.length > 0) {
        selectedCheckboxIds?.map(item => {
          formData.append("package_ids[]", item);
        });
      }
      formData.append("status", status ? status : "");

      let response = await changePackageStatusApi(formData);
      if (response) {
        if (response.data.res === true) {
          NotificationManager.success(response.data.msg);
          if (activeTab === "active" || activeTab === "inactive") {
            const updatedPackageList = bookingList.filter(item => !selectedCheckboxIds.includes(parseInt(item.booking_id)));
            setBookingList(updatedPackageList);
          } else {
            if (status === "3") {
              const updatedPackageList = bookingList.filter(item => !selectedCheckboxIds.includes(parseInt(item.booking_id)));
              setBookingList(updatedPackageList);
            } else if (status === "0") {
              const updatedPackageList = bookingList.map(item => {
                if (selectedCheckboxIds.includes(parseInt(item.booking_id))) {
                  return {...item, status: "Inactive"}; // Assuming 'status' is the property to update
                }
                return item;
              });
              setBookingList(updatedPackageList);
            } else if (status === "1") {
              const updatedPackageList = bookingList.map(item => {
                if (selectedCheckboxIds.includes(parseInt(item.booking_id))) {
                  return {...item, status: "Active"}; // Assuming 'status' is the property to update
                }
                return item;
              });
              setBookingList(updatedPackageList);
            } else {
            }
          }
          setSelectedCheckboxIds([]);
        } else {
          NotificationManager.error(response.data.msg);
        }
      }
    } catch (error) {}
  };

  const handleDuplicate = async () => {
    if (selectedCheckboxIds.length > 1) {
      NotificationManager.warning("You have to select only one package to duplicate.");
    } else {
      navigate("/business/package/duplicate/" + selectedCheckboxIds[0]);
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  const messageAdmin = () => {
    let tempArray = [...bookingList];
    let selectedData = tempArray.find(item => parseInt(item?.booking_id) === selectedCheckboxIds[0]);
    // console.log(selectedCheckboxIds);
    // console.log(selectedData);
    setSelectedBooking(selectedData);
    setShowPopup(true);
  };

  const [showPopup2, setShowPopup2] = useState(false);

  const uploadCSV = () => {
    setShowPopup2(true);
  };

  const [showPopup3, setShowPopup3] = useState(false);
  const filterPopup = () => {
    setShowPopup3(true);
  };

  const [selectedTab, setSelectedTab] = useState("Date Range");

  const handleTabClick = tab => {
    setSelectedTab(tab);
    setFilterParameter(prev => ({
      ...prev,
      filterType: tab === "Date Range" ? 1 : 2,
      startDate: null,
      endDate: null,
      isCancelledBooking: false,
      bookingStatus: null,
      phoneNumber: null,
      customerName: null,
      destination: null,
      originDestination: null,
      bookingId: null,
    }));
  };

  const handleSendMsgToAdmin = async () => {};

  useEffect(() => {
    // Clear selected checkboxes when bookingList is updated
    setSelectedCheckboxIds([]);
  }, [bookingList]);

  useEffect(() => {
    const result = data.filter(item => {
      //return item.title.toLowerCase().match(search.toLowerCase());
    });
    setFilter(result);
  }, [search]);

  useEffect(() => {
    getBookingList(activeTab);
  }, [activeTab, applyFilterBtn]);

  useEffect(() => {
    fetchVendorCities();
  }, []);

  useEffect(() => {
    getPaymentHistory();
  }, []);

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Commissions`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta name="description" content="This is Commissions list page" />
      </Helmet>
      <AgentHeader />
      <div className="venderInnerDiv">
        <AgentSideBar />

        <div className="venderRight">
          {screenLoading && <LoadingPage />}
          <div className="hdrSec">
            <h2>Your Commission</h2>
          </div>
          <div className="venderMain venderMain2">
            <ul className="navbar packages-list">
              <li className={activeTab === "current" ? "active" : ""}>
                <Link
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("current");
                    // getBookingList();
                  }}>
                  Commission History
                </Link>
              </li>
              <li className={activeTab === "history" ? "active" : ""}>
                <Link
                  onClick={() => {
                    setSelectedCheckboxIds([]);
                    setActiveTab("history");
                    // getBookingList("1");
                  }}>
                  Payment History
                </Link>
              </li>
            </ul>
            <div className="data-table-section bookDataSec">
              {!loading ? (
                activeTab === "current" ? (
                  <DataTable
                    key={activeTab + dynamicTableKey}
                    className="Tbl packages-listing booking-listing"
                    columns={columns}
                    data={filteredPackageList}
                    fixedHeader
                    //fixedHeaderScrollHeight="300px"
                    highlightOnHover
                    pagination
                    //paginationComponentOptions={paginationComponentOptions}
                    responsive
                    selectableRows
                    selectableRowsHighlight
                    subHeader
                    subHeaderAlign="left"
                    onSelectedRowsChange={handleCheckBoxChange}
                    // onRowClicked={row => {
                    //   const url = `/partners/booking/detail/${row.booking_id}`;
                    //   window.open(url, "_blank"); // Opens in a new tab
                    // }}
                    subHeaderComponent={
                      <>
                        <div className="search-box">
                          <img src={filterBtn} alt="" className="filterBtn" onClick={() => filterPopup()} />
                          <Form.Control type="text" className="SrchInpt" placeholder="Search here" value={search} onChange={e => setSearch(e.target.value)} />
                        </div>
                        <div className="ActionRow">
                          <span className="LftField">
                            {/* <span>Action:</span>{" "}
                            <button
                              className={`exportLink ${selectedCheckboxIds?.length == 0 || activeTab === "inactive" ? "disabledButton" : ""}`}
                              disabled={selectedCheckboxIds?.length == 0 || activeTab === "inactive" ? true : false}
                              onClick={() => handleActionChange("inactive", "0")}>
                              Generate Invoice
                            </button> */}
                            {/* <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 || activeTab === "active" ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 || selectedCheckboxIds?.length > 1 ? true : false}
                            onClick={() => messageAdmin()}>
                            Message to Admin
                          </button>
                          <button
                            className={`exportLink ${selectedCheckboxIds?.length == 0 || selectedCheckboxIds?.length > 1 ? "disabledButton" : ""}`}
                            disabled={selectedCheckboxIds?.length == 0 || selectedCheckboxIds?.length > 1 ? true : false}
                            onClick={() => handleDuplicate()}>
                            Cancel
                          </button> */}
                          </span>
                        </div>
                        {/* <p className='SelectedRow'>08 Selected</p> */}
                      </>
                    }
                  />
                ) : (
                  <DataTable
                    key={activeTab + dynamicTableKey}
                    className="Tbl packages-listing booking-listing"
                    columns={columns1}
                    data={paymentHistory}
                    fixedHeader
                    //fixedHeaderScrollHeight="300px"
                    highlightOnHover
                    pagination
                    //paginationComponentOptions={paginationComponentOptions}
                    responsive
                    selectableRows
                    selectableRowsHighlight
                    subHeader
                    subHeaderAlign="left"
                    onSelectedRowsChange={handleCheckBoxChange}
                    // onRowClicked={row => navigate(`/business/booking/${row.booking_id}`)}
                    subHeaderComponent={
                      <>
                        <div className="search-box">
                          <img src={filterBtn} alt="" className="filterBtn" onClick={() => filterPopup()} />
                          <Form.Control type="text" className="SrchInpt" placeholder="Search here" value={search} onChange={e => setSearch(e.target.value)} />
                        </div>
                        {/* <div className="ActionRow">
                          <span className="LftField">
                            <span>Action:</span>{" "}
                            <button
                              className={`exportLink ${selectedCheckboxIds?.length == 0 || activeTab === "inactive" ? "disabledButton" : ""}`}
                              disabled={selectedCheckboxIds?.length == 0 || activeTab === "inactive" ? true : false}
                              onClick={() => handleActionChange("inactive", "0")}>
                              Generate Invoice
                            </button>
                          </span>
                        </div> */}
                      </>
                    }
                  />
                )
              ) : null}
            </div>
          </div>
        </div>

        {showPopup && (
          <div className={`popup addPopup`}>
            <div className="sliderflex">
              <div className="popInner2 bookingPopup">
                <h2>Message Admin</h2>
                <ul className="formList">
                  <li className="fullWd">
                    <textarea onChange={e => setAdminMsgText(e.target.value)}>Type message</textarea>
                  </li>
                  <li className="halfWd">
                    <label>Package</label>
                    <select>
                      <option value={selectedBooking?.package_name}>{selectedBooking?.package_name}</option>
                    </select>
                  </li>
                  <li className="smlWd">
                    <label>Package ID</label>
                    <input readOnly type="text" placeholder="#551236" value={"#" + selectedBooking?.package_id} />
                  </li>
                  <li className="halfWd">
                    <label>Run Date</label>
                    <select>
                      {/* <option>March 20, 2024</option> */}
                      <option value={selectedBooking?.first_booking_date}>{formattedDateFunc(selectedBooking?.first_booking_date)}</option>
                    </select>
                  </li>
                  <li className="fullWd">
                    <button className="addBtn addBtn2" disabled={!adminMsgText} onClick={() => handleSendMsgToAdmin()}>
                      Send Message
                    </button>
                  </li>
                </ul>
                <button className="closePopupBtn2" onClick={() => setShowPopup(false)}>
                  <img src={crossIcon2} alt="" />
                </button>
              </div>
            </div>
          </div>
        )}

        {showPopup2 && (
          <div className={`popup addPopup`}>
            <div className="sliderflex">
              <div className="popInner2 bookingPopup">
                <h2>Upload CSV</h2>
                <ul className="formList">
                  <li className="fullWd">
                    <label>File</label>

                    <input type="file" />
                  </li>

                  <li className="fullWd">
                    <button className="addBtn">Save</button>
                  </li>
                </ul>
                <button className="closePopupBtn2" onClick={() => setShowPopup2(false)}>
                  <img src={crossIcon2} alt="" />
                </button>
              </div>
            </div>
          </div>
        )}

        {showPopup3 && (
          <div className={`popup addPopup`}>
            <div className="sliderflex">
              <div className="popInner2 filterPopup">
                <h2>Filter</h2>

                {activeTab === "current" ? (
                  <div className="tabs">
                    {["Date Range", "Single Booking"].map(tab => (
                      <div key={tab} className={`tab ${tab === selectedTab ? "active" : ""}`} onClick={() => handleTabClick(tab)}>
                        {tab}
                      </div>
                    ))}
                  </div>
                ) : null}

                <div className="tabContent">
                  {selectedTab === "Date Range" && (
                    <div>
                      <ul className="formList">
                        {activeTab !== "current" ? (
                          <li>
                            <label class="filterLabel">Payment date Range</label>
                          </li>
                        ) : null}
                        <li>
                          <div className="datePckr2">
                            <DatePicker
                              selected={filterParameter.startDate}
                              onChange={date =>
                                setFilterParameter({
                                  ...filterParameter,
                                  startDate: date,
                                })
                              }
                              // dateFormat="yyyy-MM-dd"
                              dateFormat="MMMM d, yyyy"
                              placeholderText="From"
                              // minDate={new Date()}
                            />
                          </div>
                        </li>
                        <li>
                          <div className="datePckr2">
                            <DatePicker
                              selected={filterParameter.endDate}
                              onChange={date =>
                                setFilterParameter({
                                  ...filterParameter,
                                  endDate: date,
                                })
                              }
                              // dateFormat="yyyy-MM-dd"
                              dateFormat="MMMM d, yyyy"
                              placeholderText="To"
                              // minDate={new Date()}
                            />
                          </div>
                        </li>
                        {/* <li>
                          <label class="checkCustom">
                            Cancelled Booking
                            <input
                              type="checkbox"
                              value={filterParameter.isCancelledBooking}
                              onChange={e =>
                                setFilterParameter({
                                  ...filterParameter,
                                  isCancelledBooking: e.target.checked,
                                })
                              }
                            />
                            <span class="checkmark"></span>
                          </label>
                        </li> */}
                        {activeTab === "current" ? (
                          <>
                            <li>
                              <select
                                value={filterParameter.bookingStatus}
                                onChange={e =>
                                  setFilterParameter({
                                    ...filterParameter,
                                    bookingStatus: e.target.value,
                                  })
                                }>
                                <option selected hidden>
                                  Travel Status
                                </option>
                                {bookingStatusArray?.map((item, index) => (
                                  <option key={index} value={item?.id}>
                                    {item?.value}
                                  </option>
                                ))}
                              </select>
                            </li>
                            <li>
                              <select
                                value={filterParameter.paymentStatus}
                                onChange={e =>
                                  setFilterParameter({
                                    ...filterParameter,
                                    paymentStatus: e.target.value,
                                  })
                                }>
                                <option selected hidden>
                                  Payment Status
                                </option>
                                {paymentStatusArray?.map((item, index) => (
                                  <option key={index} value={item?.id}>
                                    {item?.value}
                                  </option>
                                ))}
                              </select>
                            </li>

                            <li>
                              <input
                                type="text"
                                placeholder="Customer Name"
                                value={filterParameter.customerName}
                                onChange={e =>
                                  setFilterParameter({
                                    ...filterParameter,
                                    customerName: e.target.value,
                                  })
                                }
                              />
                            </li>

                            <li className="fullWd appBtn">
                              <button
                                onClick={() => {
                                  setApplyFilterBtn(!applyFilterBtn);
                                  setShowPopup3(false);
                                }}>
                                Apply Filters
                              </button>
                            </li>
                            <li className="fullWd resetFilterBtn">
                              <a
                                href="#"
                                onClick={event => {
                                  event.preventDefault(); // Prevent default anchor tag behavior
                                  handleResetFilter();
                                }}>
                                Reset Filters
                              </a>
                            </li>
                          </>
                        ) : null}
                      </ul>
                    </div>
                  )}

                  {selectedTab === "Single Booking" && (
                    <div>
                      <ul className="formList">
                        <li>
                          <input
                            type="text"
                            placeholder="Booking ID"
                            value={filterParameter.bookingId}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                bookingId: e.target.value,
                              })
                            }
                          />
                        </li>
                        <li>
                          <select
                            value={filterParameter.bookingStatus}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                bookingStatus: e.target.value,
                              })
                            }>
                            <option selected hidden>
                              Travel Status
                            </option>
                            {bookingStatusArray?.map((item, index) => (
                              <option key={index} value={item?.id}>
                                {item?.value}
                              </option>
                            ))}
                          </select>
                        </li>
                        <li>
                          <select
                            value={filterParameter.paymentStatus}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                paymentStatus: e.target.value,
                              })
                            }>
                            <option selected hidden>
                              Payment Status
                            </option>
                            {paymentStatusArray?.map((item, index) => (
                              <option key={index} value={item?.id}>
                                {item?.value}
                              </option>
                            ))}
                          </select>
                        </li>
                        <li>
                          <input
                            type="text"
                            placeholder="Customer Name"
                            value={filterParameter.customerName}
                            onChange={e =>
                              setFilterParameter({
                                ...filterParameter,
                                customerName: e.target.value,
                              })
                            }
                          />
                        </li>

                        <li className="fullWd appBtn">
                          <button
                            onClick={() => {
                              setApplyFilterBtn(!applyFilterBtn);
                              setShowPopup3(false);
                            }}>
                            Apply Filters
                          </button>
                        </li>
                        <li className="fullWd resetFilterBtn">
                          <a
                            href="#"
                            onClick={event => {
                              event.preventDefault(); // Prevent default anchor tag behavior
                              handleResetFilter();
                            }}>
                            Reset Filters
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <button className="closePopupBtn2" onClick={() => setShowPopup3(false)}>
                  <img src={crossIcon2} alt="" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AgentCommisionList;
