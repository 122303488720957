import React, {Component, useState, useRef, useEffect} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import RangeSliderComponent from "../component/RangeSliderComponent";
import {Link, useNavigate} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {loginApi, vendorLoginApi} from "../services/ApiService";

import google from "../assets/img/google-icon.svg";
import facebook from "../assets/img/facebook-icon2.svg";
import VendorRegistrationLogo from "../assets/img/vendor-registration-logo.svg";
import VendorRegisteredSuccessIcon from "../assets/img/Vendor-registered-success-icon.svg";

const VendorThankYouPage = () => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [validationError, setValidationError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = e => {
    setMobileNumber(e.target.value);
  };

  const handleKeyPress = event => {
    if (event.key === "Enter" && !loading) {
      handleContinueWithOTP();
    }
  };

  const handleContinueWithOTP = async () => {
    const isValid = /^\d{10}$/.test(mobileNumber);

    if (!isValid) {
      setValidationError("Please enter a valid mobile number.");
    } else {
      setValidationError("");
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("mobile", mobileNumber);
        // Api call
        let response = await vendorLoginApi(formData);
        if (response) {
          if (response.data.res === true) {
            navigate("/business/login/phone/verify-otp", {state: {loginValue: mobileNumber, responseValue: response.data.data}});
          } else {
            setValidationError(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div className="main">
      <div className="innerDiv RegistrationInnerDiv">
        <div className="mainDiv">
          <div className="VendorRegistrationLogoSec">
            <img src={VendorRegistrationLogo} alt="" />
            <div className="loginDiv">
              <img src={VendorRegisteredSuccessIcon} alt="" className="SuccessIconImg" />
              <h2>Thank You!</h2>
              <span className="ConfirmSpanText">You will recieve a confirmation email shortly.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorThankYouPage;
