import React, {Component, useState, useRef, useEffect} from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import {Link, useNavigate} from "react-router-dom";

const special1 = require("./../assets/img/special1.png");

const BlogNews = () => {
  return (
    <div className="main">
      <Header />

      <div className="innerDiv">
        <div className="pplrDstn offersDiv">
          <div className="mainDiv">
            <h2 className="offerHdn">News</h2>
            <div className="blogList">
              <ul>
                <li>
                  <Link to="/news/1">
                    <img src={special1} alt="" />
                    <p>Aug 28 2023</p>
                    <h3>Kenya vs Tanzania Safari: The Better African Safari Experience</h3>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <img src={special1} alt="" />
                    <p>Aug 28 2023</p>
                    <h3>Kenya vs Tanzania Safari: The Better African Safari Experience</h3>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <img src={special1} alt="" />
                    <p>Aug 28 2023</p>
                    <h3>Kenya vs Tanzania Safari: The Better African Safari Experience</h3>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <img src={special1} alt="" />
                    <p>Aug 28 2023</p>
                    <h3>Kenya vs Tanzania Safari: The Better African Safari Experience</h3>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <img src={special1} alt="" />
                    <p>Aug 28 2023</p>
                    <h3>Kenya vs Tanzania Safari: The Better African Safari Experience</h3>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <img src={special1} alt="" />
                    <p>Aug 28 2023</p>
                    <h3>Kenya vs Tanzania Safari: The Better African Safari Experience</h3>
                  </Link>
                </li>
              </ul>
            </div>
            <Link to="/packages" className="offersBtn">
              Load More
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogNews;
